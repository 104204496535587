/**
 * @flow
 * */
import { createSelector } from 'reselect';
import { getProjectState } from 'modules/Dashboard';

export const getAuthState = createSelector(
  getProjectState,
  (state) => state.auth
);

export const getUserState = createSelector(
  getProjectState,
  (state) => state.user
);

export const getHowToState = createSelector(
  getProjectState,
  (state) => state.howTo
);

export const getNotificationState = createSelector(
  getProjectState,
  (state) => state.notification
);
