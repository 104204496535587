import type { Action } from 'lib/core/flow';

import SETTINGS_ACTIONS from '../types';

// LicenseRules Settings
export const fetchAdditionalSettings = ({ partnerId }): Action => ({
  type: SETTINGS_ACTIONS.FETCH_ADDITIONAL_SETTINGS,
  payload: { partnerId }
});
export const setFetchAdditionalSettingsSuccess = ({ data }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ADDITIONAL_SETTINGS_SUCCESS,
  payload: { data }
});
export const setFetchAdditionalSettingsFailure = ({ error }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ADDITIONAL_SETTINGS_FAILURE,
  payload: { error }
});
export const clearAdditionalSettingsState = (): Action => ({
  type: SETTINGS_ACTIONS.CLEAR_ADDITIONAL_SETTINGS_STATE
});
export const changeAdditionalSettings = ({ key, data }): Action => ({
  type: SETTINGS_ACTIONS.CHANGE_ADDITIONAL_SETTINGS,
  payload: { key, data }
});

// LicenseRules Category
export const editAdditionalCategorySettings = ({ data, key, path, keyForEdit, isSubKey = false, subKey, forDelete, partnerId }): Action => ({
  type: SETTINGS_ACTIONS.EDIT_ADDITIONAL_CATEGORY_SETTINGS,
  payload: { data, key, isSubKey, path, keyForEdit, subKey, forDelete, partnerId }
});
export const editAdditionalCategorySettingsSuccess = ({ data, key, keyForEdit, isSubKey = false, subKey, forDelete }): Action => ({
  type: SETTINGS_ACTIONS.EDIT_ADDITIONAL_CATEGORY_SETTINGS_SUCCESS,
  payload: { data, key, keyForEdit, isSubKey, subKey, forDelete }
});
export const editAdditionalCategorySettingsFailure = ({ error, key }): Action => ({
  type: SETTINGS_ACTIONS.EDIT_ADDITIONAL_CATEGORY_SETTINGS_FAILURE,
  payload: { error, key }
});
export const editAdditionalCategorySettingsState = ({ key }): Action => ({
  type: SETTINGS_ACTIONS.CLEAR_ADDITIONAL_CATEGORY_SETTINGS_STATE,
  payload: { key }
});
