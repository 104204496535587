/**
 * @flow
 * */
import React from 'react';
import { Menu as CoreMenu } from '@geneui/components';

import './styles.scss';

type MenuItem = {
    color: string | null,
    title: string,
    icon: string,
    url: string,
    key?: string,
    children?: MenuItem
}

type Props = {
   menuItems: Array<MenuItem>,
   onSelectHandler: Function
}

/**
 * Menu
 * @param { Array<MenuItem> } menuItems -
 * @param { Function } onSelectHandler -
 * @returns {*} {JSX.Element} - React.Component
 */
const Menu = ({ menuItems, onSelectHandler }: Props) => (
  <div className="menu">
    <CoreMenu
      onSelect={(e, item) => onSelectHandler(item)}
      data={menuItems}
    />
  </div>
);

export default Menu;
