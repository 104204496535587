import type { Action } from 'lib/core/flow';

import type { SettingsState } from 'modules/SRMT/Settings/types';
import enabledPartnersSettings from './enabledPartnersSettings';
import partnerAlertSettings from './partnerAlertSettings';
import alertMonitorSettings from './alertMonitorSettings';
import notificationSettings from './notificationSettings';
import categorySettings from './categorySettings';
import changeHistory from './changeHistory';

const initialState = (): SettingsState => ({
  ...enabledPartnersSettings.initialState(),
  ...categorySettings.initialState(),
  ...notificationSettings.initialState(),
  ...changeHistory.initialState(),
  ...alertMonitorSettings.initialState(),
  ...partnerAlertSettings.initialState()
});

const actionManager = {
  ...categorySettings.actionManager,
  ...enabledPartnersSettings.actionManager,
  ...notificationSettings.actionManager,
  ...changeHistory.actionManager,
  ...alertMonitorSettings.actionManager,
  ...partnerAlertSettings.actionManager
};

const settings = (state = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};

export default {
  settings
};
