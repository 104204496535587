/**
 * @flow
* */
import React from 'react';
import { Redirect } from 'react-router-dom';

const PlayerCheckHistoryReport = React.lazy(() => import('./pages/Reports/PlayerCheckHistoryReport'));
const PlayerBlockHistoryReport = React.lazy(() => import('./pages/Reports/PlayerBlockHistoryReport'));
const AmlRuleScoreReport = React.lazy(() => import('./pages/Reports/AmlRuleScoreReport'));
const PlayerScoreReport = React.lazy(() => import('./pages/Reports/PlayerScoreReport'));
const SettingsChangeHistory = React.lazy(() => import('./pages/SettingsChangeHistory'));
const PlayerDeepView = React.lazy(() => import('./pages/PlayerDeepView'));
const Transactions = React.lazy(() => import('./pages/Transactions'));
const AlertMonitor = React.lazy(() => import('./pages/AlertMonitor'));
const AmlDashboard = React.lazy(() => import('./pages/Dashboard'));
const Licenses = React.lazy(() => import('./pages/Licenses'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Players = React.lazy(() => import('./pages/Players'));

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const modulePath = 'aml';

const ROUTES: Array<Route> = [
  { path: `/${modulePath}`, name: 'Aml', exact: true, page: () => <Redirect to={`/${modulePath}/licenses`} /> },
  { path: `/${modulePath}/licenses`, name: 'Aml Licenses', exact: true, page: Licenses },
  { path: `/${modulePath}/:license/transactions`, name: 'Transactions', exact: true, page: Transactions },
  { path: `/${modulePath}/:license/settings`, name: 'Settings', exact: true, page: () => <Redirect to={`/${modulePath}/:license/settings/main-settings`} /> },
  { path: `/${modulePath}/:license/settings/main-settings`, name: 'Settings', exact: true, page: Settings },
  { path: `/${modulePath}/:license/settings/settings-change-history`, name: 'Settings', exact: true, page: SettingsChangeHistory },
  { path: `/${modulePath}/:license/players`, name: 'Players', exact: true, page: Players },
  { path: `/${modulePath}/:license/players/:id`,
    name: 'Player Deep View',
    exact: true,
    page: () => {
      const params = window.location.pathname.split('/');
      const url = `/${modulePath}/${params[2]}/players/${params[params.length - 1]}/general`;
      return <Redirect to={url} />;
    }
  },
  { path: `/${modulePath}/:license/players/:id/:tab`, name: 'Player/Tab', exact: true, page: PlayerDeepView },
  { path: `/${modulePath}/:license/reports`, name: 'Reports', exact: true, page: () => <Redirect to={`/${modulePath}/:license/reports/player-block-history`} /> },
  { path: `/${modulePath}/:license/reports/player-block-history`, name: 'Player Block History Report', exact: true, page: PlayerBlockHistoryReport },
  { path: `/${modulePath}/:license/reports/player-score`, name: 'Player Score Report', exact: true, page: PlayerScoreReport },
  { path: `/${modulePath}/:license/reports/player-check-history-reports`, name: 'Player Check History Reports', exact: true, page: PlayerCheckHistoryReport },
  { path: `/${modulePath}/:license/reports/aml-rule-score`, name: 'AML Rule Score Report', exact: true, page: AmlRuleScoreReport },
  { path: `/${modulePath}/:license/alert-monitor/:tab`, name: 'Alert Monitor', exact: true, page: AlertMonitor },
  { path: `/${modulePath}/:license/alert-monitor`, name: 'Alert Monitor', exact: true, page: AlertMonitor },
  { path: `/${modulePath}/:license/dashboard`, name: 'Aml Dashboard', exact: true, page: AmlDashboard }

];

export default ROUTES;
