import { DashboardTypeState, RoundInfoState } from 'modules/LCRMT/Dashboard/types';

const InitialState = {
  status: null,
  error: null,
  data: null
};

const createDashboardFiltersInitialState = (): DashboardTypeState => InitialState;
const createDashboardChartsInitialState = (): DashboardTypeState => InitialState;
const createDashboardTopPlayersInitialState = (): DashboardTypeState => InitialState;
const createRoundInfoInitialState = (): RoundInfoState => InitialState;

export {
  createDashboardFiltersInitialState,
  createDashboardChartsInitialState,
  createDashboardTopPlayersInitialState,
  createRoundInfoInitialState
};
