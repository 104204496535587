import moment from 'moment';
import { triggerTypes } from './triggerTypes';

const deleteKeys = ({ filterParams, keys }) => {
  // eslint-disable-next-line no-param-reassign
  keys.forEach(item => delete filterParams[item]);
};

export const mapByAlertFilterFetchParams = (filterParams) => {
  if (filterParams.playerBirthDate) {
    // eslint-disable-next-line no-param-reassign
    filterParams.playerBirthDate = moment(new Date(`${filterParams.playerBirthDate} UTC`)).format('x');
  }
  const newParams = {};
  let keys;
  const { triggers } = filterParams;
  switch (+triggers) {
    case triggerTypes.paymentSystem:
      keys = ['paymentSystems', 'byAlertWalletId'];
      newParams.triggerKey = `${filterParams.byAlertWalletId}:${filterParams.paymentSystems}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.fingerprint:
      keys = ['byAlertFingerPrint'];
      newParams.triggerKey = `${filterParams.byAlertFingerPrint}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.registrationIp:
      keys = ['byAlertRegistrationIp'];
      newParams.triggerKey = `${filterParams.byAlertRegistrationIp}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.birthDateFirstNameLastName:
      keys = ['playerBirthDate', 'byAlertFirstName', 'byAlertLastName'];
      newParams.triggerKey = `${filterParams.playerBirthDate}:${filterParams.byAlertFirstName}:${filterParams.byAlertLastName}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.lastNameBirthDateAddress:
      keys = ['byAlertLastName', 'playerBirthDate', 'byAlertPlayerAddress'];
      newParams.triggerKey = `${filterParams.byAlertLastName}:${filterParams.playerBirthDate}:${filterParams.byAlertPlayerAddress}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.lastNameBirthDateEmail:
      keys = ['byAlertLastName', 'playerBirthDate', 'byAlertPlayerEmail'];
      newParams.triggerKey = `${filterParams.byAlertLastName}:${filterParams.playerBirthDate}:${filterParams.byAlertPlayerEmail}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.birthDateAddressEmail:
      keys = ['playerBirthDate', 'byAlertPlayerAddress', 'byAlertPlayerEmail'];
      newParams.triggerKey = `${filterParams.playerBirthDate}:${filterParams.byAlertPlayerAddress}:${filterParams.byAlertPlayerEmail}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.lastNameEmailAddress:
      keys = ['byAlertLastName', 'byAlertPlayerEmail', 'byAlertPlayerAddress'];
      newParams.triggerKey = `${filterParams.byAlertLastName}:${filterParams.byAlertPlayerEmail}:${filterParams.byAlertPlayerAddress}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.documentNumber:
      keys = [['byAlertPlayerDocumentId']];
      newParams.triggerKey = `${filterParams.byAlertPlayerDocumentId}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.email:
      keys = [['byAlertPlayerEmail']];
      newParams.triggerKey = `${filterParams.byAlertPlayerEmail}`;
      deleteKeys({ filterParams, keys });
      break;
    case triggerTypes.phoneCountry:
      keys = ['byAlertPlayerPhone', 'countries'];
      newParams.triggerKey = `${filterParams.byAlertPlayerPhone}:${filterParams.countries}`;
      deleteKeys({ filterParams, keys });
      break;
    default: keys = [];
  }
  return {
    ...filterParams,
    ...newParams
  };
};
