import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import { generateObjectByKeys } from 'lib/helpers/generateObjectByKeys';
import type { SettingsState } from 'modules/SRMT/Settings/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import { generateObjectByActionType, keysForAdditionalSettings } from '../settings';
import { initialAdditionalState } from '../initialStates';

const initialState = {
  additional: initialAdditionalState()
};

const actionManager = {
  [SETTINGS_ACTION_TYPES.FETCH_ADDITIONAL_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...initialAdditionalState(),
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_ADDITIONAL_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...generateObjectByKeys({ object: payload.data, keys: keysForAdditionalSettings }),
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_ADDITIONAL_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...initialAdditionalState(),
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_ADDITIONAL_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: initialAdditionalState()
    }
  }),
  [SETTINGS_ACTION_TYPES.CHANGE_ADDITIONAL_SETTINGS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...state.categorySettings.additional,
        [payload.key]: payload.data
      }
    }
  }),
  // LicenseRules Category
  [SETTINGS_ACTION_TYPES.EDIT_ADDITIONAL_CATEGORY_SETTINGS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...state.categorySettings.additional,
        [payload.key]: {
          ...state.categorySettings.additional[payload.key],
          action: {
            status: ACTION_STATUSES.PENDING,
            error: null
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.EDIT_ADDITIONAL_CATEGORY_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...state.categorySettings.additional,
        [payload.key]: {
          data: generateObjectByActionType({
            data: state.categorySettings.additional[payload.key].data,
            payload
          }),
          action: {
            status: ACTION_STATUSES.SUCCEED,
            error: null
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.EDIT_ADDITIONAL_CATEGORY_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...state.categorySettings.additional,
        [payload.key]: {
          ...state.categorySettings.additional[payload.key],
          action: {
            status: ACTION_STATUSES.FAILED,
            error: payload.error
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_ADDITIONAL_CATEGORY_SETTINGS_STATE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      additional: {
        ...state.categorySettings.additional,
        [payload.key]: {
          ...state.categorySettings.additional[payload.key],
          action: {
            status: null,
            error: null
          }
        }
      }
    }
  })
};

export default {
  actionManager,
  initialState
};
