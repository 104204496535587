/**
 * @flow
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as signalR from '@microsoft/signalr';
import Button from '@geneui/components/Button';

import NotificationSettingsModal from 'modules/Dashboard/Auth/views/components/NotificationSettingsModal';
import NoNotificationSetting from 'modules/Dashboard/Auth/views/components/NoNotificationSetting';
import NotificationPopup from 'modules/Dashboard/Auth/views/components/NotificationPopup';
import { fetchNotifications } from 'modules/Dashboard/Auth/store/actions/notification';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import {
  getAddUpdateNotificationSelectors,
  getNotificationFilterSelectors,
  notificationsSelectors
} from 'modules/Dashboard/Auth/store/selectors/notificationSelectors';
import { buttonConfig } from 'config/settings/core-ui-strings';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useMessageHandler from 'hooks/useMessageHandler';
import messages from 'config/settings/messages';

import './styles.scss';

type Props = {
  setIsOpenNotification: Function,
  setIsNotificationOpen: Function,
  isOpenNotification: boolean,
  isNotificationOpen: boolean,
  userId: number
}

const Notification = ({ isOpenNotification, setIsOpenNotification, isNotificationOpen, setIsNotificationOpen, userId }:Props) => {
  const [hasNotificationSetting, setHasNotificationSetting] = useState(null);
  const [reviewedData, setReviewedData] = useState(null);
  const [signalRData, setSignalRData] = useState(null);
  const [alertType, setAlertType] = useState([]);
  const [unread, setUnread] = useState(false);
  const [product, setProduct] = useState([]);
  const [count, setCount] = useState(null);
  const [alert, setAlert] = useState([]);
  const [data, setData] = useState([]);
  const [date, setDate] = useState({
    startDate: null,
    endDate: null
  });

  const prevUnread = useRef(unread);
  const prevAlert = useRef(alert);
  const prevProduct = useRef(product);
  const prevAlertType = useRef(alertType);
  const prevDate = useRef(date);

  const {
    state: stateAddUpdate,
    isFailed: isFailedAddUpdate,
    isSucceed: isSucceedAddUpdate,
    dispatch
  } = useContainerStatusHandler({ selector: getAddUpdateNotificationSelectors, initiallyIsLoading: false });

  const {
    state,
    isFailed,
    isLoading,
    isSucceed
  } = useContainerStatusHandler({ selector: notificationsSelectors });

  const {
    state: filterState
  } = useContainerStatusHandler({ selector: getNotificationFilterSelectors });

  useMessageHandler({
    isFailed: isFailedAddUpdate,
    isSucceed: isSucceedAddUpdate,
    errorMessage: stateAddUpdate.error,
    successMessage: messages.success.saved
  });

  const popoverRef = useRef(null);
  const iconRef = useRef(null);
  const filterRef = useRef(null);

  const makeSignalRData = useCallback(() => {
    if (signalRData) {
      const tempData = [signalRData, ...data];
      setCount(count + 1);
      setData(tempData);
    }
  }, [setData, signalRData, data, setCount, count]);

  const makeDataStatus = useCallback(() => {
    if (reviewedData) {
      data.forEach(item => {
        if (item.Id === reviewedData.Id) {
          // eslint-disable-next-line no-param-reassign
          item.Status = 3;
        }
      });
      setData([...data]);
    }
  }, [reviewedData, data, setData]);

  const connectSignalR = useCallback(() => {
    // $FlowFixMe
    const connection = new signalR.HubConnectionBuilder().withUrl(`${process.env.REACT_APP_RMT_API}/hubs/notificationhub?userId=${userId}`).build();

    connection.on('SendMassage', item => {
      const buttonElement = document.querySelector('.bell-button');
      if (buttonElement) {
        buttonElement.classList.remove('bell-button');
        setTimeout(() => {
          buttonElement.classList.add('bell-button');
        }, 0);
      }
      setSignalRData(JSON.parse(item));
    });

    connection.on('UpdateNotificationStatus', item => {
      setReviewedData(JSON.parse(item));
    });

    connection.start().catch(() => {});
  }, [userId, setSignalRData, setReviewedData]);

  const closePopover = useCallback(() => {
    setIsOpenNotification(false);
  }, [setIsOpenNotification]);

  const notificationModalHandler = useCallback(() => {
    setIsNotificationOpen(true);
    setIsOpenNotification(false);
  }, [setIsNotificationOpen, setIsOpenNotification]);

  const getAndSetData = useCallback(() => {
    if (isSucceed) {
      const productChanged = JSON.stringify(prevProduct.current) !== JSON.stringify(product);
      const alertTypeChanged = JSON.stringify(prevAlertType.current) !== JSON.stringify(alertType);
      const alertChanged = JSON.stringify(prevAlert.current) !== JSON.stringify(alert);
      const dateChanged = JSON.stringify(prevDate.current) !== JSON.stringify(date);

      if (prevUnread.current !== unread || productChanged || alertTypeChanged || alertChanged || dateChanged) {
        setData(state.data.Notifications);
      } else {
        setData([...data, ...state.data.Notifications]);
      }

      setCount(state?.data?.NotificationCount);
      setHasNotificationSetting(state?.data?.HasSetting);

      prevUnread.current = unread;
      prevAlert.current = alert;
      prevProduct.current = product;
      prevAlertType.current = alertType;
      prevDate.current = date;
    }
  }, [state, setData, setCount, setHasNotificationSetting, data, prevUnread, unread, prevAlert, alert, prevProduct, product, prevAlertType, alertType, isSucceed, prevDate, date]);

  const getData = useCallback(() => {
    let tempAlert = [];
    if (alertType.length && !alert.length) {
      const tempAlertType = alertType.map(item => item.Id);
      tempAlert = filterState.data.Alert.filter(it => tempAlertType.includes(it.ParentId)).map(item => item.Id);
    } else if (alert.length) {
      tempAlert = alert.map(item => item.Id);
    }
    dispatch(fetchNotifications({
      data: {
        showUnRead: unread,
        hasSetting: hasNotificationSetting,
        startIndex: 0,
        count: 5,
        alertTypeIds: tempAlert,
        productIds: product,
        userId,
        createdDateFrom: date.startDate,
        createdDateTo: date.endDate
      }
    }));
  }, [dispatch, unread, alert, product, hasNotificationSetting, alertType, userId, date, filterState]);

  useEffect(connectSignalR, [dispatch]);
  useEffect(getData, [unread, alert, product, alertType, isSucceedAddUpdate, date]);
  useEffect(getAndSetData, [isSucceed]);
  useEffect(makeSignalRData, [signalRData]);
  useEffect(makeDataStatus, [reviewedData]);
  // $FlowFixMe
  useOnClickOutside(popoverRef, closePopover, iconRef, filterRef);

  return (
    <div className="notification">
      <div className="bell-wrapper" ref={iconRef}>
        <Button
          onClick={() => setIsOpenNotification(!isOpenNotification)}
          appearance={buttonConfig.appearance.minimal}
          disabled={isLoading || isFailed}
          icon="bc-icon-bell-outline"
          style={{ color: 'white' }}
          className="bell-button"
        />
        {/* $FlowFixMe */}
        {!!count && <div className="bell-count">{count > 99 ? '99+' : count}</div>}
      </div>
      {isNotificationOpen && (
        <NotificationSettingsModal
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
        />
      )}
      {isOpenNotification && (
        <div className="notification-popup" ref={popoverRef}>
          {!hasNotificationSetting && (
            <NoNotificationSetting notificationModalHandler={notificationModalHandler} />
          )}
          {hasNotificationSetting
            && (
              <NotificationPopup
                setAlertType={setAlertType}
                dataLoading={isLoading}
                setProduct={setProduct}
                setUnread={setUnread}
                filterRef={filterRef}
                alertType={alertType}
                setAlert={setAlert}
                setCount={setCount}
                setData={setData}
                product={product}
                setDate={setDate}
                userId={userId}
                unread={unread}
                count={count}
                alert={alert}
                data={data}
                date={date}
              />
            )}
        </div>
      )}
    </div>
  );
};

Notification.displayName = 'Notification';

export default Notification;
