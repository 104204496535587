/**
 * @flow
 * */
import { RoundExpandInfoParams, RoundInfoParams } from 'modules/LCRMT/Dashboard/types';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';

/**
 * @class DashboardSDK
 */
class DashboardSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * GetDashboardFilters
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetDashboardFilters(): Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTDashboard/GetDashboardFilters',
        { query_params: {}, abortController: this.setAbortController('GetDashboardFilters'), caching: true }

      );

      return { data: body.Data };
    }

    /**
   * charts
   * @param {Object} params -
   * @returns {Promise<{data: {Models: ?Object} }>} -
   */
    async GetDashboardCharts({ payload }: Object): Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTDashboard/GetDashboardData',
        { body: payload, abortController: this.setAbortController('GetDashboardData') }
      );

      return { data: body.Data };
    }

    /**
   * top players
   * @param {Object} params -
   * @returns {Promise<{data: {Models: ?Object} }>} -
   */
    async GetDashboardTopPlayers({ payload }: Object): Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        `LCRMTDashboard/GetDashboardTopPlayersData?metricType=${payload.metricType}`,
        { body: payload, abortController: this.setAbortController('GetDashboardTopPlayersData') }
      );

      return { data: body.Data };
    }

    /**
     * GetRoundInfo
     * @param {RoundInfoParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetRoundInfo({ payload } : RoundInfoParams): Promise<Object> {
      const { roundId, tableId } = payload;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTReport/GetRoundPlayers',
        { query_params: { roundId, tableId }, abortController: this.setAbortController('getRoundInfo') }

      );
      return { data: body.Data };
    }

    /**
     * GetRoundInfoExpand
     * @param {RoundExpandInfoParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetRoundInfoExpand({ roundId, tableId, clientId } : RoundExpandInfoParams): Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetRoundData',
        { query_params: { roundId, tableId, clientId }, abortController: this.setAbortController('getRoundInfo') }

      );
      return { data: body.Data };
    }

    /**
     * cancelDashboardSDKCalls
     * @param {Object} params -
     * @returns {Object} -
     */
    cancelDashboardSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new DashboardSDK();
