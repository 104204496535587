/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';

// Processed Alerts
export const fetchProcessedAlerts = ({ playerId, count, start }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PROCESSED_ALERTS,
  payload: { playerId, count, start }
});
export const setFetchProcessedAlertsSuccess = ({ data, totalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PROCESSED_ALERTS_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchProcessedAlertsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PROCESSED_ALERTS_FAILURE,
  payload: { error }
});
export const clearProcessedAlertsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PROCESSED_ALERTS_STATE
});

// Player Alerts
export const fetchPlayerAlerts = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_ALERTS,
  payload: { playerId }
});
export const setFetchPlayerAlertsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_ALERTS_SUCCESS,
  payload: { data }
});
export const setFetchPlayerAlertsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_ALERTS_FAILURE,
  payload: { error }
});

export const clearPlayerAlertsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_ALERTS_STATE
});

// Alerts Bulk Review
export const fetchAlertsBulkReview = ({ params }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERTS_BULK_REVIEW,
  payload: { params }
});
export const setFetchAlertsBulkReviewSuccess = (): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ALERTS_BULK_REVIEW_SUCCESS
});
export const setFetchAlertsBulkReviewFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ALERTS_BULK_REVIEW_FAILURE,
  payload: { error }
});
export const clearAlertsBulkReviewState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_ALERTS_BULK_REVIEW_STATE
});

// Update
export const updatePlayerAlerts = ({ data, totalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.UPDATE_PLAYER_ALERTS,
  payload: { data, totalCount }
});
