/**
 * @flow
 * */

import NetworkService from 'core/services/network/NetworkService';
import {
  NotificationSettingsData,
  SaveNotificationsParas,
  SaveNotificationData
} from 'modules/SRMT/Settings/core/sdk/types';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';

/**
 * @class NotificationSettingSDK
 */
class NotificationSettingSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * fetchNotificationSettings -
     * @returns {Promise<WithData<NotificationSettingsData>>} -
     */
    async fetchNotificationSettings(): Promise<{data: Array<NotificationSettingsData>}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'NotificationSettings/GetNotifications',
        { query_params: { }, abortController: this.setAbortController('notificationSettings') }
      );

      return { data: body.Data };
    }

    /**
     * saveNotificationSettings -
     * @returns {Array<SaveNotificationData>} -
     */
    async saveNotificationSettings({ params }: SaveNotificationsParas):Promise<SaveNotificationData> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'NotificationSettings/SaveNotifications',
        { body: params }
      );
      return { data: body.Data };
    }

    /**
     * deleteNotificationSettings -
     * @returns {Promise<Object>} -
     */
    async deleteNotificationSettings({ Id }: Object):Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'NotificationSettings/DeleteNotification',
        { query_params: { Id } }
      );

      return { data: body.Data };
    }

    /**
     * cancelNotificationSettingsSDKCalls
     * @param {Object} params -
     * @returns {Object} -
     */
    cancelNotificationSettingsSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new NotificationSettingSDK();
