/**
 * @flow
 * */
import createSymbol from 'lib/core/createSymbol';

const createCommentsTemplateSymbol = createSymbol('COMMENTS_TEMPLATE');

export default {
  FETCH_COMMENTS_TEMPLATE: createCommentsTemplateSymbol('FETCH_COMMENTS_TEMPLATE'),
  SET_FETCH_COMMENTS_TEMPLATE_SUCCESS: createCommentsTemplateSymbol('SET_FETCH_COMMENTS_TEMPLATE_SUCCESS'),
  SET_FETCH_COMMENTS_TEMPLATE_FAILURE: createCommentsTemplateSymbol('SET_FETCH_COMMENTS_TEMPLATE_FAILURE')
};
