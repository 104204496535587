/**
 * @flow
 * */
import config from 'config';

import { statisticTypeEnumByKey } from 'modules/LCRMT/utils/enums';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import type { WithData } from 'types';
import type {
  AlertsReviewActionParams,
  ResolveAlertReviewParams,
  AlertReviewParams,
  AlertReviewModel,
  AlertsParams,
  AlertsModel
} from 'modules/LCRMT/Alerts/types';
import {
  HourlyAlertReviewExpandParams,
  HourlyAlertExpandParams,
  StatisticParams
} from 'modules/LCRMT/Alerts/types';

/**
 * * @class AlertsSDK
 */
class AlertsSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * getAlerts
     * @param {AlertsParams} params -
     * @returns {Promise<WithData<AlertsModel>>} -
     */
    async getAlerts(params: AlertsParams): Promise<WithData<AlertsModel>> {
      const { count, start, filters } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetChiSquareAlerts',
        { query_params: { count, start, ...filters }, abortController: this.setAbortController('getAlerts') }
      );

      return {
        data: body.Data.Models,
        totalCount: body.Data.TotalCount // @TODO change when backend will be ready
      };
    }

    /**
     * getHourlyAlerts
     * @param {AlertsParams} params -
     * @returns {Promise<WithData<AlertsModel>>} -
     */
    async getHourlyAlerts(params: AlertsParams): Promise<WithData<AlertsModel>> {
      const { count, start, filters, passTotalCount } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTAlerts/GetHourlyAlerts',
        { body: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('getLcrmtHourlyAlert') }
      );

      return {
        data: body.Data.Models,
        totalCount: body.Data.TotalCount
      };
    }

    /**
     * GetHourlyAlertsExpand
     * @param {HourlyAlertExpandParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetHourlyAlertsExpand(params: HourlyAlertExpandParams): Promise<Object> {
      const { alertId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetHourlyAlertActionHistory',
        { query_params: { alertId } }
      );

      return { data: body.Data };
    }

    /**
     * GetHourlyAlertsReview
     * @param {AlertReviewParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetHourlyAlertsReview(params: AlertReviewParams): Promise<Object> {
      const { alertId, alertType } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetAlertReview',
        { query_params: { alertId, alertType } }
      );

      return { data: body.Data };
    }

    /**
     * UpdateHourlyAlertsReviewAction
     * @param {AlertsReviewActionParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async UpdateHourlyAlertsReviewAction(params: AlertsReviewActionParams): Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTAlerts/UpdateHourlyAlert',
        { body: params }
      );

      return { data: body.Data };
    }

    /**
     * GetHourlyAlertsReviewExpand
     * @param {HourlyAlertReviewExpandParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetHourlyAlertsReviewExpand(params: HourlyAlertReviewExpandParams): Promise<Object> {
      const { roundId, tableId, clientId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetRoundData',
        { query_params: { roundId, tableId, clientId } }
      );

      return { data: body.Data };
    }

    /**
     * GetHourlyAlertsReviewHistory
     * @param {AlertReviewParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetHourlyAlertsReviewHistory(params: AlertReviewParams): Promise<Object> {
      const { alertId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetHourlyAlertHistory',
        { query_params: { alertId } }
      );

      return { data: body.Data };
    }

    /**
     * GetHourlyAlertsTrueCount
     * @param {AlertReviewParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetHourlyAlertsTrueCount(params: AlertReviewParams): Promise<Object> {
      const { alertId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetAdvantageAlertTrueCount',
        { query_params: { alertId } }
      );

      return { data: body.Data };
    }

    /**
     * GetStatistics
     * @param {StatisticParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async GetStatistics(params: StatisticParams): Promise<Object> {
      const { clientId, dates, statisticType } = params;
      let tempStatisticType = statisticType;
      if (typeof statisticType !== 'number') {
        tempStatisticType = statisticTypeEnumByKey[statisticType];
      }
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetClientStatistic',
        { query_params: { clientId, dates, statisticType: tempStatisticType } }
      );

      return { data: body.Data };
    }

    /**
     * getAlertReview
     * @param {AlertReviewParams} params -
     * @returns {Promise<WithData<AlertReviewModel>>} -
     */
    async getAlertReview(params: AlertReviewParams): Promise<WithData<AlertReviewModel>> {
      const { alertId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/ReviewChiSquareAlert',
        { query_params: { alertId } }
      );

      return {
        data: body.Data.ReviewResults
      };
    }

    /**
     * closeAlertReview
     * @param {AlertReviewParams} params -
     * @returns {Promise<WithData<any>>} -
     */
    async closeAlertReview(params: AlertReviewParams): Promise<WithData<any>> {
      const { alertId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/CloseChiSquareAlertPopUp',
        { query_params: { alertId } }
      );

      return body.Data;
    }

    /**
     * resolveAlertReview
     * @param {AlertReviewParams} params -
     * @returns {null} -
     */
    async resolveAlertReview(params: ResolveAlertReviewParams): Promise<WithData<any>> {
      const { alertId, comment } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/CloseChiSquareAlert',
        { query_params: { alertId, comment } }
      );

      return body.Data;
    }

    /**
     * getAlertReviewStatistics
     * @param {AlertReviewParams} params -
     * @returns {Promise<WithData<AlertReviewModel>>} -
     */
    async getAlertReviewStatistics(params: AlertReviewParams): Promise<WithData<AlertReviewModel>> {
      const { alertId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/ReviewChiSquareAlertStatistics',
        { query_params: { alertId } }
      );

      return {
        data: body.Data
      };
    }

    /**
   * cancelAlertSDKCalls
   * @param {Object} params -
   * @returns {Object} -
   */
    cancelAlertSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new AlertsSDK();
