/* eslint-disable  no-mixed-operators */
function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export const generateColor = (name) => {
  if (!name) return;
  // get first alphabet in upper case
  const firstAlphabet = name.charAt(0).toLowerCase();

  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);

  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  // eslint-disable-next-line radix
  const num = Math.round(0xffffff * parseInt(colorNum));
  // eslint-disable-next-line no-bitwise
  const r = num >> 16 & 255;
  // eslint-disable-next-line no-bitwise
  const g = num >> 8 & 255;
  // eslint-disable-next-line no-bitwise
  const b = num & 255;

  // eslint-disable-next-line consistent-return
  return rgbToHex(r, g, b);
};
