import type { MinimalReducer } from 'lib/core/flow';

type IdNameModel = {|
  Id: number,
  Name: string
|}

export type generalDataModel = {|
  SolutionType: number,
  SolutionTypeName:string,
  License: number,
  LicenseName: string
|};

export type projectsModel = {|
  aml: string,
  rmt: string
|}

export type PartnerManagementListModel = {|
  PartnerId: number,
  PartnerName: string,
  SRMT:boolean,
  AMLRG:boolean,
  OperatingRegion: string,
  SolutionType: string,
  License: string
|};

export type PartnerLicenseManagementModel= {|
  generalData: generalDataModel,
  projects: projectsModel,
  SRMT: string,
  AMLRG: string,
  selectedLicenseTemplateId: number,
  selectedLicenseId: number,
  selectedPartner: number,
|};

export type PartnerManagementSelectedRowModel = {|
  OperatingRegion: string,
  SolutionType: number,
  SolutionTypeName: string,
  PartnerName: string,
  PartnerId: number,
  AMLRG: boolean,
  SRMT: boolean,
  License: number,
  LicenseName: string
|}

export type GeneralDetailModel = {|
  solutionTypes: Array<IdNameModel>
|};

export type GeneralDetailState = {
  ...MinimalReducer,
  data: GeneralDetailModel,
};

export type PartnerManagementList = {|
  ...MinimalReducer,
  data: ?Array<PartnerManagementListModel>,
  totalCount: ?number
|};

export type PartnerLicenseManagementState = {|
  data: ? PartnerLicenseManagementModel,
  rowData: ? PartnerManagementSelectedRowModel
|};

export const stepTypes = {
  products: 1,
  settings: 2
};

export const footerNavigation = {
  next: 2,
  back: 1
};

export type PartnerManagementParams = {
  count: number,
  start: number,
  filters: Object,
  passTotalCount:boolean
};

export const BackProjectEnum = {
  rmt: 'RMT',
  aml: 'AML',
  lcrmt: 'LCRMT',
  crmt: 'CRMT',
  frmt: 'FRMT',
  agp: 'AGP',
  agpRequest: 'AGPREQUEST'
};

export const BackProjectEnumById = {
  2: 'RMT',
  1: 'AML',
  4: 'LCRMT',
  8: 'CRMT',
  16: 'FRMT'
};

export const BackTemplateEnum = {
  template: 2,
  partner: 4,
  license: 8,
  client: 16
};

export type BackTemplateEnumTypes = BackTemplateEnum.partner | BackTemplateEnum.template | BackTemplateEnum.license;
export type BackProjectEnumTypes = BackProjectEnum.rmt | BackProjectEnum.aml;

export type DeactivatePartnerRequest = {
  partnerId: number,
  productType: BackProjectEnumTypes
}

export type SrmtDataRequest = {
  id: number,
  forPartnerId: number,
  templateType: BackTemplateEnumTypes
}

export type AmlDataRequest = {
  id: number,
  templateType: BackTemplateEnumTypes,
  fromAml: boolean,
  playerSettings: boolean
}

export const projectsTypes = {
  srmt: 2,
  aml: 1,
  lcrmt: 4,
  crmt: 8
};

export const projectsTypesById = {
  2: 'SRMT',
  1: 'AMLRG',
  4: 'LCRMT',
  8: 'CRMT'
};

export const GamingControlEnum = {
  aml: 2,
  rg: 4
};

export const AmlAlertTypes = {
  aml: 1,
  rg: 2,
  singleThreshold: 3,
  multipleAccount: 4,
  suspiciousActivity: 5
};

export const CheckType = {
  countable: 1,
  cumulative: 2
};

export type Steps = stepTypes.general | stepTypes.products | stepTypes.settings;
export type GamingControlType = GamingControlEnum.aml | GamingControlEnum.rg;
export type NextBackType = footerNavigation.next | footerNavigation.back;
export type ProjectsBoxTypes = projectsTypes.srmt | projectsTypes.aml;

export type PartnerManagementState = {
  partnerLicenseManagementData:PartnerLicenseManagementState,
  partnerManagementData: PartnerManagementList,
  partners: GeneralDetailState,
  templates: GeneralDetailState,
  amlTemplates: GeneralDetailState,
  srmtData: GeneralDetailState,
  amlData: GeneralDetailState,
  allAmlSettings: GeneralDetailState,
  riskTypes: GeneralDetailState,
  setChangedData: { ...MinimalReducer },
  deactivatePartner: { ...MinimalReducer }
};

export type SingleRuleRestriction = {
  CanBet: boolean,
  CanDeposit: boolean,
  CanWithdraw: boolean,
  CanLogin: boolean
}

export type SingleRule = {
  AmlRuleId: number,
  Amount: number,
  Restriction: SingleRuleRestriction,
  RuleName: string
}

export const DefaultNotificationSettings = {
  Id: 0,
  Name: '',
  Frequency: null,
  IsDisabled: true,
  Emails: [],
  PandayoPlusUrls: [],
  AmlRules: [],
  RgRules: [],
  SingleThresholdRule: [],
  RiskAssessmentRule: []
};
