/**
 * @flow
 * */

/**
 * @class BaseSDK
 */
class BaseSDK {
  _abortController: Object;

  constructor() {
    this._abortController = {};
  }

  setAbortController(name: string): AbortController {
    this._abortController[name] = new AbortController();
    return this._abortController[name];
  }

  abortCall(name: string | Array<string>): void {
    if (typeof name === 'string') {
      if (this._abortController[name]) {
        this._abortController[name].abort();
      }
    }
    if (Array.isArray(name)) {
      name.forEach(item => {
        if (this._abortController[item]) {
          this._abortController[item].abort();
        }
      });
    }
  }
}
export default BaseSDK;
