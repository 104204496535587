import { deepClone } from 'lib/helpers/makeData';

export const amlAlertConfigs = {
  amlAlerts: {
    tabKey: 'amlAlerts'
  },
  rgAlerts: {
    tabKey: 'rgAlerts'
  },
  singleThresholdAlerts: {
    tabKey: 'singleThresholdAlerts'
  },
  suspiciousActivityAlerts: {
    tabKey: 'suspiciousActivityAlerts'
  },
  multiAccountAlerts: {
    tabKey: 'multiAccountAlerts'
  },
  multiAccountThresholdAlerts: {
    tabKey: 'multiAccountThresholdAlerts'
  },
  monitoringAlerts: {
    tabKey: 'monitoringAlerts'
  }
};

export const amlAlertInitialState = {
  alerts: {
    data: null,
    status: null,
    error: null,
    totalCount: null
  },
  export_state: {
    data: null,
    status: null,
    error: null
  },
  reviewing_alert: {
    data: null,
    status: null,
    error: null,
    edit: {
      data: null,
      status: null,
      error: null
    },
    history: {
      data: null,
      status: null,
      error: null
    }
  },
  bulk_review: {
    status: null,
    error: null
  },
  risk_category_history: {
    data: null,
    status: null,
    error: null
  }
};

export class AmlAlertsFactory {
  static createInitialAmlAlertsMonitor() {
    const alerts = {};
    Object.keys(amlAlertConfigs).forEach(name => { alerts[name] = deepClone(amlAlertInitialState); });
    return alerts;
  }
}
