/**
 * @flow
 */
import React from 'react';
import notification from 'assets/images/notification.svg';

import './styles.scss';

type Props = {
  notificationModalHandler: Function
}

const NoNotificationSetting = ({ notificationModalHandler }:Props) => (
  <div className="no-notification-setting">
    <div className="no-notification-setting-title">
      Notifications
    </div>
    <div className="no-notification-setting-body">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      No notifications have been set up, to set up your personal notifications go to the <span onClick={notificationModalHandler} className="notification-setting-link">Notifications</span>.
    </div>
    <div className="no-notification-setting-img">
      <img alt="notification" src={notification} />
    </div>
  </div>
);

NoNotificationSetting.displayName = 'NoNotificationSetting';

export default NoNotificationSetting;
