/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';

// Game Style Categories
export const fetchGameStyleCategories = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_GAME_STYLE_CATEGORIES,
  payload: { playerId }
});
export const setFetchGameStyleCategoriesSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_GAME_STYLE_CATEGORIES_SUCCESS,
  payload: { data }
});
export const setFetchGameStyleCategoriesFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_GAME_STYLE_CATEGORIES_FAILURE,
  payload: { error }
});
export const clearGameStyleCategoriesState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_GAME_STYLE_CATEGORIES_STATE
});

export const gameStyleCategoryUpdate = ({ playerId, categoryId, subId, action, comment }: Object): Action => ({
  type: PLAYER_ACTIONS.GAME_STYLE_CATEGORY_UPDATE,
  payload: { playerId, categoryId, subId, action, comment }
});
export const setGameStyleCategoryUpdateSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_GAME_STYLE_CATEGORY_UPDATE_SUCCESS,
  payload: { data }
});
export const setGameStyleCategoryUpdateFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_GAME_STYLE_CATEGORY_UPDATE_FAILURE,
  payload: { error }
});
export const clearGameStyleCategoryUpdate = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_GAME_STYLE_CATEGORY_UPDATE_STATE
});

export const gameStyleCategoriesBulkUpdate = ({ playerId, toAdd, toUpdate, toDelete, comment }: Object): Action => ({
  type: PLAYER_ACTIONS.GAME_STYLE_CATEGORIES_BULK_UPDATE,
  payload: { playerId, toAdd, toUpdate, toDelete, comment }
});
export const setGameStyleCategoriesBulkUpdateSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_SUCCESS,
  payload: { data }
});
export const setGameStyleCategoriesBulkUpdateFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_FAILURE,
  payload: { error }
});
export const clearGameStyleCategoriesBulkUpdate = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_GAME_STYLE_CATEGORIES_BULK_UPDATE_STATE
});

// Partner Game Style Categories
export const fetchPartnerGameStyleCategories = ({ partnerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PARTNER_GAME_STYLE_CATEGORIES,
  payload: { partnerId }
});
export const setFetchPartnerGameStyleCategoriesSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_SUCCESS,
  payload: { data }
});
export const setFetchPartnerGameStyleCategoriesFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_FAILURE,
  payload: { error }
});
export const clearPartnerGameStyleCategoriesState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PARTNER_GAME_STYLE_CATEGORIES_STATE
});

// Category History
export const fetchCategoryHistory = ({ playerId, count, start }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_CATEGORY_HISTORY,
  payload: { playerId, count, start }
});
export const setFetchCategoryHistorySuccess = ({ data, totalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_CATEGORY_HISTORY_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchCategoryHistoryFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_CATEGORY_HISTORY_FAILURE,
  payload: { error }
});
export const clearCategoryHistoryState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_CATEGORY_HISTORY_STATE
});

// Edit LicenseRules Category
export const editAdditionalCategory = ({ playerId, categoryId, isActive }: Object): Action => ({
  type: PLAYER_ACTIONS.EDIT_ADDITIONAL_CATEGORY,
  payload: { playerId, categoryId, isActive }
});
export const setEditAdditionalCategorySuccess = ({ categoryId }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_EDIT_ADDITIONAL_CATEGORY_SUCCESS,
  payload: { categoryId }
});
export const setEditAdditionalCategoryFailure = ({ categoryId, error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_EDIT_ADDITIONAL_CATEGORY_FAILURE,
  payload: { categoryId, error }
});

// MergeBulk LicenseRules Category
export const additionalCategoriesBulkMerge = ({ playerId, changeAdditionals, isCalcDisabled, comment }: Object): Action => ({
  type: PLAYER_ACTIONS.ADDITIONAL_CATEGORIES_BULK_MERGE,
  payload: { playerId, changeAdditionals, isCalcDisabled, comment }
});
export const setAdditionalCategoriesBulkMergeSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_ADDITIONAL_CATEGORIES_BULK_MERGE_SUCCESS,
  payload: { data }
});
export const setAdditionalCategoriesBulkMergeFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_ADDITIONAL_CATEGORIES_BULK_MERGE_FAILURE,
  payload: { error }
});
export const clearAdditionalCategoriesBulkMergeState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_ADDITIONAL_CATEGORIES_BULK_MERGE_STATE
});

// LicenseRules Categories
export const fetchAdditionalCategories = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ADDITIONAL_CATEGORIES,
  payload: { playerId }
});
export const setFetchAdditionalCategoriesSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ADDITIONAL_CATEGORIES_SUCCESS,
  payload: { data }
});
export const setFetchAdditionalCategoriesFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ADDITIONAL_CATEGORIES_FAILURE,
  payload: { error }
});
export const clearAdditionalCategoriesState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_ADDITIONAL_CATEGORIES_STATE
});

// Available LicenseRules Categories
export const fetchAvailableAdditionalCategories = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_AVAILABLE_ADDITIONAL_CATEGORIES,
  payload: { playerId }
});
export const setFetchAvailableAdditionalCategoriesSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_SUCCESS,
  payload: { data }
});
export const setFetchAvailableAdditionalCategoriesFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_FAILURE,
  payload: { error }
});
export const clearAvailableAdditionalCategoriesState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_AVAILABLE_ADDITIONAL_CATEGORIES_STATE
});

// Player KPI Tree
export const fetchPlayerKPITree = ({ level, playerId, sportId, regionId, withParents, key }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_KPI_TREE,
  payload: { level, playerId, sportId, regionId, withParents, key }
});
export const setFetchPlayerKPITreeSuccess = ({ data, key, children }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_KPI_TREE_SUCCESS,
  payload: { data, key, children }
});
export const setFetchPlayerKPITreeFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_KPI_TREE_FAILURE,
  payload: { error }
});
export const closePlayerKPITreeNode = ({ key }: Object): Action => ({
  type: PLAYER_ACTIONS.CLOSE_PLAYER_KPI_TREE_NODE,
  payload: { key }
});
export const openPlayerKPITreeNode = ({ key }: Object): Action => ({
  type: PLAYER_ACTIONS.OPEN_PLAYER_KPI_TREE_NODE,
  payload: { key }
});
export const clearPlayerKPITreeState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_KPI_TREE_STATE
});

// SuspiciousActivity Categories
export const fetchFinancialCategories = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_FINANCIAL_CATEGORIES,
  payload: { playerId }
});
export const updateFinancialCategories = ({ data }: any) => ({
  type: PLAYER_ACTIONS.UPDATE_FINANCIAL_CATEGORIES,
  payload: { data }
});
export const setFetchFinancialCategoriesSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_FINANCIAL_CATEGORIES_SUCCESS,
  payload: { data }
});
export const setFetchFinancialCategoriesFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_FINANCIAL_CATEGORIES_FAILURE,
  payload: { error }
});
export const clearFinancialCategoriesState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_FINANCIAL_CATEGORIES_STATE
});
