/**
 * @flow
 * */

import { initialActionReducerWithoutData, initialSectionState } from 'store/reducers/initialStates';
import expandReducerChildren from 'modules/SRMT/Players/utils/expandReducerChildren';
import PLAYER_ACTION_TYPES from 'modules/SRMT/Players/store/actions/player/types';
import { initialSectionWithEditState } from 'lib/core/initialStates';
import type { MonitorState } from 'modules/SRMT/AlertMonitor/types';
import type { PlayerState } from 'modules/SRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

const initialState = {
  playerFinancialTransactions: initialSectionState(),
  playerFinancialBalances: initialSectionState(),
  playerFinancialCashOut: initialSectionState(),
  financialCategories: initialSectionState(),
  playerFinancialKPIs: initialSectionState(),
  playersBets: initialSectionState()
};

const actionManager = {
  // Player SuspiciousActivity Cash Out
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_CASH_OUT]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialCashOut: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialCashOut: {
      status: ACTION_STATUSES.SUCCEED,
      data: [payload.data],
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialCashOut: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_FINANCIAL_CASH_OUT_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialCashOut: initialSectionState()
  }),
  // Player SuspiciousActivity KPIs
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_KPIS]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialKPIs: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_KPIS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialKPIs: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_KPIS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialKPIs: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_FINANCIAL_KPIS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialKPIs: initialSectionState()
  }),

  // PlayerBets
  [PLAYER_ACTION_TYPES.FETCH_PLAYERS_BETS]: (state: PlayerState): PlayerState => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYERS_BETS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playersBets.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYERS_BETS_APPEND_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      status: ACTION_STATUSES.SUCCEED,
      data: [
        ...state.playersBets.data,
        ...payload.data
      ],
      totalCount: payload.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYERS_BETS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYERS_BETS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playersBets: initialSectionWithEditState()
  }),

  // expand row children
  [PLAYER_ACTION_TYPES.FETCH_EXPAND_BETS_ROW_CHILDREN_DATA]: (state: MonitorState, payload) => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      data: expandReducerChildren(state.playersBets.data, payload.betId, null, ACTION_STATUSES.PENDING, null, 'BetId')
    }
  }),
  [PLAYER_ACTION_TYPES.SET_EXPAND_BETS_ROW_CHILDREN_DATA_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      data: expandReducerChildren(state.playersBets.data, payload.betId, payload.data, ACTION_STATUSES.SUCCEED, null, 'BetId')
    }
  }),
  [PLAYER_ACTION_TYPES.SET_EXPAND_BETS_ROW_CHILDREN_DATA_FAILURE]: (state: MonitorState, payload) => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      data: expandReducerChildren(state.playersBets.data, payload.betId, null, ACTION_STATUSES.FAILED, payload.error, 'BetId')
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_EXPAND_BETS_ROW_CHILDREN_DATA]: (state: MonitorState, payload) => ({
    ...state,
    playersBets: {
      ...state.playersBets,
      data: expandReducerChildren(state.playersBets.data, payload.betId, null, null, null, 'BetId')
    }
  }),

  // Player SuspiciousActivity Transactions
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_TRANSACTIONS]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialTransactions: {
      ...state.playerFinancialTransactions,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialTransactions: {
      status: ACTION_STATUSES.SUCCEED,
      totalCount: payload.totalCount,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialTransactions: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_FINANCIAL_TRANSACTIONS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialTransactions: initialSectionState()
  }),
  // Player SuspiciousActivity Balances
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_BALANCES]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialBalances: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_BALANCES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialBalances: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINANCIAL_BALANCES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerFinancialBalances: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_FINANCIAL_BALANCES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerFinancialBalances: initialSectionState()
  }),
  // Player Force Calculation
  [PLAYER_ACTION_TYPES.FORCE_PLAYER_FINANCIAL_CALCULATION]: (state: PlayerState): PlayerState => ({
    ...state,
    playerForceCalculation: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FORCE_PLAYER_FINANCIAL_CALCULATION_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerForceCalculation: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FORCE_PLAYER_FINANCIAL_CALCULATION_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerForceCalculation: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_FORCE_PLAYER_FINANCIAL_CALCULATION_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerForceCalculation: initialSectionState()
  }),

  // Player change calculation state
  [PLAYER_ACTION_TYPES.PLAYER_CHANGE_CALCULATION_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerChangeCalculationState: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_PLAYER_CHANGE_CALCULATION_STATE_SUCCESS]: (state: PlayerState): PlayerState => ({
    ...state,
    playerChangeCalculationState: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_PLAYER_CHANGE_CALCULATION_STATE_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerChangeCalculationState: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_CHANGE_CALCULATION_STATE_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerChangeCalculationState: initialActionReducerWithoutData()
  })
};

export default {
  actionManager,
  initialState
};
