import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

const PlayerDeepView = React.lazy(() => import('./views/pages/PlayerDeepView'));
const Players = React.lazy(() => import('./views/pages/PlayerList'));

const subUrl = settings.PRODUCTS.RMT.path;

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>
};

const modulePath = `${subUrl}/players`;

const ROUTES: Array<Route> = [
  { path: `/${modulePath}/:id/:tab`, name: 'Player/Tab', exact: true, page: PlayerDeepView },
  { path: `/${modulePath}/:id`,
    name: 'Player Deep View',
    exact: true,
    page: () => {
      const params = window.location.pathname.split('/');
      return <Redirect to={`/${modulePath}/${params[params.length - 1]}/general`} />;
    } },
  { path: `/${modulePath}`, name: 'Players', exact: true, page: Players }
];

export default ROUTES;
