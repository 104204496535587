/**
 * @flow
 * */
import { put, takeEvery } from 'redux-saga/effects';

import { fetchHowToFailed, fetchHowToSucceed } from '../../store/actions/howTo';
import HOW_TO_ACTION_TYPES from '../../store/actions/howTo/types';
import howToSDK from '../sdk/auth/HowToSDK';

function* fetchFile({ payload }) {
  try {
    const { data } = yield howToSDK.fetchFile({ fileName: payload });
    yield put(fetchHowToSucceed({ data }));
  } catch (err) {
    if (err.name === 'AbortError') return;
    yield put(fetchHowToFailed(err.message));
  }
}

function* cancelAmlSDKCalls({ payload }) {
  const { name } = payload;
  yield howToSDK.cancelHowToCalls({ name });
}

function* authControllerWatcher<T>(): Iterable<T> {
  yield takeEvery(HOW_TO_ACTION_TYPES.FETCH_HOW_TO, fetchFile);
  yield takeEvery(HOW_TO_ACTION_TYPES.CANCEL_HOW_TO, cancelAmlSDKCalls);
}

export default authControllerWatcher;
