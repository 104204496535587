/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import COMMENTS_TEMPLATE_ACTION_TYPES from 'store/actions/commentsTemplate/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { CommentsTemplateState } from 'store/types';

const initialState = (): CommentsTemplateState => ({
  status: null,
  error: null,
  data: null
});

const actionManager = {

  // get comments Template
  [COMMENTS_TEMPLATE_ACTION_TYPES.FETCH_COMMENTS_TEMPLATE]: (state: CommentsTemplateState) => ({
    ...state,
    status: ACTION_STATUSES.PENDING
  }),
  [COMMENTS_TEMPLATE_ACTION_TYPES.SET_FETCH_COMMENTS_TEMPLATE_SUCCESS]: (state: CommentsTemplateState, payload) => ({
    ...state,
    data: payload.data,
    status: ACTION_STATUSES.SUCCEED,
    error: null
  }),
  [COMMENTS_TEMPLATE_ACTION_TYPES.SET_FETCH_COMMENTS_TEMPLATE_FAILURE]: (state: CommentsTemplateState, payload) => ({
    ...state,
    data: null,
    status: ACTION_STATUSES.FAILED,
    error: payload.error
  })
};

export default (state : CommentsTemplateState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
