/**
 * @flow
 * */

import { all } from 'redux-saga/effects';

import { controllers as dashboardControllers } from 'modules/Dashboard';
import { controllers as adminControllers } from 'modules/Admin';
import { controllers as lcrmtControllers } from 'modules/LCRMT';
import { controllers as srmtControllers } from 'modules/SRMT';
import { controllers as amlControllers } from 'modules/AML';

import playerGeneralDataController from './PlayerGeneralDataController';
import commentsTemplateController from './CommentsTemplateController';
import UserSettingsController from './UserSettingsController';
import appSettingsController from './AppSettingsController';
import applicationController from './applicationController';
import permissionController from './PermissionController';
import landingController from './landingController';
import filterController from './FiltersController';
import exportController from './ExportController';
import PlayerController from './PlayerController';
// forClIImport

export default function* rootSaga(): Iterator<Object> {
  yield all([
    // forClIControllers
    filterController(),
    UserSettingsController(),
    playerGeneralDataController(),
    landingController(),
    permissionController(),
    appSettingsController(),
    PlayerController(),
    applicationController(),
    commentsTemplateController(),
    exportController(),
    ...lcrmtControllers,
    ...dashboardControllers,
    ...srmtControllers,
    ...amlControllers,
    ...adminControllers
  ]);
}
