import createStore from 'lib/core/createStore';
import combinedReducers from 'store/reducers';
import controllers from 'core/controllers';

const REF = 'GENERAL_STORE_REF';

const getGeneralStore = () => window[REF].getState();
const createGeneralStore = () => createStore(REF, combinedReducers, controllers);

export {
  createGeneralStore,
  getGeneralStore
};
