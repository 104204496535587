/**
 * @flow
 * */

import { initialSectionWithEditState } from 'lib/core/initialStates';
import type { PlayerState } from 'modules/SRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import {
  initialSectionWithoutData,
  initialSectionState
} from 'store/reducers/initialStates';

import PLAYER_ACTION_TYPES from '../actions/player/types';
import restrictionReducer from './restrictionReducer';
import playerBetsReducer from './playerBetsReducer';
import financialReducer from './financialReducer';
import categoryReducer from './categoryReducer';
import generalReducer from './generalReducer';
import alertReducer from './alertReducer';
import noteReducer from './noteReducer';

const initialState = (): PlayerState => ({
  players: initialSectionWithEditState(),
  globalKPIs: initialSectionState(),
  setPriority: initialSectionWithoutData(),
  removePriority: initialSectionWithoutData(),
  monitoring: {
    existingAlert: initialSectionState(),
    setAlert: initialSectionWithoutData()
  },
  ...restrictionReducer.initialState,
  ...noteReducer.initialState,
  ...categoryReducer.initialState,
  ...financialReducer.initialState,
  ...generalReducer.initialState,
  ...alertReducer.initialState,
  ...playerBetsReducer.initialState
});

const actionManager = {
  // global KPIs
  [PLAYER_ACTION_TYPES.FETCH_GLOBAL_KPIS]: (state: PlayerState) => ({
    ...state,
    globalKPIs: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GLOBAL_KPIS_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    globalKPIs: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GLOBAL_KPIS_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    globalKPIs: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_GLOBAL_KPIS_STATE]: (state: PlayerState) => ({
    ...state,
    globalKPIs: initialSectionState()
  }),

  // PlayerList
  [PLAYER_ACTION_TYPES.FETCH_PLAYERS]: (state: PlayerState): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYERS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.players.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYERS_APPEND_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.SUCCEED,
      data: [
        ...state.players.data,
        ...payload.data
      ],
      totalCount: payload.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYERS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYERS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    players: initialSectionWithEditState()
  }),

  // Monitoring Existing Alert
  [PLAYER_ACTION_TYPES.FETCH_EXISTING_ALERT]: (state: PlayerState): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      existingAlert: {
        status: ACTION_STATUSES.PENDING,
        error: null,
        data: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_EXISTING_ALERT_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      existingAlert: {
        status: ACTION_STATUSES.SUCCEED,
        data: payload.data,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_EXISTING_ALERT_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      existingAlert: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_EXISTING_ALERT_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      existingAlert: initialSectionState()
    }
  }),

  // Monitoring Set Alert
  [PLAYER_ACTION_TYPES.FETCH_SET_ALERT]: (state: PlayerState): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      setAlert: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_SET_ALERT_SUCCESS]: (state: PlayerState): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      setAlert: {
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_SET_ALERT_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      setAlert: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_SET_ALERT_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    monitoring: {
      ...state.monitoring,
      setAlert: initialSectionWithoutData()
    }
  }),

  // setTopPriority
  [PLAYER_ACTION_TYPES.FETCH_SET_TOP_PRIORITY]: (state: PlayerState) => ({
    ...state,
    setPriority: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FORCE_SET_TOP_PRIORITY_SUCCESS]: (state: PlayerState) => ({
    ...state,
    setPriority: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FORCE_SET_TOP_PRIORITY_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    setPriority: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_FORCE_SET_TOP_PRIORITY_STATE]: (state: PlayerState) => ({
    ...state,
    setPriority: initialSectionWithoutData()
  }),

  // removePriority
  [PLAYER_ACTION_TYPES.FETCH_REMOVE_TOP_PRIORITY]: (state: PlayerState) => ({
    ...state,
    removePriority: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FORCE_REMOVE_TOP_PRIORITY_SUCCESS]: (state: PlayerState) => ({
    ...state,
    removePriority: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FORCE_REMOVE_TOP_PRIORITY_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    removePriority: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_FORCE_REMOVE_TOP_PRIORITY_STATE]: (state: PlayerState) => ({
    ...state,
    removePriority: initialSectionWithoutData()
  }),

  ...financialReducer.actionManager,
  ...noteReducer.actionManager,
  ...generalReducer.actionManager,
  ...restrictionReducer.actionManager,
  ...categoryReducer.actionManager,
  ...alertReducer.actionManager,
  ...playerBetsReducer.actionManager
};

export default (state: PlayerState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
