const iconColors = {
  defaultValueColor: '#3c4043',
  blueIconColor: '#1473e6',
  blue2IconColor: '#5fb4f6',
  blue3IconColor: '#3ec3ef',
  blue4IconColor: '#207ede',
  blue5IconColor: '#7FDDE9',
  greenIconColor: '#7dc681',
  green2IconColor: '#abd47c',
  green3IconColor: '#47b4a9',
  green4IconColor: '#46904a',
  yellowIconColor: '#dbe670',
  yellow2IconColor: '#ffe76b',
  yellow3IconColor: '#fdc625',
  orangeIconColor: '#ffad5f',
  orange2IconColor: '#ffca5f',
  orange3IconColor: '#F48A3A',
  redIconColor: '#e03f33',
  red2IconColor: '#df2f4d',
  pinkIconColor: '#ef5d8f',
  pink2IconColor: '#da5cac',
  pink3IconColor: '#B10F78',
  pink4IconColor: '#DB8E94',
  pink5IconColor: '#CD1D59',
  purpleIconColor: '#b763c6',
  purple2IconColor: '#7481c9',
  purple3IconColor: '#9171cb',
  purple4IconColor: '#9FA8DA',
  brownIconColor: '#b26c72',
  brown2IconColor: '#824f5d',
  brown3IconColor: '#8E4148'
};

export const rouletteNumberColors = {
  0: '#62af45',
  32: '#353736',
  15: '#dd2f27',
  19: '#dd2f27',
  4: '#353736',
  21: '#dd2f27',
  2: '#353736',
  25: '#dd2f27',
  17: '#dd2f27',
  34: '#353736',
  6: '#353736',
  27: '#dd2f27',
  13: '#dd2f27',
  36: '#353736',
  11: '#dd2f27',
  30: '#353736',
  8: '#353736',
  23: '#dd2f27',
  10: '#353736',
  5: '#dd2f27',
  24: '#353736',
  16: '#353736',
  33: '#dd2f27',
  1: '#dd2f27',
  20: '#353736',
  14: '#353736',
  31: '#dd2f27',
  9: '#dd2f27',
  22: '#353736',
  18: '#353736',
  29: '#dd2f27',
  7: '#dd2f27',
  28: '#353736',
  12: '#353736',
  35: '#dd2f27',
  3: '#dd2f27',
  26: '#353736'
};

export default iconColors;
