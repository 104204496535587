/**
 * @flow
 * */
import NetworkService from 'core/services/network/NetworkService';
import type { WithTotalCountAndData } from 'types';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';

/**
   * @class ReportsSDK
   */
class ReportsSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
   * fetchClientReport
   * @param {Number} count -
   * @param {Number}start -
   * @param {boolean}passTotalCount -
   * @param {Object} filters -
   * @returns {Promise<*>} -
   */
    async fetchClientReport({ count, start, passTotalCount, filters }: Object): Promise<WithTotalCountAndData<Object>> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Reports/ClientReport',
        { query_params: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('fetchClientReport') }
      );
      return body.Data;
    }

    /**
   * fetchAlertReport
   * @param {Number} count -
   * @param {Number}start -
   * @param {Boolean}passTotalCount -
   * @param {Object} filters -
   * @returns {Promise<*>} -
   */
    async fetchAlertReport({ count, start, passTotalCount, filters }: Object): Promise<WithTotalCountAndData<Object>> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Reports/AlertHistoryReport',
        { query_params: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('AlertHistoryReport') }
      );
      return body.Data;
    }

    /**
   * fetchPlayerBlockStatusReport
   * @param {Number} count -
   * @param {Number}start -
   * @param {Boolean}passTotalCount -
   * @param {Object} filters -
   * @returns {Promise<*>} -
   */
    async fetchPlayerBlockStatusReport({ count, start, passTotalCount, filters }: Object): Promise<WithTotalCountAndData<Object>> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Reports/BlockHistoryReport',
        { query_params: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('BlockHistoryReport') }
      );
      return body.Data;
    }

    /**
   * cancelAlertSDKCalls
   * @param {Object} params -
   * @returns {Object} -
   */
    cancelAlertSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new ReportsSDK();
