/**
 * @flow
 * */
import type { PartnerManagementState } from 'modules/Admin/PartnerLicenseManagement/types';
import ACTION_TYPES from 'modules/Admin/PartnerLicenseManagement/store/actions/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import {
  initialSectionWithEditState,
  initialSectionWithoutData,
  initialSectionState
} from 'lib/core/initialStates';

const partnerLicenseManageState = () => ({
  data: null,
  rowData: null
});

const initialState = (): PartnerManagementState => ({
  partnerLicenseManagementData: partnerLicenseManageState(),
  partnerManagementData: initialSectionWithEditState(),
  templates: initialSectionState(),
  amlTemplates: initialSectionState(),
  partners: initialSectionState(),
  srmtData: initialSectionState(),
  amlData: initialSectionState(),
  allAmlSettings: initialSectionState(),
  riskTypes: initialSectionState(),
  setChangedData: initialSectionWithoutData(),
  deactivatePartner: initialSectionWithoutData()
});

const actionManager = {

  // partner Management
  [ACTION_TYPES.FETCH_MANAGEMENT_LIST]: (state: PartnerManagementState) => ({
    ...state,
    partnerManagementData: {
      ...state.partnerManagementData,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTION_TYPES.FETCH_MANAGEMENT_LIST_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    partnerManagementData: {
      ...state.partnerManagementData,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.partnerManagementData.totalCount,
      error: null
    }
  }),
  [ACTION_TYPES.FETCH_MANAGEMENT_LIST_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    partnerManagementData: {
      ...state.partnerManagementData,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTION_TYPES.CLEAR_MANAGEMENT_LIST]: (state: PartnerManagementState) => ({
    ...state,
    partnerManagementData: initialSectionWithEditState()
  }),

  // Partner/License Management
  [ACTION_TYPES.FETCH_PARTNERLICENSE_MANAGEMENT]: (state: PartnerManagementState, payload) => ({
    ...state,
    partnerLicenseManagementData: {
      ...state.partnerLicenseManagementData,
      data: payload.data
    }
  }),
  [ACTION_TYPES.CLEAR_PARTNERLICENSE_MANAGEMENT]: (state: PartnerManagementState) => ({
    ...state,
    partnerLicenseManagementData: partnerLicenseManageState()
  }),

  // Partner Management Selected Row
  [ACTION_TYPES.FETCH_PARTNER_MANAGEMENT_SELECTED_ROW]: (state: PartnerManagementState, payload) => ({
    ...state,
    partnerLicenseManagementData: {
      ...state.partnerLicenseManagementData,
      rowData: payload.data
    }
  }),

  // partners
  [ACTION_TYPES.FETCH_PARTNERS]: (state: PartnerManagementState) => ({
    ...state,
    partners: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [ACTION_TYPES.SET_PARTNERS_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    partners: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [ACTION_TYPES.SET_PARTNERS_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    partners: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_PARTNERS_STATE]: (state: PartnerManagementState) => ({
    ...state,
    partners: initialSectionState()
  }),

  // Deactivate Partner
  [ACTION_TYPES.FETCH_DEACTIVATE_PARTNER]: (state: PartnerManagementState) => ({
    ...state,
    deactivatePartner: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTION_TYPES.SET_DEACTIVATE_PARTNER_SUCCESS]: (state: PartnerManagementState) => ({
    ...state,
    deactivatePartner: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [ACTION_TYPES.SET_DEACTIVATE_PARTNER_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    deactivatePartner: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_DEACTIVATE_PARTNER_STATE]: (state: PartnerManagementState) => ({
    ...state,
    deactivatePartner: initialSectionWithoutData()
  }),

  // srmt data
  [ACTION_TYPES.FETCH_SRMT_DATA]: (state: PartnerManagementState) => ({
    ...state,
    srmtData: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [ACTION_TYPES.SET_SRMT_DATA_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    srmtData: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [ACTION_TYPES.SET_SRMT_DATA_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    srmtData: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_SRMT_DATA_STATE]: (state: PartnerManagementState) => ({
    ...state,
    srmtData: initialSectionState()
  }),

  // templates
  [ACTION_TYPES.FETCH_TEMPLATES]: (state: PartnerManagementState) => ({
    ...state,
    templates: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [ACTION_TYPES.SET_TEMPLATES_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    templates: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [ACTION_TYPES.SET_TEMPLATES_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    templates: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_TEMPLATES_STATE]: (state: PartnerManagementState) => ({
    ...state,
    templates: initialSectionState()
  }),

  // aml templates
  [ACTION_TYPES.FETCH_AML_TEMPLATES]: (state: PartnerManagementState) => ({
    ...state,
    amlTemplates: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [ACTION_TYPES.SET_AML_TEMPLATES_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    amlTemplates: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [ACTION_TYPES.SET_AML_TEMPLATES_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    amlTemplates: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_AML_TEMPLATES_STATE]: (state: PartnerManagementState) => ({
    ...state,
    amlTemplates: initialSectionState()
  }),

  // aml data
  [ACTION_TYPES.FETCH_AML_DATA]: (state: PartnerManagementState) => ({
    ...state,
    amlData: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [ACTION_TYPES.SET_AML_DATA_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    amlData: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [ACTION_TYPES.SET_AML_DATA_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    amlData: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_AML_DATA_STATE]: (state: PartnerManagementState) => ({
    ...state,
    amlData: initialSectionState()
  }),

  // partners
  [ACTION_TYPES.FETCH_ALL_AML_SETTINGS]: (state: PartnerManagementState) => ({
    ...state,
    allAmlSettings: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [ACTION_TYPES.SET_ALL_AML_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    allAmlSettings: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [ACTION_TYPES.SET_ALL_AML_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    allAmlSettings: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_ALL_AML_STATE]: (state: PartnerManagementState) => ({
    ...state,
    allAmlSettings: initialSectionState()
  }),

  // risk types
  [ACTION_TYPES.FETCH_RISK_TYPE_SETTINGS]: (state: PartnerManagementState) => ({
    ...state,
    riskTypes: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [ACTION_TYPES.SET_RISK_TYPE_SUCCESS]: (state: PartnerManagementState, payload) => ({
    ...state,
    riskTypes: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [ACTION_TYPES.SET_RISK_TYPE_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    riskTypes: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_RISK_TYPE_STATE]: (state: PartnerManagementState) => ({
    ...state,
    riskTypes: initialSectionState()
  }),

  // set changed data
  [ACTION_TYPES.SET_CHANGED_DATA]: (state: PartnerManagementState) => ({
    ...state,
    setChangedData: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTION_TYPES.SET_CHANGED_DATA_SUCCESS]: (state: PartnerManagementState) => ({
    ...state,
    setChangedData: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [ACTION_TYPES.SET_CHANGED_DATA_FAILURE]: (state: PartnerManagementState, payload) => ({
    ...state,
    setChangedData: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [ACTION_TYPES.CLEAR_CHANGED_DATA_STATE]: (state: PartnerManagementState) => ({
    ...state,
    setChangedData: initialSectionWithoutData()
  })
};

export default (state: PartnerManagementState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
