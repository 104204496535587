/**
 * @flow
 * */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initApp } from 'store/actions/application';
import { appStateSelector } from 'store/selectors/applicationSelectors';

/**
 * useInitApp
 * @return {Object} appState -
 */

const useInitApp = () => {
  const dispatch = useDispatch();
  const appState = useSelector(appStateSelector);

  const initialize = useCallback(() => {
    if (!appState.isReady) {
      dispatch(initApp());
    }
  }, [dispatch, appState.isReady]);

  useEffect(initialize, [dispatch, appState]);

  return { appState };
};

export default useInitApp;
