import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createStatisticsSymbol = createSymbol('ROULETTE_STATISTICS');
const createStatisticsInternalSymbol = createInternalSymbol('ROULETTE_STATISTICS');

export default {
  // statistics
  FETCH_STATISTICS: createStatisticsSymbol('FETCH_STATISTICS'),
  SET_FETCH_STATISTICS_SUCCESS: createStatisticsSymbol('SET_FETCH_STATISTICS_SUCCESS'),
  SET_FETCH_STATISTICS_FAILURE: createStatisticsSymbol('SET_FETCH_STATISTICS_FAILURE'),
  CLEAR_STATISTICS_STATE: createStatisticsInternalSymbol('CLEAR_STATISTICS_STATE'),

  // statistics for Table
  FETCH_TABLE_STATISTICS: createStatisticsSymbol('FETCH_TABLE_STATISTICS'),
  SET_FETCH_TABLE_STATISTICS_SUCCESS: createStatisticsSymbol('SET_FETCH_TABLE_STATISTICS_SUCCESS'),
  SET_FETCH_TABLE_STATISTICS_FAILURE: createStatisticsSymbol('SET_FETCH_TABLE_STATISTICS_FAILURE'),
  CLEAR_TABLE_STATISTICS_STATE: createStatisticsInternalSymbol('CLEAR_TABLE_STATISTICS_STATE'),

  // roulette statistics
  FETCH_ROULETTE_STATISTICS: createStatisticsSymbol('FETCH_ROULETTE_STATISTICS'),
  SET_FETCH_ROULETTE_STATISTICS_SUCCESS: createStatisticsSymbol('SET_FETCH_ROULETTE_STATISTICS_SUCCESS'),
  SET_FETCH_ROULETTE_STATISTICS_FAILURE: createStatisticsSymbol('SET_FETCH_ROULETTE_STATISTICS_FAILURE'),
  CLEAR_ROULETTE_STATISTICS_STATE: createStatisticsInternalSymbol('CLEAR_ROULETTE_STATISTICS_STATE'),

  // roulette biased statistics
  FETCH_ROULETTE_BIASED_STATISTICS: createStatisticsSymbol('FETCH_ROULETTE_BIASED_STATISTICS'),
  SET_FETCH_ROULETTE_BIASED_STATISTICS_SUCCESS: createStatisticsSymbol('SET_FETCH_ROULETTE_BIASED_STATISTICS_SUCCESS'),
  SET_FETCH_ROULETTE_BIASED_STATISTICS_FAILURE: createStatisticsSymbol('SET_FETCH_ROULETTE_BIASED_STATISTICS_FAILURE'),
  CLEAR_ROULETTE_BIASED_STATISTICS_STATE: createStatisticsInternalSymbol('CLEAR_ROULETTE_BIASED_STATISTICS_STATE'),

  // card statistics
  FETCH_CARD_STATISTICS: createStatisticsSymbol('FETCH_CARD_STATISTICS'),
  SET_FETCH_CARD_STATISTICS_SUCCESS: createStatisticsSymbol('SET_FETCH_CARD_STATISTICS_SUCCESS'),
  SET_FETCH_CARD_STATISTICS_FAILURE: createStatisticsSymbol('SET_FETCH_CARD_STATISTICS_FAILURE'),
  CLEAR_CARD_STATISTICS_STATE: createStatisticsInternalSymbol('CLEAR_CARD_STATISTICS_STATE'),

  // time range
  FETCH_TIME_RANGE: createStatisticsSymbol('FETCH_TIME_RANGE'),
  SET_FETCH_TIME_RANGE_SUCCESS: createStatisticsSymbol('SET_FETCH_TIME_RANGE_SUCCESS'),
  SET_FETCH_TIME_RANGE_FAILURE: createStatisticsSymbol('SET_FETCH_TIME_RANGE_FAILURE'),
  CLEAR_TIME_RANGE_STATE: createStatisticsInternalSymbol('CLEAR_TIME_RANGE_STATE'),

  // statistics table
  FETCH_STATISTICS_TABLE_DATA: createStatisticsSymbol('FETCH_STATISTICS_TABLE_DATA'),
  SET_FETCH_STATISTICS_TABLE_DATA_SUCCESS: createStatisticsSymbol('SET_FETCH_STATISTICS_TABLE_DATA_SUCCESS'),
  SET_FETCH_STATISTICS_TABLE_DATA_FAILURE: createStatisticsSymbol('SET_FETCH_STATISTICS_TABLE_DATA_FAILURE'),
  CLEAR_STATISTICS_TABLE_DATA_STATE: createStatisticsInternalSymbol('CLEAR_STATISTICS_TABLE_DATA_STATE'),

  // cancel call
  CANCEL_STATISTIC_SDK_CALL: createStatisticsSymbol('CANCEL_STATISTIC_SDK_CALL')
};
