import config from 'config';

import { AutomatedCommentsActionParams, AutomatedCommentsModel } from 'modules/Admin/AutomatedComments/core/sdk/types';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import { WithTotalCount } from 'types';

class AutomatedCommentsSDK extends BaseSDK {
    _rmtApiService: NetworkService = null;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
     * getAutomatedComments
     * @param {Object }params -
     * @returns {Promise<Object>} -
     */
    async getAutomatedComments(params): { data: WithTotalCount<Array<AutomatedCommentsModel>> } {
      const { count, start, passTotalCount } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'CommentTemplate/Get',
        { query_params: { count, start, passTotalCount }, abortController: this.setAbortController('getAutomatedComments') }
      );
      return body.Data;
    }

    /**
     * actionAutomatedComments
     * @param {Object }params -
     * @returns {Promise<Object>} -
     */
    async actionAutomatedComments(params: AutomatedCommentsActionParams): { data: AutomatedCommentsModel } {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'CommentTemplate/Update',
        { body: params }
      );
      return body.Data;
    }

    /**
     * actionAutomatedComments
     * @param {Object }params -
     * @returns {Promise<Object>} -
     */
    async deleteAutomatedComments(params): { data: AutomatedCommentsModel } {
      const { id } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        `CommentTemplate/Delete/${id}`,
        { body: params }
      );
      return body.Data;
    }

    /**
     * cancelAutomatedCommentsSDKCalls
     * @param {Object} params -
     * @returns {Promise<{data: Object}>} -
     */
    cancelAutomatedCommentsSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new AutomatedCommentsSDK();
