/**
 * @flow
 * */
import type { Action } from 'lib/core/flow/types';

import COMMENTS_TEMPLATE_ACTION_TYPES from './types';

type ActionType = {
  data?: any,
  error?: string,
}

export const fetchCommentsTemplate = (): Action => ({
  type: COMMENTS_TEMPLATE_ACTION_TYPES.FETCH_COMMENTS_TEMPLATE
});
export const setFetchCommentsTemplateSuccess = ({ data }: ActionType): Action => ({
  type: COMMENTS_TEMPLATE_ACTION_TYPES.SET_FETCH_COMMENTS_TEMPLATE_SUCCESS,
  payload: { data }
});
export const setFetchCommentsTemplateFailure = ({ error }: ActionType): Action => ({
  type: COMMENTS_TEMPLATE_ACTION_TYPES.SET_FETCH_COMMENTS_TEMPLATE_FAILURE,
  payload: { error }
});
