/**
 * @flow
 * */

import PLAYER_ACTION_TYPES from 'modules/SRMT/Players/store/actions/player/types';
import { initialSectionWithCountState } from 'store/reducers/initialStates';
import type { MonitorState } from 'modules/SRMT/AlertMonitor/types';
import type { PlayerState } from 'modules/SRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import {
  makeAlertPlayersBetsExpandChildrenReducerData,
  makeAlertPlayersBetsExpandReducerData
} from 'modules/SRMT/Players/utils/alertPlayersBetsExpandReducerChildren';

const initialState = {
  alertPlayersBets: initialSectionWithCountState()
};
const actionManager = {
  // Alert Player Bets
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS]: (state: PlayerState): PlayerState => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alertPlayersBets: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.alertPlayersBets.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_FAILED]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),

  // Alert Player Bets Expand
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND]: (state: MonitorState, payload) => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      data: makeAlertPlayersBetsExpandReducerData({
        allData: state.alertPlayersBets.data,
        payload,
        payloadKey: 'playerId',
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null,
        dataKey: 'ClientId',
        hasTotal: false
      })
    }
  }),
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      data: makeAlertPlayersBetsExpandReducerData({
        allData: state.alertPlayersBets.data,
        payload,
        payloadKey: 'playerId',
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        dataKey: 'ClientId',
        hasTotal: true,
        passTotalCount: payload.passTotalCount
      })
    }
  }),
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND_FAILED]: (state: MonitorState, payload) => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      data: makeAlertPlayersBetsExpandReducerData({
        allData: state.alertPlayersBets.data,
        payload,
        payloadKey: 'playerId',
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        dataKey: 'ClientId',
        hasTotal: false
      })
    }
  }),

  // Alert Player Bets Expand Children
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN]: (state: MonitorState, payload) => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      data: makeAlertPlayersBetsExpandChildrenReducerData({
        allData: state.alertPlayersBets.data,
        payload,
        payloadKey: 'playerId',
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null,
        dataKey: 'ClientId'
      })
    }
  }),
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      data: makeAlertPlayersBetsExpandChildrenReducerData({
        allData: state.alertPlayersBets.data,
        payload,
        payloadKey: 'playerId',
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        dataKey: 'ClientId'
      })
    }
  }),
  [PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_FAILED]: (state: MonitorState, payload) => ({
    ...state,
    alertPlayersBets: {
      ...state.alertPlayersBets,
      data: makeAlertPlayersBetsExpandChildrenReducerData({
        allData: state.alertPlayersBets.data,
        payload,
        payloadKey: 'playerId',
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        dataKey: 'ClientId'
      })
    }
  })

};

export default {
  actionManager,
  initialState
};
