/**
 * @flow
 * */
/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';

const Navigation = React.lazy(() => import('./views/components/NavigationBar'));
const RolesList = React.lazy(() => import('./views/pages/RolesList'));
const Assign = React.lazy(() => import('./views/pages/Assign'));
const Users = React.lazy(() => import('./views/pages/Users'));

// if oyu are going to use lazy loader, you have to Suspense in Router Render method

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const modulePath = 'admin/user-management';

const ROUTES: Array<Route> = [
  { path: `/${modulePath}`, name: 'UserManagement', exact: true, page: () => <Redirect to={`/${modulePath}/users`} /> },
  { path: `/${modulePath}/assign`, name: 'Assign', exact: true, page: Assign, navigation: Navigation },
  { path: `/${modulePath}/roles`, name: 'Role Permission', exact: true, page: RolesList, navigation: Navigation },
  { path: `/${modulePath}/assign/:id`, name: 'Assign', exact: true, page: Assign, navigation: Navigation },
  { path: `/${modulePath}/users`, name: 'Users', exact: true, page: Users, navigation: Navigation }
];

export default ROUTES;
