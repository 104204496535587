import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createDashboardSymbol = createSymbol('LCRMT_DASHBOARD');
const createDashboardInternalSymbol = createInternalSymbol('LCRMT_DASHBOARD');

export default {

  // dashboard filter
  FETCH_LCRMT_DASHBOARD_FILTER: createDashboardSymbol('FETCH_LCRMT_DASHBOARD_FILTER'),
  SET_FETCH_LCRMT_DASHBOARD_FILTER_SUCCESS: createDashboardSymbol('SET_FETCH_LCRMT_DASHBOARD_FILTER_SUCCESS'),
  SET_FETCH_LCRMT_DASHBOARD_FILTER_FAILURE: createDashboardSymbol('SET_FETCH_LCRMT_DASHBOARD_FILTER_FAILURE'),
  CLEAR_LCRMT_DASHBOARD_FILTER_STATE: createDashboardInternalSymbol('CLEAR_LCRMT_DASHBOARD_FILTER_STATE'),

  // dashboard charts
  FETCH_LCRMT_DASHBOARD_CHARTS: createDashboardSymbol('FETCH_LCRMT_DASHBOARD_CHARTS'),
  SET_FETCH_LCRMT_DASHBOARD_CHARTS_SUCCESS: createDashboardSymbol('SET_FETCH_LCRMT_DASHBOARD_CHARTS_SUCCESS'),
  SET_FETCH_LCRMT_DASHBOARD_CHARTS_FAILURE: createDashboardSymbol('SET_FETCH_LCRMT_DASHBOARD_CHARTS_FAILURE'),
  CLEAR_LCRMT_DASHBOARD_CHARTS_STATE: createDashboardInternalSymbol('CLEAR_LCRMT_DASHBOARD_CHARTS_STATE'),

  // dashboard top players
  FETCH_LCRMT_DASHBOARD_TOP_PLAYERS: createDashboardSymbol('FETCH_LCRMT_DASHBOARD_TOP_PLAYERS'),
  SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_SUCCESS: createDashboardSymbol('SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_SUCCESS'),
  SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_FAILURE: createDashboardSymbol('SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_FAILURE'),
  CLEAR_LCRMT_DASHBOARD_TOP_PLAYERS_STATE: createDashboardInternalSymbol('CLEAR_LCRMT_DASHBOARD_TOP_PLAYERS_STATE'),

  // round info
  FETCH_ROUND_INFO: createDashboardSymbol('FETCH_ROUND_INFO'),
  SET_FETCH_ROUND_INFO_SUCCESS: createDashboardSymbol('SET_FETCH_ROUND_INFO_SUCCESS'),
  SET_FETCH_ROUND_INFO_FAILURE: createDashboardSymbol('SET_FETCH_ROUND_INFO_FAILURE'),
  CLEAR_ROUND_INFO_STATE: createDashboardInternalSymbol('CLEAR_ROUND_INFO_STATE'),

  // round info expand
  FETCH_ROUND_INFO_EXPAND: createDashboardSymbol('FETCH_ROUND_INFO_EXPAND'),
  SET_FETCH_ROUND_INFO_EXPAND_SUCCESS: createDashboardSymbol('SET_FETCH_ROUND_INFO_EXPAND_SUCCESS'),
  SET_FETCH_ROUND_INFO_EXPAND_FAILURE: createDashboardSymbol('SET_FETCH_ROUND_INFO_EXPAND_FAILURE'),

  // cancel call
  CANCEL_DASHBOARD_SDK_CALL: createDashboardSymbol('CANCEL_DASHBOARD_SDK_CALL')
};
