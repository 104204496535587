/**
 * @flow
 */
import React, { memo } from 'react';
import Modal from '@geneui/components/Modal';

import { modalConfig, positions } from 'config/settings/core-ui-strings';
// $FlowFixMe
import pdf from 'assets/pdf/Roulette_Unfairness_Detection_converted.pdf';
import './styles.scss';

type Props = {
  setIsOpen: Function,
  isOpen: boolean,
}

/**
 *
 * @param {boolean} isOpen - if true, the popup will be shown
 * @param {Function} setIsOpen - change visible status
 * @returns {Node} -
 * @constructor
 */

const HelpPopupContainer = ({ isOpen, setIsOpen }: Props) => (
  <Modal
    background={modalConfig.background.light}
    className="settings-popup-container"
    position={positions.center}
    visible={isOpen}
    title="LCRMT Help"
    width="95%"
    withPortal
    onCancel={() => setIsOpen(false)}
    closable
    closeOnClickOutside
    footer={<></>}
  >

    <embed
      src={pdf}
      style={{ width: '100%', height: '80vh' }}
    />
  </Modal>
);

HelpPopupContainer.displayName = 'HelpPopupContainer';

export default memo<Props>(HelpPopupContainer);
