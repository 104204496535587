import SETTINGS_ACTIONS from '../types';

// Partners
export const fetchChangeHistory = (filters) => ({
  type: SETTINGS_ACTIONS.FETCH_CHANGE_HISTORY,
  payload: { filters }
});
export const setFetchChangeHistorySuccess = ({ data, totalCount, passTotalCount }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_CHANGE_HISTORY_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchChangeHistoryFailure = (error) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_CHANGE_HISTORY_FAILURE,
  payload: { error }
});
export const clearChangeHistoryState = () => ({
  type: SETTINGS_ACTIONS.CLEAR_CHANGE_HISTORY_STATE
});
