import createSymbol from 'lib/core/createSymbol';

const createPartnerManagementSymbol = createSymbol('PARTNER_MANAGEMENT');

export default {
  // Partner Management Table
  FETCH_MANAGEMENT_LIST: createPartnerManagementSymbol('FETCH_MANAGEMENT_LIST'),
  FETCH_MANAGEMENT_LIST_SUCCESS: createPartnerManagementSymbol('FETCH_MANAGEMENT_LIST_SUCCESS'),
  FETCH_MANAGEMENT_LIST_FAILURE: createPartnerManagementSymbol('FETCH_MANAGEMENT_LIST_FAILURE'),
  CLEAR_MANAGEMENT_LIST: createPartnerManagementSymbol('CLEAR_MANAGEMENT_LIST'),

  // Partner/License Management State
  FETCH_PARTNERLICENSE_MANAGEMENT: createPartnerManagementSymbol('FETCH_PARTNERLICENSE_MANAGEMENT'),
  CLEAR_PARTNERLICENSE_MANAGEMENT: createPartnerManagementSymbol('CLEAR_PARTNERLICENSE_MANAGEMENT'),

  // Partner Management Selected Row
  FETCH_PARTNER_MANAGEMENT_SELECTED_ROW: createPartnerManagementSymbol('FETCH_PARTNER_MANAGEMENT_SELECTED_ROW'),

  // cancel calls
  CANCEL_PM_CALL: createPartnerManagementSymbol('CANCEL_PM_CALL'),

  // General Detail
  FETCH_PARTNERS: createPartnerManagementSymbol('FETCH_PARTNERS'),
  SET_PARTNERS_SUCCESS: createPartnerManagementSymbol('SET_PARTNERS_SUCCESS'),
  SET_PARTNERS_FAILURE: createPartnerManagementSymbol('SET_PARTNERS_FAILURE'),
  CLEAR_PARTNERS_STATE: createPartnerManagementSymbol('CLEAR_PARTNERS_STATE'),

  // Deactivate Partner
  FETCH_DEACTIVATE_PARTNER: createPartnerManagementSymbol('FETCH_DEACTIVATE_PARTNER'),
  SET_DEACTIVATE_PARTNER_SUCCESS: createPartnerManagementSymbol('SET_DEACTIVATE_PARTNER_SUCCESS'),
  SET_DEACTIVATE_PARTNER_FAILURE: createPartnerManagementSymbol('SET_DEACTIVATE_PARTNER_FAILURE'),
  CLEAR_DEACTIVATE_PARTNER_STATE: createPartnerManagementSymbol('CLEAR_DEACTIVATE_PARTNER_STATE'),

  // Srmt data
  FETCH_SRMT_DATA: createPartnerManagementSymbol('FETCH_SRMT_DATA'),
  SET_SRMT_DATA_SUCCESS: createPartnerManagementSymbol('SET_SRMT_DATA_SUCCESS'),
  SET_SRMT_DATA_FAILURE: createPartnerManagementSymbol('SET_SRMT_DATA_FAILURE'),
  CLEAR_SRMT_DATA_STATE: createPartnerManagementSymbol('CLEAR_SRMT_DATA_STATE'),

  // Srmt Templates
  FETCH_TEMPLATES: createPartnerManagementSymbol('FETCH_TEMPLATES'),
  SET_TEMPLATES_SUCCESS: createPartnerManagementSymbol('SET_TEMPLATES_SUCCESS'),
  SET_TEMPLATES_FAILURE: createPartnerManagementSymbol('SET_TEMPLATES_FAILURE'),
  CLEAR_TEMPLATES_STATE: createPartnerManagementSymbol('CLEAR_TEMPLATES_STATE'),

  // Aml Templates
  FETCH_AML_TEMPLATES: createPartnerManagementSymbol('FETCH_AML_TEMPLATES'),
  SET_AML_TEMPLATES_SUCCESS: createPartnerManagementSymbol('SET_AML_TEMPLATES_SUCCESS'),
  SET_AML_TEMPLATES_FAILURE: createPartnerManagementSymbol('SET_AML_TEMPLATES_FAILURE'),
  CLEAR_AML_TEMPLATES_STATE: createPartnerManagementSymbol('CLEAR_AML_TEMPLATES_STATE'),

  // Aml data
  FETCH_AML_DATA: createPartnerManagementSymbol('FETCH_AML_DATA'),
  SET_AML_DATA_SUCCESS: createPartnerManagementSymbol('SET_AML_DATA_SUCCESS'),
  SET_AML_DATA_FAILURE: createPartnerManagementSymbol('SET_AML_DATA_FAILURE'),
  CLEAR_AML_DATA_STATE: createPartnerManagementSymbol('CLEAR_AML_DATA_STATE'),

  // All AML Settings
  FETCH_ALL_AML_SETTINGS: createPartnerManagementSymbol('FETCH_ALL_AML_SETTINGS'),
  SET_ALL_AML_SUCCESS: createPartnerManagementSymbol('SET_ALL_AML_SUCCESS'),
  SET_ALL_AML_FAILURE: createPartnerManagementSymbol('SET_ALL_AML_FAILURE'),
  CLEAR_ALL_AML_STATE: createPartnerManagementSymbol('CLEAR_ALL_AML_STATE'),

  // Country Risk Type
  FETCH_RISK_TYPE_SETTINGS: createPartnerManagementSymbol('FETCH_RISK_TYPE_SETTINGS'),
  SET_RISK_TYPE_SUCCESS: createPartnerManagementSymbol('SET_RISK_TYPE_SUCCESS'),
  SET_RISK_TYPE_FAILURE: createPartnerManagementSymbol('SET_RISK_TYPE_FAILURE'),
  CLEAR_RISK_TYPE_STATE: createPartnerManagementSymbol('CLEAR_RISK_TYPE_STATE'),

  // Country Risk Type
  SET_CHANGED_DATA: createPartnerManagementSymbol('SET_CHANGED_DATA'),
  SET_CHANGED_DATA_SUCCESS: createPartnerManagementSymbol('SET_CHANGED_DATA_SUCCESS'),
  SET_CHANGED_DATA_FAILURE: createPartnerManagementSymbol('SET_CHANGED_DATA_FAILURE'),
  CLEAR_CHANGED_DATA_STATE: createPartnerManagementSymbol('CLEAR_CHANGED_DATA_STATE')
};
