import { CheckType } from 'modules/Admin/PartnerLicenseManagement/types';
import CacheService from 'core/services/storage/CacheService';

const initRules = [
  {
    'RiskCategory': 1,
    'RiskCategoryName': 'Low',
    'isActive': true,
    'IsLocked': false,
    'CanBet': true,
    'CanDeposit': true,
    'CanIncreaseLimit': true,
    'CanLogin': true,
    'CanWithdraw': true,
    'IsCasinoBlocked': false,
    'IsNoBonus': false,
    'IsSportBlocked': false
  },
  {
    'RiskCategory': 2,
    'RiskCategoryName': 'Medium',
    'isActive': true,
    'isLocked': false,
    'CanBet': true,
    'CanDeposit': true,
    'CanIncreaseLimit': true,
    'CanLogin': true,
    'CanWithdraw': true,
    'IsCasinoBlocked': false,
    'IsNoBonus': false,
    'IsSportBlocked': false
  },
  {
    'RiskCategory': 3,
    'RiskCategoryName': 'High',
    'isActive': false,
    'Blocked': false,
    'CanBet': true,
    'CanDeposit': true,
    'CanIncreaseLimit': true,
    'CanLogin': true,
    'CanWithdraw': true,
    'IsCasinoBlocked': false,
    'IsNoBonus': false,
    'IsSportBlocked': false
  },
  {
    'RiskCategory': 4,
    'RiskCategoryName': 'Very High',
    'isActive': false,
    'Blocked': false,
    'CanBet': true,
    'CanDeposit': true,
    'CanIncreaseLimit': true,
    'CanLogin': true,
    'CanWithdraw': true,
    'IsCasinoBlocked': false,
    'IsNoBonus': false,
    'IsSportBlocked': false
  }
];

export const checkRuleTypeByCheckType = (item) => {
  let ruleType = 'Amount';
  if (item.CheckType === CheckType.cumulative && item.Id === 128) {
    ruleType = 'Percent';
  } else if (item.CheckType === CheckType.countable) {
    ruleType = 'Count';
  }
  return ruleType;
};

export const checkRuleType = (item) => {
  let ruleType = 'Amount';
  if (item.RuleConfigs && item.RuleConfigs.length) {
    for (let j = 0; j < item.RuleConfigs.length; j++) {
      if ('CountFrom' in item.RuleConfigs[j] || 'CountTo' in item.RuleConfigs[j]) {
        ruleType = 'Count';
        break;
      }
      if ('PercentFrom' in item.RuleConfigs[j] || 'PercentTo' in item.RuleConfigs[j]) {
        ruleType = 'Percent';
        break;
      }
    }
  }
  return ruleType;
};

export const makeSkeletonRuleConfig = (item) => {
  const rules = [];
  const type = checkRuleTypeByCheckType(item);
  initRules.forEach((i, index) => {
    rules.push({
      ...i,
      ...item.RuleConfigs ? item.RuleConfigs[index] : null,
      [`${type}From`]: null,
      [`${type}To`]: null
    });
  });
  return rules;
};

export const RuleConfigInitialData = (i, item) => {
  const ruleType = checkRuleTypeByCheckType(item);
  return {
    [`${ruleType}From`]: null,
    [`${ruleType}To`]: null,
    RiskCategory: i.id,
    RiskCategoryName: i.name,
    isActive: false,
    CanIncreaseLimit: true,
    CanBet: true,
    CanDeposit: true,
    CanLogin: true,
    CanWithdraw: true,
    IsCasinoBlocked: false,
    IsNoBonus: false,
    IsLocked: false,
    IsSportBlocked: false,
    Score: 0
  };
};

export const ScoreRuleCategoryInitialData = (i) => ({
  From: null,
  RiskClasifiation: i.id,
  RiskClasifiationName: i.name,
  To: null,
  IsActive: false,
  Restriction: {
    Blocked: false,
    CanBet: true,
    CanDeposit: true,
    CanIncreaseLimit: true,
    CanLogin: true,
    CanWithdraw: true,
    IsCasinoBlocked: false,
    IsNoBonus: false,
    IsSportBlocked: false
  }
});

export const AmlRuleScoreRuleCategoryInitialData = (i) => ({
  CanBet: true,
  CanDeposit: true,
  CanIncreaseLimit: true,
  CanLogin: true,
  CanWithdraw: true,
  IsCasinoBlocked: false,
  IsNoBonus: false,
  IsLocked: false,
  IsSportBlocked: false,
  From: null,
  RiskClasifiation: i.id,
  RiskClasifiationName: i.name,
  To: null,
  IsActive: false
});

export const RgRuleScoreRuleCategoryInitialData = (i) => ({
  CanBet: true,
  CanDeposit: true,
  CanIncreaseLimit: true,
  CanLogin: true,
  CanWithdraw: true,
  IsCasinoBlocked: false,
  IsNoBonus: false,
  IsLocked: false,
  IsSportBlocked: false,
  From: null,
  RiskClasifiation: i.id,
  RiskClasifiationName: i.name,
  To: null,
  IsActive: false
});

export const makeRule = ({ gamingControl, item, ruleEnabled = true }) => ({
  'AmlRuleId': item.Id,
  'RuleName': item.Name,
  'RuleEnabled': ruleEnabled,
  'GamingControl': gamingControl,
  'Description': item.Description,
  'RuleConfigs': makeSkeletonRuleConfig(item)
});

export const makeRules = ({ newData, existData, gamingControl }) => {
  const makeNewData = [];
  newData.forEach(item => {
    const hasData = existData.find(i => i.AmlRuleId === item.Id && i.GamingControl === gamingControl);
    if (hasData) {
      makeNewData.push(hasData);
    } else {
      makeNewData.push(makeRule({ gamingControl, item }));
    }
  });
  return makeNewData;
};

const initialRestrictionData = {
  IsLocked: false,
  CanBet: true,
  CanDeposit: true,
  CanIncreaseLimit: true,
  CanLogin: true,
  CanWithdraw: true,
  IsCasinoBlocked: false,
  IsNoBonus: false,
  IsSportBlocked: false
};

export const initialCategorySettings = [
  {
    RiskClasifiation: 1,
    RiskClasifiationName: 'Low',
    From: null,
    To: null,
    Restriction: initialRestrictionData
  },
  {
    RiskClasifiation: 2,
    RiskClasifiationName: 'Medium',
    From: null,
    To: null,
    Restriction: initialRestrictionData
  },
  {
    RiskClasifiation: 3,
    RiskClasifiationName: 'High',
    From: null,
    To: null,
    Restriction: initialRestrictionData
  },
  {
    RiskClasifiation: 4,
    RiskClasifiationName: 'Very High',
    From: null,
    To: null,
    Restriction: initialRestrictionData
  }
];

export const makeAmlData = (data: Object) => {
  CacheService.setJson('all_rules', data.AllRules);
  // eslint-disable-next-line no-param-reassign
  delete data.AllRules;
  if (!Object.values(data).some(i => !!i)) return null;
  return data;
};

export const makeSrmtData = (data) => {
  if (!Object.values(data).some(i => !!i)) return null;
  return data;
};

export const singleRulesInitialData = (id, name, description, ruleType = 1) => ({
  'AmlRuleId': id,
  'RuleName': name,
  'Description': description,
  'AmlSingleThresholdAlertType': ruleType,
  'OneTimeGeneration': false,
  'Restriction': {
    'CanIncreaseLimit': true,
    'CanBet': true,
    'CanDeposit': true,
    'CanLogin': true,
    'CanWithdraw': true
  },
  'Enabled': true,
  'Amount': null,
  'PaymentSystemIds': []
});
