import PLAYER_ACTIONS from 'modules/LCRMT/Players/store/actions/player/types';
import type { Action } from 'lib/core/flow';

// gaming kpi
export const fetchLCRMTPlayerFinancialGamingKPI = ({ partnerId, clientId, filter }):Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI,
  payload: { partnerId, clientId, filter }
});
export const fetchLCRMTPlayerFinancialGamingKPISucceed = ({ data }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_SUCCESS,
  payload: { data }
});
export const fetchLCRMTPlayerFinancialGamingKPIFailed = ({ error }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_FAILURE,
  payload: { error }
});
export const clearLCRMTPlayerFinancialGamingKPI = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_STATE,
  payload: {}
});

// gaming table
export const fetchLCRMTPlayerFinancialGamingTable = ({ partnerId, clientId, filter }):Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE,
  payload: { partnerId, clientId, filter }
});
export const fetchLCRMTPlayerFinancialGamingTableSucceed = ({ data }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_SUCCESS,
  payload: { data }
});
export const fetchLCRMTPlayerFinancialGamingTableFailed = ({ error }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_FAILURE,
  payload: { error }
});
export const clearLCRMTPlayerFinancialGamingTable = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_STATE,
  payload: {}
});

// activity
export const fetchLCRMTPlayerFinancialActivity = ({ partnerId, clientId, filter }):Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY,
  payload: { partnerId, clientId, filter }
});
export const fetchLCRMTPlayerFinancialActivitySucceed = ({ data }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_SUCCESS,
  payload: { data }
});
export const fetchLCRMTPlayerFinancialActivityFailed = ({ error }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_FAILURE,
  payload: { error }
});
export const clearLCRMTPlayerFinancialActivity = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_PLAYER_FINANCIAL_ACTIVITY_STATE,
  payload: {}
});

// transactions kpi
export const fetchLCRMTPlayerFinancialTransactionsKPI = ({ partnerId, clientId, filter }):Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI,
  payload: { partnerId, clientId, filter }
});
export const fetchLCRMTPlayerFinancialTransactionsKPISucceed = ({ data }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_SUCCESS,
  payload: { data }
});
export const fetchLCRMTPlayerFinancialTransactionsKPIFailed = ({ error }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_FAILURE,
  payload: { error }
});
export const clearLCRMTPlayerFinancialTransactionsKPI = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_STATE,
  payload: {}
});

// transactions table
export const fetchLCRMTPlayerFinancialTransactionsTable = ({ partnerId, clientId, filter }):Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE,
  payload: { partnerId, clientId, filter }
});
export const fetchLCRMTPlayerFinancialTransactionsTableSucceed = ({ data }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_SUCCESS,
  payload: { data }
});
export const fetchLCRMTPlayerFinancialTransactionsTableFailed = ({ error }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_FAILURE,
  payload: { error }
});
export const clearLCRMTPlayerFinancialTransactionsTable = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_STATE,
  payload: {}
});
