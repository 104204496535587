/**
 * @flow
 * */
import { takeLatest, put } from 'redux-saga/effects';

import { parseAMLModelFromResponse, parseAMLPlayerMultiAccountResponse } from 'modules/AML/utils/parsers';
import ACTION_TYPES from 'modules/AML/store/actions/types';
import {
  setFetchAmlPlayerAlertsBulkReviewSuccess, setFetchAmlPlayerAlertsBulkReviewFailure,
  fetchReviewHistoryExpandedDataFailure, fetchReviewHistoryExpandedDataSuccess,
  setFetchAmlAlertsReviewHistoryFailure, setFetchAmlAlertsReviewHistorySuccess,
  setFetchAmlPlayerHistoryReportFailure, setFetchAmlPlayerHistoryReportSuccess,
  setFetchAmlRiskCategoryHistoryFailure, setFetchAmlRiskCategoryHistorySuccess,
  setFetchPlayerScoreRulesActionFailure, setFetchPlayerScoreRulesActionSuccess,
  setFetchAmlPlayerScoreHistoryFailure, setFetchAmlPlayerScoreHistorySuccess,
  setFetchAmlAlertsReviewExpandSuccess, setFetchAmlAlertsReviewExpandFailure,
  setFetchBettingHourlyActivitySuccess, setFetchBettingHourlyActivityFailure,
  setFetchAmlPlayerScoreReportFailure, setFetchAmlPlayerScoreReportSuccess,
  setFetchAmlAlertsBulkReviewFailure, setFetchAmlAlertsBulkReviewSuccess,
  setFetchPlayerScoreRulesFailure, setFetchPLayerAlertsBulkReviewSuccess,
  setFetchAmlRuleScoreReportSuccess, setFetchAmlRuleScoreReportFailure,
  setFetchAmlAlertsReviewEditSuccess, setFetchAmlAlertsReviewFailure,
  setFetchAmlAlertsReviewEditFailure, setFetchAmlAlertsReviewSuccess,
  setFetchAmlPlayersFailure, setFetchAmlPlayersMultiAccountsFailure,
  setFetchAmlPlayersMultiAccountsSuccess, setFetchAmlPlayersSuccess,
  setTransactionActivitySuccess, setFetchTransactionActivityFailure,
  setFetchPlayerScoreRulesSuccess, setFetchAmlPlayerAlertsFailure,
  setFetchTransactionsFailure, setFetchTransactionsSuccess,
  setFetchAmlDashboardSuccess, setFetchAmlDashboardFailure,
  setFetchAmlPlayerAlertSuccess, setFetchAmlAlertsFailure,
  setFetchAmlAlertsSuccess, setChangeScoreCountFailure,
  setChangeScoreCountSuccess, fetchExpandedDataFailure,
  fetchExpandedDataSuccess, setFetchConfigsFailure,
  setFetchConfigsSuccess, setFetchBoxesFailure,
  setFetchBoxesSuccess, updateChangeScoreCount
} from 'modules/AML/store/actions';

import amlSDK from '../sdk';

class AMlController {
  static* fetchDashboard({ payload }) {
    try {
      const { data } = yield amlSDK.fetchDashboard(payload);
      yield put(setFetchAmlDashboardSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAmlDashboardFailure({ error: err.message }));
    }
  }

  static* fetchTransactions({ payload }) {
    const { passTotalCount } = payload.filters;
    try {
      const response = yield amlSDK.fetchTransactions(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchTransactionsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchTransactionsFailure({ error: err.message }));
    }
  }

  static* fetchPlayers({ payload }) {
    const { passTotalCount } = payload.filters;
    try {
      const response = yield amlSDK.fetchPlayers(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchAmlPlayersSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAmlPlayersFailure({ error: err.message }));
    }
  }

  static* fetchPlayerAlerts({ payload }) {
    try {
      const response = yield amlSDK.fetchPlayerAlerts(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchAmlPlayerAlertSuccess({ data, totalCount }));
    } catch (err) {
      yield put(setFetchAmlPlayerAlertsFailure({ error: err.message }));
    }
  }

  static* fetchTransactionActivity({ payload }) {
    try {
      const data = yield amlSDK.fetchAnalytics(payload);
      yield put(setTransactionActivitySuccess({ data }));
    } catch (err) {
      yield put(setFetchTransactionActivityFailure({ error: err.message }));
    }
  }

  static* fetchBettingHourlyActivity({ payload }) {
    try {
      const data = yield amlSDK.fetchAnalytics(payload);
      yield put(setFetchBettingHourlyActivitySuccess({ data }));
    } catch (err) {
      yield put(setFetchBettingHourlyActivityFailure({ error: err.message }));
    }
  }

  static* fetchPlayerAlertsBulkReview({ payload }) {
    const alertIds = payload.filters.alerts;
    try {
      yield amlSDK.updatePlayerAlertsBulkReview(payload);
      yield put(setFetchAmlPlayerAlertsBulkReviewSuccess());
      yield put(setFetchPLayerAlertsBulkReviewSuccess({ alertIds }));
    } catch (err) {
      yield put(setFetchAmlPlayerAlertsBulkReviewFailure({ error: err.message }));
    }
  }

  static* fetchBoxes() {
    try {
      const { data } = yield amlSDK.fetchBoxes();
      yield put(setFetchBoxesSuccess({ data }));
    } catch (err) {
      yield put(setFetchBoxesFailure({ error: err.message }));
    }
  }

  static* fetchConfig({ payload }) {
    try {
      const { license } = payload;
      const { data } = yield amlSDK.fetchConfigs(payload);
      yield put(setFetchConfigsSuccess({ data, license }));
    } catch (err) {
      yield put(setFetchConfigsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerRuleScores({ payload }) {
    try {
      const { data } = yield amlSDK.fetchPlayerRuleScores(payload);
      yield put(setFetchPlayerScoreRulesSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerScoreRulesFailure({ error: err.message }));
    }
  }

  static* fetchChangeScoreCount({ payload }) {
    try {
      const { data } = yield amlSDK.fetchChangeScoreCount(payload);
      yield put(setChangeScoreCountSuccess({ data, ruleId: payload.ruleId }));
      yield put(updateChangeScoreCount({ count: data, ruleId: payload.ruleId }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setChangeScoreCountFailure({ error: err.message }));
    }
  }

  static* fetchPlayerRuleScoresAction({ payload }) {
    try {
      const { data } = yield amlSDK.fetchPlayerRuleScoresAction(payload);
      yield put(setFetchPlayerScoreRulesActionSuccess({ data }));
      yield put(updateChangeScoreCount({ count: 0, ruleId: payload.ruleId, enabled: payload.enabled }));
    } catch (err) {
      yield put(setFetchPlayerScoreRulesActionFailure({ error: err.message }));
    }
  }

  static* fetchPlayerBlockHistoryReport({ payload }) {
    const { passTotalCount } = payload.filters;
    try {
      const response = yield amlSDK.fetchPlayerBlockHistoryReport(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchAmlPlayerHistoryReportSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAmlPlayerHistoryReportFailure({ error: err.message }));
    }
  }

  static* fetchPlayerScoreReport({ payload }) {
    const { passTotalCount } = payload.filters;
    try {
      const response = yield amlSDK.fetchPlayerScoreReport(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchAmlPlayerScoreReportSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAmlPlayerScoreReportFailure({ error: err.message }));
    }
  }

  static* fetchAmlRuleScoreReport({ payload }) {
    const { passTotalCount } = payload.filters;
    try {
      const response = yield amlSDK.fetchAmlRuleScoreReport(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchAmlRuleScoreReportSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAmlRuleScoreReportFailure({ error: err.message }));
    }
  }

  static* fetchAmlAlertsReview({ payload }) {
    const { tabKey } = payload;
    try {
      const { Data: data } = yield amlSDK.fetchAmlAlertsReview(payload);
      yield put(setFetchAmlAlertsReviewSuccess({ data, tabKey }));
    } catch (err) {
      yield put(setFetchAmlAlertsReviewFailure({ error: err.message, tabKey }));
    }
  }

  static* fetchAmlAlertsReviewExpand({ payload }) {
    const { tabKey, sessionId } = payload;
    try {
      const { Data: data } = yield amlSDK.fetchAmlAlertsReviewExpand(payload);
      yield put(setFetchAmlAlertsReviewExpandSuccess({ data, tabKey, sessionId }));
    } catch (err) {
      yield put(setFetchAmlAlertsReviewExpandFailure({ error: err.message, tabKey, sessionId }));
    }
  }

  static* fetchAmlAlertsReviewHistory({ payload }) {
    const { tabKey } = payload;
    try {
      const { Data: data } = yield amlSDK.fetchAmlAlertsReviewHistory(payload);
      yield put(setFetchAmlAlertsReviewHistorySuccess({ data, tabKey }));
    } catch (err) {
      yield put(setFetchAmlAlertsReviewHistoryFailure({ error: err.message, tabKey }));
    }
  }

  static* fetchAmlAlerts({ payload }) {
    const { tabKey } = payload;
    const { passTotalCount } = payload.filters;
    try {
      const response = yield amlSDK.fetchAmlAlerts(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchAmlAlertsSuccess({ data, totalCount, tabKey, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAmlAlertsFailure({ error: err.message, tabKey }));
    }
  }

  static* fetchAmlExpandedData({ payload }) {
    const { tabKey, alertId } = payload;
    try {
      const data = yield amlSDK.fetchAmlExpandedData(payload);
      yield put(fetchExpandedDataSuccess({ data, tabKey, alertId }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchExpandedDataFailure({ error: err.message, tabKey, alertId }));
    }
  }

  static* fetchAmlReviewHistoryExpandedData({ payload }) {
    const { tabKey, alertId } = payload;
    try {
      const data = yield amlSDK.fetchAmlAlertsReviewHistoryExpand(payload);
      yield put(fetchReviewHistoryExpandedDataSuccess({ data, tabKey, alertId }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchReviewHistoryExpandedDataFailure({ error: err.message, tabKey, alertId }));
    }
  }

  static* fetchAmlAlertsBulkReview({ payload }) {
    const { tabKey } = payload;
    try {
      const data = yield amlSDK.fetchAmlAlertsBulkReview(payload);
      yield put(setFetchAmlAlertsBulkReviewSuccess({ tabKey, data }));
    } catch (err) {
      yield put(setFetchAmlAlertsBulkReviewFailure({ error: err.message, tabKey }));
    }
  }

  static* fetchAmlAlertsReviewEdit({ payload }) {
    const { tabKey } = payload;
    try {
      const data = yield amlSDK.fetchAmlAlertsReviewEdit(payload);
      yield put(setFetchAmlAlertsReviewEditSuccess({ tabKey, data }));
    } catch (err) {
      yield put(setFetchAmlAlertsReviewEditFailure({ error: err.message, tabKey }));
    }
  }

  static* fetchPlayerMultiAccount({ payload }) {
    try {
      const response = yield amlSDK.fetchPlayerMultiAccount(payload);
      const { data } = parseAMLPlayerMultiAccountResponse(response);
      yield put(setFetchAmlPlayersMultiAccountsSuccess({ data }));
    } catch (err) {
      yield put(setFetchAmlPlayersMultiAccountsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerRuleScoreHistory({ payload }) {
    try {
      const response = yield amlSDK.fetchPlayerRuleScoreHistory(payload);
      const { data, totalCount } = parseAMLModelFromResponse(response);
      yield put(setFetchAmlPlayerScoreHistorySuccess({ data, totalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAmlPlayerScoreHistoryFailure({ error: err.message }));
    }
  }

  static* fetchAmlAlertsRiskCategoryHistory({ payload }) {
    const { tabKey } = payload;
    try {
      const { Data: data } = yield amlSDK.fetchAmlAlertsRiskCategoryHistory(payload);
      yield put(setFetchAmlRiskCategoryHistorySuccess({ data, tabKey }));
    } catch (err) {
      yield put(setFetchAmlRiskCategoryHistoryFailure({ error: err.message, tabKey }));
    }
  }

  static* cancelAmlSDKCalls({ payload }) {
    const { name } = payload;
    yield amlSDK.cancelAmlSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND, AMlController.fetchAmlReviewHistoryExpandedData);
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERT_RISK_CATEGORY, AMlController.fetchAmlAlertsRiskCategoryHistory);
    yield takeLatest(ACTION_TYPES.FETCH_PLAYER_BLOCK_HISTORY_REPORT, AMlController.fetchPlayerBlockHistoryReport);
    yield takeLatest(ACTION_TYPES.FETCH_AML_PLAYER_ALERTS_BULK_REVIEW, AMlController.fetchPlayerAlertsBulkReview);
    yield takeLatest(ACTION_TYPES.FETCH_BETTING_HOURLY_ACTIVITY_DATA, AMlController.fetchBettingHourlyActivity);
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_HISTORY, AMlController.fetchAmlAlertsReviewHistory);
    yield takeLatest(ACTION_TYPES.FETCH_PLAYER_SCORE_RULE_ACTION, AMlController.fetchPlayerRuleScoresAction);
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_EXPAND, AMlController.fetchAmlAlertsReviewExpand);
    yield takeLatest(ACTION_TYPES.FETCH_AML_PLAYERS_MULTI_ACCOUNTS, AMlController.fetchPlayerMultiAccount);
    yield takeLatest(ACTION_TYPES.FETCH_TRANSACTION_ACTIVITY_DATA, AMlController.fetchTransactionActivity);
    yield takeLatest(ACTION_TYPES.FETCH_PLAYER_SCORE_HISTORY, AMlController.fetchPlayerRuleScoreHistory);
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERTS_BULK_REVIEW, AMlController.fetchAmlAlertsBulkReview);
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_EDIT, AMlController.fetchAmlAlertsReviewEdit);
    yield takeLatest(ACTION_TYPES.FETCH_AML_RULE_SCORE_REPORT, AMlController.fetchAmlRuleScoreReport);
    yield takeLatest(ACTION_TYPES.FETCH_PLAYER_SCORE_REPORT, AMlController.fetchPlayerScoreReport);
    yield takeLatest(ACTION_TYPES.FETCH_CHANGE_SCORE_COUNT, AMlController.fetchChangeScoreCount);
    yield takeLatest(ACTION_TYPES.FETCH_PLAYER_SCORE_RULE, AMlController.fetchPlayerRuleScores);
    yield takeLatest(ACTION_TYPES.FETCH_AML_EXPANDED_DATA, AMlController.fetchAmlExpandedData);
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERTS_REVIEW, AMlController.fetchAmlAlertsReview);
    yield takeLatest(ACTION_TYPES.FETCH_AML_PLAYER_ALERTS, AMlController.fetchPlayerAlerts);
    yield takeLatest(ACTION_TYPES.FETCH_TRANSACTIONS, AMlController.fetchTransactions);
    yield takeLatest(ACTION_TYPES.FETCH_AML_DASHBOARD, AMlController.fetchDashboard);
    yield takeLatest(ACTION_TYPES.CANCEL_AML_CALL, AMlController.cancelAmlSDKCalls);
    yield takeLatest(ACTION_TYPES.FETCH_AML_ALERTS, AMlController.fetchAmlAlerts);
    yield takeLatest(ACTION_TYPES.FETCH_AML_PLAYERS, AMlController.fetchPlayers);
    yield takeLatest(ACTION_TYPES.FETCH_CONFIGS, AMlController.fetchConfig);
    yield takeLatest(ACTION_TYPES.FETCH_BOXES, AMlController.fetchBoxes);
  }
}

export default AMlController.actionsWatcher;
