/**
 * @flow
 * */
import React, { useState, useMemo, useRef, useCallback } from 'react';
import Icon from '@geneui/components/Icon';

import useOnClickOutside from 'hooks/useOnClickOutside';
import usePermission from 'hooks/usePermission';
import Link from 'react-router-dom/es/Link';
import products from './config';

import './styles.scss';

const Apps = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const popoverRef = useRef(null);
  const iconRef = useRef(null);
  const { hasRead } = usePermission();

  const activeProducts = useMemo(() => products.filter(({ permission }) => !permission || hasRead(permission)), [products, hasRead]);

  const getSize = useMemo(() => {
    if (activeProducts.length === 3) return 316;
    if (activeProducts.length === 2) return 220;
    if (activeProducts.length === 1) return 130;
    return 400;
  }, [activeProducts]);

  const closePopover = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const onClockHandler = useCallback(({ main, handler }) => {
    if (!main) {
      handler();
    }
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  useOnClickOutside(popoverRef, closePopover, iconRef);

  return (
    <div className="apps">
      <div ref={iconRef}>
        <Icon onClick={() => setIsModalOpen(!isModalOpen)} className="x-icon" type="bc-icon-apps" style={{ color: 'white' }} />
      </div>
      {isModalOpen && (
        <div className="apps-popup" style={{ width: getSize }} ref={popoverRef}>
          {activeProducts.map(({ id, img, text, main, path, handler, icon }, index) => (
            <div key={id} className="products">
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div onClick={() => onClockHandler({ main, handler })}>
                <Link to={path} onClick={(event) => !path && event.preventDefault()}>
                  {icon ? <div className="apps-icon"><img alt="info" src={icon} /></div> : <img className="products-img" alt="info" src={img} />}
                  <p className="products-text">{text}</p>
                </Link>
              </div>
              {activeProducts.length > 4 && (index === 0 || index === 1 || index === 2 || index === 3) && <div className="apps-popup-line" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Apps;
