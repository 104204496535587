/**
 * @flow
 * */
import type { Action, MinimalReducer } from 'lib/core/flow';
import PLAYER_ACTION_TYPES from 'store/actions/export/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { AppSettingsState } from 'types';

export type ApplicationState = {
  ...MinimalReducer,
  data?: any,
  fileType?: string
};

const initialState = (): ApplicationState => ({
  status: null,
  error: null,
  data: null,
  fileType: null
});

const actionManager = {
  // table Export
  [PLAYER_ACTION_TYPES.FETCH_EXPORT_DATA]: (state: ApplicationState, payload) => ({
    ...state,
    status: ACTION_STATUSES.PENDING,
    error: null,
    fileType: payload.fileType
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_EXPORT_DATA_SUCCESS]: (state: ApplicationState, payload) => ({
    ...state,
    status: ACTION_STATUSES.SUCCEED,
    data: payload.data,
    error: null,
    fileType: payload.fileType
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_EXPORT_DATA_FAILURE]: (state: ApplicationState, payload) => ({
    ...state,
    status: ACTION_STATUSES.FAILED,
    error: payload.error,
    data: null
  }),
  [PLAYER_ACTION_TYPES.CLEAR_EXPORT_STATE]: () => initialState()
};

export default (state : AppSettingsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
