/**
 * @flow
 * */
import React, { memo } from 'react';
import Tag from '@geneui/components/Tag';

import { tagConfig } from 'config/settings/core-ui-strings';
import { generateNameColor } from './generateNameColor';

type Props = {
    tagName: string
};

const GameStyleCategoryTag = ({ tagName }: Props) => {
  const { name, color } = generateNameColor(tagName);
  return (
    <Tag
      name={name}
      color={color}
      size={tagConfig.size.medium}
      appearance={tagConfig.appearance.light}
      cornerRadius={tagConfig.cornerRadius.smoothRadius}
      flexibility={tagConfig.flexibility.fullWidth}
    />
  );
};

export default memo<Props>(GameStyleCategoryTag);
