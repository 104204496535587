/**
 * @flow
 * */

import type { Action } from 'lib/core/flow';
import ACTIONS from './types';

// User Player Reports
export const fetchUserPlayerReports = ({ count, start, passTotalCount, filters }: any): Action => ({
  type: ACTIONS.FETCH_USER_PLAYER_REPORTS,
  payload: { count, start, passTotalCount, filters }
});
export const setFetchUserPlayerReportsSuccess = ({ data, totalCount, passTotalCount }: any): Action => ({
  type: ACTIONS.SET_FETCH_USER_PLAYER_REPORTS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchUserPlayerReportsFailure = ({ error }: any): Action => ({
  type: ACTIONS.SET_FETCH_USER_PLAYER_REPORTS_FAILURE,
  payload: { error }
});
export const clearUserPlayerReportsState = (): Action => ({
  type: ACTIONS.CLEAR_USER_PLAYER_REPORTS_STATE
});

// Alert Reports
export const fetchAlertReports = ({ count, start, passTotalCount, filters }: any): Action => ({
  type: ACTIONS.FETCH_ALERT_REPORTS,
  payload: { count, start, passTotalCount, filters }
});
export const setFetchAlertReportsSuccess = ({ data, totalCount, passTotalCount }: any): Action => ({
  type: ACTIONS.SET_FETCH_ALERT_REPORTS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAlertReportsFailure = ({ error }: any): Action => ({
  type: ACTIONS.SET_FETCH_ALERT_REPORTS_FAILURE,
  payload: { error }
});
export const clearAlertReportsState = (): Action => ({
  type: ACTIONS.CLEAR_ALERT_REPORTS_STATE
});

// Player Block Status Reports
export const fetchPlayerBlockStatusReports = ({ count, start, passTotalCount, filters }: any): Action => ({
  type: ACTIONS.FETCH_PLAYER_BLOCK_STATUS_REPORTS,
  payload: { count, start, passTotalCount, filters }
});
export const setFetchPlayerBlockStatusReportsSuccess = ({ data, totalCount, passTotalCount }: any): Action => ({
  type: ACTIONS.SET_FETCH_PLAYER_BLOCK_STATUS_REPORTS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchPlayerBlockStatusReportsFailure = ({ error }: any): Action => ({
  type: ACTIONS.SET_FETCH_PLAYER_BLOCK_STATUS_REPORTS_FAILURE,
  payload: { error }
});
export const clearPlayerBlockStatusReportsState = (): Action => ({
  type: ACTIONS.CLEAR_PLAYER_BLOCK_STATUS_STATE
});

// cancel report call
export const cancelReportCall = ({ name }:any): Action => ({
  type: ACTIONS.CANCEL_REPORT_CALL,
  payload: { name }
});
