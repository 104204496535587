/**
 * @flow
 * */
/* eslint-disable camelcase */

import { createSelector } from 'reselect';
import type { FilterReducer, HolderStatus } from 'types/FilterModels';

const getFilterState: Object = (state: any) => state.filter;

export const filterHolderStatusSelector: () => HolderStatus = createSelector(
  getFilterState,
  (state: any): HolderStatus => state.holderStatus
);

export const selectedFilterSelector = (filterId: Object) => () => createSelector(
  getFilterState,
  (state: any) => state.selectedFilters[filterId]
);

export const filterSelector = (filters: any) => createSelector(
  getFilterState,
  (state: FilterReducer) => {
    const filtersData = {
      status: state.data?.status,
      error: state.data?.error
    };
    // eslint-disable-next-line no-return-assign
    filters.map((key) => filtersData[key] = state.data[key] || null);
    return filtersData;
  }
);

export const allFiltersSelector = createSelector(
  getFilterState,
  (state: FilterReducer) => state.data
);

export const filterStatusSelector = createSelector(
  getFilterState,
  (state: FilterReducer) => state.filterStatus
);

export const filterUsersSelector = createSelector(
  getFilterState,
  (state: FilterReducer) => state.filterUsers
);
