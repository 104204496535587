import type { Action } from 'lib/core/flow';

import PLAYER_GROUP_ACTIONS from './types';

// PlayerGroups
export const fetchPlayerGroups = ({ count, start, passTotalCount, ...filters }): Action => ({
  type: PLAYER_GROUP_ACTIONS.FETCH_PLAYER_GROUPS,
  payload: { count, start, filters, passTotalCount }
});
export const setFetchPlayerGroupsSuccess = ({ data, totalCount, passTotalCount }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_FETCH_PLAYER_GROUPS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchPlayerGroupsFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_FETCH_PLAYER_GROUPS_FAILURE,
  payload: { error }
});
export const clearPlayerGroupsState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_PLAYER_GROUPS_STATE
});

// Add Player Group
export const addPlayerGroup = ({ name, description, partnerId, filters }): Action => ({
  type: PLAYER_GROUP_ACTIONS.ADD_GROUP,
  payload: { name, description, partnerId, filters }
});
export const setAddPlayerGroupSuccess = (group): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_ADD_GROUP_SUCCESS,
  payload: group
});
export const setAddPlayerGroupFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_ADD_GROUP_FAILURE,
  payload: { error }
});
export const clearAddGroupState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_ADD_GROUP_STATE
});

// Get Group Info
export const fetchPlayerGroupInfo = ({ groupId }): Action => ({
  type: PLAYER_GROUP_ACTIONS.FETCH_GROUP_INFO,
  payload: { groupId }
});
export const setFetchPlayerGroupInfoSuccess = ({ data }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_GROUP_INFO_SUCCESS,
  payload: data
});
export const setFetchPlayerGroupInfoFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_GROUP_INFO_FAILURE,
  payload: { error }
});
export const clearPlayerGroupInfoState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_GROUP_INFO_STATE
});

// Update Player Group
export const updatePlayerGroup = ({ id, name, description }): Action => ({
  type: PLAYER_GROUP_ACTIONS.UPDATE_GROUP,
  payload: { id, name, description }
});
export const setUpdatePlayerGroupSuccess = (group): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_UPDATE_GROUP_SUCCESS,
  payload: group
});
export const setUpdatePlayerGroupFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_UPDATE_GROUP_FAILURE,
  payload: { error }
});
export const clearUpdateGroupState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_UPDATE_GROUP_STATE
});

// Delete Player Group
export const deletePlayerGroup = ({ groupId }): Action => ({
  type: PLAYER_GROUP_ACTIONS.DELETE_GROUP,
  payload: { groupId }
});
export const setDeletePlayerGroupSuccess = (payload): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_DELETE_GROUP_SUCCESS,
  payload
});
export const setDeletePlayerGroupFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_DELETE_GROUP_FAILURE,
  payload: { error }
});
export const clearDeleteGroupState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_DELETE_GROUP_STATE
});

// Group Players
export const fetchGroupPlayers = ({ groupId, count, start, passTotalCount }): Action => ({
  type: PLAYER_GROUP_ACTIONS.FETCH_GROUP_PLAYERS,
  payload: { groupId, count, start, passTotalCount }
});
export const setFetchGroupPlayersSuccess = ({ data, totalCount, passTotalCount }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_FETCH_GROUP_PLAYERS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchGroupPlayersFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_FETCH_GROUP_PLAYERS_FAILURE,
  payload: { error }
});
export const clearGroupPlayersState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_GROUP_PLAYERS_STATE
});

// Add Player To Group
export const addPlayerToGroup = ({ groupId, playerIds = [], description = null, filters = {} }): Action => ({
  type: PLAYER_GROUP_ACTIONS.ADD_PLAYER_TO_GROUP,
  payload: { groupId, playerIds, description, filters }
});
export const setAddPlayerToGroupSuccess = ({ data }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_ADD_PLAYER_TO_GROUP_SUCCESS,
  payload: { data }
});
export const setAddPlayerToGroupFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_ADD_PLAYER_TO_GROUP_FAILURE,
  payload: { error }
});
export const clearAddPlayerToGroupState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_ADD_PLAYER_TO_GROUP_STATE
});

// Add Player To Group Lists
export const addPlayerToGroupList = ({ playersList }): Action => ({
  type: PLAYER_GROUP_ACTIONS.ADD_PLAYER_TO_GROUP_LIST,
  payload: { playersList }
});

// delete Player To Group Lists
export const deletePlayerToGroupList = ({ playerIds }): Action => ({
  type: PLAYER_GROUP_ACTIONS.DELETE_PLAYER_TO_GROUP_LIST,
  payload: { playerIds }
});

// Delete Player From Group
export const deletePlayerFromGroup = ({ groupId, playerIds }): Action => ({
  type: PLAYER_GROUP_ACTIONS.DELETE_PLAYER_FROM_GROUP,
  payload: { groupId, playerIds }
});
export const setDeletePlayerFromGroupSuccess = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_DELETE_PLAYER_FROM_GROUP_SUCCESS
});
export const setDeletePlayerFromGroupFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_DELETE_PLAYER_FROM_GROUP_FAILURE,
  payload: { error }
});
export const clearDeletePlayerFromGroupState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_DELETE_PLAYER_FROM_GROUP_STATE
});

// cancel call
export const cancelPlayerGroupSdkCall = ({ name }): Action => ({
  type: PLAYER_GROUP_ACTIONS.CANCEL_PLAYER_GROUP_SDK_CALL,
  payload: { name }
});

// Group Action
export const groupAction = (actionData): Action => ({
  type: PLAYER_GROUP_ACTIONS.GROUP_ACTION,
  payload: actionData
});
export const setGroupActionSuccess = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_GROUP_ACTION_SUCCESS
});
export const setGroupActionFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_GROUP_ACTION_FAILURE,
  payload: { error }
});
export const clearGroupActionState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_GROUP_ACTION_STATE
});

// upload file
export const uploadFile = ({ formData }: any): Action => ({
  type: PLAYER_GROUP_ACTIONS.UPLOAD_FILE,
  payload: { formData }
});
export const setUploadFileSuccess = ({ data }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_UPLOAD_FILE_SUCCESS,
  payload: { data }
});
export const setUploadFileFailure = ({ error }): Action => ({
  type: PLAYER_GROUP_ACTIONS.SET_UPLOAD_FILE_FAILURE,
  payload: { error }
});
export const clearUploadFileState = (): Action => ({
  type: PLAYER_GROUP_ACTIONS.CLEAR_UPLOAD_FILE_STATE
});
