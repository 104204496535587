/**
 * @flow
 * */
/* eslint-disable camelcase */

import { createSelector } from 'reselect';

const getPermissionState = (state: Object) => state.userPermission;

export const getPermissionData: Function = createSelector(
  getPermissionState,
  (state: Object) => state.data || []
);
