/**
 * @flow
 * */

import NetworkService from 'core/services/network/NetworkService';
import type { FiltersDataModel } from 'types/FilterModels';
import config from 'config';

import type { FilterUsersDataModel, FilterUsersParams, FiltersParams } from './types';

/**
 * @class FilterSDK
 */
class FilterSDK {
    _rmtApiService: NetworkService;

    constructor() {
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * getFiltersData
     * @param {FiltersParams} params -
     * @returns {Promise<{data: FiltersDataModel}>} -
     */
    async getFiltersData(params: FiltersParams): Promise<{data: FiltersDataModel}> {
      const { filters, filterParams, caching } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Filter/GetFiltersData',
        { query_params: { filters, ...filterParams }, caching }
      );
      return {
        data: body.Data
      };
    }

    /**
   * fetchFilterUsers
   * @param {Object} params -
   * @returns {Promise<any>} -
   */
    async fetchFilterUsers(params: FilterUsersParams): Promise<{ data: Array<FilterUsersDataModel> }> {
      const { filters } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        `User/${'partnerId' in filters ? 'GetAllUsersOfPartner' : 'GetAllUsers'}`,
        { query_params: { ...filters } }
      );

      return {
        data: body.Data
      };
    }
}

export default new FilterSDK();
