import type { PlayerKPITreeModel } from 'modules/SRMT/Players/core/sdk/players/types';
import { KPITypes } from 'modules/SRMT/Players/views/components/KPICard/settings';

export const parsePlayersModelFromResponse = (response: Object) => {
  const { Data: { Models, TotalCount } } = response;
  return {
    data: Models,
    totalCount: TotalCount
  };
};

export const parsePlayersKPITreeModelFromResponse = (response: Object, withParents: boolean) => {
  let returningObject = {};

  response.forEach((row: PlayerKPITreeModel) => {
    returningObject[row.Key] = {
      key: row.Key,
      name: row.Name,
      showName: row.ShowName,
      regionId: row.BetKPI.RegionId,
      sportId: row.BetKPI.SportId,
      competitionId: row.BetKPI.CompetitionId,
      limits: {
        limit: row.Limit,
        delay: row.Delay
      },
      betKPIs: {
        [KPITypes.turnover]: row.BetKPI.TurnOver,
        [KPITypes.profit]: row.BetKPI.Profit,
        [KPITypes.percent]: row.BetKPI.PercentofProfite,
        [KPITypes.totalBets]: row.BetKPI.CalcBets,
        [KPITypes.averageBet]: row.BetKPI.AvgBets,
        [KPITypes.averageCoefficient]: row.BetKPI.AvgPrice
      },
      gameStyles: row.GameStyles,
      canOpen: row.HasChildren,
      isLoading: false,
      isLoadedChildren: withParents && row.Children.length > 0,
      isOpened: withParents && row.Children.length > 0,
      children: row.Children.map((children) => children.Key)
    };
    if (withParents) {
      returningObject = { ...returningObject, ...parsePlayersKPITreeModelFromResponse(row.Children, withParents) };
    }
  });
  return returningObject;
};

export const parseSRMTPlayerMultiAccountResponse = (response: Object) => {
  const { Data } = response;
  const newData = [];
  Data.forEach(item => {
    const row = newData.find(i => i.PlayerId === item.PlayerId);
    if (row) {
      row.MultiAccountTypes.push({ Name: item.TriggerName });
    } else {
      newData.push({
        ...item,
        MultiAccountTypes: [{ Name: item.TriggerName }]
      });
    }
  });
  return {
    data: newData
  };
};
