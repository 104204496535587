/**
 * @flow
 * */
import React, { Suspense } from 'react';
import type { Node } from 'react';
import { Switch, Route } from 'react-router-dom';

import Skeleton from 'views/components/loaders/Skeleton';

// types

type Props = {
    routes: Array<Object>,
}

export default ({ routes }: Props): Node => (
  <Switch>
    <Suspense
      fallback={<Skeleton isFillScreen center />}
    >
      {routes.map(item => (
        <Route
          path={item.path}
          exact={item.exact}
          key={item.name}
          component={item.page}
        />
      ))}
    </Suspense>
  </Switch>
);

export type propTypes = Props;
