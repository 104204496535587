/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import type { IFilterAction, FilterReducer, FilterStatus } from 'types/FilterModels';
import FILTER_ACTION_TYPES from 'store/actions/filter/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import filterCacheService from 'core/services/storage/FilterCacheService';
import { initialSectionState } from '../initialStates';

const filterState: FilterReducer = filterCacheService.getFilterState();

const createInitialFilterStatusState = (): FilterStatus => ({
  isApplyActive: false,
  isResetActive: false
});

const initialState = (): FilterReducer => ({
  ...filterState,
  filterStatus: createInitialFilterStatusState(),
  filterUsers: initialSectionState()
});

const actionManager = {
  // holder status
  [FILTER_ACTION_TYPES.SET_FILTER_HOLDER_STATUS]: (state: FilterReducer, action: IFilterAction): FilterReducer => ({
    ...state,
    holderStatus: filterCacheService.setHolderStatus(action.payload)
  }),

  // filter status
  [FILTER_ACTION_TYPES.SET_FILTER_STATUS]: (state: FilterReducer, action: IFilterAction): FilterReducer => ({
    ...state,
    filterStatus: {
      ...state.filterStatus,
      ...action.payload
    }
  }),
  [FILTER_ACTION_TYPES.CLEAR_FILTER_STATUS]: (state: FilterReducer): FilterReducer => ({
    ...state,
    filterStatus: createInitialFilterStatusState()
  }),

  // selected filters
  [FILTER_ACTION_TYPES.SET_SELECTED_FILTER]: (state: FilterReducer, action: IFilterAction): FilterReducer => ({
    ...state,
    selectedFilters: filterCacheService.setSelectedFilter(action.payload)
  }),
  [FILTER_ACTION_TYPES.CLEAR_SELECTED_FILTER]: (state: FilterReducer, action: IFilterAction): FilterReducer => ({
    ...state,
    selectedFilters: filterCacheService.clearSelectedFilter(action.payload)
  }),

  // fetch data
  [FILTER_ACTION_TYPES.FETCH_FILTERS_DATA]: (state: FilterReducer): FilterReducer => ({
    ...state,
    data: {
      ...state.data,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [FILTER_ACTION_TYPES.SET_FETCH_FILTERS_DATA_SUCCESS]: (state: FilterReducer, action: IFilterAction): FilterReducer => ({
    ...state,
    data: {
      ...state.data,
      ...action.payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [FILTER_ACTION_TYPES.SET_FETCH_FILTERS_DATA_FAILURE]: (state: FilterReducer, action: IFilterAction): FilterReducer => ({
    ...state,
    data: {
      ...state.data,
      status: ACTION_STATUSES.FAILED,
      error: action.payload.error
    }
  }),
  [FILTER_ACTION_TYPES.CLEAR_FILTERS_DATA]: (state: FilterReducer) => ({
    ...state,
    data: {
      ...state.data,
      status: null,
      error: null
    }
  }),
  // fetch filter users
  [FILTER_ACTION_TYPES.FETCH_USERS_DATA]: (state: FilterReducer): FilterReducer => ({
    ...state,
    filterUsers: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [FILTER_ACTION_TYPES.SET_FETCH_USERS_DATA_SUCCESS]: (state: FilterReducer, action: Object): FilterReducer => ({
    ...state,
    filterUsers: {
      data: action.payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [FILTER_ACTION_TYPES.SET_FETCH_USERS_DATA_FAILURE]: (state: FilterReducer, action: Object): FilterReducer => ({
    ...state,
    filterUsers: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: action.payload.error
    }
  })
};

export default (state: FilterReducer = initialState(), action: Action) => {
  if (!actionManager[action.type]) return state;

  return actionManager[action.type](state, action);
};
