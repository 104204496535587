import ACTIONS from './types';

// Automated Comments Table
export const fetchAutomatedComments = ({ count, start, passTotalCount }: Object) => ({
  type: ACTIONS.FETCH_AUTOMATED_COMMENT,
  payload: { count, start, passTotalCount }
});
export const setFetchAutomatedCommentsSuccess = ({ data, totalCount, passTotalCount }: Object) => ({
  type: ACTIONS.SET_FETCH_AUTOMATED_COMMENT_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAutomatedCommentsFailure = (error: string) => ({
  type: ACTIONS.SET_FETCH_AUTOMATED_COMMENT_FAILURE,
  payload: { error }
});
export const clearAutomatedCommentsState = () => ({
  type: ACTIONS.CLEAR_AUTOMATED_COMMENT_STATE
});

// Action Automated Comments
export const actionAutomatedComments = ({ data, page }: Object) => ({
  type: ACTIONS.FETCH_ACTION_AUTOMATED_COMMENT,
  payload: { data, page }
});
export const setFetchActionAutomatedCommentsSuccess = ({ id, data, page } : Object) => ({
  type: ACTIONS.SET_FETCH_ACTION_AUTOMATED_COMMENT_SUCCESS,
  payload: { id, data, page }
});
export const setFetchActionAutomatedCommentsFailure = (error: string) => ({
  type: ACTIONS.SET_FETCH_ACTION_AUTOMATED_COMMENT_FAILURE,
  payload: { error }
});
export const clearActionAutomatedCommentsFailure = () => ({
  type: ACTIONS.CLEAR_ACTION_AUTOMATED_COMMENT
});

// Delete Automated Comments
export const deleteAutomatedComments = ({ id }: Object) => ({
  type: ACTIONS.FETCH_DELETE_AUTOMATED_COMMENT,
  payload: { id }
});
export const setFetchDeleteAutomatedCommentsSuccess = ({ id } : Object) => ({
  type: ACTIONS.SET_FETCH_DELETE_AUTOMATED_COMMENTS_SUCCESS,
  payload: { id }
});
export const setFetchDeleteAutomatedCommentsFailure = (error: string) => ({
  type: ACTIONS.SET_FETCH_DELETE_AUTOMATED_COMMENT_FAILURE,
  payload: { error }
});
export const clearDeleteAutomatedComments = () => ({
  type: ACTIONS.CLEAR_DELETE_AUTOMATED_COMMENT
});
