/**
 * @flow
 * */
import { put, takeEvery } from 'redux-saga/effects';

import NOTIFICATION_ACTION_TYPES from 'modules/Dashboard/Auth/store/actions/notification/types';
import notification from 'modules/Dashboard/Auth/core/sdk/auth/NotificationSDK';
import {
  fetchGetAddUpdateNotificationSucceed,
  fetchGetAddUpdateNotificationFailed,
  fetchGetNotificationFilterSucceed,
  fetchGetNotificationFilterFailed,
  fetchGetNotificationSucceed,
  fetchGetNotificationFailed,
  fetchNotificationsSucceed,
  fetchNotificationsFailed,
  fetchMarkAsReadSucceed,
  fetchMarkAsReadFailed
} from 'modules/Dashboard/Auth/store/actions/notification';

function* fetchGetNotification() {
  try {
    const { data } = yield notification.fetchGetNotificationSettings();
    yield put(fetchGetNotificationSucceed({ data }));
  } catch (err) {
    if (err.name === 'AbortError') return;
    yield put(fetchGetNotificationFailed(err.message));
  }
}

function* fetchAddUpdateNotification({ payload }) {
  try {
    const { data } = yield notification.fetchAddNotificationSettings(payload);
    yield put(fetchGetAddUpdateNotificationSucceed({ data }));
  } catch (err) {
    if (err.name === 'AbortError') return;
    yield put(fetchGetAddUpdateNotificationFailed(err.message));
  }
}

function* fetchGetNotificationFilter() {
  try {
    const { data } = yield notification.fetchGetNotificationFilter();
    yield put(fetchGetNotificationFilterSucceed({ data }));
  } catch (err) {
    if (err.name === 'AbortError') return;
    yield put(fetchGetNotificationFilterFailed(err.message));
  }
}

function* fetchNotifications({ payload }) {
  try {
    const { data } = yield notification.fetchNotifications(payload);
    yield put(fetchNotificationsSucceed({ data }));
  } catch (err) {
    if (err.name === 'AbortError') return;
    yield put(fetchNotificationsFailed(err.message));
  }
}

function* fetchMarkAsRead({ payload }) {
  try {
    const { data } = yield notification.fetchMarkAsRead(payload);
    yield put(fetchMarkAsReadSucceed({ data }));
  } catch (err) {
    if (err.name === 'AbortError') return;
    yield put(fetchMarkAsReadFailed(err.message));
  }
}

function* cancelNotificationSDKCalls({ payload }) {
  const { name } = payload;
  yield notification.cancelNotificationCalls({ name });
}

function* authControllerWatcher<T>(): Iterable<T> {
  yield takeEvery(NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION, fetchGetNotification);
  yield takeEvery(NOTIFICATION_ACTION_TYPES.FETCH_ADD_UPDATE_NOTIFICATION, fetchAddUpdateNotification);
  yield takeEvery(NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION_FILTER, fetchGetNotificationFilter);
  yield takeEvery(NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS, fetchNotifications);
  yield takeEvery(NOTIFICATION_ACTION_TYPES.FETCH_MARK_AS_READ, fetchMarkAsRead);
  yield takeEvery(NOTIFICATION_ACTION_TYPES.CANCEL_NOTIFICATION, cancelNotificationSDKCalls);
}

export default authControllerWatcher;
