/* eslint-disable no-empty-function */
/**
 * @flow
 * */
import { takeLatest, put, takeEvery } from 'redux-saga/effects';

import parseAlertMonitorModelFromGetResponse from 'modules/SRMT/AlertMonitor/utils/parseAlertMonitorModelFromGetResponse';
import { mapByAlertFilterFetchParams } from 'modules/SRMT/AlertMonitor/utils/byAlertFilterFetchParams';
import parseHistoryData from 'modules/SRMT/AlertMonitor/utils/parseHistoryData';
import AM_ACTION_TYPES from 'modules/SRMT/AlertMonitor/store/actions/types';
import alertSDK from 'modules/SRMT/AlertMonitor/core/sdk/AlertMonitorSDK';
import { alertTypesEnum } from 'config/settings/constants';
import {
  setHistoryReviewChildrenDataFailure,
  setHistoryReviewChildrenDataSuccess,
  setExpandRowChildrenDataSuccess,
  setExpandRowChildrenDataFailure,
  setHistoryReviewDataSuccess,
  setHistoryReviewDataFailure,
  setFetchMonitorTabSuccess,
  setFetchMonitorTabFailure,
  setFetchReviewDataSuccess,
  setFetchReviewDataFailure,
  setEditReviewDataSuccess,
  setEditReviewDataFailure
} from 'modules/SRMT/AlertMonitor/store/actions';

const actions = {
  reject: 'Reject',
  accept: 'Confirm',
  close: 'ClosePopUp'
};

class AlertMonitorController {
  static* getMonitorTabData({ payload }) {
    const { tabKey } = payload;
    let { filters } = payload;
    if ((filters.alertType === alertTypesEnum.multiAccount || filters.alertType === alertTypesEnum.linkedAccount) && filters.byAlert) {
      filters = mapByAlertFilterFetchParams(filters);
    }
    try {
      const response = yield alertSDK.getMonitorTabData({ filters });
      const { data, totalCount } = parseAlertMonitorModelFromGetResponse(response, tabKey);
      yield put(setFetchMonitorTabSuccess({ tabKey, data, totalCount, passTotalCount: filters.passTotalCount }));
      // run background service
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchMonitorTabFailure({ tabKey, error: err.message }));
    }
  }

  static* getReviewData({ payload }) {
    const { tabKey, filters } = payload;
    try {
      const { Data: data } = yield alertSDK.getReviewData({ filters });
      yield put(setFetchReviewDataSuccess({ tabKey, data }));
    } catch (err) {
      yield put(setFetchReviewDataFailure({ tabKey, error: err.message }));
    }
  }

  static* editReviewData({ payload }) {
    const { tabKey, data, actionType } = payload;
    try {
      const response = yield alertSDK.editReviewData({ data, action: actions[actionType] });
      yield put(setEditReviewDataSuccess({ tabKey, response }));
    } catch (err) {
      yield put(setEditReviewDataFailure({ tabKey, error: err.message }));
    }
  }

  static* getReviewHistoryData({ payload }) {
    const { tabKey, alertId, alertType } = payload;
    try {
      const response = yield alertSDK.getAlertHistoryData({ alertId, alertType });
      const { data } = parseHistoryData(response);
      yield put(setHistoryReviewDataSuccess({ tabKey, data }));
    } catch (err) {
      yield put(setHistoryReviewDataFailure({ tabKey, error: err.message }));
    }
  }

  static* getReviewHistoryChildrenData({ payload }) {
    const { tabKey, alertHistoryId, alertId, alertType } = payload;
    try {
      const { Data: data } = yield alertSDK.getAlertHistoryChildrenData({ alertHistoryId, alertId, alertType });
      yield put(setHistoryReviewChildrenDataSuccess({ tabKey, data, alertHistoryId }));
    } catch (err) {
      yield put(setHistoryReviewChildrenDataFailure({ tabKey, error: err.message, alertHistoryId }));
    }
  }

  static* getExpandRowChildrenData({ payload }) {
    const { tabKey, alertId } = payload;
    try {
      const { Data: data } = yield alertSDK.getAlertExpandRowChildrenData({ alertId });
      yield put(setExpandRowChildrenDataSuccess({ tabKey, data, alertId }));
    } catch (err) {
      yield put(setExpandRowChildrenDataFailure({ tabKey, error: err.message, alertId }));
    }
  }

  static* cancelAlertSDKCalls({ payload }) {
    const { name } = payload;
    yield alertSDK.cancelAlertSDKCalls({ name });
  }

  static* alertMonitorWatcher<T>(): Iterable<T> {
    yield takeLatest(AM_ACTION_TYPES.FETCH_MONITOR_TAB, AlertMonitorController.getMonitorTabData);
    yield takeLatest(AM_ACTION_TYPES.CANCEL_ALERT_SDK_CALL, AlertMonitorController.cancelAlertSDKCalls);
    yield takeLatest(AM_ACTION_TYPES.FETCH_REVIEW_DATA, AlertMonitorController.getReviewData);
    yield takeLatest(AM_ACTION_TYPES.EDIT_REVIEW_DATA, AlertMonitorController.editReviewData);
    yield takeLatest(AM_ACTION_TYPES.FETCH_HISTORY_REVIEW_DATA, AlertMonitorController.getReviewHistoryData);
    yield takeLatest(AM_ACTION_TYPES.FETCH_HISTORY_REVIEW_CHILDREN_DATA, AlertMonitorController.getReviewHistoryChildrenData);
    yield takeEvery(AM_ACTION_TYPES.FETCH_EXPAND_ROW_CHILDREN_DATA, AlertMonitorController.getExpandRowChildrenData);
  }
}

export default AlertMonitorController.alertMonitorWatcher;
