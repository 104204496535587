import { arrayNormalizer } from './arrayNormalizer';

const InitState = {
  status: null,
  error: null
};

type KeyType = {
  initialKey?: string,
  key: string,
  groupKeys?: Array<{
    initialKey?: string,
    key: string
  }>
}
type Props = {
  object: Object,
  keys: Array<KeyType>
}

/**
 * generateObjectByKeys
 * @description
 * the function returns a new object with new key, if the initial key exists, so initial key value is added, but with a new key,
 * if the initial key does not exist, we add the same object with the same key,
 * if you want to group many keys into one key you can use group keys
 * @param {Object} object - selected partner id
 * @param {Array<KeyType>} keys -
 * @returns {Object} -
 */

export const generateObjectByKeys = ({ object, keys }: Props) => {
  const obj = {};

  keys.forEach(({ initialKey, key, groupKeys, normalizeKey }) => {
    if (groupKeys) {
      const groupObj = {};
      groupKeys.forEach(item => {
        const editedObj = object[item.initialKey || item.key];
        groupObj[item.key] = item.normalizeKey ? arrayNormalizer(editedObj, item.normalizeKey) : editedObj;
      });
      obj[key] = {
        data: groupObj,
        action: { ...InitState }
      };
    } else {
      const editedObj = object[initialKey || key] || {};
      obj[key] = {
        data: normalizeKey ? arrayNormalizer(editedObj, normalizeKey) : editedObj,
        action: { ...InitState }
      };
    }
  });

  return obj;
};
