import { tabModelKey } from '../settings';

const data = (key, models) => {
  const fraudTabs = ['linkedAccount', 'multiAccount', 'monitoring', 'gameStyle'];
  if (fraudTabs.find(e => e === key)) {
    return models;
  }
  return models[0][tabModelKey[key]];
};

const parseAlertMonitorModelFromGetResponse = (response: Object, tabKey: string) => {
  const { Data: { Models, TotalCount } } = response;
  return {
    data: data(tabKey, Models),
    totalCount: TotalCount
  };
};

export default parseAlertMonitorModelFromGetResponse;
