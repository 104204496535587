/**
 * @flow
 * */

import React from 'react';
import { Redirect } from 'react-router-dom';

const PartnerManagementTable = React.lazy(() => import('./views/pages/PartnerManagementTable'));
const LicenseManagement = React.lazy(() => import('./views/pages/LicenseManagement'));

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const modulePath = 'admin';

const ROUTES: Array<Route> = [
  { path: `/${modulePath}/partner-management`, name: 'PartnerManager', exact: true, page: () => <Redirect to={`/${modulePath}/partner-management/partner-activation`} /> },
  { path: `/${modulePath}/partner-management/partner-activation`, name: 'Partner Activation', exact: true, page: PartnerManagementTable },
  { path: `/${modulePath}/license-management`, name: 'License management', exact: true, page: LicenseManagement }
];

export default ROUTES;
