/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

const PlayerCheckHistoryReports = React.lazy(() => import('./views/pages/PlayerCheckHistoryReports'));
const PlayerBlockStatusReports = React.lazy(() => import('./views/pages/PlayerBlockStatusReports'));
const UserPlayerReports = React.lazy(() => import('./views/pages/UserPlayerReports'));
const AlertReports = React.lazy(() => import('./views/pages/AlertReports'));
const BetsReports = React.lazy(() => import('./views/pages/BetsReports'));

const subUrl = settings.PRODUCTS.RMT.path;

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const ROUTES: Array<Route> = [
  { path: `/${subUrl}/reports`, name: 'Reports', exact: true, page: () => <Redirect to={`/${subUrl}/reports/bets-reports`} /> },
  { path: `/${subUrl}/reports/bets-reports`, name: 'Bets Reports', exact: true, page: BetsReports },
  { path: `/${subUrl}/reports/user-player-reports`, name: 'User-Player Reports', exact: true, page: UserPlayerReports },
  { path: `/${subUrl}/reports/alert-reports`, name: 'Alert Reports', exact: true, page: AlertReports },
  { path: `/${subUrl}/reports/player-block-status-reports`, name: 'Player Block Status Reports', exact: true, page: PlayerBlockStatusReports },
  { path: `/${subUrl}/reports/player-check-history-reports`, name: 'Player Check History Reports', exact: true, page: PlayerCheckHistoryReports }
];

export default ROUTES;
