/**
 * @flow
 * */
import type { Action } from 'lib/core/flow/types';
import APP_ACTION_TYPES from './types';

type AppActionPayload = {|
  isLoggedIn: boolean,
  isOnline?: boolean,
  onLineMessage?: string,
  offLineMessage?: string,
  isReady?: boolean
|};

export const initApp = (): Action => ({
  type: APP_ACTION_TYPES.INIT_APPLICATION
});

export const setAppIsReady = ({ isLoggedIn, isReady = true }: AppActionPayload): Action => ({
  type: APP_ACTION_TYPES.SET_APPLICATION_IS_READY,
  payload: { isLoggedIn, isReady }
});

export const changeInternetStatus = (internetStatus: boolean): Action => ({
  type: APP_ACTION_TYPES.CHANGE_INTERNET_STATUS,
  payload: { internetStatus }
});
