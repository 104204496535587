/**
 * @flow
 * */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';

/**
 * @class LandingSDK
 */
class LandingSDK {
    _rmtApiService: NetworkService;

    constructor() {
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * fetchDemo
     * @param {Object} params -
     */
    // eslint-disable-next-line no-unused-vars
    async fetchDemo(params: any) {
      // const { name } = params;
      // const { body } = await this._rmtApiService.makeAPIGetRequest(
      //   'user/GetTableSetting',
      //   { query_params: { Schema: name } }
      // );
      return {
        data: null
      };
    }
}

export default new LandingSDK();
