/**
 * @flow
 * */
import { initialSectionState, initialSectionWithoutData } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import { deepClone } from 'lib/helpers/makeData';
import type { Action } from 'lib/core/flow';

import { amlAlertInitialState, AmlAlertsFactory } from 'modules/AML/utils/amlAlertsFactory';
import expandReducerChildren from 'modules/SRMT/AlertMonitor/utils/expandReducerChildren';
import AM_ACTION_TYPES from 'modules/AML/store/actions/types';
import { AMLState } from 'modules/AML/types';

const amlAlerts = AmlAlertsFactory.createInitialAmlAlertsMonitor();

const initialState = (): AMLState => ({
  ...amlAlerts
});

const actionManager = {
  /** ALERT* */
  // aml alerts
  [AM_ACTION_TYPES.FETCH_AML_ALERTS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        error: null,
        data: payload.data,
        totalCount: !payload.passTotalCount ? payload.totalCount : state[payload.tabKey].alerts.totalCount,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        data: null,
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_ALERTS_STATE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: deepClone(amlAlertInitialState)
  }),

  // aml expanded data
  [AM_ACTION_TYPES.FETCH_AML_EXPANDED_DATA]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren(state[payload.tabKey].alerts.data, payload, null, ACTION_STATUSES.PENDING, null)
      }
    }
  }),
  [AM_ACTION_TYPES.FETCH_AML_EXPANDED_DATA_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren(state[payload.tabKey].alerts.data, payload, payload.data, ACTION_STATUSES.SUCCEED, null)
      }
    }
  }),
  [AM_ACTION_TYPES.FETCH_AML_EXPANDED_DATA_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren(state[payload.tabKey].alerts.data, payload, null, ACTION_STATUSES.FAILED, payload.error)
      }
    }
  }),

  // aml review history expanded data
  [AM_ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          ...state[payload.tabKey].reviewing_alert.history,
          data: expandReducerChildren(state[payload.tabKey].reviewing_alert.history.data, payload, null, ACTION_STATUSES.PENDING, null, 'AlertHistoryId')
        }

      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          ...state[payload.tabKey].reviewing_alert.history,
          data: expandReducerChildren(state[payload.tabKey].reviewing_alert.history.data, payload, payload.data, ACTION_STATUSES.SUCCEED, null, 'AlertHistoryId')
        }

      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          ...state[payload.tabKey].reviewing_alert.history,
          data: expandReducerChildren(state[payload.tabKey].reviewing_alert.history.data, payload, null, ACTION_STATUSES.FAILED, payload.error, 'AlertHistoryId')
        }

      }
    }
  }),
  // aml alerts export
  [AM_ACTION_TYPES.FETCH_AML_ALERTS_EXPORT]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      export_state: {
        ...state[payload.tabKey].export_state,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_EXPORT_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      export_state: {
        error: null,
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_EXPORT_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      export_state: {
        data: null,
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_ALERTS_EXPORT_STATE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      export_state: initialSectionState()
    }
  }),
  // aml alerts review
  [AM_ACTION_TYPES.FETCH_AML_ALERTS_REVIEW]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        error: null,
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        data: null,
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_ALERTS_REVIEW_STATE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...initialSectionState(),
        edit: initialSectionState()
      }
    }
  }),

  // aml alerts review expand
  [AM_ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_EXPAND]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        data: {
          Rate: state[payload.tabKey].reviewing_alert.data.Rate,
          Data: expandReducerChildren(state[payload.tabKey].reviewing_alert.data.Data, payload, null, ACTION_STATUSES.PENDING, null, 'SessionId', 'sessionId')
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_EXPAND_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        data: {
          Rate: state[payload.tabKey].reviewing_alert.data.Rate,
          Data: expandReducerChildren(state[payload.tabKey].reviewing_alert.data.Data, payload, payload.data, ACTION_STATUSES.SUCCEED, null, 'SessionId', 'sessionId')
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_EXPAND_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        data: {
          Rate: state[payload.tabKey].reviewing_alert.data.Rate,
          Data: expandReducerChildren(state[payload.tabKey].reviewing_alert.data, payload, null, ACTION_STATUSES.FAILED, payload.error, 'SessionId', 'sessionId')
        }
      }
    }
  }),

  // aml alerts review edit
  [AM_ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_EDIT]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: {
          data: null,
          error: null,
          status: ACTION_STATUSES.PENDING
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_EDIT_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: {
          data: payload.data,
          error: null,
          status: ACTION_STATUSES.SUCCEED
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_EDIT_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: {
          data: null,
          error: payload.error,
          status: ACTION_STATUSES.FAILED
        }
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_ALERTS_REVIEW_EDIT_STATE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: initialSectionState()
      }
    }
  }),
  // aml alerts review history
  [AM_ACTION_TYPES.FETCH_AML_ALERTS_REVIEW_HISTORY]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          error: null,
          data: null,
          status: ACTION_STATUSES.PENDING
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          error: null,
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          data: null,
          error: payload.error,
          status: ACTION_STATUSES.FAILED
        }
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_ALERTS_REVIEW_HISTORY_STATE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: initialSectionState()
      }
    }
  }),
  // aml alerts bulk review
  [AM_ACTION_TYPES.FETCH_AML_ALERTS_BULK_REVIEW]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      bulk_review: {
        ...state[payload.tabKey].bulk_review,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_BULK_REVIEW_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      bulk_review: {
        data: payload.data,
        error: null,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERTS_BULK_REVIEW_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      bulk_review: {
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_ALERT_BULK_REVIEW_STATE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      bulk_review: initialSectionWithoutData()
    }
  }),
  // aml risk category history
  [AM_ACTION_TYPES.FETCH_AML_ALERT_RISK_CATEGORY]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      risk_category_history: {
        ...state[payload.tabKey].risk_category_history,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERT_RISK_CATEGORY_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      risk_category_history: {
        error: null,
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_ALERT_RISK_CATEGORY_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      risk_category_history: {
        data: null,
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_ALERT_RISK_CATEGORY_STATE]: (state: AMLState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      risk_category_history: initialSectionState()
    }
  })
};

export default (state: AMLState = initialState(), action: Action) => {
  if (!actionManager[action.type]) return state;

  return actionManager[action.type](state, action.payload);
};
