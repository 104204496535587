import createSymbol from 'lib/core/createSymbol';

const createAuthSymbol = createSymbol('NOTIFICATION');

export default {
  FETCH_GET_NOTIFICATION: createAuthSymbol('FETCH_GET_NOTIFICATION'),
  FETCH_GET_NOTIFICATION_SUCCESS: createAuthSymbol('FETCH_GET_NOTIFICATION_SUCCESS'),
  FETCH_GET_NOTIFICATION_FAILURE: createAuthSymbol('FETCH_GET_NOTIFICATION_FAILURE'),
  CLEAR_GET_NOTIFICATION: createAuthSymbol('CLEAR_GET_NOTIFICATION'),

  FETCH_ADD_UPDATE_NOTIFICATION: createAuthSymbol('FETCH_ADD_UPDATE_NOTIFICATION'),
  FETCH_ADD_UPDATE_NOTIFICATION_SUCCESS: createAuthSymbol('FETCH_ADD_UPDATE_NOTIFICATION_SUCCESS'),
  FETCH_ADD_UPDATE_NOTIFICATION_FAILURE: createAuthSymbol('FETCH_ADD_UPDATE_NOTIFICATION_FAILURE'),
  CLEAR_ADD_UPDATE_NOTIFICATION: createAuthSymbol('CLEAR_ADD_UPDATE_NOTIFICATION'),

  FETCH_GET_NOTIFICATION_FILTER: createAuthSymbol('FETCH_GET_NOTIFICATION_FILTER'),
  FETCH_GET_NOTIFICATION_FILTER_SUCCESS: createAuthSymbol('FETCH_GET_NOTIFICATION_FILTER_SUCCESS'),
  FETCH_GET_NOTIFICATION_FILTER_FAILURE: createAuthSymbol('FETCH_GET_NOTIFICATION_FILTER_FAILURE'),
  CLEAR_GET_NOTIFICATION_FILTER: createAuthSymbol('CLEAR_GET_NOTIFICATION_FILTER'),

  FETCH_NOTIFICATIONS: createAuthSymbol('FETCH_NOTIFICATIONS'),
  FETCH_NOTIFICATIONS_SUCCESS: createAuthSymbol('FETCH_NOTIFICATIONS_SUCCESS'),
  FETCH_NOTIFICATIONS_FAILURE: createAuthSymbol('FETCH_NOTIFICATIONS_FAILURE'),
  CLEAR_NOTIFICATIONS: createAuthSymbol('CLEAR_NOTIFICATIONS'),

  FETCH_MARK_AS_READ: createAuthSymbol('FETCH_MARK_AS_READ'),
  FETCH_MARK_AS_READ_SUCCESS: createAuthSymbol('FETCH_MARK_AS_READ_SUCCESS'),
  FETCH_MARK_AS_READ_FAILURE: createAuthSymbol('FETCH_MARK_AS_READ_FAILURE'),

  CANCEL_NOTIFICATION: createAuthSymbol('CANCEL_NOTIFICATION')
};
