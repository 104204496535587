import config from 'config';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import { makeDefaultData } from 'modules/Admin/UserManagement/utils/userManagementUtils';
import { PermissionEditType } from 'modules/Admin/UserManagement/utils/enums';
import type {
  AddResourcesRequestModel,
  AddResourcesResponseModel,
  Resource, RolePermissionModel,
  TemplatesResponseModel,
  UserModel
} from './types';

import { OperatingPartnerUpdateModel, UsersPartner } from './types';

/**
   * @class UserManagementSDK
 */
class UserManagementSDK extends BaseSDK {
    _rmtApiService: NetworkService = null;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
     * getRoleTemplates
     * @params data -
     * @returns {Array<TemplatesResponseModel>} -
     */
    async getRoleTemplates(): {data: Array<TemplatesResponseModel>} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Role/Get'
      );
      return {
        data: body.Data
      };
    }

    /**
     * getAllResources
     * @params -
     * @returns {Array<Resource>} -
     */
    async getAllResources(): {data: Array<Resource>} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Resource/Get?userId=1111'
      );
      return {
        data: makeDefaultData(body.Data)
      };
    }

    /**
     * getRoleResources
     * @param {Object }params -
     * @returns {Promise<Object>} -
     */
    async getRoleResources(params: { id: number }): {data: Array<Resource>} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'UserManagement/GetRolePermissions',
        { query_params: { roleId: params.id } }
      );
      return {
        data: body.Data
      };
    }

    /**
   * getUserGroupResources
   * @param {Object }params -
   * @returns {Promise<Object>} -
   */
    async getUserGroupResources(params: { userId: number }): {data: Array<Resource>} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'UserManagement/GetUserPermissions',
        { query_params: { userId: params.userId } }
      );
      return {
        data: body.Data
      };
    }

    /**
   * setResources
   * @param {Object }params -
   * @returns {Promise<Object>} -
   */
    async setResources(params: AddResourcesRequestModel): {data: AddResourcesResponseModel} {
      const { changes, type, userId, roleId } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        `UserManagement/${type === PermissionEditType.user ? 'ManageUserPermissions' : 'ManageRolePermissions'}`,
        { body: { changes, type, userId, roleId } }
      );
      return {
        data: body.Data
      };
    }

    /**
   * getUsers
   * @param {Object }params -
   * @returns {Promise<Object>} -
   */
    async getUsers(params): { data: Array<UserModel> } {
      const { filters, count, start, passTotalCount } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'userManagement/GetUsers',
        { query_params: { ...filters, count, start, passTotalCount }, abortController: this.setAbortController('getUsers') }
      );
      return body.Data;
    }

    /**
   * UpdateOperatingPartners
   * @param {Object }params -
   * @returns {Promise<Object>} -
   */
    async UpdateOperatingPartners(params): { data: OperatingPartnerUpdateModel } {
      const requestBody = params;

      const { response } = await this._rmtApiService.makeAPIPostRequest(
        'UserManagement/ManageUserPermissions',
        { body: { ...requestBody } }
      );
      return response;
    }

    /**
     * cancelPartnerManagementSDKCalls
     * @param {Object} params -
     * @returns {Promise<{Object}>} -
     */
    cancelUserManagementSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }

    async getRoleData(): { data: Array<RolePermissionModel> } {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Role/Get'
      );

      await new Promise(resolve => setTimeout(resolve, 1000));

      return body.Data;
    }

    cancelRolesDataCAll(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }

    /**
     * getUsersPartner
     * @param {Array}params -
     * @returns {Promise<Object>} -
     */
    async getUsersPartner(params: { userIds: Array<number> }): {data: Array<UsersPartner>} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'User/GetUsersPartner',
        { query_params: { userIds: params.userIds } }
      );
      return {
        data: body.Data
      };
    }

    /**
   * deactivationUser
   * @param {Array}params -
   * @returns {Promise<Object>} -
   */
    async deactivationUser(params: { userId: number }): void {
      await this._rmtApiService.makeAPIPostRequest(
        `userManagement/DeactivateUser?userId=${params.userId}`
      );
    }
}

export default new UserManagementSDK();
