/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';

// Multi Accounts
export const fetchMultiAccounts = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_MULTI_ACCOUNTS,
  payload: { playerId }
});
export const setFetchMultiAccountsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_MULTI_ACCOUNTS_SUCCESS,
  payload: { data }
});
export const setFetchMultiAccountsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_MULTI_ACCOUNTS_FAILURE,
  payload: { error }
});
export const clearMultiAccountsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_MULTI_ACCOUNTS_STATE
});

// Set Monitoring Alert
export const fetchSetMonitoringAlert = ({ filters, projectType }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_SET_MONITORING_ALERT,
  payload: { filters, projectType }
});
export const setFetchSetMonitoringAlertSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_SET_MONITORING_SUCCESS,
  payload: { data }
});
export const setFetchSetMonitoringAlertFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_SET_MONITORING_FAILURE,
  payload: { error }
});
export const clearSetMonitoringAlertState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_SET_MONITORING_STATE
});

// Player Info
export const fetchPlayerInfo = ({ id }: Object): Object => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_INFO,
  payload: { id }
});
export const setFetchPlayerInfoSuccess = ({ data }: Object): Object => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_INFO_SUCCESS,
  payload: { data }
});
export const setFetchPlayerInfoFailure = (error: string) => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_INFO_FAILURE,
  payload: { error }
});
export const updatePlayerInfo = (params: any) => ({
  type: PLAYER_ACTIONS.UPDATE_PLAYER_INFO,
  payload: { params }
});
export const clearPlayerInfo = () => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_INFO_STATE
});

// Player Session Ips
export const fetchPlayerSessionIps = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_SESSION_IPS,
  payload: { playerId }
});
export const setFetchPlayerSessionIpsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_SESSION_IPS_SUCCESS,
  payload: { data }
});
export const setFetchPlayerSessionIpsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_SESSION_IPS_FAILURE,
  payload: { error }
});
export const clearPlayerSessionIpsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_SESSION_IPS_STATE
});

// Player All Session Ips
export const fetchPlayerAllSessionIps = ({ playerId, loginIp }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_ALL_SESSION_IPS,
  payload: { playerId, loginIp }
});
export const setFetchPlayerAllSessionIpsSuccess = ({ data, loginIp }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_All_SESSION_IPS_SUCCESS,
  payload: { data, loginIp }
});
export const setFetchPlayerAllSessionIpsFailure = ({ error, loginIp }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_All_SESSION_IPS_FAILURE,
  payload: { error, loginIp }
});

// All Players Session Ip
export const fetchAllPlayersSessionIp = ({ loginIp }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALL_PLAYERS_SESSION_IP,
  payload: { loginIp }
});
export const setFetchAllPlayersSessionIpSuccess = ({ data, loginIp }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_All_PLAYERS_SESSION_IP_SUCCESS,
  payload: { data, loginIp }
});
export const setFetchAllPlayersSessionIpFailure = ({ error, loginIp }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_All_PLAYERS_SESSION_IP_FAILURE,
  payload: { error, loginIp }
});

// Player Fingerprints
export const fetchPlayerFingerprints = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_FINGERPRINTS,
  payload: { playerId }
});
export const setFetchPlayerFingerprintsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINGERPRINTS_SUCCESS,
  payload: { data }
});
export const setFetchPlayerFingerprintsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINGERPRINTS_FAILURE,
  payload: { error }
});
export const clearPlayerFingerprintsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_FINGERPRINTS_STATE
});

// Checked Manually
export const setCheckedManually = ({ playerId, source }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_SET_CHECK_MANUALLY,
  payload: { playerId, source }
});
export const setCheckedManuallySuccess = (): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_SET_CHECK_MANUALLY_SUCCESS
});
export const setCheckedManuallyFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_SET_CHECK_MANUALLY_FAILURE,
  payload: { error }
});
export const clearCheckedManuallyState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_FETCH_SET_CHECK_MANUALLY_STATE
});

// Check History
export const fetchCheckHistory = ({ count, start, passTotalCount, projectType, ...filters }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_CHECK_HISTORY,
  payload: { filters, count, start, passTotalCount, projectType }
});
export const setFetchCheckHistorySuccess = ({ data, totalCount, passTotalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_CHECK_HISTORY_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchCheckHistoryFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_CHECK_HISTORY_FAILURE,
  payload: { error }
});
export const clearFetchCheckHistoryState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_FETCH_CHECK_HISTORY_STATE
});
