/**
 * @flow
 * */
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeInternetStatus } from 'store/actions/application';
/**
 * Custom hook InternetStatusListener
 *
 * call from the main component, the hook tracks the change in the status of the internet connection and throws an error if the connection is lost
 *
 */

function InternetStatusListener() {
  const dispatch = useDispatch();

  const onLineStatusListener = useCallback(() => {
    dispatch(changeInternetStatus(true));
  }, [dispatch]);
  const offLineStatusListener = useCallback(() => {
    dispatch(changeInternetStatus(false));
  }, [dispatch]);
  const addInternetStatusListener = useCallback(() => {
    window.addEventListener('load', () => {
      window.addEventListener('online', onLineStatusListener);
      window.addEventListener('offline', offLineStatusListener);
    });
  }, [onLineStatusListener, offLineStatusListener]);
  const removeInternetStatusListener = useCallback(() => {
    window.removeEventListener('online', onLineStatusListener);
    window.removeEventListener('offline', offLineStatusListener);
  }, [onLineStatusListener, offLineStatusListener]);
  const handler = useCallback(() => {
    addInternetStatusListener();
    return removeInternetStatusListener();
  }, [addInternetStatusListener, removeInternetStatusListener]);

  useEffect(handler, [addInternetStatusListener, removeInternetStatusListener]);
}

export default InternetStatusListener;
