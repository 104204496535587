/**
 * @flow
 * */
import { connect } from 'react-redux';

import { userSelector } from 'modules/Dashboard/Auth/store/selectors/userSelectors';
import Container from './Container';

const mapStateToProps = store => ({
  user: userSelector(store)
});

export default connect(
  mapStateToProps
)(Container);
