import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createFilterInternalSymbol = createInternalSymbol('FILTER');
const createFilterExternalSymbol = createSymbol('FILTER');

export default {
  SET_FILTER_HOLDER_STATUS: createFilterInternalSymbol('SET_FILTER_HOLDER_STATUS'),

  SET_FILTER_STATUS: createFilterInternalSymbol('SET_FILTER_STATUS'),
  CLEAR_FILTER_STATUS: createFilterInternalSymbol('CLEAR_FILTER_STATUS'),

  SET_QUERY_OBJECT: createFilterInternalSymbol('SET_QUERY_OBJECT'),
  RESET_QUERY_OBJECT: createFilterInternalSymbol('RESET_QUERY_OBJECT'),

  SET_SELECTED_FILTER: createFilterInternalSymbol('SET_SELECTED_FILTER'),
  CLEAR_SELECTED_FILTER: createFilterInternalSymbol('CLEAR_SELECTED_FILTER'),

  FETCH_FILTERS_DATA: createFilterExternalSymbol('FETCH_FILTERS_DATA'),
  SET_FETCH_FILTERS_DATA_SUCCESS: createFilterExternalSymbol('SET_FETCH_FILTERS_DATA_SUCCESS'),
  SET_FETCH_FILTERS_DATA_FAILURE: createFilterExternalSymbol('SET_FETCH_FILTERS_DATA_FAILURE'),
  CLEAR_FILTERS_DATA: createFilterExternalSymbol('CLEAR_FILTERS_DATA'),

  FETCH_USERS_DATA: createFilterExternalSymbol('FETCH_USERS_DATA'),
  SET_FETCH_USERS_DATA_SUCCESS: createFilterExternalSymbol('SET_FETCH_USERS_DATA_SUCCESS'),
  SET_FETCH_USERS_DATA_FAILURE: createFilterExternalSymbol('SET_FETCH_USERS_DATA_FAILURE')
};
