export const permissions = {
  /// srmt
  SRMTBox: 'SRMTBox',
  SRMTFraudAlerts: 'SRMTFraudAlerts',
  SRMTInfoAlerts: 'SRMTInfoAlerts',
  SRMTPlayersGroup: 'SRMTPlayersGroup',
  SRMTSettings: 'SRMTSettings',
  SRMTDashboardAndAnalyticData: 'SRMTDashboardAndAnalyticData',
  SRMTReports: 'SRMTReports',
  SRMTExport: 'SRMTExport',
  /// LCRMT
  LCRMTBox: 'LCRMTBox',
  LCRMTAdmin: 'LCRMTAdmin',
  LCRMTExport: 'LCRMTExport',
  LCRMTReport: 'LCRMTReport',
  LCRMTDailyAlerts: 'LCRMTDailyAlerts',
  LCRMTHourlyAlerts: 'LCRMTHourlyAlerts',
  LCRMTAlertMonitor: 'LCRMTAlertMonitor',
  LCRMTSettings: 'LCRMTSettings',
  LCRMTStudioAlerts: 'LCRMTStudioAlerts',

  /// aml
  AMLBox: 'AMLBox',
  AMLRGGeneral: 'AMLRGGeneral',
  AMLSettings: 'AMLSettings',
  AMLExport: 'AMLRGProductBoxExport',

  /// FRMT
  FRMTBox: 'FRMTBox',
  FRMTSettings: 'FRMTSettings',
  FRMTAlertMonitor: 'FRMTAlertMonitor',
  FRMTExport: 'FRMTExport',

  /// common
  PlayersListAndIndividualPage: 'PlayersListAndIndividualPage',
  AdminPanel: 'AdminPanel',

  /// CRMT
  CRMTBox: 'CRMTBox',
  CRMTPlayersGroup: 'CRMTPlayersGroup',
  CRMTAlertMonitor: 'CRMTAlertMonitor',
  CRMTCumulativeLoss: 'CRMTCumulativeLoss',
  CRMTSettings: 'CRMTSettings'
};
