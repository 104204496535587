/**
 * @flow
 * */

import playersSDK from 'core/sdk/playerGeneral/playerGlobalSDK';
import GENERAL_ACTION from 'store/actions/general/types';
import { put, takeLatest } from 'redux-saga/effects';
import {
  setFetchPlayerGlobalInfoFailure,
  setFetchPlayerGlobalInfoSuccess
} from 'store/actions/general/actions';

class PlayerGeneralController {
  static* fetchGlobalInfo({ payload }) {
    const { playerId, productType } = payload;
    try {
      const { data } = yield playersSDK.getGlobalInfo({ playerId, productType });
      yield put(setFetchPlayerGlobalInfoSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerGlobalInfoFailure({ error: err.message, errorStatusCode: err.res.body.status }));
    }
  }

  static* cancelPlayerGeneralSDKCalls({ payload }) {
    const { name } = payload;
    yield playersSDK.cancelPlayerGeneralSDKCalls({ name });
  }

  static* generalWatcher<T>(): Iterable<T> {
    yield takeLatest(GENERAL_ACTION.FETCH_PLAYER_GLOBAL_INFO, PlayerGeneralController.fetchGlobalInfo);
    yield takeLatest(GENERAL_ACTION.CANCEL_PLAYER_GENERAL_SDK_CALL, PlayerGeneralController.cancelPlayerGeneralSDKCalls);
  }
}

export default PlayerGeneralController.generalWatcher;
