import type { Action } from 'lib/core/flow';
import AM_ACTION_TYPES from 'modules/SRMT/AlertMonitor/store/actions/types';
import type { AlertMonitorReducer } from 'modules/SRMT/AlertMonitor/types';

import reducerManager from './reducerManager';

const initialState = (monitorKey): AlertMonitorReducer => ({
  initializedMonitorKey: monitorKey
});

export default (state = initialState(null), action: Action) => {
  const { monitorKey } = action.payload || {};

  switch (action.type) {
    case AM_ACTION_TYPES.INIT_MONITOR:
      return { ...initialState(monitorKey), ...reducerManager[monitorKey](state[monitorKey], action) };
    case AM_ACTION_TYPES.UNMOUNT_MONITOR:
      // eslint-disable-next-line no-param-reassign
      delete state[monitorKey];
      // eslint-disable-next-line no-param-reassign
      state.initializedMonitorKey = null;
      return {
        initializedMonitorKey: null,
        ...state
      };

    default:
      return state.initializedMonitorKey
        ? { ...state, [state.initializedMonitorKey]: reducerManager[state.initializedMonitorKey](state[state.initializedMonitorKey], action) }
        : state;
  }
};
