/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import CHI_SQUARE_REPORTS_ACTION_TYPES from './types';

// reports
export const fetchChiSquareReports = ({ start, count, ...filters }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.FETCH_CHI_SQUARE_REPORTS,
  payload: { start, count, filters }
});

export const setFetchChiSquareReportsSuccess = ({ data, totalCount }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_REPORTS_SUCCESS,
  payload: { data, totalCount }
});

export const setFetchChiSquareReportsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_REPORTS_FAILURE,
  payload: { error }
});

export const clearChiSquareReportsState = (): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.CLEAR_CHI_SQUARE_REPORTS_STATE
});

// lifeTime reports
export const fetchLifeTimeChiSquareReports = ({ ...filters }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.FETCH_LIFE_TIME_CHI_SQUARE_REPORTS,
  payload: { filters }
});

export const setFetchLifeTimeChiSquareReportsSuccess = ({ data }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_SUCCESS,
  payload: { data }
});

export const setFetchLifeTimeChiSquareReportsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_FAILURE,
  payload: { error }
});

export const clearLifeTimeChiSquareReportsState = (): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.CLEAR_LIFE_TIME_CHI_SQUARE_REPORTS_STATE
});

// players bet reports
export const fetchPlayersBetsReports = ({ start, count, passTotalCount, filters }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.FETCH_PLAYERS_BETS_REPORT,
  payload: { start, count, passTotalCount, filters }
});

export const setFetchPlayersBetsReportsSuccess = ({ data, totalCount, passTotalCount }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_FETCH_PLAYERS_BETS_REPORT_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});

export const setFetchLifePlayersBetsReportsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_PLAYERS_BETS_REPORT_FAILURE,
  payload: { error }
});

export const cleaPlayersBetsReportsState = (): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.CLEAR_PLAYERS_BETS_REPORT_STATE
});

// players bet reports
export const fetchPlayersRoundsReports = ({ start, count, passTotalCount, filters }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.FETCH_PLAYERS_ROUNDS_REPORT,
  payload: { start, count, passTotalCount, filters }
});

export const setFetchPlayersRoundsReportsSuccess = ({ data, totalCount, passTotalCount }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_FETCH_PLAYERS_ROUNDS_REPORT_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});

export const setFetchLifePlayersRoundsReportsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_PLAYERS_ROUNDS_REPORT_FAILURE,
  payload: { error }
});

export const cleaPlayersRoundsReportsState = (): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.CLEAR_PLAYERS_ROUNDS_REPORT_STATE
});

// partners info reports
export const fetchPartnersInfoReports = ({ start, count, passTotalCount, filters }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.FETCH_PARTNERS_INFO_REPORT,
  payload: { start, count, passTotalCount, filters }
});

export const setFetchPartnersInfoReportsSuccess = ({ data, totalCount, passTotalCount }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_PARTNERS_INFO_REPORT_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});

export const setFetchPartnersInfoReportsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.SET_FETCH_PARTNERS_INFO_REPORT_FAILURE,
  payload: { error }
});

export const clearPartnersInfoReportsState = (): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.CLEAR_PARTNERS_INFO_REPORT_STATE
});

// cancel call
export const cancelReportSDKCalls = ({ name }: Object): Action => ({
  type: CHI_SQUARE_REPORTS_ACTION_TYPES.CANCEL_REPORT_SDK_CALL,
  payload: { name }
});
