import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { MinimalReducer } from 'lib/core/flow/types';
import type { Action } from 'lib/core/flow';

import AUTH_ACTION_TYPES from 'modules/Dashboard/Auth/store/actions/auth/types';
import type { JwtLoginDataModel } from 'core/sdk/AGPSDK/types';

export type AuthState = {
  log_out: MinimalReducer,
  jwt_auth: { ...MinimalReducer, data: JwtLoginDataModel },
  jwt_change_password: { ...MinimalReducer, data: any },
  two_step: { ...MinimalReducer, data: any }
};

const initialState = (): AuthState => ({
  log_out: {
    error: null,
    status: null
  },
  jwt_auth: {
    error: null,
    status: null,
    data: null
  },
  jwt_change_password: {
    error: null,
    status: null,
    data: null
  },
  two_step: {
    error: null,
    status: null,
    data: null
  }
});

const FetchJWTAuth = (state: AuthState) => ({
  ...state,
  jwt_auth: {
    ...state.jwt_auth,
    status: ACTION_STATUSES.PENDING
  }
});

const FetchJWTAuthFailed = (state: AuthState, action: Action) => ({
  ...state,
  jwt_auth: {
    status: ACTION_STATUSES.FAILED,
    error: action.payload,
    data: null
  }
});

const FetchJWTAuthSucceed = (state: AuthState, action: Action) => ({
  ...state,
  jwt_auth: {
    status: ACTION_STATUSES.SUCCEED,
    error: null,
    data: action.payload
  }
});

const FetchJWTAuthChangePassword = (state: AuthState) => ({
  ...state,
  jwt_change_password: {
    ...state.jwt_change_password,
    status: ACTION_STATUSES.PENDING
  }
});

const FetchJWTAuthChangePasswordFailed = (state: AuthState, action: Action) => ({
  ...state,
  jwt_change_password: {
    status: ACTION_STATUSES.FAILED,
    error: action.payload,
    data: null
  }
});

const FetchJWTAuthChangePasswordSucceed = (state: AuthState, action: Action) => ({
  ...state,
  jwt_change_password: {
    status: ACTION_STATUSES.SUCCEED,
    error: null,
    data: action.payload
  }
});

const FetchJWTAuthTwoStep = (state: AuthState) => ({
  ...state,
  two_step: {
    ...state.two_step,
    status: ACTION_STATUSES.PENDING
  }
});

const FetchJWTAuthTwoStepFailed = (state: AuthState, action: Action) => ({
  ...state,
  two_step: {
    status: ACTION_STATUSES.FAILED,
    error: action.payload,
    data: null
  }
});

const FetchJWTAuthTwoStepSucceed = (state: AuthState, action: Action) => ({
  ...state,
  two_step: {
    status: ACTION_STATUSES.SUCCEED,
    error: null,
    data: action.payload
  }
});

export default (state: AuthState = initialState(), action: Action) => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH:
      return FetchJWTAuth(state);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_SUCCESS:
      return FetchJWTAuthSucceed(state, action);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_FAILED:
      return FetchJWTAuthFailed(state, action);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_CHANGE_PASSWORD:
      return FetchJWTAuthChangePassword(state);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_CHANGE_PASSWORD_SUCCESS:
      return FetchJWTAuthChangePasswordSucceed(state, action);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_CHANGE_PASSWORD_FAILED:
      return FetchJWTAuthChangePasswordFailed(state, action);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_TWO_STEP:
      return FetchJWTAuthTwoStep(state);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_TWO_STEP_SUCCESS:
      return FetchJWTAuthTwoStepSucceed(state, action);
    case AUTH_ACTION_TYPES.FETCH_JWT_AUTH_TWO_STEP_FAILED:
      return FetchJWTAuthTwoStepFailed(state, action);
    default:
      return state;
  }
};
