/**
 * @flow
 * */

import { takeLatest, put } from 'redux-saga/effects';

import { setDemoFailed, setDemoSuccess } from 'store/actions/landing';
import LANDING_ACTION_TYPES from 'store/actions/landing/types';
import LandingSDK from 'core/sdk/landing/landingSDK';

class LandingController {
  static* fetchDemo({ payload }) {
    try {
      yield LandingSDK.fetchDemo(payload);
      yield new Promise(resolve => {
        setTimeout(() => {
          resolve(5);
        }, 2000);
      });
      yield put(setDemoSuccess());
    } catch (err) {
      yield put(setDemoFailed(err.message));
    }
  }

  static* userWatcher<T>(): Iterable<T> {
    yield takeLatest(LANDING_ACTION_TYPES.FETCH_DEMO, LandingController.fetchDemo);
  }
}

export default LandingController.userWatcher;
