/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import settings from 'config/constants';

// eslint-disable-next-line import/no-cycle
const PlayerGroupList = React.lazy(() => import('./views/pages/PlayerGroupList'));
const PlayerGroup = React.lazy(() => import('./views/pages/PlayerGroup'));

const modulePath = `/${settings.PRODUCTS.RMT.path}/player-groups`;

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const ROUTES: Array<Route> = [
  { path: `${modulePath}/:id`, name: 'PlayerGroupList', exact: true, page: PlayerGroup },
  { path: `${modulePath}`, name: 'PlayerGroup', exact: true, page: PlayerGroupList }
];

export default ROUTES;
