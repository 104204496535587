/**
 * @flow
 * */
import type { StatisticsState } from 'modules/LCRMT/EquipmentIntegrity/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import { createStatisticsInitialState, createTableStatisticsInitialState } from './initialStates';

import STATISTICS_ACTION_TYPES from '../actions/statistics/types';

const initialState = (): StatisticsState => ({
  statistics: createStatisticsInitialState(),
  tableStatistics: createTableStatisticsInitialState(),
  rouletteStatistics: createStatisticsInitialState(),
  rouletteBiasedStatistics: createStatisticsInitialState(),
  cardStatistics: createStatisticsInitialState(),
  timeRange: createStatisticsInitialState(),
  statisticsTableData: createStatisticsInitialState()
});

const actionManager = {
  // statistics
  [STATISTICS_ACTION_TYPES.FETCH_STATISTICS]: (state: StatisticsState): StatisticsState => ({
    ...state,
    statistics: {
      ...state.statistics,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_SUCCESS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    statistics: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_FAILURE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    statistics: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [STATISTICS_ACTION_TYPES.CLEAR_STATISTICS_STATE]: (state: StatisticsState): StatisticsState => ({
    ...state,
    statistics: createStatisticsInitialState()
  }),

  // table statistics
  [STATISTICS_ACTION_TYPES.FETCH_TABLE_STATISTICS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    tableStatistics: {
      ...state.tableStatistics,
      [payload.tableId]: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_TABLE_STATISTICS_SUCCESS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    tableStatistics: {
      ...state.tableStatistics,
      [payload.tableId]: {
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        data: payload.data
      }
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_TABLE_STATISTICS_FAILURE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    tableStatistics: {
      ...state.tableStatistics,
      [payload.tableId]: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        data: null
      }
    }
  }),
  [STATISTICS_ACTION_TYPES.CLEAR_TABLE_STATISTICS_STATE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    tableStatistics: {
      ...state.tableStatistics,
      [payload.tableId]: createTableStatisticsInitialState()
    }
  }),

  // roulette statistics
  [STATISTICS_ACTION_TYPES.FETCH_ROULETTE_STATISTICS]: (state: StatisticsState): StatisticsState => ({
    ...state,
    rouletteStatistics: {
      ...state.rouletteStatistics,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_STATISTICS_SUCCESS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    rouletteStatistics: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_STATISTICS_FAILURE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    rouletteStatistics: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [STATISTICS_ACTION_TYPES.CLEAR_ROULETTE_STATISTICS_STATE]: (state: StatisticsState): StatisticsState => ({
    ...state,
    rouletteStatistics: createStatisticsInitialState()
  }),

  // roulette biased statistics
  [STATISTICS_ACTION_TYPES.FETCH_ROULETTE_BIASED_STATISTICS]: (state: StatisticsState): StatisticsState => ({
    ...state,
    rouletteBiasedStatistics: {
      ...state.rouletteBiasedStatistics,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_BIASED_STATISTICS_SUCCESS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    rouletteBiasedStatistics: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_BIASED_STATISTICS_FAILURE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    rouletteBiasedStatistics: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [STATISTICS_ACTION_TYPES.CLEAR_ROULETTE_BIASED_STATISTICS_STATE]: (state: StatisticsState): StatisticsState => ({
    ...state,
    rouletteBiasedStatistics: createStatisticsInitialState()
  }),

  // card statistics
  [STATISTICS_ACTION_TYPES.FETCH_CARD_STATISTICS]: (state: StatisticsState): StatisticsState => ({
    ...state,
    cardStatistics: {
      ...state.cardStatistics,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_CARD_STATISTICS_SUCCESS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    cardStatistics: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_CARD_STATISTICS_FAILURE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    cardStatistics: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [STATISTICS_ACTION_TYPES.CLEAR_CARD_STATISTICS_STATE]: (state: StatisticsState): StatisticsState => ({
    ...state,
    cardStatistics: createStatisticsInitialState()
  }),

  // time range data
  [STATISTICS_ACTION_TYPES.FETCH_TIME_RANGE]: (state: StatisticsState): StatisticsState => ({
    ...state,
    timeRange: {
      ...state.timeRange,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_TIME_RANGE_SUCCESS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    timeRange: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_TIME_RANGE_FAILURE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    timeRange: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [STATISTICS_ACTION_TYPES.CLEAR_TIME_RANGE_STATE]: (state: StatisticsState): StatisticsState => ({
    ...state,
    timeRange: createStatisticsInitialState()
  }),

  // card statistics table data
  [STATISTICS_ACTION_TYPES.FETCH_STATISTICS_TABLE_DATA]: (state: StatisticsState): StatisticsState => ({
    ...state,
    statisticsTableData: {
      ...state.statisticsTableData,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_TABLE_DATA_SUCCESS]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    statisticsTableData: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_TABLE_DATA_FAILURE]: (state: StatisticsState, payload): StatisticsState => ({
    ...state,
    statisticsTableData: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [STATISTICS_ACTION_TYPES.CLEAR_STATISTICS_TABLE_DATA_STATE]: (state: StatisticsState): StatisticsState => ({
    ...state,
    statisticsTableData: createStatisticsInitialState()
  })
};

export default (state: StatisticsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
