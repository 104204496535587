/**
 * @flow
 * */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import type { WithData } from 'types';

/**
 * @class NotificationSDK
 */
class NotificationSDK extends BaseSDK {
  _rmtApiService: NetworkService;

  constructor() {
    super();
    this._rmtApiService = new NetworkService(config.rmtApi);
  }

  /**
   * fetchGetNotificationSettings
   * @returns {Promise<WithData<Object>>} -
   */
  async fetchGetNotificationSettings(): Promise<WithData<Object>> {
    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'Notification/GetNotificationSettings',
      { query_params: {}, abortController: this.setAbortController('fetchGetNotificationSettings') }
    );
    return {
      data: body.Data
    };
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * fetchAddNotificationSettings
   * @returns {Promise<WithData<Object>>} -
   */
  async fetchAddNotificationSettings(params: any): Promise<WithData<Object>> {
    const { body } = await this._rmtApiService.makeAPIPostRequest(
      'Notification/AddUpdateNotificationSettings',
      { body: params, abortController: this.setAbortController('fetchAddNotificationSettings') }
    );
    return body;
  }

  /**
   * fetchGetNotificationSettings
   * @returns {Promise<WithData<Object>>} -
   */
  async fetchGetNotificationFilter(): Promise<WithData<Object>> {
    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'Notification/GetNotificationFilter',
      { query_params: {}, abortController: this.setAbortController('fetchGetNotificationFilter'), caching: true }
    );
    return {
      data: body.Data
    };
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * fetchNotifications
   * @returns {Promise<WithData<Object>>} -
   */
  async fetchNotifications(params: any): Promise<WithData<Object>> {
    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'Notification/GetNotifications',
      { query_params: params, abortController: this.setAbortController('fetchNotifications') }
    );
    return {
      data: body.Data
    };
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * fetchMarkAsRead
   * @returns {Promise<WithData<Object>>} -
   */
  async fetchMarkAsRead(params: any): Promise<WithData<Object>> {
    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'Notification/MarkAsRead',
      { query_params: params, abortController: this.setAbortController('fetchMarkAsRead') }
    );
    return {
      data: body.Data
    };
  }

  /**
   * cancelNotificationCalls
   * @param {any} params -
   * @returns {any} -
   */
  cancelNotificationCalls(params: { name: string | Array<string> }) {
    const { name } = params;
    this.abortCall(name);
  }
}

export default new NotificationSDK();
