/**
 * @flow
 * */
import config from 'config';
import { ViewModes } from 'config/settings/constants';

import NetworkService from 'core/services/network/NetworkService';
import CacheService from 'core/services/storage/CacheService';

import type { AppSettingsParams, AppSettingsModel } from './types';

const initialAppSettings: AppSettingsModel = {
  viewMode: ViewModes.normal,
  isSizeChecked: false
};

/**
 * @class AppSettingsSDK
 */
class AppSettingsSDK {
    _rmtApiService: NetworkService;

    _storageKey: string = 'appSettings';

    constructor() {
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
   * getAppSettings
   * @returns {Promise<{data: AppSettingsModel}>} -
   */
    async getAppSettings(): Promise<{data: AppSettingsModel}> {
      // $FlowFixMe
      let data: ?AppSettingsModel = CacheService.getJson(this._storageKey);
      if (!data) data = initialAppSettings;
      return { data };
    }

    /**
   * updateUserSettings
   * @param {AppSettingsParams} params -
   * @returns {Promise<{data: AppSettingsModel}>} -
   */
    async updateUserSettings(params: AppSettingsParams): Promise<AppSettingsModel> {
      const { viewMode, language, isSizeChecked } = params;
      CacheService.setJson(this._storageKey, {
        viewMode,
        language,
        isSizeChecked
      });
      return { viewMode, language, isSizeChecked };
    }
}

export default new AppSettingsSDK();
