/**
 * @flow
 * */
import HOW_TO_ACTION_TYPES from 'modules/Dashboard/Auth/store/actions/howTo/types';
import { initialSectionState } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import { HowToState } from 'modules/Dashboard/Auth/types';
import type { Action } from 'lib/core/flow';

const initialState = (): HowToState => ({
  howToFile: initialSectionState()
});

const actionManager = {
  // How To
  [HOW_TO_ACTION_TYPES.FETCH_HOW_TO]: (state: HowToState) => ({
    ...state,
    howToFile: {
      ...state.howToFile,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [HOW_TO_ACTION_TYPES.FETCH_HOW_TO_SUCCESS]: (state: HowToState, payload) => ({
    ...state,
    howToFile: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [HOW_TO_ACTION_TYPES.FETCH_HOW_TO_FAILURE]: (state: HowToState, payload) => ({
    ...state,
    howToFile: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload
    }
  }),
  [HOW_TO_ACTION_TYPES.CLEAR_HOW_TO]: (state: HowToState) => ({
    ...state,
    howToFile: initialSectionState()
  })
};

export default (state: HowToState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
