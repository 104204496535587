import createSymbol from 'lib/core/createSymbol';

const createSettingsSymbol = createSymbol('PLAYER');

export default {
  FETCH_PLAYER_FILES: createSettingsSymbol('FETCH_PLAYER_FILES'),
  SET_FETCH_PLAYER_FILES_SUCCESS: createSettingsSymbol('SET_FETCH_PLAYER_FILES_SUCCESS'),
  SET_FETCH_PLAYER_FILES_FAILURE: createSettingsSymbol('SET_FETCH_PLAYER_FILES_FAILURE'),
  CLEAR_PLAYER_FILES_STATE: createSettingsSymbol('CLEAR_PLAYER_FILES_STATE'),

  FETCH_AML_PLAYER_FILES: createSettingsSymbol('FETCH_AML_PLAYER_FILES'),
  SET_FETCH_AML_PLAYER_FILES_SUCCESS: createSettingsSymbol('SET_FETCH_AML_PLAYER_FILES_SUCCESS'),
  SET_FETCH_AML_PLAYER_FILES_FAILURE: createSettingsSymbol('SET_FETCH_AML_PLAYER_FILES_FAILURE'),
  CLEAR_AML_PLAYER_FILES_STATE: createSettingsSymbol('CLEAR_AML_PLAYER_FILES_STATE'),

  FETCH_GET_SRMT_PLAYER_FILES: createSettingsSymbol('FETCH_GET_SRMT_PLAYER_FILES'),
  SET_FETCH_GET_SRMT_PLAYER_FILES_SUCCESS: createSettingsSymbol('SET_FETCH_GET_SRMT_PLAYER_FILES_SUCCESS'),
  SET_FETCH_GET_SRMT_PLAYER_FILES_FAILURE: createSettingsSymbol('SET_FETCH_GET_SRMT_PLAYER_FILES_FAILURE'),
  CLEAR_GET_SRMT_PLAYER_FILES_STATE: createSettingsSymbol('CLEAR_GET_SRMT_PLAYER_FILES_STATE'),

  FETCH_GET_AML_PLAYER_FILES: createSettingsSymbol('FETCH_GET_AML_PLAYER_FILES'),
  SET_FETCH_GET_AML_PLAYER_FILES_SUCCESS: createSettingsSymbol('SET_FETCH_GET_AML_PLAYER_FILES_SUCCESS'),
  SET_FETCH_GET_AML_PLAYER_FILES_FAILURE: createSettingsSymbol('SET_FETCH_GET_AML_PLAYER_FILES_FAILURE'),
  CLEAR_GET_AML_PLAYER_FILES_STATE: createSettingsSymbol('CLEAR_GET_AML_PLAYER_FILES_STATE'),

  FETCH_DELETE_SRMT_PLAYER_FILES: createSettingsSymbol('FETCH_DELETE_SRMT_PLAYER_FILES'),
  SET_FETCH_DELETE_SRMT_PLAYER_FILES_SUCCESS: createSettingsSymbol('SET_FETCH_DELETE_SRMT_PLAYER_FILES_SUCCESS'),
  SET_FETCH_DELETE_SRMT_PLAYER_FILES_FAILURE: createSettingsSymbol('SET_FETCH_DELETE_SRMT_PLAYER_FILES_FAILURE'),
  CLEAR_DELETE_SRMT_PLAYER_FILES_STATE: createSettingsSymbol('CLEAR_DELETE_SRMT_PLAYER_FILES_STATE'),

  FETCH_DELETE_AML_PLAYER_FILES: createSettingsSymbol('FETCH_DELETE_AML_PLAYER_FILES'),
  SET_FETCH_DELETE_AML_PLAYER_FILES_SUCCESS: createSettingsSymbol('SET_FETCH_DELETE_AML_PLAYER_FILES_SUCCESS'),
  SET_FETCH_DELETE_AML_PLAYER_FILES_FAILURE: createSettingsSymbol('SET_FETCH_DELETE_AML_PLAYER_FILES_FAILURE'),
  CLEAR_DELETE_AML_PLAYER_FILES_STATE: createSettingsSymbol('CLEAR_DELETE_AML_PLAYER_FILES_STATE')
};
