const checkUrlIsExists = (existUrl, currentUrl) => {
  let newArr = [];
  for (let i = 0; i < existUrl.length; i++) {
    if (existUrl[i] === currentUrl[i] || existUrl[i].search(':') >= 0) {
      newArr.push(existUrl[i] === currentUrl[i] ? existUrl[i] : currentUrl[i]);
    } else {
      newArr = [];
      break;
    }
  }
  return !!newArr.length;
};
export const detectAbsentUrl = (location, allRoutes) => {
  const existing = allRoutes.find(i => i.path === location.pathname);
  if (existing) { return existing; }
  const someLengthRoutes = allRoutes.filter(i => i.path.split('/').length === location.pathname.split('/').length && i.path.search('/:') > 0);
  if (someLengthRoutes.length) {
    for (let i = 0; i < someLengthRoutes.length; i++) {
      const existUrl = someLengthRoutes[i].path.split('/');
      const currentUrl = location.pathname.split('/');
      if (checkUrlIsExists(existUrl, currentUrl)) { return someLengthRoutes[i]; }
    }
  }
  return false;
};
