/**
 * @flow
 * */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import type { WithData } from 'types';

/**
 * @class HowToSDK
 */
class HowToSDK extends BaseSDK {
  _rmtApiService: NetworkService;

  constructor() {
    super();
    this._rmtApiService = new NetworkService(config.rmtApi);
  }

  /**
   * checkJwtAuth
   * @returns {Promise<WithData<Object>>} -
   */
  async fetchFile({ fileName }: { fileName: string }): Promise<WithData<Object>> {
    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'HowTo/GetFile',
      { query_params: { fileName }, abortController: this.setAbortController('fetchFile') }
    );
    return {
      data: body.Data
    };
  }

  async ActivePlayers(filters: any): Promise<any> {
    const { body } = await this._rmtApiService.makeAPIPostRequest(
      'Reports/GetActivePartners',
      { body: filters }
    );
    return {
      data: body.Data
    };
  }

  /**
   * checkJwtAuth
   * @returns {Promise<WithData<Object>>} -
   */
  async dbMonitoring(): Promise<WithData<Object>> {
    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'Dashboard/DbMonitoring'
    );
    return {
      data: body.Data
    };
  }

  /**
   * cancelHowToCalls
   * @param {PlayerForceCalculationParams} params -
   * @returns {any} -
   */
  cancelHowToCalls(params: { name: string | Array<string> }) {
    const { name } = params;
    this.abortCall(name);
  }
}

export default new HowToSDK();
