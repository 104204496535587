export default [
  { name: "How To View Player's Personal Page", active: false },
  { name: 'How To Manage Players Table?', active: false },
  { name: 'How To Filter Players?', active: false },
  { name: 'How To Set a Game Style To a Player?', active: false },
  { name: 'How To Set a Top Priority To a Player?', active: false },
  { name: 'How To Set an Additional Custom Category To a Player?', active: false },
  { name: 'How To Review Alerts?',
    active: false,
    open: false,
    children: [
      { name: 'How To Review Additional Alerts?', active: false },
      { name: 'How to Review False Game Style Alerts?', active: false },
      { name: 'How To Review Financial Alerts?', active: false },
      { name: 'How To Review Game Style Alerts?', active: false },
      { name: 'How To Review Linked Account Alerts?', active: false },
      { name: 'How To Review Monitoring Alerts?', active: false },
      { name: 'How To Review Multi Account Alerts?', active: false }
    ]
  },
  { name: 'How To Block Player\'s Account?', active: false },
  { name: 'How To Block Player\'s Restrictions?', active: false },
  { name: 'How To Block an Automatic Financial Calculation On a Player?', active: false },
  { name: 'How To Set a Monitoring Alert To a player?', active: false },
  { name: 'How To Create And Manage Players Groups?', active: false },
  { name: 'How To Check Reports?', active: false },
  { name: 'How To Use The Dashboard?', active: false }
];
