/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import ACTIONS from 'modules/LCRMT/Dashboard/store/actions/dashboard/dashboardActions/types';
import dashboardSDK from 'modules/LCRMT/Dashboard/core/sdk/DashboardSDK';
import {
  fetchDashboardTopPlayersDataFailure,
  fetchDashboardTopPlayersDataSuccess,
  fetchDashboardChartsDataFailure,
  fetchDashboardChartsDataSuccess,
  fetchDashboardFilterDataFailure,
  fetchDashboardFilterDataSuccess,
  fetchRoundInfoExpandDataSuccess,
  fetchRoundInfoExpandDataFailure,
  fetchRoundInfoDataFailure,
  fetchRoundInfoDataSuccess
} from 'modules/LCRMT/Dashboard/store/actions/dashboard/dashboardActions';

class DashboardController {
  static* fetchDashboard() {
    try {
      const { data } = yield dashboardSDK.GetDashboardFilters();
      yield put(fetchDashboardFilterDataSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchDashboardFilterDataFailure({ error: err.message }));
    }
  }

  static* fetchDashboardCharts({ payload }) {
    try {
      const { data } = yield dashboardSDK.GetDashboardCharts({ payload });
      yield put(fetchDashboardChartsDataSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchDashboardChartsDataFailure({ error: err.message }));
    }
  }

  static* fetchDashboardTopPlayers({ payload }) {
    try {
      const { data } = yield dashboardSDK.GetDashboardTopPlayers({ payload });
      yield put(fetchDashboardTopPlayersDataSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchDashboardTopPlayersDataFailure({ error: err.message }));
    }
  }

  static* fetchRoundInfo({ payload }) {
    try {
      const { data } = yield dashboardSDK.GetRoundInfo({ payload });
      yield put(fetchRoundInfoDataSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchRoundInfoDataFailure({ error: err.message }));
    }
  }

  static* fetchRoundInfoExpand({ payload }) {
    const { tableId, roundId, clientId, index } = payload;
    try {
      const { data } = yield dashboardSDK.GetRoundInfoExpand({ tableId, roundId, clientId });
      yield put(fetchRoundInfoExpandDataSuccess({ index, data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchRoundInfoExpandDataFailure({ index, error: err.message }));
    }
  }

  static* cancelDashboardSDKCalls({ payload }) {
    const { name } = payload;
    yield dashboardSDK.cancelDashboardSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.FETCH_LCRMT_DASHBOARD_FILTER, DashboardController.fetchDashboard);
    yield takeLatest(ACTIONS.FETCH_LCRMT_DASHBOARD_CHARTS, DashboardController.fetchDashboardCharts);
    yield takeLatest(ACTIONS.FETCH_LCRMT_DASHBOARD_TOP_PLAYERS, DashboardController.fetchDashboardTopPlayers);
    yield takeLatest(ACTIONS.FETCH_ROUND_INFO, DashboardController.fetchRoundInfo);
    yield takeLatest(ACTIONS.FETCH_ROUND_INFO_EXPAND, DashboardController.fetchRoundInfoExpand);
    yield takeLatest(ACTIONS.CANCEL_DASHBOARD_SDK_CALL, DashboardController.cancelDashboardSDKCalls);
  }
}

export default DashboardController.actionsWatcher;
