/**
 * @flow
 * */
import { takeLatest, put } from 'redux-saga/effects';

import LCRMT_SETTINGS_TYPES from 'modules/LCRMT/Settings/store/actions/settingsActions/types';
import {
  fetchGameTableSettingsFilterSuccess,
  fetchGameTableSettingsFilterFailure,
  deleteNotificationSettingsFailure,
  deleteNotificationSettingsSuccess,
  fetchNotificationSettingsFailure,
  fetchNotificationSettingsSuccess,
  saveNotificationSettingsFailure,
  saveNotificationSettingsSuccess,
  fetchGameTableSettingsSuccess,
  fetchGameTableSettingsFailure,
  saveGameTableSettingsSuccess,
  saveGameTableSettingsFailure
} from 'modules/LCRMT/Settings/store/actions/settingsActions';
import settingSdk from 'modules/LCRMT/Settings/core/sdk';

class SettingsController {
  static* fetchNotificationSettings() {
    try {
      const { data } = yield settingSdk.fetchNotificationSettings();
      yield put(fetchNotificationSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchNotificationSettingsFailure({ error: err.message }));
    }
  }

  static* saveNotificationSettings({ payload }) {
    const { params } = payload;
    try {
      const { data } = yield settingSdk.saveNotificationSettings({ params });
      yield put(saveNotificationSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(saveNotificationSettingsFailure({ error: err.message }));
    }
  }

  static* deleteNotificationSettings({ payload }) {
    const { Id } = payload;
    try {
      const { data } = yield settingSdk.deleteNotificationSettings({ Id });
      yield put(deleteNotificationSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(deleteNotificationSettingsFailure({ error: err.message }));
    }
  }

  static* fetchGameTableSettingsFilter() {
    try {
      const { data } = yield settingSdk.fetchGameTableSettingsFilter();
      yield put(fetchGameTableSettingsFilterSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchGameTableSettingsFilterFailure({ error: err.message }));
    }
  }

  static* fetchGameTableSettings({ payload }) {
    const { gameId } = payload;
    try {
      const { data } = yield settingSdk.fetchGameTableSettings({ gameId });
      yield put(fetchGameTableSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchGameTableSettingsFailure({ error: err.message }));
    }
  }

  static* saveGameTableSettings({ payload }) {
    const savedData = payload;
    try {
      const { data } = yield settingSdk.saveGameTableSettings(savedData);
      yield put(saveGameTableSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(saveGameTableSettingsFailure({ error: err.message }));
    }
  }

  static* cancelSettingsSDKCalls({ payload }) {
    const { name } = payload;
    yield settingSdk.cancelSettingsSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(LCRMT_SETTINGS_TYPES.CANCEL_LCRMT_SETTINGS_CALL, SettingsController.cancelSettingsSDKCalls);
    yield takeLatest(LCRMT_SETTINGS_TYPES.FETCH_NOTIFICATION_SETTINGS, SettingsController.fetchNotificationSettings);
    yield takeLatest(LCRMT_SETTINGS_TYPES.SAVE_NOTIFICATION_SETTINGS, SettingsController.saveNotificationSettings);
    yield takeLatest(LCRMT_SETTINGS_TYPES.DELETE_NOTIFICATION_SETTINGS, SettingsController.deleteNotificationSettings);
    yield takeLatest(LCRMT_SETTINGS_TYPES.DELETE_NOTIFICATION_SETTINGS, SettingsController.deleteNotificationSettings);
    yield takeLatest(LCRMT_SETTINGS_TYPES.FETCH_GAME_TABLE_SETTINGS_FILTER, SettingsController.fetchGameTableSettingsFilter);
    yield takeLatest(LCRMT_SETTINGS_TYPES.FETCH_GAME_TABLE_SETTINGS, SettingsController.fetchGameTableSettings);
    yield takeLatest(LCRMT_SETTINGS_TYPES.SAVE_GAME_TABLE_SETTINGS, SettingsController.saveGameTableSettings);
  }
}

export default SettingsController.actionsWatcher;
