/**
 * @flow
 * */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';

/**
 * @class AuthSDK
 */
class AuthSDK {
  _rmtApiService: NetworkService;

  constructor() {
    this._rmtApiService = new NetworkService(config.rmtApi);
  }

  /**
   * checkJwtAuth
   * @returns {Promise<{ user: Object }>} -
   */
  async checkJwtAuth() :Promise<{ user: Object }> {
    const { body } = await this._rmtApiService.makeAPIPostRequest('Auth/Check');

    return {
      user: body.Data
    };
  }
}

export default new AuthSDK();
