import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createAlertsSymbol = createSymbol('LCRMT_HOURLY_ALERTS');
const createAlertsInternalSymbol = createInternalSymbol('LCRMT_HOURLY_ALERTS');

export default {
  // hourly alerts
  FETCH_LCRMT_HOURLY_ALERTS: createAlertsSymbol('FETCH_LCRMT_HOURLY_ALERTS'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_SUCCESS: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_SUCCESS'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_FAILURE: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_FAILURE'),
  SET_LCRMT_HOURLY_ALERTS_DATA_CHANGE_AFTER_REVIEW: createAlertsSymbol('SET_LCRMT_HOURLY_ALERTS_DATA_CHANGE_AFTER_REVIEW'),
  CLEAR_LCRMT_HOURLY_ALERTS_STATE: createAlertsInternalSymbol('CLEAR_LCRMT_HOURLY_ALERTS_STATE'),

  // hourly alerts expand
  FETCH_LCRMT_HOURLY_ALERTS_EXPAND: createAlertsSymbol('FETCH_LCRMT_HOURLY_ALERTS_EXPAND'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_SUCCESS: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_SUCCESS'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_FAILURE: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_FAILURE'),

  // hourly alerts review
  FETCH_LCRMT_HOURLY_ALERTS_REVIEW: createAlertsSymbol('FETCH_LCRMT_HOURLY_ALERTS_REVIEW'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_SUCCESS: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_SUCCESS'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_FAILURE: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_FAILURE'),
  CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_STATE: createAlertsInternalSymbol('CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_STATE'),

  // hourly alerts review action
  FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION: createAlertsSymbol('FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_SUCCESS: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_SUCCESS'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_FAILURE: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_FAILURE'),
  CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_STATE: createAlertsInternalSymbol('CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_STATE'),

  // hourly alerts review expand
  FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND: createAlertsSymbol('FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_SUCCESS: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_SUCCESS'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_FAILURE: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_FAILURE'),

  // hourly alerts review history
  FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY: createAlertsSymbol('FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_SUCCESS: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_SUCCESS'),
  SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_FAILURE: createAlertsSymbol('SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_FAILURE'),
  CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_STATE: createAlertsInternalSymbol('CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_STATE'),

  // statistics
  FETCH_STATISTICS: createAlertsSymbol('FETCH_STATISTICS'),
  SET_FETCH_STATISTICS_SUCCESS: createAlertsSymbol('SET_FETCH_STATISTICS_SUCCESS'),
  SET_FETCH_STATISTICS_FAILURE: createAlertsSymbol('SET_FETCH_STATISTICS_FAILURE'),
  CLEAR_STATISTICS_STATE: createAlertsInternalSymbol('CLEAR_STATISTICS_STATE'),

  // true count
  FETCH_TRUE_COUNT: createAlertsSymbol('FETCH_TRUE_COUNT'),
  SET_FETCH_TRUE_COUNT_SUCCESS: createAlertsSymbol('SET_FETCH_TRUE_COUNT_SUCCESS'),
  SET_FETCH_TRUE_COUNT_FAILURE: createAlertsSymbol('SET_FETCH_TRUE_COUNT_FAILURE'),
  CLEAR_TRUE_COUNTY_STATE: createAlertsInternalSymbol('CLEAR_TRUE_COUNTY_STATE'),

  // cancel call
  CANCEL_HOURLY_ALERT_SDK_CALL: createAlertsSymbol('CANCEL_HOURLY_ALERT_SDK_CALL')
};
