import type {
  StatisticsState,
  TableStatisticsState
} from 'modules/LCRMT/Alerts/types';

const InitialState = {
  status: null,
  error: null,
  data: null
};

const createStatisticsInitialState = (): StatisticsState => InitialState;
const createTableStatisticsInitialState = (): TableStatisticsState => {};

export {
  createStatisticsInitialState,
  createTableStatisticsInitialState
};
