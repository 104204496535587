/**
 * @flow
 * */
import { initialSectionState } from 'lib/core/initialStates';
import AM_ACTION_TYPES from 'modules/AML/store/actions/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import { AMLState } from 'modules/AML/types';
import type { Action } from 'lib/core/flow';

const initialState = (): AMLState => ({
  dashboardCharts: initialSectionState()
});

const actionManager = {
  // aml dashboard charts
  [AM_ACTION_TYPES.FETCH_AML_DASHBOARD]: (state: AMLState) => ({
    ...state,
    dashboardCharts: {
      ...state.dashboardCharts,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_DASHBOARD_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    dashboardCharts: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_DASHBOARD_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    dashboardCharts: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_DASHBOARD_STATE]: (state: AMLState) => ({
    ...state,
    dashboardCharts: initialSectionState()
  })
};

export default (state: AMLState = initialState(), action: Action) => {
  if (!actionManager[action.type]) return state;

  return actionManager[action.type](state, action.payload);
};
