/**
 * @flow
 * */
import createSymbol from 'lib/core/createSymbol';

const createSettingsSymbol = createSymbol('APP_SETTINGS');

export default {
  FETCH_APP_SETTINGS: createSettingsSymbol('FETCH_APP_SETTINGS'),
  SET_FETCH_APP_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_APP_SETTINGS_SUCCESS'),
  SET_FETCH_APP_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_APP_SETTINGS_FAILURE'),
  UPDATE_APP_SETTINGS: createSettingsSymbol('UPDATE_APP_SETTINGS'),
  SET_UPDATE_APP_SETTINGS_SUCCESS: createSettingsSymbol('SET_UPDATE_APP_SETTINGS_SUCCESS'),
  SET_UPDATE_APP_SETTINGS_FAILURE: createSettingsSymbol('SET_UPDATE_APP_SETTINGS_FAILURE'),

  // Settings
  SET_FETCH_AGP_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_AGP_SETTINGS_SUCCESS'),
  UPDATE_AGP_SETTINGS: createSettingsSymbol('UPDATE_AGP_SETTINGS'),
  SET_UPDATE_AGP_SETTINGS_SUCCESS: createSettingsSymbol('SET_UPDATE_AGP_SETTINGS_SUCCESS'),
  SET_UPDATE_AGP_SETTINGS_FAILURE: createSettingsSymbol('SET_UPDATE_AGP_SETTINGS_FAILURE'),
  CLEAR_AGP_SETTINGS: createSettingsSymbol('CLEAR_AGP_SETTINGS')
};
