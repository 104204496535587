/**
 * @flow
 * */
/* eslint-disable camelcase */

import { createSelector } from 'reselect';

const getAppState = (state: Object) => state.application;

export const internetStatusSelector: Function = createSelector(
  getAppState,
  (state: Object) => state.internetStatus
);

export const appStateSelector: Function = createSelector(
  getAppState,
  ({ isLoggedIn, isReady }) => ({ isLoggedIn, isReady })
);
