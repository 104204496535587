/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import LCRMT_HOURLY_ALERTS_TYPES from './types';

// hourly alert data
export const fetchHourlyAlertTabData = ({ tabKey, filters = {} }: Object):Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS,
  payload: { tabKey, filters }
});

export const fetchHourlyAlertTabDataSuccess = ({ tabKey, data, totalCount, passTotalCount }: Object):Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_SUCCESS,
  payload: { tabKey, data, passTotalCount, totalCount }
});

export const fetchHourlyAlertTabDataFailure = ({ tabKey, error }: Object):Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_FAILURE,
  payload: { tabKey, error }
});

export const removeHourlyAlertsState = ({ tabKey }: Object):Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_STATE,
  payload: { tabKey }
});

// alert review
export const fetchHourlyAlertsReview = ({ alertId, tabKey, alertType }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW,
  payload: { alertId, tabKey, alertType }
});
export const setFetchHourlyAlertsReviewSuccess = ({ tabKey, data }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchHourlyAlertsReviewFailure = ({ tabKey, error }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_FAILURE,
  payload: { tabKey, error }
});
export const clearHourlyAlertsReview = ({ tabKey }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_STATE,
  payload: { tabKey }
});

// alert review expand
export const fetchHourlyAlertsExpand = ({ tabKey, alertId, index }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_EXPAND,
  payload: { tabKey, alertId, index }
});
export const setFetchHourlyAlertsExpandSuccess = ({ tabKey, data, index }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_SUCCESS,
  payload: { tabKey, data, index }
});
export const setFetchHourlyAlertsExpandFailure = ({ tabKey, index, error }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_FAILURE,
  payload: { tabKey, index, error }
});

// Review action
export const fetchHourlyAlertsReviewAction = ({ params }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION,
  payload: { params }
});
export const setFetchHourlyAlertsReviewActionSuccess = ({ tabKey, data }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchHourlyAlertsReviewActionFailure = ({ tabKey, error }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_FAILURE,
  payload: { tabKey, error }
});
export const clearHourlyAlertsReviewActionState = ({ tabKey }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_STATE,
  payload: { tabKey }
});

// alert review expand
export const fetchHourlyAlertsReviewExpand = ({ roundId, tableId, clientId, tabKey, index }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND,
  payload: { roundId, tableId, clientId, tabKey, index }
});
export const setFetchHourlyAlertsReviewExpandSuccess = ({ tabKey, data, index }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_SUCCESS,
  payload: { tabKey, data, index }
});
export const setFetchHourlyAlertsReviewExpandFailure = ({ tabKey, index, error }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_FAILURE,
  payload: { tabKey, index, error }
});

// alert review history
export const fetchHourlyAlertsReviewHistory = ({ alertId, tabKey }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY,
  payload: { alertId, tabKey }
});
export const setFetchHourlyAlertsReviewHistorySuccess = ({ tabKey, data }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchHourlyAlertsReviewHistoryFailure = ({ tabKey, error }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_FAILURE,
  payload: { tabKey, error }
});
export const clearHourlyAlertsReviewHistory = ({ tabKey }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_STATE,
  payload: { tabKey }
});

// alert review statistics
export const fetchStatistics = ({ clientId, tabKey, dates, statisticType }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_STATISTICS,
  payload: { clientId, tabKey, dates, statisticType }
});
export const setFetchStatisticsSuccess = ({ tabKey, statisticType, data }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_STATISTICS_SUCCESS,
  payload: { tabKey, data, statisticType }
});
export const setFetchStatisticsFailure = ({ tabKey, statisticType, error }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_STATISTICS_FAILURE,
  payload: { tabKey, error, statisticType }
});
export const clearStatistics = ({ tabKey }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.CLEAR_STATISTICS_STATE,
  payload: { tabKey }
});

// true count
export const fetchTrueCount = ({ alertId, tabKey }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.FETCH_TRUE_COUNT,
  payload: { alertId, tabKey }
});
export const setFetchTrueCountSuccess = ({ tabKey, data }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_TRUE_COUNT_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchTrueCountFailure = ({ tabKey, error }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_TRUE_COUNT_FAILURE,
  payload: { tabKey, error }
});
export const clearTrueCountState = ({ tabKey }: Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.CLEAR_TRUE_COUNTY_STATE,
  payload: { tabKey }
});

// cancel call
export const cancelHourlyAlertsSDKCAll = ({ name }:Object): Action => ({
  type: LCRMT_HOURLY_ALERTS_TYPES.CANCEL_HOURLY_ALERT_SDK_CALL,
  payload: { name }
});
