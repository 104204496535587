/**
 * @flow
 * */

import { put, takeLatest } from 'redux-saga/effects';

//
import PartnerManagementSDK from 'modules/Admin/PartnerLicenseManagement/core/sdk/PartnerManagementSDK';
import { parsePartnersModelFromResponse } from 'modules/Admin/PartnerLicenseManagement/utils/parsers';
import ACTIONS from 'modules/Admin/PartnerLicenseManagement/store/actions/types';
import {
  fetchPartnerManagementFailure,
  fetchPartnerManagementSuccess,
  setFetchDeactivatePartnerFailure,
  setFetchDeactivatePartnerSuccess,
  setFetchPartnersFailure,
  setFetchPartnersSuccess,
  setFetchSrmtDataSuccess,
  setFetchSrmtDataFailure,
  setFetchTemplatesSuccess,
  setFetchTemplatesFailure,
  setFetchAmlTemplatesSuccess,
  setFetchAmlTemplatesFailure,
  setFetchAmlDataSuccess,
  setFetchAmlDataFailure,
  setFetchAllAMLSuccess,
  setFetchAllAMLFailure,
  setFetchRiskTypesSuccess,
  setFetchRiskTypesFailure, setChangedDataSuccess, setChangedDataFailure
} from 'modules/Admin/PartnerLicenseManagement/store/actions';

import type { AmlDataRequest, DeactivatePartnerRequest, SrmtDataRequest } from 'modules/Admin/PartnerLicenseManagement/types';
import { makeAmlData, makeSrmtData } from 'modules/Admin/PartnerLicenseManagement/utils/initialData';

class PartnerManagementController {
  static* fetchPartnerManagement({ payload }) {
    const { start, count, passTotalCount, filters } = payload;
    try {
      const response = yield PartnerManagementSDK.fetchPartnerManagement({ filters, count, start, passTotalCount });
      const { data, totalCount } = parsePartnersModelFromResponse(response);
      yield put(fetchPartnerManagementSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchPartnerManagementFailure(err.message));
    }
  }

  static* fetchPartners() {
    try {
      const data = yield PartnerManagementSDK.fetchPartners();
      yield put(setFetchPartnersSuccess({ data }));
    } catch (err) {
      yield put(setFetchPartnersFailure(err.message));
    }
  }

  static* fetchTemplates() {
    try {
      const data = yield PartnerManagementSDK.fetchTemplates();
      yield put(setFetchTemplatesSuccess({ data }));
    } catch (err) {
      yield put(setFetchTemplatesFailure(err.message));
    }
  }

  static* fetchAmlTemplates() {
    try {
      const data = yield PartnerManagementSDK.fetchAmlTemplates();
      yield put(setFetchAmlTemplatesSuccess({ data }));
    } catch (err) {
      yield put(setFetchAmlTemplatesFailure(err.message));
    }
  }

  static* deactivatePartner({ payload }: { payload: DeactivatePartnerRequest }) {
    try {
      yield PartnerManagementSDK.deactivatePartner(payload);
      yield put(setFetchDeactivatePartnerSuccess());
    } catch (err) {
      yield put(setFetchDeactivatePartnerFailure(err.message));
    }
  }

  static* fetchSrmtData({ payload }: { payload: SrmtDataRequest }) {
    try {
      const data = yield PartnerManagementSDK.fetchSrmtData(payload);
      yield put(setFetchSrmtDataSuccess({ data: makeSrmtData(data) }));
    } catch (err) {
      yield put(setFetchSrmtDataFailure(err.message));
    }
  }

  static* fetchAmlData({ payload }: { payload: AmlDataRequest }) {
    const { fromAml } = payload;
    try {
      let data;
      if (fromAml) {
        data = yield PartnerManagementSDK.fetchAmlSettingsData(payload);
      } else {
        data = yield PartnerManagementSDK.fetchAmlData(payload);
      }
      yield put(setFetchAmlDataSuccess({ data: makeAmlData(data) }));
    } catch (err) {
      yield put(setFetchAmlDataFailure(err.message));
    }
  }

  static* fetchAllAmlSettings() {
    try {
      const data = yield PartnerManagementSDK.fetchAllAmlSettings();
      yield put(setFetchAllAMLSuccess({ data }));
    } catch (err) {
      yield put(setFetchAllAMLFailure(err.message));
    }
  }

  static* fetchRiskTypesSettings() {
    try {
      const data = yield PartnerManagementSDK.fetchRiskTypesSettings();
      yield put(setFetchRiskTypesSuccess({ data }));
    } catch (err) {
      yield put(setFetchRiskTypesFailure(err.message));
    }
  }

  static* setChangedData({ payload }: Object) {
    const { fromAml } = payload;
    try {
      if (fromAml) {
        yield PartnerManagementSDK.setAmlChangedData(payload);
      } else {
        yield PartnerManagementSDK.setChangedData(payload);
      }
      yield put(setChangedDataSuccess());
    } catch (err) {
      yield put(setChangedDataFailure(err.message));
    }
  }

  static* cancelPartnerManagementSDKCalls({ payload }) {
    const { name } = payload;
    yield PartnerManagementSDK.cancelPartnerManagementSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.SET_CHANGED_DATA, PartnerManagementController.setChangedData);
    yield takeLatest(ACTIONS.FETCH_ALL_AML_SETTINGS, PartnerManagementController.fetchAllAmlSettings);
    yield takeLatest(ACTIONS.FETCH_RISK_TYPE_SETTINGS, PartnerManagementController.fetchRiskTypesSettings);
    yield takeLatest(ACTIONS.CANCEL_PM_CALL, PartnerManagementController.cancelPartnerManagementSDKCalls);
    yield takeLatest(ACTIONS.FETCH_MANAGEMENT_LIST, PartnerManagementController.fetchPartnerManagement);
    yield takeLatest(ACTIONS.FETCH_PARTNERS, PartnerManagementController.fetchPartners);
    yield takeLatest(ACTIONS.FETCH_TEMPLATES, PartnerManagementController.fetchTemplates);
    yield takeLatest(ACTIONS.FETCH_AML_TEMPLATES, PartnerManagementController.fetchAmlTemplates);
    yield takeLatest(ACTIONS.FETCH_DEACTIVATE_PARTNER, PartnerManagementController.deactivatePartner);
    yield takeLatest(ACTIONS.FETCH_SRMT_DATA, PartnerManagementController.fetchSrmtData);
    yield takeLatest(ACTIONS.FETCH_AML_DATA, PartnerManagementController.fetchAmlData);
  }
}

export default PartnerManagementController.actionsWatcher;
