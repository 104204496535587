/**
 * @flow
 * */
import type { Action as BaseAction } from 'lib/core/flow';

import AUTH_ACTION_TYPES from './types';

export const fetchJWTAuth = ({ data }: any): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH,
  payload: data
});

export const FetchJWTAuthFailed = (error: string): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_FAILED,
  payload: error
});

export const FetchJWTAuthSucceed = ({ data }: any): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_SUCCESS,
  payload: data
});

export const fetchJWTAuthChangePassword = ({ data }: any): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_CHANGE_PASSWORD,
  payload: data
});

export const FetchJWTAuthChangePasswordFailed = (error: string): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_CHANGE_PASSWORD_FAILED,
  payload: error
});

export const FetchJWTAuthChangePasswordSucceed = ({ data }: any): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_CHANGE_PASSWORD_SUCCESS,
  payload: data
});

export const fetchJWTAuthTwoStep = ({ data }: any): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_TWO_STEP,
  payload: data
});

export const FetchJWTAuthTwoStepFailed = (error: string): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_TWO_STEP_FAILED,
  payload: error
});

export const FetchJWTAuthTwoStepSucceed = ({ data }: any): BaseAction => ({
  type: AUTH_ACTION_TYPES.FETCH_JWT_AUTH_TWO_STEP_SUCCESS,
  payload: data
});
