export const makeActionData = (params, data) => {
  if (params.id) {
    const newData = data.map(item => {
      if (item.Id === params.id) {
        // eslint-disable-next-line no-param-reassign
        item.Comment = params.data.Comment;
        // eslint-disable-next-line no-param-reassign
        item.CommentType = params.data.CommentType;
        // eslint-disable-next-line no-param-reassign
        item.Name = params.data.Name;
        // eslint-disable-next-line no-param-reassign
        item.Modified = params.data.Modified;
      }
      return item;
    });
    return newData;
  }
  if (params.page === 1) {
    return [params.data, ...data];
  }
  return data;
};
