import SETTINGS_ACTIONS from '../types';

// Alert Monitor Setting Table
export const fetchAlertMonitorSettingsTable = (filters) => ({
  type: SETTINGS_ACTIONS.FETCH_ALERT_MONITOR_SETTINGS_TABLE,
  payload: { filters }
});
export const setFetchAlertMonitorSettingsTableSuccess = ({ data, totalCount, passTotalCount }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAlertMonitorSettingsTableFailure = ({ error }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_FAILURE,
  payload: { error }
});
export const clearAlertMonitorSettingsTableState = () => ({
  type: SETTINGS_ACTIONS.CLEAR_ALERT_MONITOR_SETTINGS_TABLE_STATE
});

// Alert Monitor Setting
export const fetchAlertMonitorSettings = ({ Id }) => ({
  type: SETTINGS_ACTIONS.FETCH_ALERT_MONITOR_SETTINGS,
  payload: { Id }
});
export const setFetchAlertMonitorSettingsSuccess = ({ data }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ALERT_MONITOR_SETTINGS_SUCCESS,
  payload: { data }
});
export const setFetchAlertMonitorSettingsFailure = ({ error }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ALERT_MONITOR_SETTINGS_FAILURE,
  payload: { error }
});
export const clearAlertMonitorSettingsState = () => ({
  type: SETTINGS_ACTIONS.CLEAR_ALERT_MONITOR_SETTINGS_STATE
});

// Delete Alert Monitor Setting
export const fetchDeleteAlertMonitorSettings = ({ Id }) => ({
  type: SETTINGS_ACTIONS.FETCH_DELETE_ALERT_MONITOR_SETTINGS,
  payload: { Id }
});
export const setFetchDeleteAlertMonitorSettingsSuccess = ({ Id }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_SUCCESS,
  payload: { Id }
});
export const setFetchDeleteAlertMonitorSettingsFailure = ({ error }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_FAILURE,
  payload: { error }
});
export const clearDeleteAlertMonitorSettings = () => ({
  type: SETTINGS_ACTIONS.CLEAR_DELETE_ALERT_MONITOR_SETTINGS
});

// Save Alert Monitor Setting
export const fetchSaveAlertMonitorSettings = ({ updateData, editRow, page }) => ({
  type: SETTINGS_ACTIONS.FETCH_SAVE_ALERT_MONITOR_SETTINGS,
  payload: { updateData, editRow, page }
});
export const setFetchSaveAlertMonitorSettingsSuccess = ({ data, editRow, page }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_SUCCESS,
  payload: { data, editRow, page }
});
export const setFetchSaveAlertMonitorSettingsFailure = ({ error }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_FAILURE,
  payload: { error }
});
export const clearSaveAlertMonitorSettings = () => ({
  type: SETTINGS_ACTIONS.CLEAR_SAVE_ALERT_MONITOR_SETTINGS_FAILURE
});
