/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import STATISTICS_ACTION_TYPES from './types';

// statistics
export const fetchStatistics = (): Action => ({
  type: STATISTICS_ACTION_TYPES.FETCH_STATISTICS
});
export const setFetchStatisticsSuccess = ({ data }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_SUCCESS,
  payload: { data }
});
export const setFetchStatisticsFailure = ({ error }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_FAILURE,
  payload: { error }
});
export const clearStatisticsState = (): Action => ({
  type: STATISTICS_ACTION_TYPES.CLEAR_STATISTICS_STATE
});

// table statistics
export const fetchTableStatistics = ({ tableId, spinCount }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.FETCH_TABLE_STATISTICS,
  payload: { tableId, spinCount }
});
export const setFetchTableStatisticsSuccess = ({ data, tableId }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_TABLE_STATISTICS_SUCCESS,
  payload: { data, tableId }
});
export const setFetchTableStatisticsFailure = ({ error, tableId }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_TABLE_STATISTICS_FAILURE,
  payload: { error, tableId }
});
export const clearTableStatisticsState = ({ tableId }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.CLEAR_TABLE_STATISTICS_STATE,
  payload: { tableId }
});

// roulette statistics
export const fetchRouletteStatistics = ({ type, date, tableId, spinCount }:Object): Action => ({
  type: STATISTICS_ACTION_TYPES.FETCH_ROULETTE_STATISTICS,
  payload: { type, date, tableId, spinCount }
});
export const setFetchRouletteStatisticsSuccess = ({ data }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_STATISTICS_SUCCESS,
  payload: { data }
});
export const setFetchRouletteStatisticsFailure = ({ error }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_STATISTICS_FAILURE,
  payload: { error }
});
export const clearRouletteStatisticsState = (): Action => ({
  type: STATISTICS_ACTION_TYPES.CLEAR_ROULETTE_STATISTICS_STATE
});

// biased statistics
export const fetchRouletteBiasedStatistics = (): Action => ({
  type: STATISTICS_ACTION_TYPES.FETCH_ROULETTE_BIASED_STATISTICS
});
export const setFetchRouletteBiasedStatisticsSuccess = ({ data }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_BIASED_STATISTICS_SUCCESS,
  payload: { data }
});
export const setFetchRouletteBiasedStatisticsFailure = ({ error }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_ROULETTE_BIASED_STATISTICS_FAILURE,
  payload: { error }
});
export const clearRouletteBiasedStatisticsState = (): Action => ({
  type: STATISTICS_ACTION_TYPES.CLEAR_ROULETTE_BIASED_STATISTICS_STATE
});

// card statistics
export const fetchCardStatistics = (): Action => ({
  type: STATISTICS_ACTION_TYPES.FETCH_CARD_STATISTICS
});
export const setFetchCardStatisticsSuccess = ({ data }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_CARD_STATISTICS_SUCCESS,
  payload: { data }
});
export const setFetchCardStatisticsFailure = ({ error }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_CARD_STATISTICS_FAILURE,
  payload: { error }
});
export const clearCardStatisticsState = (): Action => ({
  type: STATISTICS_ACTION_TYPES.CLEAR_CARD_STATISTICS_STATE
});

// time range data
export const fetchTimeRange = ({ tableId, date }:Object): Action => ({
  type: STATISTICS_ACTION_TYPES.FETCH_TIME_RANGE,
  payload: { tableId, date }
});
export const setFetchTimeRangeSuccess = ({ data }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_TIME_RANGE_SUCCESS,
  payload: { data }
});
export const setFetchTimeRangeFailure = ({ error }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_TIME_RANGE_FAILURE,
  payload: { error }
});
export const clearTimeRangeState = (): Action => ({
  type: STATISTICS_ACTION_TYPES.CLEAR_TIME_RANGE_STATE
});

// card statistic table data
export const fetchStatisticsTableData = ({ shoeId }:Object): Action => ({
  type: STATISTICS_ACTION_TYPES.FETCH_STATISTICS_TABLE_DATA,
  payload: { shoeId }
});
export const setFetchStatisticsTableDataSuccess = ({ data }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_TABLE_DATA_SUCCESS,
  payload: { data }
});
export const setFetchStatisticsTableDataFailure = ({ error }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.SET_FETCH_STATISTICS_TABLE_DATA_FAILURE,
  payload: { error }
});
export const clearStatisticsTableDataState = (): Action => ({
  type: STATISTICS_ACTION_TYPES.CLEAR_STATISTICS_TABLE_DATA_STATE
});

// cancel call
export const cancelStatisticSDKCalls = ({ name }: Object): Action => ({
  type: STATISTICS_ACTION_TYPES.CANCEL_STATISTIC_SDK_CALL,
  payload: { name }
});
