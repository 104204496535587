export const fraudMonitor = {
  key: 'fraudMonitor',
  displayName: 'Fraud Monitor',
  tabs: {
    'linkedAccount': {
      displayTitle: 'Linked Account',
      alertType: 2
    },
    'multiAccount': {
      displayTitle: 'Multi Account',
      alertType: 1
    },
    'gameStyle': {
      displayTitle: 'Game Style',
      alertType: 9
    }
  },
  tabsTypeId: {
    'linkedAccount': {
      displayTitle: 'Linked Account',
      alertType: 2
    },
    'multiAccount': {
      displayTitle: 'Multi Account',
      alertType: 1
    },
    'gameStyle': {
      displayTitle: 'Game Style',
      alertType: 9
    }
  },
  tabKeys: {
    linkedAccount: 'linkedAccount',
    multiAccount: 'multiAccount',
    gameStyle: 'gameStyle'
  },
  tabKeysById: {
    4: 'linkedAccount',
    9: 'gameStyle',
    8: 'multiAccount'
  }
};

export const tabModelKey = {
  falseGameStyle: 'FalseGameStyle',
  multiAccount: 'Multiaccount',
  additional: 'Additional',
  monitoring: 'Monitoring',
  linkedAccount: 'Linked Account',
  gameStyle: 'GameStyle',
  financial: 'Financial',
  zipCode: 'ZipCode'
};

export const infoMonitor = {
  key: 'infoMonitor',
  displayName: 'Info Monitor',
  tabs: {
    'financial': {
      displayTitle: 'Financial',
      alertType: 6
    },
    'monitoring': {
      displayTitle: 'Monitoring',
      alertType: 3
    },
    'additional': {
      displayTitle: 'Additional',
      alertType: 10
    },
    'falseGameStyle': {
      displayTitle: 'False Game Style',
      alertType: 15
    }
  },
  tabKeys: {
    financial: 'financial',
    monitoring: 'monitoring',
    additional: 'additional',
    falseGameStyle: 'falseGameStyle'
  },
  tabKeysById: {
    6: 'financial',
    3: 'monitoring',
    10: 'additional',
    15: 'falseGameStyle'
  }
};

export const partnerLevelAlerts = {
  key: 'partnerLevelAlerts',
  displayName: 'Partner Level Alerts',
  tabs: {
    'profitLimitAlerts': {
      displayTitle: 'Profit Limit Alerts',
      alertType: 1
    },
    'betAlerts': {
      displayTitle: 'Bet Alerts',
      alertType: 3
    },
    'tipAlerts': {
      displayTitle: 'Alerts on Tips',
      alertType: 4
    }
  },
  tabKeys: {
    profitLimitAlerts: 'profitLimitAlerts',
    betAlerts: 'betAlerts',
    tipAlerts: 'tipAlerts'
  }
};

export const paginationSelectorData = [
  {
    label: '20',
    value: 20
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  },
  {
    label: '150',
    value: 150
  }
];

export default {
  fraudMonitor,
  infoMonitor
};
