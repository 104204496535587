/**
 * @flow
 * */
import NetworkService from 'core/services/network/NetworkService';
import {
  RouletteBiasesStatisticsModel,
  statisticTableDataParams,
  RouletteStatisticsParams,
  RouletteStatisticsModel,
  statisticTableDataModel,
  TableStatisticsParams,
  TableStatisticsModel,
  CardStatisticsModel,
  StatisticsModel,
  timeRangeParam,
  timeRangeModel
} from 'modules/LCRMT/EquipmentIntegrity/types';
import BaseSDK from 'core/sdk/BaseSDK';
import type { WithData } from 'types';
import config from 'config';

/**
   * @class StatisticsSDK
   */
class StatisticsSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * getStatistics
     * @returns {Promise<WithData<StatisticsModel>>} -
     */
    async getStatistics(): Promise<WithData<StatisticsModel>> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LiveCasinoStatistic/GetSpinResultsAllTables',
        { abortController: this.setAbortController('getStatistics') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getStatisticsForTable
     * @param {TableStatisticsParams} params -
     * @returns {Promise<WithData<TableStatisticsModel>>} -
     */
    async getStatisticsForTable(params: TableStatisticsParams): Promise<WithData<TableStatisticsModel>> {
      const { tableId, spinCount } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LiveCasinoStatistic/GetSpinResults',
        { query_params: { tableId, spinCount } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getRouletteStatistics
     * @param {RouletteStatisticsParams} params -
     * @returns {Promise<WithData<TableStatisticsModel>>} -
     */
    async getRouletteStatistics(params: RouletteStatisticsParams): Promise<{ data: RouletteStatisticsModel}> {
      const { type, date, tableId, spinCount } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LiveCasinoStatistic/GetRouletteStatistics',
        { query_params: { type, date, tableId, spinCount }, abortController: this.setAbortController('getRouletteStatistics') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getRouletteBiasedStatistics
     * @returns {Promise<WithData<RouletteBiasesStatisticsModel>>} -
     */
    async getRouletteBiasedStatistics(): Promise<{ data: RouletteBiasesStatisticsModel}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LiveCasinoStatistic/GetRouletteTableStatistics',
        { query_params: { }, abortController: this.setAbortController('getRouletteBiasedStatistics') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getCardStatistics
     * @returns {Promise<Array<CardStatisticsModel>>} -
     */
    async getCardStatistics(): Promise<{ data: Array<CardStatisticsModel>}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LiveCasinoStatistic/GetCardGameTables',
        { query_params: { }, abortController: this.setAbortController('getCardStatistics') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getTimeRange
     * @param {timeRangeParam} params -
     * @returns {Promise<Array<timeRangeModel>>} -
     */
    async getTimeRange(params: timeRangeParam): Promise<{ data: Array<timeRangeModel>}> {
      const { tableId, date } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LiveCasinoStatistic/GetTableShoesByDate',
        { query_params: { tableId, date }, abortController: this.setAbortController('getTimeRange') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getTimeRange
     * @param {statisticTableDataParams} params -
     * @returns {Promise<Array<statisticTableDataModel>>} -
     */
    async getStatisticTableData(params: statisticTableDataParams): Promise<{ data: Array<statisticTableDataModel>}> {
      const { shoeId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LiveCasinoStatistic/GetShoeCards',
        { query_params: { shoeId }, abortController: this.setAbortController('getStatisticTableData') }
      );

      return {
        data: body.Data
      };
    }

    /**
   * cancelStatisticSDKCalls
   * @param {Object} params -
   * @returns  {Object} -
   */
    cancelStatisticSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new StatisticsSDK();
