export const parseSettingsModelFromResponse = (response: Object) => {
  const PredefinedIPs = {
    Enabled: response.IsPredefinedIPsEnabled,
    Delay: response.PredefinedIPsDelay,
    Limit: response.PredefinedIPsLimit
  };
  response.PreDefinedIps = PredefinedIPs;
  delete response.Id;
  delete response.IsPredefinedIPsEnabled;
  delete response.PredefinedIPsLimit;
  delete response.PredefinedIPsDelay;
  return response;
};

export const parseSettingsChangeHistoryModelFromResponse = (response: Object) => {
  const { Data: { Models, TotalCount } } = response;
  return {
    data: Models,
    totalCount: TotalCount
  };
};
