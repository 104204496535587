/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import ACTION_TYPES from 'modules/SRMT/Reports/store/actions/types';
import reportsSDK from 'modules/SRMT/Reports/core/sdk/ReportsSDK';
import { parseDataWithTotalCount } from 'lib/helpers/parser';
import {
  setFetchAlertReportsFailure,
  setFetchAlertReportsSuccess,
  setFetchPlayerBlockStatusReportsFailure,
  setFetchPlayerBlockStatusReportsSuccess,
  setFetchUserPlayerReportsFailure,
  setFetchUserPlayerReportsSuccess
} from 'modules/SRMT/Reports/store/actions';

class ReportsController {
  static* fetchClientReport({ payload }) {
    const { count, start, filters, passTotalCount } = payload;

    try {
      const response = yield reportsSDK.fetchClientReport({ count, start, passTotalCount, filters });
      const { data, totalCount } = parseDataWithTotalCount(response);
      yield put(setFetchUserPlayerReportsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchUserPlayerReportsFailure({ error: err.message }));
    }
  }

  static* fetchAlertReport({ payload }) {
    const { count, start, passTotalCount, filters } = payload;

    try {
      const response = yield reportsSDK.fetchAlertReport({ count, start, passTotalCount, filters });
      const { data, totalCount } = parseDataWithTotalCount(response);
      yield put(setFetchAlertReportsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAlertReportsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerBlockStatusReport({ payload }) {
    const { count, start, passTotalCount, filters } = payload;

    try {
      const response = yield reportsSDK.fetchPlayerBlockStatusReport({ count, start, passTotalCount, filters });
      const { data, totalCount } = parseDataWithTotalCount(response);
      yield put(setFetchPlayerBlockStatusReportsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerBlockStatusReportsFailure({ error: err.message }));
    }
  }

  static* cancelAlertSDKCalls({ payload }) {
    const { name } = payload;
    yield reportsSDK.cancelAlertSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTION_TYPES.FETCH_PLAYER_BLOCK_STATUS_REPORTS, ReportsController.fetchPlayerBlockStatusReport);
    yield takeLatest(ACTION_TYPES.FETCH_ALERT_REPORTS, ReportsController.fetchAlertReport);
    yield takeLatest(ACTION_TYPES.FETCH_USER_PLAYER_REPORTS, ReportsController.fetchClientReport);
    yield takeLatest(ACTION_TYPES.CANCEL_REPORT_CALL, ReportsController.cancelAlertSDKCalls);
  }
}

export default ReportsController.actionsWatcher;
