/**
 * @flow
 * */
import Logger from 'lib/logger';

class CacheService {
  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static setItem(key: string, value: any) {
    return localStorage.setItem(key, value);
  }

  static deleteItem(key: string) {
    return localStorage.removeItem(key);
  }

  static clearStorage() {
    const finger = CacheService.getItem('Fingerprint');
    localStorage.clear();
    if (finger) {
      CacheService.setItem('Fingerprint', finger);
    }
  }

  static setAuthToken(token: string) {
    return CacheService.setItem('auth_token', token);
  }

  static setUser(value: Object) {
    return CacheService.setItem('user', JSON.stringify(value));
  }

  static setUserPermissions(value: string) {
    return CacheService.setItem('user_permissions', JSON.stringify(value.split(',')));
  }

  static getUserPermissions() {
    const perms = CacheService.getItem('user_permissions');
    if (typeof perms !== 'string') {
      Logger.error('DANGER!');
      Logger.warn('The cached data of user_permissions is malformed.');
      return null;
    }
    // $FlowFixMe
    return JSON.parse(perms);
  }

  static getUser() {
    const user = CacheService.getItem('user_permissions');
    if (typeof user !== 'string') {
      Logger.error('DANGER!');
      Logger.warn('The cached data of user is malformed.');
      return null;
    }
    // $FlowFixMe
    return JSON.parse(user);
  }

  static getAuthToken() {
    return CacheService.getItem('auth_token');
  }

  static setJson(key: string, json: Object | Array<any>) {
    return CacheService.setItem(key, JSON.stringify(json));
  }

  /**
   * getJson
   * @param {String} key - the key identifer
   * @returns {Object | Array<any>} - parsed json
   */
  static getJson(key: string): Object | Array<any> {
    const json = CacheService.getItem(key);
    // $FlowFixMe
    return json && JSON.parse(json);
  }

  static callCaching(url: string) {
    const callCaching = CacheService.getJson('callCaching');
    if (!callCaching) return false;
    // $FlowFixMe
    return callCaching[url] || false;
  }

  static setCallCaching(url: string, data: any) {
    const callCaching = CacheService.getJson('callCaching');
    if (!callCaching) {
      CacheService.setJson('callCaching',
        {
          [url]: data
        });
    } else {
      // $FlowFixMe
      callCaching[url] = data;
      CacheService.setJson(
        'callCaching',
        {
          // $FlowFixMe
          ...callCaching
        }
      );
    }
  }
}

export default CacheService;
