/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';

// Alert Players Bets
export const fetchAlertPlayersBets = ({ alertId, alertType, start, count, passTotalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS,
  payload: { alertId, alertType, start, count, passTotalCount }
});
export const setFetchAlertPlayersBetsSuccess = ({ data, totalCount, passTotalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAlertPlayersBetsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_FAILED,
  payload: { error }
});
export const clearAlertPlayersBets = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_ALERT_PLAYERS_BETS_STATE
});

// Alert Players Bets Expand
export const fetchAlertPlayersBetsExpand = ({ appendData = false, playerId, start, count, passTotalCount, section = 'bets' }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_EXPAND,
  payload: { playerId, start, count, passTotalCount, appendData, section }
});
export const setFetchAlertPlayersBetsExpandSuccess = ({ data, totalCount, playerId, passTotalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_EXPAND_SUCCESS,
  payload: { data, totalCount, playerId, passTotalCount }
});
export const setFetchAlertPlayersBetsExpandFailure = ({ playerId, error }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_EXPAND_FAILED,
  payload: { playerId, error }
});

// Alert Players Bets Expand Children
export const fetchAlertPlayersBetsExpandChildren = ({ playerId, betId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN,
  payload: { playerId, betId }
});
export const setFetchAlertPlayersBetsExpandChildrenSuccess = ({ data, playerId, betId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_SUCCESS,
  payload: { data, playerId, betId }
});
export const setFetchAlertPlayersBetsExpandChildrenFailure = ({ playerId, error, betId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_FAILED,
  payload: { playerId, error, betId }
});
