import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createPlayerSymbol = createSymbol('PLAYER');
const createPlayerInternalSymbol = createInternalSymbol('PLAYER');

export default {
  // Global KPIs
  FETCH_GLOBAL_KPIS: createPlayerSymbol('FETCH_GLOBAL_KPIS'),
  SET_FETCH_GLOBAL_KPIS_SUCCESS: createPlayerSymbol('SET_FETCH_GLOBAL_KPIS_SUCCESS'),
  SET_FETCH_GLOBAL_KPIS_FAILURE: createPlayerSymbol('SET_FETCH_GLOBAL_KPIS_FAILURE'),
  CLEAR_GLOBAL_KPIS_STATE: createPlayerInternalSymbol('CLEAR_GLOBAL_KPIS_STATE'),

  // LicenseRulesContainer Categories
  FETCH_ADDITIONAL_CATEGORIES: createPlayerSymbol('FETCH_ADDITIONAL_CATEGORIES'),
  SET_FETCH_ADDITIONAL_CATEGORIES_SUCCESS: createPlayerSymbol('SET_FETCH_ADDITIONAL_CATEGORIES_SUCCESS'),
  SET_FETCH_ADDITIONAL_CATEGORIES_FAILURE: createPlayerSymbol('SET_FETCH_ADDITIONAL_CATEGORIES_FAILURE'),
  CLEAR_ADDITIONAL_CATEGORIES_STATE: createPlayerInternalSymbol('CLEAR_ADDITIONAL_CATEGORIES_STATE'),

  // Available LicenseRulesContainer Categories
  FETCH_AVAILABLE_ADDITIONAL_CATEGORIES: createPlayerSymbol('FETCH_AVAILABLE_ADDITIONAL_CATEGORIES'),
  SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_SUCCESS: createPlayerSymbol('SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_SUCCESS'),
  SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_FAILURE: createPlayerSymbol('SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_FAILURE'),
  CLEAR_AVAILABLE_ADDITIONAL_CATEGORIES_STATE: createPlayerInternalSymbol('CLEAR_AVAILABLE_ADDITIONAL_CATEGORIES_STATE'),

  // SuspiciousActivityContainer Categories
  FETCH_FINANCIAL_CATEGORIES: createPlayerSymbol('FETCH_FINANCIAL_CATEGORIES'),
  UPDATE_FINANCIAL_CATEGORIES: createPlayerSymbol('UPDATE_FINANCIAL_CATEGORIES'),
  SET_FETCH_FINANCIAL_CATEGORIES_SUCCESS: createPlayerSymbol('SET_FETCH_FINANCIAL_CATEGORIES_SUCCESS'),
  SET_FETCH_FINANCIAL_CATEGORIES_FAILURE: createPlayerSymbol('SET_FETCH_FINANCIAL_CATEGORIES_FAILURE'),
  CLEAR_FINANCIAL_CATEGORIES_STATE: createPlayerInternalSymbol('CLEAR_FINANCIAL_CATEGORIES_STATE'),

  // Player Info
  FETCH_PLAYER_INFO: createPlayerSymbol('FETCH_PLAYER_INFO'),
  SET_FETCH_PLAYER_INFO_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_INFO_SUCCESS'),
  SET_FETCH_PLAYER_INFO_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_INFO_FAILURE'),
  UPDATE_PLAYER_INFO: createPlayerSymbol('UPDATE_PLAYER_INFO'),
  CLEAR_PLAYER_INFO_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_INFO_STATE'),

  // Category History
  FETCH_CATEGORY_HISTORY: createPlayerSymbol('FETCH_CATEGORY_HISTORY'),
  SET_FETCH_CATEGORY_HISTORY_SUCCESS: createPlayerSymbol('SET_FETCH_CATEGORY_HISTORY_SUCCESS'),
  SET_FETCH_CATEGORY_HISTORY_FAILURE: createPlayerSymbol('SET_FETCH_CATEGORY_HISTORY_FAILURE'),
  CLEAR_CATEGORY_HISTORY_STATE: createPlayerInternalSymbol('CLEAR_CATEGORY_HISTORY_STATE'),

  // Multi accounts
  FETCH_MULTI_ACCOUNTS: createPlayerSymbol('FETCH_MULTI_ACCOUNTS'),
  SET_FETCH_MULTI_ACCOUNTS_SUCCESS: createPlayerSymbol('SET_FETCH_MULTI_ACCOUNTS_SUCCESS'),
  SET_FETCH_MULTI_ACCOUNTS_FAILURE: createPlayerSymbol('SET_FETCH_MULTI_ACCOUNTS_FAILURE'),
  CLEAR_MULTI_ACCOUNTS_STATE: createPlayerInternalSymbol('CLEAR_MULTI_ACCOUNTS_STATE'),

  // Processed Alerts
  FETCH_PROCESSED_ALERTS: createPlayerSymbol('FETCH_PROCESSED_ALERTS'),
  SET_FETCH_PROCESSED_ALERTS_SUCCESS: createPlayerSymbol('SET_FETCH_PROCESSED_ALERTS_SUCCESS'),
  SET_FETCH_PROCESSED_ALERTS_FAILURE: createPlayerSymbol('SET_FETCH_PROCESSED_ALERTS_FAILURE'),
  CLEAR_PROCESSED_ALERTS_STATE: createPlayerInternalSymbol('CLEAR_PROCESSED_ALERTS_STATE'),

  // Note Comments
  FETCH_NOTE_COMMENTS: createPlayerSymbol('FETCH_NOTE_COMMENTS'),
  SET_FETCH_NOTE_COMMENTS_SUCCESS: createPlayerSymbol('SET_FETCH_NOTE_COMMENTS_SUCCESS'),
  SET_FETCH_NOTE_COMMENTS_FAILURE: createPlayerSymbol('SET_FETCH_NOTE_COMMENTS_FAILURE'),
  CLEAR_NOTE_COMMENTS_STATE: createPlayerInternalSymbol('CLEAR_NOTE_COMMENTS_STATE'),

  // AGP Note Comments
  FETCH_AGP_COMMENTS: createPlayerSymbol('FETCH_AGP_COMMENTS'),
  SET_FETCH_AGP_COMMENTS_SUCCESS: createPlayerSymbol('SET_FETCH_AGP_COMMENTS_SUCCESS'),
  SET_FETCH_AGP_COMMENTS_FAILURE: createPlayerSymbol('SET_FETCH_AGP_COMMENTS_FAILURE'),
  CLEAR_AGP_COMMENTS_STATE: createPlayerInternalSymbol('CLEAR_AGP_COMMENTS_STATE'),

  // Note Client Note
  FETCH_CLIENT_NOTE: createPlayerSymbol('FETCH_CLIENT_NOTE'),
  SET_FETCH_CLIENT_NOTE_SUCCESS: createPlayerSymbol('SET_FETCH_CLIENT_NOTE_SUCCESS'),
  SET_FETCH_CLIENT_NOTE_FAILURE: createPlayerSymbol('SET_FETCH_CLIENT_NOTE_FAILURE'),
  CLEAR_CLIENT_NOTE_STATE: createPlayerInternalSymbol('CLEAR_CLIENT_NOTE_STATE'),

  // Player SuspiciousActivityContainer KPIs
  FETCH_PLAYER_FINANCIAL_KPIS: createPlayerSymbol('FETCH_PLAYER_FINANCIAL_KPIS'),
  SET_FETCH_PLAYER_FINANCIAL_KPIS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_KPIS_SUCCESS'),
  SET_FETCH_PLAYER_FINANCIAL_KPIS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_KPIS_FAILURE'),
  CLEAR_PLAYER_FINANCIAL_KPIS_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_FINANCIAL_KPIS_STATE'),

  // PlayerList SuspiciousActivityContainer Cash Out
  FETCH_PLAYER_FINANCIAL_CASH_OUT: createPlayerSymbol('FETCH_PLAYER_FINANCIAL_CASH_OUT'),
  SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_SUCCESS'),
  SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_FAILURE'),
  CLEAR_PLAYER_FINANCIAL_CASH_OUT_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_FINANCIAL_CASH_OUT_STATE'),

  // PlayerList SuspiciousActivityContainer Transactions
  FETCH_PLAYER_FINANCIAL_TRANSACTIONS: createPlayerSymbol('FETCH_PLAYER_FINANCIAL_TRANSACTIONS'),
  SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_SUCCESS'),
  SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_FAILURE'),
  CLEAR_PLAYER_FINANCIAL_TRANSACTIONS_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_FINANCIAL_TRANSACTIONS_STATE'),

  // PlayerList SuspiciousActivityContainer Balances
  FETCH_PLAYER_FINANCIAL_BALANCES: createPlayerSymbol('FETCH_PLAYER_FINANCIAL_BALANCES'),
  SET_FETCH_PLAYER_FINANCIAL_BALANCES_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_BALANCES_SUCCESS'),
  SET_FETCH_PLAYER_FINANCIAL_BALANCES_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_FINANCIAL_BALANCES_FAILURE'),
  CLEAR_PLAYER_FINANCIAL_BALANCES_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_FINANCIAL_BALANCES_STATE'),

  // PlayerList
  FETCH_PLAYERS: createPlayerSymbol('FETCH_PLAYERS'),
  SET_FETCH_PLAYERS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYERS_SUCCESS'),
  SET_FETCH_PLAYERS_APPEND_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYERS_APPEND_SUCCESS'),
  SET_FETCH_PLAYERS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYERS_FAILURE'),
  CLEAR_PLAYERS_STATE: createPlayerInternalSymbol('CLEAR_PLAYERS_STATE'),

  // PlayerBets
  FETCH_PLAYERS_BETS: createPlayerSymbol('FETCH_PLAYERS_BETS'),
  SET_FETCH_PLAYERS_BETS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYERS_BETS_SUCCESS'),
  SET_FETCH_PLAYERS_BETS_APPEND_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYERS_BETS_APPEND_SUCCESS'),
  SET_FETCH_PLAYERS_BETS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYERS_BETS_FAILURE'),
  CLEAR_PLAYERS_BETS_STATE: createPlayerInternalSymbol('CLEAR_PLAYERS_BETS_STATE'),

  // expand row data
  FETCH_EXPAND_BETS_ROW_CHILDREN_DATA: createPlayerInternalSymbol('FETCH_EXPAND_BETS_ROW_CHILDREN_DATA'),
  SET_EXPAND_BETS_ROW_CHILDREN_DATA_SUCCESS: createPlayerInternalSymbol('SET_EXPAND_BETS_ROW_CHILDREN_DATA_SUCCESS'),
  SET_EXPAND_BETS_ROW_CHILDREN_DATA_FAILURE: createPlayerInternalSymbol('SET_EXPAND_BETS_ROW_CHILDREN_DATA_FAILURE'),
  CLEAR_EXPAND_BETS_ROW_CHILDREN_DATA: createPlayerInternalSymbol('CLEAR_EXPAND_BETS_ROW_CHILDREN_DATA'),

  // Restrictions Log
  FETCH_RESTRICTIONS_LOG: createPlayerSymbol('FETCH_RESTRICTIONS_LOG'),
  SET_FETCH_RESTRICTIONS_LOG_SUCCESS: createPlayerSymbol('SET_FETCH_RESTRICTIONS_LOG_SUCCESS'),
  SET_FETCH_RESTRICTIONS_LOG_FAILURE: createPlayerSymbol('SET_FETCH_RESTRICTIONS_LOG_FAILURE'),
  CLEAR_RESTRICTIONS_LOG_STATE: createPlayerInternalSymbol('CLEAR_RESTRICTIONS_LOG_STATE'),

  // Game Style Categories
  FETCH_GAME_STYLE_CATEGORIES: createPlayerSymbol('FETCH_GAME_STYLE_CATEGORIES'),
  SET_FETCH_GAME_STYLE_CATEGORIES_SUCCESS: createPlayerSymbol('SET_FETCH_GAME_STYLE_CATEGORIES_SUCCESS'),
  SET_FETCH_GAME_STYLE_CATEGORIES_FAILURE: createPlayerSymbol('SET_FETCH_GAME_STYLE_CATEGORIES_FAILURE'),
  CLEAR_GAME_STYLE_CATEGORIES_STATE: createPlayerInternalSymbol('CLEAR_GAME_STYLE_CATEGORIES_STATE'),

  GAME_STYLE_CATEGORY_UPDATE: createPlayerSymbol('GAME_STYLE_CATEGORY_UPDATE'),
  SET_GAME_STYLE_CATEGORY_UPDATE_SUCCESS: createPlayerSymbol('SET_GAME_STYLE_CATEGORY_UPDATE_SUCCESS'),
  SET_GAME_STYLE_CATEGORY_UPDATE_FAILURE: createPlayerSymbol('SET_GAME_STYLE_CATEGORY_UPDATE_FAILURE'),
  CLEAR_GAME_STYLE_CATEGORY_UPDATE_STATE: createPlayerInternalSymbol('CLEAR_GAME_STYLE_CATEGORY_UPDATE_STATE'),

  GAME_STYLE_CATEGORIES_BULK_UPDATE: createPlayerSymbol('GAME_STYLE_CATEGORIES_BULK_UPDATE'),
  SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_SUCCESS: createPlayerSymbol('SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_SUCCESS'),
  SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_FAILURE: createPlayerSymbol('SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_FAILURE'),
  CLEAR_GAME_STYLE_CATEGORIES_BULK_UPDATE_STATE: createPlayerInternalSymbol('CLEAR_GAME_STYLE_CATEGORIES_BULK_UPDATE_STATE'),

  // Partner Game Style Categories
  FETCH_PARTNER_GAME_STYLE_CATEGORIES: createPlayerSymbol('FETCH_PARTNER_GAME_STYLE_CATEGORIES'),
  SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_SUCCESS: createPlayerSymbol('SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_SUCCESS'),
  SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_FAILURE: createPlayerSymbol('SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_FAILURE'),
  CLEAR_PARTNER_GAME_STYLE_CATEGORIES_STATE: createPlayerInternalSymbol('CLEAR_PARTNER_GAME_STYLE_CATEGORIES_STATE'),

  // Monitoring Existing Alert
  FETCH_EXISTING_ALERT: createPlayerSymbol('FETCH_EXISTING_ALERT'),
  SET_FETCH_EXISTING_ALERT_SUCCESS: createPlayerSymbol('SET_FETCH_EXISTING_ALERT_SUCCESS'),
  SET_FETCH_EXISTING_ALERT_FAILURE: createPlayerSymbol('SET_FETCH_EXISTING_ALERT_FAILURE'),
  CLEAR_EXISTING_ALERT_STATE: createPlayerInternalSymbol('CLEAR_EXISTING_ALERT_STATE'),

  // Monitoring Set Alert
  FETCH_SET_ALERT: createPlayerSymbol('FETCH_SET_ALERT'),
  SET_FETCH_SET_ALERT_SUCCESS: createPlayerSymbol('SET_FETCH_SET_ALERT_SUCCESS'),
  SET_FETCH_SET_ALERT_FAILURE: createPlayerSymbol('SET_FETCH_SET_ALERT_FAILURE'),
  CLEAR_SET_ALERT_STATE: createPlayerInternalSymbol('CLEAR_SET_ALERT_STATE'),

  // Monitoring Resolve Alert
  FETCH_RESOLVE_ALERT: createPlayerSymbol('FETCH_RESOLVE_ALERT'),
  SET_FETCH_RESOLVE_ALERT_SUCCESS: createPlayerSymbol('SET_FETCH_RESOLVE_ALERT_SUCCESS'),
  SET_FETCH_RESOLVE_ALERT_FAILURE: createPlayerSymbol('SET_FETCH_RESOLVE_ALERT_FAILURE'),
  CLEAR_RESOLVE_ALERT_STATE: createPlayerInternalSymbol('CLEAR_RESOLVE_ALERT_STATE'),

  // Player Session ips
  FETCH_PLAYER_SESSION_IPS: createPlayerSymbol('FETCH_PLAYER_SESSION_IPS'),
  SET_FETCH_PLAYER_SESSION_IPS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_SESSION_IPS_SUCCESS'),
  SET_FETCH_PLAYER_SESSION_IPS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_SESSION_IPS_FAILURE'),
  CLEAR_PLAYER_SESSION_IPS_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_SESSION_IPS_STATE'),

  // Player All Session ips
  FETCH_PLAYER_ALL_SESSION_IPS: createPlayerSymbol(' FETCH_PLAYER_ALL_SESSION_IPS'),
  SET_FETCH_PLAYER_All_SESSION_IPS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_All_SESSION_IPS_SUCCESS'),
  SET_FETCH_PLAYER_All_SESSION_IPS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_All_SESSION_IPS_FAILURE'),

  // All Player Session ip
  FETCH_ALL_PLAYERS_SESSION_IP: createPlayerSymbol('FETCH_ALL_PLAYERS_SESSION_IP'),
  SET_FETCH_All_PLAYERS_SESSION_IP_SUCCESS: createPlayerSymbol('SET_FETCH_All_PLAYERS_SESSION_IP_SUCCESS'),
  SET_FETCH_All_PLAYERS_SESSION_IP_FAILURE: createPlayerSymbol('SET_FETCH_All_PLAYERS_SESSION_IP_FAILURE'),

  // Player Fingerprints
  FETCH_PLAYER_FINGERPRINTS: createPlayerSymbol('FETCH_PLAYER_FINGERPRINTS'),
  SET_FETCH_PLAYER_FINGERPRINTS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_FINGERPRINTS_SUCCESS'),
  SET_FETCH_PLAYER_FINGERPRINTS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_FINGERPRINTS_FAILURE'),
  CLEAR_PLAYER_FINGERPRINTS_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_FINGERPRINTS_STATE'),

  // Add Comment
  ADD_COMMENT: createPlayerSymbol('ADD_COMMENT'),
  SET_ADD_COMMENT_SUCCESS: createPlayerSymbol('SET_ADD_COMMENT_SUCCESS'),
  SET_ADD_COMMENT_FAILURE: createPlayerSymbol('SET_ADD_COMMENT_FAILURE'),
  CLEAR_ADD_COMMENT_STATE: createPlayerInternalSymbol('CLEAR_ADD_COMMENT_STATE'),

  // Edit LicenseRulesContainer Category
  EDIT_ADDITIONAL_CATEGORY: createPlayerSymbol('EDIT_ADDITIONAL_CATEGORY'),
  SET_EDIT_ADDITIONAL_CATEGORY_SUCCESS: createPlayerSymbol('SET_EDIT_ADDITIONAL_CATEGORY_SUCCESS'),
  SET_EDIT_ADDITIONAL_CATEGORY_FAILURE: createPlayerSymbol('SET_EDIT_ADDITIONAL_CATEGORY_FAILURE'),

  // MergeBulk LicenseRulesContainer Category
  ADDITIONAL_CATEGORIES_BULK_MERGE: createPlayerSymbol('ADDITIONAL_CATEGORIES_BULK_MERGE'),
  SET_ADDITIONAL_CATEGORIES_BULK_MERGE_SUCCESS: createPlayerSymbol('SET_ADDITIONAL_CATEGORIES_BULK_MERGE_SUCCESS'),
  SET_ADDITIONAL_CATEGORIES_BULK_MERGE_FAILURE: createPlayerSymbol('SET_ADDITIONAL_CATEGORIES_BULK_MERGE_FAILURE'),
  CLEAR_ADDITIONAL_CATEGORIES_BULK_MERGE_STATE: createPlayerInternalSymbol('CLEAR_ADDITIONAL_CATEGORIES_BULK_MERGE_STATE'),

  // Player KPI Tree
  FETCH_PLAYER_KPI_TREE: createPlayerSymbol('FETCH_PLAYER_KPI_TREE'),
  SET_FETCH_PLAYER_KPI_TREE_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_KPI_TREE_SUCCESS'),
  SET_FETCH_PLAYER_KPI_TREE_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_KPI_TREE_FAILURE'),
  CLOSE_PLAYER_KPI_TREE_NODE: createPlayerInternalSymbol('CLOSE_PLAYER_KPI_TREE_NODE'),
  OPEN_PLAYER_KPI_TREE_NODE: createPlayerInternalSymbol('OPEN_PLAYER_KPI_TREE_NODE'),
  CLEAR_PLAYER_KPI_TREE_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_KPI_TREE_STATE'),

  // Player Alerts
  FETCH_PLAYER_ALERTS: createPlayerSymbol('FETCH_PLAYER_ALERTS'),
  UPDATE_PLAYER_ALERTS: createPlayerSymbol('UPDATE_PLAYER_ALERTS'),
  SET_FETCH_PLAYER_ALERTS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_ALERTS_SUCCESS'),
  SET_FETCH_PLAYER_ALERTS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_ALERTS_FAILURE'),
  CLEAR_PLAYER_ALERTS_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_ALERTS_STATE'),

  // Alerts Bulk Review
  FETCH_ALERTS_BULK_REVIEW: createPlayerSymbol('FETCH_ALERTS_BULK_REVIEW'),
  SET_FETCH_ALERTS_BULK_REVIEW_SUCCESS: createPlayerSymbol('SET_FETCH_ALERTS_BULK_REVIEW_SUCCESS'),
  SET_FETCH_ALERTS_BULK_REVIEW_FAILURE: createPlayerSymbol('SET_FETCH_ALERTS_BULK_REVIEW_FAILURE'),
  CLEAR_ALERTS_BULK_REVIEW_STATE: createPlayerInternalSymbol('CLEAR_ALERTS_BULK_REVIEW_STATE'),

  // Player Restrictions
  FETCH_PLAYER_RESTRICTIONS: createPlayerSymbol('FETCH_PLAYER_RESTRICTIONS'),
  SET_FETCH_PLAYER_RESTRICTIONS_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_RESTRICTIONS_SUCCESS'),
  SET_FETCH_PLAYER_RESTRICTIONS_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_RESTRICTIONS_FAILURE'),
  CLEAR_PLAYER_RESTRICTIONS_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_RESTRICTIONS_STATE'),
  CHANGE_PLAYER_RESTRICTIONS_STATE: createPlayerInternalSymbol('CHANGE_PLAYER_RESTRICTIONS_STATE'),

  // Edit Player Restrictions
  FETCH_EDIT_PLAYER_RESTRICTIONS: createPlayerSymbol('FETCH_EDIT_PLAYER_RESTRICTIONS'),
  SET_FETCH_EDIT_PLAYER_RESTRICTIONS_SUCCESS: createPlayerSymbol('SET_FETCH_EDIT_PLAYER_RESTRICTIONS_SUCCESS'),
  SET_FETCH_EDIT_PLAYER_RESTRICTIONS_FAILURE: createPlayerSymbol('SET_FETCH_EDIT_PLAYER_RESTRICTIONS_FAILURE'),
  CLEAR_EDIT_PLAYER_RESTRICTIONS_STATE: createPlayerInternalSymbol('CLEAR_EDIT_PLAYER_RESTRICTIONS_STATE'),

  // Player Force Calculation
  FORCE_PLAYER_FINANCIAL_CALCULATION: createPlayerSymbol('FORCE_PLAYER_FINANCIAL_CALCULATION'),
  SET_FORCE_PLAYER_FINANCIAL_CALCULATION_SUCCESS: createPlayerSymbol('SET_FORCE_PLAYER_FINANCIAL_CALCULATION_SUCCESS'),
  SET_FORCE_PLAYER_FINANCIAL_CALCULATION_FAILURE: createPlayerSymbol('SET_FORCE_PLAYER_FINANCIAL_CALCULATION_FAILURE'),
  CLEAR_FORCE_PLAYER_FINANCIAL_CALCULATION_STATE: createPlayerInternalSymbol('CLEAR_FORCE_PLAYER_FINANCIAL_CALCULATION_STATE'),

  // Set Alert
  FETCH_SET_MONITORING_ALERT: createPlayerSymbol('FETCH_SET_MONITORING_ALERT'),
  SET_FETCH_SET_MONITORING_SUCCESS: createPlayerSymbol('SET_FETCH_SET_MONITORING_SUCCESS'),
  SET_FETCH_SET_MONITORING_FAILURE: createPlayerSymbol('SET_FETCH_SET_MONITORING_FAILURE'),
  CLEAR_SET_MONITORING_STATE: createPlayerInternalSymbol('CLEAR_SET_MONITORING_STATE'),

  // Player SDK cancel
  CANCEL_PLAYER_SDK_CALL: createPlayerSymbol('CANCEL_PLAYER_SDK_CALL'),

  // Player Change Calculation State
  PLAYER_CHANGE_CALCULATION_STATE: createPlayerSymbol('PLAYER_CHANGE_CALCULATION_STATE'),
  SET_PLAYER_CHANGE_CALCULATION_STATE_SUCCESS: createPlayerSymbol('SET_PLAYER_CHANGE_CALCULATION_STATE_SUCCESS'),
  SET_PLAYER_CHANGE_CALCULATION_STATE_FAILURE: createPlayerSymbol('SET_PLAYER_CHANGE_CALCULATION_STATE_FAILURE'),
  CLEAR_PLAYER_CHANGE_CALCULATION_STATE_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_CHANGE_CALCULATION_STATE_STATE'),

  // Remove top priority
  FETCH_REMOVE_TOP_PRIORITY: createPlayerSymbol('FETCH_REMOVE_TOP_PRIORITY'),
  SET_FORCE_REMOVE_TOP_PRIORITY_SUCCESS: createPlayerSymbol('SET_FORCE_REMOVE_TOP_PRIORITY_SUCCESS'),
  SET_FORCE_REMOVE_TOP_PRIORITY_FAILURE: createPlayerSymbol('SET_FORCE_REMOVE_TOP_PRIORITY_FAILURE'),
  CLEAR_FORCE_REMOVE_TOP_PRIORITY_STATE: createPlayerInternalSymbol('CLEAR_FORCE_REMOVE_TOP_PRIORITY_STATE'),

  // Set top priority
  FETCH_SET_TOP_PRIORITY: createPlayerSymbol('FETCH_SET_TOP_PRIORITY'),
  SET_FORCE_SET_TOP_PRIORITY_SUCCESS: createPlayerSymbol('SET_FORCE_SET_TOP_PRIORITY_SUCCESS'),
  SET_FORCE_SET_TOP_PRIORITY_FAILURE: createPlayerSymbol('SET_FORCE_SET_TOP_PRIORITY_FAILURE'),
  CLEAR_FORCE_SET_TOP_PRIORITY_STATE: createPlayerInternalSymbol('CLEAR_FORCE_SET_TOP_PRIORITY_STATE'),

  // Checked Manually
  FETCH_SET_CHECK_MANUALLY: createPlayerSymbol('FETCH_SET_CHECK_MANUALLY'),
  SET_FETCH_SET_CHECK_MANUALLY_SUCCESS: createPlayerSymbol('SET_FETCH_SET_CHECK_MANUALLY_SUCCESS'),
  SET_FETCH_SET_CHECK_MANUALLY_FAILURE: createPlayerSymbol('SET_FETCH_SET_CHECK_MANUALLY_FAILURE'),
  CLEAR_FETCH_SET_CHECK_MANUALLY_STATE: createPlayerInternalSymbol('CLEAR_FETCH_SET_CHECK_MANUALLY_STATE'),

  // Check history
  FETCH_CHECK_HISTORY: createPlayerSymbol('FETCH_CHECK_HISTORY'),
  SET_FETCH_CHECK_HISTORY_SUCCESS: createPlayerSymbol('SET_FETCH_CHECK_HISTORY_SUCCESS'),
  SET_FETCH_CHECK_HISTORY_FAILURE: createPlayerSymbol('SET_FETCH_CHECK_HISTORY_FAILURE'),
  CLEAR_FETCH_CHECK_HISTORY_STATE: createPlayerInternalSymbol('CLEAR_FETCH_CHECK_HISTORY_STATE'),

  // Alerts Players Bets
  FETCH_ALERT_PLAYERS_BETS: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS'),
  FETCH_ALERT_PLAYERS_BETS_SUCCESS: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_SUCCESS'),
  FETCH_ALERT_PLAYERS_BETS_FAILED: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_FAILED'),
  CLEAR_ALERT_PLAYERS_BETS_STATE: createPlayerSymbol('CLEAR_ALERT_PLAYERS_BETS_STATE'),

  // Alerts Players Bets Expand
  FETCH_ALERT_PLAYERS_BETS_EXPAND: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_EXPAND'),
  FETCH_ALERT_PLAYERS_BETS_EXPAND_SUCCESS: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_EXPAND_SUCCESS'),
  FETCH_ALERT_PLAYERS_BETS_EXPAND_FAILED: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_EXPAND_FAILED'),

  // Alerts Players Bets Expand Children
  FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN'),
  FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_SUCCESS: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_SUCCESS'),
  FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_FAILED: createPlayerSymbol('FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN_FAILED')
};
