import type { Action } from 'lib/core/flow';

import SETTINGS_ACTIONS from '../types';

// Game Style Settings
export const fetchGameStyleSettings = ({ partnerId }): Action => ({
  type: SETTINGS_ACTIONS.FETCH_GAME_STYLE_SETTINGS,
  payload: { partnerId }
});
export const setFetchGameStyleSettingsSuccess = ({ data }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_GAME_STYLE_SETTINGS_SUCCESS,
  payload: { data }
});
export const setFetchGameStyleSettingsFailure = ({ error }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_GAME_STYLE_SETTINGS_FAILURE,
  payload: { error }
});
export const clearGameStyleSettingsState = (): Action => ({
  type: SETTINGS_ACTIONS.CLEAR_GAME_STYLE_SETTINGS_STATE
});

// Game Style Settings Update
export const updateGameStyleSettings = ({ id, limit, delay, priority }): Action => ({
  type: SETTINGS_ACTIONS.UPDATE_GAME_STYLE_SETTINGS,
  payload: { id, limit, delay, priority }
});
export const setUpdateGameStyleSettingsSuccess = ({ id, data }): Action => ({
  type: SETTINGS_ACTIONS.SET_UPDATE_GAME_STYLE_SETTINGS_SUCCESS,
  payload: { id, data }
});
export const setUpdateGameStyleSettingsFailure = ({ id, error }): Action => ({
  type: SETTINGS_ACTIONS.SET_UPDATE_GAME_STYLE_SETTINGS_FAILURE,
  payload: { id, error }
});

// Game Style Settings Update Priority
export const updateGameStyleSettingsPriority = ({ partnerId, priorities }): Action => ({
  type: SETTINGS_ACTIONS.UPDATE_GAME_STYLE_SETTINGS_PRIORITY,
  payload: { partnerId, priorities }
});
export const setUpdateGameStyleSettingsPrioritySuccess = (): Action => ({
  type: SETTINGS_ACTIONS.SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_SUCCESS
});
export const setUpdateGameStyleSettingsPriorityFailure = ({ error }): Action => ({
  type: SETTINGS_ACTIONS.SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_FAILURE,
  payload: { error }
});
