import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createGeneralStore } from 'store';
import GeneUIProvider from '@geneui/components/GeneUIProvider';
import * as serviceWorker from './serviceWorker';
import App from './App';

import 'scss/main.scss';

const store: Object = createGeneralStore();

const RootNode = () => (
  <GeneUIProvider>
    <div className="GeneUIProvider">
      <Provider store={store}>
        <App />
      </Provider>
    </div>
    <div id="portal" />
  </GeneUIProvider>
);

ReactDOM.render(
  <RootNode />,
  document.getElementById('_umbrella') || document.createElement('div')
);

serviceWorker.unregister();

export default RootNode;
