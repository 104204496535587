/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PERMISSION_TYPES from 'store/actions/permission/types';
import { initialSectionState } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import { PermissionState } from 'store/types';

const initialState = (): PermissionState => ({
  ...initialSectionState()
});

const actionManager = {
  [PERMISSION_TYPES.FETCH_PERMISSION]: (state: PermissionState) => ({
    ...state,
    status: ACTION_STATUSES.PENDING
  }),
  [PERMISSION_TYPES.SET_FETCH_PERMISSION_SUCCESS]: (state: PermissionState, payload) => ({
    ...state,
    data: payload.data,
    status: ACTION_STATUSES.SUCCEED,
    error: null
  }),
  [PERMISSION_TYPES.SET_FETCH_PERMISSION_FAILURE]: (state: PermissionState, payload) => ({
    ...state,
    data: null,
    status: ACTION_STATUSES.FAILED,
    error: payload.error
  })
};

export default (state : PermissionState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
