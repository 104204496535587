/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import { Redirect } from 'react-router-dom';
import React from 'react';

import settings from 'config/constants';

const StatisticsPage = React.lazy(() => import('./views/pages/Statistics'));
const RouletteStatisticsPage = React.lazy(() => import('./views/pages/RouletteStatistics'));
const CardStatisticsPage = React.lazy(() => import('./views/pages/CardStatistics'));

const subUrl = settings.PRODUCTS.LCRMT.path;

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}
const ROUTES: Array<Route> = [
  { path: `/${subUrl}/equipment-integrity`, name: 'Statistics', exact: true, page: () => <Redirect to={`/${subUrl}/equipment-integrity/statistics`} /> },
  { path: `/${subUrl}/equipment-integrity/statistics`, name: 'Statistics', exact: true, page: StatisticsPage },
  { path: `/${subUrl}/equipment-integrity/roulette-statistics`, name: 'Roulette Statistics', exact: true, page: RouletteStatisticsPage },
  { path: `/${subUrl}/equipment-integrity/card-statistics`, name: 'Card Statistics', exact: true, page: CardStatisticsPage },
  { path: `/${subUrl}/equipment-integrity/card-statistics/:tab`, name: 'Card Statistics/Tab', exact: true, page: CardStatisticsPage },
  { path: '/grmt', name: 'Statistics', exact: true, page: () => <Redirect to="/grmt/statistics" /> },
  { path: '/grmt/statistics', name: 'Statistics', exact: true, page: StatisticsPage }
];

export default ROUTES;
