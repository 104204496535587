/**
 * @flow
 * */
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';

/**
   * @class DashboardSDK
   */
class DashboardSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
     * createGroup
     * @param {{ date: string }} params -
     * @returns {Object} -
     */
    // eslint-disable-next-line no-unused-vars
    async fetchCategoriesChart({ date }: Object): Promise<any> {
      // const { name, description } = params;
      /* const { body } = await this._rmtApiService.makeAPIPostRequest(
          'Common/Add',
          { body: { name, description } }
        ); */
      const body = {
        categories: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday'
        ],
        data: [
          {
            data: [5, 3, 4, 7, 2],
            name: 'Third name'
          },
          {
            name: 'Second name',
            data: [2, 2, 3, 2, 1]
          },
          {
            name: 'First name',
            data: [3, 4, 4, 2, 5]
          }
        ]
      };

      return body;
    }

    /**
   * fetchChartData
   * @param {{ date: string }} params -
   * @returns {Object} -
   */
    async fetchChartData({ range, name, partnerId }: Object): Promise<any> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'DashBoard/Metric',
        { query_params: { range, name, partners: partnerId }, abortController: this.setAbortController(`fetchChartData${name}`) }
      );
      return body.Data;
    }

    /**
   * fetchAnalyticChart
   * @param {{ date: string }} params -
   * @returns {Object} -
   */
    async fetchAnalyticChart({ partnerId }: Object): Promise<any> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'AnalyticData/AnalyticChart',
        { query_params: { partners: partnerId }, abortController: this.setAbortController('fetchAnalyticChart') }
      );
      return body.Data;
    }

    /**
     * fetchAlertChart
     * @param {{ date: string }} params -
     * @returns {Object} -
     */
    async getAlertChart({ partnerIds, alertType, startDateFrom, endDateTo }: Object): Promise<any> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'DashBoard/GetFraudAlertsByTriggerTypeStatistic',
        { query_params: { partners: partnerIds, alertType, startDateFrom, endDateTo }, abortController: this.setAbortController(`fetchAlertChart${alertType}`) }
      );
      return body.Data;
    }

    /**
   * fetchAnalyticKpis
   * @param {{ date: string }} params -
   * @returns {Object} -
   */
    async fetchAnalyticKpis({ startDate, endDate, partnerId }: Object): Promise<any> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'AnalyticData/GetAnalyticData',
        { query_params: { startDate, endDate, partners: partnerId }, abortController: this.setAbortController('fetchAnalyticKpis') }
      );
      return body.Data;
    }

    /**
   * fetchDashboard
   * @param {{ date: string }} params -
   * @returns {Object} -
   */
    async fetchDashboardKpis({ partnerId }: Object): Promise<any> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'DashBoard/Data',
        { query_params: { partners: partnerId }, abortController: this.setAbortController('fetchDashboardKpis') }
      );
      return body.Data;
    }

    /**
   * cancelDashboardSDKCalls
   * @param {Object} params -
   * @returns  {Object} -
   */
    async cancelDashboardSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new DashboardSDK();
