/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import { initialSectionWithCountState } from 'lib/core/initialStates';
import ACTIONS from 'modules/SRMT/Reports/store/actions/types';
import { ReportsState } from 'modules/SRMT/Reports/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

const initialState = (): ReportsState => ({
  userPlayerReports: initialSectionWithCountState(),
  alertReports: initialSectionWithCountState(),
  playerBlockStatusReports: initialSectionWithCountState()
});

const actionManager = {
  // userPlayerReports
  [ACTIONS.FETCH_USER_PLAYER_REPORTS]: (state: ReportsState): ReportsState => ({
    ...state,
    userPlayerReports: {
      ...state.userPlayerReports,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [ACTIONS.SET_FETCH_USER_PLAYER_REPORTS_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    userPlayerReports: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.userPlayerReports.totalCount,
      error: null
    }
  }),
  [ACTIONS.SET_FETCH_USER_PLAYER_REPORTS_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    userPlayerReports: {
      ...state.users,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),

  [ACTIONS.CLEAR_USER_PLAYER_REPORTS_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    userPlayerReports: initialSectionWithCountState()
  }),

  // alertReports
  [ACTIONS.FETCH_ALERT_REPORTS]: (state: ReportsState): ReportsState => ({
    ...state,
    alertReports: {
      ...state.alertReports,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [ACTIONS.SET_FETCH_ALERT_REPORTS_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    alertReports: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.alertReports.totalCount,
      error: null
    }
  }),
  [ACTIONS.SET_FETCH_ALERT_REPORTS_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    alertReports: {
      ...state.users,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),

  [ACTIONS.CLEAR_ALERT_REPORTS_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    alertReports: initialSectionWithCountState()
  }),

  // Player Block Status Reports
  [ACTIONS.FETCH_PLAYER_BLOCK_STATUS_REPORTS]: (state: ReportsState): ReportsState => ({
    ...state,
    playerBlockStatusReports: {
      ...state.playerBlockStatusReports,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [ACTIONS.SET_FETCH_PLAYER_BLOCK_STATUS_REPORTS_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    playerBlockStatusReports: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playerBlockStatusReports.totalCount,
      error: null
    }
  }),
  [ACTIONS.SET_FETCH_PLAYER_BLOCK_STATUS_REPORTS_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    playerBlockStatusReports: {
      ...state.users,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),

  [ACTIONS.CLEAR_PLAYER_BLOCK_STATUS_REPORTS_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    playerBlockStatusReports: initialSectionWithCountState()
  })

};

export default (state:ReportsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
