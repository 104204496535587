/**
 * @flow
 */
import React, { useCallback, useEffect, useState } from 'react';

import Dropdown from '@geneui/components/Dropdown';

import { defaultDropdownConfigs } from 'config/settings/configs';

type Props = {
  data: Object,
  makeAlertData: Function
}
/**
 *
 * @param {Object} data
 * @param {Function} makeAlertData
 * @returns {JSX.Element}
 * @constructor
 */

const NotificationSettingsItem = ({ data, makeAlertData }:Props) => {
  const [alert, setAlert] = useState(data.Alert.filter(item => item.IsSelected));
  const [alerts, setAlerts] = useState(data.Alerts.filter(item => item.IsSelected));
  const [localAlert, setLocalAlert] = useState(data.Alert);

  const onChangeAlerts = useCallback((item) => {
    const AlertsIds = item.map(it => it.AlertTypeId);
    setAlerts(item);
    if (alerts.length > AlertsIds.length && alert.length) {
      setAlert(alert.filter(el => AlertsIds.includes(el.AlertTypeId)));
    }
    setLocalAlert(item.length ? data.Alert.filter(it => AlertsIds.includes(it.AlertTypeId)) : data.Alert);
    makeAlertData({ ProductType: data.ProductType, alertIds: alert, alerts: item });
  }, [setAlerts, setAlert, setLocalAlert, data, makeAlertData, alerts, alert]);

  const onChangeAlert = useCallback((item) => {
    setAlert(item);
    makeAlertData({ ProductType: data.ProductType, alertIds: item, alerts });
  }, [setAlert, makeAlertData, data, alerts]);

  const defaultState = useCallback(() => {
    setLocalAlert(data && data.Alert.filter(it => alerts.map(it => it.AlertTypeId).includes(it.AlertTypeId)));
    makeAlertData({ ProductType: data.ProductType, alertIds: alert, alerts });
  }, [makeAlertData, alert, data, setLocalAlert, alerts]);

  useEffect(defaultState, [data]);

  return (
    <div className="notifications-popup-item">
      <div className="notifications-popup-label">
        <div className="notifications-popup-title">
          {data.ProductType}
        </div>
        <div className="notifications-popup-line" />
      </div>
      <div className="notifications-popup-dropdown-container">
        <Dropdown
          className="notifications-popup-dropdown-item"
          value={alerts.map(it => it.AlertTypeId)}
          {...defaultDropdownConfigs}
          onChange={onChangeAlerts}
          placeholder="Alert Type"
          valueKey="AlertTypeId"
          labelKey="AlertType"
          data={data.Alerts}
          isMultiSelect
          isValid
        />
        <Dropdown
          className="notifications-popup-dropdown-item"
          value={alert.map(it => it.AlertId)}
          {...defaultDropdownConfigs}
          disabled={!alerts.length}
          onChange={onChangeAlert}
          placeholder="Alert"
          valueKey="AlertId"
          data={localAlert}
          labelKey="Alert"
          isMultiSelect
          isValid
        />
      </div>
    </div>
  );
};

NotificationSettingsItem.displayName = 'NotificationSettingsItem';

export default NotificationSettingsItem;
