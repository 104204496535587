export const makeAlertPlayersBetsExpandReducerData = ({ allData, payload, payloadKey, data, status, error, dataKey, hasTotal, passTotalCount }) => allData?.map(e => {
  if (payload[payloadKey] === e[dataKey]) {
    const newObj = {
      ...e,
      expand: {
        ...e.expand,
        status,
        error,
        data
      }
    };
    if (hasTotal) {
      newObj.expand.totalCount = !passTotalCount ? payload.totalCount : e.expand.totalCount;
    }
    return newObj;
  }
  return { ...e };
});

export const makeAlertPlayersBetsExpandChildrenReducerData = ({ allData, payload, payloadKey, data, status, error, dataKey }) => {
  allData.forEach(item => {
    if (payload[payloadKey] === item[dataKey]) {
      // eslint-disable-next-line array-callback-return
      item.expand.data.forEach(e => {
        if (payload.betId === e.BetId) {
          e.children = {
            status,
            error,
            data
          };
        }
      });
    }
  });
  return allData;
};
