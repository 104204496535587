import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import { spliceArrayFromCustomItem } from 'lib/helpers/arrayNormalizer';
import type { SettingsState } from 'modules/SRMT/Settings/types';
import {
  initialSectionState,
  initialSectionWithEditDeleteState
} from 'store/reducers/initialStates';

const initialState = (): SettingsState => ({
  alertMonitorSettings: initialSectionWithEditDeleteState()
});

const actionManager = {
  // Alert Monitor Settings Table
  [SETTINGS_ACTION_TYPES.FETCH_ALERT_MONITOR_SETTINGS_TABLE]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      status: ACTION_STATUSES.SUCCEED,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.alertMonitorSettings.totalCount,
      data: payload.data,
      error: null
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      status: ACTION_STATUSES.FAILED,
      totalCount: null,
      data: null,
      error: payload.error
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_ALERT_MONITOR_SETTINGS_TABLE_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: initialSectionWithEditDeleteState()
  }),

  // Alert Monitor Settings
  [SETTINGS_ACTION_TYPES.FETCH_ALERT_MONITOR_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      edit: {
        status: ACTION_STATUSES.PENDING,
        data: null,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_ALERT_MONITOR_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      edit: {
        status: ACTION_STATUSES.SUCCEED,
        data: payload.data,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_ALERT_MONITOR_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      edit: {
        status: ACTION_STATUSES.FAILED,
        data: null,
        error: payload.error
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_ALERT_MONITOR_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      edit: initialSectionState()
    }
  }),

  // Delete Alert Monitor Settings
  [SETTINGS_ACTION_TYPES.FETCH_DELETE_ALERT_MONITOR_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      delete: {
        status: ACTION_STATUSES.PENDING,
        data: null,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      data: state.alertMonitorSettings.data.filter(item => item.Id !== payload.Id),
      delete: {
        status: ACTION_STATUSES.SUCCEED,
        data: payload.data,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      delete: {
        status: ACTION_STATUSES.FAILED,
        data: null,
        error: payload.error
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_DELETE_ALERT_MONITOR_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      delete: initialSectionState()
    }
  }),

  // Save Alert Monitor Setting
  [SETTINGS_ACTION_TYPES.FETCH_SAVE_ALERT_MONITOR_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      save: {
        status: ACTION_STATUSES.PENDING,
        data: null,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      data: payload.editRow ? [...spliceArrayFromCustomItem(state.alertMonitorSettings.data, payload.data, 'Id')]
        : [...payload.page === 1 ? [payload.data] : [], ...state.alertMonitorSettings.data],
      save: {
        status: ACTION_STATUSES.SUCCEED,
        data: payload.data,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      save: {
        status: ACTION_STATUSES.FAILED,
        data: null,
        error: payload.error
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_SAVE_ALERT_MONITOR_SETTINGS_FAILURE]: (state: SettingsState): SettingsState => ({
    ...state,
    alertMonitorSettings: {
      ...state.alertMonitorSettings,
      save: initialSectionState()
    }
  })
};

export default {
  actionManager,
  initialState
};
