/**
 * Closure
 * Returns a function which know provided prefix, that function
 *   will return string prefix + name.
 * @param {String} prefix - prefix
 * @returns {Function} {function(string): string}
 */
const createSymbol: Function = (prefix: string) => (name: string): string => `@@//${prefix}/${name}`;

export const createInternalSymbol: () => string = (prefix: string) => (name: string): string => `IN//${prefix}/${name}`;
export const createSignalRSymbol: () => string = (prefix: string) => (name: string): string => `SR//${prefix}/${name}`;
export const createWorkerSymbol: () => string = (prefix: string) => (name: string): string => `BW//${prefix}/${name}`;

export default createSymbol;
