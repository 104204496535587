import queryString from 'qs';
import { isEmptyObj } from './common';

export const parseQueryString = (str) => {
  const newStr = str && str.length ? str.replaceAll('+', '=_(_)_=') : str;
  const parsedObj = queryString.parse(
    newStr, { ignoreQueryPrefix: true, comma: true, plainObjects: true }
  );
  if (!isEmptyObj(parsedObj)) {
    Object.keys(parsedObj).forEach(key => {
      if (typeof parsedObj[key] === 'string') {
        parsedObj[key] = parsedObj[key].replaceAll('=_(_)_=', '+');
      }
    });
  }
  return parsedObj;
};

export const generateQueryString = (queryObj: any) => queryString.stringify(
  queryObj, { arrayFormat: 'comma', encode: false, comma: true, addQueryPrefix: true }
);
