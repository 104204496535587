/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import settings from 'config/constants';
import { Redirect } from 'react-router-dom';

const PlayerDeepView = React.lazy(() => import('./views/pages/PlayerDeepView'));
const PlayersPage = React.lazy(() => import('./views/pages/PlayerList'));

const subUrl = settings.PRODUCTS.LCRMT.path;

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const modulePath = `${subUrl}/players`;

const ROUTES: Array<Route> = [
  { path: `/${modulePath}/:id/:tab/:subtab`, name: 'Player/Tab/gaming', exact: true, page: PlayerDeepView },
  { path: `/${modulePath}/:id/:tab`, name: 'Player/Tab', exact: true, page: PlayerDeepView },
  { path: `/${modulePath}/:id`,
    name: 'Player Deep View',
    exact: true,
    page: () => {
      const params = window.location.pathname.split('/');
      return <Redirect to={`/${modulePath}/${params[params.length - 1]}/general`} />;
    } },
  { path: `/${modulePath}`, name: 'Players', exact: true, page: PlayersPage }
];

export default ROUTES;
