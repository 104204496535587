/**
 * @flow
 * */
import type { Action, Action as BaseAction } from 'lib/core/flow';

import NOTIFICATION from './types';

// get notification
export const fetchGetNotification = (): BaseAction => ({
  type: NOTIFICATION.FETCH_GET_NOTIFICATION
});

export const fetchGetNotificationFailed = (error: string): BaseAction => ({
  type: NOTIFICATION.FETCH_GET_NOTIFICATION_FAILURE,
  payload: error
});

export const fetchGetNotificationSucceed = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_GET_NOTIFICATION_SUCCESS,
  payload: data
});

export const clearGetNotification = (): BaseAction => ({
  type: NOTIFICATION.CLEAR_GET_NOTIFICATION
});

// get add update notification
export const fetchGetAddUpdateNotification = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_ADD_UPDATE_NOTIFICATION,
  payload: data
});

export const fetchGetAddUpdateNotificationFailed = (error: string): BaseAction => ({
  type: NOTIFICATION.FETCH_ADD_UPDATE_NOTIFICATION_FAILURE,
  payload: error
});

export const fetchGetAddUpdateNotificationSucceed = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_ADD_UPDATE_NOTIFICATION_SUCCESS,
  payload: data
});

export const clearGetAddUpdateNotification = (): BaseAction => ({
  type: NOTIFICATION.CLEAR_ADD_UPDATE_NOTIFICATION
});

// get notification filter
export const fetchGetNotificationFilter = (): BaseAction => ({
  type: NOTIFICATION.FETCH_GET_NOTIFICATION_FILTER
});

export const fetchGetNotificationFilterFailed = (error: string): BaseAction => ({
  type: NOTIFICATION.FETCH_GET_NOTIFICATION_FILTER_FAILURE,
  payload: error
});

export const fetchGetNotificationFilterSucceed = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_GET_NOTIFICATION_FILTER_SUCCESS,
  payload: data
});

export const clearGetNotificationFilter = (): BaseAction => ({
  type: NOTIFICATION.CLEAR_GET_NOTIFICATION_FILTER
});

// get notifications
export const fetchNotifications = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_NOTIFICATIONS,
  payload: data
});

export const fetchNotificationsFailed = (error: string): BaseAction => ({
  type: NOTIFICATION.FETCH_NOTIFICATIONS_FAILURE,
  payload: error
});

export const fetchNotificationsSucceed = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_NOTIFICATIONS_SUCCESS,
  payload: data
});

export const clearNotifications = (): BaseAction => ({
  type: NOTIFICATION.CLEAR_NOTIFICATIONS
});

// mark as read
export const fetchMarkAsRead = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_MARK_AS_READ,
  payload: data
});

export const fetchMarkAsReadFailed = (error: string): BaseAction => ({
  type: NOTIFICATION.FETCH_MARK_AS_READ_FAILURE,
  payload: error
});

export const fetchMarkAsReadSucceed = ({ data }: any): BaseAction => ({
  type: NOTIFICATION.FETCH_MARK_AS_READ_SUCCESS,
  payload: data
});

// cancel Notification Calls
export const cancelNotificationCalls = ({ name }: any): Action => ({
  type: NOTIFICATION.CANCEL_NOTIFICATION,
  payload: { name }
});
