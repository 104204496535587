const expandReducerChildren = (allData, payloadValue, data, status, error, dataKey) => allData?.map(e => {
  if (payloadValue === e[dataKey]) {
    return {
      ...e,
      expand: {
        status,
        error,
        data
      }
    };
  }
  return { ...e };
});

export default expandReducerChildren;
