/**
 * @flow
* */
import React from 'react';

const Dashboard = React.lazy(() => import('./views/pages/Dashboard'));
const ActivePlayers = React.lazy(() => import('./views/pages/ActivePlayers'));

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const modulePath = 'admin';

const ROUTES: Array<Route> = [
  { path: `/${modulePath}`, name: 'Admin', exact: true, page: Dashboard },
  { path: `/${modulePath}/active-players`, name: 'active-players', exact: true, page: ActivePlayers }
];

export default ROUTES;
