/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import PLAYER_GROUP_ACTION_TYPES from 'modules/SRMT/PlayerGroup/store/actions/playerGroup/types';
import { parsePlayersModelFromResponse } from 'modules/SRMT/Players/utils/parsers';
import playerGroupsSDK from 'modules/SRMT/PlayerGroup/core/sdk/PlayerGroupSDK';
import {
  setDeletePlayerFromGroupSuccess, setDeletePlayerFromGroupFailure,
  setFetchPlayerGroupInfoSuccess, setFetchPlayerGroupInfoFailure,
  setFetchPlayerGroupsSuccess, setFetchPlayerGroupsFailure,
  setDeletePlayerGroupSuccess, setDeletePlayerGroupFailure,
  setUpdatePlayerGroupSuccess, setUpdatePlayerGroupFailure,
  setFetchGroupPlayersSuccess, setFetchGroupPlayersFailure,
  setAddPlayerToGroupSuccess, setAddPlayerToGroupFailure,
  setAddPlayerGroupSuccess, setAddPlayerGroupFailure,
  setGroupActionSuccess, setGroupActionFailure,
  setUploadFileSuccess, setUploadFileFailure

} from 'modules/SRMT/PlayerGroup/store/actions/playerGroup';

class PlayerGroupController {
  static* fetchGroupPlayers({ payload }) {
    try {
      const { groupId, start, count, passTotalCount } = payload;
      const response = yield playerGroupsSDK.getGroupPlayers({ groupId, start, count, passTotalCount });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchGroupPlayersSuccess({ data, totalCount, passTotalCount }));
    } catch (error) {
      yield put(setFetchGroupPlayersFailure({ error: error.message }));
    }
  }

  static* fetchGroupInfo({ payload }) {
    try {
      const { groupId } = payload;
      const { data } = yield playerGroupsSDK.getPlayerGroupInfo({ groupId });
      yield put(setFetchPlayerGroupInfoSuccess({ data }));
    } catch (error) {
      yield put(setFetchPlayerGroupInfoFailure({ error: error.message }));
    }
  }

  static* fetchPlayerGroups({ payload }) {
    try {
      // $FlowFixMe
      const { count, start, filters, passTotalCount } = payload;
      const response = yield playerGroupsSDK.getPlayerGroups({ count, start, passTotalCount, filters });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchPlayerGroupsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerGroupsFailure({ error: err.message }));
    }
  }

  static* deletePlayerGroup({ payload }) {
    try {
      const { groupId } = payload;
      yield playerGroupsSDK.deleteGroup({ groupId });
      yield put(setDeletePlayerGroupSuccess({ groupId }));
    } catch (err) {
      yield put(setDeletePlayerGroupFailure({ error: err.message }));
    }
  }

  static* createPlayerGroup({ payload }) {
    try {
      const { data } = yield playerGroupsSDK.createGroup(payload);
      yield put(setAddPlayerGroupSuccess(data));
    } catch (err) {
      yield put(setAddPlayerGroupFailure({ error: err.message }));
    }
  }

  static* updatePlayerGroup({ payload }) {
    try {
      const { id, name, description } = payload;
      const { data } = yield playerGroupsSDK.updateGroup({ id, name, description });
      yield put(setUpdatePlayerGroupSuccess(data));
    } catch (err) {
      yield put(setUpdatePlayerGroupFailure({ error: err.message }));
    }
  }

  static* addPlayerToGroup({ payload }) {
    try {
      const { groupId, playerIds, description, filters } = payload;
      const { data } = yield playerGroupsSDK.addPlayerToGroup({ groupId, playerIds, description, filters });
      if (!data) throw new SyntaxError('something went wrong');
      yield put(setAddPlayerToGroupSuccess({ data }));
    } catch (err) {
      yield put(setAddPlayerToGroupFailure({ error: err.message }));
    }
  }

  static* deletePlayerFromGroup({ payload }) {
    try {
      const { groupId, playerIds } = payload;
      yield playerGroupsSDK.deletePlayerFromGroup({ groupId, playerIds });
      yield put(setDeletePlayerFromGroupSuccess());
    } catch (err) {
      yield put(setDeletePlayerFromGroupFailure({ error: err.message }));
    }
  }

  static* groupAction({ payload }) {
    try {
      yield playerGroupsSDK.groupAction(payload);
      yield put(setGroupActionSuccess());
    } catch (err) {
      yield put(setGroupActionFailure({ error: err.message }));
    }
  }

  static* uploadFile({ payload }) {
    try {
      const { formData } = payload;
      const { data } = yield playerGroupsSDK.uploadFile(formData);
      yield put(setUploadFileSuccess({ data }));
    } catch (err) {
      yield put(setUploadFileFailure({ error: err.message }));
    }
  }

  static* cancelPlayerGroupSDKCalls({ payload }) {
    const { name } = payload;
    yield playerGroupsSDK.cancelPlayerGroupSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.FETCH_GROUP_PLAYERS, PlayerGroupController.fetchGroupPlayers);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.FETCH_PLAYER_GROUPS, PlayerGroupController.fetchPlayerGroups);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.FETCH_GROUP_INFO, PlayerGroupController.fetchGroupInfo);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.CANCEL_PLAYER_GROUP_SDK_CALL, PlayerGroupController.cancelPlayerGroupSDKCalls);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.DELETE_GROUP, PlayerGroupController.deletePlayerGroup);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.ADD_GROUP, PlayerGroupController.createPlayerGroup);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.UPDATE_GROUP, PlayerGroupController.updatePlayerGroup);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.ADD_PLAYER_TO_GROUP, PlayerGroupController.addPlayerToGroup);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.DELETE_PLAYER_FROM_GROUP, PlayerGroupController.deletePlayerFromGroup);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.GROUP_ACTION, PlayerGroupController.groupAction);
    yield takeLatest(PLAYER_GROUP_ACTION_TYPES.UPLOAD_FILE, PlayerGroupController.uploadFile);
  }
}

export default PlayerGroupController.actionsWatcher;
