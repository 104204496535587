import createSymbol from 'lib/core/createSymbol';

const createAuthSymbol = createSymbol('HOW_TO');

export default {
  FETCH_HOW_TO: createAuthSymbol('FETCH_HOW_TO'),
  FETCH_HOW_TO_SUCCESS: createAuthSymbol('FETCH_HOW_TO_SUCCESS'),
  FETCH_HOW_TO_FAILURE: createAuthSymbol('FETCH_HOW_TO_FAILURE'),
  CLEAR_HOW_TO: createAuthSymbol('CLEAR_HOW_TO'),
  CANCEL_HOW_TO: createAuthSymbol('CANCEL_HOW_TO')
};
