import alerts from './alerts';
import reports from './reports';
import players from './players';
import common from './common';
import dashboard from './dashboard';

export default {
  dashboard,
  alerts,
  reports,
  players,
  common
};
