/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';

// Note Comment
export const fetchNoteComments = ({ playerId, count, start }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_NOTE_COMMENTS,
  payload: { playerId, count, start }
});
export const setFetchNoteCommentsSuccess = ({ data, totalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_NOTE_COMMENTS_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchNoteCommentsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_NOTE_COMMENTS_FAILURE,
  payload: { error }
});
export const clearNoteCommentsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_NOTE_COMMENTS_STATE
});

// Note Client Note
export const fetchClientNote = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_CLIENT_NOTE,
  payload: { playerId }
});
export const setFetchClientNoteSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_CLIENT_NOTE_SUCCESS,
  payload: { data }
});
export const setFetchClientNoteFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_CLIENT_NOTE_FAILURE,
  payload: { error }
});
export const clearClientNoteState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_CLIENT_NOTE_STATE
});

// Note Client Note
export const fetchAgpClientNote = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_AGP_COMMENTS,
  payload: { playerId }
});
export const setFetchAgpClientNoteSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_AGP_COMMENTS_SUCCESS,
  payload: { data }
});
export const setFetchAgpClientNoteFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_AGP_COMMENTS_FAILURE,
  payload: { error }
});
export const clearAgpClientNoteState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_AGP_COMMENTS_STATE
});

// Player Add Comment
export const addComment = ({ playerId, comment, source }: Object): Action => ({
  type: PLAYER_ACTIONS.ADD_COMMENT,
  payload: { playerId, comment, source }
});
export const setAddCommentSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_ADD_COMMENT_SUCCESS,
  payload: { data }
});
export const setAddCommentFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_ADD_COMMENT_FAILURE,
  payload: { error }
});
export const clearAddCommentState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_ADD_COMMENT_STATE
});
