/**
 * @flow
 * */
import GENERAL_ACTION from 'store/actions/general/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import { GeneralState } from 'store/types';

import { initialSectionStateWithError } from '../initialStates';

const initialState = (): GeneralState => ({
  globalInfo: initialSectionStateWithError()
});

const actionManager = {
  // global Info
  [GENERAL_ACTION.FETCH_PLAYER_GLOBAL_INFO]: (state: GeneralState) => ({
    ...state,
    globalInfo: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null,
      errorStatusCode: null
    }
  }),
  [GENERAL_ACTION.SET_FETCH_PLAYER_GLOBAL_INFO_SUCCESS]: (state: GeneralState, payload) => ({
    ...state,
    globalInfo: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      errorStatusCode: null
    }
  }),
  [GENERAL_ACTION.SET_FETCH_PLAYER_GLOBAL_INFO_FAILURE]: (state: GeneralState, payload) => ({
    ...state,
    globalInfo: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      errorStatusCode: payload.errorStatusCode
    }
  }),
  [GENERAL_ACTION.UPDATE_PLAYER_GLOBAL_INFO]: (state: GeneralState, payload) => ({
    ...state,
    globalInfo: {
      data: {
        ...state.globalInfo.data,
        Limit: payload.Limit,
        Delay: payload.Delay,
        WWLimit: payload.WWLimit !== undefined ? payload.WWLimit : state.globalInfo.data.WWLimit,
        preMatchLimit: payload.preMatchLimit !== undefined ? payload.preMatchLimit : state.globalInfo.data.preMatchLimit,
        WWDelay: payload.WWDelay !== undefined ? payload.WWDelay : state.globalInfo.data.WWDelay,
        IsTopPriority: payload.IsTopPriority !== undefined ? payload.IsTopPriority : state.globalInfo.data.IsTopPriority,
        SportsBookProfileName: payload.SportsBookProfileName ? payload.SportsBookProfileName : state.globalInfo.data.SportsBookProfileName,
        SportsBookProfileId: payload.SportsBookProfileId ? payload.SportsBookProfileId : state.globalInfo.data.SportsBookProfileId
      },
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      errorStatusCode: null
    }
  }),
  [GENERAL_ACTION.EDIT_PLAYER_GLOBAL_INFO]: (state: GeneralState, payload) => ({
    ...state,
    globalInfo: {
      ...state.globalInfo,
      data: {
        ...state.globalInfo.data,
        ...payload
      }
    }
  }),
  [GENERAL_ACTION.CLEAR_PLAYER_GLOBAL_INFO_STATE]: (state: GeneralState) => ({
    ...state,
    globalInfo: initialSectionStateWithError()
  })
};

export default (state: GeneralState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
