import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createAutomatedCommentSymbol = createSymbol('AUTOMATEDCOMMENTS');
const createAutomatedCommentInternalSymbol = createInternalSymbol('AUTOMATEDCOMMENTS');

export default {

  // Automated Comment Table
  FETCH_AUTOMATED_COMMENT: createAutomatedCommentSymbol('FETCH_AUTOMATED_COMMENT'),
  SET_FETCH_AUTOMATED_COMMENT_SUCCESS: createAutomatedCommentSymbol('SET_FETCH_AUTOMATED_COMMENT_SUCCESS'),
  SET_FETCH_AUTOMATED_COMMENT_FAILURE: createAutomatedCommentSymbol('SET_FETCH_AUTOMATED_COMMENT_FAILURE'),
  CLEAR_AUTOMATED_COMMENT_STATE: createAutomatedCommentInternalSymbol('CLEAR_AUTOMATED_COMMENT_STATE'),

  // Action Automated Comment
  FETCH_ACTION_AUTOMATED_COMMENT: createAutomatedCommentSymbol('FETCH_ACTION_AUTOMATED_COMMENT'),
  SET_FETCH_ACTION_AUTOMATED_COMMENT_SUCCESS: createAutomatedCommentSymbol('SET_FETCH_ACTION_AUTOMATED_COMMENT_SUCCESS'),
  SET_FETCH_ACTION_AUTOMATED_COMMENT_FAILURE: createAutomatedCommentSymbol('SET_FETCH_ACTION_AUTOMATED_COMMENT_FAILURE'),
  CLEAR_ACTION_AUTOMATED_COMMENT: createAutomatedCommentSymbol('CLEAR_ACTION_AUTOMATED_COMMENT'),

  // Delete Comment
  FETCH_DELETE_AUTOMATED_COMMENT: createAutomatedCommentSymbol('FETCH_DELETE_AUTOMATED_COMMENT'),
  SET_FETCH_DELETE_AUTOMATED_COMMENTS_SUCCESS: createAutomatedCommentSymbol('SET_FETCH_DELETE_AUTOMATED_COMMENTS_SUCCESS'),
  SET_FETCH_DELETE_AUTOMATED_COMMENT_FAILURE: createAutomatedCommentSymbol('SET_FETCH_DELETE_AUTOMATED_COMMENT_FAILURE'),
  CLEAR_DELETE_AUTOMATED_COMMENT: createAutomatedCommentSymbol('CLEAR_DELETE_AUTOMATED_COMMENT'),

  // cancel call
  CANCEL_AUTOMATED_COMMENT_SDK_CALL: createAutomatedCommentSymbol('CANCEL_AUTOMATED_COMMENT_SDK_CALL')
};
