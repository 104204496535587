/**
 * @flow
 * */
import React from 'react';
import Button from '@geneui/components/Button';
import warning from 'assets/icons/warning.svg';
import classNames from 'classnames';

import './styles.scss';

type Props = {
    horizontal?: boolean,
    buttonText?: string,
    onRefresh: Function,
    onClose?: Function,
    close?: boolean,
    title?: string,
    text?: string,
};

const defaultErrorText = 'Sorry we ran into a problem loading this information.';
const defaultErrorTitle = 'Error Loading Data.';
const defaultButtonText = 'Try Again';

/**
 *
 * @param {boolean} horizontal  - if true show horizontal if false show vertical
 * @param {Function} onRefresh  - callBack
 * @param {Function} onClose  - callBack
 * @param {string} buttonText  - default value is <defaultButtonText>
 * @param {string} text - default value is <defaultErrorText>
 * @param {string} title - default value is <defaultErrorTitle>
 * @param {string} close -
 * @returns {JSX.Element} - React.Component
 */
const FailedDataLoadingError = ({
  horizontal, onRefresh, buttonText = defaultButtonText,
  text = defaultErrorText, title = defaultErrorTitle,
  close, onClose
}: Props) => (
  <div className={`failedDataLoadingError ${horizontal ? 'horizontal ' : ''}`}>
    <div className="failedDataLoadingError-icon">
      <img alt="warning" src={warning} />
    </div>
    <div className="failedDataLoadingError-title">
      {title}
    </div>
    {!horizontal && (
      <div className="failedDataLoadingError-text">
        {text}
      </div>
    )}
    <div className={classNames({ 'flex': close })}>
      <div className="failedDataLoadingError-button">
        <Button color="confirm" icon="bc-icon-refresh" onClick={onRefresh}>{buttonText}</Button>
      </div>
      {close && (
        <div className="failedDataLoadingError-button failedDataLoadingError-close">
          <Button color="default" onClick={onClose}>Close</Button>
        </div>
      )}
    </div>
  </div>
);

FailedDataLoadingError.displayName = 'FailedDataLoadingError';

export default FailedDataLoadingError;
