/**
 * @flow
 * */

import { takeLatest, put } from 'redux-saga/effects';
import APP_SETTINGS_ACTION_TYPES from 'store/actions/appSettings/types';
import {
  setUpdateAppSettingsSuccess,
  setUpdateAppSettingsFailure,
  setFetchAppSettingsSuccess,
  setFetchAppSettingsFailure,
  setUpdateSettingsSuccess,
  setUpdateSettingsFailure
} from 'store/actions/appSettings/actions';

import appSettingsSDK from '../sdk/appSettings';
import AGPSDK from '../sdk/AGPSDK/AGPSDK';

class AppSettingsController {
  static* fetchAppSettings({ payload }) {
    const { userId } = payload;
    try {
      const { data } = yield appSettingsSDK.getAppSettings({ userId });
      yield put(setFetchAppSettingsSuccess({ data }));
    } catch (err) {
      yield put(setFetchAppSettingsFailure({ error: err.message }));
    }
  }

  static* updateAppSettings({ payload }) {
    const { userId, viewMode, language, isSizeChecked } = payload;
    try {
      const data = yield appSettingsSDK.updateUserSettings({ userId, viewMode, language, isSizeChecked });
      yield put(setUpdateAppSettingsSuccess(data));
    } catch (err) {
      yield put(setUpdateAppSettingsFailure({ error: err.message }));
    }
  }

  static* updateSettings({ payload }) {
    const { params } = payload;
    try {
      yield AGPSDK.updateSetting(params);
      yield put(setUpdateSettingsSuccess({ id: params.TimeZone }));
    } catch (err) {
      yield put(setUpdateSettingsFailure({ error: err.message }));
    }
  }

  static* appSettingsWatcher<T>(): Iterable<T> {
    yield takeLatest(APP_SETTINGS_ACTION_TYPES.FETCH_APP_SETTINGS, AppSettingsController.fetchAppSettings);
    yield takeLatest(APP_SETTINGS_ACTION_TYPES.UPDATE_APP_SETTINGS, AppSettingsController.updateAppSettings);
    yield takeLatest(APP_SETTINGS_ACTION_TYPES.UPDATE_AGP_SETTINGS, AppSettingsController.updateSettings);
  }
}

export default AppSettingsController.appSettingsWatcher;
