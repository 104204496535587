import { FilterKeys } from 'config/settings/constants';

export default [
  FilterKeys.clientSportsBookProfile,
  FilterKeys.singleThresholdAlertType,
  FilterKeys.multipleAccountAlertType,
  FilterKeys.transactionTypes,
  FilterKeys.combinationType,
  FilterKeys.activePartners,
  FilterKeys.paymentSystems,
  FilterKeys.exclusionType,
  FilterKeys.categoryType,
  FilterKeys.playerStatus,
  FilterKeys.alertStatus,
  FilterKeys.countries,
  FilterKeys.product
];
