import createSymbol from 'lib/core/createSymbol';

const createAMLSymbol = createSymbol('AML');

export default {
  /** Boxes */
  FETCH_BOXES: createAMLSymbol('FETCH_BOXES'),
  SET_FETCH_BOXES_SUCCESS: createAMLSymbol('SET_FETCH_BOXES_SUCCESS'),
  SET_FETCH_BOXES_FAILURE: createAMLSymbol('SET_FETCH_BOXES_FAILURE'),
  CLEAR_BOXES: createAMLSymbol('CLEAR_BOXES'),

  /** Configs */
  FETCH_CONFIGS: createAMLSymbol('FETCH_CONFIGS'),
  SET_FETCH_CONFIGS_SUCCESS: createAMLSymbol('SET_FETCH_CONFIGS_SUCCESS'),
  SET_FETCH_CONFIGS_FAILURE: createAMLSymbol('SET_FETCH_CONFIGS_FAILURE'),
  CLEAR_CONFIGS: createAMLSymbol('CLEAR_CONFIGS'),

  /** Dashboard */
  FETCH_AML_DASHBOARD: createAMLSymbol('FETCH_AML_DASHBOARD'),
  SET_FETCH_AML_DASHBOARD_SUCCESS: createAMLSymbol('SET_FETCH_AML_DASHBOARD_SUCCESS'),
  SET_FETCH_AML_DASHBOARD_FAILURE: createAMLSymbol('SET_FETCH_AML_DASHBOARD_FAILURE'),
  CLEAR_AML_DASHBOARD_STATE: createAMLSymbol('CLEAR_AML_DASHBOARD_STATE'),

  /** Transactions */
  FETCH_TRANSACTIONS: createAMLSymbol('FETCH_TRANSACTIONS'),
  SET_FETCH_TRANSACTIONS_SUCCESS: createAMLSymbol('SET_FETCH_TRANSACTIONS_SUCCESS'),
  SET_FETCH_TRANSACTIONS_FAILURE: createAMLSymbol('SET_FETCH_TRANSACTIONS_FAILURE'),
  CLEAR_TRANSACTIONS: createAMLSymbol('CLEAR_TRANSACTIONS'),

  /** Players */
  FETCH_AML_PLAYERS: createAMLSymbol('FETCH_AML_PLAYERS'),
  SET_FETCH_AML_PLAYERS_SUCCESS: createAMLSymbol('SET_FETCH_AML_PLAYERS_SUCCESS'),
  SET_FETCH_AML_PLAYERS_FAILURE: createAMLSymbol('SET_FETCH_AML_PLAYERS_FAILURE'),
  CLEAR_AML_PLAYERS: createAMLSymbol('CLEAR_AML_PLAYERS'),

  /** Players Alerts */
  FETCH_AML_PLAYER_ALERTS: createAMLSymbol('FETCH_AML_PLAYER_ALERTS'),
  SET_FETCH_AML_PLAYER_ALERTS_SUCCESS: createAMLSymbol('SET_FETCH_AML_PLAYER_ALERTS_SUCCESS'),
  SET_FETCH_PLAYER_ALERTS_BULK_REVIEW_SUCCESS: createAMLSymbol('SET_FETCH_PLAYER_ALERTS_BULK_REVIEW_SUCCESS'),
  SET_FETCH_AML_PLAYER_ALERTS_FAILURE: createAMLSymbol('SET_FETCH_AML_PLAYER_ALERTS_FAILURE'),
  CLEAR_AML_PLAYER_ALERTS: createAMLSymbol('CLEAR_AML_PLAYER_ALERTS'),

  /** Players Alerts Bulk Review */
  FETCH_AML_PLAYER_ALERTS_BULK_REVIEW: createAMLSymbol('FETCH_AML_PLAYER_ALERTS_BULK_REVIEW'),
  SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_SUCCESS: createAMLSymbol('SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_SUCCESS'),
  SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_FAILURE: createAMLSymbol('SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_FAILURE'),
  CLEAR_AML_PLAYER_ALERTS_BULK_REVIEW: createAMLSymbol('CLEAR_AML_PLAYER_ALERTS_BULK_REVIEW'),

  /** Players Multi Accounts */
  FETCH_AML_PLAYERS_MULTI_ACCOUNTS: createAMLSymbol('FETCH_AML_PLAYERS_MULTI_ACCOUNTS'),
  SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_SUCCESS: createAMLSymbol('SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_SUCCESS'),
  SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_FAILURE: createAMLSymbol('SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_FAILURE'),
  CLEAR_AML_PLAYERS_MULTI_ACCOUNTS: createAMLSymbol('CLEAR_AML_PLAYERS_MULTI_ACCOUNTS'),

  /** Score Rule */
  FETCH_PLAYER_SCORE_RULE: createAMLSymbol('FETCH_PLAYER_SCORE_RULE'),
  SET_FETCH_PLAYER_SCORE_RULE_SUCCESS: createAMLSymbol('SET_FETCH_PLAYER_SCORE_RULE_SUCCESS'),
  SET_FETCH_PLAYER_SCORE_RULE_FAILURE: createAMLSymbol('SET_FETCH_PLAYER_SCORE_RULE_FAILURE'),
  CLEAR_FETCH_PLAYER_SCORE_RULE: createAMLSymbol('CLEAR_FETCH_PLAYER_SCORE_RULE'),

  /** Change Score Count */
  FETCH_CHANGE_SCORE_COUNT: createAMLSymbol('FETCH_CHANGE_SCORE_COUNT'),
  SET_CHANGE_SCORE_COUNT_SUCCESS: createAMLSymbol('SET_CHANGE_SCORE_COUNT_SUCCESS'),
  SET_CHANGE_SCORE_COUNT_FAILURE: createAMLSymbol('SET_CHANGE_SCORE_COUNT_FAILURE'),
  UPDATE_CHANGE_SCORE_COUNT: createAMLSymbol('UPDATE_CHANGE_SCORE_COUNT'),
  CLEAR_CHANGE_SCORE_COUNT: createAMLSymbol('CLEAR_CHANGE_SCORE_COUNT'),

  /** Score Rule Enabled/Disabled */
  FETCH_PLAYER_SCORE_RULE_ACTION: createAMLSymbol('FETCH_PLAYER_SCORE_RULE_ACTION'),
  SET_FETCH_PLAYER_SCORE_RULE_ACTION_SUCCESS: createAMLSymbol('SET_FETCH_PLAYER_SCORE_RULE_ACTION_SUCCESS'),
  SET_FETCH_PLAYER_SCORE_RULE_ACTION_FAILURE: createAMLSymbol('SET_FETCH_PLAYER_SCORE_RULE_ACTION_FAILURE'),
  CLEAR_FETCH_PLAYER_SCORE_RULE_ACTION: createAMLSymbol('CLEAR_FETCH_PLAYER_SCORE_RULE_ACTION'),

  /** Player Block History Report */
  FETCH_PLAYER_BLOCK_HISTORY_REPORT: createAMLSymbol('FETCH_PLAYER_BLOCK_HISTORY_REPORT'),
  SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_SUCCESS: createAMLSymbol('SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_SUCCESS'),
  SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_FAILURE: createAMLSymbol('SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_FAILURE'),
  CLEAR_PLAYER_BLOCK_HISTORY_REPORT_STATE: createAMLSymbol('CLEAR_PLAYER_BLOCK_HISTORY_REPORT_STATE'),

  /** Player Score Report */
  FETCH_PLAYER_SCORE_REPORT: createAMLSymbol('FETCH_PLAYER_SCORE_REPORT'),
  SET_FETCH_PLAYER_SCORE_REPORT_SUCCESS: createAMLSymbol('SET_FETCH_PLAYER_SCORE_REPORT_SUCCESS'),
  SET_FETCH_PLAYER_SCORE_REPORT_FAILURE: createAMLSymbol('SET_FETCH_PLAYER_SCORE_REPORT_FAILURE'),
  CLEAR_PLAYER_SCORE_REPORT_STATE: createAMLSymbol('CLEAR_PLAYER_SCORE_REPORT_STATE'),

  /** Aml Rule Score Report */
  FETCH_AML_RULE_SCORE_REPORT: createAMLSymbol('FETCH_AML_RULE_SCORE_REPORT'),
  SET_FETCH_AML_RULE_SCORE_REPORT_SUCCESS: createAMLSymbol('SET_FETCH_AML_RULE_SCORE_REPORT_SUCCESS'),
  SET_FETCH_AML_RULE_SCORE_REPORT_FAILURE: createAMLSymbol('SET_FETCH_AML_RULE_SCORE_REPORT_FAILURE'),
  CLEAR_AML_RULE_SCORE_REPORT_STATE: createAMLSymbol('CLEAR_AML_RULE_SCORE_REPORT_STATE'),

  /** Rule Score History */
  FETCH_PLAYER_SCORE_HISTORY: createAMLSymbol('FETCH_PLAYER_SCORE_HISTORY'),
  SET_FETCH_PLAYER_SCORE_HISTORY_SUCCESS: createAMLSymbol('SET_FETCH_PLAYER_SCORE_HISTORY_SUCCESS'),
  SET_FETCH_PLAYER_SCORE_HISTORY_FAILURE: createAMLSymbol('SET_FETCH_PLAYER_SCORE_HISTORY_FAILURE'),
  CLEAR_PLAYER_SCORE_HISTORY_STATE: createAMLSymbol('CLEAR_PLAYER_SCORE_HISTORY_STATE'),

  /** Aml Alerts Count */
  FETCH_AML_ALERTS_COUNT: createAMLSymbol('FETCH_AML_ALERTS_COUNT'),
  SET_FETCH_AML_ALERTS_COUNT_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_COUNT_SUCCESS'),
  SET_FETCH_AML_ALERTS_COUNT_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_COUNT_FAILURE'),
  CLEAR_AML_ALERTS_COUNT_STATE: createAMLSymbol('CLEAR_AML_ALERTS_COUNT_STATE'),

  /** Alerts */
  FETCH_AML_ALERTS: createAMLSymbol('FETCH_AML_ALERTS'),
  SET_FETCH_AML_ALERTS_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_SUCCESS'),
  SET_FETCH_AML_ALERTS_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_FAILURE'),
  CLEAR_AML_ALERTS_STATE: createAMLSymbol('CLEAR_AML_ALERTS_STATE'),

  /** expanded data */
  FETCH_AML_EXPANDED_DATA: createAMLSymbol('FETCH_AML_EXPANDED_DATA'),
  FETCH_AML_EXPANDED_DATA_SUCCESS: createAMLSymbol('FETCH_AML_EXPANDED_DATA_SUCCESS'),
  FETCH_AML_EXPANDED_DATA_FAILURE: createAMLSymbol('FETCH_AML_EXPANDED_DATA_FAILURE'),

  /** Alerts Export */
  FETCH_AML_ALERTS_EXPORT: createAMLSymbol('FETCH_AML_ALERTS_EXPORT'),
  SET_FETCH_AML_ALERTS_EXPORT_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_EXPORT_SUCCESS'),
  SET_FETCH_AML_ALERTS_EXPORT_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_EXPORT_FAILURE'),
  CLEAR_AML_ALERTS_EXPORT_STATE: createAMLSymbol('CLEAR_AML_ALERTS_EXPORT_STATE'),

  /** Alerts Review */
  FETCH_AML_ALERTS_REVIEW: createAMLSymbol('FETCH_AML_ALERTS_REVIEW'),
  SET_FETCH_AML_ALERTS_REVIEW_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_SUCCESS'),
  SET_FETCH_AML_ALERTS_REVIEW_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_FAILURE'),
  CLEAR_AML_ALERTS_REVIEW_STATE: createAMLSymbol('CLEAR_AML_ALERTS_REVIEW_STATE'),

  /** Alerts Review Expand */
  FETCH_AML_ALERTS_REVIEW_EXPAND: createAMLSymbol('FETCH_AML_ALERTS_REVIEW_EXPAND'),
  SET_FETCH_AML_ALERTS_REVIEW_EXPAND_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_EXPAND_SUCCESS'),
  SET_FETCH_AML_ALERTS_REVIEW_EXPAND_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_EXPAND_FAILURE'),

  /** Alerts Review History */
  FETCH_AML_ALERTS_REVIEW_HISTORY: createAMLSymbol('FETCH_AML_ALERTS_REVIEW_HISTORY'),
  SET_FETCH_AML_ALERTS_REVIEW_HISTORY_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_HISTORY_SUCCESS'),
  SET_FETCH_AML_ALERTS_REVIEW_HISTORY_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_HISTORY_FAILURE'),
  CLEAR_AML_ALERTS_REVIEW_HISTORY_STATE: createAMLSymbol('CLEAR_AML_ALERTS_REVIEW_HISTORY_STATE'),

  /** Alerts Review History Expand */
  FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND: createAMLSymbol('FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND'),
  SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_SUCCESS'),
  SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_FAILURE'),

  /** Alerts Review Edit */
  FETCH_AML_ALERTS_REVIEW_EDIT: createAMLSymbol('FETCH_AML_ALERTS_REVIEW_EDIT'),
  SET_FETCH_AML_ALERTS_REVIEW_EDIT_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_EDIT_SUCCESS'),
  SET_FETCH_AML_ALERTS_REVIEW_EDIT_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_REVIEW_EDIT_FAILURE'),
  CLEAR_AML_ALERTS_REVIEW_EDIT_STATE: createAMLSymbol('CLEAR_AML_ALERTS_REVIEW_EDIT_STATE'),

  /** Alerts Bulk Review */
  FETCH_AML_ALERTS_BULK_REVIEW: createAMLSymbol('FETCH_AML_ALERTS_BULK_REVIEW'),
  SET_FETCH_AML_ALERTS_BULK_REVIEW_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERTS_BULK_REVIEW_SUCCESS'),
  SET_FETCH_AML_ALERTS_BULK_REVIEW_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERTS_BULK_REVIEW_FAILURE'),
  CLEAR_AML_ALERT_BULK_REVIEW_STATE: createAMLSymbol('CLEAR_AML_ALERT_BULK_REVIEW_STATE'),

  /** Aml Alerts Risk Category History */
  FETCH_AML_ALERT_RISK_CATEGORY: createAMLSymbol('FETCH_AML_ALERT_RISK_CATEGORY'),
  SET_FETCH_AML_ALERT_RISK_CATEGORY_SUCCESS: createAMLSymbol('SET_FETCH_AML_ALERT_RISK_CATEGORY_SUCCESS'),
  SET_FETCH_AML_ALERT_RISK_CATEGORY_FAILURE: createAMLSymbol('SET_FETCH_AML_ALERT_RISK_CATEGORY_FAILURE'),
  CLEAR_AML_ALERT_RISK_CATEGORY_STATE: createAMLSymbol('CLEAR_AML_ALERT_RISK_CATEGORY_STATE'),

  /** Transaction Activity */
  FETCH_TRANSACTION_ACTIVITY_DATA: createAMLSymbol('FETCH_TRANSACTION_ACTIVITY_DATA'),
  SET_FETCH_TRANSACTION_ACTIVITY_DATA_SUCCESS: createAMLSymbol('SET_FETCH_TRANSACTION_ACTIVITY_DATA_SUCCESS'),
  SET_FETCH_TRANSACTION_ACTIVITY_DATA_FAILURE: createAMLSymbol('SET_FETCH_TRANSACTION_ACTIVITY_DATA_FAILURE'),
  CLEAR_TRANSACTION_ACTIVITY_DATA_STATE: createAMLSymbol('CLEAR_TRANSACTION_ACTIVITY_DATA_STATE'),

  /** Betting Hourly Activity */
  FETCH_BETTING_HOURLY_ACTIVITY_DATA: createAMLSymbol('FETCH_BETTING_HOURLY_ACTIVITY_DATA'),
  SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_SUCCESS: createAMLSymbol('SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_SUCCESS'),
  SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_FAILURE: createAMLSymbol('SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_FAILURE'),
  CLEAR_BETTING_HOURLY_ACTIVITY_DATA_STATE: createAMLSymbol('CLEAR_BETTING_HOURLY_ACTIVITY_DATA_STATE'),

  CANCEL_AML_CALL: createAMLSymbol('CANCEL_AML_CALL')
};
