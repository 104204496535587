import type { MonitorState, MonitorTabState } from 'modules/SRMT/AlertMonitor/types';

interface IMonitorFactory {
  // the method to create Initial Monitor Tab State
  createInitialMonitorTab(name: string): MonitorTabState;

  // the method to create Initial Monitor State
  createInitialMonitor(name: string, tabs: Array<MonitorTabState>): MonitorState;
}

function toNestedPlainObjectFromArray(val: Array<any>) {
  const value = Object(val);
  const result = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in value) {
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    const keyName = Object.keys(value[key])[0];
    result[keyName] = value[key][keyName];
  }
  return result;
}

/*
 * class AlertMonitor Factory
 * The Factory class to create monitor specific state data structure
 */
class AlertMonitorFactory implements IMonitorFactory {
  /**
   * Factory method to create Initial Monitor Tab
   * @param {string} name - the name of the monitor tab
   * @returns {MonitorState} - the initial Monitor Tab State
   */
  static createInitialMonitorTab(name: string /* filters later */): MonitorState {
    return {
      [name]: {
        alerts: {
          data: null,
          status: null,
          error: null
        },
        reviewing_alert: {
          data: null,
          status: null,
          error: null,
          edit: {
            data: null,
            status: null,
            error: null
          },
          history: {
            data: null,
            status: null,
            error: null
          }
        }
      }
    };
  }

  /**
   * Factory method to create Initial Monitor
   * @param {string} name - the name of the monitor
   * @param {Array<MonitorState>} tabs - tab instance's array, which includes alert monitor of this name
   * @returns {MonitorState} - the initial Monitor State
   */
  static createInitialMonitor(name: string, tabs: Array<MonitorTabState>): MonitorState {
    return {
      [name]: {
        ...toNestedPlainObjectFromArray(tabs)
      }
    };
  }
}

export default AlertMonitorFactory;
