const InitState = {
  status: null,
  error: null
};

export const initialAdditionalState = () => ({
  Women: {
    data: null,
    action: { ...InitState }
  },
  Age: {
    data: null,
    action: { ...InitState }
  },
  Country: {
    data: null,
    action: { ...InitState }
  },
  Currency: {
    data: null,
    action: { ...InitState }
  },
  DangerousIP: {
    data: null,
    action: { ...InitState }
  },
  PreDefinedIps: {
    data: null,
    action: { ...InitState }
  },
  Fingerprint: {
    data: null,
    action: { ...InitState }
  },
  AffilateIdBTag: {
    data: null,
    action: { ...InitState }
  },
  ...InitState
});
