import notFoundDetector from 'core/services/network/interceptors/notFoundDetector';
import { CheckManuallyEnum } from 'modules/SRMT/Players/utils/common';
import NetworkService from 'core/services/network/NetworkService';
import { ACTION_TYPES } from 'lib/core/actionStatuses';
import type { GlobalKPIsModel } from 'types';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';
import type {
  AdditionalCategoriesBulkMergeParams, AdditionalCategoriesBulkMergeModel,
  GameStyleCategoryBulkUpdateParams, GameStyleCategoryBulkUpdateModel,
  PlayerFinancialTransactionModel, PlayerFinancialTransactionParams,
  PlayerAlertModel, ProcessedAlertsModel, ProcessedAlertsParams,
  GameStyleCategoryUpdateParams, GameStyleCategoryUpdateModel,
  PlayerFinancialBalanceParams, PlayerFinancialBalanceModel,
  PlayerFinancialCashOutParams, PlayerFinancialCashOutModel,
  PlayerForceCalculationParams, PlayerForceCalculationModel,
  PlayerCategoryHistoryModel, PlayerCategoryHistoryParams,
  PlayerRestrictionsParams, EditPlayerRestrictionsParams,
  PartnerGameStyleCategoryParams, PlayerAddCommentParams,
  PlayerAllSessionIpsParams, PlayerAllSessionIpsModel,
  PlayerFinancialKPIsParams, PlayerFinancialKPIsModel,
  AllPlayersSessionIpParams, AllPlayersSessionIpModel,
  AdditionalCategoriesParams, AdditionalCategoryModel,
  PlayerExistingAlertModel, PlayerExistingAlertParams,
  FinancialCategoryModel, FinancialCategoryParams,
  GameStyleCategoryParams, GameStyleCategoryModel,
  PlayerSessionIpsParams, PlayerSessionIpsModel,
  RemoveTopPriorityParams, SetTopPriorityParams,
  RestrictionsLogParams, RestrictionsLogModel,
  PlayerFingerprintParams, CheckHistoryModel,
  PlayerAlertParams, PlayerRestrictionsModel,
  PlayerKPITreeParams, PlayerKPITreeModel,
  PlayerSetAlertParams, GlobalKPIsParams,
  MultiAccountModel, MultiAccountsParams,
  NoteCommentsModel, NoteCommentsParams,
  ClientNoteParams, ClientNoteModel,
  PlayerInfoModel, PlayerInfoParams,
  GlobalInfoModel, GlobalInfoParams,
  PlayersModel, PlayersParams,
  AlertPlayersBetsParams
} from './types';
import { AlertsBulkReviewParams, PlayerFingerprintsModel } from './types';

/**
 * @class PlayersSDK
 */
class PlayersSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    _amlApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, [notFoundDetector]);
      this._amlApiService = new NetworkService(config.amlApi, [notFoundDetector]);
    }

    /**
     * getGlobalInfo
     * @param {GlobalInfoParams} params - PlayerId
     * @returns {Promise<{data: GlobalInfoModel}>} -
     */
    async getGlobalInfo(params: GlobalInfoParams): Promise<{data: GlobalInfoModel}> {
      const { playerId, productType } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerGlobalInfo',
        { query_params: { playerId, productType }, abortController: this.setAbortController('getGlobalInfo') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getGlobalKPIs
     * @param {GlobalKPIsParams} params - PlayerId
     * @returns {Promise<{data: GlobalKPIsModel}>} -
     */
    async getGlobalKPIs(params: GlobalKPIsParams): Promise<{ data: GlobalKPIsModel }> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'PlayerKPI/GetPlayerBetKPIGlobal',
        { query_params: { playerId }, abortController: this.setAbortController('getGlobalKPIs') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getAdditionalCategories
     * @param {AdditionalCategoriesParams} params -
     * @returns {Promise<{data: Array<AdditionalCategoryModel> }>} -
     */
    async getAdditionalCategories(params: AdditionalCategoriesParams): Promise<{ data: Array<AdditionalCategoryModel> }> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerAdditionalCategories',
        { query_params: { playerId }, abortController: this.setAbortController('getAdditionalCategories') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getAvailableAdditionalCategories
     * @param {AdditionalCategoriesParams} params -
     * @returns {Promise<{data: Array<AdditionalCategoryModel> }>} -
     */
    async getAvailableAdditionalCategories(params: AdditionalCategoriesParams): Promise<{ data: Array<AdditionalCategoryModel> }> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/getAvailableAdditionalCategories',
        { query_params: { playerId }, abortController: this.setAbortController('getAvailableAdditionalCategories') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getFinancialCategories
     * @param {AdditionalCategoriesParams} params -
     * @returns {Promise<{data: Array<FinancialCategoryModel> }>} -
     */
    async getFinancialCategories(params: FinancialCategoryParams): Promise<{ data: FinancialCategoryModel }> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetFincanceCategory', // @TODO Change Back-end
        { query_params: { playerId }, abortController: this.setAbortController('getFinancialCategories') }
      );
      return {
        data: body.Data
      };
    }

    /**
     * getPlayerInfo
     * @param {PlayerInfoParams} params - PlayerId
     * @returns {Promise<{data: PlayerInfoModel}>} -
     */
    async getPlayerInfo(params: PlayerInfoParams): Promise<{data: PlayerInfoModel}> {
      const { id } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerInfo',
        { query_params: { playerId: id }, abortController: this.setAbortController('getPlayerInfo') }
      );
      return {
        data: body.Data
      };
    }

    /**
     * getCategoryHistory
     * @param {PlayerCategoryHistoryParams} params -
     * @returns {Promise} -
     */
    async getCategoryHistory(params: PlayerCategoryHistoryParams): Promise<{ Data: {Models: ?Array<PlayerCategoryHistoryModel>, TotalCount: number}}> {
      const { playerId, count, start } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/LastChangedCategories',
        { query_params: { playerId, count, start }, abortController: this.setAbortController('getCategoryHistory') }
      );

      return body;
    }

    /**
   * fetchCheckHistory
   * @param {Object} params -
   * @returns {Promise} -
   */
    async fetchCheckHistory(params: Object): Promise<{ Data: {Models: ?Array<CheckHistoryModel>, TotalCount: number}}> {
      const { count, start, fromDeepView, passTotalCount, projectType, ...filters } = params;
      let url = 'Reports/GetClientCheckHistoryReport';
      let service = this._rmtApiService;
      if (fromDeepView) {
        url = 'ClientCheck/GetClientCheckHistoriesByClientId';
      } else if (projectType === CheckManuallyEnum.AML) {
        url = 'Reports/GetClientCheckHistoryReport';
        service = this._amlApiService;
      }

      const { body } = await service.makeAPIGetRequest(
        url,
        { query_params: { ...filters, count, start, passTotalCount }, abortController: this.setAbortController('fetchCheckHistory') }
      );

      return body;
    }

    /**
     * getMultiAccounts
     * @param {GlobalKPIsParams} params -
     * @returns {Promise<{data: GlobalKPIsModel}>} -
     */
    async getMultiAccounts(params: MultiAccountsParams): Promise<{ data: Array<MultiAccountModel | null> }> {
      const { playerId, count, start } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetFraudMultiAccounts',
        { query_params: { playerId, count, start }, abortController: this.setAbortController('getMultiAccounts') }
      );

      return body;
    }

    /**
     * getNoteComments
     * @param {NoteCommentsParams} params -
     * @returns {Promise<{data: NoteCommentsModel}>} -
     */
    async getNoteComments(params: NoteCommentsParams): Promise<{ data: NoteCommentsModel }> {
      const { playerId, count, start } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Comment/GetCommentHistory',
        { query_params: { playerId, count, start }, abortController: this.setAbortController('getNoteComments') }
      );
      return {
        // $FlowFixMe
        Data: body.Data
      };
    }

    /**
     * getClientNote
     * @param {ClientNoteParams} params -
     * @returns {Promise<{data: ClientNoteModel}>} -
     */
    async getClientNote(params: ClientNoteParams): Promise<{ data: ClientNoteModel }> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Comment/GetCommentHistory',
        { query_params: { playerId }, abortController: this.setAbortController('getClientNote') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPlayers
     * @param {PlayersParams} params -
     * @returns {Promise<{data: PlayersModel}>} -
     */
    async getPlayers(params: PlayersParams): Promise<{ data: PlayersModel }> {
      const { count, start, passTotalCount, ...filters } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Player/FindPlayer',
        { body: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('getPlayers') }
      );

      return body;
    }

    /**
     * getPlayersBets
     * @param {PlayersParams} params -
     * @returns {Promise<{data: PlayersModel}>} -
     */
    async getPlayersBets(params: PlayersParams): Promise<{ data: PlayersModel }> {
      const { count, start, passTotalCount, playerId, ConvertCurrencyType, ...filters } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerBets',
        { query_params: { count, start, passTotalCount, ClientId: playerId, ConvertCurrencyType, ...filters }, abortController: this.setAbortController('getPlayerBets') }
      );

      return body;
    }

    /**
     * getFinancialExpandBetsRowChildrenData
     * @param {AlertExpandRowChildrenParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async getFinancialExpandBetsRowChildrenData({ betId }): Promise<any> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerBetSelection',
        { query_params: { betId } }
      );
      return body;
    }

    /**
   * removeTopPriority`
   * @param {RemoveTopPriorityParams} params -
   * @returns {Promise<{data: PlayersModel}>} -
   */
    async removeTopPriority(params: RemoveTopPriorityParams): Promise<{ data:any }> {
      const { playerId, state } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Player/ModifyTopPriority',
        { body: { playerId, state }, abortController: this.setAbortController('removeTopPriority') }
      );
      return body;
    }

    /**
   * setTopPriority
   * @param {PlayersParams} params -
   * @returns {Promise<{data: PlayersModel}>} -
   */
    async setTopPriority(params: SetTopPriorityParams): Promise<{ data:any }> {
      const { limit, preMatchLimit, delay, playerId, clientSportsBookProfileId, state, comment } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Player/ModifyTopPriority',
        { body: { limit, preMatchLimit, delay, playerId, clientSportsBookProfileId, state, comment }, abortController: this.setAbortController('setTopPriority') }
      );
      return body;
    }

    /**
     * getRestrictionsLog
     * @param {RestrictionsLogParams} params -
     * @returns {Promise<{data: RestrictionsLogModel}>} -
     */
    async getRestrictionsLog(params: RestrictionsLogParams): Promise<{ data: RestrictionsLogModel }> {
      const { playerId, count, start } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/BlockHistory',
        { query_params: { playerId, count, start }, abortController: this.setAbortController('getRestrictionsLog') }
      );
      return body;
    }

    /**
     * getPlayerFinancialKPIs
     * @param {PlayerFinancialKPIsParams} params -
     * @returns {Promise<{data: PlayerFinancialKPIsModel}>} -
     */
    async getPlayerFinancialKPIs(params: PlayerFinancialKPIsParams): Promise<{ data: PlayerFinancialKPIsModel }> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetFinancialKPI',
        { query_params: { playerId }, abortController: this.setAbortController('getPlayerFinancialKPIs') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPlayerFinancialCashOut
     * @param {PlayerFinancialCashOutParams} params -
     * @returns {Promise<{data: Array<PlayerFinancialCashOutModel>}>} -
     */
    async getPlayerFinancialCashOut(params: PlayerFinancialCashOutParams): Promise<{ data: Array<PlayerFinancialCashOutModel>}> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerCashOut',
        { query_params: { playerId } }
      );
      return {
        data: body.Data
      };
    }

    /**
     * getPlayerExistingAlert
     * @param {PlayerExistingAlertParams} params -
     * @returns {Promise<{data: PlayerExistingAlertModel}>} -
     */
    async getPlayerExistingAlert(params: PlayerExistingAlertParams): Promise<{ data: PlayerExistingAlertModel }> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'MonitoringAlert/GetProcessingAlert',
        { query_params: { playerId } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * setPlayerAlert
     * @param {PlayerSetAlertParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async setPlayerAlert(params: PlayerSetAlertParams): Promise<{ data: Object }> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'MonitoringAlert/SetAlert',
        { body: params }
      );

      return {
        data: body.Data
      };
    }

    /**
   * fetchResolveMonitoringAlert
   * @param {any} params -
   * @returns {Promise<any>} -
   */
    async fetchResolveMonitoringAlert(params: any): Promise<any> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'MonitoringAlert/UpdateAlert',
        { body: params }
      );

      return body;
    }

    /**
     * getPlayerFinancialTransactions
     * @param {PlayerFinancialTransactionParams} params -
     * @returns {Promise<{data: Array<PlayerFinancialTransactionModel>}>} -
     */
    async getPlayerFinancialTransactions(params: PlayerFinancialTransactionParams): Promise<{ data: Array<PlayerFinancialTransactionModel>}> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Transaction/GetPlayerTransaction',
        { query_params: { playerId } }
      );
      return {
        // $FlowFixMe
        Data: body.Data
      };
    }

    /**
     * getPlayerFinancialBalances
     * @param {PlayerFinancialBalanceParams} params -
     * @returns {Promise<{data: Array<PlayerFinancialBalanceModel>}>} -
     */
    async getPlayerFinancialBalances(params: PlayerFinancialBalanceParams): Promise<{ data: Array<PlayerFinancialBalanceModel>}> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Account/GetBalances',
        { query_params: { playerId }, abortController: this.setAbortController('getPlayerFinancialBalances') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPlayerSessionIps
     * @param {PlayerSessionIpsParams} params -
     * @returns {Promise<{data: {Models: ?Array<PlayerSessionIpsModel>} }>} -
     */
    async getPlayerSessionIps(params: PlayerSessionIpsParams): Promise<{ response: {Models: ?Array<PlayerSessionIpsModel>} }> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Session/GetPlayerSessionIps',
        { query_params: { playerId } }
      );
      return { data: body.Data };
    }

    /**
     * getPlayerAllSessionIps
     * @param {PlayerAllSessionIpsParams} params -
     * @returns {Promise<{data: {Models: ?Array<PlayerAllSessionIpsModel>, TotalCount: number} }>} -
     */
    async getPlayerAllSessionIps(params: PlayerAllSessionIpsParams): Promise<{ response: {Models: ?Array<PlayerAllSessionIpsModel>} }> {
      const { playerId, loginIp } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Session/Sessions',
        { query_params: { playerId, loginIp } }
      );
      return { data: body.Data };
    }

    /**
     * getAllPlayersSessionIp
     * @param {AllPlayersSessionIpParams} params -
     * @returns {Promise<{data: {Models: ?Array<PlayerAllSessionIpsModel>, TotalCount: number} }>} -
     */
    async getAllPlayersSessionIp(params: AllPlayersSessionIpParams): Promise<{ response: {Models: ?Array<AllPlayersSessionIpModel>} }> {
      const { loginIp } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Session/SessionsByLoginIp',
        { query_params: { loginIp } }
      );
      return { data: body.Data };
    }

    /**
     * getPlayerFingerprint
     * @param {PlayerFingerprintParams} params -
     * @returns {Promise<{data: {Models: ?Array<PlayerFingerprintsModel>} }>} -
     */
    async getPlayerFingerprint(params: PlayerFingerprintParams): Promise<{ response: {Models: ?Array<PlayerFingerprintsModel>} }> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'ClientLoginDevice/GetClientLoginDevices',
        { query_params: { playerId } }
      );
      return { data: body.Data };
    }

    /**
     * setPlayerComment
     * @param {PlayerAddCommentParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async setPlayerComment(params: PlayerAddCommentParams): Promise<{ data: Object }> {
      const { playerId, comment, source } = params;
      const { body } = await this[`_${source === 'SrmtPlayerPage' || source === 'LCRMTPlayerPage' ? 'rmt' : 'aml'}ApiService`].makeAPIPostRequest(
        'Comment/AddComment',
        { body: { playerId, comment, source } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getGameStyleCategories
     * @param {GameStyleCategoryParams} params -
     * @returns {Promise<{data: Array<GameStyleCategoryModel> }>} -
     */
    async getGameStyleCategories(params: GameStyleCategoryParams): Promise<{ data: Array<GameStyleCategoryModel> }> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerCurrentGameStyles',
        { query_params: { playerId }, abortController: this.setAbortController('getGameStyleCategories') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPartnerGameStyleCategories
     * @param {PartnerGameStyleCategoryParams} params -
     * @returns {Promise<{data: Array<GameStyleCategoryModel> }>} -
     */
    async getPartnerGameStyleCategories(params: PartnerGameStyleCategoryParams): Promise<{ data: Array<GameStyleCategoryModel> }> {
      const { partnerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'GameStyle/GetGameStyleTreeForPartner',
        { query_params: { partnerId }, abortController: this.setAbortController('getGameStyleCategories') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * gameStyleCategoryUpdate
     * @param {GameStyleCategoryUpdateParams} params -
     * @returns {Promise<{data: GameStyleCategoryUpdateModel }>} -
     */
    async gameStyleCategoryUpdate(params: GameStyleCategoryUpdateParams): Promise< { data: GameStyleCategoryUpdateModel }> {
      const { playerId, categoryId, subId, action, comment } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        `GameStyle/${action}`,
        { body: {
          playerId,
          categoryId,
          subId,
          comment,
          isSelected: action !== ACTION_TYPES.DELETE,
          sportId: null,
          regionId: null,
          competitionId: null
        } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * gameStyleCategoriesUpdateBulk
     * @param {GameStyleCategoryBulkUpdateParams} params -
     * @returns {Promise<{data: GameStyleCategoryBulkUpdateModel }>} -
     */
    async gameStyleCategoriesUpdateBulk(params: GameStyleCategoryBulkUpdateParams): Promise<{ data: GameStyleCategoryBulkUpdateModel }> {
      const { playerId, toAdd, toUpdate, toDelete, comment } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'GameStyle/MergeBulk',
        { body: {
          playerId,
          comment,
          AddGameStyle: toAdd,
          UpdateGameStyle: toUpdate,
          DeleteGameStyle: toDelete
        } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPlayerKPITree
     * @param {PlayerKPITreeParams} params -
     * @returns {Promise<{data: PlayerKPITreeModel}>} -
     */
    async getPlayerKPITree(params: PlayerKPITreeParams): Promise<{ data: Array<PlayerKPITreeModel> }> {
      const { playerId, level, sportId, regionId, withParents } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'PlayerKPI/GetPlayerKPITree',
        { query_params: { playerId, level, sportId, regionId, withParents }, abortController: this.setAbortController('getPlayerKPITree') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * editAdditionalCategory
     * @param {{playerId: number, categoryId: number}} params -
     * @returns {Promise<{data: any }>} -
     */
    async editAdditionalCategory(params: {playerId: number, categoryId: number, isActive: boolean}): Object {
      const { playerId, categoryId, isActive } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LicenseRules/EditAdditional',
        { body: { playerId, categoryId, isActive } }
      );
      return {
        data: body.Data
      };
    }

    /**
     * additionalCategoriesBulkMerge
     * @param {AdditionalCategoriesBulkMergeParams} params -
     * @returns {Promise<{data: AdditionalCategoriesBulkMergeModel }>} -
     */
    async additionalCategoriesBulkMerge(params: AdditionalCategoriesBulkMergeParams): Promise<{ data: AdditionalCategoriesBulkMergeModel } > {
      const { playerId, changeAdditionals, isCalcDisabled, comment } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Additional/MergeBulk',
        { body: {
          playerId,
          changeAdditionals,
          isCalcDisabled,
          comment
        } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPlayerAlerts
     * @param {PlayerAlertParams} params -
     * @returns {Promise<{data: {Models: ?Array<PlayerAlertModel>, TotalCount: number} }>} -
     */
    async getPlayerAlerts(params: PlayerAlertParams): Promise<{ response: { Models: ?Array<PlayerAlertModel>, TotalCount: number } }> {
      // eslint-disable-next-line no-unused-vars
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Alertmonitor/GetByPlayerId',
        { query_params: { playerId }, abortController: this.setAbortController('getPlayerAlerts') }
      );

      return { data: body.Data };
    }

    /**
     * updateBulkAlerts
     * @param {AlertsBulkReviewParams} params -
     * @returns {Promise<{data: {Models: ?Object} }>} -
     */
    async updateBulkAlerts(params: AlertsBulkReviewParams): Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Alertmonitor/BulkyReview',
        { body: params }
      );

      return { data: body.Data };
    }

    /**
     * getProcessedAlerts
     * @param {ProcessedAlertsParams} params -
     * @returns {Promise<{data: {Models: ?Array<ProcessedAlertsModel>, TotalCount: number} }>} -
     */
    async getProcessedAlerts(params: ProcessedAlertsParams): Promise<{
        response: { Models: ?Array<ProcessedAlertsModel>, TotalCount: number } }> {
      const { playerId, count, start } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'AlertMonitor/GetLog',
        { query_params: { playerId, count, start, statuses: [2, 3] }, abortController: this.setAbortController('getProcessedAlerts') }
      );

      return body;
    }

    /**
     * getPlayerRestrictions
     * @param {PlayerRestrictionsParams} params -
     * @returns {Promise<{data: PlayerRestrictionsModel}>} -
     */
    async getPlayerRestrictions(params: PlayerRestrictionsParams): Promise<{ data: PlayerRestrictionsModel }> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetRestriction',
        { query_params: { playerId }, abortController: this.setAbortController('getPlayerRestrictions') }
      );
      return {
        data: body.Data
      };
    }

    /**
     * editPlayerRestrictions
     * @param {EditPlayerRestrictionsParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async editPlayerRestrictions(params: EditPlayerRestrictionsParams): Promise<{ data: PlayerRestrictionsModel }> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Block/Update',
        { body: params }
      );
      return {
        data: body.Data
      };
    }

    /**
     * playerForceCalculation
     * @param {PlayerForceCalculationParams} params -
     * @returns {Promise<{data: PlayerForceCalculationModel}>} -
     */
    async playerForceCalculation(params: PlayerForceCalculationParams): Promise<{data: PlayerForceCalculationModel}> {
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Calculation/ClientForceCalculation',
        { query_params: { clientId: playerId } }
      );
      return {
        data: body.Data
      };
    }

    /**
     * playerChangeCalculationState
     * @param {any} params -
     * @returns {Promise<{data: any}>} -
     */
    async playerChangeCalculationState(params: any): Promise<{data: any}> {
      const { state, playerId } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Player/ChanegeCalculationState',
        { body: { State: state, PlayerId: playerId } }
      );
      return {
        data: body.Data
      };
    }

    /**
   * fetchSetMonitoringAlert
   * @param {Object} filters -
   * @returns {Promise<any>} -
   */
    async fetchSetMonitoringAlert({ filters }: Object): Promise<{data: any}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'MonitoringAlert/GetProcessingAlert',
        { query_params: { ...filters } }
      );
      return {
        data: body.Data
      };
    }

    /**
   * fetchCheckedManually
   * @param {any} params -
   * @returns {Promise<any>} -
   */
    async fetchCheckedManually({ playerId, source }: any) {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'ClientCheck/AddClientCheckHistory',
        { body: { playerId, source } }
      );
      return {
        data: body.Data
      };
    }

    /**
     * fetchAgpComments
     * @param {playerId} params -
     * @returns {Promise<any>} -
     */
    async fetchAgpComments(params:any) {
      // eslint-disable-next-line no-unused-vars
      const { playerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Comment/GetClientNotes',
        { query_params: { playerId }, abortController: this.setAbortController('fetchAgpComments') }
      );

      return { data: body };
    }

    /**
     * fetchAlertPlayersBets
     * @param {AlertPlayersBetsParams} params -
     * @returns {Promise<any>} -
     */
    async fetchAlertPlayersBets(params:AlertPlayersBetsParams) {
      // eslint-disable-next-line no-unused-vars
      const { alertId, alertType, start, count, passTotalCount } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'FraudAlert/GetAlertPlayers',
        { query_params: { alertId, alertType, start, count, passTotalCount }, abortController: this.setAbortController('alertPlayersBets') }
      );

      return { Data: body.Data };
    }

    /**
     * cancelPlayerSDKCalls
     * @param {String} params -
     * @returns {Promise} -
     */
    cancelPlayerSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new PlayersSDK();
