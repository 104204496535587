/**
 * @flow
 * */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';

import type { UserSettingsParams, UserSettingsModel } from './types';

/**
 * @class UserSettingsSDK
 */
class UserSettingsSDK {
    _rmtApiService: NetworkService;

    constructor() {
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * getUserSettings
     * @param {UserSettingsParams} params -
     * @returns {Promise<{data: UserSettingsModel}>} -
     */
    async getUserSettings(params: UserSettingsParams): Promise<{data: UserSettingsModel}> {
      const { name } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'user/GetTableSetting',
        { query_params: { Schema: name } }
      );
      return {
        data: body.Data
      };
    }

    /**
     * updateUserSettings
     * @param {UserSettingsParams} params -
     * @returns {Promise<{data: UserSettingsModel}>} -
     */
    async updateUserSettings(params: UserSettingsParams): Promise<{data: UserSettingsModel}> {
      const { name, scheme } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'user/updateTableSetting',
        { body: { name, scheme } }
      );

      return {
        data: body.Data
      };
    }
}

export default new UserSettingsSDK();
