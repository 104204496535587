import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createReportsSymbol = createSymbol('CHI_SQUARE_REPORTS');
const createReportsInternalSymbol = createInternalSymbol('CHI_SQUARE_REPORTS');

export default {
  // reports
  FETCH_CHI_SQUARE_REPORTS: createReportsSymbol('FETCH_CHI_SQUARE_REPORTS'),
  SET_FETCH_CHI_SQUARE_REPORTS_SUCCESS: createReportsSymbol('SET_FETCH_CHI_SQUARE_REPORTS_SUCCESS'),
  SET_FETCH_CHI_SQUARE_REPORTS_FAILURE: createReportsSymbol('SET_FETCH_CHI_SQUARE_REPORTS_FAILURE'),
  CLEAR_CHI_SQUARE_REPORTS_STATE: createReportsInternalSymbol('CLEAR_CHI_SQUARE_REPORTS_STATE'),

  // lifeTime reports
  FETCH_LIFE_TIME_CHI_SQUARE_REPORTS: createReportsSymbol('FETCH_LIFE_TIME_CHI_SQUARE_REPORTS'),
  SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_SUCCESS: createReportsSymbol('SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_SUCCESS'),
  SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_FAILURE: createReportsSymbol('SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_FAILURE'),
  CLEAR_LIFE_TIME_CHI_SQUARE_REPORTS_STATE: createReportsInternalSymbol('CLEAR_LIFE_TIME_CHI_SQUARE_REPORTS_STATE'),

  // player bets report
  FETCH_PLAYERS_BETS_REPORT: createReportsSymbol('FETCH_PLAYERS_BETS_REPORT'),
  SET_FETCH_FETCH_PLAYERS_BETS_REPORT_SUCCESS: createReportsSymbol('SET_FETCH_FETCH_PLAYERS_BETS_REPORT_SUCCESS'),
  SET_FETCH_PLAYERS_BETS_REPORT_FAILURE: createReportsSymbol('SET_FETCH_PLAYERS_BETS_REPORT_FAILURE'),
  CLEAR_PLAYERS_BETS_REPORT_STATE: createReportsInternalSymbol('CLEAR_PLAYERS_BETS_REPORT_STATE'),

  // player rounds report
  FETCH_PLAYERS_ROUNDS_REPORT: createReportsSymbol('FETCH_PLAYERS_ROUNDS_REPORT'),
  SET_FETCH_FETCH_PLAYERS_ROUNDS_REPORT_SUCCESS: createReportsSymbol('SET_FETCH_FETCH_PLAYERS_ROUNDS_REPORT_SUCCESS'),
  SET_FETCH_PLAYERS_ROUNDS_REPORT_FAILURE: createReportsSymbol('SET_FETCH_PLAYERS_ROUNDS_REPORT_FAILURE'),
  CLEAR_PLAYERS_ROUNDS_REPORT_STATE: createReportsInternalSymbol('CLEAR_PLAYERS_ROUNDS_REPORT_STATE'),

  // partners info report
  FETCH_PARTNERS_INFO_REPORT: createReportsSymbol('FETCH_PARTNERS_INFO_REPORT'),
  SET_FETCH_PARTNERS_INFO_REPORT_SUCCESS: createReportsSymbol('SET_FETCH_PARTNERS_INFO_REPORT_SUCCESS'),
  SET_FETCH_PARTNERS_INFO_REPORT_FAILURE: createReportsSymbol('SET_FETCH_PARTNERS_INFO_REPORT_FAILURE'),
  CLEAR_PARTNERS_INFO_REPORT_STATE: createReportsInternalSymbol('CLEAR_PARTNERS_INFO_REPORT_STATE'),

  CANCEL_REPORT_SDK_CALL: createReportsSymbol('CANCEL_REPORT_SDK_CALL')
};
