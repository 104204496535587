import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createSettingsSymbol = createSymbol('SETTINGS');
const createSettingsInternalSymbol = createInternalSymbol('SETTINGS');

export default {

  // notification settings
  FETCH_SRMT_NOTIFICATION_SETTINGS: createSettingsSymbol('FETCH_SRMT_NOTIFICATION_SETTINGS'),
  SET_FETCH_SRMT_NOTIFICATION_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_SRMT_NOTIFICATION_SETTINGS_SUCCESS'),
  SET_FETCH_SRMT_NOTIFICATION_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_SRMT_NOTIFICATION_SETTINGS_FAILURE'),
  CLEAR_SRMT_NOTIFICATION_SETTINGS_STATE: createSettingsInternalSymbol('CLEAR_SRMT_NOTIFICATION_SETTINGS_STATE'),

  // save notification settings
  SAVE_SRMT_NOTIFICATION_SETTINGS: createSettingsSymbol('SAVE_SRMT_NOTIFICATION_SETTINGS'),
  SET_SAVE_SRMT_NOTIFICATION_SETTINGS_SUCCESS: createSettingsSymbol('SET_SAVE_SRMT_NOTIFICATION_SETTINGS_SUCCESS'),
  SET_SAVE_SRMT_NOTIFICATION_SETTINGS_FAILURE: createSettingsSymbol('SET_SAVE_SRMT_NOTIFICATION_SETTINGS_FAILURE'),

  // delete notification settings
  DELETE_SRMT_NOTIFICATION_SETTINGS: createSettingsSymbol('DELETE_SRMT_NOTIFICATION_SETTINGS'),
  SET_DELETE_SRMT_NOTIFICATION_SETTINGS_SUCCESS: createSettingsSymbol('SET_DELETE_SRMT_NOTIFICATION_SETTINGS_SUCCESS'),
  SET_DELETE_SRMT_NOTIFICATION_SETTINGS_FAILURE: createSettingsSymbol('SET_DELETE_SRMT_NOTIFICATION_SETTINGS_FAILURE'),

  // cancel call
  CANCEL_SRMT_NOTIFICATION_SETTINGS_CALL: createSettingsSymbol('CANCEL_SRMT_NOTIFICATION_SETTINGS_CALL')
};
