/**
 * @flow
 * */
import { initialSectionState, initialStatisticState } from 'store/reducers/initialStates';
import PLAYER_ACTION_TYPES from 'modules/LCRMT/Players/store/actions/player/types';
import expandReducerChildren from 'modules/LCRMT/utils/expandReducerChildren';
import { statisticTypeEnumById } from 'modules/LCRMT/utils/enums';
import type { PlayerState } from 'modules/LCRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

const initialState = {
  activityHistory: {
    activitySession: initialSectionState(),
    activitySessionView: initialSectionState(),
    activitySessionStatistics: initialStatisticState()
  }
};

const actionManager = {
  // Activity History
  [PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION]: (state: PlayerState) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySession: {
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySession: {
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySession: {
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ACTIVITY_SESSION_STATE]: (state: PlayerState) => ({
    ...state,
    activityHistory: {
      activitySession: initialSectionState()
    }
  }),

  // Activity History View
  [PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION_VIEW]: (state: PlayerState) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionView: {
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_VIEW_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionView: {
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_VIEW_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionView: {
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ACTIVITY_SESSION_VIEW_STATE]: (state: PlayerState) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionView: initialSectionState()
    }
  }),

  // Activity History View expand
  [PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION_VIEW_EXPAND]: (state:PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionView: {
        ...state.activityHistory.activitySessionView,
        data: expandReducerChildren({
          allData: state.activityHistory.activitySessionView.data,
          itemIndex: payload.index,
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        })
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_SUCCESS]: (state:PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionView: {
        ...state.activityHistory.activitySessionView,
        data: expandReducerChildren({
          allData: state.activityHistory.activitySessionView.data,
          itemIndex: payload.index,
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        })
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_FAILURE]: (state:PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionView: {
        ...state.activityHistory.activitySessionView,
        data: expandReducerChildren({
          allData: state.activityHistory.activitySessionView.data,
          itemIndex: payload.index,
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        })
      }
    }
  }),

  // Activity History View
  [PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION_STATISTICS]: (state: PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionStatistics: {
        ...state.activityHistory.activitySessionStatistics,
        [statisticTypeEnumById[payload.statisticType]]: {
          ...state.activityHistory.activitySessionStatistics[statisticTypeEnumById[payload.statisticType]],
          status: ACTION_STATUSES.PENDING
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_STATISTICS_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionStatistics: {
        ...state.activityHistory.activitySessionStatistics,
        [statisticTypeEnumById[payload.statisticType]]: {
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ACTIVITY_SESSION_STATISTICS_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionStatistics: {
        ...state.activityHistory.activitySessionStatistics,
        [statisticTypeEnumById[payload.statisticType]]: {
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ACTIVITY_SESSION_STATISTICS_STATE]: (state: PlayerState) => ({
    ...state,
    activityHistory: {
      ...state.activityHistory,
      activitySessionStatistics: initialStatisticState()
    }
  })

};

export default {
  initialState,
  actionManager
};
