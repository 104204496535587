/**
 * @flow
 * */
import { combineReducers } from 'redux';
import COMMON_ROUTES from './views/routes';
import aml from './store/reducers';

import amlController from './core/controllers';

const getProjectState = (state: Object) => state.AML;

const routes = [
  ...COMMON_ROUTES
];

const controllers = [
  amlController()
];

const reducers = combineReducers(aml);

export {
  getProjectState,
  controllers,
  reducers,
  routes
};
