import type { Action } from 'lib/core/flow';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import expandReducerChildren from 'modules/SRMT/AlertMonitor/utils/expandReducerChildren';
import reducerChildren from 'modules/SRMT/AlertMonitor/utils/reducerChildren';
import type { MonitorState } from 'modules/SRMT/AlertMonitor/types';

import FraudMonitorFactory, { fraudMonitorInitialState } from './Factories/FraudMonitorFactory';
import AM_ACTION_TYPES from '../actions/types';

const initialState = (): MonitorState => FraudMonitorFactory.createInitialFraudMonitor();

const actionManager = {
  // tab data
  [AM_ACTION_TYPES.FETCH_MONITOR_TAB]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_MONITOR_TAB_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        error: null,
        data: payload.data,
        totalCount: !payload.passTotalCount ? payload.totalCount : state[payload.tabKey].alerts.totalCount,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_MONITOR_TAB_FAILURE]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        data: null,
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_MONITOR_TAB_STATE]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: fraudMonitorInitialState
  }),
  [AM_ACTION_TYPES.REMOVE_ALERT_ROW]: (state: MonitorState, payload) => {
    const alertsData = state[payload.tabKey].alerts;
    if (alertsData.data && payload.id && payload.id.length) {
      alertsData.data = alertsData.data.filter((item) => {
        if (payload.id.includes(item.AlertId) || payload.id.includes(item.Id)) {
          alertsData.totalCount -= 1;
          return false;
        }
        return true;
      });
    }
    return {
      ...state,
      [payload.tabKey]: {
        ...state[payload.tabKey],
        alerts: state[payload.tabKey].alerts ? alertsData : null
      }
    };
  },
  [AM_ACTION_TYPES.CHANGE_ALERT_ROW]: (state: MonitorState, payload) => {
    const alertsData = state[payload.tabKey].alerts.data;
    if (alertsData && payload.id && payload.id.length) {
      alertsData.forEach((item) => {
        if (payload.id.includes(item.AlertId) || payload.id.includes(item.Id)) {
          // $FlowFixMe
          // eslint-disable-next-line no-param-reassign
          item.Status = payload.status.Name;
          // $FlowFixMe
          // eslint-disable-next-line no-param-reassign
          item.StatusId = payload.status.Id;
          // $FlowFixMe
          // eslint-disable-next-line no-param-reassign
          item.UserName = payload.UserName;
          // $FlowFixMe
          // eslint-disable-next-line no-param-reassign
          item.UserId = payload.UserId;
          // $FlowFixMe
          // eslint-disable-next-line no-param-reassign
          item.Created = payload.Created;
        }
      });
    }
    return {
      ...state,
      [payload.tabKey]: {
        ...state[payload.tabKey],
        alerts: {
          ...state[payload.tabKey].alerts,
          data: alertsData
        }
      }
    };
  },

  // review data
  [AM_ACTION_TYPES.FETCH_REVIEW_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null,
        edit: {
          status: null,
          error: null
        },
        history: {
          data: null,
          status: null,
          error: null
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_REVIEW_DATA_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        data: payload.data,
        error: null,
        status: ACTION_STATUSES.SUCCEED,
        edit: {
          status: null,
          error: null
        },
        history: {
          data: null,
          status: null,
          error: null
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_REVIEW_DATA_FAILURE]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        data: null,
        error: payload.error,
        status: ACTION_STATUSES.FAILED,
        edit: {
          status: null,
          error: null
        },
        history: {
          data: null,
          status: null,
          error: null
        }
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_REVIEW_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        data: null,
        error: null,
        status: null,
        edit: {
          status: null,
          error: null
        },
        history: {
          data: null,
          status: null,
          error: null
        }
      }
    }
  }),

  // edit review data
  [AM_ACTION_TYPES.EDIT_REVIEW_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: {
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_EDIT_REVIEW_DATA_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: {
          data: payload.response,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_EDIT_REVIEW_DATA_FAILURE]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: {
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_EDIT_REVIEW_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        edit: {
          data: null,
          status: null,
          error: null
        }
      }
    }
  }),

  // review history
  [AM_ACTION_TYPES.FETCH_HISTORY_REVIEW_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_HISTORY_REVIEW_DATA_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_HISTORY_REVIEW_DATA_FAILURE]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [AM_ACTION_TYPES.CLEAR_HISTORY_REVIEW_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          data: null,
          status: null,
          error: null
        }
      }
    }
  }),

  // review children history
  [AM_ACTION_TYPES.FETCH_HISTORY_REVIEW_CHILDREN_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          ...state[payload.tabKey].reviewing_alert.history,
          data: reducerChildren(state[payload.tabKey].reviewing_alert.history.data, payload, null, ACTION_STATUSES.PENDING, null)
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_HISTORY_REVIEW_CHILDREN_DATA_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          ...state[payload.tabKey].reviewing_alert.history,
          data: reducerChildren(state[payload.tabKey].reviewing_alert.history.data, payload, payload.data, ACTION_STATUSES.SUCCEED, null)
        }
      }
    }
  }),
  [AM_ACTION_TYPES.SET_HISTORY_REVIEW_CHILDREN_DATA_FAILURE]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      reviewing_alert: {
        ...state[payload.tabKey].reviewing_alert,
        history: {
          ...state[payload.tabKey].reviewing_alert.history,
          data: reducerChildren(state[payload.tabKey].reviewing_alert.history.data, payload, null, ACTION_STATUSES.FAILED, payload.error)
        }

      }
    }
  }),

  // expand row children
  [AM_ACTION_TYPES.FETCH_EXPAND_ROW_CHILDREN_DATA]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren(state[payload.tabKey].alerts.data, payload, null, ACTION_STATUSES.PENDING, null)
      }
    }
  }),
  [AM_ACTION_TYPES.SET_EXPAND_ROW_CHILDREN_DATA_SUCCESS]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren(state[payload.tabKey].alerts.data, payload, payload.data, ACTION_STATUSES.SUCCEED, null)
      }
    }
  }),
  [AM_ACTION_TYPES.SET_EXPAND_ROW_CHILDREN_DATA_FAILURE]: (state: MonitorState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren(state[payload.tabKey].alerts.data, payload, null, ACTION_STATUSES.FAILED, payload.error)
      }
    }
  })
};

export default (state = initialState(), action: Action) => {
  if (!actionManager[action.type]) return state;

  return actionManager[action.type](state, action.payload);
};
