import type { Action } from 'lib/core/flow/types';
import USER_SETTINGS_ACTION_TYPES from './types';

type ActionType = {
  name?: string,
  scheme?: string,
  error?: string,
  data?: any
}

export const setUserSettings = ({ name, scheme }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.SET_USER_SETTINGS,
  payload: { name, scheme }
});
export const setUserSettingsSuccess = ({ data, name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.SET_USER_SETTINGS_SUCCESS,
  payload: { data, name }
});
export const updateUserSettingsSuccess = ({ data, name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.UPDATE_USER_SETTINGS_SUCCESS,
  payload: { data, name }
});
export const changeUserSettingsSuccess = ({ data, name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.CHANGE_USER_SETTINGS_FAILURE,
  payload: { data, name }
});

export const setUserSettingsFailure = ({ error, name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.SET_USER_SETTINGS_FAILURE,
  payload: { error, name }
});

export const clearUserSettingsState = ({ name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.CLEAR_USER_SETTINGS_STATE,
  payload: { name }
});

export const fetchUserSettings = ({ name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.FETCH_USER_SETTINGS,
  payload: { name }
});
export const setFetchUserSettingsSuccess = ({ data, name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.SET_FETCH_USER_SETTINGS_SUCCESS,
  payload: { data, name }
});
export const setFetchUserSettingsFailure = ({ error, name }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.SET_FETCH_USER_SETTINGS_FAILURE,
  payload: { error, name }
});

export const setTableSettingsCache = ({ data }: ActionType): Action => ({
  type: USER_SETTINGS_ACTION_TYPES.SET_TABLE_SETTINGS_CACHE,
  payload: { data }
});
