/**
 * @flow
 * */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';
import type {
  JwtChangePasswordParams,
  AddClientImageParams,
  UpdateSettingsParams,
  UpdateSettingsModel,
  GetSettingsModelL,
  JwtLoginDataModel,
  JwtLoginParams,
  TwoStepParams
} from './types';

/**
 * @class AuthSDK
 */
class AGPSDK {
  _agpApiService: NetworkService;

  constructor() {
    this._agpApiService = new NetworkService(config.agpApi);
  }

  /**
   * checkAuthentication
   * @returns {Promise<{ user: *}>} -
   */
  async checkAuthentication() {
    const response = await this._agpApiService.makeAPIGetRequest(
      'en/Account/CheckAuthentication',
      { credentials: 'include' }
    );
    return response;
  }

  /**
   * jwt login
   * @param {String} userName -
   * @param {String} password -
   * @returns {Promise<any>} -
   */
  async jwtLogin({ userName, password, device }: JwtLoginParams):Promise<{data:JwtLoginDataModel}> {
    const response = await this._agpApiService.makeAPIPostRequest(
      'en/Account/login',
      { body: { userName, password, device } }
    );
    return response;
  }

  /**
   * jwt change Password
   * @param {String} userId -
   * @param {String} confirmPassword -
   * @param {String} newPassword -
   * @param {String} currentPassword -
   * @returns {Promise<any>} -
   */
  async jwtChangePassword({ userId, confirmPassword, newPassword, currentPassword }: JwtChangePasswordParams): Promise<{data:Object}> {
    const { body } = await this._agpApiService.makeAPIPostRequest(
      'en/Account/ChangePassword',
      { body: { userId, confirmPassword, newPassword, currentPassword } }
    );
    return { data: body };
  }

  /**
   * twoStep
   * @param {String} trustedPeriod -
   * @param {String} fingerprint -
   * @param {String} isDeviceTrusted -
   * @param {String} token -
   * @returns {Promise<any>} -
   */
  async twoStep({ fingerprint, isDeviceTrusted, token, trustedPeriod }: TwoStepParams): Promise<{data:Object}> {
    const { body } = await this._agpApiService.makeAPIPostRequest(
      'en/Account/ApplyTwoFactorToken',
      { body: { fingerprint, isDeviceTrusted, token, trustedPeriod } }
    );
    return { data: body };
  }

  /**
   * logOut
   * @returns {Promise<any>} -
   */
  async logOut() {
    await this._agpApiService.makeAPIPostRequest(
      'en/Account/Logout'
    );
  }

  /**
   * getSetting
   * @returns {Promise<any>} -
   */
  async getSetting(): Promise<{body:GetSettingsModelL}> {
    const response = await this._agpApiService.makeAPIGetRequest(
      'en/setting/GetSetting',
      { caching: true }
    );
    return { body: response.body.Data };
  }

  /**
   * updateSetting
   * @param {object} params -
   * @returns {Promise<any>} -
   */
  async updateSetting(params: UpdateSettingsParams):Promise<UpdateSettingsModel> {
    const { body } = await this._agpApiService.makeAPIPostRequest(
      'en/setting/saveSetting',
      { body: params }
    );
    return body;
  }

  /**
   * AddClientImage
   * @param {object} params -
   * @returns {Promise<any>} -
   */
  async AddClientImage(params: AddClientImageParams):Promise<{data:Object}> {
    const { body } = await this._agpApiService.makeAPIPostRequest(
      'null/Client/AddClientImage',
      { body: params }
    );
    return body;
  }

  /**
   * GetClientUploadedImages
   * @param {object} params -
   * @returns {Promise<any>} -
   */
  async GetClientUploadedImages(params: AddClientImageParams):Promise<{data:Object}> {
    const { clientId } = params;
    const { body } = await this._agpApiService.makeAPIGetRequest(
      'null/Client/GetClientUploadedImages',
      { query_params: { clientId } }
    );
    return { data: body.Data };
  }

  /**
   * deleteClientImage
   * @param {object} params -
   * @returns {Promise<any>} -
   */
  async DeleteClientFile(params: any):Promise<{data:Object}> {
    const { body } = await this._agpApiService.makeAPIPostRequest(
      'en/reference/UpdateUploadedImages',
      { body: [params] }
    );
    return body;
  }
}

export default new AGPSDK();
