import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

// Pages
const FraudMonitor = React.lazy(() => import('./views/pages/FraudMonitor'));
const InfoMonitor = React.lazy(() => import('./views/pages/InfoMonitor'));

const subUrl = settings.PRODUCTS.RMT.path;

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>
};

const modulePath = `${subUrl}/alert-monitor`;

const ROUTES: Array<Route> = [
  { path: `/${modulePath}/fraud-monitor/`, name: 'Fraud Monitor', exact: true, page: () => <Redirect to={`/${modulePath}/fraud-monitor/linkedAccount`} /> },
  { path: `/${modulePath}/fraud-monitor/:tab`, name: 'Tabs', exact: true, page: FraudMonitor },
  { path: `/${modulePath}/info-monitor`, name: 'Info Monitor', exact: true, page: () => <Redirect to={`/${modulePath}/info-monitor/financial`} /> },
  { path: `/${modulePath}/info-monitor/:tab`, name: 'Tabs', exact: true, page: InfoMonitor }
];

export default ROUTES;
