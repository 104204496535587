/**
 * @flow
 * */

import { takeLatest, put } from 'redux-saga/effects';
import ACTION_TYPES from 'store/actions/permission/types';
import { setPermissionSuccess, setPermissionFailure } from 'store/actions/permission/actions';

import permissionSDK from '../sdk/permission/PermissionSDK';

class PermissionController {
  static* fetchUserPermission({ payload }) {
    const { userId } = payload;
    try {
      const { data } = yield permissionSDK.getUserPermission({ userId });
      yield put(setPermissionSuccess({ data }));
    } catch (err) {
      yield put(setPermissionFailure({ error: err.message }));
    }
  }

  static* appSettingsWatcher<T>(): Iterable<T> {
    yield takeLatest(ACTION_TYPES.FETCH_PERMISSION, PermissionController.fetchUserPermission);
  }
}

export default PermissionController.appSettingsWatcher;
