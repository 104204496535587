import { MonitorState, MonitorTabState } from 'modules/SRMT/AlertMonitor/types';
import { partnerLevelAlerts } from 'modules/SRMT/AlertMonitor/settings';
import AlertMonitorFactory from './AlertMonitorFactory';

class PartnerLevelAlertsFactory extends AlertMonitorFactory {
  static createInitialInfoMonitor(): MonitorState {
    const tabs: MonitorTabState = Object.keys(partnerLevelAlerts.tabs).map(
      name => PartnerLevelAlertsFactory.createInitialMonitorTab(name)
    );

    return PartnerLevelAlertsFactory.createInitialMonitor(partnerLevelAlerts.key, tabs);
  }
}

export default PartnerLevelAlertsFactory;
