import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import { initialSectionState } from 'store/reducers/initialStates';
import type { SettingsState } from 'modules/SRMT/Settings/types';

const initialState = {
  financial: initialSectionState()
};

const normalizer = (arr, key) => {
  const returnedObj = {};
  arr.forEach(item => {
    returnedObj[item[key]] = { data: item, status: null, error: null };
  });
  return returnedObj;
};

const actionManager = {
  [SETTINGS_ACTION_TYPES.FETCH_FINANCIAL_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: {
        ...state.categorySettings.financial,
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_FINANCIAL_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: {
        data: payload.data && normalizer(payload.data, 'OrderNumber'),
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_FINANCIAL_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: {
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_FINANCIAL_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: initialSectionState()
    }
  }),

  // Settings Update
  [SETTINGS_ACTION_TYPES.FETCH_FINANCIAL_UPDATE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: {
        ...state.categorySettings.financial,
        data: {
          ...state.categorySettings.financial.data,
          [payload.params.OrderNumber]: {
            data: state.categorySettings.financial.data[payload.params.OrderNumber].data,
            status: ACTION_STATUSES.PENDING,
            error: null
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_FINANCIAL_UPDATE_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: {
        ...state.categorySettings.financial,
        data: {
          ...state.categorySettings.financial.data,
          [payload.data.OrderNumber]: {
            data: {
              ...state.categorySettings.financial.data[payload.data.OrderNumber].data,
              ...payload.data
            },
            status: ACTION_STATUSES.SUCCEED,
            error: null
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_FINANCIAL_UPDATE_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: {
        ...state.categorySettings.financial,
        data: {
          ...state.categorySettings.financial.data,
          [payload.params.OrderNumber]: {
            ...state.categorySettings.financial.data[payload.params.OrderNumber],
            status: ACTION_STATUSES.FAILED,
            error: payload.error
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_FINANCIAL_UPDATE_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      financial: initialSectionState()
    }
  })
};

export default {
  actionManager,
  initialState
};
