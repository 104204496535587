/**
 * @flow
 * */
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CacheService from 'core/services/storage/CacheService';
import actionStatuses from 'lib/core/actionStatuses';
import { history } from 'config/history';

import { amlBoxesSelector, amlConfigsSelector } from '../store/selectors';
import { getAmlLicenseForRedux, isAmlLicense } from '../utils/amlConfigs';
import { licenseIds } from '../utils/licenseIds';

/**
 * useAmlLicense
 */

const useAmlLicense = () => {
  const location = useLocation();

  const license = useMemo(() => {
    const amlLicense = isAmlLicense();
    if (amlLicense.key) {
      return amlLicense.key;
    }
    return '';
  }, [location]);

  const state = useSelector(amlBoxesSelector);
  const configState = useSelector(amlConfigsSelector);
  const licenseId = useMemo(() => licenseIds[license] && licenseIds[license]?.license, [license]);
  const partnerId = useMemo(() => CacheService.getItem('amlTurnkeyPartnerId'), [location]);

  const configs = useMemo(() => configState?.data && configState?.data[getAmlLicenseForRedux({ license, licenseId, partnerId })] || {}, [configState, licenseId, license, partnerId]);
  const isLoadingConfig = useMemo(() => actionStatuses.isActionStatusPending(configState.status), [configState]);

  const licenseFilterParams = useCallback(({ licenseKey, partnerKey }: any) => {
    if (licenseId) {
      return { [licenseKey]: licenseId };
    }
    const turnkey = CacheService.getItem('amlTurnkeyPartnerId');
    if (turnkey) {
      return { [partnerKey]: turnkey };
    }

    return {};
  }, [license, licenseId]);

  const hasSomeConfig = useCallback((name: Array<string>) => {
    if (typeof name === 'string') {
      return !!configs[name];
    }
    return name.some(item => !!configs[item]);
  }, [configs]);

  const hasAllConfig = useCallback((name: Array<string>) => {
    if (typeof name === 'string') {
      return !!configs[name];
    }
    return name.every(item => !!configs[item]);
  }, [configs]);

  const init = useCallback(() => {
    if (license) {
      if (!licenseIds[license]) {
        history.push('/404');
      }

      if (state.data && state.data.length) {
        const allLicenses = state.data.map(i => i.Type.toLowerCase());
        const existLicense = allLicenses.find(element => element === license);
        if (!existLicense) {
          history.push('/404');
        }
      } else {
        history.push('/404');
      }
    }
  }, [license, state, history]);

  const hasOne = useMemo(() => {
    if (state.data && state.data.length) {
      if (state.data.length === 1) {
        const first = state.data[0];
        if (first.Type === 'Turnkey') {
          if (first.Partners.length === 1) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }, [state]);

  useEffect(init, [license]);

  return {
    licenseFilterParams,
    isLoadingConfig,
    hasSomeConfig,
    hasAllConfig,
    licenseId,
    partnerId,
    hasOne,
    license,
    configs
  };
};

export default useAmlLicense;
