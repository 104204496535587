/**
 * @flow
 * */
import createSymbol from 'lib/core/createSymbol';

const createGeneralSymbol = createSymbol('GENERAL_ACTION');

export default {
  FETCH_PLAYER_GLOBAL_INFO: createGeneralSymbol('FETCH_PLAYER_GLOBAL_INFO'),
  SET_FETCH_PLAYER_GLOBAL_INFO_SUCCESS: createGeneralSymbol('SET_FETCH_PLAYER_GLOBAL_INFO_SUCCESS'),
  SET_FETCH_PLAYER_GLOBAL_INFO_FAILURE: createGeneralSymbol('SET_FETCH_PLAYER_GLOBAL_INFO_FAILURE'),
  UPDATE_PLAYER_GLOBAL_INFO: createGeneralSymbol('UPDATE_PLAYER_GLOBAL_INFO'),
  EDIT_PLAYER_GLOBAL_INFO: createGeneralSymbol('EDIT_PLAYER_GLOBAL_INFO'),
  CLEAR_PLAYER_GLOBAL_INFO_STATE: createGeneralSymbol('CLEAR_PLAYER_GLOBAL_INFO_STATE'),

  // Player SDK cancel
  CANCEL_PLAYER_GENERAL_SDK_CALL: createGeneralSymbol('CANCEL_PLAYER_GENERAL_SDK_CALL')
};
