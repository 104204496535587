import createSymbol from 'lib/core/createSymbol';

const createSettingsSymbol = createSymbol('USER_SETTINGS');

export default {
  SET_USER_SETTINGS: createSettingsSymbol('SET_USER_SETTINGS'),
  SET_USER_SETTINGS_SUCCESS: createSettingsSymbol('SET_USER_SETTINGS_SUCCESS'),
  UPDATE_USER_SETTINGS_SUCCESS: createSettingsSymbol('UPDATE_USER_SETTINGS_SUCCESS'),
  SET_USER_SETTINGS_FAILURE: createSettingsSymbol('SET_USER_SETTINGS_FAILURE'),
  CLEAR_USER_SETTINGS_STATE: createSettingsSymbol('CLEAR_USER_SETTINGS_STATE'),
  FETCH_USER_SETTINGS: createSettingsSymbol('FETCH_USER_SETTINGS'),
  SET_FETCH_USER_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_USER_SETTINGS_SUCCESS'),
  SET_FETCH_USER_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_USER_SETTINGS_FAILURE'),
  CHANGE_USER_SETTINGS_FAILURE: createSettingsSymbol('CHANGE_USER_SETTINGS_FAILURE'),
  SET_TABLE_SETTINGS_CACHE: createSettingsSymbol('SET_TABLE_SETTINGS_CACHE')
};
