import { generateColor } from 'lib/helpers/generateColor';
import {
  AmlAdditionalRiskCategoryTags,
  AdditionalCategoryTags,
  FinancialCategoryTags,
  GameStyleCategoryTags,
  AssessmentsTags,
  PlayerStatuses,
  OppositeType,
  ProductsType,
  Type
} from './tags';

export const generateNameColor = (tagName) => {
  const { name, color } = {
    ...GameStyleCategoryTags,
    ...FinancialCategoryTags,
    ...AdditionalCategoryTags,
    ...PlayerStatuses,
    ...AmlAdditionalRiskCategoryTags,
    ...AssessmentsTags,
    ...OppositeType,
    ...ProductsType,
    ...Type }[tagName] || {};
  if (name && color) return { name, color };

  return { name: tagName, color: generateColor(tagName) };
};
