import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createDashboardSymbol = createSymbol('LCRMT_SETTINGS');
const createDashboardInternalSymbol = createInternalSymbol('LCRMT_SETTINGS');

export default {

  // notification settings
  FETCH_NOTIFICATION_SETTINGS: createDashboardSymbol('FETCH_NOTIFICATION_SETTINGS'),
  SET_FETCH_NOTIFICATION_SETTINGS_SUCCESS: createDashboardSymbol('SET_FETCH_NOTIFICATION_SETTINGS_SUCCESS'),
  SET_FETCH_NOTIFICATION_SETTINGS_FAILURE: createDashboardSymbol('SET_FETCH_NOTIFICATION_SETTINGS_FAILURE'),
  CLEAR_NOTIFICATION_SETTINGS_STATE: createDashboardInternalSymbol('CLEAR_NOTIFICATION_SETTINGS_STATE'),

  // save notification settings
  SAVE_NOTIFICATION_SETTINGS: createDashboardSymbol('SAVE_NOTIFICATION_SETTINGS'),
  SET_SAVE_NOTIFICATION_SETTINGS_SUCCESS: createDashboardSymbol('SET_SAVE_NOTIFICATION_SETTINGS_SUCCESS'),
  SET_SAVE_NOTIFICATION_SETTINGS_FAILURE: createDashboardSymbol('SET_SAVE_NOTIFICATION_SETTINGS_FAILURE'),

  // delete notification settings
  DELETE_NOTIFICATION_SETTINGS: createDashboardSymbol('DELETE_NOTIFICATION_SETTINGS'),
  SET_DELETE_NOTIFICATION_SETTINGS_SUCCESS: createDashboardSymbol('SET_DELETE_NOTIFICATION_SETTINGS_SUCCESS'),
  SET_DELETE_NOTIFICATION_SETTINGS_FAILURE: createDashboardSymbol('SET_DELETE_NOTIFICATION_SETTINGS_FAILURE'),

  // game table setting filter
  FETCH_GAME_TABLE_SETTINGS_FILTER: createDashboardSymbol('FETCH_GAME_TABLE_SETTINGS_FILTER'),
  SET_FETCH_GAME_TABLE_SETTINGS_FILTER_SUCCESS: createDashboardSymbol('SET_FETCH_GAME_TABLE_SETTINGS_FILTER_SUCCESS'),
  SET_FETCH_GAME_TABLE_SETTINGS_FILTER_FAILURE: createDashboardSymbol('SET_FETCH_GAME_TABLE_SETTINGS_FILTER_FAILURE'),
  CLEAR_GAME_TABLE_SETTINGS_FILTER_STATE: createDashboardInternalSymbol('CLEAR_GAME_TABLE_SETTINGS_FILTER_STATE'),

  // game table setting
  FETCH_GAME_TABLE_SETTINGS: createDashboardSymbol('FETCH_GAME_TABLE_SETTINGS'),
  SET_FETCH_GAME_TABLE_SETTINGS_SUCCESS: createDashboardSymbol('SET_FETCH_GAME_TABLE_SETTINGS_SUCCESS'),
  SET_FETCH_GAME_TABLE_SETTINGS_FAILURE: createDashboardSymbol('SET_FETCH_GAME_TABLE_SETTINGS_FAILURE'),
  CLEAR_GAME_TABLE_SETTINGS_STATE: createDashboardInternalSymbol('CLEAR_GAME_TABLE_SETTINGS_STATE'),

  // save game table setting
  SAVE_GAME_TABLE_SETTINGS: createDashboardSymbol('SAVE_GAME_TABLE_SETTINGS'),
  SET_SAVE_GAME_TABLE_SETTINGS_SUCCESS: createDashboardSymbol('SET_SAVE_GAME_TABLE_SETTINGS_SUCCESS'),
  SET_SAVE_GAME_TABLE_SETTINGS_FAILURE: createDashboardSymbol('SET_SAVE_GAME_TABLE_SETTINGS_FAILURE'),
  CLEAR_SAVE_GAME_TABLE_SETTINGS_STATE: createDashboardInternalSymbol('CLEAR_SAVE_GAME_TABLE_SETTINGS_STATE'),

  // cancel call
  CANCEL_LCRMT_SETTINGS_CALL: createDashboardSymbol('CANCEL_LCRMT_SETTINGS_CALL')
};
