/* eslint-disable camelcase */

import type { FiltersDataModel, IFilterAction, HolderStatusPayload, FilterStatusPayload } from 'types/FilterModels';
import FILTER_ACTION_TYPES from './types';

type ActionType = {
  filterId?: number,
  selectedFilter?: any,
  filters?: any
}

export const setFilterHolderStatus = (filterHolderStatus: HolderStatusPayload): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_FILTER_HOLDER_STATUS,
  payload: filterHolderStatus
});

export const setFilterStatus = ({ isApplyActive, isResetActive, onPressFilterAction }: FilterStatusPayload): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_FILTER_STATUS,
  payload: { isApplyActive, isResetActive, onPressFilterAction }
});
export const clearFilterStatus = (): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_FILTER_STATUS
});

export const setSelectedFilter = ({ filterId, selectedFilter }: ActionType): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_SELECTED_FILTER,
  payload: { filterId, selectedFilter }
});
export const clearSelectedFilter = ({ filterId }: ActionType): IFilterAction => ({
  type: FILTER_ACTION_TYPES.CLEAR_SELECTED_FILTER,
  payload: { filterId }
});

export const fetchFiltersData = ({ filters, filterParams, caching }: ActionType): IFilterAction => ({
  type: FILTER_ACTION_TYPES.FETCH_FILTERS_DATA,
  payload: { filters, filterParams, caching }
});
export const setFetchFiltersDataSuccess = (data: FiltersDataModel): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_FETCH_FILTERS_DATA_SUCCESS,
  payload: { data }
});
export const setFetchFiltersDataFailure = (error: string): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_FETCH_FILTERS_DATA_FAILURE,
  payload: { error }
});

export const clearFiltersData = () => ({
  type: FILTER_ACTION_TYPES.CLEAR_FILTERS_DATA,
  payload: { }
});

export const fetchUserData = ({ filters }: ActionType): IFilterAction => ({
  type: FILTER_ACTION_TYPES.FETCH_USERS_DATA,
  payload: { filters }
});
export const setFetchUserDataSuccess = (data: FiltersDataModel): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_FETCH_USERS_DATA_SUCCESS,
  payload: { data }
});
export const setFetchUserDataFailure = (error: string): IFilterAction => ({
  type: FILTER_ACTION_TYPES.SET_FETCH_USERS_DATA_FAILURE,
  payload: { error }
});
