/**
 * @flow
 * */
import type { PlayerState } from 'modules/SRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import {
  initialSectionWithCountState,
  initialSectionWithoutData,
  initialSectionState
} from 'store/reducers/initialStates';

import PLAYER_ACTION_TYPES from '../actions/player/types';

const initialState = {
  alerts: initialSectionState(),
  processedAlerts: initialSectionWithCountState(),
  alertsBulkReview: initialSectionWithoutData()
};

const actionManager = {
  // Player Alerts
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_ALERTS]: (state: PlayerState): PlayerState => ({
    ...state,
    alerts: {
      ...state.alerts,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_ALERTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alerts: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_ALERTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alerts: {
      ...state.alerts,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_ALERTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    alerts: initialSectionState()
  }),
  [PLAYER_ACTION_TYPES.UPDATE_PLAYER_ALERTS]: (state: PlayerState, payload) => ({
    ...state,
    alerts: {
      ...state.alerts,
      data: payload.data,
      totalCount: payload.totalCount
    }
  }),

  // Alerts Bulk Review
  [PLAYER_ACTION_TYPES.FETCH_ALERTS_BULK_REVIEW]: (state: PlayerState): PlayerState => ({
    ...state,
    alertsBulkReview: {
      ...state.alertsBulkReview,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ALERTS_BULK_REVIEW_SUCCESS]: (state: PlayerState): PlayerState => ({
    ...state,
    alertsBulkReview: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ALERTS_BULK_REVIEW_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    alertsBulkReview: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ALERTS_BULK_REVIEW_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    alertsBulkReview: initialSectionWithoutData()
  }),

  // Processed Alerts
  [PLAYER_ACTION_TYPES.FETCH_PROCESSED_ALERTS]: (state: PlayerState): PlayerState => ({
    ...state,
    processedAlerts: {
      ...state.processedAlerts,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PROCESSED_ALERTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    processedAlerts: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: payload.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PROCESSED_ALERTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    processedAlerts: {
      ...state.processedAlerts,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PROCESSED_ALERTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    processedAlerts: initialSectionWithCountState()
  })
};

export default {
  actionManager,
  initialState
};
