export const AlertStatuses = {
  Created: 0,
  Processing: 9
};

export const CheckManuallyEnum = {
  AML: 2,
  SRMT: 1,
  CRMT: 8
};
