import { MonitorState, MonitorTabState } from 'modules/SRMT/AlertMonitor/types';
import { infoMonitor } from 'modules/SRMT/AlertMonitor/settings';
import AlertMonitorFactory from './AlertMonitorFactory';

class InfoMonitorFactory extends AlertMonitorFactory {
  static createInitialInfoMonitor(): MonitorState {
    const tabs: MonitorTabState = Object.keys(infoMonitor.tabs).map(
      name => InfoMonitorFactory.createInitialMonitorTab(name)
    );

    return InfoMonitorFactory.createInitialMonitor(infoMonitor.key, tabs);
  }
}

export default InfoMonitorFactory;
