/**
 * @flow
 * */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import BusyLoader from '@geneui/components/BusyLoader';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from 'config/history';
import Icon from '@geneui/components/Icon';
import ReactDOM from 'react-dom';

import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import { busyLoader } from 'config/settings/core-ui-strings';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useMessageHandler from 'hooks/useMessageHandler';

import { AMLConfigEnum, getAmlLicenseForRedux } from 'modules/AML/utils/amlConfigs';
import { amlBoxesSelector, amlConfigsSelector } from 'modules/AML/store/selectors';
import ChoosePartnerModal from 'modules/AML/views/components/ChoosePartnerModal';
import CacheService from 'core/services/storage/CacheService';
import { licenseIds } from 'modules/AML/utils/licenseIds';
import { fetchConfigs } from 'modules/AML/store/actions';
import Menu from 'views/components/Menu';

import './styles.scss';

/**
 * AmlLicenseNav
 * @returns {React.Node} -
 * @constructor
 */
const AmlLicenseNav = () => {
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigationRef = useRef();
  const menuRef = useRef();

  const state = useSelector(amlBoxesSelector);
  const location = useLocation();

  const closePopover = useCallback(() => setIsOpen(false), [setIsOpen]);

  const [redirectConditions, setRedirectConditions] = useState({});

  const {
    isLoading: isLoadingConfig,
    isSucceed: isSucceedConfig,
    isFailed: isFailedConfig,
    state: configState,
    dispatch
  } = useContainerStatusHandler({ selector: amlConfigsSelector, initiallyIsLoading: false });

  useMessageHandler({
    errorMessage: configState.error,
    isFailed: isFailedConfig
  });

  const getConfig = useCallback(({ licenseId, partnerId, license }: any) => {
    dispatch(fetchConfigs({
      filters: { id: licenseId || partnerId, type: licenseId ? AMLConfigEnum.License : AMLConfigEnum.Partner },
      license: getAmlLicenseForRedux({ license, licenseId, partnerId })
    }));
    setRedirectConditions({
      license,
      partnerId
    });
  }, [dispatch, setRedirectConditions]);

  const activeLicenses = useMemo(() => state.data && state.data.length ? state.data.filter(i => !!licenseIds[i.Type.toLowerCase()]).map(i => ({
    ...licenseIds[i.Type.toLowerCase()],
    partners: i.Partners,
    key: i.Type.toLowerCase()
  })) : [], [state]);

  const navigation = useMemo(() => activeLicenses ? activeLicenses.map(i => ({
    'color': location.pathname.includes(i.key) ? 'hero' : null,
    'url': `/aml/${i.key}/dashboard`,
    'icon': 'bc-icon-license',
    'title': i.name,
    'key': i.key
  })) : [], [activeLicenses, location]);

  const hasMenu = useMemo(() => {
    if (activeLicenses.length > 1) return true;
    // $FlowFixMe
    if (licenseIds[activeLicenses?.key]?.license) return false;
    if (activeLicenses[0]?.partners?.length > 1) return true;
    return false;
  }, [activeLicenses]);

  const currentName = useMemo(() => {
    const item = navigation.find(i => i.color === 'hero');
    if (item) {
      if (licenseIds[item?.key]?.license) {
        return item?.title;
      }
      const turnkey = activeLicenses.find(i => i.key === item.key);
      const amlTurnkeyPartnerId = CacheService.getItem('amlTurnkeyPartnerId');
      if (turnkey && amlTurnkeyPartnerId) {
        const partner = turnkey.partners.find(i => i.Id === +amlTurnkeyPartnerId);
        if (partner) {
          return item.title.replace('N/A', partner.Name);
        }
      }
    }
    return '';
  }, [navigation, activeLicenses]);

  const onSelectHandler = useCallback((item) => {
    if (licenseIds[item.key]?.license) {
      getConfig({ licenseId: licenseIds[item.key].license, license: item.key });
    } else {
      const row = activeLicenses.find(i => i.key === item.key);
      // $FlowFixMe
      if (row.partners.length === 1) {
        // $FlowFixMe
        getConfig({ partnerId: row.partners[0].Id, license: item.key });
      } else {
        setModalIsOpen(row);
      }
    }
    setIsOpen(false);
  }, [setIsOpen, getConfig, activeLicenses, setModalIsOpen]);

  const configSuccessHandler = useCallback(() => {
    if (isSucceedConfig && redirectConditions?.license) {
      if (redirectConditions?.partnerId) {
        CacheService.setItem('amlTurnkeyPartnerId', redirectConditions?.partnerId);
      }
      history.push(`/aml/${redirectConditions.license}/dashboard`);
      setRedirectConditions({});
    }
  }, [isSucceedConfig, redirectConditions, setRedirectConditions]);

  const continueHandler = useCallback((partnerId, license) => {
    setModalIsOpen(false);
    getConfig({ partnerId, license });
  }, [setModalIsOpen, getConfig]);

  useEffect(configSuccessHandler, [isSucceedConfig]);
  useOnClickOutside(menuRef, closePopover, navigationRef);

  return (
    <div className="top-aml-menu-bar flex ">
      {modalIsOpen && (
        <ChoosePartnerModal
          continueHandler={(partnerId) => continueHandler(partnerId, modalIsOpen.key)}
          partners={modalIsOpen.partners}
          modalIsOpen={!!modalIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      )}
      <div
        className="m-dropdown-content"
        ref={navigationRef}
        role="button"
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="dropdown-header"
          role="button"
          tabIndex="0"
        >
          <span>{currentName}</span>
          <BusyLoader isBusy={isLoadingConfig} type={busyLoader.loaderType.spinner} spinnerSize={busyLoader.spinnerSize.big} />
          {!isLoadingConfig && hasMenu && <Icon className="arrow" type="bc-icon-input-number-down" />}
        </div>
        {isOpen && hasMenu && ReactDOM.createPortal(
          <div
            style={{ left: +navigationRef.current?.getBoundingClientRect().left - 15 }}
            className="menu-items"
            ref={menuRef}
          >
            <Menu onSelectHandler={onSelectHandler} menuItems={navigation} />
          </div>, // $FlowFixMe
          document.getElementById('portal')
        )}
      </div>
    </div>
  );
};

AmlLicenseNav.displayName = 'AmlLicenseNav';

export default AmlLicenseNav;
