/**
 * @flow
 * */
import { combineReducers } from 'redux';

import { reducers as DASHBOARD } from 'modules/Dashboard';
import { reducers as ADMIN } from 'modules/Admin';
import { reducers as LCRMT } from 'modules/LCRMT';
import { reducers as SRMT } from 'modules/SRMT';
import { reducers as AML } from 'modules/AML';

import commentsTemplate from './commentsTemplate';
import userPermission from './permission';
import userSettings from './userSettings';
import appSettings from './appSettings';
import application from './application';
import exportData from './exportData';
import landing from './landing';
import general from './general';
import filter from './filter';

export default combineReducers({
  DASHBOARD,
  ADMIN,
  LCRMT,
  SRMT,
  AML,
  general,
  commentsTemplate,
  userPermission,
  userSettings,
  application,
  appSettings,
  exportData,
  landing,
  filter
});
