/**
 * @flow
 * */

import React from 'react';

const AutomatedComments = React.lazy(() => import('./views/pages/AutomatedComments'));

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const modulePath = 'admin';

const ROUTES: Array<Route> = [
  { path: `/${modulePath}/automated-comments`, name: 'Automated Comments', exact: true, page: AutomatedComments }
];

export default ROUTES;
