/**
 * @flow
 * */

import { takeLatest, put } from 'redux-saga/effects';
import { setExportFailed, setExportSuccess } from 'store/actions/export';
import ACTION_TYPES from 'store/actions/export/types';
import exportSDK from 'core/sdk/export/exportSDK';

class ExportController {
  static* fetchExportData({ payload }) {
    const { fileType, filters, projectType, exportUrl, isPost } = payload;
    try {
      const data = isPost ? yield exportSDK.fetchPostExportData({ filters, fileType, projectType, exportUrl }) : yield exportSDK.fetchExportData({ filters, fileType, projectType, exportUrl });
      yield put(setExportSuccess({ data, fileType }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setExportFailed(err.message));
    }
  }

  static* cancelExportSDKCalls() {
    yield exportSDK.cancelExportSDKCalls();
  }

  static* userWatcher<T>(): Iterable<T> {
    yield takeLatest(ACTION_TYPES.FETCH_EXPORT_DATA, ExportController.fetchExportData);
    yield takeLatest(ACTION_TYPES.CLEAR_EXPORT_STATE, ExportController.cancelExportSDKCalls);
  }
}

export default ExportController.userWatcher;
