/**
 * @flow
 * */
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import type { WithTotalCount } from 'types';
import config from 'config';
import type {
  AlertExpandRowChildrenDataModel,
  AlertHistoryChildrenParams,
  MonitorTypeDataParams,
  AlertHistoryDataModel,
  MonitorTypeDataModel,
  MonitorReviewParams,
  MonitorRevDataModel,
  AlertHistoryParams,
  EditReviewParams
} from './types';

const getMonitorTabDataUrl = (type) => {
  const data = {
    '1': 'FraudAlert/GetPendingFraudAlerts',
    '2': 'FraudAlert/GetPendingFraudAlerts',
    '3': 'MonitoringAlert/GetPendingAlerts',
    '9': 'GameStyleAlert/GetPendingGameStyleAlerts'
  };
  return data[type.toString()] || 'AlertMonitor/Get';
};

const getReviewDataUrl = (type) => {
  const data = {
    '1': 'FraudAlert/GetAlertReview',
    '2': 'FraudAlert/GetAlertReview',
    '3': 'MonitoringAlert/GetAlertReview',
    '9': 'GameStyleAlert/GetAlertReview'
  };
  return data[type.toString()] || 'AlertMonitor/StartReview';
};

const editReviewDataUrl = (type, action = null) => {
  const data = {
    '1': 'FraudAlert/UpdateAlert',
    '2': 'FraudAlert/UpdateAlert',
    '3': 'MonitoringAlert/UpdateAlert',
    '9': 'GameStyleAlert/UpdateAlert'
  };
  return data[type.toString()] || `AlertMonitor/${action || ''}`;
};

const getAlertHistoryDataUrl = (type) => {
  const data = {
    '1': 'FraudAlert/GetAlertHistory',
    '2': 'FraudAlert/GetAlertHistory',
    '3': 'MonitoringAlert/GetAlertHistory',
    '9': 'GameStyleAlert/GetAlertHistory'
  };
  return data[type.toString()] || 'FraudAlert/GetAlertHistory';
};
const getAlertHistoryChildrenDataUrl = (type) => {
  const data = {
    '9': 'GameStyleAlert/GetGameStyleAlertHistoryDetails'
  };
  return data[type.toString()] || 'FraudAlert/GetPlayers';
};

/**
   * @class AlertMonitorSDK
   */
class AlertMonitorSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
     * getMonitorTabData
     * @param {MonitorTypeDataParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async getMonitorTabData({ filters }: MonitorTypeDataParams): Promise<WithTotalCount<MonitorTypeDataModel>> {
      // eslint-disable-next-line no-param-reassign
      filters.typeId = filters.alertType;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        getMonitorTabDataUrl(filters.alertType),
        { query_params: filters, abortController: this.setAbortController('getMonitorTabData') }
      );
      return body;
    }

    /**
   * getReviewData
   * @param {MonitorReviewParams} params -
   * @returns {Promise<{data: Object}>} -
   */
    async getReviewData({ filters }: MonitorReviewParams): Promise<MonitorRevDataModel> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        getReviewDataUrl(filters.alertType),
        { query_params: filters }
      );
      return body;
    }

    /**
   * getAlertHistoryData
   * @param {AlertHistoryParams} params -
   * @returns {Promise<{data: Object}>} -
   */
    async getAlertHistoryData({ alertId, alertType }: AlertHistoryParams): Promise<AlertHistoryDataModel> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        getAlertHistoryDataUrl(alertType),
        { query_params: { alertId } }
      );
      return body;
    }

    /**
   * getAlertHistoryChildrenData
   * @param {AlertHistoryChildrenParams} params -
   * @returns {Promise<{data: Object}>} -
   */
    async getAlertHistoryChildrenData({ alertHistoryId, alertId, alertType }: AlertHistoryChildrenParams): Promise<AlertExpandRowChildrenDataModel> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        getAlertHistoryChildrenDataUrl(alertType),
        { query_params: { alertHistoryId, alertId } }
      );
      return body;
    }

    /**
     * getAlertExpandRowChildrenData
     * @param {AlertExpandRowChildrenParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async getAlertExpandRowChildrenData({ alertId }: Object): Promise<AlertExpandRowChildrenDataModel> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'FraudAlert/GetAlertShortReview',
        { query_params: { alertId } }
      );
      return body;
    }

    /**
   * editReviewData
   * @param {EditReviewParams} params -
   * @param {EditReviewParams} action -
   * @returns {Promise<{data: Object}>} -
   */
    async editReviewData({ data, action }: EditReviewParams) {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        editReviewDataUrl(data.alertType, action),
        { body: data }
      );
      return body.Data;
    }

    /**
   * cancelAlertSDKCalls
   * @param {Object} params -
   * @returns  {Object} -
   */
    async cancelAlertSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new AlertMonitorSDK();
