/**
 * @flow
 * */
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import {
  initialSectionState,
  initialSectionWithEditState,
  initialSectionWithoutData,
  initialKPITreeState,
  initialSectionWithCountState
} from 'store/reducers/initialStates';
import type { PlayerState } from 'modules/SRMT/Players/types';
import PLAYER_ACTION_TYPES from '../actions/player/types';

const initialState = {
  additionalCategories: initialSectionWithEditState(),
  availableAdditionalCategories: initialSectionState(),
  additionalCategoriesBulkMerge: initialSectionState(),
  gameStyleCategories: initialSectionState(),
  partnerGameStyleCategories: initialSectionState(),
  categoryHistory: initialSectionWithCountState(),
  playerKPITree: initialKPITreeState()
};

const actionManager = {
  // Game Style Categories
  [PLAYER_ACTION_TYPES.FETCH_GAME_STYLE_CATEGORIES]: (state: PlayerState): PlayerState => ({
    ...state,
    gameStyleCategories: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GAME_STYLE_CATEGORIES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameStyleCategories: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_GAME_STYLE_CATEGORIES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameStyleCategories: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_GAME_STYLE_CATEGORIES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    gameStyleCategories: initialSectionState()
  }),

  // Partner Game Style Categories
  [PLAYER_ACTION_TYPES.FETCH_PARTNER_GAME_STYLE_CATEGORIES]: (state: PlayerState): PlayerState => ({
    ...state,
    partnerGameStyleCategories: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    partnerGameStyleCategories: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PARTNER_GAME_STYLE_CATEGORIES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    partnerGameStyleCategories: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PARTNER_GAME_STYLE_CATEGORIES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    partnerGameStyleCategories: initialSectionState()
  }),

  // Category History
  [PLAYER_ACTION_TYPES.FETCH_CATEGORY_HISTORY]: (state: PlayerState): PlayerState => ({
    ...state,
    categoryHistory: {
      ...state.categoryHistory,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_CATEGORY_HISTORY_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    categoryHistory: {
      status: ACTION_STATUSES.SUCCEED,
      totalCount: payload.totalCount,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_CATEGORY_HISTORY_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    categoryHistory: {
      ...state.categoryHistory,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_CATEGORY_HISTORY_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    categoryHistory: initialSectionWithCountState()
  }),
  // LicenseRules Categories
  [PLAYER_ACTION_TYPES.FETCH_ADDITIONAL_CATEGORIES]: (state: PlayerState): PlayerState => ({
    ...state,
    additionalCategories: {
      ...state.additionalCategories,
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ADDITIONAL_CATEGORIES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    additionalCategories: {
      ...state.additionalCategories,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_ADDITIONAL_CATEGORIES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    additionalCategories: {
      ...state.additionalCategories,
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ADDITIONAL_CATEGORIES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    additionalCategories: initialSectionWithEditState()
  }),
  // Edit LicenseRules Category
  [PLAYER_ACTION_TYPES.EDIT_ADDITIONAL_CATEGORY]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    additionalCategories: {
      ...state.additionalCategories,
      edit: {
        ...state.additionalCategories.edit,
        [payload.categoryId]: {
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_EDIT_ADDITIONAL_CATEGORY_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    additionalCategories: {
      ...state.additionalCategories,
      edit: {
        ...state.additionalCategories.edit,
        [payload.categoryId]: {
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_EDIT_ADDITIONAL_CATEGORY_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    additionalCategories: {
      ...state.additionalCategories,
      edit: {
        ...state.additionalCategories.edit,
        [payload.categoryId]: {
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  // Available LicenseRules Categories
  [PLAYER_ACTION_TYPES.FETCH_AVAILABLE_ADDITIONAL_CATEGORIES]: (state: PlayerState): PlayerState => ({
    ...state,
    availableAdditionalCategories: {
      ...state.availableAdditionalCategories,
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null,
      edit: initialSectionWithoutData()
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    availableAdditionalCategories: {
      ...state.availableAdditionalCategories,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_AVAILABLE_ADDITIONAL_CATEGORIES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    availableAdditionalCategories: {
      ...state.availableAdditionalCategories,
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_AVAILABLE_ADDITIONAL_CATEGORIES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    availableAdditionalCategories: initialSectionState()
  }),
  // MergeBulk LicenseRules Category
  [PLAYER_ACTION_TYPES.ADDITIONAL_CATEGORIES_BULK_MERGE]: (state: PlayerState): PlayerState => ({
    ...state,
    additionalCategoriesBulkMerge: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_ADDITIONAL_CATEGORIES_BULK_MERGE_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    additionalCategoriesBulkMerge: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_ADDITIONAL_CATEGORIES_BULK_MERGE_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    additionalCategoriesBulkMerge: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ADDITIONAL_CATEGORIES_BULK_MERGE_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    additionalCategoriesBulkMerge: initialSectionState()
  }),
  // Player KPI Tree
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_KPI_TREE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerKPITree: {
      ...state.playerKPITree,
      data: {
        ...state.playerKPITree.data,
        [payload.key]: {
          ...state.playerKPITree.data[payload.key],
          isLoading: true
        }
      },
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_KPI_TREE_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerKPITree: {
      status: ACTION_STATUSES.SUCCEED,
      data: {
        ...state.playerKPITree.data,
        ...payload.data,
        [payload.key]: {
          ...state.playerKPITree.data[payload.key],
          children: payload.children,
          isLoading: false,
          isOpened: true,
          isLoadedChildren: true
        }
      },
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_KPI_TREE_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerKPITree: {
      status: ACTION_STATUSES.FAILED,
      data: null,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLOSE_PLAYER_KPI_TREE_NODE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerKPITree: {
      data: {
        ...state.playerKPITree.data,
        [payload.key]: {
          ...state.playerKPITree.data[payload.key],
          isOpened: false
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.OPEN_PLAYER_KPI_TREE_NODE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerKPITree: {
      data: {
        ...state.playerKPITree.data,
        [payload.key]: {
          ...state.playerKPITree.data[payload.key],
          isOpened: true
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_KPI_TREE_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerKPITree: initialKPITreeState()
  }),
  // SuspiciousActivity Categories
  [PLAYER_ACTION_TYPES.FETCH_FINANCIAL_CATEGORIES]: (state: PlayerState): PlayerState => ({
    ...state,
    financialCategories: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.UPDATE_FINANCIAL_CATEGORIES]: (state: PlayerState, payload) => ({
    ...state,
    financialCategories: {
      data: {
        ...state.financialCategories.data,
        ...payload.data
      },
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_FINANCIAL_CATEGORIES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    financialCategories: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_FINANCIAL_CATEGORIES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    financialCategories: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_FINANCIAL_CATEGORIES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    financialCategories: initialSectionState()
  }),
  // Game Style Category Update
  [PLAYER_ACTION_TYPES.GAME_STYLE_CATEGORY_UPDATE]: (state: PlayerState): PlayerState => ({
    ...state,
    gameStyleCategoryUpdate: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_GAME_STYLE_CATEGORY_UPDATE_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameStyleCategoryUpdate: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_GAME_STYLE_CATEGORY_UPDATE_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameStyleCategoryUpdate: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_GAME_STYLE_CATEGORY_UPDATE_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    gameStyleCategoryUpdate: initialSectionState()
  }),
  // Game Style Categories Bulk Update
  [PLAYER_ACTION_TYPES.GAME_STYLE_CATEGORIES_BULK_UPDATE]: (state: PlayerState): PlayerState => ({
    ...state,
    gameStyleCategoriesBulkUpdate: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameStyleCategoriesBulkUpdate: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_GAME_STYLE_CATEGORIES_BULK_UPDATE_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    gameStyleCategoriesBulkUpdate: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_GAME_STYLE_CATEGORIES_BULK_UPDATE_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    gameStyleCategoriesBulkUpdate: initialSectionState()
  })
};

export default {
  actionManager,
  initialState
};
