/**
 * @flow
 * */
import { initialSectionState, initialSectionWithCountState, initialSectionWithoutData } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

import { bulkAlertsDataChange } from 'modules/AML/utils/bulkAlertsDataChange';
import AM_ACTION_TYPES from 'modules/AML/store/actions/types';
import PLAYER_TYPES from 'store/actions/player/types';
import { AMLState } from 'modules/AML/types';

const initialState = (): AMLState => ({
  playerBlockHistoryReport: initialSectionWithCountState(),
  playerRuleScoreHistory: initialSectionWithCountState(),
  playerScoreReport: initialSectionWithCountState(),
  playerScoreRulesAction: initialSectionState(),
  playerMultiAccounts: initialSectionState(),
  playerScoreRules: initialSectionState(),
  players: initialSectionWithCountState(),
  playerAlerts: initialSectionWithCountState(),
  playerAlertsBulkReview: initialSectionWithoutData(),
  transactionActivity: initialSectionState(),
  bettingHourlyActivity: initialSectionState(),
  addFiles: initialSectionState(),
  getFiles: initialSectionState(),
  deleteFiles: initialSectionState()
});

const actionManager = {

  // delete Note Attached files
  [PLAYER_TYPES.FETCH_DELETE_AML_PLAYER_FILES]: (state: AMLState) => ({
    ...state,
    deleteFiles: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_DELETE_AML_PLAYER_FILES_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    deleteFiles: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_DELETE_AML_PLAYER_FILES_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    deleteFiles: {
      ...state.deleteFiles,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_TYPES.CLEAR_DELETE_AML_PLAYER_FILES_STATE]: (state: AMLState) => ({
    ...state,
    deleteFiles: initialSectionState()
  }),

  // Client Note Attached files
  [PLAYER_TYPES.FETCH_AML_PLAYER_FILES]: (state: AMLState) => ({
    ...state,
    addFiles: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_AML_PLAYER_FILES_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    addFiles: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_AML_PLAYER_FILES_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    addFiles: {
      ...state.addFiles,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_TYPES.CLEAR_AML_PLAYER_FILES_STATE]: (state: AMLState) => ({
    ...state,
    addFiles: initialSectionState()
  }),

  // Get Note Attached files
  [PLAYER_TYPES.FETCH_GET_AML_PLAYER_FILES]: (state: AMLState) => ({
    ...state,
    getFiles: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_GET_AML_PLAYER_FILES_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    getFiles: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_GET_AML_PLAYER_FILES_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    getFiles: {
      ...state.getFiles,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_TYPES.CLEAR_GET_AML_PLAYER_FILES_STATE]: (state: AMLState) => ({
    ...state,
    getFiles: initialSectionState()
  }),

  // player multi account
  [AM_ACTION_TYPES.FETCH_AML_PLAYERS_MULTI_ACCOUNTS]: (state: AMLState) => ({
    ...state,
    playerMultiAccounts: {
      ...state.playerMultiAccounts,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerMultiAccounts: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerMultiAccounts: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_PLAYERS_MULTI_ACCOUNTS]: (state: AMLState) => ({
    ...state,
    playerMultiAccounts: initialSectionState()
  }),

  // players
  [AM_ACTION_TYPES.FETCH_AML_PLAYERS]: (state: AMLState) => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYERS_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    players: {
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.players.totalCount,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYERS_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    players: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_PLAYERS]: (state: AMLState) => ({
    ...state,
    players: initialSectionWithCountState()
  }),

  // player alerts
  [AM_ACTION_TYPES.FETCH_AML_PLAYER_ALERTS]: (state: AMLState) => ({
    ...state,
    playerAlerts: {
      ...state.playerAlerts,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYER_ALERTS_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerAlerts: {
      error: null,
      data: payload.data,
      totalCount: payload.totalCount,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_ALERTS_BULK_REVIEW_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerAlerts: {
      ...state.playerAlerts,
      data: bulkAlertsDataChange(state.playerAlerts.data, payload.alertIds)
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYER_ALERTS_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerAlerts: {
      data: null,
      totalCount: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_PLAYER_ALERTS]: (state: AMLState) => ({
    ...state,
    players: initialSectionWithCountState()
  }),

  // alerts bulk review
  [AM_ACTION_TYPES.FETCH_AML_PLAYER_ALERTS_BULK_REVIEW]: (state: AMLState) => ({
    ...state,
    playerAlertsBulkReview: {
      ...state.playerAlertsBulkReview,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_SUCCESS]: (state: AMLState) => ({
    ...state,
    playerAlertsBulkReview: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerAlertsBulkReview: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_PLAYER_ALERTS_BULK_REVIEW]: (state: AMLState) => ({
    ...state,
    playerAlertsBulkReview: initialSectionWithoutData()
  }),

  // player score rules
  [AM_ACTION_TYPES.FETCH_PLAYER_SCORE_RULE]: (state: AMLState) => ({
    ...state,
    playerScoreRules: {
      ...state.playerScoreRules,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_RULE_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerScoreRules: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_RULE_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerScoreRules: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_FETCH_PLAYER_SCORE_RULE]: (state: AMLState) => ({
    ...state,
    playerScoreRules: initialSectionState()
  }),

  // Change Score Count
  [AM_ACTION_TYPES.FETCH_CHANGE_SCORE_COUNT]: (state: AMLState, payload) => ({
    ...state,
    scoreCount: {
      data: { ruleId: payload.ruleId },
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_CHANGE_SCORE_COUNT_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    scoreCount: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.UPDATE_CHANGE_SCORE_COUNT]: (state: AMLState, payload) => ({
    ...state,
    playerScoreRules: {
      data: {
        ...state.playerScoreRules.data,
        Rules: [
          // eslint-disable-next-line no-return-assign
          ...state.playerScoreRules.data.Rules.map(
            (item) => ({
              ...item,
              // eslint-disable-next-line no-return-assign
              Rules: item.Rules.map((val) => ({
                ...val,
                // eslint-disable-next-line no-param-reassign
                ScoreCount: val.Id === payload.ruleId ? val.ScoreCount = payload.count : val.ScoreCount,
                Enabled: val.Id === payload.ruleId && payload.enabled !== undefined ? payload.enabled : val.Enabled
              }))
            })
          )
        ]
      },
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_CHANGE_SCORE_COUNT_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    scoreCount: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_CHANGE_SCORE_COUNT]: (state: AMLState) => ({
    ...state,
    scoreCount: initialSectionState()
  }),

  // player score rules enabled/disabled
  [AM_ACTION_TYPES.FETCH_PLAYER_SCORE_RULE_ACTION]: (state: AMLState) => ({
    ...state,
    playerScoreRulesAction: {
      ...state.playerScoreRulesAction,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_RULE_ACTION_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerScoreRulesAction: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_RULE_ACTION_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerScoreRulesAction: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_FETCH_PLAYER_SCORE_RULE_ACTION]: (state: AMLState) => ({
    ...state,
    playerScoreRulesAction: initialSectionState()
  }),

  // Rule Score History
  [AM_ACTION_TYPES.FETCH_PLAYER_SCORE_HISTORY]: (state: AMLState) => ({
    ...state,
    playerRuleScoreHistory: {
      ...state.playerRuleScoreHistory,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_HISTORY_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerRuleScoreHistory: {
      error: null,
      data: payload.data,
      totalCount: payload.totalCount,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_HISTORY_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerRuleScoreHistory: {
      data: null,
      error: payload.error,
      totalCount: null,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_PLAYER_SCORE_HISTORY_STATE]: (state: AMLState) => ({
    ...state,
    playerRuleScoreHistory: initialSectionWithCountState()
  }),

  // Transaction Activity Data
  [AM_ACTION_TYPES.FETCH_TRANSACTION_ACTIVITY_DATA]: (state: AMLState) => ({
    ...state,
    transactionActivity: {
      ...state.transactionActivity,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_TRANSACTION_ACTIVITY_DATA_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    transactionActivity: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_TRANSACTION_ACTIVITY_DATA_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    transactionActivity: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_TRANSACTION_ACTIVITY_DATA_STATE]: (state: AMLState) => ({
    ...state,
    transactionActivity: initialSectionState()
  }),

  // Betting Hourly Activity Data
  [AM_ACTION_TYPES.FETCH_BETTING_HOURLY_ACTIVITY_DATA]: (state: AMLState) => ({
    ...state,
    bettingHourlyActivity: {
      ...state.bettingHourlyActivity,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    bettingHourlyActivity: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    bettingHourlyActivity: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_BETTING_HOURLY_ACTIVITY_DATA_STATE]: (state: AMLState) => ({
    ...state,
    bettingHourlyActivity: initialSectionState()
  })
};

export default (state: AMLState = initialState(), action: Action) => {
  if (!actionManager[action.type]) return state;

  return actionManager[action.type](state, action.payload);
};
