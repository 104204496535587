/**
 * @flow
 * */
import type { FilterType, WithData, WithTotalCountAndData, FilterAndTabKeyType } from 'types';
import { amlAlertConfigs } from 'modules/AML/utils/amlAlertsFactory';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';
import type {
  PlayerExistingAlertParams,
  PlayerExistingAlertModel,
  PlayerSetAlertParams
} from 'modules/SRMT/Players/core/sdk/players/types';
import {
  PrayerBlockHistoryState,
  AMLPlayerMultiAccount,
  PlayerAlertsState,
  TransactionsState,
  AnalyticDataState,
  AmlRuleScoreState,
  ScoreRuleSAction,
  PrayerScoreState,
  RuleScoreHistory,
  DashboardParams,
  DashboardState,
  AnalyticTypes,
  ConfigsState,
  PrayerState,
  BoxesState,
  ScoreRule
} from 'modules/AML/types';

const amlAlertsCall = {
  [amlAlertConfigs.amlAlerts.tabKey]: 'AmlAlert/GetAlerts',
  [amlAlertConfigs.rgAlerts.tabKey]: 'AmlAlert/GetAlerts',
  [amlAlertConfigs.singleThresholdAlerts.tabKey]: 'SingleThresholdAlert/GetSingleThresholdAlerts',
  [amlAlertConfigs.multiAccountThresholdAlerts.tabKey]: 'SingleThresholdAlert/GetSingleThresholdMultiaccountAlerts',
  [amlAlertConfigs.multiAccountAlerts.tabKey]: 'AmlRgFraudAlert/GetAlerts',
  [amlAlertConfigs.suspiciousActivityAlerts.tabKey]: 'SuspiciousAlert/GetSuspiciousAlert',
  [amlAlertConfigs.monitoringAlerts.tabKey]: 'MonitoringAlert/GetPendingAlerts'
};

const amlAlertsGetReviewCall = {
  [amlAlertConfigs.singleThresholdAlerts.tabKey]: 'SingleThresholdAlert/GetAlertDocuments',
  [amlAlertConfigs.rgAlerts.tabKey]: 'AmlAlert/Review',
  [amlAlertConfigs.amlAlerts.tabKey]: 'AmlAlert/Review',
  [amlAlertConfigs.multiAccountAlerts.tabKey]: 'AmlRgFraudAlert/GetAlertReview',
  [amlAlertConfigs.multiAccountThresholdAlerts.tabKey]: 'SingleThresholdAlert/GetAlertDocuments',
  [amlAlertConfigs.monitoringAlerts.tabKey]: 'MonitoringAlert/GetAlertReview'
};

const amlAlertsGetReviewHistoryCall = {
  [amlAlertConfigs.rgAlerts.tabKey]: 'AmlAlert/GetReviewHistory',
  [amlAlertConfigs.amlAlerts.tabKey]: 'AmlAlert/GetReviewHistory',
  [amlAlertConfigs.multiAccountAlerts.tabKey]: 'AmlRgFraudAlert/GetAlertHistory',
  [amlAlertConfigs.monitoringAlerts.tabKey]: 'MonitoringAlert/GetAlertHistory'
};

const amlAlertsBulkReviewCall = {
  [amlAlertConfigs.amlAlerts.tabKey]: 'AmlAlert/MarkAsRead',
  [amlAlertConfigs.rgAlerts.tabKey]: 'AmlAlert/MarkAsRead',
  [amlAlertConfigs.singleThresholdAlerts.tabKey]: 'SingleThresholdAlert/UpdateSingleThresholdAlerts',
  [amlAlertConfigs.multiAccountThresholdAlerts.tabKey]: 'SingleThresholdAlert/UpdateSingleThresholdMultiAccountAlerts',
  [amlAlertConfigs.multiAccountAlerts.tabKey]: 'AmlRgFraudAlert/UpdateAlert',
  [amlAlertConfigs.suspiciousActivityAlerts.tabKey]: 'SuspiciousAlert/MarkAsRead',
  [amlAlertConfigs.monitoringAlerts.tabKey]: 'MonitoringAlert/UpdateAlert'
};

const multiAccountExpandsCalls = {
  [amlAlertConfigs.multiAccountAlerts.tabKey]: 'AmlRgFraudAlert/GetAlertShortReview',
  [amlAlertConfigs.multiAccountThresholdAlerts.tabKey]: 'SingleThresholdAlert/GetAlertShortReview'
};

/**
   * @class AMLSDK
   */
class AMLSDK extends BaseSDK {
    _amlApiService: NetworkService;

    constructor() {
      super();
      this._amlApiService = new NetworkService(config.amlApi, []);
    }

    /**
     * fetchDashboard -
     * @param {DashboardParams} params -
     * @returns {Promise<DashboardState>} -
     */
    async fetchDashboard(params: DashboardParams): Promise<DashboardState> {
      const { body } = await this._amlApiService.makeAPIPostRequest(
        'Aml/GetDashboardData',
        { body: params, abortController: this.setAbortController('fetchDashboard') }
      );
      return { data: body.Data };
    }

    /**
   * fetchTransactions -
   * @param {FilterType} params -
   * @returns {Promise<WithTotalCountAndData<TransactionsState>>} -
   */
    async fetchTransactions(params: FilterType): Promise<WithTotalCountAndData<TransactionsState>> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'AmlAlert/GetDocuments',
        { query_params: filters, abortController: this.setAbortController('fetchTransactions') }
      );

      return body;
    }

    /**
   * fetchBoxes -
   * @returns {Promise<WithData<BoxesState>>} -
   */
    async fetchBoxes(): Promise<WithData<BoxesState>> {
      const { body } = await this._amlApiService.makeAPIGetRequest(
        'Aml/GetAvailableBoxes',
        { caching: true }
      );

      return { data: body.Data };
    }

    /**
   * fetchConfigs -
   * @param {FilterType} params -
   * @returns {Promise<WithData<ConfigsState>>} -
   */
    async fetchConfigs(params: FilterType): Promise<WithData<ConfigsState>> {
      const { filters } = params;
      const { body } = await this._amlApiService.makeAPIGetRequest(
        'Aml/GetAmlConfig',
        { query_params: filters, caching: true }
      );

      return { data: body.Data };
    }

    /**
   * fetchPlayerRuleScores -
   * @param {{ playerId: number }} params -
   * @returns { Promise<WithData<ScoreRule>> } -
   */
    async fetchPlayerRuleScores(params: { playerId: number }): Promise<WithData<ScoreRule>> {
      const { playerId } = params;
      const { body } = await this._amlApiService.makeAPIGetRequest(
        'PlayerScoreRule/Get',
        { query_params: { playerId }, abortController: this.setAbortController('fetchPlayerRuleScores') }
      );
      // $FlowFixMe
      return { data: body.Data };
    }

    /**
     * fetchChangeScoreCount -
     * @param {{ clientId: number, typeId: number, ruleId: number, scoreRuleTypeId: number }} params -
     * @returns { Promise<WithData<number>> } -
     */
    async fetchChangeScoreCount(params: { clientId: number, typeId: number, ruleId: number, scoreRuleTypeId: number }): Promise<WithData<number>> {
      const { body } = await this._amlApiService.makeAPIPostRequest(
        'PlayerScoreRule/ChangeScoreCount',
        { body: params, abortController: this.setAbortController('fetchChangeScoreCount') }
      );
        // $FlowFixMe
      return { data: body.Data };
    }

    /**
     * fetchPlayerRuleScoresAction -
     * @param {{ playerId: number, enabled: boolean, ruleId: number }} params -
     * @returns {Promise<WithData<ScoreRuleSAction>>} -
     */
    async fetchPlayerRuleScoresAction(params: { playerId: number, enabled: boolean, ruleId: number }): Promise<WithData<ScoreRuleSAction>> {
      const { body } = await this._amlApiService.makeAPIPostRequest(
        'PlayerScoreRule/ToggleScoreRule',
        { body: params }
      );

      return { data: body.Data };
    }

    /**
   * fetchPlayerMultiAccount -
   * @param {{ filters: { playerId: number } }} params -
   * @returns {Promise<Array<AMLPlayerMultiAccount>>} -
   */
    async fetchPlayerMultiAccount(params: { filters: { playerId: number } }): Promise<Array<AMLPlayerMultiAccount>> {
      const { filters: { playerId } } = params;
      const { body } = await this._amlApiService.makeAPIGetRequest(
        'PlayerMultiAccounts/GetRelatedPlayersWithConnections',
        { query_params: { playerId } }
      );
      return body;
    }

    /**
   * fetchPlayers -
   * @param {FilterType} params -
   * @returns {Promise<Array<PrayerState>>} -
   */
    async fetchPlayers(params: FilterType): Promise<Array<PrayerState>> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'AmlPlayer/FindPlayers',
        { query_params: filters, abortController: this.setAbortController('fetchPlayers') }
      );

      return body;
    }

    /**
     * fetchPlayers -
     * @param {FilterType} params -
     * @returns {Promise<Array<PlayerAlertsState>>} -
     */
    async fetchPlayerAlerts(params: FilterType): Promise<Array<PlayerAlertsState>> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'AmlAlert/GetAlerts',
        { query_params: filters, abortController: this.setAbortController('fetchPlayerAlerts') }
      );

      return body;
    }

    /**
     * fetchAnalytics -
     * @param {FilterType} params -
     * @returns {Promise<Array<PlayerAlertsState>>} -
     */
    async fetchAnalytics(params: AnalyticTypes): Promise<Array<AnalyticDataState>> {
      const { type, playerId, dateFrom, dateTo, activityFilter } = params;
      const urlObj = {
        transactionActivity: 'Player/GetClientTransactionalActivity',
        bettingHourlyActivity: 'Player/GetClientBettingHourlyActivity'
      };
      const { body } = await this._amlApiService.makeAPIGetRequest(
        `${urlObj[type]}`,
        { query_params: { clientId: playerId, dateFrom, dateTo, activityFilter }, abortController: this.setAbortController('fetchAnalytics') }
      );

      return body.Data;
    }

    /**
     * updatePlayerAlertsBulkReview -
     * @param {FilterType} params -
     * @returns {Promise<Object>} -
     */
    async updatePlayerAlertsBulkReview(params: FilterType):Promise<Object> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIPostRequest(
        'AmlAlert/BulkyReview',
        { body: filters }
      );

      return body;
    }

    /**
   * fetchPlayerBlockHistoryReport -
   * @param {FilterType} params -
   * @returns {Promise<Array<PrayerBlockHistoryState>>} -
   */
    async fetchPlayerBlockHistoryReport(params: FilterType): Promise<Array<PrayerBlockHistoryState>> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'Reports/GetAutomatedBlockReport',
        { query_params: filters, abortController: this.setAbortController('fetchPlayerBlockHistoryReport') }
      );

      return body;
    }

    /**
   * fetchPlayerScoreReport -
   * @param {FilterType} params -
   * @returns {Promise<Array<PrayerScoreState>>} -
   */
    async fetchPlayerScoreReport(params: FilterType): Promise<Array<AmlRuleScoreState>> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'Reports/GetScoreReport',
        { query_params: filters, abortController: this.setAbortController('fetchPlayerScoreReport') }
      );

      return body;
    }

    /**
     * fetchAmlRuleScoreReport -
     * @param {FilterType} params -
     * @returns {Promise<Array<PrayerScoreState>>} -
     */
    async fetchAmlRuleScoreReport(params: FilterType): Promise<Array<PrayerScoreState>> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'Reports/GetAmlRuleScoreReport',
        { query_params: filters, abortController: this.setAbortController('fetchAmlRuleScoreReport') }
      );

      return body;
    }

    /**
   * fetchAmlAlertsReview -
   * @param {FilterAndTabKeyType} params -
   * @returns {Promise<{ Data: Object }>} -
   */
    async fetchAmlAlertsReview(params: FilterAndTabKeyType): Promise<{ Data: Object }> {
      const { filters, tabKey } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        amlAlertsGetReviewCall[tabKey],
        { query_params: filters }
      );
      return body;
    }

    /**
     * fetchAmlAlertsReviewExpand -
     * @param {{sessionId: number}} params -
     * @returns {Promise<{ Data: Object }>} -
     */
    async fetchAmlAlertsReviewExpand(params: { sessionId: number }): Promise<{ Data: Object }> {
      const { sessionId } = params;
      const { body } = await this._amlApiService.makeAPIGetRequest(
        'AmlAlert/GetSessionBets',
        { query_params: { sessionId } }
      );
      return body;
    }

    /**
   * fetchAmlAlertsReviewHistory -
   * @param {FilterAndTabKeyType} params -
   * @returns {Promise<{ Data: Object }>} -
   */
    async fetchAmlAlertsReviewHistory(params: FilterAndTabKeyType): Promise<{ Data: Object }> {
      const { filters, tabKey } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        amlAlertsGetReviewHistoryCall[tabKey],
        { query_params: filters }
      );
      return body;
    }

    /**
     * fetchAmlAlertsReviewHistoryExpand -
     * @param {{ alertId: number }} params -
     * @returns {Promise<Object>} -
     */
    async fetchAmlAlertsReviewHistoryExpand(params: { alertId: number }): Promise<Object> {
      const { alertId } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'AmlRgFraudAlert/GetPlayers',
        { query_params: { alertHistoryId: alertId } }
      );
      return body.Data;
    }

    /**
   * fetchAmlAlertsRiskCategoryHistory -
   * @param {FilterType} params -
   * @returns {Promise<{ Data: Object }>} -
   */
    async fetchAmlAlertsRiskCategoryHistory(params: FilterType): Promise<{ Data: Object }> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'AmlAlert/GetRiskCategoryHistory',
        { query_params: filters }
      );
      return body;
    }

    /**
   * fetchAmlAlerts -
   * @param {FilterAndTabKeyType} params -
   * @returns {Promise<{ Data: Object }>} -
   */
    async fetchAmlAlerts(params: FilterAndTabKeyType): Promise<{ Data: Object }> {
      const { filters, tabKey } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        amlAlertsCall[tabKey],
        { query_params: filters, abortController: this.setAbortController(`fetchAmlAlerts${tabKey}`) }
      );

      return body;
    }

    /**
     * fetchAmlExpandedData -
     * @param {{ alertId: number, tabKey: string }} params -
     * @returns {Promise<Object>} -
     */
    async fetchAmlExpandedData(params: { alertId: number, tabKey:string }): Promise<Object> {
      const { alertId, tabKey } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        multiAccountExpandsCalls[tabKey],
        { query_params: { alertId } }
      );
      return body.Data;
    }

    /**
   * fetchAmlAlertsBulkReview -
   * @param {FilterAndTabKeyType} params -
   * @returns {Promise<Object>} -
   */
    async fetchAmlAlertsBulkReview(params: FilterAndTabKeyType): Promise<Object> {
      const { filters, tabKey } = params;

      const { body } = await this._amlApiService.makeAPIPostRequest(
        amlAlertsBulkReviewCall[tabKey],
        { body: filters }
      );

      return body.Data;
    }

    /**
   * fetchAmlAlertsReviewEdit -
   * @param {FilterAndTabKeyType} params -
   * @returns {Promise<Object>} -
   */
    async fetchAmlAlertsReviewEdit(params: FilterAndTabKeyType): Promise<Object> {
      const { filters, tabKey } = params;

      const { body } = await this._amlApiService.makeAPIPostRequest(
        amlAlertsBulkReviewCall[tabKey],
        { body: filters }
      );

      return body.Data;
    }

    /**
   * fetchPlayerRuleScoreHistory -
   * @param {FilterType} params -
   * @returns {Promise<WithTotalCountAndData<RuleScoreHistory>>} -
   */
    async fetchPlayerRuleScoreHistory(params: FilterType): Promise<WithTotalCountAndData<RuleScoreHistory>> {
      const { filters } = params;

      const { body } = await this._amlApiService.makeAPIGetRequest(
        'Player/GetScoreRuleHistory',
        { query_params: filters, abortController: this.setAbortController('fetchPlayerRuleScoreHistory') }
      );

      return body;
    }

    /**
     * fetchSetAmlMonitoringAlert
     * @param {Object} filters -
     * @returns {Promise<any>} -
     */
    async fetchSetAmlMonitoringAlert({ filters }: any): Promise<{data: any}> {
      const { body } = await this._amlApiService.makeAPIGetRequest(
        'MonitoringAlert/GetProcessingAlert',
        { query_params: { ...filters } }
      );
      return {
        data: body.Data
      };
    }

    /**
     * getAmlPlayerExistingAlert
     * @param {PlayerExistingAlertParams} params -
     * @returns {Promise<{data: PlayerExistingAlertModel}>} -
     */
    async getAmlPlayerExistingAlert(params: PlayerExistingAlertParams): Promise<{ data: PlayerExistingAlertModel }> {
      const { playerId, licence } = params;
      const { body } = await this._amlApiService.makeAPIGetRequest(
        'MonitoringAlert/GetProcessingAlert',
        { query_params: { playerId, ...licence } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * setAmlPlayerAlert
     * @param {PlayerSetAlertParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async setAmlPlayerAlert(params: PlayerSetAlertParams): Promise<{ data: Object }> {
      const { body } = await this._amlApiService.makeAPIPostRequest(
        'MonitoringAlert/SetAlert',
        { body: params }
      );

      return {
        data: body.Data
      };
    }

    /**
     * fetchAmlResolveMonitoringAlert
     * @param {any} params -
     * @returns {Promise<any>} -
     */
    async fetchAmlResolveMonitoringAlert(params: any): Promise<any> {
      const { body } = await this._amlApiService.makeAPIPostRequest(
        'MonitoringAlert/UpdateAlert',
        { body: params }
      );

      return body;
    }

    /**
   * cancelAmlSDKCalls
   * @param {Object} params -
   * @returns {Object} -
   */
    cancelAmlSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new AMLSDK();
