export const delay = (t) => new Promise(res => setTimeout(() => res(), t));

export const titleCase = (str: string) => {
  // eslint-disable-next-line no-param-reassign
  str = str.toLowerCase();
  const array = str.split(' ');
  for (let i = 0; i < array.length; i++) {
    array[i] = array[i].charAt(0).toUpperCase() + array[i].substring(1);
  }
  const finalString = array.join(' ');
  return finalString;
};

export const objectFilterByValue = (obj, val) => {
  const scoreArr = Object.entries(obj);
  // eslint-disable-next-line no-unused-vars
  const filteredArr = scoreArr.filter(([key, value]) => value !== val);
  const newScore = Object.fromEntries(filteredArr);
  return newScore;
};

export const isNumeric = (str) => {
  if (typeof str !== 'string') return false; // we only process strings!
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    // eslint-disable-next-line no-restricted-globals
    && !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};

export const generateSearchQuery = (request, search) => {
  if (isNumeric(search)) {
    request.userId = search;
  } else {
    request.name = search;
  }
  return request;
};

export const compare = (key) => (a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const isEmptyObj = (obj) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const toBoolean = (bool) => bool !== undefined && bool.toString() === 'true';

export const spaceBeforeCapitalLetter = (str) => str.replace(/([A-Z])/g, ' $1');
