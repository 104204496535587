import type {
  ChiSquareAlertCloseReviewState,
  ChiSquareAlertReviewState,
  ChiSquareAlertsState,
  ChiSquareTablesState
} from 'modules/LCRMT/Alerts/types';

const InitialState = {
  status: null,
  error: null,
  data: null
};

const createChiSquareAlertsInitialState = (): ChiSquareAlertsState => InitialState;
const additionalInfoInitialState = (): ChiSquareAlertsState => InitialState;
const createChiSquareTablesInitialState = (): ChiSquareTablesState => InitialState;
const createAlertReviewInitialState = (): ChiSquareAlertReviewState => InitialState;
const createCloseAlertReviewInitialState = (): ChiSquareAlertCloseReviewState => ({ status: null, error: null });

export {
  createCloseAlertReviewInitialState,
  createChiSquareAlertsInitialState,
  createChiSquareTablesInitialState,
  createAlertReviewInitialState,
  additionalInfoInitialState
};
