/**
 * @flow
 * */

import { takeLatest, takeEvery, put, all } from 'redux-saga/effects';
import USER_SETTINGS_ACTION_TYPES from 'store/actions/userSettings/types';
import {
  setUserSettingsSuccess, setUserSettingsFailure,
  setFetchUserSettingsSuccess, setFetchUserSettingsFailure
} from 'store/actions/userSettings/actions';

import userSettingsSDK from '../sdk/settings/UserSettingsSDK';

class UserSettingsController {
  static* setUserSettings({ payload }) {
    const { name, scheme } = payload;
    try {
      const { data } = yield userSettingsSDK.updateUserSettings({ name, scheme });
      yield put(setUserSettingsSuccess({ data: data.Scheme, name }));
    } catch (err) {
      yield put(setUserSettingsFailure({ error: err.message, name }));
    }
  }

  static* fetchUserSettings({ payload }) {
    const { name } = payload;
    try {
      const { data } = yield userSettingsSDK.getUserSettings({ name });
      yield all(name.map(item => {
        const currentRow = data.find(i => i.Name === item.toLowerCase());
        return put(setFetchUserSettingsSuccess({ data: currentRow?.Scheme || null, name: item }));
      }));
    } catch (err) {
      yield all(name.map(item => put(setFetchUserSettingsFailure({ error: err.message, name: item }))));
    }
  }

  static* userWatcher<T>(): Iterable<T> {
    yield takeLatest(USER_SETTINGS_ACTION_TYPES.SET_USER_SETTINGS, UserSettingsController.setUserSettings);
    yield takeEvery(USER_SETTINGS_ACTION_TYPES.FETCH_USER_SETTINGS, UserSettingsController.fetchUserSettings);
  }
}

export default UserSettingsController.userWatcher;
