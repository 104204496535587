import { put, takeLatest, takeEvery } from 'redux-saga/effects';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import {
  setFetchPartnerAlertSettingsSuccess, setFetchPartnerAlertSettingsFailure,
  setUpdatePartnerAlertSettingsSuccess, setUpdatePartnerAlertSettingsFailure,
  setDeletePartnerAlertSettingsSuccess, setDeletePartnerAlertSettingsFailure,
  addPartnerAlertSettingsRule, setFetchEnabledPartnersSuccess, setFetchEnabledPartnersFailure
} from 'modules/SRMT/Settings/store/actions/settings';

import partnerAlertSettingsSDK from '../sdk/PartnerAlertSettingsSDK';

class PartnerAlertSettingsController {
  static* fetchPartnerAlertSettings({ payload }) {
    const { partnerId } = payload;
    try {
      const { data } = yield partnerAlertSettingsSDK.getPartnerAlertSettings({ partnerId });
      yield put(setFetchPartnerAlertSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPartnerAlertSettingsFailure({ error: err.message }));
    }
  }

  static* updatePartnerAlertSettings({ payload }) {
    const { id, enabled, amount, count, partnerId, isNewRule } = payload;
    try {
      const { data } = yield partnerAlertSettingsSDK.updatePartnerAlertSettings({ id, enabled, amount, count, partnerId, isNewRule });
      yield put(setUpdatePartnerAlertSettingsSuccess({ id, data }));
      if (isNewRule) {
        yield put(addPartnerAlertSettingsRule({ data }));
      }
    } catch (err) {
      yield put(setUpdatePartnerAlertSettingsFailure({ error: err.message, id }));
    }
  }

  static* deletePartnerAlertSettings({ payload }) {
    const { id, partnerId } = payload;
    try {
      const { data } = yield partnerAlertSettingsSDK.deletePartnerAlertSettings({ id, partnerId });
      yield put(setDeletePartnerAlertSettingsSuccess({ data, id }));
    } catch (err) {
      yield put(setDeletePartnerAlertSettingsFailure({ error: err.message, id }));
    }
  }

  static* fetchEnabledPartners() {
    try {
      const { data } = yield partnerAlertSettingsSDK.getEnabledPartners();
      yield put(setFetchEnabledPartnersSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchEnabledPartnersFailure({ error: err.message }));
    }
  }

  static* cancelCategorySettingsSDKCalls({ payload }) {
    const { name } = payload;
    yield partnerAlertSettingsSDK.cancelPartnerSDKCalls({ name });
  }

  static* actionsWatcher() {
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_ENABLED_PARTNERS, PartnerAlertSettingsController.fetchEnabledPartners);
    yield takeLatest(SETTINGS_ACTION_TYPES.CANCEL_PARTNER_SDK_CALL, PartnerAlertSettingsController.cancelCategorySettingsSDKCalls);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_PARTNER_ALERT_SETTINGS, PartnerAlertSettingsController.fetchPartnerAlertSettings);
    yield takeEvery(SETTINGS_ACTION_TYPES.UPDATE_PARTNER_ALERT_SETTINGS, PartnerAlertSettingsController.updatePartnerAlertSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.DELETE_PARTNER_ALERT_SETTINGS, PartnerAlertSettingsController.deletePartnerAlertSettings);
  }
}

export default PartnerAlertSettingsController.actionsWatcher;
