import { redirectToBackOfficeSso } from 'lib/helpers/redirectTo';
import { permissions } from 'config/settings/permissions';

import BackofficeLogo from 'assets/icons/BackofficeLogo.svg';
import aml from 'assets/icons/AML&RG.svg';
import lcrmt from 'assets/icons/GRMT.svg';
import srmt from 'assets/icons/SRMT.svg';
import frmt from 'assets/icons/frmt.svg';
import crmt from 'assets/icons/crmt.svg';

export default [
  {
    id: 'srmt',
    main: true,
    path: '/srmt',
    text: 'SRMT',
    img: srmt,
    handler: null,
    permission: permissions.SRMTBox
  },
  {
    id: 'crmt',
    main: true,
    path: '/crmt',
    text: 'CRMT',
    img: crmt,
    handler: null,
    permission: permissions.CRMTBox
  },
  {
    id: 'lcrmt',
    main: true,
    path: '/lcrmt',
    text: 'LCRMT',
    img: lcrmt,
    handler: null,
    permission: permissions.LCRMTBox
  },
  {
    id: 'aml',
    main: true,
    path: '/aml',
    text: 'AML & RG',
    img: aml,
    handler: null,
    permission: permissions.AMLBox
  },
  {
    id: 'frmt',
    main: true,
    path: '/frmt',
    text: 'FRMT',
    img: frmt,
    handler: null,
    permission: permissions.FRMTBox
  },
  {
    id: 'backOffice',
    main: false,
    handler: redirectToBackOfficeSso,
    text: 'BackOffice',
    permission: null,
    path: '',
    icon: BackofficeLogo
  }
];
