/**
 * @flow
 * */
import React, { memo } from 'react';
import BusyLoader from '@geneui/components/BusyLoader';
import { busyLoader } from 'config/settings/core-ui-strings';

import './styles.scss';

export type Props = {
  isFillScreen?: boolean,
  center?: boolean
}

const Skeleton = ({ isFillScreen = false, center = false }: Props) => (
  <div className={`loader-holder ${isFillScreen ? 'full' : 'inline'} ${center ? 'center' : ''}`}>
    <BusyLoader isBusy spinnerSize={busyLoader.spinnerSize.big} />
  </div>
);

export default memo<Props>(Skeleton);
