const initialOnlyDataState = () => ({
  data: null
});

const initialSectionState = () => ({
  data: null,
  status: null,
  error: null
});

const initialSectionWithCountState = () => ({
  ...initialSectionState(),
  totalCount: null
});
const initialSectionWithoutData = () => ({
  status: null,
  error: null
});

const initialSectionWithAction = () => ({
  ...initialSectionWithCountState(),
  action: initialSectionWithoutData()
});

const initialSectionWithActionDelete = () => ({
  ...initialSectionWithAction(),
  delete: initialSectionWithoutData()
});

const initialSectionWithEditState = () => ({
  ...initialSectionWithCountState()
});

const initialKPITreeState = () => ({
  data: {
    root: null
  },
  status: null,
  error: null
});

export {
  initialSectionWithActionDelete,
  initialSectionWithCountState,
  initialSectionWithEditState,
  initialSectionWithoutData,
  initialSectionWithAction,
  initialOnlyDataState,
  initialSectionState,
  initialKPITreeState
};
