/* eslint-disable  no-mixed-operators */
import type { Resource } from '../core/sdk/types';

const initPermission = { Read: false, Update: false };
const typeConvertor = {
  'Read': 'Update',
  'Update': 'Read'
};

export const debounce = function (timeout?: number, handler: Function) {
  let timeOutId;

  return (value) => {
    if (timeout) {
      if (timeOutId) clearTimeout(timeOutId);

      timeOutId = setTimeout(() => {
        handler(value);
      }, timeout);
      return;
    }
    handler(value);
  };
};

export const detectIndeterminates = (data: Array<Resource>, isEdit) => data.map(item => ({
  ...item,
  ...isEdit ? {} : {
    Permissions: { ...initPermission }
  },
  ...item.ChildResource ? {
    ChildResource: detectIndeterminates(item.ChildResource, isEdit),
    indeterminate: { ...initPermission }
  } : {}
}));

const addAdditionalInfo = (data: Array<Resource>, isEdit) => [
  {
    Name: 'List of Permissions',
    Permissions: { ...initPermission },
    indeterminate: { ...initPermission },
    ConfigurableActions: ['Read', 'Update'],
    ResourceId: 0,
    ChildResource: detectIndeterminates(data, isEdit)
  }
];

const getStairs = (data: Array<Resource>) => {
  const stair = [];
  const starRecursiveFunction = (data, stairs) => {
    data.forEach(item => {
      const newStairs = [...stairs, item.ResourceId];
      if (!item.ResourceId || Object.values(item.Permissions).some(i => i)) {
        const checkExisting = [...newStairs];
        if (checkExisting.length > 3) {
          checkExisting.pop();
          const existingItem = stair.findIndex(i => JSON.stringify(i) === JSON.stringify(checkExisting));
          if (existingItem) {
            stair.splice(existingItem, 1);
          }
        }
        stair.push(newStairs);
        if (item.ChildResource) {
          starRecursiveFunction(item.ChildResource, newStairs);
        }
      }
    });
  };
  starRecursiveFunction(data, []);
  stair.shift();
  return stair;
};

const parentDetectWithExistingData = (data, stairs) => {
  if (stairs.length > 1) {
    stairs.pop();
    // eslint-disable-next-line no-use-before-define
    const newData = recursiveFindElem(data, [...stairs]);
    ['Read', 'Update'].forEach(key => {
      const every = newData.ChildResource.every(i => !i.ConfigurableActions.includes(key)
                || !i.indeterminate && i.Permissions[key]
                || i.indeterminate && i.Permissions[key] && !i.indeterminate[key]);
      const some = newData.ChildResource.some(i => i.Permissions[key] && i.ConfigurableActions.includes(key));
      if (every) {
        newData.indeterminate[key] = false;
        newData.Permissions[key] = true;
      } else if (some) {
        newData.Permissions[key] = true;
        newData.indeterminate[key] = true;
      } else {
        newData.Permissions[key] = false;
        newData.indeterminate[key] = false;
      }
    });
    parentDetectWithExistingData(data, stairs);
  }
};

const editChanges = (data: Array<Resource>) => {
  const stairs = getStairs(data);
  if (stairs.length) {
    stairs.forEach(stair => parentDetectWithExistingData(data, stair));
  }
};

export const makeResources = (data: Array<Resource>, isEdit) => {
  const withAdditional = addAdditionalInfo(data, isEdit);
  if (isEdit) {
    editChanges(withAdditional);
  }
  return withAdditional;
};

const recursiveFindElem = (data, stairs) => {
  if (stairs.length) {
    const stair = stairs[0];
    stairs.shift();
    const item = data.find(i => i.ResourceId === stair);
    if (item?.ChildResource && stairs.length) {
      return recursiveFindElem(item.ChildResource, stairs);
    }
    return item;
  }
  return data;
};

const childDetect = (data: Object, key, checked) => {
  // eslint-disable-next-line no-param-reassign
  data.Permissions[key] = checked;

  if (key === 'Update' && checked) {
    // eslint-disable-next-line no-param-reassign
    data.Permissions.Read = checked;
  } else if (key === 'Read' && !checked) {
    // eslint-disable-next-line no-param-reassign
    data.Permissions.Update = checked;
  }

  if (data.ChildResource) {
    if (key === 'Update' && checked) {
      // eslint-disable-next-line no-param-reassign
      data.indeterminate.Read = false;
    }
    if (key === 'Read' && !checked) {
      // eslint-disable-next-line no-param-reassign
      data.indeterminate.Update = false;
    }

    data.ChildResource.forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.Permissions[key] = checked;
      if (!checked && item.indeterminate) {
        // eslint-disable-next-line no-param-reassign
        item.indeterminate[key] = false;
      }
      childDetect(item, key, checked);
    });
  }
};

const parentDetect = (data, stairs, key, checked) => {
  if (stairs.length > 1) {
    stairs.pop();
  }
  const newData = recursiveFindElem(data, [...stairs]);
  const every = newData.ChildResource.every(i => !i.ConfigurableActions.includes(key)
           || !i.indeterminate && i.Permissions[key] === checked
           || i.indeterminate && i.Permissions[key] === checked && i.indeterminate[key] !== checked);
  const some = newData.ChildResource.some(i => i.Permissions[key] === true && i.ConfigurableActions.includes(key));

  if (every) {
    newData.indeterminate[key] = false;
    newData.Permissions[key] = checked;
  } else if (some) {
    newData.Permissions[key] = true;
    newData.indeterminate[key] = true;
  } else {
    newData.Permissions[key] = false;
    newData.indeterminate[key] = false;
  }
  if (stairs.length > 1) {
    parentDetect(data, stairs, key, checked);
  }
};

export const makeChanges = (data, stairs, key, checked) => {
  const newData = recursiveFindElem(data, [...stairs]);
  if (!checked && newData.indeterminate) {
    newData.indeterminate[key] = false;
  }
  childDetect(newData, key, checked, [...stairs]);
  parentDetect(data, [...stairs], typeConvertor[key], newData.Permissions[typeConvertor[key]]);
  parentDetect(data, [...stairs], key, checked);

  return [...data];
};

const findAndSetPermissions = (resources, roleResources) => resources.map(item => {
  const hasChange = roleResources.find(i => i.ResourceId === item.ResourceId);
  return {
    ...item,
    Permissions: {
      ...initPermission,
      ...hasChange ? hasChange.Permissions : {}
    },
    ...item.ChildResource ? {
      ChildResource: findAndSetPermissions(item.ChildResource, roleResources)
    } : {}
  };
});

export const mergeResources = (allResources: Array<Resource>, roleResources: Array<Resource>) => findAndSetPermissions(allResources, roleResources);

export const detectResourceChanges = (currentResource: Array<Resource>, defaultResource: Array<Resource>, anyChanges = false) => {
  // eslint-disable-next-line no-use-before-define
  const changes = makeChangeArray(currentResource, defaultResource, [], {
    added: [],
    deleted: []
  }, anyChanges);
  return changes;
};

const addChangedPermission = (key, value, resourceId, changes) => {
  const changeType = value ? 'added' : 'deleted';
  const change = changes[changeType].find(i => i.ResourceId === resourceId);
  if (change) {
    change.Permissions[key] = value;
  } else {
    changes[changeType].push({
      ResourceId: resourceId,
      Permissions: {
        [key]: value
      }
    });
  }
  return changes;
};

const makeChangeArray = function (resources, defaultResource, stairs, changes, anyChanges) {
  // eslint-disable-next-line consistent-return
  resources.forEach(item => {
    const newStairs = [...stairs, item.ResourceId];
    const defaultRow = recursiveFindElem(defaultResource, newStairs);
    if (item.ResourceId) {
      Object.entries(item.Permissions).forEach(permission => {
        if ((!anyChanges && item.Permissions[permission[0]] !== defaultRow.Permissions[permission[0]] || anyChanges && item.Permissions[permission[0]]) && defaultRow.ConfigurableActions.includes(permission[0])) {
          // eslint-disable-next-line no-param-reassign
          changes = addChangedPermission(permission[0], item.Permissions[permission[0]], item.ResourceId, changes);
        }
      });
    }
    if (item.ChildResource) {
      return makeChangeArray(item.ChildResource, defaultRow.ChildResource, newStairs, changes, anyChanges);
    }
  });
  return changes;
};

export const makeChildrenData = (resources, resource, newData) => {
  if (!newData.length) return false;
  for (let i = 0; i < newData.length; i++) {
    if (resource.ParentId === newData[i].ResourceId) {
      if (resources.filter(item => resource.Id === item.ParentId).length) {
        newData[i].ChildResource.push({
          Name: resource.Name,
          ResourceId: resource.Id,
          ConfigurableActions: ['Read', 'Update'],
          ChildResource: []
        });
      } else {
        newData[i].ChildResource.unshift({
          Name: resource.Name,
          ResourceId: resource.Id,
          ConfigurableActions: ['Read', 'Update'],
          ChildResource: []
        });
      }
      return true;
    }
    if (makeChildrenData(resources, resource, newData[i].ChildResource)) {
      return true;
    }
  }
  return false;
};

const recursiveResource = (resources, newData) => {
  resources.forEach((resource, index) => {
    if (makeChildrenData(resources, resource, newData)) {
      resources.splice(index, 1);
    }
  });

  if (resources.length) {
    recursiveResource(resources, newData);
  }
};

export const makeDefaultData = (resources) => {
  const newData = [];
  const ids = [];
  resources.forEach((i) => {
    if (!i.ParentId) {
      ids.push(i.Id);
      newData.push({
        Name: i.Name,
        ResourceId: i.Id,
        ConfigurableActions: ['Read', 'Update'],
        ChildResource: []
      });
    }
  });
  // eslint-disable-next-line no-param-reassign
  resources = resources.filter(item => !ids.includes(item.Id));
  recursiveResource(resources, newData);
  return newData;
};

const currentState = (resource, newData) => {
  resource.forEach(item => {
    if (!item.ResourceId) {
      currentState(item.ChildResource, newData);
    } else {
      newData.push({
        Name: item.Name,
        Permissions: item.Permissions,
        ResourceId: item.ResourceId
      });
      if (item.ChildResource.length) {
        currentState(item.ChildResource, newData);
      }
    }
  });
};

export const makeCurrentState = (resource) => {
  const newData = [];
  currentState(resource, newData);
  return newData;
};
