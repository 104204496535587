import type { Action as BaseAction } from 'lib/core/flow/types';

import ACTION_TYPES from './types';

export const fetchDemo = (payload): BaseAction => ({
  type: ACTION_TYPES.FETCH_DEMO,
  payload
});

export const setDemoFailed = (error: string): BaseAction => ({
  type: ACTION_TYPES.SET_DEMO_FAILED,
  payload: error
});

export const setDemoSuccess = (): BaseAction => ({
  type: ACTION_TYPES.SET_DEMO_SUCCEED
});

export const clearDemo = (): BaseAction => ({
  type: ACTION_TYPES.CLEAR_DEMO
});
