/**
 * @flow
 * */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getPermissionData } from '../store/selectors/permissionSelectors';
import menuItems from '../config/nav';
import { userSelector } from '../modules/Dashboard/Auth/store/selectors/userSelectors';

type Props = string | Array<string>
/**
 * useAmlLicense
 */

const usePermission = () => {
  const state = useSelector(getPermissionData);
  const userState = useSelector(userSelector);

  const checkPermission = useCallback((type: string, name: Props) => {
    if (typeof name === 'string') {
      return state.some(item => item.Name === name && item.Permissions[type]);
    }
    return name.every(item => {
      const permission = state.find(i => i.Name === item);
      return permission && permission.Permissions[type];
    });
  }, [state]);

  const hasRead = useCallback((name: Props) => checkPermission('Read', name), [checkPermission]);

  const hasUpdate = useCallback((name: Props) => checkPermission('Update', name), [checkPermission]);

  const hasSomeRead = useCallback((name: Array<string>) => {
    if (typeof name === 'string') {
      return state.some(item => item.Name === name && item.Permissions.Read);
    }
    return name.some(item => {
      const permission = state.find(i => i.Name === item);
      return permission && permission.Permissions.Read;
    });
  }, [state]);

  const hasEveryRead = useCallback((name: Array<string>) => {
    if (typeof name === 'string') {
      return state.every(item => item.Name === name && item.Permissions.Read);
    }
    return name.every(item => {
      const permission = state.find(i => i.Name === item);
      return permission && permission.Permissions.Read;
    });
  }, [state]);

  // $FlowFixMe
  const allHaveReadablePermission = useCallback((name: Array<string>) => name.filter(item => {
    const permission = state.find(i => i.Name === item);
    return permission && permission.Permissions.Read;
  }), [state]);

  const getFirstActiveTabUrl = useCallback((type: string) => {
    const menuItem = menuItems[type]();
    if (menuItem && menuItem.length) {
      for (let i = 0; i < menuItem.length; i++) {
        if (hasSomeRead(menuItem[i].permission)) {
          return menuItem[i].id;
        }
      }
    }
    return '';
  }, [hasSomeRead]);

  const moreThenOneAreReadable = useCallback((name: Array<string>) => allHaveReadablePermission(name).length > 1, [allHaveReadablePermission]);

  const hasAgpPermission = useCallback((name: string) => !!(userState?.PermissionList || []).find(i => i === name), [userState]);

  return {
    hasRead,
    hasUpdate,
    hasSomeRead,
    hasEveryRead,
    hasAgpPermission,
    allHaveReadablePermission,
    moreThenOneAreReadable,
    getFirstActiveTabUrl
  };
};

export default usePermission;
