/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import type { AmlDataRequest, DeactivatePartnerRequest, SrmtDataRequest } from 'modules/Admin/PartnerLicenseManagement/types';
import ACTIONS from './types';

// Partner Management List
export const fetchPartnerManagement = ({ start, count, passTotalCount, ...filters }: any): Action => ({
  type: ACTIONS.FETCH_MANAGEMENT_LIST,
  payload: { start, count, passTotalCount, filters }
});
export const fetchPartnerManagementSuccess = ({ data, totalCount, passTotalCount }: any): Action => ({
  type: ACTIONS.FETCH_MANAGEMENT_LIST_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const fetchPartnerManagementFailure = (error:string): Action => ({
  type: ACTIONS.FETCH_MANAGEMENT_LIST_FAILURE,
  payload: { error }
});
export const clearPartnerManagementState = (): Action => ({
  type: ACTIONS.CLEAR_MANAGEMENT_LIST
});

// Partner/License Management
export const setPartnerLicenseManagement = ({ data }: any): Action => ({
  type: ACTIONS.FETCH_PARTNERLICENSE_MANAGEMENT,
  payload: { data }
});
export const clearPartnerLicenseManagementState = (): Action => ({
  type: ACTIONS.CLEAR_PARTNERLICENSE_MANAGEMENT
});

// Partner Management Selected Row
export const setPartnerSelectedRow = ({ data }: any): Action => ({
  type: ACTIONS.FETCH_PARTNER_MANAGEMENT_SELECTED_ROW,
  payload: { data }
});

// cancel call
export const cancelPMCall = ({ name }:any): Action => ({
  type: ACTIONS.CANCEL_PM_CALL,
  payload: { name }
});

// partners
export const fetchPartners = () => ({
  type: ACTIONS.FETCH_PARTNERS
});
export const setFetchPartnersSuccess = ({ data }: any) => ({
  type: ACTIONS.SET_PARTNERS_SUCCESS,
  payload: { data }
});
export const setFetchPartnersFailure = (error: string) => ({
  type: ACTIONS.SET_PARTNERS_FAILURE,
  payload: { error }
});
export const clearPartners = () => ({
  type: ACTIONS.CLEAR_PARTNERS_STATE
});

// deactivate partner
export const fetchDeactivatePartner = ({ partnerId, productType }: DeactivatePartnerRequest) => ({
  type: ACTIONS.FETCH_DEACTIVATE_PARTNER,
  payload: { partnerId, productType }
});
export const setFetchDeactivatePartnerSuccess = () => ({
  type: ACTIONS.SET_DEACTIVATE_PARTNER_SUCCESS
});
export const setFetchDeactivatePartnerFailure = (error: string) => ({
  type: ACTIONS.SET_DEACTIVATE_PARTNER_FAILURE,
  payload: { error }
});
export const clearDeactivatePartner = () => ({
  type: ACTIONS.CLEAR_DEACTIVATE_PARTNER_STATE
});

// srmt data
export const fetchSrmtData = ({ id, forPartnerId, templateType }: SrmtDataRequest) => ({
  type: ACTIONS.FETCH_SRMT_DATA,
  payload: { id, forPartnerId, templateType }
});
export const setFetchSrmtDataSuccess = ({ data }: any) => ({
  type: ACTIONS.SET_SRMT_DATA_SUCCESS,
  payload: { data }
});
export const setFetchSrmtDataFailure = (error: string) => ({
  type: ACTIONS.SET_SRMT_DATA_FAILURE,
  payload: { error }
});
export const clearSrmtData = () => ({
  type: ACTIONS.CLEAR_SRMT_DATA_STATE
});

// srmt templates
export const fetchTemplates = () => ({
  type: ACTIONS.FETCH_TEMPLATES
});
export const setFetchTemplatesSuccess = ({ data }: any) => ({
  type: ACTIONS.SET_TEMPLATES_SUCCESS,
  payload: { data }
});
export const setFetchTemplatesFailure = (error: string) => ({
  type: ACTIONS.SET_TEMPLATES_FAILURE,
  payload: { error }
});
export const clearTemplates = () => ({
  type: ACTIONS.CLEAR_TEMPLATES_STATE
});

// aml templates
export const fetchAmlTemplates = () => ({
  type: ACTIONS.FETCH_AML_TEMPLATES
});
export const setFetchAmlTemplatesSuccess = ({ data }: any) => ({
  type: ACTIONS.SET_AML_TEMPLATES_SUCCESS,
  payload: { data }
});
export const setFetchAmlTemplatesFailure = (error: string) => ({
  type: ACTIONS.SET_AML_TEMPLATES_FAILURE,
  payload: { error }
});
export const clearAmlTemplates = () => ({
  type: ACTIONS.CLEAR_AML_TEMPLATES_STATE
});

// aml data
export const fetchAmlData = ({ id, templateType, fromAml, playerSettings = false }: AmlDataRequest) => ({
  type: ACTIONS.FETCH_AML_DATA,
  payload: { id, templateType, fromAml, playerSettings }
});
export const setFetchAmlDataSuccess = ({ data }: any) => ({
  type: ACTIONS.SET_AML_DATA_SUCCESS,
  payload: { data }
});
export const setFetchAmlDataFailure = (error: string) => ({
  type: ACTIONS.SET_AML_DATA_FAILURE,
  payload: { error }
});
export const clearAmlData = () => ({
  type: ACTIONS.CLEAR_AML_DATA_STATE
});

// all aml settings
export const fetchAllAMLSettings = () => ({
  type: ACTIONS.FETCH_ALL_AML_SETTINGS
});
export const setFetchAllAMLSuccess = ({ data }: any) => ({
  type: ACTIONS.SET_ALL_AML_SUCCESS,
  payload: { data }
});
export const setFetchAllAMLFailure = (error: string) => ({
  type: ACTIONS.SET_ALL_AML_FAILURE,
  payload: { error }
});
export const clearAllAML = () => ({
  type: ACTIONS.CLEAR_ALL_AML_STATE
});

// all aml settings
export const fetchRiskTypesSettings = () => ({
  type: ACTIONS.FETCH_RISK_TYPE_SETTINGS
});
export const setFetchRiskTypesSuccess = ({ data }: any) => ({
  type: ACTIONS.SET_RISK_TYPE_SUCCESS,
  payload: { data }
});
export const setFetchRiskTypesFailure = (error: string) => ({
  type: ACTIONS.SET_RISK_TYPE_FAILURE,
  payload: { error }
});
export const clearRiskTypes = () => ({
  type: ACTIONS.CLEAR_RISK_TYPE_STATE
});

// set Changed Data
export const setChangedData = ({ data, fromAml = false, playerSettings = false }: Object) => ({
  type: ACTIONS.SET_CHANGED_DATA,
  payload: { data, fromAml, playerSettings }
});
export const setChangedDataSuccess = () => ({
  type: ACTIONS.SET_CHANGED_DATA_SUCCESS
});
export const setChangedDataFailure = (error: string) => ({
  type: ACTIONS.SET_CHANGED_DATA_FAILURE,
  payload: { error }
});
export const clearChangedData = () => ({
  type: ACTIONS.CLEAR_CHANGED_DATA_STATE
});
