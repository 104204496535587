/**
 * @flow
 * */
import { combineReducers } from 'redux';

import notificationController from 'modules/Dashboard/Auth/core/controllers/notificationController';
import howToController from 'modules/Dashboard/Auth/core/controllers/howToController';
import authController from 'modules/Dashboard/Auth/core/controllers/authController';

import { DASHBOARD_ROUTES } from './Auth/routes';

import auth from './Auth/store/reducers';

const getProjectState = (state: Object) => state.DASHBOARD;

const routes = [
  ...DASHBOARD_ROUTES
];

const controllers = [
  authController(),
  howToController(),
  notificationController()
];

const reducers = combineReducers({
  ...auth
});

export {
  getProjectState,
  controllers,
  reducers,
  routes
};
