/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import ACTIONS from 'modules/LCRMT/Reports/store/actions/reports/types';
import reportsSDK from 'modules/LCRMT/Reports/core/sdk/ReportsSDK';
import {
  setFetchLifeTimeChiSquareReportsSuccess,
  setFetchLifeTimeChiSquareReportsFailure,
  setFetchLifePlayersRoundsReportsFailure,
  setFetchLifePlayersBetsReportsFailure,
  setFetchPlayersRoundsReportsSuccess,
  setFetchPartnersInfoReportsSuccess,
  setFetchPartnersInfoReportsFailure,
  setFetchPlayersBetsReportsSuccess,
  setFetchChiSquareReportsSuccess,
  setFetchChiSquareReportsFailure
} from 'modules/LCRMT/Reports/store/actions/reports';

class ReportsController {
  static* fetchReports({ payload }) {
    const { count, start, filters } = payload;
    try {
      const { data, totalCount } = yield reportsSDK.getReports({ count, start, filters });
      yield put(setFetchChiSquareReportsSuccess({ data, totalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchChiSquareReportsFailure({ error: err.message }));
    }
  }

  static* fetchLifeTimeReports({ payload }) {
    const { filters } = payload;
    try {
      const { data } = yield reportsSDK.getLifeTimeReports({ filters });
      yield put(setFetchLifeTimeChiSquareReportsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchLifeTimeChiSquareReportsFailure({ error: err.message }));
    }
  }

  static* fetchPlayersBetsReports({ payload }) {
    const { count, start, filters, passTotalCount } = payload;
    try {
      const { data, totalCount } = yield reportsSDK.getPlayersBetsReports({ count, start, filters, passTotalCount });
      yield put(setFetchPlayersBetsReportsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchLifePlayersBetsReportsFailure({ error: err.message }));
    }
  }

  static* fetchPlayersRoundsReports({ payload }) {
    const { count, start, filters, passTotalCount } = payload;
    try {
      const { data, totalCount } = yield reportsSDK.getPlayersRoundsReports({ count, start, filters, passTotalCount });
      yield put(setFetchPlayersRoundsReportsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchLifePlayersRoundsReportsFailure({ error: err.message }));
    }
  }

  static* fetchPartnersInfoReports({ payload }) {
    const { count, start, filters, passTotalCount } = payload;
    try {
      const { data, totalCount } = yield reportsSDK.getPartnersInfoReports({ count, start, filters, passTotalCount });
      yield put(setFetchPartnersInfoReportsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPartnersInfoReportsFailure({ error: err.message }));
    }
  }

  static* cancelReportSDKCalls({ payload }) {
    const { name } = payload;
    yield reportsSDK.cancelReportSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.FETCH_LIFE_TIME_CHI_SQUARE_REPORTS, ReportsController.fetchLifeTimeReports);
    yield takeLatest(ACTIONS.CANCEL_REPORT_SDK_CALL, ReportsController.cancelReportSDKCalls);
    yield takeLatest(ACTIONS.FETCH_CHI_SQUARE_REPORTS, ReportsController.fetchReports);
    yield takeLatest(ACTIONS.FETCH_PLAYERS_BETS_REPORT, ReportsController.fetchPlayersBetsReports);
    yield takeLatest(ACTIONS.FETCH_PLAYERS_ROUNDS_REPORT, ReportsController.fetchPlayersRoundsReports);
    yield takeLatest(ACTIONS.FETCH_PARTNERS_INFO_REPORT, ReportsController.fetchPartnersInfoReports);
  }
}

export default ReportsController.actionsWatcher;
