import { STATIC_ROUTES } from 'modules/Dashboard/Auth/routes';
import MODULE_ROUTES from '../modules/routes';

type Route = {
  path: string,
  key: string,
  exact: boolean,
  component: Function,
  subRoutes?: Array<Route>
};

export const staticRoutes: Array<Route> = [
  ...STATIC_ROUTES
];

export const routes: Array<Route> = [
  ...MODULE_ROUTES
];
