export const timezone = [
  {
    'DisplayName': 'UTC−12:00',
    'Id': -12,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−11:00',
    'Id': -11,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−10:00',
    'Id': -10,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−09:30',
    'Id': -9.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−09:00',
    'Id': -9,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−08:00',
    'Id': -8,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−07:00',
    'Id': -7,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−06:00',
    'Id': -6,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−05:00',
    'Id': -5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−04:30',
    'Id': -4.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−04:00',
    'Id': -4,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−03:30',
    'Id': -3.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−03:00',
    'Id': -3,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−02:00',
    'Id': -2,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−01:00',
    'Id': -1,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC−00:00',
    'Id': 0,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+01:00',
    'Id': 1,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+02:00',
    'Id': 2,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+03:00',
    'Id': 3,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+03:30',
    'Id': 3.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+04:00',
    'Id': 4,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+04:30',
    'Id': 4.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+05:00',
    'Id': 5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+05:30',
    'Id': 5.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+06:00',
    'Id': 6,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+06:30',
    'Id': 6.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+07:00',
    'Id': 7,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+08:00',
    'Id': 8,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+08:45',
    'Id': 8.75,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+09:00',
    'Id': 9,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+09:30',
    'Id': 9.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+10:00',
    'Id': 10,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+10:30',
    'Id': 10.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+11:00',
    'Id': 11,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+11:30',
    'Id': 11.5,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+12:00',
    'Id': 12,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+12:45',
    'Id': 12.75,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+13:00',
    'Id': 13,
    'IsSelected': false
  },
  {
    'DisplayName': 'UTC+14:00',
    'Id': 14,
    'IsSelected': false
  }
];
