/**
 * @flow
 * */
import { initialSectionState } from 'store/reducers/initialStates';
import type { PlayerState } from 'modules/SRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import PLAYER_TYPES from 'store/actions/player/types';
import PLAYER_ACTION_TYPES from '../actions/player/types';

const initialState = {
  addComment: initialSectionState(),
  noteComments: initialSectionState(),
  clientNote: initialSectionState(),
  agpComments: initialSectionState(),
  addFiles: initialSectionState(),
  getFiles: initialSectionState(),
  deleteFiles: initialSectionState()
};

const actionManager = {
  // Notes Comments
  [PLAYER_ACTION_TYPES.FETCH_NOTE_COMMENTS]: (state: PlayerState): PlayerState => ({
    ...state,
    noteComments: {
      ...state.noteComments,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_NOTE_COMMENTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    noteComments: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: payload.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_NOTE_COMMENTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    noteComments: {
      ...state.noteComments,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_NOTE_COMMENTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    noteComments: initialSectionState()
  }),
  // Notes Client Note
  [PLAYER_ACTION_TYPES.FETCH_CLIENT_NOTE]: (state: PlayerState): PlayerState => ({
    ...state,
    clientNote: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_CLIENT_NOTE_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    clientNote: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_CLIENT_NOTE_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    clientNote: {
      ...state.clientNote,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_CLIENT_NOTE_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    clientNote: initialSectionState()
  }),
  // Player Add Comment
  [PLAYER_ACTION_TYPES.ADD_COMMENT]: (state: PlayerState): PlayerState => ({
    ...state,
    addComment: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_ADD_COMMENT_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    addComment: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [PLAYER_ACTION_TYPES.SET_ADD_COMMENT_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    addComment: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_ADD_COMMENT_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    addComment: initialSectionState()
  }),
  // AGP Client Note
  [PLAYER_ACTION_TYPES.FETCH_AGP_COMMENTS]: (state: PlayerState): PlayerState => ({
    ...state,
    agpComments: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_AGP_COMMENTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    agpComments: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_AGP_COMMENTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    agpComments: {
      ...state.agpComments,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_AGP_COMMENTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    agpComments: initialSectionState()
  }),

  // Client Note Attached files
  [PLAYER_TYPES.FETCH_PLAYER_FILES]: (state: PlayerState): PlayerState => ({
    ...state,
    addFiles: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_PLAYER_FILES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    addFiles: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_PLAYER_FILES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    addFiles: {
      ...state.addFiles,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_TYPES.CLEAR_PLAYER_FILES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    addFiles: initialSectionState()
  }),

  // Get Note Attached files
  [PLAYER_TYPES.FETCH_GET_SRMT_PLAYER_FILES]: (state: PlayerState): PlayerState => ({
    ...state,
    getFiles: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_GET_SRMT_PLAYER_FILES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    getFiles: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_GET_SRMT_PLAYER_FILES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    getFiles: {
      ...state.getFiles,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_TYPES.CLEAR_GET_SRMT_PLAYER_FILES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    getFiles: initialSectionState()
  }),

  // delete Note Attached files
  [PLAYER_TYPES.FETCH_DELETE_SRMT_PLAYER_FILES]: (state: PlayerState): PlayerState => ({
    ...state,
    deleteFiles: {
      status: ACTION_STATUSES.PENDING,
      error: null,
      data: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_DELETE_SRMT_PLAYER_FILES_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    deleteFiles: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_TYPES.SET_FETCH_DELETE_SRMT_PLAYER_FILES_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    deleteFiles: {
      ...state.deleteFiles,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_TYPES.CLEAR_DELETE_SRMT_PLAYER_FILES_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    deleteFiles: initialSectionState()
  })

};

export default {
  actionManager,
  initialState
};
