/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';

// Player SuspiciousActivity Cash Out
export const fetchPlayerFinancialCashOut = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_FINANCIAL_CASH_OUT,
  payload: { playerId }
});
export const setFetchPlayerFinancialCashOutSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_SUCCESS,
  payload: { data }
});
export const setFetchPlayerFinancialCashOutFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_CASH_OUT_FAILURE,
  payload: { error }
});
export const clearPlayerFinancialCashOutState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_FINANCIAL_CASH_OUT_STATE
});

// Player SuspiciousActivity KPIs
export const fetchPlayerFinancialKPIs = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_FINANCIAL_KPIS,
  payload: { playerId }
});
export const setFetchPlayerFinancialKPIsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_KPIS_SUCCESS,
  payload: { data }
});
export const setFetchPlayerFinancialKPIsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_KPIS_FAILURE,
  payload: { error }
});
export const clearPlayerFinancialKPIsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_FINANCIAL_KPIS_STATE
});

// PlayerBets
export const fetchPlayersBets = ({ appendData = false, count, start, passTotalCount, playerId, ConvertCurrencyType, ...filters }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYERS_BETS,
  payload: { appendData, count, start, passTotalCount, playerId, ConvertCurrencyType, ...filters }
});
export const setFetchPlayersBetsSuccess = ({ data, totalCount, passTotalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYERS_BETS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchPlayersBetsAppendSuccess = ({ data, totalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYERS_BETS_APPEND_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchPlayersBetsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYERS_BETS_FAILURE,
  payload: { error }
});
export const clearPlayersBetsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYERS_BETS_STATE
});

// expand row children data
export const fetchExpandBetsRowChildrenData = ({ betId }: Object) => ({
  type: PLAYER_ACTIONS.FETCH_EXPAND_BETS_ROW_CHILDREN_DATA,
  payload: { betId }
});
export const setExpandBetsRowChildrenDataSuccess = ({ betId, data }: Object) => ({
  type: PLAYER_ACTIONS.SET_EXPAND_BETS_ROW_CHILDREN_DATA_SUCCESS,
  payload: { betId, data }
});
export const setExpandBetsRowChildrenDataFailure = ({ betId, error }: Object) => ({
  type: PLAYER_ACTIONS.SET_EXPAND_BETS_ROW_CHILDREN_DATA_FAILURE,
  payload: { betId, error }
});
export const clearExpandBetsRowChildrenData = ({ betId }: Object) => ({
  type: PLAYER_ACTIONS.CLEAR_EXPAND_BETS_ROW_CHILDREN_DATA,
  payload: { betId }
});

// Player SuspiciousActivity Transactions
export const fetchPlayerFinancialTransactions = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_FINANCIAL_TRANSACTIONS,
  payload: { playerId }
});
export const setFetchPlayerFinancialTransactionsSuccess = ({ data, totalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchPlayerFinancialTransactionsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_TRANSACTIONS_FAILURE,
  payload: { error }
});
export const clearPlayerFinancialTransactionsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_FINANCIAL_TRANSACTIONS_STATE
});

// Player SuspiciousActivity Balances
export const fetchPlayerFinancialBalances = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_FINANCIAL_BALANCES,
  payload: { playerId }
});
export const setFetchPlayerFinancialBalancesSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_BALANCES_SUCCESS,
  payload: { data }
});
export const setFetchPlayerFinancialBalancesFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_FINANCIAL_BALANCES_FAILURE,
  payload: { error }
});
export const clearPlayerFinancialBalancesState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_FINANCIAL_BALANCES_STATE
});

// Player Force Calculation
export const fetchPlayerForceCalculation = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FORCE_PLAYER_FINANCIAL_CALCULATION,
  payload: { playerId }
});
export const setForcePlayerFinancialCalculationSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FORCE_PLAYER_FINANCIAL_CALCULATION_SUCCESS,
  payload: { data }
});
export const setForcePlayerFinancialCalculationFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FORCE_PLAYER_FINANCIAL_CALCULATION_FAILURE,
  payload: { error }
});
export const clearForcePlayerFinancialCalculationState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_FORCE_PLAYER_FINANCIAL_CALCULATION_STATE
});

// Player Change Calculation State
export const fetchPlayerChangeCalculationState = ({ state, playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.PLAYER_CHANGE_CALCULATION_STATE,
  payload: { state, playerId }
});
export const setPlayerChangeCalculationStateSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_PLAYER_CHANGE_CALCULATION_STATE_SUCCESS,
  payload: { data }
});
export const setPlayerChangeCalculationStateFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_PLAYER_CHANGE_CALCULATION_STATE_FAILURE,
  payload: { error }
});
export const clearPlayerChangeCalculationStateState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_CHANGE_CALCULATION_STATE_STATE
});
