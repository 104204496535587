/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import settings from 'config/constants';
import { Redirect } from 'react-router-dom';

const DashboardPage = React.lazy(() => import('./views/pages/Dashboard'));
const RoundInfo = React.lazy(() => import('./views/pages/RoundInfo'));

const subUrl = settings.PRODUCTS.LCRMT.path;

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}
const ROUTES: Array<Route> = [
  { path: `/${subUrl}`, name: 'Dashboard', exact: true, page: () => <Redirect to={`/${subUrl}/dashboard`} /> },
  { path: `/${subUrl}/dashboard`, name: 'Dashboard', exact: true, page: DashboardPage },
  { path: '/round-info', name: 'Round Info', exact: true, page: RoundInfo }
];

export default ROUTES;
