import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createAlertsSymbol = createSymbol('CHI_SQUARE_ALERTS');
const createAlertsInternalSymbol = createInternalSymbol('CHI_SQUARE_ALERTS');

export default {
  // alerts
  FETCH_CHI_SQUARE_ALERTS: createAlertsSymbol('FETCH_CHI_SQUARE_ALERTS'),
  SET_FETCH_CHI_SQUARE_ALERTS_SUCCESS: createAlertsSymbol('SET_FETCH_CHI_SQUARE_ALERTS_SUCCESS'),
  SET_FETCH_CHI_SQUARE_ALERTS_FAILURE: createAlertsSymbol('SET_FETCH_CHI_SQUARE_ALERTS_FAILURE'),
  CLEAR_CHI_SQUARE_ALERTS_STATE: createAlertsInternalSymbol('CLEAR_CHI_SQUARE_ALERTS_STATE'),
  // alert reviews
  FETCH_ALERT_REVIEWS: createAlertsSymbol('FETCH_ALERT_REVIEWS'),
  SET_FETCH_ALERT_REVIEWS_SUCCESS: createAlertsSymbol('SET_FETCH_ALERT_REVIEWS_SUCCESS'),
  SET_FETCH_ALERT_REVIEWS_FAILURE: createAlertsSymbol('SET_FETCH_ALERT_REVIEWS_FAILURE'),
  CLEAR_ALERT_REVIEWS_STATE: createAlertsInternalSymbol('CLEAR_ALERT_REVIEWS_STATE'),
  // close alert review
  CLOSE_ALERT_REVIEW: createAlertsSymbol('CLOSE_ALERT_REVIEW'),
  SET_CLOSE_ALERT_REVIEW_SUCCESS: createAlertsSymbol('SET_CLOSE_ALERT_REVIEW_SUCCESS'),
  SET_CLOSE_ALERT_REVIEW_FAILURE: createAlertsSymbol('SET_CLOSE_ALERT_REVIEW_FAILURE'),
  CLEAR_CLOSE_REVIEW_STATE: createAlertsInternalSymbol('CLEAR_CLOSE_REVIEW_STATE'),
  // resolve alert review
  RESOLVE_ALERT_REVIEW: createAlertsSymbol('RESOLVE_ALERT_REVIEW'),
  SET_RESOLVE_ALERT_REVIEW_SUCCESS: createAlertsSymbol('SET_RESOLVE_ALERT_REVIEW_SUCCESS'),
  SET_RESOLVE_ALERT_REVIEW_FAILURE: createAlertsSymbol('SET_RESOLVE_ALERT_REVIEW_FAILURE'),
  CLEAR_RESOLVE_REVIEW_STATE: createAlertsInternalSymbol('CLEAR_RESOLVE_REVIEW_STATE'),
  // alert reviews statistics
  FETCH_ALERT_REVIEWS_STATISTICS: createAlertsSymbol('FETCH_ALERT_REVIEWS_STATISTICS'),
  SET_FETCH_ALERT_REVIEWS_STATISTICS_SUCCESS: createAlertsSymbol('SET_FETCH_ALERT_REVIEWS_STATISTICS_SUCCESS'),
  SET_FETCH_ALERT_REVIEWS_STATISTICS_FAILURE: createAlertsSymbol('SET_FETCH_ALERT_REVIEWS_STATISTICS_FAILURE'),
  CLEAR_ALERT_REVIEWS_STATISTICS_STATE: createAlertsInternalSymbol('CLEAR_ALERT_REVIEWS_STATISTICS_STATE'),
  // update alert
  UPDATE_ALERT: createAlertsInternalSymbol('UPDATE_ALERT'),
  // cancel call
  CANCEL_ALERT_SDK_CALL: createAlertsSymbol('CANCEL_ALERT_SDK_CALL')
};
