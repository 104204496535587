import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createSettingsSymbol = createSymbol('SETTINGS');
const createSettingsInternalSymbol = createInternalSymbol('SETTINGS');

export default {
  // LicenseRulesContainer Settings
  FETCH_ADDITIONAL_SETTINGS: createSettingsSymbol('FETCH_ADDITIONAL_SETTINGS'),
  SET_FETCH_ADDITIONAL_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_ADDITIONAL_SETTINGS_SUCCESS'),
  SET_FETCH_ADDITIONAL_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_ADDITIONAL_SETTINGS_FAILURE'),
  CLEAR_ADDITIONAL_SETTINGS_STATE: createSettingsInternalSymbol('CLEAR_ADDITIONAL_SETTINGS_STATE'),
  CHANGE_ADDITIONAL_SETTINGS: createSettingsInternalSymbol('CHANGE_ADDITIONAL_SETTINGS'),

  // LicenseRulesContainer Category
  EDIT_ADDITIONAL_CATEGORY_SETTINGS: createSettingsSymbol('EDIT_ADDITIONAL_CATEGORY_SETTINGS'),
  EDIT_ADDITIONAL_CATEGORY_SETTINGS_SUCCESS: createSettingsSymbol('EDIT_ADDITIONAL_CATEGORY_SETTINGS_SUCCESS'),
  EDIT_ADDITIONAL_CATEGORY_SETTINGS_FAILURE: createSettingsSymbol('EDIT_ADDITIONAL_CATEGORY_SETTINGS_FAILURE'),
  CLEAR_ADDITIONAL_CATEGORY_SETTINGS_STATE: createSettingsInternalSymbol('CLEAR_ADDITIONAL_CATEGORY_SETTINGS_STATE'),

  // SuspiciousActivityContainer Settings
  FETCH_FINANCIAL_SETTINGS: createSettingsSymbol('FETCH_FINANCIAL_SETTINGS'),
  SET_FETCH_FINANCIAL_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_FINANCIAL_SETTINGS_SUCCESS'),
  SET_FETCH_FINANCIAL_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_FINANCIAL_SETTINGS_FAILURE'),
  CLEAR_FINANCIAL_SETTINGS_STATE: createSettingsInternalSymbol('CLEAR_FINANCIAL_SETTINGS_STATE'),

  // SuspiciousActivityContainer update
  FETCH_FINANCIAL_UPDATE: createSettingsSymbol('FETCH_FINANCIAL_UPDATE'),
  SET_FETCH_FINANCIAL_UPDATE_SUCCESS: createSettingsSymbol('SET_FETCH_FINANCIAL_UPDATE_SUCCESS'),
  SET_FETCH_FINANCIAL_UPDATE_FAILURE: createSettingsSymbol('SET_FETCH_FINANCIAL_UPDATE_FAILURE'),
  CLEAR_FINANCIAL_UPDATE_STATE: createSettingsInternalSymbol('CLEAR_FINANCIAL_UPDATE_STATE'),

  // Game Style Settings
  FETCH_GAME_STYLE_SETTINGS: createSettingsSymbol('FETCH_GAME_STYLE_SETTINGS'),
  SET_FETCH_GAME_STYLE_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_GAME_STYLE_SETTINGS_SUCCESS'),
  SET_FETCH_GAME_STYLE_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_GAME_STYLE_SETTINGS_FAILURE'),
  CLEAR_GAME_STYLE_SETTINGS_STATE: createSettingsInternalSymbol('CLEAR_GAME_STYLE_SETTINGS_STATE'),

  // Game Style Settings Update
  UPDATE_GAME_STYLE_SETTINGS: createSettingsSymbol('UPDATE_GAME_STYLE_SETTINGS'),
  SET_UPDATE_GAME_STYLE_SETTINGS_SUCCESS: createSettingsSymbol('SET_UPDATE_GAME_STYLE_SETTINGS_SUCCESS'),
  SET_UPDATE_GAME_STYLE_SETTINGS_FAILURE: createSettingsSymbol('SET_UPDATE_GAME_STYLE_SETTINGS_FAILURE'),

  // Game Style Settings Update Priority
  UPDATE_GAME_STYLE_SETTINGS_PRIORITY: createSettingsSymbol('UPDATE_GAME_STYLE_SETTINGS_PRIORITY'),
  SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_SUCCESS: createSettingsSymbol('SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_SUCCESS'),
  SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_FAILURE: createSettingsSymbol('SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_FAILURE'),

  // Enabled Partners
  FETCH_ENABLED_PARTNERS: createSettingsSymbol('FETCH_ENABLED_PARTNERS'),
  SET_FETCH_ENABLED_PARTNERS_SUCCESS: createSettingsSymbol('SET_FETCH_ENABLED_PARTNERS_SUCCESS'),
  SET_FETCH_ENABLED_PARTNERS_FAILURE: createSettingsSymbol('SET_FETCH_ENABLED_PARTNERS_FAILURE'),
  CLEAR_ENABLED_PARTNERS_STATE: createSettingsInternalSymbol('CLEAR_ENABLED_PARTNERS_STATE'),

  // Change History
  FETCH_CHANGE_HISTORY: createSettingsSymbol('FETCH_CHANGE_HISTORY'),
  SET_FETCH_CHANGE_HISTORY_SUCCESS: createSettingsSymbol('SET_FETCH_CHANGE_HISTORY_SUCCESS'),
  SET_FETCH_CHANGE_HISTORY_FAILURE: createSettingsSymbol('SET_FETCH_CHANGE_HISTORY_FAILURE'),
  CLEAR_CHANGE_HISTORY_STATE: createSettingsInternalSymbol('CLEAR_CHANGE_HISTORY_STATE'),

  // Alert Monitor Settings Table
  FETCH_ALERT_MONITOR_SETTINGS_TABLE: createSettingsSymbol('FETCH_ALERT_MONITOR_SETTINGS_TABLE'),
  SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_SUCCESS: createSettingsSymbol('SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_SUCCESS'),
  SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_FAILURE: createSettingsSymbol('SET_FETCH_ALERT_MONITOR_SETTINGS_TABLE_FAILURE'),
  CLEAR_ALERT_MONITOR_SETTINGS_TABLE_STATE: createSettingsInternalSymbol('CLEAR_ALERT_MONITOR_SETTINGS_TABLE_STATE'),

  // Alert Monitor Settings
  FETCH_ALERT_MONITOR_SETTINGS: createSettingsSymbol('FETCH_ALERT_MONITOR_SETTINGS'),
  SET_FETCH_ALERT_MONITOR_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_ALERT_MONITOR_SETTINGS_SUCCESS'),
  SET_FETCH_ALERT_MONITOR_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_ALERT_MONITOR_SETTINGS_FAILURE'),
  CLEAR_ALERT_MONITOR_SETTINGS_STATE: createSettingsInternalSymbol('CLEAR_ALERT_MONITOR_SETTINGS_STATE'),

  // Delete Alert Monitor Settings
  FETCH_DELETE_ALERT_MONITOR_SETTINGS: createSettingsSymbol('FETCH_DELETE_ALERT_MONITOR_SETTINGS'),
  SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_SUCCESS'),
  SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_DELETE_ALERT_MONITOR_SETTINGS_FAILURE'),
  CLEAR_DELETE_ALERT_MONITOR_SETTINGS: createSettingsSymbol('CLEAR_DELETE_ALERT_MONITOR_SETTINGS'),

  // Save Alert Monitor Settings
  FETCH_SAVE_ALERT_MONITOR_SETTINGS: createSettingsSymbol('FETCH_SAVE_ALERT_MONITOR_SETTINGS'),
  SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_SUCCESS'),
  SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_SAVE_ALERT_MONITOR_SETTINGS_FAILURE'),
  CLEAR_SAVE_ALERT_MONITOR_SETTINGS_FAILURE: createSettingsSymbol('CLEAR_SAVE_ALERT_MONITOR_SETTINGS_FAILURE'),

  // Partner Alert Settings
  FETCH_PARTNER_ALERT_SETTINGS: createSettingsSymbol('FETCH_PARTNER_ALERT_SETTINGS'),
  SET_FETCH_PARTNER_ALERT_SETTINGS_SUCCESS: createSettingsSymbol('SET_FETCH_PARTNER_ALERT_SETTINGS_SUCCESS'),
  SET_FETCH_PARTNER_ALERT_SETTINGS_FAILURE: createSettingsSymbol('SET_FETCH_PARTNER_ALERT_SETTINGS_FAILURE'),
  CLEAR_PARTNER_ALERT_SETTINGS_STATE: createSettingsInternalSymbol('CLEAR_PARTNER_ALERT_SETTINGS_STATE'),

  // Update Partner Alert Settings
  UPDATE_PARTNER_ALERT_SETTINGS: createSettingsSymbol('UPDATE_PARTNER_ALERT_SETTINGS'),
  SET_UPDATE_PARTNER_ALERT_SETTINGS_SUCCESS: createSettingsSymbol('SET_UPDATE_PARTNER_ALERT_SETTINGS_SUCCESS'),
  SET_UPDATE_PARTNER_ALERT_SETTINGS_FAILURE: createSettingsSymbol('SET_UPDATE_PARTNER_ALERT_SETTINGS_FAILURE'),
  CLEAR_UPDATE_PARTNER_ALERT_SETTINGS: createSettingsInternalSymbol('CLEAR_UPDATE_PARTNER_ALERT_SETTINGS'),
  ADD_PARTNER_ALERT_SETTINGS_RULE: createSettingsInternalSymbol('ADD_PARTNER_ALERT_SETTINGS_RULE'),

  // Delete Partner Alert Settings RuleRow
  DELETE_PARTNER_ALERT_SETTINGS: createSettingsSymbol('DELETE_PARTNER_ALERT_SETTINGS'),
  SET_DELETE_PARTNER_ALERT_SETTINGS_SUCCESS: createSettingsSymbol('SET_DELETE_PARTNER_ALERT_SETTINGS_SUCCESS'),
  SET_DELETE_PARTNER_ALERT_SETTINGS_FAILURE: createSettingsSymbol('SET_DELETE_PARTNER_ALERT_SETTINGS_FAILURE'),

  // cancel call
  CANCEL_CS_SDK_CALL: createSettingsSymbol('CANCEL_CS_SDK_CALL'),
  CANCEL_PARTNER_SDK_CALL: createSettingsSymbol('CANCEL_PARTNER_SDK_CALL')

};
