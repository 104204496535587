const expandReducerChildren = ({ allData, itemIndex, data, status, error }) => {
  // eslint-disable-next-line no-param-reassign
  allData[itemIndex] = {
    ...allData[itemIndex],
    expand: {
      status,
      error,
      data
    }
  };
  return allData;
};

export default expandReducerChildren;
