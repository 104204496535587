/* eslint-disable  no-mixed-operators */
/**
 * @flow
 * */
import { useCallback, useEffect } from 'react';
import type { RefObject } from '../lib/core/flow';

/**
 * Custom hook useOnClickOutside
 *
 * Create a ref that we add to the element for which we want to detect outside clicks
 * const ref = useRef();
 * State for our modal
 * for ex.
 * const [isModalOpen, setModalOpen] = useState(false);
 * Call hook passing in the ref and a function to call on outside click
 * useOnClickOutside(ref, () => setModalOpen(false));
 *  note that a component should be wrapped with React.forwardRef() to receive (props, ref)
 *
 * @param {React.Ref} ref - element ref from where you use this hook
 * @param {Function} handler - handler which handles event onClick
 * @param {React.Ref} exactRef - element ref from
 * @param {Boolean} exactDropdown - exactDropdown
 * @returns {Function} - Hook
 */
function useOnClickOutside(ref: RefObject, handler: Function, exactRef: RefObject = null, exactDropdown: boolean = false): Function {
  const outSideClickHandler = useCallback(() => {
    const listener = (event) => {
      if (exactDropdown && event.currentTarget.lastElementChild.className === 'has-popover-opened') {
        return;
      }
      // Do nothing if clicking ref's element or descendent elements
      // eslint-disable-next-line no-lone-blocks
      {
        if (!ref.current || ref.current.contains(event.target) || event.target.classList.contains('__levelCircle') || exactRef?.current && exactRef.current.contains(event.target)) {
          return;
        }
      }
      handler(event);
    };
    // $FlowFixMe
    document.addEventListener('mousedown', listener);
    // $FlowFixMe
    document.addEventListener('touchstart', listener);

    return () => {
      // $FlowFixMe
      document.removeEventListener('mousedown', listener);
      // $FlowFixMe
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, exactRef, exactDropdown]);

  useEffect(outSideClickHandler, [ref, handler]);
}

export default useOnClickOutside;
