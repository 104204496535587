/**
 * @flow
 * */
import React from 'react';

import AppSettingsSuggestionPopup from 'views/components/AppSettingsSuggestionPopup';
import { appSettingsSelector } from 'store/selectors/appSettingsSelectors';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import Skeleton from 'views/components/loaders/Skeleton';
import useInitApp from 'hooks/useInitApp';

type Props = {
  page: Object,
  navigation: Object
}

/**
 *
 * @param {Object} Page - Page
 * @param {Object} Navigation - Navigation
 * @returns {JSX.Element}
 * @constructor
 */

const AppLayout = ({ page: Page, navigation: Navigation }: Props) => {
  const {
    state,
    isSucceed
  } = useContainerStatusHandler({ selector: appSettingsSelector });

  const { appState } = useInitApp();

  return (
    <>
      {appState.isReady
        ? (
          <>
            {isSucceed && state.data.viewMode !== 'small' && window.innerWidth <= 1400 && !state.data?.isSizeChecked && (
              <AppSettingsSuggestionPopup />
            )}
            {appState.isLoggedIn && (
              <div className="inside-right-main-c">
                {Navigation && (
                  <div className="top-i-right-main-c">
                    <Navigation />
                  </div>
                )}
                <div className="bottom-i-right-main-c">
                  <Page />
                </div>
              </div>
            )}
          </>
        )
        : <Skeleton isFillScreen />}
    </>
  );
};

export default AppLayout;
