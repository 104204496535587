/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import DASHBOARD_ACTION_TYPES from './types';

// Categories
export const fetchCategoriesChart = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_CATEGORIES_CHART,
  payload
});
export const setFetchCategoriesChartSuccess = ({ data }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_CATEGORIES_CHART_SUCCESS,
  payload: { data }
});
export const setFetchCategoriesChartFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_CATEGORIES_CHART_FAILURE,
  payload: { error }
});
export const clearCategoriesChartState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_CATEGORIES_CHART_STATE
});

// Game Style History
export const fetchGameStyleHistoryChart = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_GAME_STYLE_HISTORY_CHART,
  payload
});
export const setFetchGameStyleHistoryChartSuccess = ({ data }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_HISTORY_CHART_SUCCESS,
  payload: { data }
});
export const setFetchGameStyleHistoryChartFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_HISTORY_CHART_FAILURE,
  payload: { error }
});
export const clearGameStyleHistoryChartState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_GAME_STYLE_HISTORY_CHART_STATE
});

// Game Style Tendency
export const fetchGameStyleTendencyChart = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_GAME_STYLE_TENDENCY_CHART,
  payload
});
export const setFetchGameStyleTendencyChartSuccess = ({ data }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_TENDENCY_CHART_SUCCESS,
  payload: { data }
});
export const setFetchGameStyleTendencyChartFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_TENDENCY_CHART_FAILURE,
  payload: { error }
});
export const clearGameStyleTendencyChartState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_GAME_STYLE_TENDENCY_CHART_STATE
});

// Analytic Data
export const fetchAnalyticChart = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_ANALYTIC_CHART,
  payload
});
export const setFetchAnalyticChartSuccess = ({ data }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_CHART_SUCCESS,
  payload: { data }
});
export const setFetchAnalyticChartFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_CHART_FAILURE,
  payload: { error }
});
export const clearAnalyticChartState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_ANALYTIC_CHART_STATE
});

// Alert Data Linked
export const fetchAlertChartLinked = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_ALERT_CHART_LINKED,
  payload
});
export const setFetchAlertChartLinkedSuccess = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_LINKED_SUCCESS,
  payload
});
export const setFetchAlertChartLinkedFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_LINKED_FAILURE,
  payload: { error }
});
export const clearAlertChartLinkedState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_ALERT_CHART_LINKED_STATE
});

// Alert Data Multi
export const fetchAlertChartMulti = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_ALERT_CHART_MULTI,
  payload
});
export const setFetchAlertChartMultiSuccess = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_MULTI_SUCCESS,
  payload
});
export const setFetchAlertChartMultiFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_MULTI_FAILURE,
  payload: { error }
});
export const clearAlertChartMultiState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_ALERT_CHART_MULTI_STATE
});

// Analytic Kpis
export const fetchAnalyticKpis = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_ANALYTIC_KPIS,
  payload
});
export const setFetchAnalyticKpisSuccess = ({ data }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_KPIS_SUCCESS,
  payload: { data }
});
export const setFetchAnalyticKpisFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_KPIS_FAILURE,
  payload: { error }
});
export const clearAnalyticKpisState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_ANALYTIC_KPIS_STATE
});

// Licenses Kpis
export const fetchDashboardKpis = (payload: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_KPIS,
  payload
});
export const setFetchDashboardKpisSuccess = ({ data }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_KPIS_SUCCESS,
  payload: { data }
});
export const setFetchDashboardKpisFailure = ({ error }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_KPIS_FAILURE,
  payload: { error }
});
export const clearDashboardKpisState = (): Action => ({
  type: DASHBOARD_ACTION_TYPES.CLEAR_DASHBOARD_KPIS_STATE
});

// cancel call
export const cancelDashboardSdkCall = ({ name }: Object): Action => ({
  type: DASHBOARD_ACTION_TYPES.CANCEL_DASHBOARD_SDK_CALL,
  payload: { name }
});
