import ACTION_TYPES from './types';

export const fetchExportData = ({ fileType, projectType, exportUrl, isPost, ...filters }: any): any => ({
  type: ACTION_TYPES.FETCH_EXPORT_DATA,
  payload: { fileType, projectType, exportUrl, isPost, filters }
});

export const setExportFailed = (error: string): any => ({
  type: ACTION_TYPES.SET_FETCH_EXPORT_DATA_FAILURE,
  payload: { error }
});

export const setExportSuccess = ({ data, fileType }: any): any => ({
  type: ACTION_TYPES.SET_FETCH_EXPORT_DATA_SUCCESS,
  payload: { data, fileType }
});

export const clearExportState = (): any => ({
  type: ACTION_TYPES.CLEAR_EXPORT_STATE
});
export const cancelExportCall = (): any => ({
  type: ACTION_TYPES.CANCEL_EXPORT_CALL
});
