/* eslint-disable no-throw-literal */
/**
 * @flow
 * */
import { put, takeEvery } from 'redux-saga/effects';

import { getAmlLicenseFilter, getAmlLicenseForRedux, isAml, isAmlLicense } from 'modules/AML/utils/amlConfigs';
import { setFetchSettingsSuccess, updateAppSettings } from 'store/actions/appSettings/actions';
import { setFetchBoxesSuccess, setFetchConfigsSuccess } from 'modules/AML/store/actions';
import { setTableSettingsCache } from 'store/actions/userSettings/actions';
import { setFetchFiltersDataSuccess } from 'store/actions/filter/actions';
import { setPermissionSuccess } from 'store/actions/permission/actions';
import { redirectToLoginWithRedirectUrl } from 'lib/helpers/redirectTo';
import { setUser } from 'modules/Dashboard/Auth/store/actions/user';
import APP_ACTION_TYPES from 'store/actions/application/types';
import CacheService from 'core/services/storage/CacheService';
import { parseQueryString } from 'lib/helpers/queryParser';
import authSdk from 'modules/Dashboard/Auth/core/sdk/auth';
import { setAppIsReady } from 'store/actions/application';
import amlSDK from 'modules/AML/core/sdk/index';
import agpSdk from 'core/sdk/AGPSDK/AGPSDK';
import config from 'config';

import userSettingsSDK from '../sdk/settings/UserSettingsSDK';
import permissionSDK from '../sdk/permission/PermissionSDK';
import { timezone } from '../../lib/helpers/timezones';

function* initApp() {
  const liveCasinoToken = parseQueryString(window.location.search)?.live_casino_token || CacheService.getItem('live_casino_token');
  let token = CacheService.getItem('auth_token');
  const dbMonitoring = window.location.pathname.split('/')[1] === 'db-monitoring';

  if (liveCasinoToken && !dbMonitoring) {
    CacheService.deleteItem('auth_token');
    CacheService.deleteItem('live_casino_token');
    redirectToLoginWithRedirectUrl(true);
    return;
  }

  if (liveCasinoToken) {
    token = liveCasinoToken;
    CacheService.setItem('auth_token', liveCasinoToken);
    CacheService.setItem('live_casino_token', liveCasinoToken);
  }

  try {
    let user = {};
    let timeZone = 0;

    if (token) {
      const response = yield authSdk.checkJwtAuth();
      if (!response) throw 'invalid data';
      user = response.user;
      timeZone = user.TimeZone;
    } else {
      const { body, headers } = yield agpSdk.checkAuthentication();
      const { HasError, Data } = body;
      if (HasError) throw 'invalid data';
      user = Data;
      timeZone = user.Settings.TimeZone;
      headers.get('authentication') && CacheService.setItem('auth_token', headers.get('authentication'));
    }
    // $FlowFixMe
    const { data } = yield permissionSDK.getUserPermission({ userId: user.Id, caching: !liveCasinoToken });
    yield put(setPermissionSuccess({ data }));

    if (!liveCasinoToken) {
      // const { body } = yield agpSdk.getSetting();
      const time = timezone.map(item => ({ ...item, IsSelected: item.Id === timeZone }));
      yield put(setFetchSettingsSuccess({ data: { TimeZones: time } }));
      const cacheGetSetting = JSON.parse(CacheService.getItem('callCaching') || '{}');
      // eslint-disable-next-line no-return-assign,no-param-reassign
      cacheGetSetting[`${config.agpApi}/en/setting/GetSetting`] = { body: { Data: { TimeZones: time } } };
      CacheService.setItem('callCaching', JSON.stringify(cacheGetSetting));
    }

    const viewModeData = CacheService.getItem('appSettings');
    if (!viewModeData) {
      const { data } = yield userSettingsSDK.getUserSettings({ name: 'appSettings' });
      if (!data.length) {
        yield put(updateAppSettings({ userId: user.Id, viewMode: 'normal', language: '', isSizeChecked: false }));
      } else {
        yield put(updateAppSettings({ userId: user.Id, viewMode: JSON.parse(data[0].Scheme).viewMode, language: '', isSizeChecked: JSON.parse(data[0].Scheme)?.isSizeChecked }));
      }
    }

    const filterCache = CacheService.getJson('filterCache');
    // $FlowFixMe
    if (filterCache && Object.keys(filterCache).length) {
      yield put(setFetchFiltersDataSuccess(filterCache));
    }

    const tableCache = CacheService.getJson('tableSettingsCache');
    if (tableCache) {
      yield put(setTableSettingsCache({ data: tableCache }));
    }

    if (isAml()) {
      const { data } = yield amlSDK.fetchBoxes();
      yield put(setFetchBoxesSuccess({ data }));
      const license = isAmlLicense();
      if (license && license?.key) {
        const filters = getAmlLicenseFilter(license);
        const { data } = yield amlSDK.fetchConfigs({ filters });
        yield put(setFetchConfigsSuccess({
          data,
          license: getAmlLicenseForRedux({
            license: license?.key,
            licenseId: license?.license,
            partnerId: CacheService.getItem('amlTurnkeyPartnerId')
          })
        }));
      }
    }
    yield put(setUser(user));
    yield put(setAppIsReady({ isLoggedIn: true }));
  } catch (err) {
    CacheService.clearStorage();
    redirectToLoginWithRedirectUrl(true);
  }
}

function* appControllerWatcher<T>(): Iterable<T> {
  yield takeEvery(APP_ACTION_TYPES.INIT_APPLICATION, initApp);
}

export default appControllerWatcher;
