const reducerChildren = (allData, payload, data, status, error) => allData.map(e => {
  if (payload.alertHistoryId === e.AlertHistoryId) {
    return {
      ...e,
      childrenHistory: {
        status,
        error,
        data
      }
    };
  }
  return { ...e };
});

export default reducerChildren;
