// This file created for absolutely general configurations
// If you considered to add getPlayers method's route url of the aml, this file is not the correct place  #see 'core/Settings'
const changeHost = (value) => {
  const mainHost = window.location.origin.split('.')[1];
  if (mainHost === 'bcapps') {
    // eslint-disable-next-line no-param-reassign
    value = value.replaceAll('.betconstruct.com', `.${mainHost}.org`);
  }
  return value;
};

const config = {
  isEnvDevelopment: process.env.REACT_APP_NODE === 'development',
  isEnvProduction: process.env.REACT_APP_NODE === 'production',
  isEnvStaging: process.env.REACT_APP_NODE === 'staging',
  rmtApi: changeHost(`${process.env.REACT_APP_RMT_API}/api`),
  amlApi: changeHost(`${process.env.REACT_APP_AML_API}/api`),
  agpApi: changeHost(`${process.env.REACT_APP_AGP_API}/api`),
  agpRequestApi: changeHost(`${process.env.REACT_APP_AGPREQUEST_API}/api`),
  agp: process.env.REACT_APP_AGP,
  umbrellaApp: window.location.origin
};

export default config;
