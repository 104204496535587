/* eslint-disable camelcase */
/**
 * @flow
 * */
import { createSelector } from 'reselect';
import type { UserState } from '../reducers/user';
import { getUserState } from './defaultStates';

/**
 * get user State State
 * @param {Object} state - state
 * @returns {UserState} - auth
 */

export const userSelector: Function = createSelector(
  getUserState,
  (user: UserState) => user?.user
);
