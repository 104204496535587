/**
 * @flow
 * */
import { initialSectionState, initialSectionWithCountState } from 'lib/core/initialStates';
import type { ReportsState } from 'modules/LCRMT/Reports/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

import REPORTS_ACTION_TYPES from '../actions/reports/types';

const initialState = (): ReportsState => ({
  chiSquareReports: initialSectionWithCountState(),
  chiSquareLifeTimeReports: initialSectionState(),
  playersBetsReport: initialSectionWithCountState(),
  playersRoundsReport: initialSectionWithCountState(),
  partnersInfoReport: initialSectionWithCountState()
});

const actionManager = {

  // reports
  [REPORTS_ACTION_TYPES.FETCH_CHI_SQUARE_REPORTS]: (state: ReportsState): ReportsState => ({
    ...state,
    chiSquareReports: {
      ...state.chiSquareReports,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_REPORTS_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    chiSquareReports: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data,
      totalCount: payload.totalCount
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_REPORTS_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    chiSquareReports: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [REPORTS_ACTION_TYPES.CLEAR_CHI_SQUARE_REPORTS_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    chiSquareReports: initialSectionWithCountState()
  }),

  // lifeTime reports
  [REPORTS_ACTION_TYPES.FETCH_LIFE_TIME_CHI_SQUARE_REPORTS]: (state: ReportsState): ReportsState => ({
    ...state,
    chiSquareLifeTimeReports: {
      ...state.chiSquareLifeTimeReports,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    chiSquareLifeTimeReports: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_LIFE_TIME_CHI_SQUARE_REPORTS_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    chiSquareLifeTimeReports: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [REPORTS_ACTION_TYPES.CLEAR_LIFE_TIME_CHI_SQUARE_REPORTS_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    chiSquareLifeTimeReports: initialSectionState()
  }),

  // player bets reports
  [REPORTS_ACTION_TYPES.FETCH_PLAYERS_BETS_REPORT]: (state: ReportsState): ReportsState => ({
    ...state,
    playersBetsReport: {
      ...state.playersBetsReport,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_FETCH_PLAYERS_BETS_REPORT_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    playersBetsReport: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playersBetsReport.totalCount
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_PLAYERS_BETS_REPORT_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    playersBetsReport: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [REPORTS_ACTION_TYPES.CLEAR_PLAYERS_BETS_REPORT_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    playersBetsReport: initialSectionWithCountState()
  }),

  // player rounds reports
  [REPORTS_ACTION_TYPES.FETCH_PLAYERS_ROUNDS_REPORT]: (state: ReportsState): ReportsState => ({
    ...state,
    playersRoundsReport: {
      ...state.playersRoundsReport,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_FETCH_PLAYERS_ROUNDS_REPORT_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    playersRoundsReport: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playersRoundsReport.totalCount
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_PLAYERS_ROUNDS_REPORT_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    playersRoundsReport: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [REPORTS_ACTION_TYPES.CLEAR_PLAYERS_ROUNDS_REPORT_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    playersRoundsReport: initialSectionWithCountState()
  }),

  // partners info reports
  [REPORTS_ACTION_TYPES.FETCH_PARTNERS_INFO_REPORT]: (state: ReportsState): ReportsState => ({
    ...state,
    partnersInfoReport: {
      ...state.partnersInfoReport,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_PARTNERS_INFO_REPORT_SUCCESS]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    partnersInfoReport: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.partnersInfoReport.totalCount
    }
  }),
  [REPORTS_ACTION_TYPES.SET_FETCH_PARTNERS_INFO_REPORT_FAILURE]: (state: ReportsState, payload): ReportsState => ({
    ...state,
    partnersInfoReport: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [REPORTS_ACTION_TYPES.CLEAR_PARTNERS_INFO_REPORT_STATE]: (state: ReportsState): ReportsState => ({
    ...state,
    partnersInfoReport: initialSectionWithCountState()
  })

};

export default (state: ReportsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
