import config from 'config';
import { history } from 'config/history';

export const redirectUrl = () => {
  if (['/'].includes(window.location.pathname)) {
    return '';
  }
  return `?redirectUrl=${window.location.pathname}${window.location.search}`;
};
export const redirectToLoginWithRedirectUrl = (force = false) => {
  let url = '/login';
  if (!['/', '/login'].includes(window.location.pathname)) {
    url = `${url}${redirectUrl()}`;
  }
  if (force) {
    window.location.href = url;
  } else if (window.location.pathname !== '/login') {
    history.push(url);
  }
};

export const redirectToLoginSso = () => {
  window.location.href = `${config.agpApi}/en/Account/SSOLogin?returnUrl=${encodeURIComponent(
    config.umbrellaApp
  )}`;
};

export const redirectAfterSsoLogin = (params) => {
  window.location.href = `${config.agpApi}/en/Account/SSOLogin?returnUrl=${encodeURIComponent(
    `${config.umbrellaApp}${params}`
  )}`;
};

export const redirectToBackOfficeSso = () => {
  window.open(`${config.agpApi}/en/Account/SSOLogin?returnUrl=${encodeURIComponent(
    config.agp
  )}`, '_blank');
};
