/**
 * @flow
 * */
import type { Action } from 'lib/core/flow/types';

import APP_SETTINGS_ACTION_TYPES from './types';

type ActionType = {
  userId?: number,
}

export const fetchPermission = ({ userId }: ActionType): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.FETCH_PERMISSION,
  payload: { userId }
});
export const setPermissionSuccess = ({ data }: Object): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS,
  payload: { data }
});
export const setPermissionFailure = ({ error }: Object): ActionType => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_FETCH_PERMISSION_FAILURE,
  payload: { error }
});
