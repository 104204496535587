const initialSectionState = () => ({
  data: null,
  status: null,
  error: null
});

const initialSectionStateWithError = () => ({
  data: null,
  status: null,
  error: null,
  errorStatusCode: null
});
const initialStatisticState = () => ({
  gameType: initialSectionState(),
  table: initialSectionState(),
  dealer: initialSectionState()

});

const initialSectionArrayState = () => ({
  data: [],
  status: null,
  error: null
});

const initialPlayerSessionApiState = () => ({
  data: [],
  allSessionIpsData: {},
  allPlayersIpData: {},
  status: null,
  error: null
});

const initialSectionWithCountState = () => ({
  ...initialSectionArrayState(),
  totalCount: null
});

const initialSectionWithoutData = () => ({
  status: null,
  error: null
});

const initialActionReducerWithoutData = () => ({
  status: null,
  error: null
});

const initialSectionWithEditState = () => ({
  data: null,
  status: null,
  error: null,
  edit: null
});

const initialSectionWithEditDeleteState = () => ({
  data: null,
  status: null,
  error: null,
  totalCount: null,
  save: initialSectionState(),
  edit: initialSectionState(),
  delete: initialSectionState()
});

const initialSectionWithSaveDeleteState = () => ({
  data: null,
  status: null,
  error: null,
  save: initialSectionState(),
  delete: initialSectionState()
});

const initialSectionWithSaveState = () => ({
  data: null,
  status: null,
  error: null,
  save: initialSectionState()
});

const initialKPITreeState = () => ({
  data: {
    root: null
  },
  status: null,
  error: null
});

export {
  initialSectionWithEditDeleteState,
  initialSectionWithSaveDeleteState,
  initialActionReducerWithoutData,
  initialSectionStateWithError,
  initialSectionWithCountState,
  initialPlayerSessionApiState,
  initialSectionWithEditState,
  initialSectionWithSaveState,
  initialSectionWithoutData,
  initialStatisticState,
  initialSectionState,
  initialKPITreeState
};
