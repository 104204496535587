/**
 * @flow
 * */
import React from 'react';

import settings from 'config/constants';
import { Redirect } from 'react-router-dom';

const AlertMonitor = React.lazy(() => import('./index'));

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const modulePath = settings.PRODUCTS.FRMT.path;

const ROUTES: Array<Route> = [
  { path: '/frmt', name: 'frmt', exact: true, page: () => <Redirect to={`/${modulePath}/alert-monitor`} /> },
  { path: `/${modulePath}/alert-monitor`, name: 'Alert Monitor', exact: true, page: AlertMonitor },
  { path: `/${modulePath}/alert-monitor/:tab`, name: 'Alert Monitor', exact: true, page: AlertMonitor }
];

export default ROUTES;
