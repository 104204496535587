/**
 * @flow
 * */
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import { generateCategoriesChart, generateGSTendency } from 'modules/SRMT/Dashboard/utils';
import DASHBOARD_ACTION_TYPES from 'modules/SRMT/Dashboard/store/actions/dashboard/types';
import dashboardSDK from 'modules/SRMT/Dashboard/core/sdk/DashboardSDK';
import {
  setFetchGameStyleTendencyChartFailure,
  setFetchGameStyleTendencyChartSuccess,
  setFetchGameStyleHistoryChartFailure,
  setFetchGameStyleHistoryChartSuccess,
  setFetchAlertChartLinkedFailure,
  setFetchAlertChartLinkedSuccess,
  setFetchCategoriesChartFailure,
  setFetchAlertChartMultiFailure,
  setFetchCategoriesChartSuccess,
  setFetchAlertChartMultiSuccess,
  setFetchAnalyticChartFailure,
  setFetchAnalyticChartSuccess,
  setFetchDashboardKpisFailure,
  setFetchDashboardKpisSuccess,
  setFetchAnalyticKpisFailure,
  setFetchAnalyticKpisSuccess
} from 'modules/SRMT/Dashboard/store/actions/dashboard';

class DashboardController {
  static* fetchCategoriesChart(payload) {
    try {
      const data = yield dashboardSDK.fetchCategoriesChart(payload);
      yield new Promise(resolve => {
        setTimeout(() => {
          resolve(5);
        }, 2000);
      });
      yield put(setFetchCategoriesChartSuccess({ data }));
    } catch (err) {
      yield put(setFetchCategoriesChartFailure({ error: err.message }));
    }
  }

  static* fetchGameStyleHistoryChart({ payload }) {
    try {
      const data = yield dashboardSDK.fetchChartData(payload);
      yield put(setFetchGameStyleHistoryChartSuccess({ data: generateCategoriesChart(data) }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGameStyleHistoryChartFailure({ error: err.message }));
    }
  }

  static* fetchGameStyleTendencyChart({ payload }) {
    try {
      const data = yield dashboardSDK.fetchChartData(payload);
      yield put(setFetchGameStyleTendencyChartSuccess({ data: generateGSTendency(data) }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGameStyleTendencyChartFailure({ error: err.message }));
    }
  }

  static* fetchAnalyticChart({ payload }) {
    const { partnerId } = payload;
    try {
      const data = yield dashboardSDK.fetchAnalyticChart({ partnerId });
      yield put(setFetchAnalyticChartSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAnalyticChartFailure({ error: err.message }));
    }
  }

  static* fetchAlertChartLinked({ payload }) {
    const { partnerIds, alertType, startDateFrom, endDateTo } = payload;
    try {
      const data = yield dashboardSDK.getAlertChart({ partnerIds, alertType, startDateFrom, endDateTo });
      yield put(setFetchAlertChartLinkedSuccess({ data, alertType }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAlertChartLinkedFailure({ error: err.message }));
    }
  }

  static* fetchAlertChartMulti({ payload }) {
    const { partnerIds, alertType, startDateFrom, endDateTo } = payload;
    try {
      const data = yield dashboardSDK.getAlertChart({ partnerIds, alertType, startDateFrom, endDateTo });
      yield put(setFetchAlertChartMultiSuccess({ data, alertType }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAlertChartMultiFailure({ error: err.message }));
    }
  }

  static* fetchAnalyticKpis({ payload }) {
    const { startDate, endDate, partnerId } = payload;
    try {
      const data = yield dashboardSDK.fetchAnalyticKpis({ startDate, endDate, partnerId });
      yield put(setFetchAnalyticKpisSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAnalyticKpisFailure({ error: err.message }));
    }
  }

  static* fetchDashboardKpis({ payload }) {
    const { partnerId } = payload;
    try {
      const data = yield dashboardSDK.fetchDashboardKpis({ partnerId });
      yield put(setFetchDashboardKpisSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchDashboardKpisFailure({ error: err.message }));
    }
  }

  static* cancelDashboardSDKCalls({ payload }) {
    const { name } = payload;
    yield dashboardSDK.cancelDashboardSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(DASHBOARD_ACTION_TYPES.FETCH_ANALYTIC_KPIS, DashboardController.fetchAnalyticKpis);
    yield takeLatest(DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_KPIS, DashboardController.fetchDashboardKpis);
    yield takeLatest(DASHBOARD_ACTION_TYPES.CANCEL_DASHBOARD_SDK_CALL, DashboardController.cancelDashboardSDKCalls);
    yield takeLatest(DASHBOARD_ACTION_TYPES.FETCH_CATEGORIES_CHART, DashboardController.fetchCategoriesChart);
    yield takeEvery(DASHBOARD_ACTION_TYPES.FETCH_ANALYTIC_CHART, DashboardController.fetchAnalyticChart);
    yield takeEvery(DASHBOARD_ACTION_TYPES.FETCH_ALERT_CHART_LINKED, DashboardController.fetchAlertChartLinked);
    yield takeEvery(DASHBOARD_ACTION_TYPES.FETCH_ALERT_CHART_MULTI, DashboardController.fetchAlertChartMulti);
    yield takeLatest(DASHBOARD_ACTION_TYPES.FETCH_GAME_STYLE_HISTORY_CHART, DashboardController.fetchGameStyleHistoryChart);
    yield takeLatest(DASHBOARD_ACTION_TYPES.FETCH_GAME_STYLE_TENDENCY_CHART, DashboardController.fetchGameStyleTendencyChart);
  }
}

export default DashboardController.actionsWatcher;
