/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import statisticsSDK from 'modules/LCRMT/EquipmentIntegrity/core/sdk/StatisticsSDK';
import ACTIONS from 'modules/LCRMT/EquipmentIntegrity/store/actions/statistics/types';
import {
  setFetchRouletteBiasedStatisticsSuccess,
  setFetchRouletteBiasedStatisticsFailure,
  setFetchStatisticsTableDataFailure,
  setFetchStatisticsTableDataSuccess,
  setFetchRouletteStatisticsSuccess,
  setFetchRouletteStatisticsFailure,
  setFetchTableStatisticsFailure,
  setFetchTableStatisticsSuccess,
  setFetchCardStatisticsFailure,
  setFetchCardStatisticsSuccess,
  setFetchStatisticsSuccess,
  setFetchStatisticsFailure,
  setFetchTimeRangeSuccess,
  setFetchTimeRangeFailure
} from 'modules/LCRMT/EquipmentIntegrity/store/actions/statistics';

class StatisticsController {
  static* fetchStatistics() {
    try {
      const { data } = yield statisticsSDK.getStatistics();
      yield put(setFetchStatisticsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchStatisticsFailure({ error: err.message }));
    }
  }

  static* fetchStatisticsForTable({ payload }) {
    const { tableId, spinCount } = payload;
    try {
      const { data } = yield statisticsSDK.getStatisticsForTable({ tableId, spinCount });
      yield put(setFetchTableStatisticsSuccess({ data, tableId }));
    } catch (err) {
      yield put(setFetchTableStatisticsFailure({ error: err.message, tableId }));
    }
  }

  static* fetchRouletteStatistics({ payload }) {
    try {
      const { data } = yield statisticsSDK.getRouletteStatistics(payload);
      yield put(setFetchRouletteStatisticsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchRouletteStatisticsFailure({ error: err.message }));
    }
  }

  static* fetchRouletteBiasedStatistics() {
    try {
      const { data } = yield statisticsSDK.getRouletteBiasedStatistics();
      yield put(setFetchRouletteBiasedStatisticsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchRouletteBiasedStatisticsFailure({ error: err.message }));
    }
  }

  static* fetchCardStatistics() {
    try {
      const { data } = yield statisticsSDK.getCardStatistics();
      yield put(setFetchCardStatisticsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchCardStatisticsFailure({ error: err.message }));
    }
  }

  static* fetchTimeRange({ payload }) {
    try {
      const { data } = yield statisticsSDK.getTimeRange(payload);
      yield put(setFetchTimeRangeSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchTimeRangeFailure({ error: err.message }));
    }
  }

  static* fetchCardStatisticsTableData({ payload }) {
    try {
      const { data } = yield statisticsSDK.getStatisticTableData(payload);
      yield put(setFetchStatisticsTableDataSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchStatisticsTableDataFailure({ error: err.message }));
    }
  }

  static* cancelStatisticSDKCalls({ payload }) {
    const { name } = payload;
    yield statisticsSDK.cancelStatisticSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.CANCEL_STATISTIC_SDK_CALL, StatisticsController.cancelStatisticSDKCalls);
    yield takeLatest(ACTIONS.FETCH_STATISTICS, StatisticsController.fetchStatistics);
    yield takeLatest(ACTIONS.FETCH_TABLE_STATISTICS, StatisticsController.fetchStatisticsForTable);
    yield takeLatest(ACTIONS.FETCH_ROULETTE_STATISTICS, StatisticsController.fetchRouletteStatistics);
    yield takeLatest(ACTIONS.FETCH_ROULETTE_BIASED_STATISTICS, StatisticsController.fetchRouletteBiasedStatistics);
    yield takeLatest(ACTIONS.FETCH_CARD_STATISTICS, StatisticsController.fetchCardStatistics);
    yield takeLatest(ACTIONS.FETCH_TIME_RANGE, StatisticsController.fetchTimeRange);
    yield takeLatest(ACTIONS.FETCH_STATISTICS_TABLE_DATA, StatisticsController.fetchCardStatisticsTableData);
  }
}

export default StatisticsController.actionsWatcher;
