import { put, takeLatest, takeEvery } from 'redux-saga/effects';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import {
  setFetchDeleteAlertMonitorSettingsSuccess,
  setFetchDeleteAlertMonitorSettingsFailure,
  setFetchAlertMonitorSettingsTableFailure,
  setFetchAlertMonitorSettingsTableSuccess,
  setFetchSaveAlertMonitorSettingsSuccess,
  setFetchSaveAlertMonitorSettingsFailure,
  setFetchAlertMonitorSettingsFailure,
  setFetchAlertMonitorSettingsSuccess
} from 'modules/SRMT/Settings/store/actions/settings/alertMonitorSettings';
import {
  setUpdateGameStyleSettingsPrioritySuccess, setUpdateGameStyleSettingsPriorityFailure,
  setUpdateGameStyleSettingsSuccess, setUpdateGameStyleSettingsFailure,
  setFetchAdditionalSettingsSuccess, setFetchGameStyleSettingsSuccess,
  setFetchGameStyleSettingsFailure, setFetchFinancialSettingsSuccess,
  setFetchFinancialUpdateFailure, setFetchAdditionalSettingsFailure,
  setFetchFinancialSettingsFailure, setFetchFinancialUpdateSuccess
} from 'modules/SRMT/Settings/store/actions/settings';
import {
  editAdditionalCategorySettingsFailure,
  editAdditionalCategorySettingsSuccess
} from 'modules/SRMT/Settings/store/actions/settings/categorySettings';
import {
  parseSettingsModelFromResponse, parseSettingsChangeHistoryModelFromResponse
} from 'modules/SRMT/Settings/utils/parsers';
import {
  setFetchChangeHistoryFailure, setFetchChangeHistorySuccess
} from 'modules/SRMT/Settings/store/actions/settings/changeHistory';
import categorySettingsSDK from '../sdk/CategorySettingsSDK';

class CategorySettingsController {
  static* fetchAdditionalSettings({ payload }) {
    const { partnerId } = payload;
    try {
      let { data } = yield categorySettingsSDK.getAdditionalSettings({ partnerId });
      data = parseSettingsModelFromResponse(data);
      yield put(setFetchAdditionalSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAdditionalSettingsFailure({ error: err.message }));
    }
  }

  static* fetchFinancialSettings({ payload }) {
    const { partnerId } = payload;
    try {
      const { data } = yield categorySettingsSDK.getFinancialSettings({ partnerId });
      yield put(setFetchFinancialSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchFinancialSettingsFailure({ error: err.message }));
    }
  }

  static* fetchGameStyleSettings({ payload }) {
    const { partnerId } = payload;
    try {
      const { data } = yield categorySettingsSDK.getGameStyleSettings({ partnerId });
      yield put(setFetchGameStyleSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGameStyleSettingsFailure({ error: err.message }));
    }
  }

  static* fetchFinancialUpdate({ payload }) {
    const { params } = payload;
    try {
      const { data } = yield categorySettingsSDK.getFinancialUpdate({ params });
      data.OrderNumber = params.OrderNumber;
      yield put(setFetchFinancialUpdateSuccess({ data }));
    } catch (err) {
      yield put(setFetchFinancialUpdateFailure({ error: err.message, params }));
    }
  }

  static* updateGameStyleSetting({ payload }) {
    const { id, limit, delay, priority } = payload;
    try {
      const { data } = yield categorySettingsSDK.updateGameStyleSetting({ id, limit, delay, priority });
      yield put(setUpdateGameStyleSettingsSuccess({ id, data }));
    } catch (err) {
      yield put(setUpdateGameStyleSettingsFailure({ id, error: err.message }));
    }
  }

  static* updateGameStyleSettingPriority({ payload }) {
    const { partnerId, priorities } = payload;
    try {
      const { data } = yield categorySettingsSDK.updateGameStyleSettingPriority({ partnerId, priorities });
      yield put(setUpdateGameStyleSettingsPrioritySuccess());
      yield put(setFetchGameStyleSettingsSuccess({ data }));
    } catch (err) {
      yield put(setUpdateGameStyleSettingsPriorityFailure({ error: err.message }));
    }
  }

  static* editAdditionalCategorySettings({ payload }) {
    try {
      const { data: response, path, key, keyForEdit, isSubKey, subKey, forDelete } = payload;
      const { data } = yield categorySettingsSDK.editAdditionalCategorySettings({ params: response, path });
      yield put(editAdditionalCategorySettingsSuccess({
        data,
        key,
        keyForEdit,
        isSubKey,
        subKey,
        forDelete: forDelete && response.id
      }));
    } catch (err) {
      yield put(editAdditionalCategorySettingsFailure({ error: err.message, key: payload.key }));
    }
  }

  static* fetchSettingsChangeHistory({ payload }) {
    const { filters } = payload;
    try {
      const response = yield categorySettingsSDK.getSettingsChangeHistory(filters);
      const { data, totalCount } = parseSettingsChangeHistoryModelFromResponse(response);
      yield put(setFetchChangeHistorySuccess({ data, totalCount, passTotalCount: filters.passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchChangeHistoryFailure({ error: err.message }));
    }
  }

  static* fetchAlertMonitorSettingsTable({ payload }) {
    const { filters } = payload;
    try {
      const response = yield categorySettingsSDK.getAlertMonitorSettingsTable(filters);
      const { data, totalCount } = parseSettingsChangeHistoryModelFromResponse(response);
      yield put(setFetchAlertMonitorSettingsTableSuccess({ data, totalCount, passTotalCount: filters.passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAlertMonitorSettingsTableFailure({ error: err.message }));
    }
  }

  static* fetchAlertMonitorSettings({ payload }) {
    const { Id } = payload;
    try {
      const data = yield categorySettingsSDK.getAlertMonitorSettings(Id);
      yield put(setFetchAlertMonitorSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAlertMonitorSettingsFailure({ error: err.message }));
    }
  }

  static* fetchDeleteAlertMonitorSettings({ payload }) {
    const { Id } = payload;
    try {
      yield categorySettingsSDK.getDeleteAlertMonitorSettings(Id);
      yield put(setFetchDeleteAlertMonitorSettingsSuccess({ Id }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchDeleteAlertMonitorSettingsFailure({ error: err.message }));
    }
  }

  static* fetchSaveAlertMonitorSettings({ payload }) {
    const { updateData, editRow, page } = payload;

    try {
      const data = yield categorySettingsSDK.saveAlertMonitorSettings(updateData);
      yield put(setFetchSaveAlertMonitorSettingsSuccess({ data, editRow, page }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchSaveAlertMonitorSettingsFailure({ error: err.message }));
    }
  }

  static* cancelCategorySettingsSDKCalls({ payload }) {
    const { name } = payload;
    yield categorySettingsSDK.cancelCategorySettingsSDKCalls({ name });
  }

  static* actionsWatcher<T>(): Iterable<T> {
    yield takeLatest(SETTINGS_ACTION_TYPES.CANCEL_CS_SDK_CALL, CategorySettingsController.cancelCategorySettingsSDKCalls);
    yield takeLatest(SETTINGS_ACTION_TYPES.EDIT_ADDITIONAL_CATEGORY_SETTINGS, CategorySettingsController.editAdditionalCategorySettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_CHANGE_HISTORY, CategorySettingsController.fetchSettingsChangeHistory);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_ALERT_MONITOR_SETTINGS_TABLE, CategorySettingsController.fetchAlertMonitorSettingsTable);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_ALERT_MONITOR_SETTINGS, CategorySettingsController.fetchAlertMonitorSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_DELETE_ALERT_MONITOR_SETTINGS, CategorySettingsController.fetchDeleteAlertMonitorSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_SAVE_ALERT_MONITOR_SETTINGS, CategorySettingsController.fetchSaveAlertMonitorSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_ADDITIONAL_SETTINGS, CategorySettingsController.fetchAdditionalSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_FINANCIAL_SETTINGS, CategorySettingsController.fetchFinancialSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_GAME_STYLE_SETTINGS, CategorySettingsController.fetchGameStyleSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_FINANCIAL_UPDATE, CategorySettingsController.fetchFinancialUpdate);
    yield takeEvery(SETTINGS_ACTION_TYPES.UPDATE_GAME_STYLE_SETTINGS, CategorySettingsController.updateGameStyleSetting);
    yield takeLatest(SETTINGS_ACTION_TYPES.UPDATE_GAME_STYLE_SETTINGS_PRIORITY, CategorySettingsController.updateGameStyleSettingPriority);
  }
}

export default CategorySettingsController.actionsWatcher;
