import type { CategorySettingsState } from 'modules/SRMT/Settings/types';

import additionalReducer from './additionalReducer';
import financialReducer from './financialReducer';
import gameStyleReducer from './gameStyleReducer';

const initialState = (): CategorySettingsState => ({
  categorySettings: {
    ...additionalReducer.initialState,
    ...financialReducer.initialState,
    ...gameStyleReducer.initialState
  }
});

const actionManager = {
  ...additionalReducer.actionManager,
  ...financialReducer.actionManager,
  ...gameStyleReducer.actionManager
};

export default {
  actionManager,
  initialState
};
