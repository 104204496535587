import { combineReducers } from 'redux';

import partnerAlertSettingsController from 'modules/SRMT/Settings/core/controllers/PartnerAlertSettingsController';
import notificationSettingsController from 'modules/SRMT/Settings/core/controllers/NotificationSettingsController';
import categorySettingsController from 'modules/SRMT/Settings/core/controllers/CategorySettingsController';
import alertMonitorController from 'modules/SRMT/AlertMonitor/core/controllers/AlertMonitorController';
import playerGroupController from 'modules/SRMT/PlayerGroup/core/controllers/PlayerGroupController';
import dashboardController from 'modules/SRMT/Dashboard/core/controllers/DashboardController';
import playersController from 'modules/SRMT/Players/core/controllers/PlayerController';
import reportsController from 'modules/SRMT/Reports/core/controllers/ReportsController';

import ALERT_MONITOR_ROUTES from './AlertMonitor/routes';
import PLAYER_GROUP_ROUTES from './PlayerGroup/routes';
import DASHBOARD_ROUTES from './Dashboard/routes';
import SETTINGS_ROUTES from './Settings/routes';
import PLAYERS_ROUTES from './Players/routes';
import REPORT_ROUTES from './Reports/routes';

import alertMonitor from './AlertMonitor/store/reducers/alertMonitorReducer';
import playerGroup from './PlayerGroup/store/reducers';
import dashboard from './Dashboard/store/reducers';
import settings from './Settings/store/reducers';
import players from './Players/store/reducers';
import reports from './Reports/store/reducers';

const getProjectState = (state) => state.SRMT;

const routes = [
  ...DASHBOARD_ROUTES,
  ...PLAYERS_ROUTES,
  ...PLAYER_GROUP_ROUTES,
  ...SETTINGS_ROUTES,
  ...ALERT_MONITOR_ROUTES,
  ...REPORT_ROUTES
];

const controllers = [
  dashboardController(),
  playersController(),
  playerGroupController(),
  categorySettingsController(),
  alertMonitorController(),
  partnerAlertSettingsController(),
  notificationSettingsController(),
  reportsController()
];

const reducers = combineReducers({
  ...dashboard,
  ...players,
  ...playerGroup,
  ...settings,
  ...reports,
  alertMonitor
});

export {
  getProjectState,
  controllers,
  reducers,
  routes
};
