/**
 * @flow
 * */
import { initialSectionState, initialSectionWithSaveDeleteState } from 'store/reducers/initialStates';
import LCRMT_SETTINGS_TYPES from 'modules/LCRMT/Settings/store/actions/settingsActions/types';
import { SettingsState } from 'modules/LCRMT/Settings/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

const initialState = (): SettingsState => ({
  notificationSettings: initialSectionWithSaveDeleteState(),
  gameTableSettingsFilter: initialSectionState(),
  gameTableSettings: initialSectionState(),
  saveGameTableSettings: initialSectionState()
});

const actionManager = {
  // notification settings
  [LCRMT_SETTINGS_TYPES.FETCH_NOTIFICATION_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_FETCH_NOTIFICATION_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_FETCH_NOTIFICATION_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.CLEAR_NOTIFICATION_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: initialSectionWithSaveDeleteState()
  }),

  // save notification settings
  [LCRMT_SETTINGS_TYPES.SAVE_NOTIFICATION_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      save: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_SAVE_NOTIFICATION_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      save: {
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        data: payload.data
      }
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_SAVE_NOTIFICATION_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      save: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        data: null
      }
    }
  }),

  // delete notification settings
  [LCRMT_SETTINGS_TYPES.DELETE_NOTIFICATION_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      delete: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_DELETE_NOTIFICATION_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      delete: {
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        data: payload.data
      }
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_DELETE_NOTIFICATION_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      delete: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        data: null
      }
    }
  }),

  // game table settings filter
  [LCRMT_SETTINGS_TYPES.FETCH_GAME_TABLE_SETTINGS_FILTER]: (state: SettingsState): SettingsState => ({
    ...state,
    gameTableSettingsFilter: {
      ...state.gameTableSettingsFilter,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_FILTER_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    gameTableSettingsFilter: {
      ...state.gameTableSettingsFilter,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_FILTER_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    gameTableSettingsFilter: {
      ...state.gameTableSettingsFilter,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.CLEAR_GAME_TABLE_SETTINGS_FILTER_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    gameTableSettingsFilter: initialSectionState()
  }),

  // game table settings
  [LCRMT_SETTINGS_TYPES.FETCH_GAME_TABLE_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    gameTableSettings: {
      ...state.gameTableSettings,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    gameTableSettings: {
      ...state.gameTableSettings,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    gameTableSettings: {
      ...state.gameTableSettings,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.CLEAR_GAME_TABLE_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    gameTableSettings: initialSectionState()
  }),

  // game table settings
  [LCRMT_SETTINGS_TYPES.SAVE_GAME_TABLE_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    saveGameTableSettings: {
      ...state.saveGameTableSettings,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_SAVE_GAME_TABLE_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    saveGameTableSettings: {
      ...state.saveGameTableSettings,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [LCRMT_SETTINGS_TYPES.SET_SAVE_GAME_TABLE_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    saveGameTableSettings: {
      ...state.saveGameTableSettings,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [LCRMT_SETTINGS_TYPES.CLEAR_SAVE_GAME_TABLE_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    saveGameTableSettings: initialSectionState()
  })
};

export default (state: SettingsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
