import config from 'config';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import type {
  UpdateGameStyleSettingsPriorityParams, UpdateGameStyleSettingsPriorityModel,
  AlertMonitorSettingsTableParams, AlertMonitorSettingsTableModel,
  UpdateGameStyleSettingsParams, UpdateGameStyleSettingsModel,
  FinancialSettingsUpdateParams, AlertMonitorSettingsModel,
  ChangeHistorySettingsModel, ChangeHistorySettingsParams,
  AdditionalSettingsModel, AdditionalSettingsParams,
  FinancialSettingsModel, FinancialSettingsParams,
  GameStyleSettingsModel, GameStyleSettingsParams
} from './types';

/**
 * @class CategorySettingsSDK
 */
class CategorySettingsSDK extends BaseSDK {
    _rmtApiService: NetworkService = null;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
     * getAdditionalSettings
     * @param {AdditionalSettingsParams} params -
     * @returns {Promise<{data: AdditionalSettingsModel}>} -
     */
    async getAdditionalSettings(params: AdditionalSettingsParams): {data: AdditionalSettingsModel} {
      const { partnerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'CategorySettings/GetAdditionalSettings',
        { query_params: { partnerId }, abortController: this.setAbortController('getAdditionalSettings') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * editAdditionalCategorySettings
     * @param {AdditionalSettingsParams} params - PlayerId
     * @param {String} path -
     * @returns {Promise<any>} -
     */
    async editAdditionalCategorySettings({ params, path }: {params: AdditionalSettingsParams, path: string}) {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        `CategorySettings/${path}`,
        { body: params }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getFinancialSettings
     * @param {FinancialSettingsParams} params -
     * @returns {Promise<{data: FinancialSettingsModel}>} -
     */
    async getFinancialSettings(params: FinancialSettingsParams): {data: FinancialSettingsModel} {
      const { partnerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'CategorySettings/GetFinanceSettings',
        { query_params: { partnerId }, abortController: this.setAbortController('getFinancialSettings') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getGameStyleSettings
     * @param {GameStyleSettingsParams} params -
     * @returns {Promise<{data: Array<GameStyleSettingsModel>}>} -
     */
    async getGameStyleSettings(params: GameStyleSettingsParams): {data: Array<GameStyleSettingsModel>} {
      const { partnerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'CategorySettings/GetGameStyleSettings',
        { query_params: { partnerId }, abortController: this.setAbortController('getGameStyleSettings') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getFinancialUpdate
     * @param {FinancialSettingsParams} params - PlayerId
     * @returns {Promise<{data: FinancialSettingsModel}>} -
     */
    async getFinancialUpdate(params: FinancialSettingsUpdateParams): {data: Object} {
      const { AverageBet, BetCount, Delay, Id, Limit, Profit, Share } = params.params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'CategorySettings/UpdateFinanceSetting',
        { body: { AvgBet: AverageBet, BetCount, Delay, Id, Limit, Profit, Share } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * updateGameStyleSetting
     * @param {UpdateGameStyleSettingsParams} params -
     * @returns {Promise<{data: UpdateGameStyleSettingsModel}>} -
     */
    async updateGameStyleSetting(params: UpdateGameStyleSettingsParams): {data: UpdateGameStyleSettingsModel} {
      const { id, limit, delay, priority } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'CategorySettings/UpdateGameStyleSetting',
        { body: { id, limit, delay, priority } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * updateGameStyleSettingPriority
     * @param {UpdateGameStyleSettingsParams} params -
     * @returns {Promise<{data: UpdateGameStyleSettingsModel}>} -
     */
    async updateGameStyleSettingPriority(params: UpdateGameStyleSettingsPriorityParams): {data: UpdateGameStyleSettingsPriorityModel} {
      const { partnerId, priorities } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'CategorySettings/UpdateGameStyleSettingPriority',
        { body: { partnerId, viewModels: priorities } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getSettingsChangeHistory
     * @param {ChangeHistorySettingsParams} params - PlayerId
     * @returns {Promise<any>} -
     */
    async getSettingsChangeHistory(params: ChangeHistorySettingsParams): { Data: {Models: ?Array<ChangeHistorySettingsModel>, TotalCount: number}} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'CategorySettingsChangeHistory/GetHistory',
        { query_params: params, abortController: this.setAbortController('getSettingsChangeHistory') }
      );

      return body;
    }

    /**
     * getAlertMonitorSettingsTable
     * @param {AlertMonitorSettingsTableParams} params -
     * @returns {Promise<any>} -
     */
    async getAlertMonitorSettingsTable(params: AlertMonitorSettingsTableParams): { Data: {Models: ?Array<AlertMonitorSettingsTableModel>, TotalCount: number}} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'AlertMonitorSetting/GetAlertMonitorSettings',
        { query_params: params, abortController: this.setAbortController('AlertMonitorSettingsTable') }
      );

      return body;
    }

    /**
     * getAlertMonitorSettings
     * @param {AlertMonitorSettingsTableParams} params -
     * @returns {Promise<any>} -
     */
    async getAlertMonitorSettings(params:Object): { Data: {Models: ?Array<AlertMonitorSettingsModel>}} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'AlertMonitorSetting/GetAlertMonitorSettingById',
        { query_params: { Id: params }, abortController: this.setAbortController('AlertMonitorSettings') }
      );

      return body.Data;
    }

    /**
     * getDeleteAlertMonitorSettings
     * @param {Object} params -
     * @returns {Promise<any>} -
     */
    async getDeleteAlertMonitorSettings(params): { Data: {Models:Object}} {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'AlertMonitorSetting/DeleteAlertMonitorSetting',
        { query_params: { Id: params } }
      );

      return body.Data;
    }

    /**
     * saveAlertMonitorSettings
     * @param {Object} params -
     * @returns {Promise<any>} -
     */
    async saveAlertMonitorSettings(params): { Data: {Models:AlertMonitorSettingsModel}} {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'AlertMonitorSetting/SaveAlertMonitorSetting',
        { body: params }
      );

      return body.Data;
    }

    /**
   * cancelCategorySettingsSDKCalls
   * @param {Object} params -
   * @returns {Promise<Object>} -
   */
    cancelCategorySettingsSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new CategorySettingsSDK();
