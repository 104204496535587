/**
 * @flow
 * */
import config from 'config';

import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import {
  LifeTimeReportsParams,
  ReportsParams,
  ReportsModel
} from 'modules/LCRMT/Reports/types';

/**
   * @class ReportsSDK
   */
class ReportsSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * getReports
     * @param {ReportsParams} params -
     * @returns {Promise<{data: ReportsModel}>} -
     */
    async getReports(params: ReportsParams): Promise<{ data: ReportsModel }> {
      const { count, start, filters } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTReport/GetChiSquareReport',
        { query_params: { count, start, ...filters }, abortController: this.setAbortController('getReports') }
      );

      return {
        data: body.Data.Models,
        totalCount: body.Data.TotalCount // @TODO change when backend will be ready
      };
    }

    /**
     * getLifeTimeReports
     * @param {LifeTimeReportsParams} params -
     * @returns {Promise<{data: ReportsModel}>} -
     */
    async getLifeTimeReports(params: LifeTimeReportsParams): Promise<{ data: ReportsModel }> {
      const { filters } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTReport/GetChiSquareLifeTimeReport',
        { query_params: { ...filters }, abortController: this.setAbortController('getLifeTimeReports') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPlayersBetsReports
     * @param {ReportsParams} params -
     * @returns {Promise<{data: ReportsModel}>} -
     */
    async getPlayersBetsReports(params: ReportsParams): Promise<{ data: ReportsModel }> {
      const { count, start, filters, passTotalCount } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTReport/GetBetsReport',
        { body: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('getPlayersBetsReports') }
      );
      return {
        data: body.Data.Models,
        totalCount: body.Data.TotalCount
      };
    }

    /**
     * getPlayersRoundsReports
     * @param {ReportsParams} params -
     * @returns {Promise<{data: ReportsModel}>} -
     */
    async getPlayersRoundsReports(params: ReportsParams): Promise<{ data: ReportsModel }> {
      const { count, start, filters, passTotalCount } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTReport/GetRoundsReport',
        { body: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('getPlayersRoundsReports') }
      );
      return {
        data: body.Data.Models,
        totalCount: body.Data.TotalCount
      };
    }

    /**
   * getPartnersInfoReports
   * @param {ReportsParams} params -
   * @returns {Promise<{data: ReportsModel}>} -
   */
    async getPartnersInfoReports(params: ReportsParams): Promise<{ data: ReportsModel }> {
      const { count, start, filters, passTotalCount } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTReport/GetPartnersInfoReport',
        { body: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('GetPartnersInfoReport') }
      );
      return {
        data: body.Data.Models,
        totalCount: body.Data.TotalCount
      };
    }

    /**
   * cancelReportSDKCalls
   * @param {Object} params -
   * @returns {Object} -
   */
    cancelReportSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new ReportsSDK();
