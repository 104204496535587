/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

const ChiSquareLifeTimeReportsPage = React.lazy(() => import('./views/pages/ChiSquareLifeTimeReports'));
const ChiSquareReportsPage = React.lazy(() => import('./views/pages/ChiSquareReports'));
const PlayersBetsReportsPage = React.lazy(() => import('./views/pages/PlayersBetsReport'));
const PlayersRoundsReportsPage = React.lazy(() => import('./views/pages/PlayersRoundsReport'));
const PartnersInfoReportsPage = React.lazy(() => import('./views/pages/PartnersInfoReport'));

const subUrl = settings.PRODUCTS.LCRMT.path;
const modulePath = `${subUrl}/reports`;
const modulePathOld = 'grmt/reports';

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const ROUTES: Array<Route> = [
  { path: `/${modulePath}/`, name: 'Reports', exact: true, page: () => <Redirect to={`/${modulePath}/chi-square-reports`} /> },
  { path: `/${modulePath}/chi-square-reports`, name: 'Chi Square Reports', exact: true, page: ChiSquareReportsPage },
  { path: `/${modulePath}/chi-square-lifetime-reports`, name: 'Chi Square LifeTime Reports', exact: true, page: ChiSquareLifeTimeReportsPage },
  { path: `/${modulePath}/players-bets-report`, name: 'Players Bets Report', exact: true, page: PlayersBetsReportsPage },
  { path: `/${modulePath}/players-rounds-report`, name: 'Players Rounds Report', exact: true, page: PlayersRoundsReportsPage },
  { path: `/${modulePath}/partners-info-report`, name: 'Partners Info Report', exact: true, page: PartnersInfoReportsPage },
  { path: `/${modulePathOld}/`, name: 'Reports', exact: true, page: () => <Redirect to={`/${modulePathOld}/chi-square-reports`} /> },
  { path: `/${modulePathOld}/chi-square-reports`, name: 'Chi Square Reports', exact: true, page: ChiSquareReportsPage },
  { path: `/${modulePathOld}/chi-square-lifetime-reports`, name: 'Chi Square LifeTime Reports', exact: true, page: ChiSquareLifeTimeReportsPage }
];

export default ROUTES;
