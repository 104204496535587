/* eslint-disable */
/**
 * @flow
 * */
import React from 'react';
import { history } from 'config/history';
import {Router} from 'react-router-dom';
import ReactGA from 'react-ga';
import config from 'config';

import {pdfConfig} from './config/settings/indexDBConfig';
import AppContainer from "./views/components/AppContainer";
import {initDB} from "react-indexed-db";
initDB(pdfConfig);

if (config.isEnvProduction) {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);

      // Hotjar Tracking Code for https://umbrella.betconstruct.com/

      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3418079,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');
          // $FlowFixMe
          r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3600734,hjsv:6};
        a=o.getElementsByTagName('head')[0];
          // $FlowFixMe
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

  });
}
export default () =>  (
    <Router history={history}>
        <AppContainer />
    </Router>
)
