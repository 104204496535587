/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import ACTIONS from 'modules/LCRMT/Alerts/store/actions/alerts/chiSquareAlerts/types';
import alertsSDK from 'modules/LCRMT/Alerts/core/sdk/AlertsSDK';
import {
  setFetchChiSquareAlertsSuccess,
  setFetchChiSquareAlertsFailure,
  setFetchAlertReviewsSuccess,
  setFetchAlertReviewsFailure,
  setCloseAlertReviewSuccess,
  setCloseAlertReviewFailure,
  setResolveAlertReviewSuccess,
  setResolveAlertReviewFailure,
  setFetchAlertReviewStatisticsSuccess,
  setFetchAlertReviewStatisticsFailure
} from 'modules/LCRMT/Alerts/store/actions/alerts/chiSquareAlerts';

class AlertsController {
  static* fetchAlerts({ payload }) {
    const { count, start, filters } = payload;
    try {
      const { data, totalCount } = yield alertsSDK.getAlerts({ count, start, filters });
      yield put(setFetchChiSquareAlertsSuccess({ data, totalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchChiSquareAlertsFailure({ error: err.message }));
    }
  }

  static* fetchAlertReviews({ payload }) {
    const { alertId } = payload;
    try {
      const { data } = yield alertsSDK.getAlertReview({ alertId });
      yield put(setFetchAlertReviewsSuccess({ data }));
    } catch (err) {
      yield put(setFetchAlertReviewsFailure({ error: err.message }));
    }
  }

  static* closeAlertReview({ payload }) {
    const { alertId } = payload;
    try {
      yield alertsSDK.closeAlertReview({ alertId });
      yield put(setCloseAlertReviewSuccess());
    } catch (err) {
      yield put(setCloseAlertReviewFailure({ error: err.message }));
    }
  }

  static* resolveAlertReview({ payload }) {
    const { alertId, comment } = payload;
    try {
      yield alertsSDK.resolveAlertReview({ alertId, comment });
      yield put(setResolveAlertReviewSuccess());
    } catch (err) {
      yield put(setResolveAlertReviewFailure({ error: err.message }));
    }
  }

  static* fetchAlertReviewStatistics({ payload }) {
    const { alertId } = payload;
    try {
      const { data } = yield alertsSDK.getAlertReviewStatistics({ alertId });
      yield put(setFetchAlertReviewStatisticsSuccess({ data }));
    } catch (err) {
      yield put(setFetchAlertReviewStatisticsFailure({ error: err.message }));
    }
  }

  static* cancelAlertSDKCalls({ payload }) {
    const { name } = payload;
    yield alertsSDK.cancelAlertSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.CANCEL_ALERT_SDK_CALL, AlertsController.cancelAlertSDKCalls);
    yield takeLatest(ACTIONS.FETCH_CHI_SQUARE_ALERTS, AlertsController.fetchAlerts);
    yield takeLatest(ACTIONS.FETCH_ALERT_REVIEWS, AlertsController.fetchAlertReviews);
    yield takeLatest(ACTIONS.CLOSE_ALERT_REVIEW, AlertsController.closeAlertReview);
    yield takeLatest(ACTIONS.RESOLVE_ALERT_REVIEW, AlertsController.resolveAlertReview);
    yield takeLatest(ACTIONS.FETCH_ALERT_REVIEWS_STATISTICS, AlertsController.fetchAlertReviewStatistics);
  }
}

export default AlertsController.actionsWatcher;
