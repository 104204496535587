import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createUserManagementSymbol = createSymbol('USERMANAGEMENT');
const createUserManagementInternalSymbol = createInternalSymbol('USERMANAGEMENT');

export default {
  // Users
  FETCH_USERS: createUserManagementSymbol('FETCH_USERS'),
  SET_FETCH_USERS_SUCCESS: createUserManagementSymbol('SET_FETCH_USERS_SUCCESS'),
  SET_FETCH_USERS_FAILURE: createUserManagementSymbol('SET_FETCH_USERS_FAILURE'),
  CLEAR_USERS_STATE: createUserManagementInternalSymbol('CLEAR_USERS_STATE'),

  // cancel calls
  CANCEL_USERS_CALL: createUserManagementSymbol('CANCEL_USERS_CALL'),

  // role templates
  FETCH_ROLE_TEMPLATES: createUserManagementSymbol('FETCH_ROLE_TEMPLATES'),
  SET_ROLE_TEMPLATES_SUCCESS: createUserManagementSymbol('SET_ROLE_TEMPLATES_SUCCESS'),
  SET_ROLE_TEMPLATES_FAILURE: createUserManagementSymbol('SET_ROLE_TEMPLATES_FAILURE'),
  CLEAR_ROLE_TEMPLATES: createUserManagementInternalSymbol('CLEAR_ROLE_TEMPLATES'),
  // all resources
  FETCH_ALL_RESOURCES: createUserManagementSymbol('FETCH_ALL_RESOURCES'),
  SET_ALL_RESOURCES_SUCCESS: createUserManagementSymbol('SET_ALL_RESOURCES_SUCCESS'),
  SET_ALL_RESOURCES_FAILURE: createUserManagementSymbol('SET_ALL_RESOURCES_FAILURE'),
  CLEAR_ALL_RESOURCES: createUserManagementInternalSymbol('CLEAR_ALL_RESOURCES'),
  // role resources
  FETCH_ROLE_RESOURCES: createUserManagementSymbol('FETCH_ROLE_RESOURCES'),
  SET_ROLE_RESOURCES_SUCCESS: createUserManagementSymbol('SET_ROLE_RESOURCES_SUCCESS'),
  SET_ROLE_RESOURCES_FAILURE: createUserManagementSymbol('SET_ROLE_RESOURCES_FAILURE'),
  CLEAR_ROLE_RESOURCES: createUserManagementInternalSymbol('CLEAR_ROLE_RESOURCES'),
  // template resources
  FETCH_TEMPLATE_RESOURCES: createUserManagementSymbol('FETCH_TEMPLATE_RESOURCES'),
  SET_TEMPLATE_RESOURCES_SUCCESS: createUserManagementSymbol('SET_TEMPLATE_RESOURCES_SUCCESS'),
  SET_TEMPLATE_RESOURCES_FAILURE: createUserManagementSymbol('SET_TEMPLATE_RESOURCES_FAILURE'),
  CLEAR_TEMPLATE_RESOURCES: createUserManagementInternalSymbol('CLEAR_TEMPLATE_RESOURCES'),
  // user group resources
  FETCH_USER_GROUP_RESOURCES: createUserManagementSymbol('FETCH_USER_GROUP_RESOURCES'),
  SET_USER_GROUP_RESOURCES_SUCCESS: createUserManagementSymbol('SET_USER_GROUP_RESOURCES_SUCCESS'),
  SET_USER_GROUP_RESOURCES_FAILURE: createUserManagementSymbol('SET_USER_GROUP_RESOURCES_FAILURE'),
  CLEAR_USER_GROUP_RESOURCES: createUserManagementInternalSymbol('CLEAR_USER_GROUP_RESOURCES'),
  // User Operating Partner Update
  FETCH_OPERATING_PARTNER_UPDATE: createUserManagementSymbol('FETCH_OPERATING_PARTNER_UPDATE'),
  SET_FETCH_OPERATING_PARTNER_UPDATE_SUCCESS: createUserManagementSymbol('SET_FETCH_OPERATING_PARTNER_UPDATE_SUCCESS'),
  SET_FETCH_OPERATING_PARTNER_UPDATE_FAILURE: createUserManagementSymbol('SET_FETCH_OPERATING_PARTNER_UPDATE_FAILURE'),
  CLEAR_OPERATING_PARTNER_UPDATE: createUserManagementInternalSymbol('CLEAR_OPERATING_PARTNER_UPDATE'),
  // add resources
  ADD_RESOURCES: createUserManagementSymbol('ADD_RESOURCES'),
  SET_ADD_RESOURCES_SUCCESS: createUserManagementSymbol('SET_ADD_RESOURCES_SUCCESS'),
  SET_ADD_RESOURCES_FAILURE: createUserManagementSymbol('SET_ADD_RESOURCES_FAILURE'),
  CLEAR_ADD_RESOURCES: createUserManagementInternalSymbol('CLEAR_ADD_RESOURCES'),
  // assign info
  SET_ASSIGN_INFO: createUserManagementSymbol('SET_ASSIGN_INFO'),
  CLEAR_ASSIGN_INFO: createUserManagementInternalSymbol('CLEAR_ASSIGN_INFO'),

  // Roles
  FETCH_ROLE_PERMISSION: createUserManagementSymbol('FETCH_ROLE_PERMISSION'),
  SET_FETCH_ROLE_PERMISSION_SUCCESS: createUserManagementSymbol('SET_FETCH_ROLE_PERMISSION_SUCCESS'),
  SET_FETCH_ROLE_PERMISSION_FAILURE: createUserManagementSymbol('SET_FETCH_ROLE_PERMISSION_FAILURE'),
  CLEAR_ROLE_PERMISSION_STATE: createUserManagementInternalSymbol('CLEAR_ROLE_PERMISSION_STATE'),

  // additional resources
  FETCH_ADDITIONAL_RESOURCES: createUserManagementSymbol('FETCH_ADDITIONAL_RESOURCES'),
  SET_ADDITIONAL_RESOURCES_SUCCESS: createUserManagementSymbol('SET_ADDITIONAL_RESOURCES_SUCCESS'),
  SET_ADDITIONAL_RESOURCES_FAILURE: createUserManagementSymbol('SET_ADDITIONAL_RESOURCES_FAILURE'),
  CLEAR_ADDITIONAL_RESOURCES: createUserManagementInternalSymbol('CLEAR_ADDITIONAL_RESOURCES'),

  // users partner
  FETCH_USERS_PARTNER: createUserManagementSymbol('FETCH_USERS_PARTNER'),
  SET_USERS_PARTNER_SUCCESS: createUserManagementSymbol('SET_USERS_PARTNER_SUCCESS'),
  SET_USERS_PARTNER_FAILURE: createUserManagementSymbol('SET_USERS_PARTNER_FAILURE'),
  CLEAR_USERS_PARTNER: createUserManagementInternalSymbol('CLEAR_USERS_PARTNER'),

  // deactivate user
  FETCH_DEACTIVATE_USER: createUserManagementSymbol('FETCH_DEACTIVATE_USER'),
  SET_DEACTIVATE_USER_SUCCESS: createUserManagementSymbol('SET_DEACTIVATE_USER_SUCCESS'),
  SET_DEACTIVATE_USER_FAILURE: createUserManagementSymbol('SET_DEACTIVATE_USER_FAILURE'),
  CLEAR_DEACTIVATE_USER: createUserManagementInternalSymbol('CLEAR_DEACTIVATE_USER'),

  // cancel roles calls
  CANCEL_ROLES_CALL: createUserManagementSymbol('CANCEL_ROLES_CALL')
};
