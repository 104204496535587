/**
 * @flow
 * */
import type { AlertsState } from 'modules/LCRMT/Alerts/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import {
  createChiSquareAlertsInitialState,
  createChiSquareTablesInitialState,
  createAlertReviewInitialState,
  createCloseAlertReviewInitialState
} from './initialStates';

import CHI_SQUARE_ALERTS_ACTION_TYPES from '../actions/alerts/chiSquareAlerts/types';

const initialState = (): AlertsState => ({
  chiSquareAlerts: createChiSquareAlertsInitialState(),
  chiSquareTables: createChiSquareTablesInitialState(),
  review: createAlertReviewInitialState(),
  alertReviewStatistics: createAlertReviewInitialState(),
  closeReview: createCloseAlertReviewInitialState(),
  resolveReview: createCloseAlertReviewInitialState()
});

const actionManager = {

  // alerts
  [CHI_SQUARE_ALERTS_ACTION_TYPES.FETCH_CHI_SQUARE_ALERTS]: (state: AlertsState): AlertsState => ({
    ...state,
    chiSquareAlerts: {
      ...state.chiSquareAlerts,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_ALERTS_SUCCESS]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    chiSquareAlerts: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data,
      totalCount: payload.totalCount
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_ALERTS_FAILURE]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    chiSquareAlerts: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null

    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_CHI_SQUARE_ALERTS_STATE]: (state: AlertsState): AlertsState => ({
    ...state,
    chiSquareAlerts: createChiSquareAlertsInitialState()
  }),

  // alert reviews
  [CHI_SQUARE_ALERTS_ACTION_TYPES.FETCH_ALERT_REVIEWS]: (state: AlertsState): AlertsState => ({
    ...state,
    review: {
      ...state.review,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_SUCCESS]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    review: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_FAILURE]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    review: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_ALERT_REVIEWS_STATE]: (state: AlertsState): AlertsState => ({
    ...state,
    review: createAlertReviewInitialState()
  }),

  // close alert review
  [CHI_SQUARE_ALERTS_ACTION_TYPES.CLOSE_ALERT_REVIEW]: (state: AlertsState): AlertsState => ({
    ...state,
    closeReview: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_CLOSE_ALERT_REVIEW_SUCCESS]: (state: AlertsState): AlertsState => ({
    ...state,
    closeReview: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_CLOSE_ALERT_REVIEW_FAILURE]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    closeReview: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_CLOSE_REVIEW_STATE]: (state: AlertsState): AlertsState => ({
    ...state,
    closeReview: createCloseAlertReviewInitialState()
  }),

  // resolve alert review
  [CHI_SQUARE_ALERTS_ACTION_TYPES.RESOLVE_ALERT_REVIEW]: (state: AlertsState): AlertsState => ({
    ...state,
    resolveReview: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_RESOLVE_ALERT_REVIEW_SUCCESS]: (state: AlertsState): AlertsState => ({
    ...state,
    resolveReview: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_RESOLVE_ALERT_REVIEW_FAILURE]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    resolveReview: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_RESOLVE_REVIEW_STATE]: (state: AlertsState): AlertsState => ({
    ...state,
    resolveReview: createCloseAlertReviewInitialState()
  }),

  // alert reviews statistics
  [CHI_SQUARE_ALERTS_ACTION_TYPES.FETCH_ALERT_REVIEWS_STATISTICS]: (state: AlertsState): AlertsState => ({
    ...state,
    alertReviewStatistics: {
      ...state.alertReviewStatistics,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_STATISTICS_SUCCESS]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    alertReviewStatistics: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_STATISTICS_FAILURE]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    alertReviewStatistics: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_ALERT_REVIEWS_STATISTICS_STATE]: (state: AlertsState): AlertsState => ({
    ...state,
    alertReviewStatistics: createAlertReviewInitialState()
  }),

  // update alerts
  [CHI_SQUARE_ALERTS_ACTION_TYPES.UPDATE_ALERT]: (state: AlertsState, payload): AlertsState => ({
    ...state,
    chiSquareAlerts: {
      ...state.chiSquareAlerts,
      data: payload
    }
  })
};

export default (state: AlertsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
