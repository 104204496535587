/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import { parsePartnersModelFromResponse } from 'modules/Admin/PartnerLicenseManagement/utils/parsers';

import {
  setAddResourcesFailure,
  setAddResourcesSuccess,
  setAllResourcesFailure,
  setAllResourcesSuccess,
  setRoleResourcesFailure,
  setRoleResourcesSuccess,
  setRoleTemplatesFailure,
  setRoleTemplatesSuccess,
  setTemplateResourcesFailure,
  setTemplateResourcesSuccess,
  setUserGroupResourcesFailure,
  setUserGroupResourcesSuccess,
  setFetchUsersSuccess,
  setFetchUsersFailure,
  setFetchRolePermissionFailure,
  setFetchRolePermissionSuccess,
  setAdditionalResourcesSuccess,
  setAdditionalResourcesFailure,
  setFetchOperatingPartnerUpdateSuccess,
  setFetchOperatingPartnerUpdateFailure,
  setUsersPartnerSuccess,
  setUsersPartnerFailure,
  setDeactivationUserSuccess,
  setDeactivationUserFailure
} from 'modules/Admin/UserManagement/store/actions/userManagement';
import ACTIONS from 'modules/Admin/UserManagement/store/actions/userManagement/types';
import userManagementSDK from 'modules/Admin/UserManagement/core/sdk/UserManagementSDK';

class UserManagementController {
  static* fetchUsers({ payload }) {
    const { start, count, filters, passTotalCount } = payload;
    try {
      const response = yield userManagementSDK.getUsers({ filters, count, start, passTotalCount });
      const { data, totalCount } = parsePartnersModelFromResponse(response);
      yield put(setFetchUsersSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchUsersFailure(err.message));
    }
  }

  static* fetchRoleTabData() {
    try {
      const data = yield userManagementSDK.getRoleData();
      yield put(setFetchRolePermissionSuccess({ data }));
    } catch (err) {
      yield put(setFetchRolePermissionFailure(err.message));
    }
  }

  static* fetchRoleTemplates() {
    try {
      const { data } = yield userManagementSDK.getRoleTemplates();
      yield put(setRoleTemplatesSuccess({ data }));
    } catch (err) {
      yield put(setRoleTemplatesFailure({ error: err.message }));
    }
  }

  static* fetchAllResources() {
    try {
      const { data } = yield userManagementSDK.getAllResources();
      yield put(setAllResourcesSuccess({ data }));
    } catch (err) {
      yield put(setAllResourcesFailure({ error: err.message }));
    }
  }

  static* fetchRoleResources({ payload }) {
    const { id } = payload;
    try {
      const { data } = yield userManagementSDK.getRoleResources({ id });
      yield put(setRoleResourcesSuccess({ data }));
    } catch (err) {
      yield put(setRoleResourcesFailure({ error: err.message }));
    }
  }

  static* fetchUserGroupResources({ payload }) {
    const { userId } = payload;
    try {
      const { data } = yield userManagementSDK.getUserGroupResources({ userId });
      yield put(setUserGroupResourcesSuccess({ data }));
    } catch (err) {
      yield put(setUserGroupResourcesFailure({ error: err.message }));
    }
  }

  static* setAddResources({ payload }) {
    try {
      const { data } = yield userManagementSDK.setResources(payload);
      yield put(setAddResourcesSuccess({ data }));
    } catch (err) {
      yield put(setAddResourcesFailure({ error: err.message }));
    }
  }

  static* fetchTemplateResources({ payload }) {
    const { id } = payload;
    try {
      const { data } = yield userManagementSDK.getRoleResources({ id });
      yield put(setTemplateResourcesSuccess({ data }));
    } catch (err) {
      yield put(setTemplateResourcesFailure({ error: err.message }));
    }
  }

  static* updateOperatingPartner({ payload }) {
    const { params } = payload;
    try {
      const data = yield userManagementSDK.UpdateOperatingPartners(params);
      yield put(setFetchOperatingPartnerUpdateSuccess({ data }));
    } catch (err) {
      yield put(setFetchOperatingPartnerUpdateFailure(err.message));
    }
  }

  static* fetchAdditionalResources({ payload }) {
    const { userId } = payload;
    try {
      const { data } = yield userManagementSDK.getUserGroupResources({ userId });
      yield put(setAdditionalResourcesSuccess({ data }));
    } catch (err) {
      yield put(setAdditionalResourcesFailure({ error: err.message }));
    }
  }

  static* cancelUserManagementSDKCalls({ payload }) {
    const { name } = payload;
    yield userManagementSDK.cancelUserManagementSDKCalls({ name });
  }

  static* cancelRolesDataCall({ payload }) {
    const { name } = payload;
    yield userManagementSDK.cancelRolesDataCAll({ name });
  }

  static* fetchUsersPartner({ payload }) {
    const { userIds } = payload;
    try {
      const { data } = yield userManagementSDK.getUsersPartner({ userIds });
      yield put(setUsersPartnerSuccess({ data }));
    } catch (err) {
      yield put(setUsersPartnerFailure({ error: err.message }));
    }
  }

  static* fetchDeactivationUser({ payload }) {
    const { userId } = payload;
    try {
      yield userManagementSDK.deactivationUser({ userId });
      yield put(setDeactivationUserSuccess());
    } catch (err) {
      yield put(setDeactivationUserFailure({ error: err.message }));
    }
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.FETCH_USERS, UserManagementController.fetchUsers);
    yield takeLatest(ACTIONS.CANCEL_USERS_CALL, UserManagementController.cancelUserManagementSDKCalls);
    yield takeLatest(ACTIONS.FETCH_OPERATING_PARTNER_UPDATE, UserManagementController.updateOperatingPartner);
    yield takeLatest(ACTIONS.FETCH_ROLE_PERMISSION, UserManagementController.fetchRoleTabData);
    yield takeLatest(ACTIONS.FETCH_ROLE_TEMPLATES, UserManagementController.fetchRoleTemplates);
    yield takeLatest(ACTIONS.CANCEL_ROLES_CALL, UserManagementController.cancelRolesDataCall);
    yield takeLatest(ACTIONS.FETCH_ALL_RESOURCES, UserManagementController.fetchAllResources);
    yield takeLatest(ACTIONS.FETCH_ROLE_RESOURCES, UserManagementController.fetchRoleResources);
    yield takeLatest(ACTIONS.FETCH_TEMPLATE_RESOURCES, UserManagementController.fetchTemplateResources);
    yield takeLatest(ACTIONS.FETCH_ADDITIONAL_RESOURCES, UserManagementController.fetchAdditionalResources);
    yield takeLatest(ACTIONS.FETCH_USER_GROUP_RESOURCES, UserManagementController.fetchUserGroupResources);
    yield takeLatest(ACTIONS.ADD_RESOURCES, UserManagementController.setAddResources);
    yield takeLatest(ACTIONS.FETCH_USERS_PARTNER, UserManagementController.fetchUsersPartner);
    yield takeLatest(ACTIONS.FETCH_DEACTIVATE_USER, UserManagementController.fetchDeactivationUser);
  }
}

export default UserManagementController.actionsWatcher;
