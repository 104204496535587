/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import { initialSectionState, initialSectionWithoutData } from 'lib/core/initialStates';
import APP_SETTINGS_ACTION_TYPES from 'store/actions/appSettings/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { AppSettingsState } from 'store/types';

const initialState = (): AppSettingsState => ({
  appSettings: {
    ...initialSectionWithoutData(),
    data: {
      viewMode: null,
      language: null
    },
    update: initialSectionWithoutData()
  },
  agpSettings: {
    ...initialSectionState(),
    update: initialSectionWithoutData()
  }
});

const actionManager = {
  // agp setting
  [APP_SETTINGS_ACTION_TYPES.SET_FETCH_AGP_SETTINGS_SUCCESS]: (state: AppSettingsState, payload) => ({
    ...state,
    agpSettings: {
      ...state.agpSettings,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),

  // AGP update Settings
  [APP_SETTINGS_ACTION_TYPES.UPDATE_AGP_SETTINGS]: (state: AppSettingsState) => ({
    ...state,
    agpSettings: {
      ...state.agpSettings,
      update: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [APP_SETTINGS_ACTION_TYPES.SET_UPDATE_AGP_SETTINGS_SUCCESS]: (state: AppSettingsState, payload) => ({
    ...state,
    agpSettings: {
      ...state.agpSettings,
      data: {
        ...state.appSettings.data,
        TimeZones: state.agpSettings.data.TimeZones.map(item => ({ ...item, IsSelected: item.Id === payload.id }))
      },
      update: {
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [APP_SETTINGS_ACTION_TYPES.SET_UPDATE_AGP_SETTINGS_FAILURE]: (state: AppSettingsState, payload) => ({
    ...state,
    agpSettings: {
      ...state.agpSettings,
      update: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [APP_SETTINGS_ACTION_TYPES.CLEAR_AGP_SETTINGS]: (state: AppSettingsState) => ({
    ...state,
    agpSettings: {
      ...state.agpSettings,
      update: initialSectionWithoutData()
    }
  }),

  // get app Settings
  [APP_SETTINGS_ACTION_TYPES.FETCH_APP_SETTINGS]: (state: AppSettingsState) => ({
    ...state,
    appSettings: {
      ...state.appSettings,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [APP_SETTINGS_ACTION_TYPES.SET_FETCH_APP_SETTINGS_SUCCESS]: (state: AppSettingsState, payload) => ({
    ...state,
    appSettings: {
      ...state.appSettings,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [APP_SETTINGS_ACTION_TYPES.SET_FETCH_APP_SETTINGS_FAILURE]: (state: AppSettingsState, payload) => ({
    ...state,
    appSettings: {
      ...state.appSettings,
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),

  // update views mode
  [APP_SETTINGS_ACTION_TYPES.UPDATE_APP_SETTINGS]: (state: AppSettingsState) => ({
    ...state,
    appSettings: {
      ...state.appSettings,
      update: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [APP_SETTINGS_ACTION_TYPES.SET_UPDATE_APP_SETTINGS_SUCCESS]: (state: AppSettingsState, payload) => ({
    ...state,
    appSettings: {
      ...state.appSettings,
      data: payload,
      update: {
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [APP_SETTINGS_ACTION_TYPES.SET_UPDATE_APP_SETTINGS_FAILURE]: (state: AppSettingsState, payload) => ({
    ...state,
    appSettings: {
      ...state.appSettings,
      update: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  })
};

export default (state : AppSettingsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
