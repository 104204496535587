/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import LCRMT_SETTINGS_TYPES from './types';

// notification settings
export const fetchNotificationSettings = ():Action => ({
  type: LCRMT_SETTINGS_TYPES.FETCH_NOTIFICATION_SETTINGS
});

export const fetchNotificationSettingsSuccess = ({ data }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  payload: { data }
});

export const fetchNotificationSettingsFailure = ({ error }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_FETCH_NOTIFICATION_SETTINGS_FAILURE,
  payload: { error }
});

export const removeNotificationSettingsState = ():Action => ({
  type: LCRMT_SETTINGS_TYPES.CLEAR_NOTIFICATION_SETTINGS_STATE
});

// save notification settings
export const saveNotificationSettings = ({ params }:Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SAVE_NOTIFICATION_SETTINGS,
  payload: { params }
});

export const saveNotificationSettingsSuccess = ({ data }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_SAVE_NOTIFICATION_SETTINGS_SUCCESS,
  payload: { data }
});

export const saveNotificationSettingsFailure = ({ error }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_SAVE_NOTIFICATION_SETTINGS_FAILURE,
  payload: { error }
});

// delete notification settings
export const deleteNotificationSettings = ({ Id }:Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.DELETE_NOTIFICATION_SETTINGS,
  payload: { Id }
});

export const deleteNotificationSettingsSuccess = ({ data }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_DELETE_NOTIFICATION_SETTINGS_SUCCESS,
  payload: { data }
});

export const deleteNotificationSettingsFailure = ({ error }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_DELETE_NOTIFICATION_SETTINGS_FAILURE,
  payload: { error }
});

// game table settings filter
export const fetchGameTableSettingsFilter = ():Action => ({
  type: LCRMT_SETTINGS_TYPES.FETCH_GAME_TABLE_SETTINGS_FILTER
});

export const fetchGameTableSettingsFilterSuccess = ({ data }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_FILTER_SUCCESS,
  payload: { data }
});

export const fetchGameTableSettingsFilterFailure = ({ error }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_FILTER_FAILURE,
  payload: { error }
});

export const removeGameTableSettingsFilterState = ():Action => ({
  type: LCRMT_SETTINGS_TYPES.CLEAR_GAME_TABLE_SETTINGS_FILTER_STATE
});

// game table settings
export const fetchGameTableSettings = ({ gameId }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.FETCH_GAME_TABLE_SETTINGS,
  payload: { gameId }
});

export const fetchGameTableSettingsSuccess = ({ data }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_SUCCESS,
  payload: { data }
});

export const fetchGameTableSettingsFailure = ({ error }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_FETCH_GAME_TABLE_SETTINGS_FAILURE,
  payload: { error }
});

export const removeGameTableSettingsState = ():Action => ({
  type: LCRMT_SETTINGS_TYPES.CLEAR_GAME_TABLE_SETTINGS_STATE
});

// save game table settings
export const saveGameTableSettings = ({ savedData }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SAVE_GAME_TABLE_SETTINGS,
  payload: { savedData }
});

export const saveGameTableSettingsSuccess = ({ data }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_SAVE_GAME_TABLE_SETTINGS_SUCCESS,
  payload: { data }
});

export const saveGameTableSettingsFailure = ({ error }: Object):Action => ({
  type: LCRMT_SETTINGS_TYPES.SET_SAVE_GAME_TABLE_SETTINGS_FAILURE,
  payload: { error }
});

export const removeSaveGameTableSettingsState = ():Action => ({
  type: LCRMT_SETTINGS_TYPES.CLEAR_SAVE_GAME_TABLE_SETTINGS_STATE
});

// cancel call
export const cancelSettingsDKCalls = ({ name }: Object): Action => ({
  type: LCRMT_SETTINGS_TYPES.CANCEL_LCRMT_SETTINGS_CALL,
  payload: { name }
});
