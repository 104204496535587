/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import AutomatedCommentsSDK from 'modules/Admin/AutomatedComments/core/sdk/AutomatedCommentsSDK';
import ACTIONS from 'modules/Admin/AutomatedComments/store/actions/types';
import { parseDataWithTotalCount } from 'lib/helpers/parser';

import {
  setFetchActionAutomatedCommentsFailure,
  setFetchActionAutomatedCommentsSuccess,
  setFetchDeleteAutomatedCommentsFailure,
  setFetchDeleteAutomatedCommentsSuccess,
  setFetchAutomatedCommentsSuccess,
  setFetchAutomatedCommentsFailure
} from 'modules/Admin/AutomatedComments/store/actions';

class AutomatedCommentsController {
  static* fetchAutomatedComments({ payload }) {
    const { start, count, passTotalCount } = payload;
    try {
      const response = yield AutomatedCommentsSDK.getAutomatedComments({ count, start, passTotalCount });
      const { data, totalCount } = parseDataWithTotalCount(response);
      yield put(setFetchAutomatedCommentsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAutomatedCommentsFailure(err.message));
    }
  }

  static* fetchActionAutomatedComments({ payload }) {
    const { data, page } = payload;
    try {
      const response = yield AutomatedCommentsSDK.actionAutomatedComments(data);
      yield put(setFetchActionAutomatedCommentsSuccess({ id: data.id, data: response, page }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchActionAutomatedCommentsFailure(err.message));
    }
  }

  static* fetchDeleteAutomatedComments({ payload }) {
    const id = payload;
    try {
      yield AutomatedCommentsSDK.deleteAutomatedComments(id);
      yield put(setFetchDeleteAutomatedCommentsSuccess(id));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchDeleteAutomatedCommentsFailure(err.message));
    }
  }

  static* cancelAutomatedCommentsCall({ payload }) {
    const { name } = payload;
    yield AutomatedCommentsSDK.cancelAutomatedCommentsSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.FETCH_AUTOMATED_COMMENT, AutomatedCommentsController.fetchAutomatedComments);
    yield takeLatest(ACTIONS.FETCH_ACTION_AUTOMATED_COMMENT, AutomatedCommentsController.fetchActionAutomatedComments);
    yield takeLatest(ACTIONS.FETCH_DELETE_AUTOMATED_COMMENT, AutomatedCommentsController.fetchDeleteAutomatedComments);
    yield takeLatest(ACTIONS.CANCEL_AUTOMATED_COMMENT_SDK_CALL, AutomatedCommentsController.cancelAutomatedCommentsCall);
  }
}

export default AutomatedCommentsController.actionsWatcher;
