import notFoundDetector from 'core/services/network/interceptors/notFoundDetector';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';
import type { GlobalInfoModel, GlobalInfoParams } from './types';

class PlayerGeneralSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    _amlApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, [notFoundDetector]);
      this._amlApiService = new NetworkService(config.amlApi, [notFoundDetector]);
    }

    /**
     * getGlobalInfo
     * @param {GlobalInfoParams} params - PlayerId
     * @returns {Promise<{data: GlobalInfoModel}>} -
     */
    async getGlobalInfo(params: GlobalInfoParams): Promise<{data: GlobalInfoModel}> {
      const { playerId, productType } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetPlayerGlobalInfo',
        { query_params: { playerId, productType }, abortController: this.setAbortController('getGlobalInfo') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * cancelPlayerGeneralSDKCalls
     * @param {String} params -
     * @returns {Promise} -
     */
    cancelPlayerGeneralSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new PlayerGeneralSDK();
