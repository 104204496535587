const alertStatusesById = {
  2: 'Confirmed',
  3: 'Rejected'
};
const alertStatusesByKey = {
  Confirmed: '2',
  Rejected: '3',
  Created: '0'
};

export const alertStatusHandlerAfterReview = (actionType, selectedAlerts, filterStatuses, alerts, user) => {
  alerts.forEach(item => {
    if (selectedAlerts.includes(item.AlertId)) {
      // eslint-disable-next-line no-param-reassign
      item.AlertStatus = alertStatusesById[actionType];
      // eslint-disable-next-line no-param-reassign
      item.User = user;
    }
  });
  return filterStatuses ? alerts.filter(item => filterStatuses.includes(alertStatusesByKey[item.AlertStatus])) : alerts;
};

export const LCRMTPlayersAlertsAfterReview = (actionType, selectedAlerts, filterStatuses, alerts, User, Modified, Assessment, Comment) => {
  alerts.forEach(item => {
    if (selectedAlerts.includes(item.AlertId)) {
      // eslint-disable-next-line no-param-reassign
      item.AlertStatus = alertStatusesById[actionType];
      // eslint-disable-next-line no-param-reassign
      item.User = User;
      // eslint-disable-next-line no-param-reassign
      item.Modified = Modified;
      // eslint-disable-next-line no-param-reassign
      item.Assessment = Assessment;
      // eslint-disable-next-line no-param-reassign
      item.Comment = Comment;
    }
  });
  return [...alerts];
};
