/* eslint-disable  no-mixed-operators */
/**
 * @flow
 * */
/* eslint-disable camelcase */

import { createSelector } from 'reselect';

const getAppSettingsState = (state: Object) => state.appSettings;

export const appSettingsSelector = createSelector(
  getAppSettingsState,
  (state: Object) => state.appSettings || {}
);

export const appSettingsUpdateSelector = createSelector(
  getAppSettingsState,
  (state: Object) => state.appSettings && state.appSettings.update || {}
);

export const agpSettingsSelector = createSelector(
  getAppSettingsState,
  (state: Object) => state.agpSettings || {}
);

export const agpSettingsUpdateSelector = createSelector(
  getAppSettingsState,
  (state: Object) => state.agpSettings && state.agpSettings.update || {}
);
