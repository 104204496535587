/**
 * @flow
 * */
import type { Action } from 'lib/core/flow/types';

import APP_SETTINGS_ACTION_TYPES from './types';

type ActionType = {
  userId?: number,
  viewMode?: string,
  language?: string,
  isSizeChecked?: boolean,
  data?: any,
  error?: string,
  name?: string
}

export const updateAppSettings = ({ userId, viewMode, language, isSizeChecked }: ActionType): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.UPDATE_APP_SETTINGS,
  payload: { userId, viewMode, language, isSizeChecked }
});
export const setUpdateAppSettingsSuccess = ({ viewMode, language, isSizeChecked }: ActionType): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_UPDATE_APP_SETTINGS_SUCCESS,
  payload: { viewMode, language, isSizeChecked }
});
export const setUpdateAppSettingsFailure = ({ error }: Object): ActionType => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_UPDATE_APP_SETTINGS_FAILURE,
  payload: { error }
});

export const fetchAppSettings = ({ userId }: ActionType): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.FETCH_APP_SETTINGS,
  payload: { userId }
});
export const setFetchAppSettingsSuccess = ({ data, name }: ActionType): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_FETCH_APP_SETTINGS_SUCCESS,
  payload: { data, name }
});
export const setFetchAppSettingsFailure = ({ error, name }: ActionType): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_FETCH_APP_SETTINGS_FAILURE,
  payload: { error, name }
});

// Agp Settings
export const updateSettings = (params: Object): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.UPDATE_AGP_SETTINGS,
  payload: { params }
});
export const setUpdateSettingsSuccess = ({ id }: Object): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_UPDATE_AGP_SETTINGS_SUCCESS,
  payload: { id }
});
export const setUpdateSettingsFailure = ({ error }: Object): ActionType => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_UPDATE_AGP_SETTINGS_FAILURE,
  payload: { error }
});
export const clearSettings = (): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.CLEAR_AGP_SETTINGS
});

export const setFetchSettingsSuccess = ({ data }: ActionType): Action => ({
  type: APP_SETTINGS_ACTION_TYPES.SET_FETCH_AGP_SETTINGS_SUCCESS,
  payload: { data }
});
