import { setFetchCommentsTemplateFailure, setFetchCommentsTemplateSuccess } from 'store/actions/commentsTemplate/actions';
import CommentsTemplateSDK from 'core/sdk/commentsTemplate/commentsTemplateSDK';
import ACTION_TYPES from 'store/actions/commentsTemplate/types';
import { put, takeLatest } from 'redux-saga/effects';

class CommentsTemplateController {
  static* fetchCommentsTemplateData() {
    try {
      const data = yield CommentsTemplateSDK.getCommentsTemplate();
      yield put(setFetchCommentsTemplateSuccess({ data }));
    } catch (err) {
      yield put(setFetchCommentsTemplateFailure(err.message));
    }
  }

  static* commentsTemplateWatcher<T>(): Iterable<T> {
    yield takeLatest(ACTION_TYPES.FETCH_COMMENTS_TEMPLATE, CommentsTemplateController.fetchCommentsTemplateData);
  }
}

export default CommentsTemplateController.commentsTemplateWatcher;
