import type { Action } from 'lib/core/flow/types';
import PLAYER_TYPES from './types';

type ActionType = {
  ImageData?: string,
  ImageType?: number,
  Name?: string,
  ObjectId?: number,
  clientId?: number
}

// SRMT Player Files
export const setSRMTPLayerFiles = ({ ImageData, ImageType, Name, ObjectId }: ActionType): Action => ({
  type: PLAYER_TYPES.FETCH_PLAYER_FILES,
  payload: { ImageData, ImageType, Name, ObjectId }
});
export const setSRMTPLayerFilesSuccess = ({ data }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_PLAYER_FILES_SUCCESS,
  payload: { data }
});
export const setSRMTPLayerFilesFailure = ({ error }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_PLAYER_FILES_FAILURE,
  payload: { error }
});
export const clearSRMTPLayerFilesState = (): Action => ({
  type: PLAYER_TYPES.CLEAR_PLAYER_FILES_STATE
});

// AML Player Files
export const setAMLPLayerFiles = ({ ImageData, ImageType, Name, ObjectId }: ActionType): Action => ({
  type: PLAYER_TYPES.FETCH_AML_PLAYER_FILES,
  payload: { ImageData, ImageType, Name, ObjectId }
});
export const setAMLPLayerFilesSuccess = ({ data }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_AML_PLAYER_FILES_SUCCESS,
  payload: { data }
});
export const setAMLPLayerFilesFailure = ({ error }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_AML_PLAYER_FILES_FAILURE,
  payload: { error }
});
export const clearAMLPLayerFilesState = (): Action => ({
  type: PLAYER_TYPES.CLEAR_AML_PLAYER_FILES_STATE
});

// Get SRMT Player Files
export const setGetSRMTPLayerFiles = ({ clientId }: ActionType): Action => ({
  type: PLAYER_TYPES.FETCH_GET_SRMT_PLAYER_FILES,
  payload: { clientId }
});
export const setGetSRMTPLayerFilesSuccess = ({ data }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_GET_SRMT_PLAYER_FILES_SUCCESS,
  payload: { data }
});
export const setGetSRMTPLayerFilesFailure = ({ error }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_GET_SRMT_PLAYER_FILES_FAILURE,
  payload: { error }
});
export const clearGetSRMTPLayerFilesState = (): Action => ({
  type: PLAYER_TYPES.CLEAR_GET_SRMT_PLAYER_FILES_STATE
});

// Get AML Player Files
export const setGetAMLPLayerFiles = ({ clientId }: ActionType): Action => ({
  type: PLAYER_TYPES.FETCH_GET_AML_PLAYER_FILES,
  payload: { clientId }
});
export const setGetAMLPLayerFilesSuccess = ({ data }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_GET_AML_PLAYER_FILES_SUCCESS,
  payload: { data }
});
export const setGetAMLPLayerFilesFailure = ({ error }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_GET_AML_PLAYER_FILES_FAILURE,
  payload: { error }
});
export const clearGetAMLPLayerFilesState = (): Action => ({
  type: PLAYER_TYPES.CLEAR_GET_AML_PLAYER_FILES_STATE
});

// delete SRMT Player Files
export const deleteSRMTPLayerFiles = ({ Id, TypeId, OriginalName, IsDeleted }: ActionType): Action => ({
  type: PLAYER_TYPES.FETCH_DELETE_SRMT_PLAYER_FILES,
  payload: { Id, TypeId, OriginalName, IsDeleted }
});
export const deleteSRMTPLayerFilesSuccess = ({ data }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_DELETE_SRMT_PLAYER_FILES_SUCCESS,
  payload: { data }
});
export const deleteSRMTPLayerFilesFailure = ({ error }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_DELETE_SRMT_PLAYER_FILES_FAILURE,
  payload: { error }
});
export const clearDeleteSRMTPLayerFilesState = (): Action => ({
  type: PLAYER_TYPES.CLEAR_DELETE_SRMT_PLAYER_FILES_STATE
});

// delete AML Player Files
export const deleteAMLPLayerFiles = ({ Id, TypeId, OriginalName, IsDeleted }: ActionType): Action => ({
  type: PLAYER_TYPES.FETCH_DELETE_AML_PLAYER_FILES,
  payload: { Id, TypeId, OriginalName, IsDeleted }
});
export const deleteAMLPLayerFilesSuccess = ({ data }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_DELETE_AML_PLAYER_FILES_SUCCESS,
  payload: { data }
});
export const deleteAMLPLayerFilesFailure = ({ error }: ActionType): Action => ({
  type: PLAYER_TYPES.SET_FETCH_DELETE_AML_PLAYER_FILES_FAILURE,
  payload: { error }
});
export const clearDeleteAMLPLayerFilesState = (): Action => ({
  type: PLAYER_TYPES.CLEAR_DELETE_AML_PLAYER_FILES_STATE
});
