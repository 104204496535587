/**
 * @flow
 * */

import LCRMT_HOURLY_ALERTS_TYPES from 'modules/LCRMT/Alerts/store/actions/alerts/hourlyAlertsActions/types';
import { alertsFactory, hourlyInitialStateByKey } from 'modules/LCRMT/utils/alertsFactory';
import { initialSectionState, initialStatisticState } from 'store/reducers/initialStates';
import expandReducerChildren from 'modules/LCRMT/utils/expandReducerChildren';
import { statisticTypeEnumById } from 'modules/LCRMT/utils/enums';
import { HourlyAlertState } from 'modules/LCRMT/Alerts/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import { deepClone } from 'lib/helpers/makeData';
import type { Action } from 'lib/core/flow';

const lcrmtHourlyAlerts = alertsFactory.createInitialAlertsMonitor('hourlyAlert');

const initialState = (): HourlyAlertState => ({
  ...lcrmtHourlyAlerts
});

// Hourly alerts
const actionManager = {
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS]: (state: HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_SUCCESS]: (state: HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        error: null,
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        totalCount: !payload.passTotalCount ? payload.totalCount : state[payload.tabKey].alerts.totalCount
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_FAILURE]: (state: HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        data: null,
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_STATE]: (state: HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: deepClone(hourlyInitialStateByKey[payload.tabKey])
  }),

  // Hourly alerts expand
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_EXPAND]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren({
          allData: state[payload.tabKey].alerts.data,
          itemIndex: payload.index,
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        })
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_SUCCESS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren({
          allData: state[payload.tabKey].alerts.data,
          itemIndex: payload.index,
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        })
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_EXPAND_FAILURE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alerts: {
        ...state[payload.tabKey].alerts,
        data: expandReducerChildren({
          allData: state[payload.tabKey].alerts.data,
          itemIndex: payload.index,
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        })
      }
    }
  }),

  // hourly alerts review
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review: {
        ...state[payload.tabKey].alert_review,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_SUCCESS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review: {
        data: payload.data,
        error: null,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_FAILURE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review: {
        error: payload.error,
        data: null,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_STATE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review: initialSectionState()
    }
  }),

  // Hourly alerts review action
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.params.tabKey]: {
      ...state[payload.params.tabKey],
      alert_review_action: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_SUCCESS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review_action: {
        status: ACTION_STATUSES.SUCCEED,
        data: payload.data,
        error: null
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_FAILURE]: (state: HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review_action: {
        error: payload.error,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_ACTION_STATE]: (state: HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review_action: initialSectionState()
    }
  }),

  // Hourly alerts review expand
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review: {
        ...state[payload.tabKey].alert_review,
        data: expandReducerChildren({
          allData: state[payload.tabKey].alert_review.data,
          itemIndex: payload.index,
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        })
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_SUCCESS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review: {
        ...state[payload.tabKey].alert_review,
        data: expandReducerChildren({
          allData: state[payload.tabKey].alert_review.data,
          itemIndex: payload.index,
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        })
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND_FAILURE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      alert_review: {
        ...state[payload.tabKey].alert_review,
        data: expandReducerChildren({
          allData: state[payload.tabKey].alert_review.data,
          itemIndex: payload.index,
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        })
      }
    }
  }),

  // hourly alerts review history
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      review_history: {
        ...state[payload.tabKey].review_history,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_SUCCESS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      review_history: {
        data: payload.data,
        error: null,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_FAILURE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      review_history: {
        error: payload.error,
        data: null,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.CLEAR_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY_STATE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      review_history: initialSectionState()
    }
  }),

  // statistics
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_STATISTICS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      statistics: {
        ...state[payload.tabKey].statistics,
        [statisticTypeEnumById[payload.statisticType]]: {
          ...state[payload.tabKey].statistics[statisticTypeEnumById[payload.statisticType]],
          status: ACTION_STATUSES.PENDING
        }
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_STATISTICS_SUCCESS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      statistics: {
        ...state[payload.tabKey].statistics,
        [statisticTypeEnumById[payload.statisticType]]: {
          data: payload.data,
          error: null,
          status: ACTION_STATUSES.SUCCEED
        }
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_STATISTICS_FAILURE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      statistics: {
        ...state[payload.tabKey].statistics,
        [statisticTypeEnumById[payload.statisticType]]: {
          error: payload.error,
          data: null,
          status: ACTION_STATUSES.FAILED
        }
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.CLEAR_STATISTICS_STATE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      statistics: initialStatisticState()
    }
  }),

  // true count
  [LCRMT_HOURLY_ALERTS_TYPES.FETCH_TRUE_COUNT]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      trueCount: {
        ...state[payload.tabKey].trueCount,
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_TRUE_COUNT_SUCCESS]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      trueCount: {
        data: payload.data,
        error: null,
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.SET_FETCH_TRUE_COUNT_FAILURE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      trueCount: {
        error: payload.error,
        data: null,
        status: ACTION_STATUSES.FAILED
      }
    }
  }),
  [LCRMT_HOURLY_ALERTS_TYPES.CLEAR_TRUE_COUNTY_STATE]: (state:HourlyAlertState, payload) => ({
    ...state,
    [payload.tabKey]: {
      ...state[payload.tabKey],
      trueCount: initialSectionState()
    }
  })
};

export default (state: HourlyAlertState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
