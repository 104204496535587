/**
 * @flow
 * */
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import type { MinimalReducer } from 'lib/core/flow/types';

import ACTION_TYPES from 'store/actions/landing/types';

export type LandingState = {
  demo: MinimalReducer
};

const initialState = (): LandingState => ({
  demo: {
    error: null,
    status: null
  }
});

const FETCH_DEMO = (state: LandingState) => ({
  ...state,
  demo: {
    ...state.demo,
    status: ACTION_STATUSES.PENDING
  }
});

const SET_DEMO_FAILED = (state: LandingState, action: Action) => ({
  ...state,
  demo: {
    status: ACTION_STATUSES.FAILED,
    error: action.payload
  }
});

const SET_DEMO_SUCCEED = (state: LandingState) => ({
  ...state,
  demo: {
    status: ACTION_STATUSES.SUCCEED,
    error: null
  }
});

const CLEAR_DEMO = (state: LandingState) => ({
  ...state,
  demo: {
    error: null,
    status: null
  }
});

export default (state: LandingState = initialState(), action: Action) => {
  switch (action.type) {
    // log out
    case ACTION_TYPES.FETCH_DEMO:
      return FETCH_DEMO(state);
    case ACTION_TYPES.SET_DEMO_FAILED:
      return SET_DEMO_FAILED(state, action);
    case ACTION_TYPES.SET_DEMO_SUCCEED:
      return SET_DEMO_SUCCEED(state);
    case ACTION_TYPES.CLEAR_DEMO:
      return CLEAR_DEMO(state);

    default:
      return state;
  }
};
