import type { Action } from 'lib/core/flow/types';

import GENERAL_ACTION from './types';

export const fetchPlayerGlobalInfo = ({ playerId, productType }): Action => ({
  type: GENERAL_ACTION.FETCH_PLAYER_GLOBAL_INFO,
  payload: { playerId, productType }
});
export const setFetchPlayerGlobalInfoSuccess = ({ data }): Action => ({
  type: GENERAL_ACTION.SET_FETCH_PLAYER_GLOBAL_INFO_SUCCESS,
  payload: { data }
});
export const setFetchPlayerGlobalInfoFailure = ({ error, errorStatusCode }): Action => ({
  type: GENERAL_ACTION.SET_FETCH_PLAYER_GLOBAL_INFO_FAILURE,
  payload: { error, errorStatusCode }
});
export const updateGlobalInfo = ({ Limit, preMatchLimit, Delay, WWLimit, WWDelay, IsTopPriority, SportsBookProfileName, SportsBookProfileId }): Action => ({
  type: GENERAL_ACTION.UPDATE_PLAYER_GLOBAL_INFO,
  payload: { Limit, preMatchLimit, Delay, WWLimit, WWDelay, IsTopPriority, SportsBookProfileName, SportsBookProfileId }
});
export const editGlobalInfo = ({ Limit, Delay, SportsBookProfileName }): Action => ({
  type: GENERAL_ACTION.EDIT_PLAYER_GLOBAL_INFO,
  payload: { Limit, Delay, SportsBookProfileName }
});
export const clearPlayerGlobalInfo = (): Action => ({
  type: GENERAL_ACTION.CLEAR_PLAYER_GLOBAL_INFO_STATE
});

// cancel call
export const cancelPlayerGeneralSdkCall = ({ name }): Action => ({
  type: GENERAL_ACTION.CANCEL_PLAYER_GENERAL_SDK_CALL,
  payload: { name }
});
