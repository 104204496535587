/* eslint-disable  no-mixed-operators */
import settings from 'config/constants';
import { history } from 'config/history';
import CacheService from 'core/services/storage/CacheService';
import { licenseIds } from './licenseIds';

const products = settings.PRODUCTS;

export const AMLConfigEnum = {
  Partner: 1,
  License: 2
};

export const AMLConfigRedirectEnum = {
  Alert: 1,
  Player: 2
};

export const isAml = () => window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1] === products.AML.path;

export const isAmlLicense = () => {
  if (isAml() && window.location.pathname.split('/').length > 3) {
    const license = licenseIds[window.location.pathname.split('/')[2]];
    if (!license) {
      history.push('/404');
    }
    return { ...license, key: window.location.pathname.split('/')[2] };
  }
  return {};
};

export const getAmlLicenseFilter = (license) => {
  const filters = {};
  if (!('license' in license)) {
    const amlTurnkeyPartnerId = CacheService.getItem('amlTurnkeyPartnerId');
    if (amlTurnkeyPartnerId) {
      filters.id = amlTurnkeyPartnerId;
      filters.type = AMLConfigEnum.Partner;
    } else {
      history.push('/404');
    }
  } else {
    filters.id = license.license;
    filters.type = AMLConfigEnum.License;
  }
  return filters;
};

export const getAmlLicenseForRedux = ({ license, licenseId, partnerId }) => `${license}_${!licenseId && partnerId || ''}`;
