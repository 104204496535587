/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import SRMT_SETTINGS_TYPES from './types';

// notification settings
export const fetchNotificationSettings = ():Action => ({
  type: SRMT_SETTINGS_TYPES.FETCH_SRMT_NOTIFICATION_SETTINGS
});

export const fetchNotificationSettingsSuccess = ({ data }: Object):Action => ({
  type: SRMT_SETTINGS_TYPES.SET_FETCH_SRMT_NOTIFICATION_SETTINGS_SUCCESS,
  payload: { data }
});

export const fetchNotificationSettingsFailure = ({ error }: Object):Action => ({
  type: SRMT_SETTINGS_TYPES.SET_FETCH_SRMT_NOTIFICATION_SETTINGS_FAILURE,
  payload: { error }
});

export const removeNotificationSettingsState = ():Action => ({
  type: SRMT_SETTINGS_TYPES.CLEAR_SRMT_NOTIFICATION_SETTINGS_STATE
});

// save notification settings
export const saveNotificationSettings = ({ params }:Object):Action => ({
  type: SRMT_SETTINGS_TYPES.SAVE_SRMT_NOTIFICATION_SETTINGS,
  payload: { params }
});

export const saveNotificationSettingsSuccess = ({ data }: Object):Action => ({
  type: SRMT_SETTINGS_TYPES.SET_SAVE_SRMT_NOTIFICATION_SETTINGS_SUCCESS,
  payload: { data }
});

export const saveNotificationSettingsFailure = ({ error }: Object):Action => ({
  type: SRMT_SETTINGS_TYPES.SET_SAVE_SRMT_NOTIFICATION_SETTINGS_FAILURE,
  payload: { error }
});

// delete notification settings
export const deleteNotificationSettings = ({ Id }:Object):Action => ({
  type: SRMT_SETTINGS_TYPES.DELETE_SRMT_NOTIFICATION_SETTINGS,
  payload: { Id }
});

export const deleteNotificationSettingsSuccess = ({ data }: Object):Action => ({
  type: SRMT_SETTINGS_TYPES.SET_DELETE_SRMT_NOTIFICATION_SETTINGS_SUCCESS,
  payload: { data }
});

export const deleteNotificationSettingsFailure = ({ error }: Object):Action => ({
  type: SRMT_SETTINGS_TYPES.SET_DELETE_SRMT_NOTIFICATION_SETTINGS_FAILURE,
  payload: { error }
});

// cancel call
export const cancelNotificationSettingsDKCalls = ({ name }: Object): Action => ({
  type: SRMT_SETTINGS_TYPES.CANCEL_SRMT_NOTIFICATION_SETTINGS_CALL,
  payload: { name }
});
