import { put, takeLatest } from 'redux-saga/effects';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/notificationSettings/types';
import notificationSettingsSdk from 'modules/SRMT/Settings/core/sdk/NotificationSettingsSDK';
import {
  fetchNotificationSettingsFailure,
  fetchNotificationSettingsSuccess,
  deleteNotificationSettingsFailure,
  deleteNotificationSettingsSuccess,
  saveNotificationSettingsFailure,
  saveNotificationSettingsSuccess
} from 'modules/SRMT/Settings/store/actions/settings/notificationSettings';

class NotificationSettingsController {
  static* fetchNotificationSettings() {
    try {
      const { data } = yield notificationSettingsSdk.fetchNotificationSettings();
      yield put(fetchNotificationSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchNotificationSettingsFailure({ error: err.message }));
    }
  }

  static* saveNotificationSettings({ payload }) {
    const { params } = payload;
    try {
      const { data } = yield notificationSettingsSdk.saveNotificationSettings({ params });
      yield put(saveNotificationSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(saveNotificationSettingsFailure({ error: err.message }));
    }
  }

  static* deleteNotificationSettings({ payload }) {
    const { Id } = payload;
    try {
      const { data } = yield notificationSettingsSdk.deleteNotificationSettings({ Id });
      yield put(deleteNotificationSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(deleteNotificationSettingsFailure({ error: err.message }));
    }
  }

  static* cancelNotificationSettingsSDKCalls({ payload }) {
    const { name } = payload;
    yield notificationSettingsSdk.cancelNotificationSettingsSDKCalls({ name });
  }

  static* actionsWatcher<T>(): Iterable<T> {
    yield takeLatest(SETTINGS_ACTION_TYPES.FETCH_SRMT_NOTIFICATION_SETTINGS, NotificationSettingsController.fetchNotificationSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.SAVE_SRMT_NOTIFICATION_SETTINGS, NotificationSettingsController.saveNotificationSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.DELETE_SRMT_NOTIFICATION_SETTINGS, NotificationSettingsController.deleteNotificationSettings);
    yield takeLatest(SETTINGS_ACTION_TYPES.CANCEL_SRMT_NOTIFICATION_SETTINGS_CALL, NotificationSettingsController.cancelNotificationSettingsSDKCalls);
  }
}

export default NotificationSettingsController.actionsWatcher;
