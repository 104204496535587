/**
 * @flow
 * */
import React from 'react';
import Paper from '@geneui/components/Paper';
import Radio from '@geneui/components/Radio';
import classNames from 'classnames';

import { paperConfig } from 'config/settings/core-ui-strings';

import './styles.scss';

type Props = {
    isSelected: boolean,
    disabled: boolean,
    textSize: number,
    title: string
};

/**
 *
 * @param {boolean} isSelected - Is Selected
 * @param {boolean} disabled - disabled
 * @param {number} textSize - Text Font Size
 * @param {string} title - View Mode Title
 * @returns {JSX.Element} - React.Component
 */
const AppSettingsPopupViewModeItem = ({ isSelected, textSize, title, disabled }: Props) => (
  <div className={`view-mode-item-container ${classNames({ 'disabled-view-mode': disabled })}`}>
    <Paper
      style={{ fontSize: textSize, borderColor: isSelected ? '#1473e6' : 'inherit' }}
      cornerRadius={paperConfig.cornerRadius.fullRadius}
      className="view-mode-item-paper"
      border
    >Aa
    </Paper>
    <Radio
      className="view-mode-item-radio"
      checked={isSelected}
      disabled={disabled}
      label={title}
    />
  </div>
);

AppSettingsPopupViewModeItem.displayName = 'AppSettingsPopupViewModeItem';

export default AppSettingsPopupViewModeItem;
