/**
 * @flow
 * */
import { put, takeEvery } from 'redux-saga/effects';

import AUTH_ACTION_TYPES from 'modules/Dashboard/Auth/store/actions/auth/types';
import AGPSDK from 'core/sdk/AGPSDK/AGPSDK';
import {
  FetchJWTAuthChangePasswordFailed,
  FetchJWTAuthChangePasswordSucceed,
  FetchJWTAuthFailed,
  FetchJWTAuthSucceed,
  FetchJWTAuthTwoStepFailed,
  FetchJWTAuthTwoStepSucceed
} from 'modules/Dashboard/Auth/store/actions/auth';

function* jwtLogin({ payload }) {
  const { userName, password, device } = payload;
  try {
    const { headers, body: { Data } } = yield AGPSDK.jwtLogin({ userName, password, device });
    const token = headers.get('Authentication');
    yield put(FetchJWTAuthSucceed({ data: { token, ...Data } }));
  } catch (err) {
    yield put(FetchJWTAuthFailed(err.message));
  }
}

function* jwtChangePassword({ payload }) {
  const { userId, confirmPassword, newPassword, currentPassword } = payload;
  try {
    const { data } = yield AGPSDK.jwtChangePassword({ userId, confirmPassword, newPassword, currentPassword });
    yield put(FetchJWTAuthChangePasswordSucceed({ data }));
  } catch (err) {
    yield put(FetchJWTAuthChangePasswordFailed(err.message));
  }
}
function* twoStep({ payload }) {
  const { fingerprint, isDeviceTrusted, token, trustedPeriod } = payload;
  try {
    const { data } = yield AGPSDK.twoStep({ fingerprint, isDeviceTrusted, token, trustedPeriod });
    yield put(FetchJWTAuthTwoStepSucceed({ data }));
  } catch (err) {
    yield put(FetchJWTAuthTwoStepFailed(err.message));
  }
}

function* authControllerWatcher<T>(): Iterable<T> {
  yield takeEvery(AUTH_ACTION_TYPES.FETCH_JWT_AUTH, jwtLogin);
  yield takeEvery(AUTH_ACTION_TYPES.FETCH_JWT_AUTH_CHANGE_PASSWORD, jwtChangePassword);
  yield takeEvery(AUTH_ACTION_TYPES.FETCH_JWT_AUTH_TWO_STEP, twoStep);
}

export default authControllerWatcher;
