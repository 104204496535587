export default {
  inputErrorText: {
    maximum_length_validator: (length) => `The maximum length must be ${length} characters.`,
    maximum_value: (value) => `The value must be less than or equal to ${value}.`,
    minmax_value: (min, max) => `The value must be in range of ${min} to ${max}.`,
    must_be_valid_phone_number: 'Must be a valid phone number.',
    must_be_positive: 'Must be a positive integer.',
    must_be_not_negative: 'Value can not be negative.',
    must_be_valid_email: 'Must be a valid email.',
    must_be_unique: 'Must be unique.',
    required: 'This field is required.',
    needToSelectPaymentSystem: 'Please choose payment system.',
    must_be_num: 'Must be a number.',
    must_be_an_number: 'Must be an integer number.',
    must_be_number: 'Should be number.',
    must_be_smaller: '\'Age From\' number should be smaller than \'Age To\'.',
    must_be_bigger: '\'Age To\' number should be bigger than \'Age From\'.',
    not_valid_item: (itemName) => `Not valid ${itemName}.`,
    ip: 'Not valid IP.',
    maximum_game_count: 'Max. allowed game count is 49'
  },
  success: {
    forceCalculation: (playerId, forceCalcState) => `Player ${playerId} has been successfully
    calculated! Limit ${forceCalcState?.data?.Limit}, Delay ${forceCalcState?.data?.Delay}`,
    deactivatePartner: ({ productName, partnerId, partnerName }) => `${productName} is successfully deactivated for Partner ${partnerName}/${partnerId}.`,
    setAdditionalCategories: 'The action has been successfully saved.',
    changed: 'Successfully Changed',
    checked: 'Successfully Checked',
    created: 'Successfully Created',
    saved: 'Successfully Saved',
    deactivated: 'Successfully Deactivated',
    added: 'Successfully Added',
    deleted: 'Successfully Deleted',
    editPlayerRestriction: 'The action has been successfully saved.',
    setSettings: 'The action has been successfully saved.',
    setStyleCategory: 'The action has been successfully saved.',
    inProcess: 'The action is successfully in process.',
    removed: 'Successfully Removed',
    pending: 'The process has been started, action will be completed shortly.',
    copied: 'Copied'
  },
  error: {
    somethingWentWrong: 'Something went wrong. Please try again later.'
  },
  confirm: {
    deactivatePartner: ({ productName }) => `Are you sure you want to deactivate ${productName} for this partner?`
  }
};

export class messages {
}
