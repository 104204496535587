/* eslint-disable  no-mixed-operators */
import React from 'react';
import Link from 'react-router-dom/es/Link';

import { permissions } from 'config/settings/permissions';
import { amlConfigs } from 'config/settings/configs';
import settings from 'config/constants';

const products = settings.PRODUCTS;

const { path } = products.AML;

export const generateAmlNav = (config, license, hasOne) => [
  {
    hasOneCheck: hasOne,
    title: <Link className="route_link" to={`/${path}/licenses`}>Licenses</Link>,
    id: `/${path}/licenses`,
    icon: 'bc-icon-desktop-preview',
    permission: 'show'
  },
  {
    title: <Link className="route_link" to={`/${path}/${license}/dashboard`}>Dashboard</Link>,
    permission: permissions.AMLRGGeneral,
    id: `/${path}/${license}/dashboard`,
    icon: 'bc-icon-dashboard'
  },
  {
    title: <Link className="route_link" to={`/${path}/${license}/alert-monitor`}>Alert Monitor</Link>,
    id: `/${path}/${license}/alert-monitor`,
    icon: 'bc-icon-alert-monitor-rmt-36',
    permission: permissions.AMLRGGeneral
  },
  {
    title: <Link className="route_link" to={`/${path}/${license}/players`}>Players</Link>,
    id: `/${path}/${license}/players`,
    icon: 'bc-icon-players-rmt-36',
    permission: permissions.AMLRGGeneral
  },
  {
    title: <Link className="route_link" to={`/${path}/${license}/transactions`}>Transactions</Link>,
    id: `/${path}/${license}/transactions`,
    icon: 'bc-icon-money-48',
    permission: permissions.AMLRGGeneral
  },
  {
    title: 'Reports',
    id: `/${path}/${license}/reports`,
    icon: 'bc-icon-report',
    permission: permissions.AMLRGGeneral,
    data: [
      {
        title: <Link to={`/${path}/${license}/reports/player-block-history`}>Player Block History Report</Link>,
        id: `/${path}/${license}/reports/player-block-history`,
        permission: permissions.AMLRGGeneral
      },
      {
        title: <Link to={`/${path}/${license}/reports/player-score`}>Player Score Report</Link>,
        id: `/${path}/${license}/reports/player-score`,
        amlConfig: amlConfigs.ScoreCalculationEnabled,
        permission: permissions.AMLRGGeneral
      },
      {
        title: <Link to={`/${path}/${license}/reports/player-check-history-reports`}>Player Check History Reports</Link>,
        id: `/${path}/${license}/reports/player-check-history-reports-score`,
        permission: permissions.AMLRGGeneral
      },
      {
        title: <Link to={`/${path}/${license}/reports/aml-rule-score`}>AML Rule Score Report</Link>,
        id: `/${path}/${license}/reports/aml-rule-score`,
        amlConfig: amlConfigs.AmlRuleScoreEnabled,
        permission: permissions.AMLRGGeneral
      }
    ]
  },
  {
    title: 'Settings',
    id: `/${path}/${license}/settings`,
    icon: 'bc-icon-settings',
    permission: permissions.AMLSettings,
    data: [
      {
        title: <Link to={`/${path}/${license}/settings/main-settings`}>Main Settings</Link>,
        id: `/${path}/${license}/settings/main-settings`,
        permission: permissions.AMLSettings
      },
      {
        title: <Link to={`/${path}/${license}/settings/settings-change-history`}>Settings Change History</Link>,
        id: `/${path}/${license}/settings/settings-change-history`,
        permission: permissions.AMLSettings
      }
    ]
  }
].filter(item => !item.hasOneCheck).map(item => {
  if (item.amlConfig && (typeof item.amlConfig === 'string' && !config[item.amlConfig] || typeof item.amlConfig === 'object' && !item.amlConfig.some(i => config[i]))) {
    return false;
  }
  if (item.data) {
    // eslint-disable-next-line no-param-reassign
    item.data = item.data.map(i => {
      if (i.amlConfig && !config[i.amlConfig]) return false;
      return i;
    }).filter(i => !!i);
  }
  return item;
}).filter(i => !!i);
