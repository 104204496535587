/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const Players = React.lazy(() => import('./views/pages/PlayersList'));
const PlayerDeepView = React.lazy(() => import('./views/pages/PlayerDeepView'));

const subUrl = settings.PRODUCTS.CRMT.path;
const modulePath = `${subUrl}/players`;

const ROUTES: Array<Route> = [
  { path: `/${modulePath}`, name: 'Players', exact: true, page: Players },
  { path: `/${modulePath}/:id/:tab`, name: 'Player/Id/Tab', exact: true, page: PlayerDeepView },
  { path: `/${modulePath}/:id`,
    name: 'Player Deep View',
    exact: true,
    page: () => {
      const params = window.location.pathname.split('/');
      return <Redirect to={`/${modulePath}/${params[params.length - 1]}/general`} />;
    } }
];

export default ROUTES;
