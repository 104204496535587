/**
 * @flow
 * */
import DASHBOARD_ACTION_TYPES from 'modules/LCRMT/Dashboard/store/actions/dashboard/dashboardActions/types';
import expandReducerChildren from 'modules/LCRMT/utils/expandReducerChildren';
import type { DashboardTypeState } from 'modules/LCRMT/Dashboard/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import {
  createDashboardTopPlayersInitialState,
  createDashboardFiltersInitialState,
  createDashboardChartsInitialState,
  createRoundInfoInitialState
} from './initialStates';
import LCRMT_DASHBOARD_TYPES from '../actions/dashboard/dashboardActions/types';

const initialState = (): DashboardTypeState => ({
  filters: createDashboardFiltersInitialState(),
  charts: createDashboardChartsInitialState(),
  topPlayers: createDashboardTopPlayersInitialState(),
  roundParticipants: createRoundInfoInitialState()
});

const actionManager = {
  // dashboard filter
  [DASHBOARD_ACTION_TYPES.FETCH_LCRMT_DASHBOARD_FILTER]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    filters: {
      ...state.filters,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_LCRMT_DASHBOARD_FILTER_SUCCESS]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    filters: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_LCRMT_DASHBOARD_FILTER_FAILURE]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    filters: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null

    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_LCRMT_DASHBOARD_FILTER_STATE]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    filters: createDashboardFiltersInitialState()
  }),

  // dashboard charts
  [DASHBOARD_ACTION_TYPES.FETCH_LCRMT_DASHBOARD_CHARTS]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    charts: {
      ...state.charts,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_LCRMT_DASHBOARD_CHARTS_SUCCESS]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    charts: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_LCRMT_DASHBOARD_CHARTS_FAILURE]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    charts: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null

    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_LCRMT_DASHBOARD_CHARTS_STATE]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    charts: createDashboardChartsInitialState()
  }),

  // dashboard top players
  [DASHBOARD_ACTION_TYPES.FETCH_LCRMT_DASHBOARD_TOP_PLAYERS]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    topPlayers: {
      ...state.charts,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_SUCCESS]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    topPlayers: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_FAILURE]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    topPlayers: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null

    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_LCRMT_DASHBOARD_TOP_PLAYERS_STATE]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    topPlayers: createDashboardChartsInitialState()
  }),

  // round info
  [LCRMT_DASHBOARD_TYPES.FETCH_ROUND_INFO]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    roundParticipants: {
      ...state.roundParticipants,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_SUCCESS]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    roundParticipants: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_FAILURE]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    roundParticipants: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null

    }
  }),
  [LCRMT_DASHBOARD_TYPES.CLEAR_ROUND_INFO_STATE]: (state: DashboardTypeState): DashboardTypeState => ({
    ...state,
    roundParticipants: createRoundInfoInitialState()
  }),

  // round info expand
  [LCRMT_DASHBOARD_TYPES.FETCH_ROUND_INFO_EXPAND]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    roundParticipants: {
      ...state.roundParticipants,
      data: expandReducerChildren({
        allData: state.roundParticipants.data,
        itemIndex: payload.index,
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null
      })
    }
  }),
  [LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_EXPAND_SUCCESS]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    roundParticipants: {
      ...state.roundParticipants,
      data: expandReducerChildren({
        allData: state.roundParticipants.data,
        itemIndex: payload.index,
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null
      })
    }
  }),
  [LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_EXPAND_FAILURE]: (state: DashboardTypeState, payload): DashboardTypeState => ({
    ...state,
    roundParticipants: {
      ...state.roundParticipants,
      data: expandReducerChildren({
        allData: state.roundParticipants.data,
        itemIndex: payload.index,
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      })
    }
  })
};

export default (state: DashboardTypeState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
