/**
 * @author => @RMT_FRONT_CORE/CLI
 */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';
import type {
  DeletePlayerFromGroupParams,
  PlayerGroupsFilterParams,
  AddPlayerToGroupParams,
  GroupPlayersParams,
  UploadFileResponse,
  GroupActionParams
} from 'modules/SRMT/PlayerGroup/types';
import {
  createPlayerGroupType,
  UploadFileParams
} from 'modules/SRMT/PlayerGroup/types';
import BaseSDK from 'core/sdk/BaseSDK';

/**
 * @class PlayerGroupSDK
 */
class PlayerGroupSDK extends BaseSDK {
  _rmtApiService: NetworkService = null;

  constructor() {
    super();
    this._rmtApiService = new NetworkService(config.rmtApi, []);
  }

  /**
   * getPlayerGroups
   * @param {PlayerGroupsFilterParams} params -
   * @returns {Promise<{data: PlayerGroupListResponseModel}>} -
   */
  async getPlayerGroups(params: PlayerGroupsFilterParams): Promise<{ data: Object }> {
    const { count, start, filters, passTotalCount } = params;

    const { body } = await this._rmtApiService.makeAPIPostRequest(
      'PlayerGroup/SearchGroup',
      { body: { count, start, passTotalCount, ...filters }, abortController: this.setAbortController('getPlayerGroups') }
    );

    return body;
  }

  /**
   * getPlayerGroupInfo
   * @param {{ id: number }} params -
   * @returns {Object} -
   */
  async getPlayerGroupInfo(params: { id: number }): Object {
    const { groupId } = params;

    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'PlayerGroup/GetGroupInfo',
      { query_params: { groupId } }
    );

    return { data: body.Data };
  }

  /**
   * deleteGroup
   * @param {{ id: number }} params -
   * @returns {Object} -
   */
  async deleteGroup(params: { id: number }): Object {
    const { groupId } = params;

    await this._rmtApiService.makeAPIGetRequest(
      'PlayerGroup/DeleteGroup',
      { query_params: { groupId } }
    );

    return {
      groupId
    };
  }

  /**
   * createGroup
   * @param {createPlayerGroupType} params -
   * @returns {Object} -
   */
  async createGroup(params:createPlayerGroupType): Object {
    const { name, description, partnerId, filters } = params;
    const { body } = await this._rmtApiService.makeAPIPostRequest(
      'PlayerGroup/CreateClientGroups',
      { body: { name, descriptionReason: description, partnerId, ...filters } }
    );

    return {
      data: body.Data
    };
  }

  /**
   * updateGroup
   * @param {{ id: number, name: string, description: string }} params -
   * @returns {Object} -
   */
  async updateGroup(params: Object): Promise<{ data: Object }> {
    const { id, name, description } = params;
    const { body } = await this._rmtApiService.makeAPIPostRequest(
      'PlayerGroup/UpdateGroup',
      { body: { Id: id, Name: name, DescriptionReason: description } }
    );

    return {
      data: body.Data
    };
  }

  /**
   * getGroupPlayers
   * @param {GroupPlayersParams} params -
   * @returns {Promise<{data: Array<Object>}>} -
   */
  async getGroupPlayers(params: GroupPlayersParams): Promise<{ data: Array<Object> }> {
    const { groupId, start, count, passTotalCount } = params;

    const { body } = await this._rmtApiService.makeAPIGetRequest(
      'PlayerGroup/GetGroupPlayers',
      { query_params: { groupId, start, count, passTotalCount } }
    );
    return {
      Data: body.Data
    };
  }

  /**
   * addPlayerToGroup
   * @param {GroupPlayersParams} params -
   * @returns {Promise<{data: Array<Object>}>} -
   */
  async addPlayerToGroup(params: AddPlayerToGroupParams): Promise<{ data: Array<Object> }> {
    const { groupId, playerIds, description, filters } = params;

    const { body } = await this._rmtApiService.makeAPIPostRequest(
      'PlayerGroup/AddPlayerToGroup',
      { body: { groupId, playerIds, descriptionReason: description, ...filters } }
    );

    return {
      data: body.Data
    };
  }

  /**
   * uloadFile
   * @param {UploadFileParams} params -
   * @returns {Promise<{data: UploadFileResponse}>} -
   */
  async uploadFile(params: UploadFileParams): Promise<{ data: UploadFileResponse }> {
    const { body } = await this._rmtApiService.makeAPIPostRequest(
      'PlayerGroup/UploadClientsFile',
      { body: params, headers: { 'Content-Type': undefined } }
    );

    return {
      data: body.Data
    };
  }

  /**
   * deletePlayerFromGroup
   * @param {DeletePlayerFromGroupParams} params -
   * @returns {null} -
   */
  async deletePlayerFromGroup(params: DeletePlayerFromGroupParams) {
    const { groupId, playerIds } = params;

    await this._rmtApiService.makeAPIPostRequest(
      'PlayerGroup/DeletePlayerFromGroup',
      { body: { groupId, playerIds } }
    );
  }

  /**
   * groupAction
   * @param {Object} params -
   * @returns {null} -
   */
  async groupAction(params:Array<GroupActionParams>) {
    await this._rmtApiService.makeAPIPostRequest(
      'PlayerGroup/GroupAction', {
        body: params
      }
    );
  }

  /**
   * cancelPlayerGroupSDKCalls
   * @param {Object} params -
   * @returns {Promise<{data: Object}>} -
   */
  cancelPlayerGroupSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
    const { name } = params;
    this.abortCall(name);
  }
}

export default new PlayerGroupSDK();
