import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import type { SettingsState } from 'modules/SRMT/Settings/types';
import { initialSectionState } from 'store/reducers/initialStates';

const initialState = {
  gameStyle: initialSectionState()
};

const actionManager = {
  // Game Style Settings
  [SETTINGS_ACTION_TYPES.FETCH_GAME_STYLE_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        ...state.categorySettings.gameStyle,
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_GAME_STYLE_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_GAME_STYLE_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_GAME_STYLE_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: initialSectionState()
    }
  }),
  // Game Style Settings Update
  [SETTINGS_ACTION_TYPES.UPDATE_GAME_STYLE_SETTINGS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        ...state.categorySettings.gameStyle,
        updates: {
          ...state.categorySettings.gameStyle.updates,
          [payload.id]: {
            status: ACTION_STATUSES.PENDING,
            error: null
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_UPDATE_GAME_STYLE_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        ...state.categorySettings.gameStyle,
        updates: {
          ...state.categorySettings.gameStyle.updates,
          [payload.id]: {
            data: payload.data,
            status: ACTION_STATUSES.SUCCEED,
            error: null
          }
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_UPDATE_GAME_STYLE_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        ...state.categorySettings.gameStyle,
        updates: {
          ...state.categorySettings.gameStyle.updates,
          [payload.id]: {
            status: ACTION_STATUSES.FAILED,
            error: payload.error
          }
        }
      }
    }
  }),
  // Game Style Settings Priority
  [SETTINGS_ACTION_TYPES.UPDATE_GAME_STYLE_SETTINGS_PRIORITY]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        ...state.categorySettings.gameStyle,
        updatePriority: {
          ...state.categorySettings.gameStyle.updatePriority,
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_SUCCESS]: (state: SettingsState): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        ...state.categorySettings.gameStyle,
        updatePriority: {
          ...state.categorySettings.gameStyle.updatePriority,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_UPDATE_GAME_STYLE_SETTINGS_PRIORITY_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    categorySettings: {
      ...state.categorySettings,
      gameStyle: {
        ...state.categorySettings.gameStyle,
        updatePriority: {
          ...state.categorySettings.gameStyle.updatePriority,
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  })
};

export default {
  actionManager,
  initialState
};
