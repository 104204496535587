/**
 * @flow
 * */
import CacheService from 'core/services/storage/CacheService';
import { redirectUrl } from 'lib/helpers/redirectTo';

export default (data: Object) => {
  if (data.status === 401) {
    CacheService.clearStorage();
    window.location.href = `/login${redirectUrl()}`;
    throw new Error(data.AlertMessage);
  }

  if (data.HasError) {
    throw new Error(data.AlertMessage);
  }

  return data;
};
