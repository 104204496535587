import config from 'config';
import NetworkService from 'core/services/network/NetworkService';

import BaseSDK from 'core/sdk/BaseSDK';
import type {
  PartnerAlertSettingsParams, PartnerAlertSettingsModel,
  UpdatePartnerAlertSettingsParams, UpdatePartnerAlertSettingsModel,
  DeletePartnerAlertSettingsParams, DeletePartnerAlertSettingsModel
} from './types';

/**
 * @class PartnerAlertSettingsSDK
 */
class PartnerAlertSettingsSDK extends BaseSDK {
    _rmtApiService: NetworkService = null;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
     * getPartnerAlertSettings
     * @param {PartnerAlertSettingsParams} params -
     * @returns {Promise<{data: Array<PartnerAlertSettingsModel>}>} -
     */
    async getPartnerAlertSettings(params: PartnerAlertSettingsParams): {data: Array<PartnerAlertSettingsModel>} {
      const { partnerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'PartnerAlert/GetSettings',
        { query_params: { partnerId }, abortController: this.setAbortController('getPartnerAlertSettings') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * updatePartnerAlertSettings
     * @param {UpdatePartnerAlertSettingsParams} params -
     * @returns {Promise<{data: UpdatePartnerAlertSettingsParams}>} -
     */
    async updatePartnerAlertSettings(params: UpdatePartnerAlertSettingsParams): {data: UpdatePartnerAlertSettingsModel} {
      const { id, enabled, amount, count, partnerId, isNewRule } = params;

      let url = '/PartnerAlert/UpdateSetting';
      let bodyParams = { id, enabled, amount, count, partnerId };
      if (isNewRule) {
        url = '/PartnerAlert/AddPartnerAmountSetting';
        bodyParams = { enabled, amount, partnerId };
      }

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        url,
        { body: bodyParams }
      );

      return {
        data: body.Data
      };
    }

    /**
     * deletePartnerAlertSettings
     * @param {DeletePartnerAlertSettingsParams} params -
     * @returns {Promise<{data: DeletePartnerAlertSettingsModel}>} -
     */
    async deletePartnerAlertSettings(params: DeletePartnerAlertSettingsParams): {data: DeletePartnerAlertSettingsModel} {
      const { id, partnerId } = params;

      const { body } = await this._rmtApiService.makeAPIPostRequest(
        '/PartnerAlert/DeletePartnerAmountSetting',
        { body: { id, partnerId } }
      );

      return {
        data: body.Data
      };
    }

    /**
   * getEnabledPartners
   * @returns {Promise<{data: EnabledPartnersModel}>} -
   */
    async getEnabledPartners() {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Partner/GetEnabledPartners',
        { abortController: this.setAbortController('getEnabledPartners') }
      );

      return {
        data: body.Data
      };
    }

    /**
   * cancelPartnerSDKCalls
   * @param {Object} params -
   * @returns {Promise<Object>} -
   */
    cancelPartnerSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new PartnerAlertSettingsSDK();
