import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createReportInternalSymbol = createInternalSymbol('REPORTS');
const createReportSymbol = createSymbol('REPORTS');

export default {
  // user player reports
  FETCH_USER_PLAYER_REPORTS: createReportSymbol('FETCH_USER_PLAYER_REPORTS'),
  SET_FETCH_USER_PLAYER_REPORTS_SUCCESS: createReportSymbol('SET_FETCH_USER_PLAYER_REPORTS_SUCCESS'),
  SET_FETCH_USER_PLAYER_REPORTS_FAILURE: createReportSymbol('SET_FETCH_USER_PLAYER_REPORTS_FAILURE'),
  CLEAR_USER_PLAYER_REPORTS_STATE: createReportInternalSymbol('CLEAR_USER_PLAYER_REPORTS_STATE'),

  // alert reports
  FETCH_ALERT_REPORTS: createReportSymbol('FETCH_ALERT_REPORTS'),
  SET_FETCH_ALERT_REPORTS_SUCCESS: createReportSymbol('SET_FETCH_ALERT_REPORTS_SUCCESS'),
  SET_FETCH_ALERT_REPORTS_FAILURE: createReportSymbol('SET_FETCH_ALERT_REPORTS_FAILURE'),
  CLEAR_ALERT_REPORTS_STATE: createReportInternalSymbol('CLEAR_ALERT_REPORTS_STATE'),

  // Player Block Status Reports
  FETCH_PLAYER_BLOCK_STATUS_REPORTS: createReportSymbol('FETCH_PLAYER_BLOCK_STATUS_REPORTS'),
  SET_FETCH_PLAYER_BLOCK_STATUS_REPORTS_SUCCESS: createReportSymbol('SET_FETCH_PLAYER_BLOCK_STATUS_SUCCESS'),
  SET_FETCH_PLAYER_BLOCK_STATUS_REPORTS_FAILURE: createReportSymbol('SET_FETCH_PLAYER_BLOCK_STATUS_FAILURE'),
  CLEAR_PLAYER_BLOCK_STATUS_STATE: createReportInternalSymbol('CLEAR_PLAYER_BLOCK_STATUS_STATE'),

  CANCEL_REPORT_CALL: createReportSymbol('CANCEL_REPORT_CALL')
};
