/**
 * @flow
 * */
import { RoundExpandInfoParams, RoundInfoParams } from 'modules/LCRMT/Dashboard/types';
import type { Action } from 'lib/core/flow';

import LCRMT_DASHBOARD_TYPES from './types';

// dashboard filter
export const fetchDashboardFilterData = ():Action => ({
  type: LCRMT_DASHBOARD_TYPES.FETCH_LCRMT_DASHBOARD_FILTER
});

export const fetchDashboardFilterDataSuccess = ({ data }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_LCRMT_DASHBOARD_FILTER_SUCCESS,
  payload: { data }
});

export const fetchDashboardFilterDataFailure = ({ error }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_LCRMT_DASHBOARD_FILTER_FAILURE,
  payload: { error }
});

export const removeDashboardFilterState = ():Action => ({
  type: LCRMT_DASHBOARD_TYPES.CLEAR_LCRMT_DASHBOARD_FILTER_STATE
});

// dashboard charts
export const fetchDashboardChartsData = (params: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.FETCH_LCRMT_DASHBOARD_CHARTS,
  payload: params
});

export const fetchDashboardChartsDataSuccess = ({ data }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_LCRMT_DASHBOARD_CHARTS_SUCCESS,
  payload: { data }
});

export const fetchDashboardChartsDataFailure = ({ error }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_LCRMT_DASHBOARD_CHARTS_FAILURE,
  payload: { error }
});

export const removeDashboardChartsState = ():Action => ({
  type: LCRMT_DASHBOARD_TYPES.CLEAR_LCRMT_DASHBOARD_CHARTS_STATE
});

// dashboard top players
export const fetchDashboardTopPlayersData = (params: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.FETCH_LCRMT_DASHBOARD_TOP_PLAYERS,
  payload: params
});

export const fetchDashboardTopPlayersDataSuccess = ({ data }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_SUCCESS,
  payload: { data }
});

export const fetchDashboardTopPlayersDataFailure = ({ error }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_LCRMT_DASHBOARD_TOP_PLAYERS_FAILURE,
  payload: { error }
});

export const removeDashboardTopPlayersState = ():Action => ({
  type: LCRMT_DASHBOARD_TYPES.CLEAR_LCRMT_DASHBOARD_TOP_PLAYERS_STATE
});

// Round Info
export const fetchRoundInfo = ({ tableId, roundId }:RoundInfoParams):Action => ({
  type: LCRMT_DASHBOARD_TYPES.FETCH_ROUND_INFO,
  payload: { tableId, roundId }
});

export const fetchRoundInfoDataSuccess = ({ data }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_SUCCESS,
  payload: { data }
});

export const fetchRoundInfoDataFailure = ({ error }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_FAILURE,
  payload: { error }
});

export const removeRoundInfoState = ():Action => ({
  type: LCRMT_DASHBOARD_TYPES.CLEAR_ROUND_INFO_STATE
});

// Round Info Expand
export const fetchRoundInfoExpand = ({ tableId, roundId, clientId, index }: RoundExpandInfoParams):Action => ({
  type: LCRMT_DASHBOARD_TYPES.FETCH_ROUND_INFO_EXPAND,
  payload: { tableId, roundId, clientId, index }
});

export const fetchRoundInfoExpandDataSuccess = ({ index, data }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_EXPAND_SUCCESS,
  payload: { index, data }
});

export const fetchRoundInfoExpandDataFailure = ({ index, error }: Object):Action => ({
  type: LCRMT_DASHBOARD_TYPES.SET_FETCH_ROUND_INFO_EXPAND_FAILURE,
  payload: { index, error }
});

// cancel call
export const cancelDashboardSDKCAll = ({ name }:any): Action => ({
  type: LCRMT_DASHBOARD_TYPES.CANCEL_DASHBOARD_SDK_CALL,
  payload: { name }
});
