/**
 * @flow
 * */
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

import DASHBOARD_ACTION_TYPES from 'modules/SRMT/Dashboard/store/actions/dashboard/types';
import { initialSectionState } from 'store/reducers/initialStates';
import type { DashboardState } from 'modules/SRMT/Dashboard/types';

const initialState = (): DashboardState => ({
  analyticChart: initialSectionState(),
  categories: initialSectionState(),
  analyticKpis: initialSectionState(),
  gameStyleHistory: initialSectionState(),
  gameStyleTendency: initialSectionState()
});

const actionManager = {
  // Categories Chart
  [DASHBOARD_ACTION_TYPES.FETCH_ANALYTIC_CHART]: (state: DashboardState) => ({
    ...state,
    analyticChart: {
      ...state.analyticChart,
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_CHART_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    analyticChart: {
      ...state.analyticChart,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_CHART_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    analyticChart: {
      ...state.analyticChart,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_ANALYTIC_CHART_STATE]: (state: DashboardState) => ({
    ...state,
    analyticChart: initialSectionState()
  }),
  // Alert Chart Linked
  [DASHBOARD_ACTION_TYPES.FETCH_ALERT_CHART_LINKED]: (state: DashboardState) => ({
    ...state,
    alertChartLinked: {
      ...state.alertChartLinked,
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_LINKED_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    alertChartLinked: {
      ...state.alertChartLinked,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_LINKED_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    alertChartLinked: {
      ...state.alertChartLinked,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_ALERT_CHART_LINKED_STATE]: (state: DashboardState) => ({
    ...state,
    alertChartLinked: initialSectionState()
  }),
  // Alert Chart Multi
  [DASHBOARD_ACTION_TYPES.FETCH_ALERT_CHART_MULTI]: (state: DashboardState) => ({
    ...state,
    alertChartMulti: {
      ...state.alertChartMulti,
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_MULTI_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    alertChartMulti: {
      ...state.alertChartMulti,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ALERT_CHART_MULTI_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    alertChartMulti: {
      ...state.alertChartMulti,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_ALERT_CHART_MULTI_STATE]: (state: DashboardState) => ({
    ...state,
    alertChartMulti: initialSectionState()
  }),
  // Categories Chart
  [DASHBOARD_ACTION_TYPES.FETCH_CATEGORIES_CHART]: (state: DashboardState) => ({
    ...state,
    categories: {
      ...state.categories,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_CATEGORIES_CHART_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    categories: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_CATEGORIES_CHART_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    categories: {
      ...state.categories,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_CATEGORIES_CHART_STATE]: (state: DashboardState) => ({
    ...state,
    categories: initialSectionState()
  }),

  // Game Style History Chart
  [DASHBOARD_ACTION_TYPES.FETCH_GAME_STYLE_HISTORY_CHART]: (state: DashboardState) => ({
    ...state,
    gameStyleHistory: {
      ...state.gameStyleHistory,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_HISTORY_CHART_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    gameStyleHistory: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_HISTORY_CHART_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    gameStyleHistory: {
      ...state.gameStyleHistory,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_GAME_STYLE_HISTORY_CHART_STATE]: (state: DashboardState) => ({
    ...state,
    gameStyleHistory: initialSectionState()
  }),

  // Game Style Tendency Chart
  [DASHBOARD_ACTION_TYPES.FETCH_GAME_STYLE_TENDENCY_CHART]: (state: DashboardState) => ({
    ...state,
    gameStyleTendency: {
      ...state.gameStyleTendency,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_TENDENCY_CHART_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    gameStyleTendency: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_GAME_STYLE_TENDENCY_CHART_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    gameStyleTendency: {
      ...state.gameStyleTendency,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_GAME_STYLE_TENDENCY_CHART_STATE]: (state: DashboardState) => ({
    ...state,
    gameStyleTendency: initialSectionState()
  }),

  // Analytic kpis
  [DASHBOARD_ACTION_TYPES.FETCH_ANALYTIC_KPIS]: (state: DashboardState) => ({
    ...state,
    analyticKpis: {
      ...state.analyticKpis,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_KPIS_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    analyticKpis: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_ANALYTIC_KPIS_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    analyticKpis: {
      ...state.analyticKpis,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_ANALYTIC_KPIS_STATE]: (state: DashboardState) => ({
    ...state,
    analyticKpis: initialSectionState()
  }),

  // Licenses kpis
  [DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_KPIS]: (state: DashboardState) => ({
    ...state,
    dashboardKpis: {
      ...state.dashboardKpis,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_KPIS_SUCCESS]: (state: DashboardState, payload) => ({
    ...state,
    dashboardKpis: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_KPIS_FAILURE]: (state: DashboardState, payload) => ({
    ...state,
    dashboardKpis: {
      ...state.dashboardKpis,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [DASHBOARD_ACTION_TYPES.CLEAR_DASHBOARD_KPIS_STATE]: (state: DashboardState) => ({
    ...state,
    dashboardKpis: initialSectionState()
  })

};

export default (state:DashboardState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
