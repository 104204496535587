/* eslint-disable */
/**
 * @flow
 * */
import settings from 'config/constants';

import CacheService from 'core/services/storage/CacheService';

import exceptionDetector from './interceptors/exceptionDetector';
import refreshTokenDetector from "./interceptors/refreshTokenDetector";

const NETWORK_SERVICE = settings.NETWORK_SERVICE;

class NetworkService {
  _interceptors = [exceptionDetector, refreshTokenDetector];
  _uri: ?string = null;

  // $FlowFixMe
  constructor(uri: string, interceptors = []) {
    if (!uri || typeof uri !== 'string') {
      throw new Error('The "uri" argument must be string.');
    }

    if (interceptors.length) {
      interceptors.forEach(interceptor => {
        if (typeof interceptor !== 'function') {
          throw new Error(`The '${interceptor}' is not a function.`)
        }
        this._interceptors.push(interceptor)
      });
    }

    this._uri = uri;
  };

  makeAPIGetRequest = (url: string, options: Object = {}) => {
    options = options || {};
    options.method = NETWORK_SERVICE.REQUEST_METHODS.GET;
    return this.makeAPIRequest(url, options);
  };

  makeAPIPostRequest = (url: string, options: Object = {}) => {
    options.method = NETWORK_SERVICE.REQUEST_METHODS.POST;
    return this.makeAPIRequest(url, options);
  };

  makeAPIPutRequest = (urlPrefix: string, options: Object = {}) => {
    options.method = NETWORK_SERVICE.REQUEST_METHODS.PUT;
    return this.makeAPIRequest(urlPrefix, options);
  };

  makeAPIGeneralRequest = (urlPrefix: string, options: Object = {}, type:string) => {
    options.method = type;
    return this.makeAPIRequest(urlPrefix, options);
  };

  makeAPIDeleteRequest = (urlPrefix: string, options: Object = {}) => {
    options.method = NETWORK_SERVICE.REQUEST_METHODS.DELETE;
    return this.makeAPIRequest(urlPrefix, options);
  };

  makeAPIPatchRequest = (urlPrefix: string, options: Object = {}) => {
    options.method = NETWORK_SERVICE.REQUEST_METHODS.PATCH;
    return this.makeAPIRequest(urlPrefix, options);
  };

  createUrl = (arg: Array<string> | string) => {
    if (Array.isArray(arg)) {
      return [this._uri, ...arg].join('/');
    }
    // $FlowFixMe
    return `${this._uri}/${arg}`;
  };

  createQueryParams = (queryParams: Object) => Object.keys(queryParams).reduce((accumulator, key) => {
    const item = queryParams[key];
    if (item === null || item === undefined) return accumulator;

    if (Array.isArray(item)) {
      for (let index = 0; index < item.length; index++) {
        const arrItem = item[index];
        accumulator += `${key}=${arrItem}&`;
      }
    } else {
      accumulator += `${key}=${item}&`;
    }
    return accumulator;
  }, '');

  // $FlowFixMe
  makeAPIRequest = (partUrl: string, options: ?Object = {}): Promise => {
    return new Promise((resolve, reject) => {
      let url: string = this.createUrl(partUrl);

      if (!url) {
        return reject(NETWORK_SERVICE.ERRORS.INVALID_REQUEST_PARAMS);
      }
      // $FlowFixMe
      if (options.query_params) {
        url += `?${this.createQueryParams(options.query_params)}`;
      }
      // $FlowFixMe
      if (!options.method) {
        // $FlowFixMe
        options.method = NETWORK_SERVICE.REQUEST_METHODS.GET;
      }

      const liveCasinoToken =CacheService.getItem('live_casino_token');
      const auth_token = CacheService.getItem('auth_token');

      const authKey = auth_token ? { 'Authentication': auth_token } : null;

      const fetch_options = {
        // $FlowFixMe
        method: options.method,
        // $FlowFixMe
        headers: {
          Accept: 'application/json, text/plain',
          'Content-Type': 'application/json',
          version: 1
        }
      };

      // $FlowFixMe
      if (options.credentials) {
        // $FlowFixMe
        fetch_options.credentials = options.credentials;
      };

      if (authKey){
        fetch_options.headers = {
          ...fetch_options.headers,
          ...authKey
        }
      }
      if (liveCasinoToken){
        fetch_options.headers = {
          ...fetch_options.headers,
          LiveCasino:  liveCasinoToken
        }
      }


      // $FlowFixMe
      if (options.headers) {
        // $FlowFixMe
        fetch_options.headers = {
          ...fetch_options.headers,
          ...options.headers
        };
        if (!fetch_options.headers['Content-Type']){
          delete fetch_options.headers['Content-Type'];
        }
      }
      try {
        // $FlowFixMe
        if (options.body) {
          // $FlowFixMe
          fetch_options.body = fetch_options.headers['Content-Type'] === 'application/json' ? JSON.stringify(options.body) : options.body;
        }
      } catch (ex) {
        return reject({ message: NETWORK_SERVICE.ERRORS.INVALID_REQUEST_PARAMS });
      }

      if (options.abortController) {
        // $FlowFixMe
        fetch_options.signal = options.abortController.signal;
      }
      if (options.caching){
        const callCaching = CacheService.callCaching(url);
        if (callCaching) {
          return resolve(callCaching);
        }
      }
      fetch(url, fetch_options)
        .then(async response => {

          if (!response) {
            return reject({ message: NETWORK_SERVICE.ERRORS.INVALID_RESPONSE_DATA });
          }

          const { headers } = response;

          let body = {};

          const contentType = headers.get('content-type');

          if (contentType && contentType.indexOf('application/json') !== -1) {
            body = await response.json();
          }

          body.status = response.status;

          try {
            if (this._interceptors.length) {
              this._interceptors.forEach(interceptor => {
                if (typeof interceptor === 'function') {
                  // $FlowFixMe
                  body = interceptor(body, headers);
                }
              });
            }
          } catch(e) {
            reject({ message: e.message, res: { body, headers } });
          }
          if (response.status >= 400) {
            return reject({ message: body.AlertMessage, res: { body, headers } });
          }

          if (options.caching){
            CacheService.setCallCaching(url, { body, headers });
          }
          return resolve({ body, headers });
        }).catch(err => reject(err));
    })
  }
}

export default NetworkService;
