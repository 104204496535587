import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import { initialSectionWithCountState } from 'store/reducers/initialStates';
import type { SettingsState } from 'modules/SRMT/Settings/types';

const initialState = (): SettingsState => ({
  changeHistory: initialSectionWithCountState()
});

const actionManager = {
  [SETTINGS_ACTION_TYPES.FETCH_CHANGE_HISTORY]: (state: SettingsState): SettingsState => ({
    ...state,
    changeHistory: {
      ...state.changeHistory,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_CHANGE_HISTORY_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    changeHistory: {
      status: ACTION_STATUSES.SUCCEED,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.changeHistory.totalCount,
      data: payload.data,
      error: null
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_CHANGE_HISTORY_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    changeHistory: {
      status: ACTION_STATUSES.FAILED,
      totalCount: null,
      data: null,
      error: payload.error
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_CHANGE_HISTORY_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    changeHistory: initialSectionWithCountState()
  })
};

export default {
  actionManager,
  initialState
};
