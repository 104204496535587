import type { Action } from 'lib/core/flow';
import ALERT_MONITOR_ACTION_TYPES from './types';
import type { AMFilterObject, EditReviewDataModel } from '../../types';

export const initMonitor = (monitorKey: string) => ({
  type: ALERT_MONITOR_ACTION_TYPES.INIT_MONITOR,
  payload: { monitorKey }
});
export const unmountMonitor = (monitorKey: string) => ({
  type: ALERT_MONITOR_ACTION_TYPES.UNMOUNT_MONITOR,
  payload: { monitorKey }
});

// monitor data
type fetchMonitorTabPayload = {|
  filters: AMFilterObject,
  tabKey: string
|};

type editReviewMonitorTabPayload = {|
  actionType: string,
  data: EditReviewDataModel,
  tabKey: string
|};

type historyReviewChildrenPayload = {|
  alertHistoryId: number
|};

type expandRowChildrenPayload = {|
  alertId: number
|};

export const fetchMonitorTab = ({ tabKey, filters = {} }: fetchMonitorTabPayload) => ({
  type: ALERT_MONITOR_ACTION_TYPES.FETCH_MONITOR_TAB,
  payload: { tabKey, filters }
});
export const setFetchMonitorTabSuccess = ({ tabKey, data, totalCount, passTotalCount }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_FETCH_MONITOR_TAB_SUCCESS,
  payload: { tabKey, data, totalCount, passTotalCount }
});
export const setFetchMonitorTabFailure = ({ tabKey, error }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_FETCH_MONITOR_TAB_FAILURE,
  payload: { tabKey, error }
});
export const clearMonitorTabState = ({ tabKey }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.CLEAR_MONITOR_TAB_STATE,
  payload: { tabKey }
});
export const removeAlertRow = ({ tabKey, id }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.REMOVE_ALERT_ROW,
  payload: { tabKey, id }
});
export const changeRowStatus = ({ tabKey, id, status, Created, UserId, UserName }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.CHANGE_ALERT_ROW,
  payload: { tabKey, id, status, Created, UserId, UserName }
});

// review data
export const fetchReviewData = ({ tabKey, filters = {} }: fetchMonitorTabPayload) => ({
  type: ALERT_MONITOR_ACTION_TYPES.FETCH_REVIEW_DATA,
  payload: { tabKey, filters }
});
export const setFetchReviewDataSuccess = ({ tabKey, data }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_FETCH_REVIEW_DATA_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchReviewDataFailure = ({ tabKey, error }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_FETCH_REVIEW_DATA_FAILURE,
  payload: { tabKey, error }
});
export const clearReviewData = ({ tabKey }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.CLEAR_REVIEW_DATA,
  payload: { tabKey }
});

// edit review data
export const editReviewData = ({ tabKey, data = {}, actionType }: editReviewMonitorTabPayload) => ({
  type: ALERT_MONITOR_ACTION_TYPES.EDIT_REVIEW_DATA,
  payload: { tabKey, data, actionType }
});
export const setEditReviewDataSuccess = ({ tabKey, response }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_EDIT_REVIEW_DATA_SUCCESS,
  payload: { tabKey, response }
});
export const setEditReviewDataFailure = ({ tabKey, error }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_EDIT_REVIEW_DATA_FAILURE,
  payload: { tabKey, error }
});
export const clearEditReviewData = ({ tabKey }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.CLEAR_EDIT_REVIEW_DATA,
  payload: { tabKey }
});

// review history data
export const fetchHistoryReviewData = ({ tabKey, alertId, alertType }: editReviewMonitorTabPayload) => ({
  type: ALERT_MONITOR_ACTION_TYPES.FETCH_HISTORY_REVIEW_DATA,
  payload: { tabKey, alertId, alertType }
});
export const setHistoryReviewDataSuccess = ({ tabKey, data }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_HISTORY_REVIEW_DATA_SUCCESS,
  payload: { tabKey, data }
});
export const setHistoryReviewDataFailure = ({ tabKey, error }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_EDIT_REVIEW_DATA_FAILURE,
  payload: { tabKey, error }
});
export const clearHistoryReviewData = ({ tabKey }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.CLEAR_HISTORY_REVIEW_DATA,
  payload: { tabKey }
});

// review history children data
export const fetchHistoryReviewChildrenData = ({ tabKey, alertHistoryId, alertId, alertType }: historyReviewChildrenPayload) => ({
  type: ALERT_MONITOR_ACTION_TYPES.FETCH_HISTORY_REVIEW_CHILDREN_DATA,
  payload: { tabKey, alertHistoryId, alertId, alertType }
});
export const setHistoryReviewChildrenDataSuccess = ({ tabKey, data, alertHistoryId }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_HISTORY_REVIEW_CHILDREN_DATA_SUCCESS,
  payload: { tabKey, data, alertHistoryId }
});
export const setHistoryReviewChildrenDataFailure = ({ tabKey, error, alertHistoryId }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_HISTORY_REVIEW_CHILDREN_DATA_FAILURE,
  payload: { tabKey, error, alertHistoryId }
});

// expand row children data
export const fetchExpandRowChildrenData = ({ tabKey, alertId }: expandRowChildrenPayload) => ({
  type: ALERT_MONITOR_ACTION_TYPES.FETCH_EXPAND_ROW_CHILDREN_DATA,
  payload: { tabKey, alertId }
});
export const setExpandRowChildrenDataSuccess = ({ tabKey, data, alertId }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_EXPAND_ROW_CHILDREN_DATA_SUCCESS,
  payload: { tabKey, data, alertId }
});
export const setExpandRowChildrenDataFailure = ({ tabKey, error, alertId }) => ({
  type: ALERT_MONITOR_ACTION_TYPES.SET_EXPAND_ROW_CHILDREN_DATA_FAILURE,
  payload: { tabKey, error, alertId }
});

// cancel call
export const cancelAlertSdkCall = ({ name }): Action => ({
  type: ALERT_MONITOR_ACTION_TYPES.CANCEL_ALERT_SDK_CALL,
  payload: { name }
});
