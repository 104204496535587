export const triggerTypes = {
  paymentSystem: 2,
  fingerprint: 3,
  birthDateFirstNameLastName: 6,
  lastNameBirthDateAddress: 7,
  lastNameBirthDateEmail: 8,
  birthDateAddressEmail: 9,
  lastNameEmailAddress: 10,
  documentNumber: 11,
  email: 12,
  phoneCountry: 13,
  registrationIp: 20
};
