/**
 * @flow
 * */
import NOTIFICATION_ACTION_TYPES from 'modules/Dashboard/Auth/store/actions/notification/types';
import { initialSectionState } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import { NotificationState } from 'modules/Dashboard/Auth/types';
import type { Action } from 'lib/core/flow';

const initialState = () => ({
  getNotification: initialSectionState(),
  getAddUpdateNotification: initialSectionState(),
  getNotificationFilter: initialSectionState(),
  notifications: initialSectionState()
});

const actionManager = {
  // get Notification
  [NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION]: (state: NotificationState) => ({
    ...state,
    getNotification: {
      ...state.getNotification,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION_SUCCESS]: (state: NotificationState, payload) => ({
    ...state,
    getNotification: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION_FAILURE]: (state: NotificationState, payload) => ({
    ...state,
    getNotification: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload
    }
  }),
  [NOTIFICATION_ACTION_TYPES.CLEAR_GET_NOTIFICATION]: (state: NotificationState) => ({
    ...state,
    getNotification: initialSectionState()
  }),

  // get add update Notification
  [NOTIFICATION_ACTION_TYPES.FETCH_ADD_UPDATE_NOTIFICATION]: (state: NotificationState) => ({
    ...state,
    getAddUpdateNotification: {
      ...state.getAddUpdateNotification,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_ADD_UPDATE_NOTIFICATION_SUCCESS]: (state: NotificationState, payload) => ({
    ...state,
    getAddUpdateNotification: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_ADD_UPDATE_NOTIFICATION_FAILURE]: (state: NotificationState, payload) => ({
    ...state,
    getAddUpdateNotification: {
      data: payload,
      status: ACTION_STATUSES.FAILED,
      error: payload
    }
  }),
  [NOTIFICATION_ACTION_TYPES.CLEAR_ADD_UPDATE_NOTIFICATION]: (state: NotificationState) => ({
    ...state,
    getAddUpdateNotification: initialSectionState()
  }),

  // get Notification Filter
  [NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION_FILTER]: (state: NotificationState) => ({
    ...state,
    getNotificationFilter: {
      ...state.getNotificationFilter,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION_FILTER_SUCCESS]: (state: NotificationState, payload) => ({
    ...state,
    getNotificationFilter: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_GET_NOTIFICATION_FILTER_FAILURE]: (state: NotificationState, payload) => ({
    ...state,
    getNotificationFilter: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload
    }
  }),
  [NOTIFICATION_ACTION_TYPES.CLEAR_GET_NOTIFICATION_FILTER]: (state: NotificationState) => ({
    ...state,
    getNotificationFilter: initialSectionState()
  }),

  // get Notifications
  [NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS]: (state: NotificationState) => ({
    ...state,
    notifications: {
      ...state.notifications,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS_SUCCESS]: (state: NotificationState, payload) => ({
    ...state,
    notifications: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS_FAILURE]: (state: NotificationState, payload) => ({
    ...state,
    notifications: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload
    }
  }),
  [NOTIFICATION_ACTION_TYPES.CLEAR_NOTIFICATIONS]: (state: NotificationState) => ({
    ...state,
    notifications: initialSectionState()
  })
};

export default (state: NotificationState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
