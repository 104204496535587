/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import PLAYER_ACTIONS from './types';

// Multi Accounts
export const fetchMultiAccounts = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_MULTI_ACCOUNTS,
  payload: { playerId }
});
export const setFetchMultiAccountsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_MULTI_ACCOUNTS_SUCCESS,
  payload: { data }
});
export const setFetchMultiAccountsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_MULTI_ACCOUNTS_FAILURE,
  payload: { error }
});
export const clearMultiAccountsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_MULTI_ACCOUNTS_STATE
});
