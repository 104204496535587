/**
 * @flow
 * */
import { initialSectionState, initialSectionWithCountState } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

import AM_ACTION_TYPES from 'modules/AML/store/actions/types';
import { AMLState } from 'modules/AML/types';

const initialState = (): AMLState => ({
  transactions: initialSectionWithCountState(),
  configs: initialSectionState(),
  boxes: initialSectionState()
});

const actionManager = {
  // boxes
  [AM_ACTION_TYPES.FETCH_BOXES]: (state: AMLState) => ({
    ...state,
    boxes: {
      ...state.boxes,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_BOXES_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    boxes: {
      error: null,
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_BOXES_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    boxes: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_BOXES]: (state: AMLState) => ({
    ...state,
    boxes: initialSectionState()
  }),

  // transactions
  [AM_ACTION_TYPES.FETCH_TRANSACTIONS]: (state: AMLState) => ({
    ...state,
    transactions: {
      ...state.transactions,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_TRANSACTIONS_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    transactions: {
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.transactions.totalCount,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_TRANSACTIONS_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    transactions: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_TRANSACTIONS]: (state: AMLState) => ({
    ...state,
    transactions: initialSectionWithCountState()
  }),

  // configs
  [AM_ACTION_TYPES.FETCH_CONFIGS]: (state: AMLState) => ({
    ...state,
    configs: {
      ...state.configs,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_CONFIGS_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    configs: {
      error: null,
      data: {
        ...state.configs.data || {},
        [payload.license]: payload.data
      },
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_CONFIGS_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    configs: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_CONFIGS]: (state: AMLState) => ({
    ...state,
    configs: initialSectionState()
  })
};

export default (state: AMLState = initialState(), action: Action) => {
  if (!actionManager[action.type]) return state;

  return actionManager[action.type](state, action.payload);
};
