const parseHistoryData = (response: Object) => {
  const { Data } = response;
  Data.forEach((item: Object) => {
    // eslint-disable-next-line no-param-reassign
    item.childrenHistory = {
      status: null,
      error: null,
      data: null
    };
  });
  return {
    data: Data
  };
};

export default parseHistoryData;
