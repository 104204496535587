export const arrayNormalizer = (arr: Array<any>, key: string) => {
  const returnedObj = {};
  arr.forEach(item => {
    returnedObj[item[key]] = item;
  });
  return returnedObj;
};

export const spliceArrayFromCustomItem = (arr: Array<any>, item: Object, key:string) => {
  arr.splice(arr.indexOf(arr.find(element => element[key] === item[key])), 1, item);
  return arr;
};
