import { combineReducers } from 'redux';

import statisticsController from 'modules/LCRMT/EquipmentIntegrity/core/controllers/StatisticsController';
import hourlyAlertController from 'modules/LCRMT/Alerts/core/controllers/HourlyAlertsControllers';
import dashboardController from 'modules/LCRMT/Dashboard/core/controllers/DashboardController';
import settingsController from 'modules/LCRMT/Settings/core/controller/settingsController';
import reportController from 'modules/LCRMT/Reports/core/controllers/ReportsController';
import PlayerController from 'modules/LCRMT/Players/core/controllers/PlayerController';
import alertController from 'modules/LCRMT/Alerts/core/controllers/AlertsController';

import EQUIPMENT_ROUTES from './EquipmentIntegrity/routes';
import DASHBOARD_ROUTES from './Dashboard/routes';
import PLAYERS_ROUTES from './Players/routes';
import REPORTS_ROUTES from './Reports/routes';
import ALERTS_ROUTES from './Alerts/routes';
import SETTINGS_ROUTES from './Settings/routes';

import equipment from './EquipmentIntegrity/store/reducers';
import dashboard from './Dashboard/store/reducers';
import settings from './Settings/store/reducers';
import reports from './Reports/store/reducers';
import player from './Players/store/reducers';
import alerts from './Alerts/store/reducers';

const getProjectState = (state) => state.LCRMT;

const routes = [
  ...ALERTS_ROUTES,
  ...DASHBOARD_ROUTES,
  ...PLAYERS_ROUTES,
  ...EQUIPMENT_ROUTES,
  ...REPORTS_ROUTES,
  ...SETTINGS_ROUTES
];

const controllers = [
  hourlyAlertController(),
  statisticsController(),
  alertController(),
  reportController(),
  PlayerController(),
  dashboardController(),
  settingsController()
];

const reducers = combineReducers({
  ...settings,
  ...dashboard,
  ...equipment,
  ...reports,
  ...alerts,
  ...player
});

export {
  getProjectState,
  controllers,
  reducers,
  routes
};
