/**
 * @flow
 * */

import { takeLatest, put } from 'redux-saga/effects';
import PLAYER_TYPES from 'store/actions/player/types';
import {
  setGetSRMTPLayerFilesFailure, setGetSRMTPLayerFilesSuccess,
  deleteSRMTPLayerFilesFailure, deleteSRMTPLayerFilesSuccess,
  deleteAMLPLayerFilesFailure, deleteAMLPLayerFilesSuccess,
  setGetAMLPLayerFilesFailure, setGetAMLPLayerFilesSuccess,
  setSRMTPLayerFilesFailure, setSRMTPLayerFilesSuccess,
  setAMLPLayerFilesFailure, setAMLPLayerFilesSuccess
} from 'store/actions/player/actions';

import playerSDK from '../sdk/AGPSDK/AGPSDK';

class PlayerController {
  static* setPlayerFiles({ payload }) {
    const { ImageData, ImageType, Name, ObjectId } = payload;
    try {
      // $FlowFixMe
      const { data } = yield playerSDK.AddClientImage({ ImageData, ImageType, Name, ObjectId });
      yield put(setSRMTPLayerFilesSuccess({ data }));
    } catch (err) {
      yield put(setSRMTPLayerFilesFailure({ error: err.message }));
    }
  }

  static* setAMLPlayerFiles({ payload }) {
    const { ImageData, ImageType, Name, ObjectId } = payload;
    try {
      // $FlowFixMe
      const { data } = yield playerSDK.AddClientImage({ ImageData, ImageType, Name, ObjectId });
      yield put(setAMLPLayerFilesSuccess({ data }));
    } catch (err) {
      yield put(setAMLPLayerFilesFailure({ error: err.message }));
    }
  }

  static* setGetSRMTPlayerFiles({ payload }) {
    const { clientId } = payload;
    try {
      // $FlowFixMe
      const { data } = yield playerSDK.GetClientUploadedImages({ clientId });
      yield put(setGetSRMTPLayerFilesSuccess({ data }));
    } catch (err) {
      yield put(setGetSRMTPLayerFilesFailure({ error: err.message }));
    }
  }

  static* setGetAMLPlayerFiles({ payload }) {
    const { clientId } = payload;
    try {
      // $FlowFixMe
      const { data } = yield playerSDK.GetClientUploadedImages({ clientId });
      yield put(setGetAMLPLayerFilesSuccess({ data }));
    } catch (err) {
      yield put(setGetAMLPLayerFilesFailure({ error: err.message }));
    }
  }

  static* deleteSRMTPlayerFiles({ payload }) {
    const { Id, TypeId, OriginalName, IsDeleted } = payload;
    try {
      // $FlowFixMe
      const { data } = yield playerSDK.DeleteClientFile({ Id, TypeId, OriginalName, IsDeleted });
      yield put(deleteSRMTPLayerFilesSuccess({ data }));
    } catch (err) {
      yield put(deleteSRMTPLayerFilesFailure({ error: err.message }));
    }
  }

  static* deleteAMLPlayerFiles({ payload }) {
    const { Id, TypeId, OriginalName, IsDeleted } = payload;
    try {
      // $FlowFixMe
      const { data } = yield playerSDK.DeleteClientFile({ Id, TypeId, OriginalName, IsDeleted });
      yield put(deleteAMLPLayerFilesSuccess({ data }));
    } catch (err) {
      yield put(deleteAMLPLayerFilesFailure({ error: err.message }));
    }
  }

  static* userWatcher<T>(): Iterable<T> {
    yield takeLatest(PLAYER_TYPES.FETCH_PLAYER_FILES, PlayerController.setPlayerFiles);
    yield takeLatest(PLAYER_TYPES.FETCH_AML_PLAYER_FILES, PlayerController.setAMLPlayerFiles);
    yield takeLatest(PLAYER_TYPES.FETCH_GET_SRMT_PLAYER_FILES, PlayerController.setGetSRMTPlayerFiles);
    yield takeLatest(PLAYER_TYPES.FETCH_GET_AML_PLAYER_FILES, PlayerController.setGetAMLPlayerFiles);
    yield takeLatest(PLAYER_TYPES.FETCH_DELETE_SRMT_PLAYER_FILES, PlayerController.deleteSRMTPlayerFiles);
    yield takeLatest(PLAYER_TYPES.FETCH_DELETE_AML_PLAYER_FILES, PlayerController.deleteAMLPlayerFiles);
  }
}

export default PlayerController.userWatcher;
