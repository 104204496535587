import notFoundDetector from 'core/services/network/interceptors/notFoundDetector';
import NetworkService from 'core/services/network/NetworkService';
import {
  ActivitySessionStatisticsParams,
  ActivitySessionViewExpandParams,
  TransactionsTableParams,
  TransactionsTableModel,
  TransactionsKPIParams,
  TransactionsKPIsModel,
  ActivityTableParams,
  GamingTableParams,
  GamingKPIParams,
  GamingKPIsModel
} from 'modules/LCRMT/Players/core/sdk/players/types';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';

import type {
  ActivitySessionViewParams,
  ActivitySessionParams,
  ActivityTableModel,
  GamingTableModel,
  GlobalInfoParams,
  GlobalInfoModel,
  PlayersParams,
  PlayersModel
} from 'modules/LCRMT/Players/core/sdk/players/types';
import type {
  MultiAccountsParams,
  MultiAccountModel,
  GlobalKPIsParams
} from 'modules/SRMT/Players/core/sdk/players/types';
import type { GlobalKPIsModel } from 'types';

/**
 * @class PlayersSDK
 */
class PlayersSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, [notFoundDetector]);
    }

    /**
     * getGlobalInfo
     * @param {GlobalInfoParams} params - PlayerId
     * @returns {Promise<{data: GlobalInfoModel}>} -
     */
    async getGlobalInfo(params: GlobalInfoParams): Promise<{data: GlobalInfoModel}> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetPlayerGlobalInfo',
        { query_params: { playerId }, abortController: this.setAbortController('GetPlayerGlobalInfoLCRMT') }
      );

      return {
        data: body.Data
      };
    }

    /**
   * getPlayersAlerts
   * @param {any} params - PlayerId
   * @returns {Promise<{data: any}>} -
   */
    async getPlayersAlerts(params: any): Promise<{data: any}> {
      const { playerId } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetPlayerAlerts',
        { query_params: { playerId, IsAGPClient: true }, abortController: this.setAbortController('GetAlerts') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * addLiveGamesRestrictions
     * @param {LiveGamesRestrictionsParams} params -
     * @returns {Promise<{data: LiveGamesRestrictionsModel}>} -
     */
    async getLiveGamesRestrictions(params: any): Promise<{ data: any }> {
      const { playerId, partnerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetPlayerRestrictions',
        { query_params: { playerId, partnerId }, abortController: this.setAbortController('getLiveGamesRestrictions') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * addLiveGamesRestrictions
     * @param {AddLiveGamesRestrictionsParams} params -
     * @returns {Promise<{data: AddLiveGamesRestrictionsModel}>} -
     */
    async addLiveGamesRestrictions(params: any): Promise<{ data: any }> {
      const { data, type } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        type === 'add' ? 'LCRMTPlayer/AddPlayerRestrictions' : 'LCRMTPlayer/UpdatePlayerRestrictions',
        { body: { ...data }, abortController: this.setAbortController('addLiveGamesRestrictions') }
      );
      return body;
    }

    /**
     * deleteLiveGamesRestrictions
     * @param {deleteLiveGamesRestrictionsParams} params -
     * @returns {Promise<{data: deleteLiveGamesRestrictionsModel}>} -
     */
    async deleteLiveGamesRestrictions(params: any): Promise<{ data: any }> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTPlayer/DeletePlayerRestrictions',
        { body: params }
      );
      return body;
    }

    /**
     * GetTablesByCategory
     * @param {GetTablesByCategoryParams} params -
     * @returns {Promise<{data: GetTablesByCategoryModel}>} -
     */
    async getTablesByCategory(params: any): Promise<{ data: any }> {
      const { categoryId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetTablesByCategory',
        { query_params: { categoryId }, abortController: this.setAbortController('getTablesByCategory'), caching: true }
      );
      return {
        data: body.Data
      };
    }

    /**
     * GetGameCategories
     * @param {GetGameCategoriesParams} params -
     * @returns {Promise<{data: GetGameCategoriesModel}>} -
     */
    async getGameCategories(params: any): Promise<{ data: any }> {
      const { partnerId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetGameCategories',
        { query_params: { partnerId }, abortController: this.setAbortController('getGameCategories'), caching: true }
      );
      return {
        data: body.Data
      };
    }

    /**
     * getHourlyActivity
     * @param {GlobalInfoParams} params - PlayerId
     * @returns {Promise<{data: GlobalInfoModel}>} -
     */
    async getHourlyActivity(params: any): Promise<{data: any}> {
      const { playerId, dateFrom, dateTo } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetPlayerHourlyActivity',
        { query_params: { playerId, dateFrom, dateTo }, abortController: this.setAbortController('GetHourlyActivityLCRMT') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getGlobalKPIs
     * @param {GlobalKPIsParams} params - PlayerId
     * @returns {Promise<{data: GlobalKPIsModel}>} -
     */
    async getGlobalKPIs(params: GlobalKPIsParams): Promise<{ data: GlobalKPIsModel }> {
      const { playerId, isEURCurrencyEnabled } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetPlayerBetKPIGlobal',
        { query_params: { playerId, isEURCurrencyEnabled }, abortController: this.setAbortController('getGlobalKPIsLCRMT') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getPlayers
     * @param {PlayersParams} params -
     * @returns {Promise<{data: PlayersModel}>} -
     */
    async getPlayers(params: PlayersParams): Promise<{ data: PlayersModel }> {
      const { count, start, ...filters } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTPlayer/FindPlayer',
        { body: { count, start, ...filters }, abortController: this.setAbortController('getLCRMTPlayers') }
      );

      return body;
    }

    /**
     * getActivitySession
     * @param {ActivitySessionParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async getActivitySession(params: ActivitySessionParams): Promise<{ data: Object }> {
      const { playerId, partnerId, filter } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetPlayerActivityHistory',
        { query_params: { playerId, partnerId, ...filter }, abortController: this.setAbortController('getActivitySession') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getActivitySessionView
     * @param {ActivitySessionViewParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async getActivitySessionView(params: ActivitySessionViewParams): Promise<{ data: Object }> {
      const { playerId, sessions } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/ReviewPlayerSessions',
        { query_params: { playerId, sessions }, abortController: this.setAbortController('getActivitySessionsView') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getActivitySessionViewExpand
     * @param {ActivitySessionViewExpandParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async getActivitySessionViewExpand(params: ActivitySessionViewExpandParams): Promise<{ data: Object }> {
      const { roundId, tableId, clientId } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetRoundData',
        { query_params: { roundId, tableId, clientId } }
      );

      return {
        data: body.Data
      };
    }

    /**
     * getActivitySessionStatistics
     * @param {ActivitySessionStatisticsParams} params -
     * @returns {Promise<{data: Object}>} -
     */
    async getActivitySessionStatistics(params: ActivitySessionStatisticsParams): Promise<{ data: Object }> {
      const { clientAGPId, dates, statisticType } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTAlerts/GetClientStatistic',
        { query_params: { clientAGPId, dates, statisticType } }
      );

      return {
        data: body.Data
      };
    }

    /**
   * getPlayerFinancialGamingKPI
   * @param {GamingKPIParams} params -
   * @returns {Promise<{data: GamingKPIsModel}>} -
   */
    async getPlayerFinancialGamingKPI(params: GamingKPIParams): Promise<{ data: GamingKPIsModel }> {
      const { partnerId, clientId, ...filters } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTPlayer/GetPlayerKPIGaming',
        { body: { partnerId, clientId, ...filters.filter }, abortController: this.setAbortController('GetPlayerKPIGaming') }
      );

      return {
        data: body.Data
      };
    }

    /**
   * getPlayerFinancialGamingTable
   * @param {GamingTableParams} params -
   * @returns {Promise<{data: GamingTableModel}>} -
   */
    async getPlayerFinancialGamingTable(params: GamingTableParams): Promise<{ data: Array<GamingTableModel> }> {
      const { partnerId, clientId, ...filter } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTPlayer/GetPlayerKPIGamingByType',
        { body: { partnerId, clientId, ...filter.filter }, abortController: this.setAbortController('GetPlayerKPIGamingByType') }
      );
      return {
        data: body.Data
      };
    }

    /**
   * getPlayerFinancialActivity
   * @param {ActivityTableParams} params -
   * @returns {Promise<{data: ActivityTableModel}>} -
   */
    async getPlayerFinancialActivity(params: ActivityTableParams): Promise<{ data: Array<ActivityTableModel> }> {
      const { partnerId, clientId, ...filters } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTPlayer/GetPlayerKPIActivity',
        { body: { partnerId, clientId, ...filters.filter }, abortController: this.setAbortController('GetPlayerKPIActivity') }
      );
      return {
        data: body.Data
      };
    }

    /**
   * getPlayerFinancialTransactionsKPI
   * @param {TransactionsKPIParams} params -
   * @returns {Promise<{data: TransactionsKPIsModel}>} -
   */
    async getPlayerFinancialTransactionsKPI(params: TransactionsKPIParams): Promise<{ data: TransactionsKPIsModel }> {
      const { partnerId, clientId, ...filters } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTPlayer/GetPlayerKPITransactionsGlobal',
        { query_params: { partnerId, clientId, ...filters.filter }, abortController: this.setAbortController('GetPlayerKPITransactionsGlobal') }
      );
      return {
        data: body.Data
      };
    }

    /**
   * getPlayerFinancialTransactionsTable
   * @param {TransactionsTableParams} params -
   * @returns {Promise<{data: TransactionsTableModel}>} -
   */
    async getPlayerFinancialTransactionsTable(params: TransactionsTableParams): Promise<{ data: Array<TransactionsTableModel> }> {
      const { partnerId, clientId, ...filters } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTPlayer/GetPlayerKPITransactions',
        { body: { partnerId, clientId, ...filters.filter }, abortController: this.setAbortController('GetPlayerKPITransactions') }
      );
      return {
        data: body.Data
      };
    }

    /**
   * getMultiAccounts
   * @param {GlobalKPIsParams} params -
   * @returns {Promise<{data: GlobalKPIsModel}>} -
   */
    async getLCRMTMultiAccounts(params: MultiAccountsParams): Promise<{ data: Array<MultiAccountModel | null> }> {
      const { playerId, count, start } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Player/GetFraudMultiAccounts',
        { query_params: { playerId, count, start }, abortController: this.setAbortController('getLCRMTMultiAccounts') }
      );

      return body;
    }

    /**
     * cancelPlayerSDKCalls
     * @param {PlayerForceCalculationParams} params -
     * @returns {Promise<{data: PlayerForceCalculationModel}>} -
     */
    cancelPlayerSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new PlayersSDK();
