/**
 * @flow
 * */
import type { Action, Action as BaseAction } from 'lib/core/flow';

import HOW_TO_ACTION_TYPES from './types';

export const fetchHowTo = ({ data }: any): BaseAction => ({
  type: HOW_TO_ACTION_TYPES.FETCH_HOW_TO,
  payload: data
});

export const fetchHowToFailed = (error: string): BaseAction => ({
  type: HOW_TO_ACTION_TYPES.FETCH_HOW_TO_FAILURE,
  payload: error
});

export const fetchHowToSucceed = ({ data }: any): BaseAction => ({
  type: HOW_TO_ACTION_TYPES.FETCH_HOW_TO_SUCCESS,
  payload: data
});

export const clearHowTo = (): BaseAction => ({
  type: HOW_TO_ACTION_TYPES.CLEAR_HOW_TO
});

export const cancelHowToCalls = ({ name }: any): Action => ({
  type: HOW_TO_ACTION_TYPES.CANCEL_HOW_TO,
  payload: { name }
});
