/**
 * @flow
 * */
import { initialSectionWithActionDelete, initialSectionWithoutData } from 'lib/core/initialStates';
import { makeActionData } from 'modules/Admin/AutomatedComments/utils/makeActionData';
import ACTION_TYPES from 'modules/Admin/AutomatedComments/store/actions/types';
import { AutomatedCommentState } from 'modules/Admin/AutomatedComments/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

const initialState = (): AutomatedCommentState => ({
  ...initialSectionWithActionDelete()
});

const actionManager = {

  // Automated Comments
  [ACTION_TYPES.FETCH_AUTOMATED_COMMENT]: (state: AutomatedCommentState): AutomatedCommentState => ({
    ...state,
    status: ACTION_STATUSES.PENDING
  }),

  [ACTION_TYPES.SET_FETCH_AUTOMATED_COMMENT_SUCCESS]: (state: AutomatedCommentState, payload): AutomatedCommentState => ({
    ...state,
    status: ACTION_STATUSES.SUCCEED,
    data: payload.data,
    totalCount: !payload.passTotalCount ? payload.totalCount : state.totalCount,
    error: null
  }),

  [ACTION_TYPES.SET_FETCH_AUTOMATED_COMMENT_FAILURE]: (state: AutomatedCommentState, payload): AutomatedCommentState => ({
    ...state,
    status: ACTION_STATUSES.FAILED,
    error: payload.error
  }),

  [ACTION_TYPES.CLEAR_AUTOMATED_COMMENT_STATE]: (): AutomatedCommentState => ({
    ...initialSectionWithActionDelete()
  }),

  // Automated Comments Action
  [ACTION_TYPES.FETCH_ACTION_AUTOMATED_COMMENT]: (state: AutomatedCommentState): AutomatedCommentState => ({
    ...state,
    action: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),

  [ACTION_TYPES.SET_FETCH_ACTION_AUTOMATED_COMMENT_SUCCESS]: (state: AutomatedCommentState, payload): AutomatedCommentState => ({
    ...state,
    data: makeActionData(payload, state.data),
    action: {
      ...state.action,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),

  [ACTION_TYPES.SET_FETCH_ACTION_AUTOMATED_COMMENT_FAILURE]: (state: AutomatedCommentState, payload): AutomatedCommentState => ({
    ...state,
    action: {
      ...state.action,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),

  [ACTION_TYPES.CLEAR_ACTION_AUTOMATED_COMMENT]: (state: AutomatedCommentState): AutomatedCommentState => ({
    ...state,
    action: initialSectionWithoutData()
  }),

  // Automated Comments Delete
  [ACTION_TYPES.FETCH_DELETE_AUTOMATED_COMMENT]: (state: AutomatedCommentState): AutomatedCommentState => ({
    ...state,
    delete: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),

  [ACTION_TYPES.SET_FETCH_DELETE_AUTOMATED_COMMENTS_SUCCESS]: (state: AutomatedCommentState, payload): AutomatedCommentState => ({
    ...state,
    data: state.data.filter(item => item.Id !== payload.id),
    delete: {
      ...state.delete,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),

  [ACTION_TYPES.SET_FETCH_DELETE_AUTOMATED_COMMENT_FAILURE]: (state: AutomatedCommentState, payload): AutomatedCommentState => ({
    ...state,
    delete: {
      ...state.delete,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),

  [ACTION_TYPES.CLEAR_DELETE_AUTOMATED_COMMENT]: (state: AutomatedCommentState): AutomatedCommentState => ({
    ...state,
    delete: initialSectionWithoutData()
  })

};

export default (state: AutomatedCommentState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
