import iconColors from 'config/settings/colors';

export const KPIs = {
  turnover: {
    iconType: 'bc-icon-turnover',
    iconColor: iconColors.blueIconColor,
    valueColor: iconColors.defaultValueColor,
    name: 'Turnover',
    isSymbol: true
  },
  profit: {
    iconType: 'bc-icon-profit',
    iconColor: iconColors.greenIconColor,
    valueColor: iconColors.greenIconColor,
    name: 'Profit',
    isSymbol: true
  },
  profitNegative: {
    iconType: 'bc-icon-zoom-in',
    iconColor: iconColors.redIconColor,
    valueColor: iconColors.redIconColor,
    name: 'Profit',
    isSymbol: true
  },
  percent: {
    iconType: 'bc-icon-percent-of-profit',
    iconColor: iconColors.greenIconColor,
    valueColor: iconColors.greenIconColor,
    name: 'Percent of Profit',
    valueIcon: 'bc-icon-percent-of-profit'
  },
  percentNegative: {
    iconType: 'bc-icon-percent-of-profit',
    iconColor: iconColors.redIconColor,
    valueColor: iconColors.redIconColor,
    name: 'Percent of Profit'
  },
  totalBets: {
    iconType: 'bc-icon-total-bet',
    iconColor: iconColors.orangeIconColor,
    valueColor: iconColors.defaultValueColor,
    name: 'Total Bets'
  },
  averageBet: {
    iconType: 'bc-icon-currencies-48',
    iconColor: iconColors.pinkIconColor,
    valueColor: iconColors.defaultValueColor,
    name: 'Average Bet',
    isSymbol: true
  },
  averageCoefficient: {
    iconType: 'bc-icon-average-coefficient',
    iconColor: iconColors.purpleIconColor,
    valueColor: iconColors.defaultValueColor,
    name: 'Average Coefficient'
  },
  financial: {
    iconType: 'bc-icon-dot',
    valueColor: iconColors.defaultValueColor
  }
};

export const KPITypes = {
  turnover: 'turnover',
  profit: 'profit',
  profitNegative: 'profitNegative',
  percent: 'percent',
  percentNegative: 'percentNegative',
  totalBets: 'totalBets',
  averageBet: 'averageBet',
  averageCoefficient: 'averageCoefficient',
  financial: 'financial'
};

export const cardIconColor = {
  'Balance': iconColors.greenIconColor,
  'Unplayed Amount': iconColors.brownIconColor,
  'Profit and Loss': iconColors.red2IconColor,
  'Deposit AMount': iconColors.yellowIconColor,
  'Withdrawal Amount': iconColors.orangeIconColor,
  'Total Bets': iconColors.pinkIconColor,
  'Total Winnings': iconColors.yellow2IconColor,
  'Percent of Profit': iconColors.purpleIconColor,
  'Last Bet Date': iconColors.blue2IconColor,
  'Total Stakes': iconColors.purple2IconColor,
  'Total Unsettled Bets': iconColors.blue3IconColor,
  'Total Unsettled Stakes': iconColors.brown2IconColor,
  'Pre-Match Limit for Selection': iconColors.green2IconColor,
  'Live Limit of Selection': iconColors.green3IconColor,
  'Global Live Delay': iconColors.purple3IconColor,
  'Client Sportsbook Profiles': iconColors.pink2IconColor
};
