import type { Action } from 'lib/core/flow';

import SETTINGS_ACTIONS from '../types';

// Partners
export const fetchEnabledPartners = () => ({
  type: SETTINGS_ACTIONS.FETCH_ENABLED_PARTNERS
});
export const setFetchEnabledPartnersSuccess = ({ data }) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ENABLED_PARTNERS_SUCCESS,
  payload: { data }
});
export const setFetchEnabledPartnersFailure = (error) => ({
  type: SETTINGS_ACTIONS.SET_FETCH_ENABLED_PARTNERS_FAILURE,
  payload: { error }
});
export const clearEnabledPartnersState = () => ({
  type: SETTINGS_ACTIONS.CLEAR_ENABLED_PARTNERS_STATE
});

// Partner Alert Settings
export const fetchPartnerAlertSettings = ({ partnerId }): Action => ({
  type: SETTINGS_ACTIONS.FETCH_PARTNER_ALERT_SETTINGS,
  payload: { partnerId }
});
export const setFetchPartnerAlertSettingsSuccess = ({ data }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_PARTNER_ALERT_SETTINGS_SUCCESS,
  payload: { data }
});
export const setFetchPartnerAlertSettingsFailure = ({ error }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_PARTNER_ALERT_SETTINGS_FAILURE,
  payload: { error }
});
export const clearPartnerAlertSettingsState = (): Action => ({
  type: SETTINGS_ACTIONS.CLEAR_PARTNER_ALERT_SETTINGS_STATE
});

// Update Partner Alert Settings
export const updatePartnerAlertSettings = ({ id, enabled, amount, count, partnerId, isNewRule }): Action => ({
  type: SETTINGS_ACTIONS.UPDATE_PARTNER_ALERT_SETTINGS,
  payload: { id, enabled, amount, count, partnerId, isNewRule }
});
export const setUpdatePartnerAlertSettingsSuccess = ({ id, data }): Action => ({
  type: SETTINGS_ACTIONS.SET_UPDATE_PARTNER_ALERT_SETTINGS_SUCCESS,
  payload: { id, data }
});
export const setUpdatePartnerAlertSettingsFailure = ({ error, id }): Action => ({
  type: SETTINGS_ACTIONS.SET_UPDATE_PARTNER_ALERT_SETTINGS_FAILURE,
  payload: { error, id }
});

export const clearUpdatePartnerAlertSettings = (): Action => ({
  type: SETTINGS_ACTIONS.CLEAR_UPDATE_PARTNER_ALERT_SETTINGS
});
export const addPartnerAlertSettingsRule = ({ data }): Action => ({
  type: SETTINGS_ACTIONS.ADD_PARTNER_ALERT_SETTINGS_RULE,
  payload: { data }
});

// Delete Partner Alert Settings
export const deletePartnerAlertSettings = ({ id, partnerId }): Action => ({
  type: SETTINGS_ACTIONS.DELETE_PARTNER_ALERT_SETTINGS,
  payload: { id, partnerId }
});
export const setDeletePartnerAlertSettingsSuccess = ({ data }): Action => ({
  type: SETTINGS_ACTIONS.SET_DELETE_PARTNER_ALERT_SETTINGS_SUCCESS,
  payload: { data }
});
export const setDeletePartnerAlertSettingsFailure = ({ error }): Action => ({
  type: SETTINGS_ACTIONS.SET_DELETE_PARTNER_ALERT_SETTINGS_FAILURE,
  payload: { error }
});
