/**
 * @flow
 * */
import CacheService from 'core/services/storage/CacheService';

// @toDo Finalize function
export default (data: Object, headers: Object) => {
  const newToken = headers.get('Authorization');
  const existingToken = CacheService.getAuthToken();
  if (newToken && newToken !== existingToken) {
    CacheService.setAuthToken(newToken);
  }

  return data;
};
