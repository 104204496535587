export default {
  NETWORK_SERVICE: {
    ERRORS: {
      INVALID_REQUEST_PARAMS: 'Invalid request parameters.',
      INVALID_RESPONSE_DATA: 'Invalid Response Data',
      SERVER_IS_UNAVAILABLE: 'The server is unavailable.',
      RESPONSE_PARSING_ERROR: 'Unable to parse response.'
    },
    REQUEST_METHODS: {
      DELETE: 'delete',
      PATCH: 'patch',
      POST: 'post',
      PUT: 'put',
      GET: 'get'
    }
  },
  PRODUCTS: {
    AML: { path: 'aml' },
    RMT: { path: 'srmt' },
    FRMT: { path: 'frmt' },
    LCRMT: { path: 'lcrmt' },
    ADMIN: { path: 'admin' },
    CRMT: { path: 'crmt' }
  }
};

export const productsData = [
  { Id: 1, Name: 'SRMT' },
  { Id: 2, Name: 'AML & RG' },
  { Id: 4, Name: 'LCRMT' },
  { Id: 8, Name: 'CRMT' },
  { Id: 16, Name: 'FRMT' }
];
