import { deepClone } from 'lib/helpers/makeData';

export const bulkAlertsDataChange = (data, alertIds) => {
  const clonedData = deepClone(data);
  clonedData.forEach(item => {
    if (alertIds.includes(item.Id)) {
      // eslint-disable-next-line no-param-reassign
      item.AlertStatus = 'Read';
    }
  });
  return clonedData;
};
