/**
 * @flow
 * */
import { combineReducers } from 'redux';

import PartnerManagementController from 'modules/Admin/PartnerLicenseManagement/core/controllers/PartnerManagementControllers';
import AutomatedCommentsController from 'modules/Admin/AutomatedComments/core/controllers/AutomatedCommentsController';
import userManagementController from 'modules/Admin/UserManagement/core/controllers/UserManagementController';

import PARTNER_MANAGEMENT_ROUTES from './PartnerLicenseManagement/routes';
import AUTOMATED_COMMENTS_ROUTES from './AutomatedComments/routes';
import USER_MANAGEMENT_ROUTES from './UserManagement/routes';
import COMMON_ROUTES from './Common/routes';

import partnerManagement from './PartnerLicenseManagement/store/reducers';
import automatedComments from './AutomatedComments/store/reducers';
import userManagement from './UserManagement/store/reducers';

const getProjectState = (state: Object) => state.ADMIN;

const routes = [
  ...USER_MANAGEMENT_ROUTES,
  ...PARTNER_MANAGEMENT_ROUTES,
  ...COMMON_ROUTES,
  ...AUTOMATED_COMMENTS_ROUTES
];

const controllers = [
  userManagementController(),
  PartnerManagementController(),
  AutomatedCommentsController()
];

const reducers = combineReducers({
  ...userManagement,
  ...partnerManagement,
  ...automatedComments
});

export {
  getProjectState,
  controllers,
  reducers,
  routes
};
