/**
 * @flow
 * */
import createSymbol from 'lib/core/createSymbol';

const createSettingsSymbol = createSymbol('USER_PERMISSION');

export default {
  FETCH_PERMISSION: createSettingsSymbol('FETCH_PERMISSION'),
  SET_FETCH_PERMISSION_SUCCESS: createSettingsSymbol('SET_FETCH_PERMISSION_SUCCESS'),
  SET_FETCH_PERMISSION_FAILURE: createSettingsSymbol('SET_FETCH_PERMISSION_FAILURE')
};
