import PLAYER_ACTIONS from 'modules/LCRMT/Players/store/actions/player/types';
import type { Action } from 'lib/core/flow';

// Player Global Info
export const fetchLCRMTGlobalInfo = ({ playerId }) => ({
  type: PLAYER_ACTIONS.FETCH_GLOBAL_INFO_LCRMT,
  payload: { playerId }
});
export const setFetchGlobalInfoSuccess = ({ data }) => ({
  type: PLAYER_ACTIONS.SET_FETCH_GLOBAL_INFO_LCRMT_SUCCESS,
  payload: { data }
});
export const setFetchGlobalInfoFailure = ({ error, errorStatusCode }) => ({
  type: PLAYER_ACTIONS.SET_FETCH_GLOBAL_INFO_LCRMT_FAILURE,
  payload: { error, errorStatusCode }
});
export const clearGlobalInfo = () => ({
  type: PLAYER_ACTIONS.CLEAR_GLOBAL_INFO_LCRMT_STATE
});

// Hourly Activity
export const fetchLCRMTHourlyActivity = ({ playerId, dateFrom, dateTo }) => ({
  type: PLAYER_ACTIONS.FETCH_HOURLY_ACTIVITY_LCRMT,
  payload: { playerId, dateFrom, dateTo }
});
export const setFetchHourlyActivitySuccess = ({ data }) => ({
  type: PLAYER_ACTIONS.SET_FETCH_HOURLY_ACTIVITY_LCRMT_SUCCESS,
  payload: { data }
});
export const setFetchHourlyActivityFailure = (error) => ({
  type: PLAYER_ACTIONS.SET_FETCH_HOURLY_ACTIVITY_LCRMT_FAILURE,
  payload: { error }
});
export const clearHourlyActivity = () => ({
  type: PLAYER_ACTIONS.CLEAR_HOURLY_ACTIVITY_LCRMT_STATE
});

// Global KPIs
export const fetchGlobalKPIs = ({ playerId, isEURCurrencyEnabled }) => ({
  type: PLAYER_ACTIONS.FETCH_GLOBAL_KPIS_LCRMT,
  payload: { playerId, isEURCurrencyEnabled }
});
export const setFetchGlobalKPIsSuccess = ({ data }) => ({
  type: PLAYER_ACTIONS.SET_FETCH_GLOBAL_KPIS_LCRMT_SUCCESS,
  payload: { data }
});
export const setFetchGlobalKPIsFailure = (error) => ({
  type: PLAYER_ACTIONS.SET_FETCH_GLOBAL_KPIS_LCRMT_FAILURE,
  payload: { error }
});
export const clearGlobalKPIs = () => ({
  type: PLAYER_ACTIONS.CLEAR_GLOBAL_KPIS_LCRMT_STATE
});

// PlayerList
export const fetchPlayers = ({ appendData = false, count, start, passTotalCount, ...filters }): Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_PLAYERS,
  payload: { appendData, count, start, passTotalCount, ...filters }
});
export const setFetchPlayersSuccess = ({ data, totalCount, passTotalCount }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYERS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchPlayersAppendSuccess = ({ data, totalCount }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYERS_APPEND_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchPlayersFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYERS_FAILURE,
  payload: { error }
});
export const clearPlayersState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_PLAYERS_STATE
});

// Alerts
export const fetchPlayersAlerts = ({ playerId }): Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_PLAYERS_ALERTS,
  payload: { playerId }
});
export const setFetchPlayersAlertsSuccess = ({ data }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYERS_ALERTS_SUCCESS,
  payload: { data }
});
export const setFetchPlayersAlertsFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_PLAYERS_ALERTS_FAILURE,
  payload: { error }
});
export const playersAlertsDataChangeAfterReview = ({ tabKey, statuses, selectedRows, actionType, User, Modified, Assessment, Comment }: Object):Action => ({
  type: PLAYER_ACTIONS.SET_LCRMT_PLAYERS_ALERTS_DATA_CHANGE_AFTER_REVIEW,
  payload: { tabKey, statuses, selectedRows, actionType, User, Modified, Assessment, Comment }
});
export const clearPlayersAlertsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_PLAYERS_ALERTS_STATE
});

// Live Games Restrictions
export const fetchLiveGamesRestrictions = ({ playerId, partnerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_LIVE_GAMES_RESTRICTIONS,
  payload: { playerId, partnerId }
});
export const setFetchLiveGamesRestrictionsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LIVE_GAMES_RESTRICTIONS_SUCCESS,
  payload: { data }
});
export const setFetchLiveGamesRestrictionsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LIVE_GAMES_RESTRICTIONS_FAILURE,
  payload: { error }
});
export const clearLiveGamesRestrictionsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_LIVE_GAMES_RESTRICTIONS_STATE
});

// add Live Games Restrictions
export const fetchAddLiveGamesRestrictions = ({ data, type }): Action => ({
  type: PLAYER_ACTIONS.FETCH_ADD_LIVE_GAMES_RESTRICTIONS,
  payload: { data, type }
});
export const setFetchAddLiveGamesRestrictionsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_SUCCESS,
  payload: { data }
});
export const setFetchAddLiveGamesRestrictionsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_FAILURE,
  payload: { error }
});
export const clearAddLiveGamesRestrictionsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_ADD_LIVE_GAMES_RESTRICTIONS_STATE
});

// delete Live Games Restrictions
export const fetchDeleteLiveGamesRestrictions = (id : Array): Action => ({
  type: PLAYER_ACTIONS.FETCH_DELETE_LIVE_GAMES_RESTRICTIONS,
  payload: id
});
export const setFetchDeleteLiveGamesRestrictionsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_SUCCESS,
  payload: { data }
});
export const setFetchDeleteLiveGamesRestrictionsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_FAILURE,
  payload: { error }
});
export const clearDeleteLiveGamesRestrictionsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_DELETE_LIVE_GAMES_RESTRICTIONS_STATE
});

// Tables By Category
export const fetchTablesByCategory = ({ categoryId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_TABLES_BY_CATEGORY,
  payload: { categoryId }
});
export const setFetchTablesByCategorySuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_TABLES_BY_CATEGORY_SUCCESS,
  payload: { data }
});
export const setFetchTablesByCategoryFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_TABLES_BY_CATEGORY_FAILURE,
  payload: { error }
});
export const clearTablesByCategoryState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_TABLES_BY_CATEGORY_STATE
});

// Game Categories
export const fetchGameCategories = ({ partnerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_GAME_CATEGORIES,
  payload: { partnerId }
});
export const setFetchGameCategoriesSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_GAME_CATEGORIES_SUCCESS,
  payload: { data }
});

export const setFetchGameCategoriesFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_GAME_CATEGORIES_FAILURE,
  payload: { error }
});
export const clearGameCategoriesState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_GAME_CATEGORIES_STATE
});

// cancel call
export const cancelPlayerSdkCall = ({ name }): Action => ({
  type: PLAYER_ACTIONS.CANCEL_LCRMT_PLAYER_SDK_CALL,
  payload: { name }
});

export * from './generalActions';
