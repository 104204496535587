import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createFilterInternalSymbol = createInternalSymbol('EXPORT');
const createFilterExternalSymbol = createSymbol('EXPORT');

export default {
  FETCH_EXPORT_DATA: createFilterExternalSymbol('FETCH_EXPORT_DATA'),
  SET_FETCH_EXPORT_DATA_SUCCESS: createFilterExternalSymbol('SET_FETCH_EXPORT_DATA_SUCCESS'),
  SET_FETCH_EXPORT_DATA_FAILURE: createFilterExternalSymbol('SET_FETCH_EXPORT_DATA_FAILURE'),
  CLEAR_EXPORT_STATE: createFilterInternalSymbol('CLEAR_EXPORT_STATE'),
  CANCEL_EXPORT_CALL: createFilterInternalSymbol('CANCEL_EXPORT_CALL')
};
