import type { MinimalReducer } from 'lib/core/flow';

export type CategoriesDataModel = {|
    data: Array<number>,
    name: string
|};

export type CategoriesResponseModel = {|
    categories: Array<string>,
    data: Array<CategoriesDataModel>
|};

export type GameStyleHistoryDataModel = {|
    data: Array<number>,
    name: string
|};

export type GameStyleHistoryResponseModel = {|
    categories: Array<string>,
    data: Array<GameStyleHistoryDataModel>
|};

export type GameStyleTendencyDataModel = {|
    data: Array<number>,
    name: string
|};

export type GameStyleTendencyResponseModel = {|
    categories: Array<string>,
    data: Array<GameStyleTendencyDataModel>
|};

export type AnalyticChartModel = {
    // eslint-disable-next-line no-use-before-define
    [AnalyticChart.additional]: {...MinimalReducer, data: any},
    // eslint-disable-next-line no-use-before-define
    [AnalyticChart.financial]: {...MinimalReducer, data: any},
    // eslint-disable-next-line no-use-before-define
    [AnalyticChart.gameStyle]: {...MinimalReducer, data: any}
};

export type AlertChartModel = {
    // eslint-disable-next-line no-use-before-define
    [AlertChart.linkedAccount]: {...MinimalReducer, data: any},
    // eslint-disable-next-line no-use-before-define
    [AlertChart.multiAccount]: {...MinimalReducer, data: any}
};

export type DashboardState = {|
    analyticKpis: {|
        ...MinimalReducer,
        data: ?any,
    |},
    dashboardKpis: {|
        ...MinimalReducer,
        data: ?any,
    |},
    analyticChart: AnalyticChartModel,
    alertChartMulti: AlertChartModel,
    alertChartLinked: AlertChartModel,
    categories: {|
        ...MinimalReducer,
        data: ?CategoriesResponseModel,
    |},
    gameStyleHistory: {|
        ...MinimalReducer,
        data: ?GameStyleHistoryResponseModel,
    |},
    gameStyleTendency: {|
        ...MinimalReducer,
        data: ?GameStyleTendencyResponseModel,
    |}
|};
export const AnalyticChart = {
  additional: 3,
  financial: 1,
  gameStyle: 2
};

export const AlertChart = {
  multiAccount: 1,
  linkedAccount: 2
};

export type AnalyticChartType = AnalyticChart.additional | AnalyticChart.financial | AnalyticChart.gameStyle;

export type AlertChartType = AlertChart.multiAccount | AlertChart.linkedAccount;
