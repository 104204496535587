/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import ACTIONS from 'modules/Admin/UserManagement/store/actions/userManagement/types';
import type { UserManagementState } from 'modules/Admin/UserManagement/types';

import { initialSectionWithCountState } from 'store/reducers/initialStates';
import { initialSectionState, initialOnlyDataState, initialSectionWithoutData } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

const initialState = (): UserManagementState => ({
  templates: initialSectionState(),
  allResources: initialSectionState(),
  roleResources: initialSectionState(),
  templateResources: initialSectionState(),
  userGroupResources: initialSectionState(),
  addResources: initialSectionState(),
  users: initialSectionWithCountState(),
  roles: initialSectionWithCountState(),
  assignInfo: initialOnlyDataState(),
  operatingPartnersUpdate: initialSectionWithoutData(),
  usersPartner: initialSectionState(),
  deactivation: initialSectionWithoutData()
});

const actionManager = {
  // templates
  [ACTIONS.FETCH_ROLE_TEMPLATES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    templates: {
      ...state.templates,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_ROLE_TEMPLATES_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    templates: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_ROLE_TEMPLATES_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    templates: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_ROLE_TEMPLATES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    templates: initialSectionState()
  }),
  // all resources
  [ACTIONS.FETCH_ALL_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    allResources: {
      ...state.allResources,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_ALL_RESOURCES_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    allResources: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_ALL_RESOURCES_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    allResources: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_ALL_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    allResources: initialSectionState()
  }),
  // role resources
  [ACTIONS.FETCH_ROLE_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    roleResources: {
      ...state.roleResources,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_ROLE_RESOURCES_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    roleResources: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_ROLE_RESOURCES_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    roleResources: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_ROLE_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    roleResources: initialSectionState()
  }),

  // template resources
  [ACTIONS.FETCH_TEMPLATE_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    templateResources: {
      ...state.templateResources,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_TEMPLATE_RESOURCES_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    templateResources: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_TEMPLATE_RESOURCES_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    templateResources: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_TEMPLATE_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    templateResources: initialSectionState()
  }),
  // user group resources
  [ACTIONS.FETCH_USER_GROUP_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    userGroupResources: {
      ...state.userGroupResources,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_USER_GROUP_RESOURCES_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    userGroupResources: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_USER_GROUP_RESOURCES_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    userGroupResources: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_USER_GROUP_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    userGroupResources: initialSectionState()
  }),
  // add resources
  [ACTIONS.ADD_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    addResources: {
      ...state.addResources,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_ADD_RESOURCES_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    addResources: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_ADD_RESOURCES_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    addResources: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_ADD_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    addResources: initialSectionState()
  }),
  // Users
  [ACTIONS.FETCH_USERS]: (state: UserManagementState): UserManagementState => ({
    ...state,
    users: {
      ...state.users,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [ACTIONS.SET_FETCH_USERS_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    users: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.users.totalCount,
      error: null
    }
  }),
  [ACTIONS.SET_FETCH_USERS_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    users: {
      ...state.users,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [ACTIONS.CLEAR_USERS_STATE]: (state: UserManagementState): UserManagementState => ({
    ...state,
    users: initialSectionWithCountState()
  }),
  // Operating Partner Update
  [ACTIONS.FETCH_OPERATING_PARTNER_UPDATE]: (state: UserManagementState): UserManagementState => ({
    ...state,
    operatingPartnersUpdate: {
      ...state.operatingPartnersUpdate,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [ACTIONS.SET_FETCH_OPERATING_PARTNER_UPDATE_SUCCESS]: (state: UserManagementState): UserManagementState => ({
    ...state,
    operatingPartnersUpdate: {
      ...state.operatingPartnersUpdate,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [ACTIONS.SET_FETCH_OPERATING_PARTNER_UPDATE_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    operatingPartnersUpdate: {
      ...state.operatingPartnersUpdate,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [ACTIONS.CLEAR_OPERATING_PARTNER_UPDATE]: (state: UserManagementState): UserManagementState => ({
    ...state,
    operatingPartnersUpdate: initialSectionWithoutData()
  }),

  // Assign Info
  [ACTIONS.SET_ASSIGN_INFO]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    assignInfo: {
      data: payload.data
    }
  }),
  [ACTIONS.CLEAR_ASSIGN_INFO]: (state: UserManagementState): UserManagementState => ({
    ...state,
    assignInfo: initialOnlyDataState()
  }),
  // Role Permission
  [ACTIONS.FETCH_ROLE_PERMISSION]: (state: UserManagementState): UserManagementState => ({
    ...state,
    roles: {
      ...state.roles,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [ACTIONS.SET_FETCH_ROLE_PERMISSION_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    roles: {
      ...state.roles,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: payload.totalCount,
      error: null
    }
  }),
  [ACTIONS.SET_FETCH_ROLE_PERMISSION_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    roles: {
      ...state.roles,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [ACTIONS.CLEAR_ROLE_PERMISSION_STATE]: (state: UserManagementState): UserManagementState => ({
    ...state,
    roles: initialSectionWithCountState()
  }),

  // additional resources
  [ACTIONS.FETCH_ADDITIONAL_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    additionalResources: {
      ...state.additionalResources,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_ADDITIONAL_RESOURCES_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    additionalResources: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_ADDITIONAL_RESOURCES_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    additionalResources: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_ADDITIONAL_RESOURCES]: (state: UserManagementState): UserManagementState => ({
    ...state,
    additionalResources: initialSectionState()
  }),

  // users partner
  [ACTIONS.FETCH_USERS_PARTNER]: (state: UserManagementState): UserManagementState => ({
    ...state,
    usersPartner: {
      ...state.usersPartner,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_USERS_PARTNER_SUCCESS]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    usersPartner: {
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [ACTIONS.SET_USERS_PARTNER_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    usersPartner: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [ACTIONS.CLEAR_USERS_PARTNER]: (state: UserManagementState): UserManagementState => ({
    ...state,
    usersPartner: initialSectionState()
  }),

  // deactivate user
  [ACTIONS.FETCH_DEACTIVATE_USER]: (state: UserManagementState): UserManagementState => ({
    ...state,
    deactivation: {
      ...state.deactivation,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [ACTIONS.SET_DEACTIVATE_USER_SUCCESS]: (state: UserManagementState): UserManagementState => ({
    ...state,
    deactivation: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [ACTIONS.SET_DEACTIVATE_USER_FAILURE]: (state: UserManagementState, payload): UserManagementState => ({
    ...state,
    deactivation: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [ACTIONS.CLEAR_DEACTIVATE_USER]: (state: UserManagementState): UserManagementState => ({
    ...state,
    deactivation: initialSectionWithoutData()
  })
};

export const initialUserManagementReducerState = initialState();

export default (state: UserManagementState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
