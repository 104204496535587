/**
 * @flow
 * */
/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';

const AlertPlayersBetsPage = React.lazy(() => import('modules/SRMT/Players/views/pages/AlertPlayersBetsPage'));
const RedirectTo = React.lazy(() => import('./views/pages/RedirectTo'));
const NotFoundError = React.lazy(() => import('./views/pages/NotFound'));
const Backoffice = React.lazy(() => import('./views/pages/Backoffice'));
const Forbidden = React.lazy(() => import('./views/pages/forbidden'));
const HowTo = React.lazy(() => import('./views/pages/HowTo'));
const Login = React.lazy(() => import('./views/pages/Login'));
const Home = React.lazy(() => import('./views/pages/Home'));
const DbMonitoring = React.lazy(() => import('./views/pages/DbMonitoring'));
const CasinoAnomalies = React.lazy(() => import('./views/pages/CasinoAnomalies'));
const CustomAlertSettings = React.lazy(() => import('./views/pages/CustomAlertSettings'));
const CustomAlertGameSettings = React.lazy(() => import('./views/pages/CustomAlertGameSettings'));
const CustomAlertStatistics = React.lazy(() => import('./views/pages/CustomAlertStatistics'));
const CustomAlert = React.lazy(() => import('./views/pages/CustomAlert'));
const CustomGameAlert = React.lazy(() => import('./views/pages/CustomGameAlert'));
const AmlRiskStatus = React.lazy(() => import('./views/pages/AmlRiskStatus'));
const Auth = React.lazy(() => import('./views/pages/Auth'));
const JWT = React.lazy(() => import('./views/pages/JWT'));

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const STATIC_ROUTES: Array<Route> = [
  { path: '/Backoffice/Authenticate', name: 'Backoffice', exact: false, page: Backoffice },
  { path: '/404', name: 'notfound', exact: true, page: NotFoundError },
  { path: '/403', name: 'forbidden', exact: true, page: Forbidden },
  { path: '/login', name: 'login', exact: false, page: Login, fullScreen: true },
  { path: '/auth', name: 'auth', exact: false, page: Auth },
  { path: '/JWT', name: 'JWT', exact: false, page: JWT }
];

const DASHBOARD_ROUTES: Array<Route> = [
  { path: '/', name: 'home', exact: true, page: Home },
  { path: '/db-monitoring', name: 'db', exact: true, page: DbMonitoring },
  { path: '/anomalies', name: 'db', exact: true, page: CasinoAnomalies },
  { path: '/detect-risk', name: 'aml', exact: true, page: AmlRiskStatus },
  { path: '/how-to', name: 'howto', exact: true, page: HowTo },
  { path: '/player/bets', name: 'playerBets', exact: true, page: AlertPlayersBetsPage },
  { path: '/redirect-to', name: 'RedirectTo', exact: true, page: RedirectTo },
  { path: '/dynamic-alert/settings', name: 'db', exact: true, page: CustomAlertSettings },
  { path: '/dynamic-game-alert/settings', name: 'db', exact: true, page: CustomAlertGameSettings },
  { path: '/dynamic-alert/statistics', name: 'db', exact: true, page: CustomAlertStatistics },
  { path: '/dynamic-alert', name: 'Custom Alert', exact: true, page: CustomAlert },
  { path: '/dynamic-game-alert', name: 'Custom Game Alert', exact: true, page: CustomGameAlert }
];

export {
  STATIC_ROUTES,
  DASHBOARD_ROUTES
};
