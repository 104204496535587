/**
 * @flow
 * */
import { BackProjectEnum } from 'modules/Admin/PartnerLicenseManagement/types';
import NetworkService from 'core/services/network/NetworkService';
import config from 'config';

import type { ExportParams } from './types';
import BaseSDK from '../BaseSDK';

/**
 * @class ExportSDK
 */
class ExportSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    _amlApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
      this._amlApiService = new NetworkService(config.amlApi);
    }

    /**
   * fetchExportData
   * @param {any} params -
   * @returns {Promise<any>} -
   */
    async fetchExportData(params:ExportParams):Promise<{ Data:string } > {
      const { fileType, filters, projectType, exportUrl } = params;

      const { body } = await (BackProjectEnum.aml === projectType ? this._amlApiService : this._rmtApiService).makeAPIGetRequest(
        exportUrl,
        { query_params: { ...filters, fileType }, abortController: this.setAbortController('fetchExportData') }
      );
      return body.Data;
    }

    /**
   * fetchExportData
   * @param {any} params -
   * @returns {Promise<any>} -
   */
    async fetchPostExportData(params:ExportParams):Promise<{ Data:string } > {
      const { fileType, filters, projectType, exportUrl } = params;

      const { body } = await (BackProjectEnum.aml === projectType ? this._amlApiService : this._rmtApiService).makeAPIPostRequest(
        exportUrl,
        { body: { ...filters, fileType }, abortController: this.setAbortController('fetchPostExportData') }
      );
      return body.Data;
    }

    /**
   * cancelExportSDKCalls
   * @returns {void} -
   */
    cancelExportSDKCalls(): void {
      this.abortCall('fetchExportData');
    }
}

export default new ExportSDK();
