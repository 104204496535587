import type { MinimalReducer } from 'lib/core/flow';
import { AmlAlertsFactory } from 'modules/AML/utils/amlAlertsFactory';
import type { GlobalKPIsModel } from 'types';

type Partners = {
  Id: number,
  Name: string
}
export type BoxesState = {
  Partners: Array<Partners>,
  Type: string
}

export type ConfigsState = {
  ApplyOnMultiaccountsEnabled: boolean,
  LicenseRulesEnabled: boolean,
  MultiaccountDetectionEnabled: boolean,
  MultipleRulesEnabled:boolean,
  OnePartnerEnabled:boolean,
  RiskRuleDetectionEnabled: boolean,
  ScoreCalculationEnabled: boolean,
  SingleRulesEnabled: boolean,
  SuspiciousAlertsEnabled: boolean,
  AMlAlertEnabled: boolean,
  RGAlertEnabled: boolean
}

export type TransactionsState = {
  DocumentId: number,
  Amount: number,
  PartnerId: number,
  PlayerId: number,
  PaymentSystemName: string,
  TypeId: string,
  PartnerName: string,
  DateTime: string,
  DateTimeOffset: string,
  Country: string,
  Currency: string,
  Note: string,
  PlayerStatus: string,
  ExcludedTypes: string
}

export type DashboardParams = {
  partnerIds: Array,
  dateFrom: string,
  dateTo: string
}

export type DashboardState = {
  CorrectionDown: number,
  CorrectionUp: number,
  Date: string,
  Deposits: number,
  PlayersDeposit: number,
  PlayersRegistered: number,
  PlayersWithdrawals: number,
  Profit: number,
  Withdrawals: number
}

type ScoreRulesPlayer = {
  Name: string,
  Score: number,
  ScoreRuleId: number
}

export type Rule = {
  IsCountable: boolean,
  OrderNumber: number,
  ScoreCount: number,
  Enabled: boolean,
  Details: string,
  Score: number,
  Type: string,
  Id: number
}
export type ScoreRule ={
  Total: number,
  Category: string,
  Rules: Array<Rule>
}

export type ScoreRuleSAction ={
  Category: string,
  Maximal: number,
  Total: number
}

export type PrayerState = {
  Id: number,
  Modified: string,
  Name: string,
  PartnerId: number,
  PartnerName: string,
  RegistrationDate: string,
  Score: number,
  ScoreRiskCategory: string,
  ScoreRules: Array<ScoreRulesPlayer>
}

export type PlayerAlertsState = {
  Id: number,
  Created: string,
  RuleType: string,
  ScoreRiskCategory: string,
  AlertType: string,
  AlertStatus: string,
}
export type AnalyticDataState = {
  DepositAmount: number,
  WithdrawalAmount: number
}

export type AnalyticTypes = {|
  dateFrom: string,
  dateTo: string,
  playerId: number,
  type: string
|};

type Restriction = {
  CanBet?: boolean,
  CanDeposit?: boolean,
  CanLogin?: boolean,
  CanWithdraw?: boolean
}

export type PrayerBlockHistoryState = {
  BlockAction: string,
  Restriction: Restriction,
  Date: string,
  FirstName: string,
  LastName: string,
  Partner: string,
  PartnerId: number,
  PlayerId: number
}

export type PrayerScoreState = {
  Score: number,
  ActualScore: number,
  ScoreCategory: string,
  ActualScoreCategory: string,
  Date: string,
  FirstName: string,
  LastName: string,
  PartnerId: number,
  PartnerName: string,
  PlayerId: number
}
export type AmlRuleScoreState = {
  ActualScoreCategory: string,
  ActualScore: number,
  PartnerName: string,
  PartnerId: number,
  Modified: string,
  PlayerId: number,
  Id: number,
}

export type AMLPlayerMultiAccount = {
  PlayerId: number,
  TriggerId: number,
  TriggerName: string,
  PlayerName: string,
  PartnerId: number,
  PartnerName: string,
  RegistrationDate : string,
}

export type RuleScoreHistory = {
  MainAlertId: number,
  Date: string,
  Details: string,
  Operation: string,
  RuleName: string,
  RuleScore: number,
  SettlementType: string,
  Source: string,
  TotalScore: number,
  UserName: string
}

const amlAlertsState = AmlAlertsFactory.createInitialAmlAlertsMonitor();

export type AMLState = {
  boxes?: {|
    ...MinimalReducer,
    data: ?BoxesState,
  |},
  configs?: {|
    ...MinimalReducer,
    data: ?ConfigsState,
  |},
  dashboardCharts?: {|
    ...MinimalReducer,
    data: ?Array<DashboardState>,
  |},
  transactions?: {|
    ...MinimalReducer,
    data: ?Array<TransactionsState>,
    totalCount: number
  |},
  players?: {|
    ...MinimalReducer,
    data: ?Array<PrayerState>,
    totalCount: number
  |},
  playerMultiAccounts?: {|
    ...MinimalReducer,
    data: ?Array<AMLPlayerMultiAccount>
  |},
  playerScoreRules?: {|
    ...MinimalReducer,
    data: ?Array<ScoreRule>,
  |},
  playerScoreRulesAction?: {|
    ...MinimalReducer,
    data: ?ScoreRuleSAction,
  |},
  playerBlockHistoryReport?: {|
    ...MinimalReducer,
    data: ?Array<PrayerBlockHistoryState>,
    totalCount: number
  |},
  playerScoreReport?: {|
    ...MinimalReducer,
    data: ?Array<PrayerScoreState>,
    totalCount: number
  |},
  amlRuleScoreReport?: {|
    ...MinimalReducer,
    data: ?Array<AmlRuleScoreState>,
    totalCount: number
  |},
  playerRuleScoreHistory?: {|
    ...MinimalReducer,
    data: ?Array<RuleScoreHistory>,
    totalCount: number
  |},
  playerAlerts?: {|
    ...MinimalReducer,
    data: ?Array<PlayerAlertsState>,
    totalCount: number
  |},
  playerAlertsBulkReview?: {|
    ...MinimalReducer,
  |},
  transactionActivity? : {|
    ...MinimalReducer,
        data: ? Array<AnalyticDataState>
  |},
  bettingHourlyActivity? : {|
    ...MinimalReducer,
        data: ? Array<AnalyticDataState>
  |},
  setMonitoringAlert: {|
    ...MinimalReducer,
    data: ?GlobalKPIsModel
  |},
  ...amlAlertsState
}
