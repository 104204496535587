/**
 * @flow
 * */
import { initialSectionWithCountState } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

import AM_ACTION_TYPES from 'modules/AML/store/actions/types';
import { AMLState } from 'modules/AML/types';

const initialState = (): AMLState => ({
  playerBlockHistoryReport: initialSectionWithCountState(),
  playerScoreReport: initialSectionWithCountState()
});

const actionManager = {
  /** Reports* */
  // player Score Report
  [AM_ACTION_TYPES.FETCH_PLAYER_SCORE_REPORT]: (state: AMLState) => ({
    ...state,
    playerScoreReport: {
      ...state.playerScoreReport,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_REPORT_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerScoreReport: {
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playerScoreReport.totalCount,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_SCORE_REPORT_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerScoreReport: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_PLAYER_SCORE_REPORT_STATE]: (state: AMLState) => ({
    ...state,
    playerScoreReport: initialSectionWithCountState()
  }),

  // player Block History Report
  [AM_ACTION_TYPES.FETCH_PLAYER_BLOCK_HISTORY_REPORT]: (state: AMLState) => ({
    ...state,
    playerBlockHistoryReport: {
      ...state.playerBlockHistoryReport,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    playerBlockHistoryReport: {
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playerBlockHistoryReport.totalCount,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    playerBlockHistoryReport: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_PLAYER_BLOCK_HISTORY_REPORT_STATE]: (state: AMLState) => ({
    ...state,
    playerBlockHistoryReport: initialSectionWithCountState()
  }),

  // Aml Rule Score Report
  [AM_ACTION_TYPES.FETCH_AML_RULE_SCORE_REPORT]: (state: AMLState) => ({
    ...state,
    amlRuleScoreReport: {
      ...state.amlRuleScoreReport,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_RULE_SCORE_REPORT_SUCCESS]: (state: AMLState, payload) => ({
    ...state,
    amlRuleScoreReport: {
      error: null,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.amlRuleScoreReport.totalCount,
      status: ACTION_STATUSES.SUCCEED
    }
  }),
  [AM_ACTION_TYPES.SET_FETCH_AML_RULE_SCORE_REPORT_FAILURE]: (state: AMLState, payload) => ({
    ...state,
    amlRuleScoreReport: {
      data: null,
      error: payload.error,
      status: ACTION_STATUSES.FAILED
    }
  }),
  [AM_ACTION_TYPES.CLEAR_AML_RULE_SCORE_REPORT_STATE]: (state: AMLState) => ({
    ...state,
    amlRuleScoreReport: initialSectionWithCountState()
  })
};

export default (state: AMLState = initialState(), action: Action) => {
  if (!actionManager[action.type]) return state;

  return actionManager[action.type](state, action.payload);
};
