import { deepClone } from 'lib/helpers/makeData';
import { inputConfig } from './core-ui-strings';

export const getRangeOptions = (today, yesterday, last7days, last30days, thisMonth, withTime) => [
  {
    name: 'Today',
    start: withTime ? deepClone(today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) : today,
    end: withTime ? deepClone(today.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })) : today
  },
  {
    name: 'Yesterday',
    start: withTime ? deepClone(yesterday.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) : yesterday.startDate,
    end: withTime ? deepClone(yesterday.endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })) : yesterday.endDate
  },
  {
    name: 'Last 7 days',
    start: withTime ? deepClone(last7days.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) : last7days.startDate,
    end: withTime ? deepClone(last7days.endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })) : last7days.endDate
  },
  {
    name: 'Last 30 days',
    start: withTime ? deepClone(last30days.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) : last30days.startDate,
    end: withTime ? deepClone(last30days.endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })) : last30days.endDate
  },
  {
    name: 'This Month',
    start: withTime ? deepClone(thisMonth.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) : thisMonth.startDate,
    end: withTime ? deepClone(thisMonth.endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })) : thisMonth.endDate
  }
];

export const getCustomOptions = (today) => (
  {
    label: 'Today',
    date: today
  }
);

export const amlConfigs = {
  LicenseRulesEnabled: 'LicenseRulesEnabled',
  SingleRulesEnabled: 'SingleRulesEnabled',
  ScoreCalculationEnabled: 'ScoreCalculationEnabled',
  RiskRuleDetectionEnabled: 'RiskRuleDetectionEnabled',
  ApplyOnMultiaccountsEnabled: 'ApplyOnMultiaccountsEnabled',
  SuspiciousAlertsEnabled: 'SuspiciousAlertsEnabled',
  MonitoringAlertsEnabled: 'MonitoringAlertsEnabled',
  MultiaccountDetectionEnabled: 'MultiaccountDetectionEnabled',
  MultiAccountAlertsEnabled: 'MultiAccountAlertsEnabled',
  MultipleRulesEnabled: 'MultipleRulesEnabled',
  OnePartnerEnabled: 'OnePartnerEnabled',
  AMlAlertEnabled: 'AMlAlertEnabled',
  RGAlertEnabled: 'RGAlertEnabled',
  AmlRuleScoreEnabled: 'AmlRuleScoreEnabled'
};

export const defaultDropdownConfigs = {
  labelAppearance: inputConfig.labelAppearance.swap,
  flexibility: inputConfig.flexibility.fullWidth,
  inputSize: inputConfig.size.big,
  size: inputConfig.size.big,
  clearable: true
};

export const defaultInputConfigs = {
  labelAppearance: inputConfig.labelAppearance.swap,
  flexibility: inputConfig.flexibility.fullWidth,
  inputSize: inputConfig.size.big,
  size: inputConfig.size.big,
  showErrorIcon: false,
  canClear: false
};

export const defaultDropdownConfigsDefault = {
  labelAppearance: inputConfig.labelAppearance.swap,
  flexibility: inputConfig.flexibility.fullWidth,
  inputSize: inputConfig.size.default,
  size: inputConfig.size.default,
  clearable: true
};

export const defaultInputConfigsDefault = {
  labelAppearance: inputConfig.labelAppearance.swap,
  flexibility: inputConfig.flexibility.fullWidth,
  inputSize: inputConfig.size.default,
  size: inputConfig.size.default,
  showErrorIcon: false,
  canClear: false
};
