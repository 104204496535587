/**
 * @flow
 * */
import { useCallback, useEffect } from 'react';
import Toaster from '@geneui/components/Toaster';

type Props = {
  isFailed?: boolean,
  isSucceed?: boolean,
  errorMessage?: string,
  successMessage?: any
}

/**
 * useMessageHandler
 * @param {boolean} isFailed -  Failed error condition
 * @param {boolean} isSucceed - Succeed condition
 * @param {string} errorMessage - Error message
 * @param {any} successMessage - Success message
 * @returns {undefined}- undefined
 */
const useMessageHandler = ({ isFailed, isSucceed, errorMessage, successMessage }: Props) => {
  const showNotification = useCallback(() => {
    if (isFailed) {
      Toaster.error({
        title: 'Warning',
        toasterPosition: 'top-right',
        duration: 3000,
        message: errorMessage
      });
    }
    if (isSucceed) {
      Toaster.success({
        title: 'Success',
        toasterPosition: 'top-right',
        duration: 3000,
        message: successMessage
      });
    }
  }, [isFailed, isSucceed, errorMessage, successMessage]);

  useEffect(showNotification, [isFailed, isSucceed, errorMessage, successMessage]);
};

export default useMessageHandler;
