/**
 * @flow
 * */
import type { Action } from 'lib/core/flow/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import USER_SETTINGS_ACTION_TYPES from 'store/actions/userSettings/types';

const editInit = {
  status: null,
  error: null
};
const actionManager = {
  // set user Settings
  [USER_SETTINGS_ACTION_TYPES.SET_USER_SETTINGS]: (state, payload) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      edit: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [USER_SETTINGS_ACTION_TYPES.SET_USER_SETTINGS_SUCCESS]: (state, payload) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      data: payload.data,
      edit: {
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [USER_SETTINGS_ACTION_TYPES.UPDATE_USER_SETTINGS_SUCCESS]: (state, payload) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      data: payload.data
    }
  }),
  [USER_SETTINGS_ACTION_TYPES.SET_USER_SETTINGS_FAILURE]: (state, payload) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      edit: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [USER_SETTINGS_ACTION_TYPES.CLEAR_USER_SETTINGS_STATE]: (state, payload) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      edit: { ...editInit }
    }
  }),

  // get user Settings
  [USER_SETTINGS_ACTION_TYPES.FETCH_USER_SETTINGS]: (state, payload) => {
    const newState = { ...state };
    payload.name.forEach(item => {
      newState[item] = {
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null,
        edit: { ...editInit }
      };
    });
    return newState;
  },
  [USER_SETTINGS_ACTION_TYPES.SET_FETCH_USER_SETTINGS_SUCCESS]: (state, payload) => ({
    ...state,
    [payload.name]: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      edit: { ...editInit }
    }
  }),
  [USER_SETTINGS_ACTION_TYPES.SET_FETCH_USER_SETTINGS_FAILURE]: (state, payload) => ({
    ...state,
    [payload.name]: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      edit: { ...editInit }
    }
  }),
  [USER_SETTINGS_ACTION_TYPES.CHANGE_USER_SETTINGS_FAILURE]: (state, payload) => ({
    ...state,
    [payload.name]: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      edit: { ...editInit }
    }
  }),
  [USER_SETTINGS_ACTION_TYPES.SET_TABLE_SETTINGS_CACHE]: (state, payload) => payload.data
};

export default (state:{} = {}, action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
