import { routes as LCRMT_ROUTES } from './LCRMT';
import { routes as FRMT_ROUTES } from './FRMT';
import { routes as DASHBOARD_ROUTES } from './Dashboard';
import { routes as SRMT_ROUTES } from './SRMT';
import { routes as AML_ROUTES } from './AML';
import { routes as ADMIN_ROUTES } from './Admin';
import { routes as CRMT_ROUTES } from './CRMT';

// forClIImport

export default [
  // forClIRouting
  ...DASHBOARD_ROUTES,
  ...AML_ROUTES,
  ...SRMT_ROUTES,
  ...LCRMT_ROUTES,
  ...FRMT_ROUTES,
  ...ADMIN_ROUTES,
  ...CRMT_ROUTES
];
