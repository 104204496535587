import type { CategorySettingsState } from 'modules/SRMT/Settings/types';

import partnerAlertReducer from './partnerAlertReducer';

const initialState = (): CategorySettingsState => ({
  ...partnerAlertReducer.initialState
});

const actionManager = {
  ...partnerAlertReducer.actionManager
};

export default {
  actionManager,
  initialState
};
