import createSymbol from 'lib/core/createSymbol';

const createAuthSymbol = createSymbol('LANDING');

export default {
  FETCH_DEMO: createAuthSymbol('FETCH_DEMO'),
  SET_DEMO_FAILED: createAuthSymbol('SET_DEMO_FAILED'),
  SET_DEMO_SUCCEED: createAuthSymbol('SET_DEMO_SUCCEED'),
  CLEAR_DEMO: createAuthSymbol('CLEAR_DEMO')
};
