import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createDashboardInternalSymbol = createInternalSymbol('DASHBOARD');
const createDashboardSymbol = createSymbol('DASHBOARD');

export default {
  // Categories Chart
  FETCH_CATEGORIES_CHART: createDashboardSymbol('FETCH_CATEGORIES_CHART'),
  SET_FETCH_CATEGORIES_CHART_SUCCESS: createDashboardSymbol('SET_FETCH_CATEGORIES_CHART_SUCCESS'),
  SET_FETCH_CATEGORIES_CHART_FAILURE: createDashboardSymbol('SET_FETCH_CATEGORIES_CHART_FAILURE'),
  CLEAR_CATEGORIES_CHART_STATE: createDashboardInternalSymbol('CLEAR_CATEGORIES_CHART_STATE'),

  // Game Style History Chart
  FETCH_GAME_STYLE_HISTORY_CHART: createDashboardSymbol('FETCH_GAME_STYLE_HISTORY_CHART'),
  SET_FETCH_GAME_STYLE_HISTORY_CHART_SUCCESS: createDashboardSymbol('SET_FETCH_GAME_STYLE_HISTORY_CHART_SUCCESS'),
  SET_FETCH_GAME_STYLE_HISTORY_CHART_FAILURE: createDashboardSymbol('SET_FETCH_GAME_STYLE_HISTORY_CHART_FAILURE'),
  CLEAR_GAME_STYLE_HISTORY_CHART_STATE: createDashboardInternalSymbol('CLEAR_GAME_STYLE_HISTORY_CHART_STATE'),

  // Game Style Tendency Chart
  FETCH_GAME_STYLE_TENDENCY_CHART: createDashboardSymbol('FETCH_GAME_STYLE_TENDENCY_CHART'),
  SET_FETCH_GAME_STYLE_TENDENCY_CHART_SUCCESS: createDashboardSymbol('SET_FETCH_GAME_STYLE_TENDENCY_CHART_SUCCESS'),
  SET_FETCH_GAME_STYLE_TENDENCY_CHART_FAILURE: createDashboardSymbol('SET_FETCH_GAME_STYLE_TENDENCY_CHART_FAILURE'),
  CLEAR_GAME_STYLE_TENDENCY_CHART_STATE: createDashboardInternalSymbol('CLEAR_GAME_STYLE_TENDENCY_CHART_STATE'),

  // Analytic Chart
  FETCH_ANALYTIC_CHART: createDashboardSymbol('FETCH_ANALYTIC_CHART'),
  SET_FETCH_ANALYTIC_CHART_SUCCESS: createDashboardSymbol('SET_FETCH_ANALYTIC_CHART_SUCCESS'),
  SET_FETCH_ANALYTIC_CHART_FAILURE: createDashboardSymbol('SET_FETCH_ANALYTIC_CHART_FAILURE'),
  CLEAR_ANALYTIC_CHART_STATE: createDashboardInternalSymbol('CLEAR_ANALYTIC_CHART_STATE'),

  // Alert Chart Multi
  FETCH_ALERT_CHART_MULTI: createDashboardSymbol('FETCH_ALERT_CHART_MULTI'),
  SET_FETCH_ALERT_CHART_MULTI_SUCCESS: createDashboardSymbol('SET_FETCH_ALERT_CHART_MULTI_SUCCESS'),
  SET_FETCH_ALERT_CHART_MULTI_FAILURE: createDashboardSymbol('SET_FETCH_ALERT_CHART_MULTI_FAILURE'),
  CLEAR_ALERT_CHART_MULTI_STATE: createDashboardInternalSymbol('CLEAR_ALERT_CHART_MULTI_STATE'),

  // Alert Chart Linked
  FETCH_ALERT_CHART_LINKED: createDashboardSymbol('FETCH_ALERT_CHART_LINKED'),
  SET_FETCH_ALERT_CHART_LINKED_SUCCESS: createDashboardSymbol('SET_FETCH_ALERT_CHART_LINKED_SUCCESS'),
  SET_FETCH_ALERT_CHART_LINKED_FAILURE: createDashboardSymbol('SET_FETCH_ALERT_CHART_LINKED_FAILURE'),
  CLEAR_ALERT_CHART_LINKED_STATE: createDashboardInternalSymbol('CLEAR_ALERT_CHART_LINKED_STATE'),

  // Analytic Kpis
  FETCH_ANALYTIC_KPIS: createDashboardSymbol('FETCH_ANALYTIC_KPIS'),
  SET_FETCH_ANALYTIC_KPIS_SUCCESS: createDashboardSymbol('SET_FETCH_ANALYTIC_KPIS_SUCCESS'),
  SET_FETCH_ANALYTIC_KPIS_FAILURE: createDashboardSymbol('SET_FETCH_ANALYTIC_KPIS_FAILURE'),
  CLEAR_ANALYTIC_KPIS_STATE: createDashboardInternalSymbol('CLEAR_ANALYTIC_KPIS_STATE'),

  // Licenses Kpis
  FETCH_DASHBOARD_KPIS: createDashboardSymbol('FETCH_DASHBOARD_KPIS'),
  SET_FETCH_DASHBOARD_KPIS_SUCCESS: createDashboardSymbol('SET_FETCH_DASHBOARD_KPIS_SUCCESS'),
  SET_FETCH_DASHBOARD_KPIS_FAILURE: createDashboardSymbol('SET_FETCH_DASHBOARD_KPIS_FAILURE'),
  CLEAR_DASHBOARD_KPIS_STATE: createDashboardInternalSymbol('CLEAR_DASHBOARD_KPIS_STATE'),

  // Licenses SDK cancel
  CANCEL_DASHBOARD_SDK_CALL: createDashboardSymbol('CANCEL_DASHBOARD_SDK_CALL')
};
