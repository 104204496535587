/**
 * @flow
 * */

export const ACTION_STATUSES = {
  CANCELED: 'canceled',
  PENDING: 'pending',
  SUCCEED: 'succeed',
  FAILED: 'failed',
  NONE: undefined
};

export const ACTION_TYPES = {
  ADD: 'Add',
  GET: 'Get',
  UPDATE: 'Update',
  DELETE: 'Delete'
};

// $FlowFixMe
export type ActionType = ACTION_TYPES.ADD |ACTION_TYPES.GET |ACTION_TYPES.UPDATE |ACTION_TYPES.DELETE;

const isActionStatusCanceled = (status: ?string): boolean => status === ACTION_STATUSES.CANCELED;
const isActionStatusPending = (status: ?string): boolean => status === ACTION_STATUSES.PENDING;
const isActionStatusSucceed = (status: ?string): boolean => status === ACTION_STATUSES.SUCCEED;
const isActionStatusFailed = (status: ?string): boolean => status === ACTION_STATUSES.FAILED;
const isActionStatusNoneDefined = (status: ?string): boolean => !!status;

export default {
  isActionStatusNoneDefined,
  isActionStatusCanceled,
  isActionStatusSucceed,
  isActionStatusPending,
  isActionStatusFailed
};
