import createSymbol from 'lib/core/createSymbol';

const createAuthSymbol = createSymbol('AUTH');

export default {

  FETCH_JWT_AUTH: createAuthSymbol('FETCH_JWT_AUTH'),
  FETCH_JWT_AUTH_SUCCESS: createAuthSymbol('FETCH_JWT_AUTH_SUCCESS'),
  FETCH_JWT_AUTH_FAILED: createAuthSymbol('FETCH_JWT_AUTH_FAILED'),
  FETCH_JWT_AUTH_CHANGE_PASSWORD: createAuthSymbol('FETCH_JWT_AUTH_CHANGE_PASSWORD'),
  FETCH_JWT_AUTH_CHANGE_PASSWORD_SUCCESS: createAuthSymbol('FETCH_JWT_AUTH_CHANGE_PASSWORD_SUCCESS'),
  FETCH_JWT_AUTH_CHANGE_PASSWORD_FAILED: createAuthSymbol('FETCH_JWT_AUTH_CHANGE_PASSWORD_FAILED'),
  FETCH_JWT_AUTH_TWO_STEP: createAuthSymbol('FETCH_JWT_AUTH_TWO_STEP'),
  FETCH_JWT_AUTH_TWO_STEP_SUCCESS: createAuthSymbol('FETCH_JWT_AUTH_TWO_STEP_SUCCESS'),
  FETCH_JWT_AUTH_TWO_STEP_FAILED: createAuthSymbol('FETCH_JWT_AUTH_TWO_STEP_FAILED')
};
