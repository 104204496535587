import type { Action } from 'lib/core/flow';
import SETTINGS_ACTIONS from './types';

export * from './categorySettings';
export * from './partnerAlertSettings';
export * from './changeHistory';

// cancel call
export const cancelCategorySettingsSDKCalls = ({ name }): Action => ({
  type: SETTINGS_ACTIONS.CANCEL_CS_SDK_CALL,
  payload: { name }
});

// cancel call
export const cancelPartnerSDKCalls = ({ name }): Action => ({
  type: SETTINGS_ACTIONS.CANCEL_PARTNER_SDK_CALL,
  payload: { name }
});
