import React from 'react';
import Link from 'react-router-dom/es/Link';

import { permissions } from 'config/settings/permissions';
import settings from 'config/constants';

const products = settings.PRODUCTS;

const { path } = products.ADMIN;

export default [
  {
    id: `/${path}`,
    title: <Link className="route_link" to={`/${path}`}>Admin Panel</Link>,
    icon: 'bc-icon-graph-flow',
    permission: permissions.AdminPanel
  },
  {
    id: `/${path}/user-management`,
    title: <Link className="route_link" to={`/${path}/user-management`}>User Management</Link>,
    icon: 'bc-icon-players-rmt-36',
    permission: permissions.AdminPanel
  },
  {
    id: `/${path}/partner-management/partner-activation`,
    title: <Link className="route_link" to={`/${path}/partner-management/partner-activation`}>Partner Management</Link>,
    icon: 'bc-icon-partner',
    permission: permissions.AdminPanel
  },
  {
    id: `/${path}/license-management`,
    title: <Link className="route_link" to={`/${path}/license-management`}>License Management</Link>,
    icon: 'bc-icon-license',
    permission: permissions.AdminPanel
  },
  {
    id: `/${path}/automated-comments`,
    title: <Link className="route_link" to={`/${path}/automated-comments`}>Automated Comments</Link>,
    icon: 'bc-icon-crm-standart-report',
    permission: permissions.AdminPanel
  }
];
