/**
 * @flow
 * */
import createSymbol from 'lib/core/createSymbol';

const createAppSymbol = createSymbol('APP');

export default {
  INIT_APPLICATION: createAppSymbol('INIT_APPLICATION'),
  SET_APPLICATION_IS_READY: createAppSymbol('SET_APPLICATION_IS_READY'),

  CHANGE_INTERNET_STATUS: createAppSymbol('CHANGE_INTERNET_STATUS'),
  RESET_INTERNET_STATUS: createAppSymbol('RESET_INTERNET_STATUS')
};
