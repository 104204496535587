/**
 * @flow
 * */
import PLAYER_ACTION_TYPES from 'modules/LCRMT/Players/store/actions/player/types';
import { initialSectionState } from 'store/reducers/initialStates';
import type { PlayerState } from 'modules/LCRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

const initialState = {
  financial: {
    gaming: {
      kpi: initialSectionState(),
      table: initialSectionState()
    },
    activity: initialSectionState(),
    transactions: {
      kpi: initialSectionState(),
      table: initialSectionState()
    }
  }
};

const actionManager = {
  // gaming kpi
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        ...state.financial.gaming,
        kpi: {
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        ...state.financial.gaming,
        kpi: {
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        ...state.financial.gaming,
        kpi: {
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_STATE]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        kpi: initialSectionState()
      }
    }
  }),

  // gaming table
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        ...state.financial.gaming,
        table: {
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        ...state.financial.gaming,
        table: {
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        ...state.financial.gaming,
        table: {
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_STATE]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      gaming: {
        ...state.financial.gaming,
        table: initialSectionState()
      }
    }
  }),

  // activity
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      activity: {
        data: null,
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      activity: {
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      activity: {
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_PLAYER_FINANCIAL_ACTIVITY_STATE]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      activity: initialSectionState()
    }
  }),

  // transactions kpi
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        kpi: {
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        kpi: {
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        kpi: {
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_STATE]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        kpi: initialSectionState()
      }
    }
  }),

  // transactions table
  [PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        table: {
          data: null,
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        table: {
          data: payload.data,
          status: ACTION_STATUSES.SUCCEED,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        table: {
          data: null,
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_STATE]: (state: PlayerState) => ({
    ...state,
    financial: {
      ...state.financial,
      transactions: {
        ...state.financial.transactions,
        table: initialSectionState()
      }
    }
  })

};

export default {
  initialState,
  actionManager
};
