/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';

// Restrictions Log
export const fetchRestrictionsLog = ({ playerId, count, start }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_RESTRICTIONS_LOG,
  payload: { playerId, count, start }
});
export const setFetchRestrictionsLogSuccess = ({ data, totalCount }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_RESTRICTIONS_LOG_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchRestrictionsLogFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_RESTRICTIONS_LOG_FAILURE,
  payload: { error }
});
export const clearRestrictionsLogState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_RESTRICTIONS_LOG_STATE
});

// Player Restrictions
export const fetchPlayerRestrictions = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_RESTRICTIONS,
  payload: { playerId }
});
export const setFetchPlayerRestrictionsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_RESTRICTIONS_SUCCESS,
  payload: { data }
});
export const setFetchPlayerRestrictionsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_RESTRICTIONS_FAILURE,
  payload: { error }
});
export const clearPlayerRestrictionsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_RESTRICTIONS_STATE
});

// Edit Player Restrictions
export const fetchEditPlayerRestrictions = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_EDIT_PLAYER_RESTRICTIONS,
  payload: { data }
});
export const setFetchEditPlayerRestrictionsSuccess = (): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_EDIT_PLAYER_RESTRICTIONS_SUCCESS
});
export const setFetchEditPlayerRestrictionsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_EDIT_PLAYER_RESTRICTIONS_FAILURE,
  payload: { error }
});
export const clearEditPlayerRestrictionsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_EDIT_PLAYER_RESTRICTIONS_STATE
});
