/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

const Dashboard = React.lazy(() => import('./views/pages/Dashboard'));

const subUrl = settings.PRODUCTS.RMT.path;

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const ROUTES: Array<Route> = [
  { path: `/${subUrl}`, name: 'Dashboard', exact: true, page: () => <Redirect to={`/${subUrl}/dashboard`} /> },
  { path: `/${subUrl}/dashboard`, name: 'Dashboard', exact: true, page: Dashboard }
];

export default ROUTES;
