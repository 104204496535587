import { AutomatedCommentsModel } from 'modules/Admin/AutomatedComments/core/sdk/types';
import NetworkService from 'core/services/network/NetworkService';
import { WithTotalCount } from 'types';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';

class CommentsTemplateSDK extends BaseSDK {
    _rmtApiService: NetworkService = null;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
    }

    /**
     * getCommentsTemplate
     * @returns {Promise<Object>} -
     */
    async getCommentsTemplate(): { data: WithTotalCount<Array<AutomatedCommentsModel>> } {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'CommentTemplate/GetAll',
        { query_params: {}, abortController: this.setAbortController('getCommentsTemplate'), caching: true }
      );
      return body.Data;
    }
}

export default new CommentsTemplateSDK();
