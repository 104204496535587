export const parseAMLModelFromResponse = (response: Object) => {
  const { Data: { Models, TotalCount } } = response;
  return {
    data: Models,
    totalCount: TotalCount
  };
};

export const parseAMLPlayerMultiAccountResponse = (response: Object) => {
  const { Data } = response;
  const newData = [];
  Data.forEach(item => {
    const row = newData.find(i => i.PlayerId === item.PlayerId);
    if (row) {
      if (!row.MultiAccountTypes.some(elem => elem.Name === item.TriggerName)) {
        row.MultiAccountTypes.push({ Name: item.TriggerName });
      }
    } else {
      newData.push({
        ...item,
        MultiAccountTypes: [{ Name: item.TriggerName }]
      });
    }
  });
  return {
    data: newData
  };
};
