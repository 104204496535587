import user from './user';
import auth from './auth';
import howTo from './howTo';
import notification from './notification';

export default {
  user,
  auth,
  howTo,
  notification
};
