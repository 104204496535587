/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import APP_ACTION_TYPES from 'store/actions/application/types';

export type ApplicationState = {
  isLoggedIn: boolean,
  isReady: boolean,
  internetStatus: {
    isOnLine: ?boolean,
    onLineMessage: string,
    offLineMessage: string
  }
};

const initialState = () => ({
  isLoggedIn: false,
  isReady: false,
  internetStatus: {
    isOnLine: null,
    onLineMessage: 'Your internet connection is online.',
    offLineMessage: 'Check your Internet Connection.'
  }
});

/* ************                         **************
   ************ SET Reducers **************
   ************                         ************** */

const actionManager = {
  [APP_ACTION_TYPES.SET_APPLICATION_IS_READY]: (state: ApplicationState, payload) => ({
    ...state,
    isLoggedIn: payload.isLoggedIn,
    isReady: payload.isReady
  }),

  [APP_ACTION_TYPES.CHANGE_INTERNET_STATUS]: (state: ApplicationState, payload) => ({
    ...state,
    internetStatus: {
      ...state.internetStatus,
      isOnLine: payload.internetStatus
    }
  }),

  [APP_ACTION_TYPES.RESET_INTERNET_STATUS]: (state: ApplicationState) => ({
    ...state,
    internetStatus: {
      isOnLine: null,
      onLineMessage: 'Your internet connection is online.',
      offLineMessage: 'Check your Internet Connection.'
    }
  })

};

export default (state : ApplicationState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
