import createSymbol from 'lib/core/createSymbol';

const createAlertMonitorSymbol = createSymbol('ALERT_MONITOR');

export default {
  // internal
  INIT_MONITOR: createAlertMonitorSymbol('INIT'),
  UNMOUNT_MONITOR: createAlertMonitorSymbol('UNMOUNT'),

  /** *************** SIDE EFFECTS ****************** */
  // fetch alert monitor data
  FETCH_MONITOR_TAB: createAlertMonitorSymbol('FETCH_MONITOR_TAB'),
  SET_FETCH_MONITOR_TAB_SUCCESS: createAlertMonitorSymbol('SET_FETCH_MONITOR_TAB_SUCCESS'),
  SET_FETCH_MONITOR_TAB_FAILURE: createAlertMonitorSymbol('SET_FETCH_MONITOR_TAB_FAILURE'),
  CLEAR_MONITOR_TAB_STATE: createAlertMonitorSymbol('CLEAR_MONITOR_TAB_STATE'),
  REMOVE_ALERT_ROW: createAlertMonitorSymbol('REMOVE_ALERT_ROW'),
  CHANGE_ALERT_ROW: createAlertMonitorSymbol('CHANGE_ALERT_ROW'),

  // fetch review data
  FETCH_REVIEW_DATA: createAlertMonitorSymbol('FETCH_REVIEW_DATA'),
  SET_FETCH_REVIEW_DATA_SUCCESS: createAlertMonitorSymbol('SET_FETCH_REVIEW_DATA_SUCCESS'),
  SET_FETCH_REVIEW_DATA_FAILURE: createAlertMonitorSymbol('SET_FETCH_REVIEW_DATA_FAILURE'),
  CLEAR_REVIEW_DATA: createAlertMonitorSymbol('CLEAR_REVIEW_DATA'),

  // edit review data
  EDIT_REVIEW_DATA: createAlertMonitorSymbol('EDIT_REVIEW_DATA'),
  SET_EDIT_REVIEW_DATA_SUCCESS: createAlertMonitorSymbol('SET_EDIT_REVIEW_DATA_SUCCESS'),
  SET_EDIT_REVIEW_DATA_FAILURE: createAlertMonitorSymbol('SET_EDIT_REVIEW_DATA_FAILURE'),
  CLEAR_EDIT_REVIEW_DATA: createAlertMonitorSymbol('CLEAR_EDIT_REVIEW_DATA'),

  // review history data
  FETCH_HISTORY_REVIEW_DATA: createAlertMonitorSymbol('FETCH_HISTORY_REVIEW_DATA'),
  SET_HISTORY_REVIEW_DATA_SUCCESS: createAlertMonitorSymbol('SET_HISTORY_REVIEW_DATA_SUCCESS'),
  SET_HISTORY_REVIEW_DATA_FAILURE: createAlertMonitorSymbol('SET_HISTORY_REVIEW_DATA_FAILURE'),
  CLEAR_HISTORY_REVIEW_DATA: createAlertMonitorSymbol('CLEAR_HISTORY_REVIEW_DATA'),

  // review history expand data
  FETCH_HISTORY_REVIEW_CHILDREN_DATA: createAlertMonitorSymbol('FETCH_HISTORY_REVIEW_CHILDREN_DATA'),
  SET_HISTORY_REVIEW_CHILDREN_DATA_SUCCESS: createAlertMonitorSymbol('SET_HISTORY_REVIEW_CHILDREN_DATA_SUCCESS'),
  SET_HISTORY_REVIEW_CHILDREN_DATA_FAILURE: createAlertMonitorSymbol('SET_HISTORY_REVIEW_CHILDREN_DATA_FAILURE'),

  // expand row data
  FETCH_EXPAND_ROW_CHILDREN_DATA: createAlertMonitorSymbol('FETCH_EXPAND_ROW_CHILDREN_DATA'),
  SET_EXPAND_ROW_CHILDREN_DATA_SUCCESS: createAlertMonitorSymbol('SET_EXPAND_ROW_CHILDREN_DATA_SUCCESS'),
  SET_EXPAND_ROW_CHILDREN_DATA_FAILURE: createAlertMonitorSymbol('SET_EXPAND_ROW_CHILDREN_DATA_FAILURE'),

  // Alert SDK cancel
  CANCEL_ALERT_SDK_CALL: createAlertMonitorSymbol('CANCEL_ALERT_SDK_CALL')
};
