import type { Action } from 'lib/core/flow';

import SETTINGS_ACTIONS from '../types';

// SuspiciousActivity Settings
export const fetchFinancialSettings = ({ partnerId }): Action => ({
  type: SETTINGS_ACTIONS.FETCH_FINANCIAL_SETTINGS,
  payload: { partnerId }
});
export const setFetchFinancialSettingsSuccess = ({ data }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_FINANCIAL_SETTINGS_SUCCESS,
  payload: { data }
});
export const setFetchFinancialSettingsFailure = ({ error }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_FINANCIAL_SETTINGS_FAILURE,
  payload: { error }
});
export const clearFinancialSettingsState = (): Action => ({
  type: SETTINGS_ACTIONS.CLEAR_FINANCIAL_SETTINGS_STATE
});

// SuspiciousActivity Update
export const fetchFinancialUpdate = ({ params }): Action => ({
  type: SETTINGS_ACTIONS.FETCH_FINANCIAL_UPDATE,
  payload: { params }
});
export const setFetchFinancialUpdateSuccess = ({ data }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_FINANCIAL_UPDATE_SUCCESS,
  payload: { data }
});
export const setFetchFinancialUpdateFailure = ({ error, params }): Action => ({
  type: SETTINGS_ACTIONS.SET_FETCH_FINANCIAL_UPDATE_FAILURE,
  payload: { error, params }
});
export const clearFinancialUpdateState = (): Action => ({
  type: SETTINGS_ACTIONS.CLEAR_FINANCIAL_UPDATE_STATE
});
