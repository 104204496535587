/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

const CategorySettingsPage = React.lazy(() => import('./views/pages/CategorySettingsPage'));
const SettingsChangeHistoryPage = React.lazy(() => import('./views/pages/SettingsChangeHistoryPage'));
const PartnerAlertsSettingsPage = React.lazy(() => import('./views/pages/PartnerAlertsSettingsPage'));
const AlertMonitorSettingsPage = React.lazy(() => import('./views/pages/AlertMonitorSettingsPage'));
const NotificationSettings = React.lazy(() => import('./views/pages/NotificationSettings'));
const TriggerSettings = React.lazy(() => import('./views/pages/TriggerSettings'));

const subUrl = settings.PRODUCTS.RMT.path;
const modulePath = `${subUrl}/settings`;

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const ROUTES: Array<Route> = [
  { path: `/${modulePath}`, name: 'Settings', exact: true, page: () => <Redirect to={`/${modulePath}/category-settings`} /> },
  { path: `/${modulePath}/category-settings/:tab`, name: 'Category Settings', exact: true, page: CategorySettingsPage },
  { path: `/${modulePath}/category-settings/`, name: 'Category Settings Main', exact: true, page: () => <Redirect to={`/${modulePath}/category-settings/additional`} /> },
  { path: `/${modulePath}/settings-change-history`, name: 'Settings Change History', exact: true, page: SettingsChangeHistoryPage },
  { path: `/${modulePath}/alert-monitor-settings`, name: 'Alert Monitor Settings', exact: true, page: AlertMonitorSettingsPage },
  { path: `/${modulePath}/partner-alerts-settings`, name: 'Partner Alerts Settings', exact: true, page: PartnerAlertsSettingsPage },
  { path: `/${modulePath}/notification-settings`, name: 'Notification Settings', exact: true, page: NotificationSettings },
  { path: `/${modulePath}/trigger-settings`, name: 'Trigger Settings', exact: true, page: TriggerSettings }
];

export default ROUTES;
