/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import CHI_SQUARE_ALERTS_ACTION_TYPES from './types';

// alerts
export const fetchChiSquareAlerts = ({ start, count, ...filters }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.FETCH_CHI_SQUARE_ALERTS,
  payload: { start, count, filters }
});

export const setFetchChiSquareAlertsSuccess = ({ data, totalCount }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_ALERTS_SUCCESS,
  payload: { data, totalCount }
});

export const setFetchChiSquareAlertsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_CHI_SQUARE_ALERTS_FAILURE,
  payload: { error }
});

export const clearChiSquareAlertsState = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_CHI_SQUARE_ALERTS_STATE
});

// alert reviews
export const fetchAlertReviews = ({ alertId }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.FETCH_ALERT_REVIEWS,
  payload: { alertId }
});

export const setFetchAlertReviewsSuccess = ({ data }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_SUCCESS,
  payload: { data }
});

export const setFetchAlertReviewsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_FAILURE,
  payload: { error }
});

export const clearAlertReviewsState = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_ALERT_REVIEWS_STATE
});

// close alert review
export const closeAlertReview = ({ alertId }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.CLOSE_ALERT_REVIEW,
  payload: { alertId }
});

export const setCloseAlertReviewSuccess = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_CLOSE_ALERT_REVIEW_SUCCESS
});

export const setCloseAlertReviewFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_CLOSE_ALERT_REVIEW_FAILURE,
  payload: { error }
});

export const clearCloseAlertReviewState = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_CLOSE_REVIEW_STATE
});

// resolve alert review
export const resolveAlertReview = ({ alertId, comment }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.RESOLVE_ALERT_REVIEW,
  payload: { alertId, comment }
});

export const setResolveAlertReviewSuccess = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_RESOLVE_ALERT_REVIEW_SUCCESS
});

export const setResolveAlertReviewFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_RESOLVE_ALERT_REVIEW_FAILURE,
  payload: { error }
});

export const clearResolveAlertReviewState = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_RESOLVE_REVIEW_STATE
});

// alert reviews statistics
export const fetchAlertReviewsStatistics = ({ alertId }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.FETCH_ALERT_REVIEWS_STATISTICS,
  payload: { alertId }
});

export const setFetchAlertReviewStatisticsSuccess = ({ data }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_STATISTICS_SUCCESS,
  payload: { data }
});

export const setFetchAlertReviewStatisticsFailure = ({ error }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.SET_FETCH_ALERT_REVIEWS_STATISTICS_FAILURE,
  payload: { error }
});

export const clearAlertReviewStatisticsState = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.CLEAR_ALERT_REVIEWS_STATISTICS_STATE
});

// update alert
export const updateAlert = (): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.UPDATE_ALERT
});

// cancel call
export const cancelAlertSDKCalls = ({ name }: Object): Action => ({
  type: CHI_SQUARE_ALERTS_ACTION_TYPES.CANCEL_ALERT_SDK_CALL,
  payload: { name }
});
