/* eslint-disable camelcase */
/**
 * @flow
 * */
import { createSelector } from 'reselect';
import { getNotificationState } from './defaultStates';

export const getNotificationSelectors: Function = createSelector(
  getNotificationState,
  (state: Object) => state?.getNotification
);

export const getAddUpdateNotificationSelectors: Function = createSelector(
  getNotificationState,
  (state: Object) => state?.getAddUpdateNotification
);

export const getNotificationFilterSelectors: Function = createSelector(
  getNotificationState,
  (state: Object) => state?.getNotificationFilter
);

export const notificationsSelectors: Function = createSelector(
  getNotificationState,
  (state: Object) => state?.notifications
);
