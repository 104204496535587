export const screenTypes = {
  desktop: 'desktop',
  mobile: 'mobile'
};
export const colors = {
  primary: 'primary',
  confirm: 'confirm',
  danger: 'danger'
};
export const sizes = {
  small: 'small',
  medium: 'medium',
  big: 'big'
};
export const positions = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
  center: 'center'
};
export const radius = {
  defaultRadius: 'default-radius',
  fullRadius: 'full-radius'
};
export const modalConfig = {
  background: {
    transparent: 'transparent',
    light: 'light-background',
    dark: 'dark-background'
  },
  appearance: {
    compact: 'compact'
  }
};

export const searchTypesConfig = {
  select: 'select',
  text: 'text'
};

export const buttonConfig = {
  appearance: {
    grayscale: 'grayscale',
    outline: 'outline',
    minimal: 'minimal',
    default: 'default'
  },
  color: {
    confirm: 'confirm',
    primary: 'primary',
    default: 'default',
    danger: 'danger'
  },
  size: {
    medium: 'medium',
    default: 'default',
    big: 'big'
  },
  cornerRadius: {
    smooth: 'smooth'
  },
  flexibility: {
    fullWidth: 'full-width'
  },
  type: {
    submit: 'submit'
  }
};

export const inputConfig = {
  type: {
    text: 'text',
    color: 'color',
    number: 'number',
    password: 'password',
    textarea: 'textarea',
    date: 'date',
    time: 'time'
  },
  appearance: {
    outline: 'outline',
    minimal: 'minimal',
    light: 'light'
  },
  size: {
    small: 'small',
    default: 'default',
    big: 'big'
  },
  flexibility: {
    fullWidth: 'full-width',
    contentSize: 'content-size'
  },
  itemsDirection: {
    start: 'start',
    end: 'end'
  },
  cornerRadius: {
    fullRadius: 'full-radius',
    smoothRadius: 'smooth-radius'
  },
  labelAppearance: {
    none: 'none',
    title: 'title',
    swap: 'swap'
  }
};
export const titleConfig = {
  color: {
    base: 'base',
    hero: 'hero'
  }
};

export const tabsConfig = {
  types: {
    box: 'box',
    text: 'text',
    basic: 'basic',
    button: 'button'
  },
  positions: {
    top: 'top',
    left: 'left',
    right: 'right'
  }
};
export const checkboxRadioSwitcherConfig = {
  size: {
    small: 'small',
    big: 'big'
  },
  labelPosition: {
    right: 'right',
    left: 'left',
    top: 'top',
    bottom: 'bottom'
  },
  labelAlignment: {
    start: 'start',
    center: 'center',
    end: 'end'
  }
};
export const tagConfig = {
  appearance: {
    simple: 'simple',
    minimal: 'minimal',
    outline: 'outline',
    clean: 'clean',
    light: 'light',
    colored: 'colored'
  },
  size: {
    small: 'small',
    medium: 'medium',
    big: 'big'
  },
  flexibility: {
    contentSize: 'content-size',
    compact: 'compact',
    fullWidth: 'full-width'
  },
  cornerRadius: {
    fullRadius: 'full-radius',
    smoothRadius: 'smooth-radius'
  }
};
export const stepsConfig = {
  size: {
    small: 'small',
    big: 'big'
  },
  direction: {
    horizontal: 'horizontal',
    vertical: 'vertical'
  },
  appearance: {
    steps: 'steps',
    dots: 'dots'
  },
  status: {
    initial: 'initial',
    current: 'current',
    success: 'success',
    fail: 'fail',
    activated: 'activated'
  }
};
export const popoverConfig = {
  align: {
    start: 'start',
    end: 'end',
    center: 'center'
  },
  position: {
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    top: 'top'
  },
  cornerRadius: [
    'full-radius',
    'smooth-radius'
  ],
  behave: [
    'toggle',
    'open'
  ]
};
export const uploaderConfig = {
  uploaderAppearance: [
    'button',
    'input',
    'cloud',
    'box'
  ],
  uploadedItemsAppearance: [
    'light',
    'minimal',
    'detailed',
    'box'
  ],
  uploadedFilesAppearance: [
    'light',
    'minimal'
  ],
  gridColumnSize: {
    xs: 1,
    md: 2,
    lg: 4,
    xl: 5,
    xxl: 6
  }
};
export const moduleTitleConfig = {
  cornerRadius: {
    positionRadius: 'position-radius',
    noRadius: 'no-radius',
    fullRadius: 'full-radius'
  },
  position: {
    top: 'top',
    bottom: 'bottom'
  },
  size: {
    small: 'small',
    medium: 'medium',
    big: 'big',
    extraBig: 'extra-big'
  }
};
export const widgetConfig = {
  type: {
    compact: 'compact',
    minimal: 'minimal',
    colorful: 'colorful'
  },
  size: {
    small: 'small',
    medium: 'medium',
    big: 'big'
  },
  comparisonStatus: {
    initial: 'initial',
    up: 'up',
    down: 'down'
  }
};
export const customScrollbarConfig = {
  size: {
    medium: 'medium',
    small: 'small'
  }
};
export const noDataConfig = ['data', 'image', 'search'];
export const timePickerConfig = {
  appearance: {
    multipleInputs: 'multipleInputs',
    singleInput: 'singleInput'
  }
};
export const optionConfig = {
  color: [
    'default',
    'hero'
  ],
  border: [
    'none',
    'top',
    'bottom'
  ],
  sticky: [
    'none',
    'top',
    'bottom'
  ]
};

export const emptyComponentConfig = {
  appearance: {
    greyscale: 'greyscale'
  },
  size: {
    big: 'big',
    small: 'small'
  }
};

export const justifyContents = {
  spaceBetween: 'space-between'
};

export const alignItems = {
  center: 'center'
};

export const paperConfig = {
  cornerRadius: {
    fullRadius: 'full-radius'
  },
  directions: {
    column: 'column'
  }
};

export const busyLoader = {
  loaderType: {
    spinner: 'spinner',
    bubbles: 'bubbles'
  },
  spinnerSize: {
    small: 'small',
    medium: 'medium',
    big: 'big'
  }
};
