/**
 * @flow
 * */
import React, { useMemo } from 'react';
import moment from 'moment/moment';

import DatePickerInput from '@geneui/components/DatePickerInput';
import Icon from '@geneui/components/Icon';

import { nowUtcFormatter } from 'lib/helpers/DateTimeFormatter';
import { getRangeOptions } from 'config/settings/configs';

import { agpSettingsSelector } from 'store/selectors/appSettingsSelectors';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';

import './styles.scss';

const DatePickerInputWithRangeWrapper = ({ onClear, value, withRangeOptions, withoutCustomDate = false, ...props }: Object) => {
  const {
    state: stateAGP
  } = useContainerStatusHandler({ selector: agpSettingsSelector });
  const timeZone = useMemo(() => stateAGP?.data?.TimeZones?.filter((item) => item.IsSelected)[0].Id || 0, [stateAGP]);
  const today = moment(nowUtcFormatter(), `MM/DD/YYYY${props.withTime ? ' HH:mm:ss' : ''}`).add(timeZone, 'hours');
  const currentDate = moment(nowUtcFormatter(), 'M/D/YYYY').add(timeZone, 'hours');
  const yesterday = { startDate: moment(nowUtcFormatter(), 'M/D/YYYY').add(timeZone, 'hours').add(-1, 'day'), endDate: moment(nowUtcFormatter(), 'M/D/YYYY').add(timeZone, 'hours').add(-1, 'day') };
  const last7days = {
    startDate: moment(nowUtcFormatter(), 'M/D/YYYY').add(timeZone, 'hours').add(-7, 'day'),
    endDate: currentDate
  };
  const last30days = {
    startDate: moment(nowUtcFormatter(), 'M/D/YYYY').add(timeZone, 'hours').add(-30, 'day'),
    endDate: currentDate
  };
  const thisMonth = {
    startDate: moment(new Date(new Date(currentDate).getFullYear(), new Date(currentDate).getMonth(), 1), 'M/D/YYYY'),
    endDate: currentDate
  };

  const canClear = props.canClear === undefined || props.canClear;

  return (
    <div className="DatePickerInputWithRangeWrapper">
      <DatePickerInput.WithRange
        {...props}
        value={value}
        {...withRangeOptions ? {
          pickerProps: {
            withRangeOptions: true,
            rangeOptions: withoutCustomDate ? [] : getRangeOptions(today, yesterday, last7days, last30days, thisMonth, props.withTime)
          }
        } : {}}
        markedDate={today}
        format={`YYYY/MM/DD${props.withTime ? ' HH:mm:ss' : ''}`}
        max={today}
      />
      {canClear && value[0] && value[1] && <Icon type="bc-icon-clear" onClick={onClear} />}
    </div>
  );
};
export default DatePickerInputWithRangeWrapper;
