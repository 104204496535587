/**
 * @flow
 * */
import PLAYER_GROUP_ACTION_TYPES from 'modules/SRMT/PlayerGroup/store/actions/playerGroup/types';
import type { PlayerGroupState } from 'modules/SRMT/PlayerGroup/types';
import { initialSectionState } from 'lib/core/initialStates';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';
import {
  initialActionReducerWithoutData,
  initialSectionWithCountState,
  initialSectionWithoutData
} from 'store/reducers/initialStates';

const initialState = (): PlayerGroupState => ({
  deleteGroup: initialActionReducerWithoutData(),
  playerGroups: initialSectionWithCountState(),
  addGroup: initialSectionState(),
  updateGroup: initialSectionState(),
  playersList: initialSectionWithCountState(),
  addPlayerToGroup: initialSectionState(),
  deletePlayerFromGroup: initialSectionWithoutData(),
  groupAction: initialSectionWithoutData(),
  uploadFile: initialSectionState(),
  groupInfo: initialSectionState()
});

function updateListAfterDelete(list, id) {
  return [...list.filter(item => item.Id !== id)];
}

const actionManager = {
  // Player Groups
  [PLAYER_GROUP_ACTION_TYPES.FETCH_PLAYER_GROUPS]: (state: PlayerGroupState) => ({
    ...state,
    playerGroups: {
      ...state.playerGroups,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_FETCH_PLAYER_GROUPS_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    playerGroups: {
      status: ACTION_STATUSES.SUCCEED,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playerGroups.totalCount,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_FETCH_PLAYER_GROUPS_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    playerGroups: {
      ...state.playerGroups,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_PLAYER_GROUPS_STATE]: (state: PlayerGroupState) => ({
    ...state,
    playerGroups: initialSectionWithCountState()
  }),

  // Delete Player Group
  [PLAYER_GROUP_ACTION_TYPES.DELETE_GROUP]: (state: PlayerGroupState) => ({
    ...state,
    deleteGroup: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_DELETE_GROUP_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    deleteGroup: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    },
    playerGroups: {
      ...state.playerGroups,
      data: updateListAfterDelete(state.playerGroups.data, payload.groupId),
      totalCount: state.playerGroups.totalCount - 1
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_DELETE_GROUP_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    deleteGroup: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_DELETE_GROUP_STATE]: (state: PlayerGroupState) => ({
    ...state,
    deleteGroup: initialActionReducerWithoutData()
  }),

  // Add Player Group
  [PLAYER_GROUP_ACTION_TYPES.ADD_GROUP]: (state: PlayerGroupState) => ({
    ...state,
    addGroup: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_ADD_GROUP_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    addGroup: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_ADD_GROUP_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    addGroup: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_ADD_GROUP_STATE]: (state: PlayerGroupState) => ({
    ...state,
    addGroup: initialSectionState()
  }),

  // Get Player Group Info
  [PLAYER_GROUP_ACTION_TYPES.FETCH_GROUP_INFO]: (state: PlayerGroupState) => ({
    ...state,
    groupInfo: {
      status: ACTION_STATUSES.PENDING,
      data: null,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_GROUP_INFO_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    groupInfo: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_GROUP_INFO_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    groupInfo: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_GROUP_INFO_STATE]: (state: PlayerGroupState) => ({
    ...state,
    groupInfo: initialSectionState()
  }),

  // Update Player Group
  [PLAYER_GROUP_ACTION_TYPES.UPDATE_GROUP]: (state: PlayerGroupState) => ({
    ...state,
    updateGroup: {
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_UPDATE_GROUP_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    updateGroup: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_UPDATE_GROUP_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    updateGroup: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_UPDATE_GROUP_STATE]: (state: PlayerGroupState) => ({
    ...state,
    updateGroup: initialSectionState()
  }),

  // Players List
  [PLAYER_GROUP_ACTION_TYPES.FETCH_PLAYERS]: (state: PlayerGroupState) => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_FETCH_PLAYERS_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    players: {
      status: ACTION_STATUSES.SUCCEED,
      totalCount: payload.totalCount,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_FETCH_PLAYERS_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    players: {
      ...state.players,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_PLAYERS_STATE]: (state: PlayerGroupState) => ({
    ...state,
    players: initialSectionWithCountState()
  }),
  // Group Players
  [PLAYER_GROUP_ACTION_TYPES.FETCH_GROUP_PLAYERS]: (state: PlayerGroupState) => ({
    ...state,
    playersList: {
      ...state.playersList,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_FETCH_GROUP_PLAYERS_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    playersList: {
      status: ACTION_STATUSES.SUCCEED,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.playersList.totalCount,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_FETCH_GROUP_PLAYERS_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    playersList: {
      ...state.playersList,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_GROUP_PLAYERS_STATE]: (state: PlayerGroupState) => ({
    ...state,
    playersList: initialSectionWithCountState()
  }),

  // Add Player to Group List
  [PLAYER_GROUP_ACTION_TYPES.ADD_PLAYER_TO_GROUP_LIST]: (state: PlayerGroupState, payload) => ({
    ...state,
    playersList: {
      ...state.playersList,
      totalCount: state.playersList.totalCount + payload.playersList?.length,
      data: [...payload.playersList, ...state.playersList.data]
    }
  }),

  // Delete Player to Group List
  [PLAYER_GROUP_ACTION_TYPES.DELETE_PLAYER_TO_GROUP_LIST]: (state: PlayerGroupState, payload) => ({
    ...state,
    playersList: {
      ...state.playersList,
      totalCount: state.playersList.totalCount - payload.playerIds.length,
      data: state.playersList.data.filter(item => !payload.playerIds.includes(item.Id))
    }
  }),

  // Add Player to Group
  [PLAYER_GROUP_ACTION_TYPES.ADD_PLAYER_TO_GROUP]: (state: PlayerGroupState) => ({
    ...state,
    addPlayerToGroup: {
      ...state.addPlayerToGroup,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_ADD_PLAYER_TO_GROUP_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    addPlayerToGroup: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_ADD_PLAYER_TO_GROUP_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    addPlayerToGroup: {
      ...state.addPlayerToGroup,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_ADD_PLAYER_TO_GROUP_STATE]: (state: PlayerGroupState) => ({
    ...state,
    addPlayerToGroup: initialSectionState()
  }),

  // Delete Player From Group
  [PLAYER_GROUP_ACTION_TYPES.DELETE_PLAYER_FROM_GROUP]: (state: PlayerGroupState) => ({
    ...state,
    deletePlayerFromGroup: {
      ...state.deletePlayerFromGroup,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_DELETE_PLAYER_FROM_GROUP_SUCCESS]: (state: PlayerGroupState) => ({
    ...state,
    deletePlayerFromGroup: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_DELETE_PLAYER_FROM_GROUP_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    deletePlayerFromGroup: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_DELETE_PLAYER_FROM_GROUP_STATE]: (state: PlayerGroupState) => ({
    ...state,
    deletePlayerFromGroup: initialSectionWithoutData()
  }),

  // Group Action
  [PLAYER_GROUP_ACTION_TYPES.GROUP_ACTION]: (state: PlayerGroupState) => ({
    ...state,
    groupAction: {
      ...state.groupAction,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_GROUP_ACTION_SUCCESS]: (state: PlayerGroupState) => ({
    ...state,
    groupAction: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_GROUP_ACTION_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    groupAction: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_GROUP_ACTION_STATE]: (state: PlayerGroupState) => ({
    ...state,
    groupAction: initialSectionWithoutData()
  }),

  // Upload File
  [PLAYER_GROUP_ACTION_TYPES.UPLOAD_FILE]: (state: PlayerGroupState) => ({
    ...state,
    uploadFile: {
      ...state.uploadFile,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_UPLOAD_FILE_SUCCESS]: (state: PlayerGroupState, payload) => ({
    ...state,
    uploadFile: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.SET_UPLOAD_FILE_FAILURE]: (state: PlayerGroupState, payload) => ({
    ...state,
    uploadFile: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [PLAYER_GROUP_ACTION_TYPES.CLEAR_UPLOAD_FILE_STATE]: (state: PlayerGroupState) => ({
    ...state,
    uploadFile: initialSectionState()
  })
};

export default (state: PlayerGroupState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
