/**
 * @flow
 * */
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import {
  initialSectionWithCountState,
  initialSectionWithEditState,
  initialSectionWithoutData
} from 'store/reducers/initialStates';
import type { PlayerState } from 'modules/SRMT/Players/types';
import PLAYER_ACTION_TYPES from '../actions/player/types';

const initialState = {
  restrictionsLog: initialSectionWithCountState(),
  playerRestrictions: initialSectionWithEditState()
};

const actionManager = {
  // Restrictions Log
  [PLAYER_ACTION_TYPES.FETCH_RESTRICTIONS_LOG]: (state: PlayerState): PlayerState => ({
    ...state,
    restrictionsLog: {
      ...state.restrictionsLog,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_RESTRICTIONS_LOG_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    restrictionsLog: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: payload.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_RESTRICTIONS_LOG_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    restrictionsLog: {
      ...state.restrictionsLog,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_RESTRICTIONS_LOG_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    restrictionsLog: initialSectionWithCountState()
  }),
  // Player Restrictions
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_RESTRICTIONS]: (state: PlayerState): PlayerState => ({
    ...state,
    playerRestrictions: {
      ...state.playerRestrictions,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_RESTRICTIONS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerRestrictions: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null,
      edit: initialSectionWithoutData()
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_RESTRICTIONS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerRestrictions: {
      ...state.playerRestrictions,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_RESTRICTIONS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerRestrictions: initialSectionWithEditState()
  }),
  [PLAYER_ACTION_TYPES.CHANGE_PLAYER_RESTRICTIONS_STATE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerRestrictions: {
      ...state.playerRestrictions,
      data: payload.data
    }
  }),
  // Edit Player Restrictions
  [PLAYER_ACTION_TYPES.FETCH_EDIT_PLAYER_RESTRICTIONS]: (state: PlayerState): PlayerState => ({
    ...state,
    playerRestrictions: {
      ...state.playerRestrictions,
      edit: {
        status: ACTION_STATUSES.PENDING
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_EDIT_PLAYER_RESTRICTIONS_SUCCESS]: (state: PlayerState): PlayerState => ({
    ...state,
    playerRestrictions: {
      ...state.playerRestrictions,
      edit: {
        status: ACTION_STATUSES.SUCCEED
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_EDIT_PLAYER_RESTRICTIONS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    playerRestrictions: {
      ...state.playerRestrictions,
      edit: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_EDIT_PLAYER_RESTRICTIONS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    playerRestrictions: {
      ...state.playerRestrictions,
      edit: initialSectionWithoutData()
    }
  })
};

export default {
  actionManager,
  initialState
};
