/**
 * @flow
 * */
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import {
  GameTableSettingsFilterData,
  NotificationSettingsData,
  SaveNotificationsParas,
  GameTableSettingsData,
  SaveGameTableSettings,
  SaveNotificationData,
  TableSettingsParams
} from 'modules/LCRMT/Settings/types';
import config from 'config';

/**
 * @class SettingSDK
 */
class SettingSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * fetchNotificationSettings -
     * @returns {Promise<WithData<NotificationSettingsData>>} -
     */
    async fetchNotificationSettings(): Promise<{data: Array<NotificationSettingsData>}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTSettings/GetNotifications',
        { query_params: { }, abortController: this.setAbortController('notificationSettings') }
      );

      return { data: body.Data };
    }

    /**
     * saveNotificationSettings -
     * @returns {Array<SaveNotificationData>} -
     */
    async saveNotificationSettings({ params }: SaveNotificationsParas):Promise<SaveNotificationData> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTSettings/SaveNotifications',
        { body: params }
      );
      return { data: body.Data };
    }

    /**
     * deleteNotificationSettings -
     * @returns {Promise<Object>} -
     */
    async deleteNotificationSettings({ Id }: Object):Promise<Object> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTSettings/DeleteNotification',
        { query_params: { Id } }
      );

      return { data: body.Data };
    }

    /**
     * fetchGameTableSettingsFilter -
     * @returns {Promise<WithData<GameTableSettingsFilterData>>} -
     */
    async fetchGameTableSettingsFilter(): Promise<{data: Array<GameTableSettingsFilterData>}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTSettings/GetGameTableSettingsFilter',
        { query_params: { }, abortController: this.setAbortController('gameTableSettingsFilter') }
      );

      return { data: body.Data };
    }

    /**
     * fetchGameTableSettings -
     * @param {TableSettingsParams} params -
     * @returns {Promise<WithData<GameTableSettingsData>>} -
     */
    async fetchGameTableSettings({ gameId }:TableSettingsParams): Promise<{data: Array<GameTableSettingsData>}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'LCRMTSettings/GetGameTableSettings',
        { query_params: { gameId }, abortController: this.setAbortController('gameTableSettings') }
      );

      return { data: body.Data };
    }

    /**
     * saveGameTableSettings -
     * @param {SaveGameTableSettings} params -
     * @returns {Promise<Object>} -
     */
    async saveGameTableSettings(params: SaveGameTableSettings): Promise<{data: Array<GameTableSettingsData>}> {
      const { savedData } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'LCRMTSettings/SaveGameTableSettings',
        { body: savedData, abortController: this.setAbortController('saveGameTableSettings') }
      );

      return {
        data: body.Data
      };
    }

    /**
     * cancelSettingsSDKCalls
     * @param {Object} params -
     * @returns {Object} -
     */
    cancelSettingsSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new SettingSDK();
