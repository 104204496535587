import { MonitorState, MonitorTabState } from 'modules/SRMT/AlertMonitor/types';
import { fraudMonitor } from 'modules/SRMT/AlertMonitor/settings';
import AlertMonitorFactory from './AlertMonitorFactory';

export const fraudMonitorInitialState = {
  alerts: {
    data: null,
    status: null,
    error: null
  },
  reviewing_alert: {
    data: null,
    status: null,
    error: null,
    edit: {
      data: null,
      status: null,
      error: null
    },
    history: {
      data: null,
      status: null,
      error: null
    }
  }
};

class FraudMonitorFactory extends AlertMonitorFactory {
  static createInitialFraudMonitor(): MonitorState {
    const tabs: MonitorTabState = Object.keys(fraudMonitor.tabs).map(
      name => FraudMonitorFactory.createInitialMonitorTab(name)
    );

    return FraudMonitorFactory.createInitialMonitor(fraudMonitor.key, tabs);
  }
}

export default FraudMonitorFactory;
