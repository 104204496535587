/**
 * @flow
 * */

import React from 'react';
import Header from 'modules/Dashboard/Auth/views/components/Header';

type Props = {
  user: Object
}

function HeaderContainer({ user }: Props) {
  return <Header user={user} />;
}

export default HeaderContainer;
