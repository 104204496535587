import config from 'config';

import NetworkService from 'core/services/network/NetworkService';
import { deepClone } from 'lib/helpers/makeData';
import BaseSDK from 'core/sdk/BaseSDK';

import { PartnerManagementParams, BackProjectEnum, BackTemplateEnum } from 'modules/Admin/PartnerLicenseManagement/types';
import type { AmlDataRequest, DeactivatePartnerRequest, SrmtDataRequest } from 'modules/Admin/PartnerLicenseManagement/types';
import parseAmlData from 'modules/Admin/PartnerLicenseManagement/utils/parseAmlData';

class PartnerManagementSDK extends BaseSDK {
    _rmtApiService: NetworkService = null;

    _amlApiService: NetworkService = null;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi, []);
      this._amlApiService = new NetworkService(config.amlApi, []);
    }

    async fetchPartnerManagement(params: PartnerManagementParams): Promise<{ Data: {Models: ?Array<any>, TotalCount: number}}> {
      const { count, start, filters, passTotalCount } = params;

      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Settings/GetPartners',
        { query_params: { ...filters, count, start, passTotalCount }, abortController: this.setAbortController('fetchPartnerManagement') }
      );
      return body.Data;
    }

    /**
     * cancelPartnerManagementSDKCalls
     * @param {Object} params -
     * @returns {Promise<{Object}>} -
     */
    cancelPartnerManagementSDKCalls(params: { name: string | Array<string> }): Promise<Object> {
      const { name } = params;
      this.abortCall(name);
    }

    async fetchPartners() {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Settings/GetTemplates',
        { query_params: { templateType: BackTemplateEnum.partner, project: BackProjectEnum.rmt } }
      );
      return body.Data;
    }

    async fetchTemplates() {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Settings/GetTemplates',
        { query_params: { templateType: BackTemplateEnum.template, project: BackProjectEnum.rmt } }
      );
      return body.Data;
    }

    async fetchAmlTemplates() {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Settings/GetTemplates',
        { query_params: { templateType: BackTemplateEnum.license, project: BackProjectEnum.aml } }
      );
      return body.Data;
    }

    async deactivatePartner(params: DeactivatePartnerRequest) {
      const { partnerId, productType } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Settings/SaveSettings',
        { body: {
          partnerId,
          [productType]: {
            [`${productType}Enabled`]: false
          }
        }
        }
      );
      return body.Data;
    }

    async fetchSrmtData(params: SrmtDataRequest) {
      // eslint-disable-next-line no-unused-vars
      const { id, forPartnerId, templateType } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Settings/GetSRMTTemplate',
        { query_params: { id, forPartnerId, templateType } }
      );
      return body.Data;
    }

    async fetchAmlData(params: AmlDataRequest) {
      const { id, templateType } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Settings/GetAMLTemplate',
        { query_params: { id, templateType } }
      );
      return parseAmlData({ Data: deepClone(body.Data) });
    }

    async fetchAmlSettingsData(params: AmlDataRequest) {
      const { id, templateType, playerSettings } = params;
      const { body } = await this._amlApiService.makeAPIGetRequest(
        `${playerSettings ? 'PlayerSettings' : 'AmlSettings'}/GetAMLTemplate`,
        { query_params: { id, templateType }, abortController: this.setAbortController('fetchAmlSettingsData') }
      );
      return parseAmlData({ Data: deepClone(body.Data) });
    }

    async fetchAllAmlSettings() {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'Settings/GetGeneralSettings'
      );
      return body.Data;
    }

    async fetchRiskTypesSettings() {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'RiskCountry/RiskModels'
      );
      return body.Data;
    }

    async setChangedData(params: Object) {
      const { data } = params;
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'Settings/SaveSettings',
        { body: data }
      );
      return body.Data;
    }

    async setAmlChangedData(params: Object) {
      const { data, playerSettings } = params;
      const { body } = await this._amlApiService.makeAPIPostRequest(
        `${playerSettings ? 'PlayerSettings' : 'AmlSettings'}/SaveSettings`,
        { body: data }
      );
      return body.Data;
    }
}

export default new PartnerManagementSDK();
