import { ACTION_STATUSES } from 'lib/core/actionStatuses';

import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/types';
import type { SettingsState } from 'modules/SRMT/Settings/types';
import { initialSectionState } from 'store/reducers/initialStates';

const initialState = {
  partnerAlerts: initialSectionState(),
  updateSettings: null,
  deleteSettings: null
};

const actionManager = {
  // Partner Alert Settings
  [SETTINGS_ACTION_TYPES.FETCH_PARTNER_ALERT_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      partnerAlerts: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_PARTNER_ALERT_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      partnerAlerts: {
        data: payload.data,
        status: ACTION_STATUSES.SUCCEED,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_PARTNER_ALERT_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      partnerAlerts: {
        data: null,
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_PARTNER_ALERT_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    partnerAlertSettings: initialState
  }),
  // Update Partner Alert Settings
  [SETTINGS_ACTION_TYPES.UPDATE_PARTNER_ALERT_SETTINGS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      updateSettings: {
        ...state.partnerAlertSettings.updateSettings,
        [payload.id]: {
          status: ACTION_STATUSES.PENDING,
          error: null
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_UPDATE_PARTNER_ALERT_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      updateSettings: {
        ...state.partnerAlertSettings.updateSettings,
        [payload.id]: {
          status: ACTION_STATUSES.SUCCEED,
          data: payload.data
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_UPDATE_PARTNER_ALERT_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      updateSettings: {
        ...state.partnerAlertSettings.updateSettings,
        [payload.id]: {
          status: ACTION_STATUSES.FAILED,
          error: payload.error
        }
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_UPDATE_PARTNER_ALERT_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      updateSettings: null
    }
  }),
  [SETTINGS_ACTION_TYPES.ADD_PARTNER_ALERT_SETTINGS_RULE]: (state: SettingsState, payload): SettingsState => {
    state.partnerAlertSettings.partnerAlerts.data
      .filter(row => row.AlertType === 1)[0].SubTypes
      .filter(row => row.AlertSubType === 2)[0].Rules.unshift(payload.data);
    return { ...state };
  },

  // Delete Partner Alert Settings
  [SETTINGS_ACTION_TYPES.DELETE_PARTNER_ALERT_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      deleteSettings: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_DELETE_PARTNER_ALERT_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      deleteSettings: {
        status: ACTION_STATUSES.SUCCEED,
        data: payload.data
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_DELETE_PARTNER_ALERT_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    partnerAlertSettings: {
      ...state.partnerAlertSettings,
      deleteSettings: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error
      }
    }
  })
};

export default {
  actionManager,
  initialState
};
