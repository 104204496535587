/**
 * @flow
 * */
import SETTINGS_ACTION_TYPES from 'modules/SRMT/Settings/store/actions/settings/notificationSettings/types';
import { initialSectionWithSaveDeleteState } from 'store/reducers/initialStates';
import type { SettingsState } from 'modules/SRMT/Settings/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';

const initialState = (): SettingsState => ({
  notificationSettings: initialSectionWithSaveDeleteState()
});

const actionManager = {
  // notification settings
  [SETTINGS_ACTION_TYPES.FETCH_SRMT_NOTIFICATION_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_SRMT_NOTIFICATION_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_FETCH_SRMT_NOTIFICATION_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [SETTINGS_ACTION_TYPES.CLEAR_SRMT_NOTIFICATION_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: initialState()
  }),

  // save notification settings
  [SETTINGS_ACTION_TYPES.SAVE_SRMT_NOTIFICATION_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      save: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_SAVE_SRMT_NOTIFICATION_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      save: {
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        data: payload.data
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_SAVE_SRMT_NOTIFICATION_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      save: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        data: null
      }
    }
  }),

  // delete notification settings
  [SETTINGS_ACTION_TYPES.DELETE_SRMT_NOTIFICATION_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      delete: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_DELETE_SRMT_NOTIFICATION_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      delete: {
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        data: payload.data
      }
    }
  }),
  [SETTINGS_ACTION_TYPES.SET_DELETE_SRMT_NOTIFICATION_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    notificationSettings: {
      ...state.notificationSettings,
      delete: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        data: null
      }
    }
  })

};

export default {
  actionManager,
  initialState
};
