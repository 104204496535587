import { takeLatest, takeEvery, put } from 'redux-saga/effects';

import PLAYER_ACTION_TYPES from 'modules/SRMT/Players/store/actions/player/types';
import { BackProjectEnum } from 'modules/Admin/PartnerLicenseManagement/types';
import playersSDK from 'modules/SRMT/Players/core/sdk/players';
import type {
  EditPlayerRestrictionsParams,
  AlertPlayersBetsParams,
  PlayerSetAlertParams
} from 'modules/SRMT/Players/core/sdk/players/types';
import {
  setFetchAvailableAdditionalCategoriesSuccess, setFetchAvailableAdditionalCategoriesFailure,
  setFetchPlayerFinancialTransactionsSuccess, setFetchPlayerFinancialTransactionsFailure,
  setForcePlayerFinancialCalculationSuccess, setForcePlayerFinancialCalculationFailure,
  setFetchPartnerGameStyleCategoriesSuccess, setFetchPartnerGameStyleCategoriesFailure,
  setAdditionalCategoriesBulkMergeSuccess, setAdditionalCategoriesBulkMergeFailure,
  setGameStyleCategoriesBulkUpdateSuccess, setGameStyleCategoriesBulkUpdateFailure,
  setFetchPlayerFinancialBalancesSuccess, setFetchPlayerFinancialBalancesFailure,
  setPlayerChangeCalculationStateSuccess, setPlayerChangeCalculationStateFailure,
  setFetchEditPlayerRestrictionsSuccess, setFetchEditPlayerRestrictionsFailure,
  setFetchPlayerFinancialCashOutSuccess, setFetchPlayerFinancialCashOutFailure,
  setFetchAdditionalCategoriesSuccess, setFetchAdditionalCategoriesFailure,
  setFetchPlayerFinancialKPIsSuccess, setFetchPlayerFinancialKPIsFailure,
  setFetchFinancialCategoriesSuccess, setFetchFinancialCategoriesFailure,
  setFetchGameStyleCategoriesSuccess, setFetchAllPlayersSessionIpSuccess,
  setFetchAllPlayersSessionIpFailure, setFetchGameStyleCategoriesFailure,
  setFetchPlayerAllSessionIpsSuccess, setFetchPlayerAllSessionIpsFailure,
  setFetchPlayerRestrictionsSuccess, setFetchPlayerRestrictionsFailure,
  setGameStyleCategoryUpdateSuccess, setGameStyleCategoryUpdateFailure,
  setFetchSetMonitoringAlertSuccess, setFetchSetMonitoringAlertFailure,
  setFetchPlayerFingerprintsSuccess, setFetchPlayerFingerprintsFailure,
  setEditAdditionalCategorySuccess, setEditAdditionalCategoryFailure,
  setFetchRemoveTopPrioritySuccess, setFetchRemoveTopPriorityFailure,
  setFetchPlayerSessionIpsFailure, setFetchCategoryHistorySuccess,
  setFetchProcessedAlertsFailure, setFetchAlertsBulkReviewFailure,
  setFetchPlayersBetsFailure, setExpandBetsRowChildrenDataSuccess,
  setFetchCategoryHistoryFailure, setFetchRestrictionsLogSuccess,
  setFetchRestrictionsLogFailure, setFetchProcessedAlertsSuccess,
  setFetchSetTopPrioritySuccess, setFetchAlertsBulkReviewSuccess,
  setFetchPlayersBetsAppendSuccess, setFetchPlayersBetsSuccess,
  setFetchCheckHistoryFailure, setFetchPlayerSessionIpsSuccess,
  setFetchCheckHistorySuccess, setFetchSetTopPriorityFailure,
  setFetchMultiAccountsSuccess, setFetchMultiAccountsFailure,
  setFetchExistingAlertSuccess, setFetchExistingAlertFailure,
  setFetchPlayerKPITreeSuccess, setFetchPlayerKPITreeFailure,
  setFetchAgpClientNoteSuccess, setFetchAgpClientNoteFailure,
  setFetchPlayersAppendSuccess, setFetchNoteCommentsSuccess,
  setAddCommentSuccess, setExpandBetsRowChildrenDataFailure,
  setFetchNoteCommentsFailure, setFetchPlayerAlertsSuccess,
  setFetchPlayerAlertsFailure, setFetchResolveAlertSuccess,
  setFetchResolveAlertFailure, updateFinancialCategories,
  setFetchClientNoteSuccess, setFetchClientNoteFailure,
  setFetchGlobalKPIsSuccess, setFetchGlobalKPIsFailure,
  setFetchPlayerInfoSuccess, setCheckedManuallySuccess,
  setCheckedManuallyFailure, setFetchPlayerInfoFailure,
  setFetchSetAlertSuccess, setFetchSetAlertFailure,
  setFetchPlayersSuccess, setFetchPlayersFailure,
  setAddCommentFailure, fetchPlayerAlerts
} from 'modules/SRMT/Players/store/actions/player';
import {
  setFetchAlertPlayersBetsExpandChildrenFailure,
  setFetchAlertPlayersBetsExpandChildrenSuccess,
  setFetchAlertPlayersBetsExpandFailure,
  setFetchAlertPlayersBetsExpandSuccess,
  setFetchAlertPlayersBetsFailure,
  setFetchAlertPlayersBetsSuccess
} from 'modules/SRMT/Players/store/actions/player/playerBetsAction';
import { updateGlobalInfo } from 'store/actions/general/actions';
import {
  parsePlayersKPITreeModelFromResponse,
  parseSRMTPlayerMultiAccountResponse,
  parsePlayersModelFromResponse
} from 'modules/SRMT/Players/utils/parsers';
import amlSDK from 'modules/AML/core/sdk';

class PlayerController {
  static* fetchRemoveTopPriority({ payload }) {
    const { playerId, state } = payload;
    try {
      const { Data } = yield playersSDK.removeTopPriority({ playerId, state });
      yield put(updateGlobalInfo({ Limit: Data.LiveLimit, Delay: Data.LiveDelay, WWLimit: Data.WWLimit, WWDelay: Data.WWDelay, IsTopPriority: false, SportsBookProfileName: Data.ClientSportsBookProfileId }));
      yield put(setFetchRemoveTopPrioritySuccess({ Data }));
    } catch (err) {
      yield put(setFetchRemoveTopPriorityFailure({ error: err.message }));
    }
  }

  static* fetchSetTopPriority({ payload }) {
    const { limit, preMatchLimit, delay, playerId, state, clientSportsBookProfileId, sportsBookProfileName, comment } = payload;
    try {
      const { Data } = yield playersSDK.setTopPriority({ limit, preMatchLimit, delay, playerId, clientSportsBookProfileId, state, comment });
      yield put(updateGlobalInfo({ Limit: Data.LiveLimit, preMatchLimit: Data.PreMatchLimit, Delay: Data.LiveDelay, WWLimit: Data.WWLimit, WWDelay: Data.WWDelay, IsTopPriority: true, SportsBookProfileName: sportsBookProfileName, SportsBookProfileId: clientSportsBookProfileId }));
      yield put(setFetchSetTopPrioritySuccess({ Data }));
    } catch (err) {
      yield put(setFetchSetTopPriorityFailure({ error: err.message }));
    }
  }

  static* fetchGlobalKPIs({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getGlobalKPIs({ playerId });
      yield put(setFetchGlobalKPIsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGlobalKPIsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerInfo({ payload }) {
    const { id } = payload;
    try {
      const { data } = yield playersSDK.getPlayerInfo({ id });
      yield put(setFetchPlayerInfoSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerInfoFailure({ error: err.message }));
    }
  }

  static* fetchAdditionalCategories({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getAdditionalCategories({ playerId });
      yield put(setFetchAdditionalCategoriesSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAdditionalCategoriesFailure({ error: err.message }));
    }
  }

  static* fetchAvailableAdditionalCategories({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getAvailableAdditionalCategories({ playerId });
      yield put(setFetchAvailableAdditionalCategoriesSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAvailableAdditionalCategoriesFailure({ error: err.message }));
    }
  }

  static* fetchFinancialCategories({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getFinancialCategories({ playerId });
      yield put(setFetchFinancialCategoriesSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchFinancialCategoriesFailure({ error: err.message }));
    }
  }

  static* fetchCategoryHistory({ payload }) {
    const { playerId, count, start } = payload;
    try {
      const response = yield playersSDK.getCategoryHistory({ playerId, count, start });
      const { data, totalCount } = parsePlayersModelFromResponse(response);

      yield put(setFetchCategoryHistorySuccess({ data, totalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchCategoryHistoryFailure({ error: err.message }));
    }
  }

  static* fetchMultiAccounts({ payload }) {
    const { playerId, count, start } = payload;
    try {
      const response = yield playersSDK.getMultiAccounts({ playerId, count, start });
      // eslint-disable-next-line prefer-const
      const { data } = parseSRMTPlayerMultiAccountResponse(response);
      yield put(setFetchMultiAccountsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchMultiAccountsFailure({ error: err.message }));
    }
  }

  static* fetchNoteComments({ payload }) {
    const { playerId, count, start } = payload;
    try {
      const response = yield playersSDK.getNoteComments({ playerId, count, start });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchNoteCommentsSuccess({ data, totalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchNoteCommentsFailure({ error: err.message }));
    }
  }

  static* fetchClientNote({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getClientNote({ playerId });
      yield put(setFetchClientNoteSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchClientNoteFailure({ error: err.message }));
    }
  }

  static* fetchPlayers({ payload }) {
    const { passTotalCount } = payload;
    try {
      // eslint-disable-next-line no-param-reassign
      !!payload.autofetch && delete payload.autofetch;
      const response = yield playersSDK.getPlayers(payload);
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      if (payload.appendData) {
        yield put(setFetchPlayersAppendSuccess({ data, totalCount }));
      } else {
        yield put(setFetchPlayersSuccess({ data, totalCount, passTotalCount }));
      }
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayersFailure({ error: err.message }));
    }
  }

  static* fetchPlayersBets({ payload }) {
    const { passTotalCount } = payload;
    try {
      // eslint-disable-next-line no-param-reassign
      !!payload.autofetch && delete payload.autofetch;
      const response = yield playersSDK.getPlayersBets(payload);
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      if (payload.appendData) {
        yield put(setFetchPlayersBetsAppendSuccess({ data, totalCount }));
      } else {
        yield put(setFetchPlayersBetsSuccess({ data, totalCount, passTotalCount }));
      }
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayersBetsFailure({ error: err.message }));
    }
  }

  static* getExpandBetsRowChildrenData({ payload }) {
    const { betId } = payload;
    try {
      const { Data: data } = yield playersSDK.getFinancialExpandBetsRowChildrenData({ betId });
      yield put(setExpandBetsRowChildrenDataSuccess({ betId, data }));
    } catch (err) {
      yield put(setExpandBetsRowChildrenDataFailure({ betId, error: err.message }));
    }
  }

  static* fetchRestrictionsLog({ payload }) {
    const { playerId, count, start } = payload;
    try {
      const response = yield playersSDK.getRestrictionsLog({ playerId, count, start });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchRestrictionsLogSuccess({ data, totalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchRestrictionsLogFailure({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialKPIs({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerFinancialKPIs({ playerId });
      yield put(setFetchPlayerFinancialKPIsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerFinancialKPIsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialCashOut({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerFinancialCashOut({ playerId });
      yield put(setFetchPlayerFinancialCashOutSuccess({ data }));
    } catch (err) {
      yield put(setFetchPlayerFinancialCashOutFailure({ error: err.message }));
    }
  }

  static* fetchGameStyleCategories({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getGameStyleCategories({ playerId });
      yield put(setFetchGameStyleCategoriesSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGameStyleCategoriesFailure({ error: err.message }));
    }
  }

  static* fetchPartnerGameStyleCategories({ payload }) {
    const { partnerId } = payload;
    try {
      const { data } = yield playersSDK.getPartnerGameStyleCategories({ partnerId });
      yield put(setFetchPartnerGameStyleCategoriesSuccess({ data }));
    } catch (err) {
      yield put(setFetchPartnerGameStyleCategoriesFailure({ error: err.message }));
    }
  }

  static* gameStyleCategoryUpdate({ payload }) {
    const { playerId, categoryId, subId, action, comment } = payload;
    try {
      const { data } = yield playersSDK.gameStyleCategoryUpdate({ playerId, categoryId, subId, action, comment });
      yield put(updateGlobalInfo({ Limit: data.Limit, Delay: data.Delay, SportsBookProfileName: data.SportsBookProfileName, WWLimit: data.WWLimit, WWDelay: data.WWDelay, IsTopPriority: data.IsTopPriority }));
      yield put(setGameStyleCategoryUpdateSuccess({ data }));
    } catch (err) {
      yield put(setGameStyleCategoryUpdateFailure({ error: err.message }));
    }
  }

  static* gameStyleCategoriesUpdateBulk({ payload }) {
    const { playerId, toAdd, toUpdate, toDelete, comment } = payload;
    try {
      const { data } = yield playersSDK.gameStyleCategoriesUpdateBulk({ playerId, toAdd, toUpdate, toDelete, comment });
      yield put(updateGlobalInfo({ Limit: data.Limit, Delay: data.Delay, SportsBookProfileName: data.SportsBookProfileName, WWLimit: data.WWLimit, WWDelay: data.WWDelay, IsTopPriority: data.IsTopPriority }));
      yield put(setGameStyleCategoriesBulkUpdateSuccess({ data }));
    } catch (err) {
      yield put(setGameStyleCategoriesBulkUpdateFailure({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialTransactions({ payload }) {
    const { playerId } = payload;
    try {
      const response = yield playersSDK.getPlayerFinancialTransactions({ playerId });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchPlayerFinancialTransactionsSuccess({ data, totalCount }));
    } catch (err) {
      yield put(setFetchPlayerFinancialTransactionsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialBalances({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerFinancialBalances({ playerId });
      yield put(setFetchPlayerFinancialBalancesSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerFinancialBalancesFailure({ error: err.message }));
    }
  }

  static* fetchPlayerExistingAlert({ payload }) {
    const { playerId, projectType, licence } = payload;
    try {
      const { data } = projectType === BackProjectEnum.rmt
        ? yield playersSDK.getPlayerExistingAlert({ playerId }) : yield amlSDK.getAmlPlayerExistingAlert({ playerId, licence });

      yield put(setFetchExistingAlertSuccess({ data }));
    } catch (err) {
      yield put(setFetchExistingAlertFailure({ error: err.message }));
    }
  }

  static* fetchPlayerSetAlert({ payload }: { data: PlayerSetAlertParams }) {
    const { data, projectType } = payload;
    try {
      projectType === BackProjectEnum.rmt ? yield playersSDK.setPlayerAlert(data) : yield amlSDK.setAmlPlayerAlert(data);
      yield put(setFetchSetAlertSuccess());
    } catch (err) {
      yield put(setFetchSetAlertFailure({ error: err.message }));
    }
  }

  static* fetchPlayerSessionIps({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerSessionIps({ playerId });
      yield put(setFetchPlayerSessionIpsSuccess({ data }));
    } catch (err) {
      yield put(setFetchPlayerSessionIpsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerAllSessionIps({ payload }) {
    const { loginIp, playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerAllSessionIps({ playerId, loginIp });
      yield put(setFetchPlayerAllSessionIpsSuccess({ data, loginIp }));
    } catch (err) {
      yield put(setFetchPlayerAllSessionIpsFailure({ error: err.message, loginIp }));
    }
  }

  static* fetchAllPlayersSessionIp({ payload }) {
    const { loginIp } = payload;
    try {
      const { data } = yield playersSDK.getAllPlayersSessionIp({ loginIp });
      yield put(setFetchAllPlayersSessionIpSuccess({ data, loginIp }));
    } catch (err) {
      yield put(setFetchAllPlayersSessionIpFailure({ error: err.message, loginIp }));
    }
  }

  static* fetchPlayerFingerprints({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerFingerprint({ playerId });
      yield put(setFetchPlayerFingerprintsSuccess({ data }));
    } catch (err) {
      yield put(setFetchPlayerFingerprintsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerKPITree({ payload }) {
    const { level, playerId, sportId, regionId, withParents, key } = payload;
    try {
      const { data } = yield playersSDK.getPlayerKPITree({ level, playerId, sportId, regionId, withParents });
      const parsedData = parsePlayersKPITreeModelFromResponse(data, withParents);
      const children = data.map((row) => row.Key);
      yield put(setFetchPlayerKPITreeSuccess({ data: parsedData, key, children }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerKPITreeFailure({ error: err.message }));
    }
  }

  static* editAdditionalCategory({ payload }) {
    const { playerId, categoryId, isActive } = payload;
    try {
      const { data } = yield playersSDK.editAdditionalCategory({ playerId, categoryId, isActive });
      yield put(updateGlobalInfo({ Limit: data.Limit, Delay: data.Delay, SportsBookProfileName: data.SportsBookProfileName, WWLimit: data.WWLimit, WWDelay: data.WWDelay, IsTopPriority: data.IsTopPriority }));
      yield put(setEditAdditionalCategorySuccess({ categoryId }));
    } catch (err) {
      yield put(setEditAdditionalCategoryFailure({ categoryId, error: err.message }));
    }
  }

  static* additionalCategoriesBulkMerge({ payload }) {
    const { playerId, changeAdditionals, isCalcDisabled, comment } = payload;
    try {
      const { data } = yield playersSDK.additionalCategoriesBulkMerge({ playerId, changeAdditionals, isCalcDisabled, comment });
      yield put(updateGlobalInfo({ Limit: data.Limit, Delay: data.Delay, SportsBookProfileName: data.SportsBookProfileName, WWLimit: data.WWLimit, WWDelay: data.WWDelay, IsTopPriority: data.IsTopPriority }));
      yield put(setAdditionalCategoriesBulkMergeSuccess({ data }));
    } catch (err) {
      yield put(setAdditionalCategoriesBulkMergeFailure({ error: err.message }));
    }
  }

  static* playerAddComment({ payload }) {
    const { playerId, comment, source } = payload;
    try {
      const { data } = yield playersSDK.setPlayerComment({ playerId, comment, source });
      yield put(setAddCommentSuccess({ data }));
    } catch (err) {
      yield put(setAddCommentFailure({ error: err.message }));
    }
  }

  static* playerAlerts({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerAlerts({ playerId });

      yield put(setFetchPlayerAlertsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerAlertsFailure({ error: err.message }));
    }
  }

  static* alertsBulkReview({ payload }) {
    try {
      const { params } = payload;
      yield playersSDK.updateBulkAlerts(params);
      yield put(setFetchAlertsBulkReviewSuccess());
      yield put(fetchPlayerAlerts({ playerId: params.playerId }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAlertsBulkReviewFailure({ error: err.message }));
    }
  }

  static* processedAlerts({ payload }) {
    const { playerId, count, start } = payload;
    try {
      const response = yield playersSDK.getProcessedAlerts({ playerId, count, start });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchProcessedAlertsSuccess({ data, totalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchProcessedAlertsFailure({ error: err.message }));
    }
  }

  static* playerRestrictions({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayerRestrictions({ playerId });
      yield put(setFetchPlayerRestrictionsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerRestrictionsFailure({ error: err.message }));
    }
  }

  static* editPlayerRestrictions({ payload }: EditPlayerRestrictionsParams) {
    try {
      yield playersSDK.editPlayerRestrictions(payload.data);
      yield put(setFetchEditPlayerRestrictionsSuccess());
    } catch (err) {
      yield put(setFetchEditPlayerRestrictionsFailure({ error: err.message }));
    }
  }

  static* playerForceCalculation({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.playerForceCalculation({ playerId });
      yield put(updateFinancialCategories({ data: { Limit: data.FinanceLimit, Delay: data.FinanceDelay } }));
      yield put(setForcePlayerFinancialCalculationSuccess({ data }));
    } catch (err) {
      yield put(setForcePlayerFinancialCalculationFailure({ error: err.message }));
    }
  }

  static* playerChangeCalculationState({ payload }) {
    const { state, playerId } = payload;
    try {
      const { data } = yield playersSDK.playerChangeCalculationState({ state, playerId });
      yield put(setPlayerChangeCalculationStateSuccess({ data }));
    } catch (err) {
      yield put(setPlayerChangeCalculationStateFailure({ error: err.message }));
    }
  }

  static* fetchAgpComments({ payload }) {
    const { playerId } = payload;
    try {
      const { data: { Data } } = yield playersSDK.fetchAgpComments({ playerId });
      yield put(setFetchAgpClientNoteSuccess({ data: Data }));
    } catch (err) {
      yield put(setFetchAgpClientNoteFailure({ error: err.message }));
    }
  }

  static* fetchSetMonitoringAlert({ payload }) {
    const { filters, projectType } = payload;
    try {
      const { data } = projectType === BackProjectEnum.rmt
        ? yield playersSDK.fetchSetMonitoringAlert({ filters }) : yield amlSDK.fetchSetAmlMonitoringAlert({ filters });
      yield put(setFetchSetMonitoringAlertSuccess({ data }));
    } catch (err) {
      yield put(setFetchSetMonitoringAlertFailure({ error: err.message }));
    }
  }

  static* fetchResolveMonitoringAlert({ payload }) {
    const { filters, projectType } = payload;
    try {
      projectType === BackProjectEnum.rmt ? yield playersSDK.fetchResolveMonitoringAlert({ ...filters })
        : yield amlSDK.fetchAmlResolveMonitoringAlert({ ...filters });
      yield put(setFetchResolveAlertSuccess());
    } catch (err) {
      yield put(setFetchResolveAlertFailure({ error: err.message }));
    }
  }

  static* fetchCheckedManually({ payload }) {
    const { playerId, source } = payload;
    try {
      yield playersSDK.fetchCheckedManually({ playerId, source });
      yield put(setCheckedManuallySuccess());
    } catch (err) {
      yield put(setCheckedManuallyFailure({ error: err.message }));
    }
  }

  static* fetchCheckHistory({ payload }) {
    const { filters, count, start, passTotalCount, projectType } = payload;
    try {
      const response = yield playersSDK.fetchCheckHistory({ ...filters, count, start, passTotalCount, projectType });
      const { data, totalCount } = parsePlayersModelFromResponse(response);

      yield put(setFetchCheckHistorySuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchCheckHistoryFailure({ error: err.message }));
    }
  }

  static* fetchAlertPlayersBets({ payload }: { data: AlertPlayersBetsParams }) {
    const { alertType, alertId, start, count, passTotalCount } = payload;
    try {
      const response = yield playersSDK.fetchAlertPlayersBets({ alertType, alertId, start, count, passTotalCount });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchAlertPlayersBetsSuccess({ data, totalCount, passTotalCount }));
    } catch (err) {
      yield put(setFetchAlertPlayersBetsFailure({ error: err.message }));
    }
  }

  static* fetchAlertPlayersBetsExpand({ payload }: { data: AlertPlayersBetsParams }) {
    const { playerId, start, count, passTotalCount, appendData, section } = payload;
    try {
      const response = yield playersSDK.getPlayersBets({ playerId, start, count, passTotalCount, appendData, section });
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      yield put(setFetchAlertPlayersBetsExpandSuccess({ data, playerId, totalCount, passTotalCount }));
    } catch (err) {
      yield put(setFetchAlertPlayersBetsExpandFailure({ playerId, error: err.message }));
    }
  }

  static* fetchAlertPlayersBetsExpandChildren({ payload }: { data: AlertPlayersBetsParams }) {
    const { playerId, betId } = payload;
    try {
      const { Data: data } = yield playersSDK.getFinancialExpandBetsRowChildrenData({ betId });
      yield put(setFetchAlertPlayersBetsExpandChildrenSuccess({ data, playerId, betId }));
    } catch (err) {
      yield put(setFetchAlertPlayersBetsExpandChildrenFailure({ playerId, error: err.message }));
    }
  }

  static* cancelPlayerSDKCalls({ payload }) {
    const { name } = payload;
    yield playersSDK.cancelPlayerSDKCalls({ name });
  }

  static* playerWatcher<T>(): Iterable<T> {
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_SET_CHECK_MANUALLY, PlayerController.fetchCheckedManually);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_CHECK_HISTORY, PlayerController.fetchCheckHistory);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_AGP_COMMENTS, PlayerController.fetchAgpComments);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_RESOLVE_ALERT, PlayerController.fetchResolveMonitoringAlert);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_SET_MONITORING_ALERT, PlayerController.fetchSetMonitoringAlert);
    yield takeLatest(PLAYER_ACTION_TYPES.CANCEL_PLAYER_SDK_CALL, PlayerController.cancelPlayerSDKCalls);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_TRANSACTIONS, PlayerController.fetchPlayerFinancialTransactions);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_CASH_OUT, PlayerController.fetchPlayerFinancialCashOut);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ADDITIONAL_CATEGORIES, PlayerController.fetchAdditionalCategories);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_AVAILABLE_ADDITIONAL_CATEGORIES, PlayerController.fetchAvailableAdditionalCategories);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_KPIS, PlayerController.fetchPlayerFinancialKPIs);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_FINANCIAL_CATEGORIES, PlayerController.fetchFinancialCategories);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_SESSION_IPS, PlayerController.fetchPlayerSessionIps);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_ALL_SESSION_IPS, PlayerController.fetchPlayerAllSessionIps);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ALL_PLAYERS_SESSION_IP, PlayerController.fetchAllPlayersSessionIp);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_FINGERPRINTS, PlayerController.fetchPlayerFingerprints);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_EXISTING_ALERT, PlayerController.fetchPlayerExistingAlert);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_RESTRICTIONS_LOG, PlayerController.fetchRestrictionsLog);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_CATEGORY_HISTORY, PlayerController.fetchCategoryHistory);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_MULTI_ACCOUNTS, PlayerController.fetchMultiAccounts);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_NOTE_COMMENTS, PlayerController.fetchNoteComments);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_SET_ALERT, PlayerController.fetchPlayerSetAlert);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_CLIENT_NOTE, PlayerController.fetchClientNote);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_REMOVE_TOP_PRIORITY, PlayerController.fetchRemoveTopPriority);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_SET_TOP_PRIORITY, PlayerController.fetchSetTopPriority);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_GLOBAL_KPIS, PlayerController.fetchGlobalKPIs);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_INFO, PlayerController.fetchPlayerInfo);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_FINANCIAL_BALANCES, PlayerController.fetchPlayerFinancialBalances);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYERS, PlayerController.fetchPlayers);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYERS_BETS, PlayerController.fetchPlayersBets);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_EXPAND_BETS_ROW_CHILDREN_DATA, PlayerController.getExpandBetsRowChildrenData);
    yield takeEvery(PLAYER_ACTION_TYPES.EDIT_ADDITIONAL_CATEGORY, PlayerController.editAdditionalCategory);
    yield takeLatest(PLAYER_ACTION_TYPES.ADDITIONAL_CATEGORIES_BULK_MERGE, PlayerController.additionalCategoriesBulkMerge);
    yield takeLatest(PLAYER_ACTION_TYPES.ADD_COMMENT, PlayerController.playerAddComment);
    yield takeEvery(PLAYER_ACTION_TYPES.FETCH_PLAYER_KPI_TREE, PlayerController.fetchPlayerKPITree);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_GAME_STYLE_CATEGORIES, PlayerController.fetchGameStyleCategories);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PARTNER_GAME_STYLE_CATEGORIES, PlayerController.fetchPartnerGameStyleCategories);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_ALERTS, PlayerController.playerAlerts);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ALERTS_BULK_REVIEW, PlayerController.alertsBulkReview);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PROCESSED_ALERTS, PlayerController.processedAlerts);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_RESTRICTIONS, PlayerController.playerRestrictions);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_EDIT_PLAYER_RESTRICTIONS, PlayerController.editPlayerRestrictions);
    yield takeLatest(PLAYER_ACTION_TYPES.FORCE_PLAYER_FINANCIAL_CALCULATION, PlayerController.playerForceCalculation);
    yield takeLatest(PLAYER_ACTION_TYPES.PLAYER_CHANGE_CALCULATION_STATE, PlayerController.playerChangeCalculationState);
    yield takeLatest(PLAYER_ACTION_TYPES.GAME_STYLE_CATEGORY_UPDATE, PlayerController.gameStyleCategoryUpdate);
    yield takeLatest(PLAYER_ACTION_TYPES.GAME_STYLE_CATEGORIES_BULK_UPDATE, PlayerController.gameStyleCategoriesUpdateBulk);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS, PlayerController.fetchAlertPlayersBets);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND, PlayerController.fetchAlertPlayersBetsExpand);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ALERT_PLAYERS_BETS_EXPAND_CHILDREN, PlayerController.fetchAlertPlayersBetsExpandChildren);
  }
}

export default PlayerController.playerWatcher;
