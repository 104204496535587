/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import ACTIONS from './types';

// Templates
export const fetchRoleTemplates = () => ({
  type: ACTIONS.FETCH_ROLE_TEMPLATES
});
export const setRoleTemplatesSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_ROLE_TEMPLATES_SUCCESS,
  payload: { data }
});
export const setRoleTemplatesFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_ROLE_TEMPLATES_FAILURE,
  payload: { error }
});
export const clearRoleTemplates = () => ({
  type: ACTIONS.CLEAR_ROLE_TEMPLATES
});

// All Resources
export const fetchAllResources = () => ({
  type: ACTIONS.FETCH_ALL_RESOURCES
});
export const setAllResourcesSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_ALL_RESOURCES_SUCCESS,
  payload: { data }
});
export const setAllResourcesFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_ALL_RESOURCES_FAILURE,
  payload: { error }
});
export const clearAllResources = () => ({
  type: ACTIONS.CLEAR_ALL_RESOURCES
});

// Role Resources
export const fetchRoleResources = ({ id }: Object) => ({
  type: ACTIONS.FETCH_ROLE_RESOURCES,
  payload: { id }
});
export const setRoleResourcesSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_ROLE_RESOURCES_SUCCESS,
  payload: { data }
});
export const setRoleResourcesFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_ROLE_RESOURCES_FAILURE,
  payload: { error }
});
export const clearRoleResources = () => ({
  type: ACTIONS.CLEAR_ROLE_RESOURCES
});

// Template Resources
export const fetchTemplateResources = ({ id }: Object) => ({
  type: ACTIONS.FETCH_TEMPLATE_RESOURCES,
  payload: { id }
});
export const setTemplateResourcesSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_TEMPLATE_RESOURCES_SUCCESS,
  payload: { data }
});
export const setTemplateResourcesFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_TEMPLATE_RESOURCES_FAILURE,
  payload: { error }
});
export const clearTemplateResources = () => ({
  type: ACTIONS.CLEAR_TEMPLATE_RESOURCES
});

// user group Resources
export const fetchUserGroupResources = ({ userId }: Object) => ({
  type: ACTIONS.FETCH_USER_GROUP_RESOURCES,
  payload: { userId }
});
export const setUserGroupResourcesSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_USER_GROUP_RESOURCES_SUCCESS,
  payload: { data }
});
export const setUserGroupResourcesFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_USER_GROUP_RESOURCES_FAILURE,
  payload: { error }
});
export const clearUserGroupResources = () => ({
  type: ACTIONS.CLEAR_USER_GROUP_RESOURCES
});

// add Resources
export const setAddResources = ({ changes, type, userId, roleId }: Object) => ({
  type: ACTIONS.ADD_RESOURCES,
  payload: { changes, type, userId, roleId }
});
export const setAddResourcesSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_ADD_RESOURCES_SUCCESS,
  payload: { data }
});
export const setAddResourcesFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_ADD_RESOURCES_FAILURE,
  payload: { error }
});
export const clearAddResources = () => ({
  type: ACTIONS.CLEAR_ADD_RESOURCES
});

// Users
export const fetchUsers = ({ count, start, passTotalCount, filters }: Object) => ({
  type: ACTIONS.FETCH_USERS,
  payload: { count, start, passTotalCount, filters }
});
export const setFetchUsersSuccess = ({ data, totalCount, passTotalCount }: Object) => ({
  type: ACTIONS.SET_FETCH_USERS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchUsersFailure = (error: string) => ({
  type: ACTIONS.SET_FETCH_USERS_FAILURE,
  payload: { error }
});
export const clearUsersState = () => ({
  type: ACTIONS.CLEAR_USERS_STATE
});

// cancel users call
export const cancelUsersCall = ({ name }:any): Action => ({
  type: ACTIONS.CANCEL_USERS_CALL,
  payload: { name }
});

// Operating Partners Update
export const fetchOperatingPartnerUpdate = ({ params }: Object) => ({
  type: ACTIONS.FETCH_OPERATING_PARTNER_UPDATE,
  payload: { params }
});
export const setFetchOperatingPartnerUpdateSuccess = ({ data }: Object) => ({
  type: ACTIONS.SET_FETCH_OPERATING_PARTNER_UPDATE_SUCCESS,
  payload: { data }
});
export const setFetchOperatingPartnerUpdateFailure = (error: string) => ({
  type: ACTIONS.SET_FETCH_OPERATING_PARTNER_UPDATE_FAILURE,
  payload: { error }
});
export const clearOperatingPartnerUpdateState = () => ({
  type: ACTIONS.CLEAR_OPERATING_PARTNER_UPDATE
});

// Assign Info
export const setAssignInfo = ({ data }: Object) => ({
  type: ACTIONS.SET_ASSIGN_INFO,
  payload: { data }
});
export const clearAssignInfo = () => ({
  type: ACTIONS.CLEAR_ASSIGN_INFO
});

// Role Permission
export const fetchRolePermission = () => ({
  type: ACTIONS.FETCH_ROLE_PERMISSION
});
export const setFetchRolePermissionSuccess = ({ data }: Object) => ({
  type: ACTIONS.SET_FETCH_ROLE_PERMISSION_SUCCESS,
  payload: { data }
});
export const setFetchRolePermissionFailure = (error: string) => ({
  type: ACTIONS.SET_FETCH_ROLE_PERMISSION_FAILURE,
  payload: { error }
});
export const clearRolePermissionState = () => ({
  type: ACTIONS.CLEAR_ROLE_PERMISSION_STATE
});

// Additional Resources
export const fetchAdditionalResources = ({ userId }: Object) => ({
  type: ACTIONS.FETCH_ADDITIONAL_RESOURCES,
  payload: { userId }
});
export const setAdditionalResourcesSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_ADDITIONAL_RESOURCES_SUCCESS,
  payload: { data }
});
export const setAdditionalResourcesFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_ADDITIONAL_RESOURCES_FAILURE,
  payload: { error }
});
export const clearAdditionalResources = () => ({
  type: ACTIONS.CLEAR_ADDITIONAL_RESOURCES
});

// users partner
export const fetchUsersPartner = ({ userIds }: Object) => ({
  type: ACTIONS.FETCH_USERS_PARTNER,
  payload: { userIds }
});
export const setUsersPartnerSuccess = ({ data }: Object): Action => ({
  type: ACTIONS.SET_USERS_PARTNER_SUCCESS,
  payload: { data }
});
export const setUsersPartnerFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_USERS_PARTNER_FAILURE,
  payload: { error }
});
export const clearUsersPartner = () => ({
  type: ACTIONS.CLEAR_USERS_PARTNER
});

// deactivate user
export const fetchDeactivationUser = ({ userId }: Object) => ({
  type: ACTIONS.FETCH_DEACTIVATE_USER,
  payload: { userId }
});
export const setDeactivationUserSuccess = (): Action => ({
  type: ACTIONS.SET_DEACTIVATE_USER_SUCCESS
});
export const setDeactivationUserFailure = ({ error }: Object): Action => ({
  type: ACTIONS.SET_DEACTIVATE_USER_FAILURE,
  payload: { error }
});
export const clearDeactivationUser = () => ({
  type: ACTIONS.CLEAR_DEACTIVATE_USER
});

// cancel call
export const cancelRolesDataCall = ({ name }:any): Action => ({
  type: ACTIONS.CANCEL_ROLES_CALL,
  payload: { name }
});
