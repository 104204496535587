import CacheService from 'core/services/storage/CacheService';
import cachedFilterKeys from 'hooks/Filter/cachedFilterKeys';

export const filterCaching = (data) => {
  let filterCache = CacheService.getJson('filterCache');
  if (!filterCache) {
    CacheService.setJson('filterCache', {});
    filterCache = CacheService.getJson('filterCache');
  }
  cachedFilterKeys.forEach(item => {
    if (data[item] && !filterCache[item]) {
      filterCache[item] = data[item];
    }
  });
  CacheService.setJson('filterCache', filterCache);
};

export const reduceKeys = (data) => {
  const filterCache = CacheService.getJson('filterCache');
  if (!filterCache) {
    return data;
  }
  const newData = [];
  data.forEach(item => {
    if (!filterCache[item]) {
      newData.push(item);
    }
  });
  return newData;
};
