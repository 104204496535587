/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const NotificationSettings = React.lazy(() => import('./views/pages/NotificationSettings'));
const TableSettings = React.lazy(() => import('./views/pages/TableSettings'));

const subUrl = settings.PRODUCTS.LCRMT.path;
const modulePath = `${subUrl}/settings`;

const ROUTES: Array<Route> = [
  { path: `/${modulePath}`, name: 'Settings', exact: true, page: () => <Redirect to={`/${modulePath}/notification-settings`} /> },
  { path: `/${modulePath}/notification-settings`, name: 'Notification Settings', exact: true, page: NotificationSettings },
  { path: `/${modulePath}/tables-settings`, name: 'Tables Settings', exact: true, page: TableSettings }
];

export default ROUTES;
