/* eslint-disable no-prototype-builtins */
/**
 * @flow
 * */

import type { FilterReducer, HolderStatusPayload } from 'types/FilterModels';
import CacheService from './CacheService';

const initialState: FilterReducer = {
  holderStatus: {
    isOpened: false
  },
  selectedFilters: {},
  data: {}
};

class FilterCacheService {
  _storageKey: string = 'filters';

  _filterState: FilterReducer;

  constructor() {
    // $FlowFixMe
    let currentState: ?FilterCacheService = CacheService.getJson(this._storageKey);

    if (!currentState) {
      currentState = initialState;
      this.setFilterState(currentState);
    }

    this._filterState = currentState;
  }

  clearFiltersCache() {
    return CacheService.deleteItem(this._storageKey);
  }

  getFilterState(): FilterReducer {
    return this._filterState;
  }

  setFilterState(state: FilterReducer) {
    return CacheService.setJson(this._storageKey, state);
  }

  setHolderStatus(payload: HolderStatusPayload) {
    let { holderStatus } = this._filterState;

    // merge filter holder payload to state
    holderStatus = { ...holderStatus, ...payload };
    this._filterState.holderStatus = holderStatus;

    this.setFilterState(this._filterState);

    return holderStatus;
  }

  setSelectedFilter({ filterId, selectedFilter }: { filterId: string, selectedFilter: string }) {
    let { selectedFilters } = this._filterState;
    selectedFilters = { ...selectedFilters, [filterId]: selectedFilter };
    this._filterState.selectedFilters = selectedFilters;

    this.setFilterState(this._filterState);

    return selectedFilters;
  }

  clearSelectedFilter({ filterId }: { filterId: string }) {
    const { selectedFilters = {} } = this._filterState;
    // $FlowFixMe
    if (selectedFilters.hasOwnProperty(filterId)) {
      // $FlowFixMe
      delete selectedFilters[filterId];
      this._filterState.selectedFilters = selectedFilters;

      this.setFilterState(this._filterState);
    }

    return selectedFilters;
  }
}

export default new FilterCacheService();
