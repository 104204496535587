/**
 * @flow
 * */
import config from 'config';
import NetworkService from 'core/services/network/NetworkService';
import type { UserPermission, UserPermissionParams } from './types';

/**
 * @class PermissionSDK
 */
class PermissionSDK {
    _rmtApiService: NetworkService;

    constructor() {
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * getUserPermission
     * @param {UserPermissionParams} params -
     * @returns {Promise<{data: Array<UserPermission>}>} -
     */
    async getUserPermission(params: UserPermissionParams): Promise<{data: Array<UserPermission>}> {
      const { userId, caching = true } = params;
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'UserPermission/Get',
        { query_params: { userId }, caching }
      );
      return {
        data: body.Data
      };
    }
}

export default new PermissionSDK();
